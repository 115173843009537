import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';

import NotificationContent from 'core/components/NotificationProvider/NotificationContent';
import {
  NotificationContext,
  NotificationVariant,
} from 'core/context/NotificationContext';

type Notification = {
  message: string;
  variant?: NotificationVariant | null;
};

type State = {
  isOpen: boolean;
  messageInfo: Notification;
};

const initialMessageInfo = {
  message: '',
  variant: null,
};

type NotificationProviderProps = {
  children: React.ReactNode | React.ReactNode[];
};

class NotificationProvider extends Component<NotificationProviderProps, State> {
  queue: Notification[] = [];

  state: State = {
    isOpen: false,
    messageInfo: {
      message: '',
      variant: null,
    },
  };

  handleShow = (message: string, variant?: NotificationVariant) => {
    this.queue.push({
      message,
      variant,
    });

    if (this.state.isOpen) {
      // Dismiss current message to show new one
      this.setState({ isOpen: false });
    } else {
      this.processQueue();
    }
  };

  handleClose = (
    _e: React.SyntheticEvent<HTMLButtonElement>,
    reason: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ isOpen: false });
  };

  handleExited = () => {
    this.processQueue();
  };

  processQueue() {
    if (this.queue.length > 0) {
      this.setState({
        isOpen: true,
        messageInfo: this.queue.shift() || initialMessageInfo,
      });
    }
  }

  render() {
    const {
      isOpen,
      messageInfo: { message, variant },
    } = this.state;

    return (
      <NotificationContext.Provider
        value={{ showNotification: this.handleShow }}
      >
        {this.props.children}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          autoHideDuration={5000}
          onClose={this.handleClose}
          onExited={this.handleExited}
          open={isOpen}
        >
          <NotificationContent
            message={message}
            onClose={this.handleClose}
            variant={variant}
          />
        </Snackbar>
      </NotificationContext.Provider>
    );
  }
}

export default NotificationProvider;
