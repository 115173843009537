import React, { useEffect, useState } from 'react';
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Formik } from 'formik';
import { Button } from 'core/components';

import { logIn } from 'api/login';
import { checkAuthGroups } from 'utils/auth';
import * as propTypes from 'utils/propTypes';
import DocumentTitle from 'components/DocumentTitle';
import {
  FormikInputWithIcon,
  FormikInputWrapper,
} from 'components/FormikForms';
import {
  emailValidator,
  requiredValidator,
} from 'components/FormikForms/utils';
import { useQuery } from 'hooks/useQuery';
import LoginRightPanel from 'pages/Login/LoginRightPanel';
import {
  SplitContainer,
  LoginContainer,
  LoginForm,
  Logo,
  Subheader,
  InputFields,
  StyledLink,
} from 'pages/Login/Login.css';
import {
  getBrandSettings,
  getBrandFromDomain,
  SupportedBrand,
} from 'utils/brands';
import EyeToggleButton from 'components/EyeToggleButton';
import { useAuthContext } from 'context/AuthContext';
import { useNotificationContext } from 'core/context/NotificationContext';

type FormProps = {
  email: string;
  password: string;
};

type LocationState = {
  redirectPath?: string;
  componentToDisplay?: React.ReactNode;
};

type LoginPageProps = RouteComponentProps<object, StaticContext, LocationState>;

const Login = ({ history, location }: LoginPageProps) => {
  const { handleLogIn, isLoggedIn } = useAuthContext();
  const { showNotification } = useNotificationContext();
  const query = useQuery();
  const [hidePassword, setHidePassword] = useState(true);

  const {
    brandId,
    loginBackgroundImgSrc,
    label,
    logoSrc,
    subheader,
    showLaunchNotes,
  } = getBrandSettings(getBrandFromDomain());

  // call Appcues with dummy identify call to be able to trigger banners on the login page
  window.Appcues.identify('login_dummy_id', {});

  useEffect(() => {
    if (isLoggedIn) {
      history.replace('/');
    }
  }, []);

  /**
   * Hook that handles the redirection from customer portal to auto-login with the passed credentials
   * after resetting password confirm
   */
  useEffect(() => {
    const accessToken = query.get('access_token');
    const refreshToken = query.get('refresh_token');
    const email = query.get('email');
    const groups = query.get('groups')?.split(',') ?? [];

    if (!!accessToken && !!refreshToken && !!email) {
      handleLogIn(accessToken, refreshToken, groups, email);
      history.replace('/');
    }
  }, [query]);

  const handleSubmit = async ({ email, password }: FormProps) => {
    const { state = {} } = location;
    const { redirectPath = '/' } = state;
    const lowerCaseEmail = email.toLowerCase();
    try {
      const {
        access_token: accessToken,
        refresh_token: refreshToken,
        groups = [],
      } = await logIn(lowerCaseEmail, password, brandId);
      const hasAccess = checkAuthGroups(groups);

      if (!hasAccess) {
        throw new Error('You are not allowed to sign in');
      }

      handleLogIn(accessToken, refreshToken, groups, email);
      history.replace(redirectPath);
    } catch (err: unknown) {
      if (err instanceof Error) {
        showNotification(err.message, 'error');
      } else {
        showNotification('Failed to log in', 'error');
      }
    }
  };

  const resetLink = (email?: string) => {
    if (email) {
      return `/account/reset?email=${email}`;
    }
    return '/account/reset';
  };

  const initialValues: FormProps = {
    email: '',
    password: '',
  };
  return (
    <DocumentTitle title="Login">
      <SplitContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting, values: { email } }) => (
            <LoginContainer>
              <LoginForm>
                {logoSrc && (
                  <Logo
                    alt={label as string}
                    src={logoSrc as string}
                    $oliv={getBrandFromDomain() === ('oliv' as SupportedBrand)}
                  />
                )}
                {subheader && <Subheader>{subheader}</Subheader>}
                <InputFields>
                  <FormikInputWrapper
                    testId="email-input"
                    autoComplete="username"
                    name="email"
                    type="email"
                    placeholder="Email"
                    validate={emailValidator}
                  />
                  <FormikInputWithIcon
                    testId="password-input"
                    autoComplete="current-password"
                    placeholder="Password"
                    name="password"
                    type={hidePassword ? 'password' : 'text'}
                    validate={requiredValidator}
                    customTrailingIcon={
                      <EyeToggleButton
                        hide={hidePassword}
                        setHide={setHidePassword}
                      />
                    }
                  />
                </InputFields>
                <Button
                  testId="login-btn"
                  buttonType="secondary"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type="submit"
                >
                  Log in
                </Button>
                <StyledLink to={resetLink(email)}>Forgot password</StyledLink>
              </LoginForm>
            </LoginContainer>
          )}
        </Formik>
        <LoginRightPanel
          backgroundImgSrc={loginBackgroundImgSrc as string}
          showLaunchNotes={showLaunchNotes as boolean}
        />
      </SplitContainer>
    </DocumentTitle>
  );
};

Login.propTypes = {
  history: propTypes.routerHistory.isRequired,
  location: propTypes.routerLocation.isRequired,
};

export default Login;
