import React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Loading } from 'core/components';
import { CustomerInfo } from 'utils/types';

import LeftArrowSVG from 'assets/arrow-left.svg?react';
import OpenInActions from 'components/PatientHeader/OpenInActions';

import {
  ActionLinks,
  Container,
  Content,
  CustomerName,
  InfoBlocks,
  InfoWrap,
  Overline,
  TabLink,
  TabNav,
  TabNavScrollContainer,
  TopLinks,
  ViewConsentButton,
} from 'components/PatientHeader/PatientHeader.css';
import CaseActions from 'components/PatientHeader/CaseActions';

interface PatientHeaderProps extends RouteComponentProps {
  backPath?: string;
  caseRef?: string;
  krakenCaseId?: string;
  customerInfo: CustomerInfo;
  isOrtho?: boolean;
  showTopLinks?: boolean;
  showCaseActions?: boolean;
  tabNavItems?: { path: string; title: string }[];
  providerFacingCaseState?: string;
  isLoading: boolean;
}

const PatientHeader = ({
  backPath,
  caseRef,
  krakenCaseId,
  customerInfo,
  isOrtho,
  match,
  showTopLinks,
  tabNavItems,
  showCaseActions,
  isLoading,
  providerFacingCaseState,
}: PatientHeaderProps) => {
  const prevUrl = match.url.substring(0, match.url.lastIndexOf('/'));

  return (
    <Container>
      <Content hasTabNav={!!tabNavItems}>
        {showTopLinks && (
          <ActionLinks>
            <TopLinks>
              <Link to={backPath || prevUrl}>
                <LeftArrowSVG aria-hidden />
                Back
              </Link>
            </TopLinks>
            <ActionLinks>
              {showCaseActions && (
                <CaseActions customerId={customerInfo.id} caseRef={caseRef} />
              )}
              <OpenInActions customerId={customerInfo.id} showOrthoPrism />
            </ActionLinks>
          </ActionLinks>
        )}
        <InfoWrap>
          <div>
            <Overline data-testid="overline-heading">PATIENT</Overline>
            <CustomerName data-private>{customerInfo.name}</CustomerName>
          </div>
          <InfoBlocks>
            <li>
              <Overline data-testid="overline-heading">STATE</Overline>
              {isLoading ? <Loading /> : <div>{providerFacingCaseState}</div>}
            </li>
            <li>
              <Overline data-testid="overline-heading">PATIENT ID</Overline>
              <div>{customerInfo.id}</div>
            </li>
            {krakenCaseId && (
              <li>
                <Overline data-testid="overline-heading">KRAKEN ID</Overline>
                <div>{krakenCaseId}</div>
              </li>
            )}
            <li>
              <Overline data-testid="overline-heading">DATE OF BIRTH</Overline>
              <div data-private>{customerInfo.dob}</div>
            </li>
            {/* Show Consent link if you are in the Ortho tool and flag is on */}
            {caseRef && isOrtho && (
              <li>
                <Overline data-testid="overline-heading">CONSENT</Overline>
                <ViewConsentButton
                  onClick={() =>
                    window.open(`/case/${customerInfo.id}/${caseRef}/consent`)
                  }
                >
                  View
                </ViewConsentButton>
              </li>
            )}
          </InfoBlocks>
        </InfoWrap>
        {tabNavItems && (
          <TabNavScrollContainer>
            <TabNav>
              {tabNavItems.map(({ path, title }) => (
                <TabLink key={path} activeClassName="active" to={path}>
                  {title}
                </TabLink>
              ))}
            </TabNav>
          </TabNavScrollContainer>
        )}
      </Content>
    </Container>
  );
};

export default withRouter(PatientHeader);
