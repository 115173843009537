import React, { createContext, useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import useScript from 'core/hooks/useScript';
import theme from 'core/styles/theme';

type AnyObject = {
  [key: string]: any;
};

declare global {
  interface Window {
    zE: (...args: any[]) => void;
    zESettings: AnyObject;
  }
}

type CustomerInfo = {
  email: string;
  name: string;
};

type ContextProps = {
  handleCloseWidget: () => void;
  handleHideWidget: () => void;
  handleOpenWidget: () => void;
  handleShowWidget: () => void;
  isWidgetLoaded: boolean;
  isWidgetOpen: boolean;
  setCustomerInfo: (info: CustomerInfo) => void;
  setDepartment: (department: string) => void;
};

type ZendeskProviderProps = {
  children: React.ReactNode | React.ReactNode[];
  hideByDefault?: boolean;
  onOpen?: () => void;
  settings?: AnyObject;
  widgetKey: string;
};

const defaultSettings = {
  color: {
    theme: theme.palette.secondary.main,
  },
  offset: {
    vertical: '2px',
  },
  launcher: {
    chatLabel: { '*': 'Need help?' },
    label: { '*': 'Need help?' },
  },
  header: {
    label: { '*': 'Candid' },
  },
  contactOptions: {
    enabled: true, // Enables multiple option view
  },
  chat: {
    departments: {
      enabled: [],
      select: '',
    },
    hideWhenOffline: true,
    menuOptions: {
      emailTranscript: false,
    },
  },
  contactForm: {
    fields: [],
    suppress: true,
  },
  talk: {
    nickname: '', // Profile nickname in Zendesk
    suppress: true,
  },
};

/**
 * @deprecated since version 2.2.5
 */
export const ZendeskContext = createContext({} as ContextProps);

const ZendeskProvider = ({
  children,
  hideByDefault,
  onOpen,
  settings = {},
  widgetKey,
}: ZendeskProviderProps) => {
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const mergedSettings = useMemo(
    () => R.mergeDeepRight(defaultSettings, settings),
    [settings]
  );
  const zESettings = { webWidget: mergedSettings };

  // Set Zendesk Widget settings
  useEffect(() => {
    window.zESettings = zESettings;
  }, []);

  // Load Zendesk Widget script
  const [isScriptLoaded, hasError] = useScript({
    src: `https://static.zdassets.com/ekr/snippet.js?key=${widgetKey}`,
    id: 'ze-snippet',
  });

  useEffect(() => {
    if (isScriptLoaded && !hasError && window.zE) {
      const { contactForm: contactFormSettings, talk: talkSettings } =
        zESettings.webWidget;
      const isTalkEnabled = !talkSettings.suppress && !!talkSettings.nickname;
      const isContactFormEnabled = !contactFormSettings.suppress;

      if (hideByDefault) {
        window.zE('webWidget', 'hide');
      }

      window.zE('webWidget:on', 'open', () => {
        setIsWidgetOpen(true);

        if (onOpen) {
          onOpen();
        }
      });

      window.zE('webWidget:on', 'close', () => {
        setIsWidgetOpen(false);
      });

      if (isTalkEnabled || isContactFormEnabled) {
        window.zE('webWidget:on', 'chat:status', (status: string) => {
          if (status === 'online') {
            // Show 'Talk' and hide 'Contact Form' when agents are online
            if (isTalkEnabled) {
              zESettings.webWidget.talk.suppress = false;
            }
            if (isContactFormEnabled) {
              zESettings.webWidget.contactForm.suppress = true;
            }
          } else if (status === 'offline') {
            // Hide 'Talk' and show 'Contact Form' when agents are offline
            if (isTalkEnabled) {
              zESettings.webWidget.talk.suppress = true;
            }
            if (isContactFormEnabled) {
              zESettings.webWidget.contactForm.suppress = false;
            }
          }

          window.zE('webWidget', 'updateSettings', zESettings);
        });
      }

      setIsWidgetLoaded(true);
    }
  }, [isScriptLoaded]);

  const handleShowWidget = () => {
    if (isWidgetLoaded) {
      window.zE('webWidget', 'show');
    }
  };

  const handleHideWidget = () => {
    if (isWidgetLoaded) {
      window.zE('webWidget', 'hide');
    }
  };

  const handleOpenWidget = () => {
    if (isWidgetLoaded) {
      window.zE('webWidget', 'open');
    }
  };

  const handleCloseWidget = () => {
    if (isWidgetLoaded) {
      window.zE('webWidget', 'close');
    }
  };

  const setCustomerInfo = ({ email, name }: CustomerInfo) => {
    if (isWidgetLoaded) {
      // Pre-fill name and email in chat
      window.zE('webWidget', 'identify', {
        name,
        email,
      });
      // Pre-fill name and email in offline contact form
      zESettings.webWidget.contactForm.fields = [
        { id: 'name', prefill: { '*': name } },
        { id: 'email', prefill: { '*': email } },
      ];
      window.zE('webWidget', 'updateSettings', zESettings);
    }
  };

  const setDepartment = (department: string) => {
    if (isWidgetLoaded) {
      zESettings.webWidget.chat.departments.select = department;
      window.zE('webWidget', 'updateSettings', zESettings);
    }
  };

  return (
    <ZendeskContext.Provider
      value={{
        handleCloseWidget,
        handleHideWidget,
        handleOpenWidget,
        handleShowWidget,
        isWidgetLoaded,
        isWidgetOpen,
        setCustomerInfo,
        setDepartment,
      }}
    >
      {children}
    </ZendeskContext.Provider>
  );
};

export default ZendeskProvider;
