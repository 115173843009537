import { GetProductVariantsQuery } from 'generated/core/graphql';

export type ProductVariantType = GetProductVariantsQuery['productVariants'][0];

export type SelectOption = {
  value: string;
  label: string;
};

export enum FormType {
  practice,
  patient,
}
