import { useAuthContext } from 'context/AuthContext';
import {
  AlertCard,
  Avatar,
  colors,
  Divider,
  Grid,
  theme,
} from 'core/components';
import React, { useState } from 'react';
import ClarificationResponseModal from 'pages/Patient/CaseCreator/ClarificationResponseModal';
import PhotoModal from 'pages/Patient/CaseCreator/PhotoModal';

import {
  AlertBody,
  AlertContainer,
  ImageIcon,
  LinkContainer,
  Title,
  StyledButton,
  StyledLink,
} from 'pages/Patient/CaseCreator/NeedsClarificationBanner.css';
import { FileInfo } from 'utils/materials';
import { LinkButton } from 'styles/inputs.css';
import { ResponseDate } from 'pages/OrthoPrism/NeedsClarificationAlert';
import {
  NeedsClarificationInfo,
  selectPendingClarificationResponse,
} from 'pages/Patient/patientSlice';
import moment from 'moment';
import { AlertTypeEnum } from 'pages/Patient/types';
import { useSelector } from 'react-redux';
import { CaseSource } from 'generated/core/graphql';

type NeedsClarificationBannerProps = {
  clarificationInfo: NeedsClarificationInfo;
  caseRef?: string | undefined;
  caseSource?: CaseSource | null | undefined;
  isEditable?: boolean;
};

type ResponseToSubmitCardProps = {
  responseInfo: NeedsClarificationInfo['responseInfo'];
  isEditable?: boolean;
  onEdit: () => void;
};

type DoctorResponseAvatarProps = {
  title: string;
  avatarContent: string;
  responseDate: string;
};

const DoctorResponseAvatar = ({
  title,
  avatarContent,
  responseDate,
}: DoctorResponseAvatarProps) => (
  <Grid container direction="row" style={{ paddingTop: '.75rem' }}>
    <Grid item style={{ paddingRight: '.75rem' }}>
      <Avatar style={{ backgroundColor: colors.blue50, paddingTop: '.25rem' }}>
        {avatarContent}
      </Avatar>
    </Grid>
    <Grid item>
      <Grid container direction="column">
        <Grid item>
          <Title>{title}</Title>
        </Grid>
        <Grid item>
          <ResponseDate>
            {responseDate ? `Responded on ${responseDate}` : ''}
          </ResponseDate>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const ResponseToSubmitCard = ({
  responseInfo,
  onEdit,
  isEditable,
}: ResponseToSubmitCardProps) => {
  const responseDate = responseInfo?.respondedAt
    ? moment(responseInfo?.respondedAt).format('ll')
    : '';
  const { userInfo } = useAuthContext();
  const doctorInitial = userInfo?.doctor?.fullName.split('')[0] || '';
  return (
    <>
      <Divider style={{ width: '100%', marginTop: '.3rem' }} />
      <DoctorResponseAvatar
        title={responseInfo?.respondedByEmail || ''}
        avatarContent={doctorInitial}
        responseDate={responseDate}
      />
      <div>{responseInfo?.response}</div>
      {isEditable && (
        <LinkButton
          type="reset"
          style={{ fontWeight: theme.fontWeights.bold }}
          color={theme.palette.text.main}
          onClick={onEdit}
        >
          Edit response
        </LinkButton>
      )}
    </>
  );
};

const NeedsClarificationBanner = ({
  clarificationInfo,
  caseRef,
  caseSource,
  isEditable = true,
}: NeedsClarificationBannerProps) => {
  const [isNCModalOpen, setNCIsModalOpen] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState<FileInfo | null>(
    null
  );
  const { question, attachments = [] } = clarificationInfo;

  const pendingClarificationResponse = useSelector(
    selectPendingClarificationResponse
  );

  const getResponseInfo = (
    savedResposneInfo: NeedsClarificationInfo | undefined,
    pendingResponseInfo: NeedsClarificationInfo | undefined | null
  ) => {
    return pendingResponseInfo?.responseInfo || savedResposneInfo?.responseInfo;
  };

  //If there's a pending response, use that, otherwise use the saved response
  const responseInfo = getResponseInfo(
    clarificationInfo,
    pendingClarificationResponse
  );

  return (
    <>
      <ClarificationResponseModal
        isOpen={isNCModalOpen}
        onClose={() => setNCIsModalOpen(false)}
        clarificationInfo={clarificationInfo}
        caseRef={caseRef}
        isCoreCase={caseSource === CaseSource.Core}
      />
      {!!attachments.length && (
        <PhotoModal
          isOpen={!!selectedAttachment}
          fileName={selectedAttachment?.filename ?? null}
          url={selectedAttachment?.url ?? null}
          onClose={() => setSelectedAttachment(null)}
        />
      )}
      <AlertContainer data-testid="needs-clarification-banner">
        <AlertCard type={AlertTypeEnum.Warning} displayIcon={false}>
          <AlertBody>
            <Title>We need clarification</Title>
            <div>{question}</div>
            {!!attachments.length && <Title>Attachments</Title>}
            {attachments.map((a: FileInfo) => {
              return (
                <LinkContainer>
                  <ImageIcon />
                  <StyledLink
                    onClick={() =>
                      setSelectedAttachment({
                        filename: a.filename,
                        url: a.url,
                      })
                    }
                  >
                    {a.filename}
                  </StyledLink>
                </LinkContainer>
              );
            })}
            {!responseInfo ? (
              <StyledButton
                data-testid="needs-clarification-banner-clarify-button"
                buttonType="black-outline"
                isShort={true}
                onClick={() => setNCIsModalOpen(true)}
              >
                Write a response
              </StyledButton>
            ) : (
              <ResponseToSubmitCard
                responseInfo={responseInfo}
                onEdit={() => setNCIsModalOpen(true)}
                isEditable={isEditable}
              />
            )}
          </AlertBody>
        </AlertCard>
      </AlertContainer>
    </>
  );
};

export default NeedsClarificationBanner;
