import React, { useMemo } from 'react';
import { useJourneyComponentsQuery } from 'generated/legacy/graphql';
import { FormikOption } from 'components/FormikForms/index';
import { FormikSelectWrapper } from 'components/FormikForms';
import {
  convertEnumToFormikOptions,
  convertJourneyComponentsToFormikOptions,
} from 'components/FormikForms/utils';
import { PROVIDER_FACING_STATUSES } from 'types/caseStatus';
import { SearchableInternalCaseStates } from 'types/Case';

export enum FilterStatus {
  InternalCaseState = 'INTERNAL',
  ProviderFacingStatus = 'provider_facing_status',
}

export type FilterType =
  | 'internal_status'
  | 'provider_facing_status'
  | 'journey';

export const getFilter = (filterType: FilterType) => {
  switch (filterType) {
    case 'internal_status':
      return InternalFilter;
    case 'journey':
      return JourneyFilter;
    case 'provider_facing_status':
      return ProviderFacingFilter;
  }
};

const InternalFilter = ({ onClear, onSelectChange }: SelectorProps) => {
  const options: FormikOption[] = convertEnumToFormikOptions(
    SearchableInternalCaseStates
  );

  return getSelector(onClear, onSelectChange, {
    label: 'Latest case status',
    name: FilterStatus.InternalCaseState,
    options,
    placeholder: 'Select',
  });
};

const JourneyFilter = ({ onClear, onSelectChange }: SelectorProps) => {
  const { data } = useJourneyComponentsQuery();

  const journeyStateOptions = useMemo(() => {
    if (!data) {
      return [];
    }

    return convertJourneyComponentsToFormikOptions(data.journeyComponents);
  }, [data]);

  return (
    <>
      {[
        {
          label: 'Journey state',
          name: 'journeyFormInput',
          options: journeyStateOptions,
          placeholder: 'Select',
        },
      ].map((selectorSettings) =>
        getSelector(onClear, onSelectChange, selectorSettings)
      )}
    </>
  );
};

const ProviderFacingFilter = ({ onClear, onSelectChange }: SelectorProps) => {
  const options: FormikOption[] = convertEnumToFormikOptions(
    PROVIDER_FACING_STATUSES
  );

  return getSelector(onClear, onSelectChange, {
    label: 'Latest case status',
    name: FilterStatus.ProviderFacingStatus,
    options,
    placeholder: 'Select',
  });
};

const getSelector = (
  onClear: () => void,
  onSelectChange: (value: FormikOption<string>) => void,
  {
    label,
    name,
    placeholder,
    options,
  }: {
    label: string;
    name: string;
    placeholder: string;
    options: FormikOption[];
  }
) => (
  <FormikSelectWrapper
    key={name}
    label={label}
    placeholder={placeholder}
    type={'select'}
    name={name}
    isClearable={true}
    options={options}
    onClear={onClear}
    onSelectChange={onSelectChange}
  />
);

type SelectorProps = {
  onClear: () => void;
  onSelectChange: (value: FormikOption<string>) => void;
};
