import React, { useEffect, useState } from 'react';
import { colors } from 'core/components';
import { LinearProgress } from '@material-ui/core';
import { useImmer } from 'use-immer';
import {
  type,
  theme,
  AlertCard,
  Text,
  Divider,
  Loading,
} from 'core/components';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLastSubmission,
  selectCurrentAggregate,
  selectCurrentPhotosByPhotoType,
  selectPrismState,
  uploadPhoto,
  uploadPhotos,
  selectHasMissingPhotoos,
  updatePhotoType,
  selectCanEditSubmission,
} from 'pages/Patient/patientSlice';
import { Spacer } from 'styles/layout.css';
import { searchDMPatientsURL } from 'utils/url';
import { CaseTypes } from 'generated/legacy/graphql';
import { useAuthContext } from 'context/AuthContext';
import { DEFAULT_LINKS } from 'utils/brands';
import { LegacyMaterialStates, PhotoStates } from 'types/Material';

import ExtraoralNoSmile from 'assets/photoTypes/extraoral_no_smile.svg';
import ExtraoralRightProfileNoSmile from 'assets/photoTypes/extraoral_right_profile_no_smile.svg';
import ExtraoralSmile from 'assets/photoTypes/extraoral_smile.svg';
import IntraoralFrontalViewWAligners from 'assets/photoTypes/intraoral_frontal_view_w_aligners.svg';
import IntraoralFrontalView from 'assets/photoTypes/intraoral_frontal_view.svg';
import IntraoralLeftLateralViewWAligners from 'assets/photoTypes/intraoral_left_lateral_view_w_aligners.svg';
import IntraoralLeftLateralView from 'assets/photoTypes/intraoral_left_lateral_view.svg';
import IntraoralLowerOcclusalWAligners from 'assets/photoTypes/intraoral_lower_occlusal_w_aligners.svg';
import IntraoralLowerOcclusal from 'assets/photoTypes/intraoral_lower_occlusal.svg';
import IntraoralRightLateralViewWAligners from 'assets/photoTypes/intraoral_right_lateral_view_w_aligners.svg';
import IntraoralRightLateralView from 'assets/photoTypes/intraoral_right_lateral_view.svg';
import IntraoralUpperOcclusalWAligners from 'assets/photoTypes/intraoral_upper_occlusal_w_aligners.svg';
import IntraoralUpperOcclusal from 'assets/photoTypes/intraoral_upper_occlusal.svg';
import useIsTouchDevice from 'hooks/useIsTouchDevice';

const PHOTO_TYPES: {
  [key in keyof typeof PRISM_PHOTO_VIEWS]: string;
} = {
  extraoral_no_smile: ExtraoralNoSmile,
  extraoral_right_profile_no_smile: ExtraoralRightProfileNoSmile,
  extraoral_smile: ExtraoralSmile,
  intraoral_frontal_view_w_aligners: IntraoralFrontalViewWAligners,
  intraoral_frontal_view: IntraoralFrontalView,
  intraoral_left_lateral_view_w_aligners: IntraoralLeftLateralViewWAligners,
  intraoral_left_lateral_view: IntraoralLeftLateralView,
  intraoral_lower_occlusal_w_aligners: IntraoralLowerOcclusalWAligners,
  intraoral_lower_occlusal: IntraoralLowerOcclusal,
  intraoral_right_lateral_view_w_aligners: IntraoralRightLateralViewWAligners,
  intraoral_right_lateral_view: IntraoralRightLateralView,
  intraoral_upper_occlusal_w_aligners: IntraoralUpperOcclusalWAligners,
  intraoral_upper_occlusal: IntraoralUpperOcclusal,
};

import {
  PRISM_PHOTO_VIEWS,
  PRISM_PHOTO_VIEW_TYPES,
  CUST_CREATOR_ERROR_MESSAGES,
} from 'types';
import { PhotoTypes } from 'generated/legacy/graphql';
import FileUpload from 'components/FileUpload';
import DownArrowIcon from 'assets/fi_arrow-down.svg?react';

import {
  RefinementsArrayContainer,
  ArrayContainer,
  ArrayItem,
  EditButton,
  DisplayImage,
  RejectedPhotoOverlay,
  StyledPencilIcon,
  StyledReUploadButton,
  StyledTooltip,
  TextHeader,
  UnderlinedLink,
  UploadBody,
  UploadHeader,
  UploadTextContainer,
  UploadForm,
  StyledAlertCard,
  InnerContent,
  EmptyText,
  StyledSparkle,
  FakeButton,
} from 'pages/Patient/CaseCreator/DiagnosticMaterials/Photos/Photos.css';
import {
  BottomPanel,
  SmallButton,
  BottonPannelText,
} from 'pages/Patient/styles.css';
import { AlertTypeEnum } from 'pages/Patient/types';

import { shouldIncludeWithAlignerPhotoType } from 'pages/Case/utils';
import { selectActiveCase, selectPatient } from 'pages/Patient/patientSlice';
import { AppDispatch } from 'state/store';
import { MaterialEditBanner } from 'pages/Patient/CaseCreator/DiagnosticMaterials/MaterialEditBanner';
import SmileCardStack from 'assets/smile-card-stack.svg?react';

import { updatePhoto } from 'pages/Prism/prismSlice';
import { getBrandSettings, getBrandFromDomain } from 'utils/brands';
import { useNotificationContext } from 'core/context/NotificationContext';

type PhotoView = keyof typeof PRISM_PHOTO_VIEWS;

type PhotoViewModel = {
  rejectionReason: string;
  fileSrc: string | null;
  isReadingFile: boolean;
  isRejected: boolean;
  ref: string | null;
  photoType: string;
  isBeingDraggedOver: boolean;
};

type PhotoViewModels = {
  [key: string]: PhotoViewModel;
};

const CenterTextArrayItem = ({ canEdit }: { canEdit: boolean }) => {
  const isTouchDevice = useIsTouchDevice();

  return (
    <UploadTextContainer canEdit={canEdit}>
      <UploadHeader>
        {`${isTouchDevice ? 'Tap' : 'Drag or click'}`} to upload
      </UploadHeader>
      <UploadBody>
        <strong>.jpeg</strong> or <strong>.png</strong> accepted
      </UploadBody>
    </UploadTextContainer>
  );
};

type PhotosTextProps = {
  includeWithAlignerPhotoType: boolean;
  email: string;
  caseType: string;
};
const PhotosText = ({
  includeWithAlignerPhotoType,
  email,
  caseType,
}: PhotosTextProps) => {
  const clinicalRequirementsURL =
    'https://drive.google.com/file/d/1J_Zi1p-Xw6Mb5M3PLLk5PA8cIl-OlbrC/view';
  const { monitoringLabel } = getBrandSettings(getBrandFromDomain());
  const { userInfo } = useAuthContext();
  const { photoExpectations } = userInfo?.brandInfo?.configs?.zendesk || {};

  if (caseType !== CaseTypes.ProAdditionalAligners) {
    return (
      <type.BodySmall>
        Bulk upload your photos or upload one-by-one to the ABO array below. See{' '}
        <type.SecondaryLink
          href={photoExpectations || DEFAULT_LINKS.PHOTO_EXPECTATIONS}
          target="_blank"
          rel="noreferrer noopener"
          style={{ color: theme.colors.blue }}
        >
          what we expect
        </type.SecondaryLink>{' '}
        from your photos.
      </type.BodySmall>
    );
  } else if (includeWithAlignerPhotoType) {
    return (
      <>
        <type.BodySmall>
          To make refinements, our team will need to receive new photos that
          represent the current state of the patient’s teeth. We require one
          full ABO array without aligners as well as occlusal and bite photos
          while wearing aligners. If the patient has been compliant,{' '}
          <UnderlinedLink
            href={`${searchDMPatientsURL()}${encodeURIComponent(email ?? '')}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            you can pull photos from {monitoringLabel}
          </UnderlinedLink>
          , as long as they meet our{' '}
          <UnderlinedLink href={clinicalRequirementsURL}>
            clinical requirements
          </UnderlinedLink>
          .
        </type.BodySmall>
        <TextHeader style={{ marginBottom: '1rem' }}>
          Without aligners
        </TextHeader>
      </>
    );
  }
  return null;
};

const WithAlignersHeader = () => (
  <>
    <Divider style={{ marginBottom: '1.5rem' }} />
    <TextHeader style={{ marginBottom: '1.5rem' }}>With aligners</TextHeader>
  </>
);

const WITH_ALIGNER_SUFFIX = 'w_aligners';

const BASE_PHOTO_TYPES = PRISM_PHOTO_VIEW_TYPES.filter(
  (view) => !view.includes(WITH_ALIGNER_SUFFIX)
);

const WITH_ALIGNER_PHOTO_TYPES = PRISM_PHOTO_VIEW_TYPES.filter((view) =>
  view.includes(WITH_ALIGNER_SUFFIX)
);

type PhotoEditBannerProps = {
  handleEditPhotos: () => void;
  allowEdit: boolean;
};

/**
 * Used when photos have been submitted and another material set requires clinician action
 * In order to edit photos at this point, the submission would need to be rejected to
 * reset the state for the aggregate to collection.
 */
const PhotoEditBanner = ({
  handleEditPhotos,
  allowEdit,
}: PhotoEditBannerProps) => {
  const lastPrismSubmission = useSelector(selectLastSubmission);

  if (!lastPrismSubmission || !lastPrismSubmission.stateData?.history) {
    return null;
  }
  const historyLength = lastPrismSubmission.stateData.history.length;
  const stateData = lastPrismSubmission.stateData.history[historyLength - 1];

  return (
    <MaterialEditBanner
      handleEdit={handleEditPhotos}
      materialTypeLabel="Photos"
      submitedDateTime={stateData.created}
      submitedBy={stateData.user?.email}
      allowEdit={allowEdit}
    />
  );
};

type PhotosProps = {
  onClickNext?: () => void;
};

const Photos = ({ onClickNext }: PhotosProps) => {
  const { showNotification } = useNotificationContext();
  const dispatch = useDispatch<AppDispatch>();
  const [isEditingAfterSubmission, setIsEditingAfterSubmission] =
    useState(false);
  const patient = useSelector(selectPatient);
  const activeCase = useSelector(selectActiveCase);
  const prismAggregate = useSelector(selectCurrentAggregate);
  const latestPrismSubmission = useSelector(selectLastSubmission);
  const currentPhotosByPhotoType = useSelector(selectCurrentPhotosByPhotoType);
  const prismState = useSelector(selectPrismState);
  const canEditSubmission = useSelector(selectCanEditSubmission);
  const caseRef = activeCase?.caseRef;
  const [isBulkUploading, setIsBulkUploading] = useState(false);
  const numberMissingPhotos = useSelector(selectHasMissingPhotoos);
  const isTouchDevice = useIsTouchDevice();

  const email = patient?.user?.email ?? '';
  const caseType = activeCase?.caseType?.name ?? '';
  const materialType = prismAggregate?.aggregateType?.name || '';
  const includeWithAlignerPhotoType =
    shouldIncludeWithAlignerPhotoType(materialType);

  const [draggedPhoto, setDraggedPhoto] = useState<PhotoViewModel | null>(null);

  /**
   * If no issues were found with this material in QC or Ortho review, but
   * another material set requires clinician action
   *
   * Use isMaterialSubmitted to prevent re-uploads unless explicitly
   * requested via canEditPostSubmission
   */
  const photoGatheringStates = [
    LegacyMaterialStates.NOT_STARTED,
    LegacyMaterialStates.COLLECTION,
    LegacyMaterialStates.EVALUATION,
    // rejected has it's own flow that allows updating photos without pressing
    // the edit button
    LegacyMaterialStates.REJECTED,
  ];
  type PhotoGatheringState = (typeof photoGatheringStates)[number];
  const isMaterialSubmitted = !photoGatheringStates.includes(
    prismState as PhotoGatheringState
  );
  const canEdit =
    (!isMaterialSubmitted || isEditingAfterSubmission) && canEditSubmission;

  const [photoViewModels, setPhotoViewModels] = useImmer<PhotoViewModels>(
    PRISM_PHOTO_VIEW_TYPES.reduce((acc, photoType) => {
      acc[photoType] = {
        isRejected: false,
        rejectionReason: '',
        fileSrc: null,
        isReadingFile: false,
        ref: null,
        photoType: photoType,
        isBeingDraggedOver: false,
      };
      return acc;
    }, {} as PhotoViewModels)
  );

  useEffect(() => {
    setPhotoViewModels((draft) =>
      PRISM_PHOTO_VIEW_TYPES.reduce((acc, photoType) => {
        const photoForView = currentPhotosByPhotoType[photoType];
        acc[photoType] = {
          ...acc[photoType],
          isRejected:
            photoForView?.stateData?.data === LegacyMaterialStates.REJECTED,
          rejectionReason: (photoForView?.rejectionReasons || [])
            .map((reject) => reject.label)
            .join(', '),
          fileSrc: photoForView?.photoUrl || null,
          ref: photoForView?.ref,
          photoType: photoType,
        };
        return acc;
      }, draft as PhotoViewModels)
    );
  }, [currentPhotosByPhotoType]);

  /**
   * Since the returning aggregate from uploading a photo doesn't have its state changed
   * (this is due the the is_uploaded flag being set async), we loosely check and force the state
   */
  const hackyGetAggregateStatePostUploadPhoto = (addedPhotoView: PhotoView) => {
    const photoTypesToCheck = includeWithAlignerPhotoType
      ? PRISM_PHOTO_VIEW_TYPES
      : BASE_PHOTO_TYPES;
    let isRejected = false;
    let isMissingPhoto = false;
    for (const photoType of photoTypesToCheck) {
      if (photoType === addedPhotoView) {
        // ignore new photo type for state because we just added it
        // so we know what the state is and the viewModel would not have updated by
        // the time this function runs
        continue;
      }
      if (!photoViewModels[photoType].fileSrc) {
        isMissingPhoto = true;
      } else if (photoViewModels[photoType].isRejected) {
        isRejected = true;
      }
    }
    if (isRejected) {
      return LegacyMaterialStates.REJECTED;
    }
    if (!isMissingPhoto) {
      return LegacyMaterialStates.EVALUATION;
    }
    return prismState;
  };

  const getCurrentPhotoStates = () => {
    const photosMap: { [key: string]: PhotoStates } = {};
    const photoTypesToCheck = includeWithAlignerPhotoType
      ? PRISM_PHOTO_VIEW_TYPES
      : BASE_PHOTO_TYPES;
    for (const photoType of photoTypesToCheck) {
      if (!photoViewModels[photoType].fileSrc) {
        photosMap[photoType] = PhotoStates.MISSING;
      } else if (photoViewModels[photoType].isRejected) {
        photosMap[photoType] = PhotoStates.REJECTED;
      } else {
        photosMap[photoType] = PhotoStates.UPLOADED;
      }
    }

    return photosMap;
  };

  const handleUploadPhoto = async (files: FileList | null, view: PhotoView) => {
    if (draggedPhoto) {
      setDraggedPhoto(null);
      return;
    }
    if (!prismAggregate) {
      showNotification(CUST_CREATOR_ERROR_MESSAGES.photo_submission, 'error');
      return;
    }

    if (files?.length !== 1) {
      showNotification('Only one photo can be uploaded at a time', 'error');
      return;
    }

    const [fileToUpload] = files;

    try {
      setPhotoViewModels((draft) => {
        draft[view].isReadingFile = true;
      });
      const newAggregateState = hackyGetAggregateStatePostUploadPhoto(view);
      await dispatch(
        uploadPhoto({
          file: fileToUpload,
          view: view as PhotoTypes,
          aggregateRef: prismAggregate.ref ?? '',
          newAggregateState,
        })
      ).unwrap();
    } catch (err) {
      showNotification(CUST_CREATOR_ERROR_MESSAGES.photo_upload, 'error');
    } finally {
      setPhotoViewModels((draft) => {
        draft[view].isReadingFile = false;
      });
    }
  };

  //Handler for when an image is getting dragged. Most of the logic in here is creating the dragged image
  const onDragStartHandler = (
    e: React.DragEvent<HTMLDivElement>,
    photo: PhotoViewModel | null
  ) => {
    //Make sure the section is populated
    if (!photo?.fileSrc) {
      return;
    }

    //Set the dragged photo
    setDraggedPhoto(photo);

    //Create the drag item, and add it to the dom
    const dragContainer = document.createElement('div');
    dragContainer.id = 'drag-container';

    const draggedImg = new Image(188, 142);
    draggedImg.src = photo.fileSrc;

    draggedImg.style.transform = 'rotate(-5deg)';

    // It's necessary to place the image used to "snapshot" the drag image far off-screen
    // so that other elements don't leak into the drag image when the snapshot is made.
    draggedImg.style.position = 'absolute';
    draggedImg.style.top = '-9999px';
    draggedImg.style.left = '-9999px';

    draggedImg.style.border = `1px solid ${colors.blue50}`;
    draggedImg.style.borderRadius = '4px';
    draggedImg.style.objectFit = 'cover';

    dragContainer.appendChild(draggedImg);
    document.body.appendChild(dragContainer);
    e.dataTransfer.setDragImage(dragContainer, 0, 0);

    // the browser only snapshots the drag image from the original element, so
    // the original element is not needed and should be removed immediately after the drag starts.
    // setTimeout is used to ensure the drag image is snapshotted before the original element is removed.
    setTimeout(() => {
      document.body.removeChild(dragContainer);
    }, 0);
  };

  //Hander for when a photo is being dropped in a valid location
  const onDropHandler = (photo: PhotoViewModel | null) => {
    if (draggedPhoto) {
      //DraggedPhoto
      dispatch(
        updatePhoto({
          photoRef: draggedPhoto.ref!,
          updates: {
            photoType: photo?.photoType as PhotoTypes,
          },
        })
      );
      dispatch(
        updatePhotoType({
          photoRef: draggedPhoto.ref!,
          aggregateRef: prismAggregate?.ref!,
          newPhotoTypep: photo?.photoType!,
        })
      );

      //Dropped photo
      if (photo?.fileSrc) {
        dispatch(
          updatePhoto({
            photoRef: photo.ref!,
            updates: {
              photoType: draggedPhoto?.photoType as PhotoTypes,
            },
          })
        );
        dispatch(
          updatePhotoType({
            photoRef: photo.ref!,
            aggregateRef: prismAggregate?.ref!,
            newPhotoTypep: draggedPhoto?.photoType!,
          })
        );
      }
      setDraggedPhoto(null);
    }
  };

  //Drag has ended without dropping anything. For example dropping in a non drop zone
  const onDragEndHandler = () => {
    const dragContainer = document.getElementById('drag-container');

    if (dragContainer) {
      document.body.removeChild(dragContainer);
    }
    setDraggedPhoto(null);
  };

  const handleUploadPhotos = async (files: FileList | null) => {
    if (!prismAggregate) {
      showNotification(CUST_CREATOR_ERROR_MESSAGES.photo_submission, 'error');
      return;
    }
    setIsBulkUploading(true);

    try {
      const photoStates = getCurrentPhotoStates();
      await dispatch(
        uploadPhotos({
          caseRef: caseRef!,
          files: Array.from(files ?? []),
          aggregateRef: prismAggregate.ref ?? '',
          photoStates,
          prismState,
        })
      ).unwrap();
    } catch (err) {
      showNotification(CUST_CREATOR_ERROR_MESSAGES.photo_upload, 'error');
    } finally {
      setIsBulkUploading(false);
    }
  };

  const renderUploadArrayItem = (photoType: PhotoView) => {
    const title = PRISM_PHOTO_VIEWS[photoType];

    const { fileSrc, rejectionReason, isReadingFile, isRejected } =
      photoViewModels[photoType];
    const helperImage = PHOTO_TYPES[photoType];
    let opacity = 1.0;

    if (!!draggedPhoto && draggedPhoto.photoType === photoType) {
      opacity = 0.2; //Update the source photo to make it clear it's being dragged
    } else if (!canEdit) {
      opacity = 0.5;
    }
    const BaseUploadArrayItem = (
      <ArrayItem
        key={photoType}
        title={title}
        showBorder={
          !!draggedPhoto && photoViewModels[photoType].isBeingDraggedOver
        }
        onDragStart={(e) => {
          //Element started being dragged
          onDragStartHandler(e, photoViewModels[photoType]);
        }}
        onDragEnd={() => {
          //Element is no longer being dragged, but wasn't dropped anywhere
          onDragEndHandler();
        }}
        onDrop={() => {
          //Element was dropped.
          onDropHandler(photoViewModels[photoType]);
        }}
        onDragOver={() => {
          //Element is being dragged over
          setPhotoViewModels((draft) => {
            draft[photoType].isBeingDraggedOver = true;
          });
        }}
        onDragLeave={() => {
          //Element stopped being dragged over
          setPhotoViewModels((draft) => {
            draft[photoType].isBeingDraggedOver = false;
          });
        }}
      >
        {isReadingFile ? (
          <Loading isCentered />
        ) : (
          <FileUpload
            data-testid={`photo-upload-${photoType}`}
            fileType=".jpg, .jpeg, .png"
            hideText
            hideDottedCircle
            isDisabled={isReadingFile || !canEdit}
            helperImageSrc={fileSrc ? undefined : helperImage}
            helperImgBackgroundSize="85% auto"
            image={
              !!fileSrc && (
                <DisplayImage alt="" src={fileSrc} opacity={opacity} />
              )
            }
            onSelectFile={(files) =>
              handleUploadPhoto(files, photoType as PhotoView)
            }
          />
        )}
        {!!fileSrc && canEdit && (
          <EditButton disabled={isReadingFile} type="button">
            <FileUpload
              data-testid={`photo-edit-upload-${photoType}`}
              fileType=".jpg, .jpeg, .png"
              hideText
              hideDottedCircle
              isDisabled={isReadingFile}
              image={<StyledPencilIcon />}
              onSelectFile={(files) =>
                handleUploadPhoto(files, photoType as PhotoView)
              }
            />
          </EditButton>
        )}
      </ArrayItem>
    );

    const RejectedArrayItem = (
      <ArrayItem key={photoType} title={title} showBorder={false}>
        <StyledTooltip
          title={<Text variant={'small'}>{rejectionReason}</Text>}
          placement="top"
        >
          <RejectedPhotoOverlay enabled>
            <StyledReUploadButton buttonType="primary" isShort>
              Replace
            </StyledReUploadButton>
            <FileUpload
              data-testid={`photo-upload-${photoType}`}
              fileType=".jpg, .jpeg, .png"
              hideText
              hideDottedCircle
              isDisabled={isReadingFile}
              showTextOverImage
              image={
                !!fileSrc && <DisplayImage alt="" src={fileSrc} opacity={0.5} />
              }
              onSelectFile={(files) =>
                handleUploadPhoto(files, photoType as PhotoView)
              }
            />
          </RejectedPhotoOverlay>
        </StyledTooltip>
      </ArrayItem>
    );

    return isRejected ? RejectedArrayItem : BaseUploadArrayItem;
  };

  return (
    <>
      {prismState === LegacyMaterialStates.REJECTED && (
        <>
          <AlertCard
            header="Note from Candid reviewer"
            displayIcon={false}
            type={AlertTypeEnum.Critical}
          >
            {latestPrismSubmission?.rejectionNotes ||
              'We have a few issues with your photos. Please replace all the highlighted photos as soon as possible.'}
          </AlertCard>
          <Spacer spacing="2rem" isVertical />
          <type.BodySmall style={{ paddingTop: '1rem' }}>
            Please re-upload the photos highlighted in red
          </type.BodySmall>
        </>
      )}
      {canEdit ? (
        <PhotosText
          includeWithAlignerPhotoType={includeWithAlignerPhotoType}
          email={email}
          caseType={caseType}
        />
      ) : (
        <PhotoEditBanner
          handleEditPhotos={() => setIsEditingAfterSubmission(true)}
          allowEdit={canEditSubmission}
        />
      )}
      {canEdit && (
        <>
          {!!isBulkUploading && (
            <LinearProgress
              color="secondary"
              style={{ marginBottom: '16px' }}
            />
          )}

          {numberMissingPhotos > 0 && (
            <StyledAlertCard type="critical" displayCloseButton>
              We were unable to auto-place some of these photos. Please place
              them in the array.
            </StyledAlertCard>
          )}
          <UploadForm>
            <FileUpload
              allowMultipleFiles={true}
              fileType=".jpg, .jpeg, .png"
              isDisabled={isBulkUploading}
              onSelectFile={(files) => handleUploadPhotos(files)}
              customInnerContent={
                <InnerContent>
                  <SmileCardStack />
                  {/* This is a div meant to look like a button. The reason we're not using a real button here, is the button's 
                  on click event overrides the file upload's click event.  */}
                  <FakeButton>
                    Smart bulk upload
                    <StyledSparkle />
                  </FakeButton>
                  <EmptyText>
                    {`${isTouchDevice ? 'Upload' : 'Drag'}`} some or all of your
                    diagnostic photos here. We’ll auto-detect the array position
                    using our new Smart Bulk Upload AI.
                  </EmptyText>
                </InnerContent>
              }
            />
          </UploadForm>
        </>
      )}
      <ArrayContainer>
        {/* NOTE: we need to render this as a function (vs <UploadArrayItem />)
         *       to allow multiple files to be uploaded in each item component
         */}
        {BASE_PHOTO_TYPES.map((photoType) =>
          renderUploadArrayItem(photoType as PhotoView)
        )}
        <CenterTextArrayItem canEdit={canEdit} />
      </ArrayContainer>
      {includeWithAlignerPhotoType && (
        <>
          <WithAlignersHeader />
          <RefinementsArrayContainer>
            {/* NOTE: we need to render this as a function (vs <UploadArrayItem />)
             *       to allow multiple files to be uploaded in each item component
             */}
            {WITH_ALIGNER_PHOTO_TYPES.map((photoType) =>
              renderUploadArrayItem(photoType as PhotoView)
            )}
            <CenterTextArrayItem canEdit={canEdit} />
          </RefinementsArrayContainer>
        </>
      )}

      <BottomPanel>
        <BottonPannelText>All 8 photos required</BottonPannelText>
        {!!onClickNext && (
          <SmallButton rightIcon={<DownArrowIcon />} onClick={onClickNext}>
            Next material
          </SmallButton>
        )}
      </BottomPanel>
    </>
  );
};

export default Photos;
