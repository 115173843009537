export enum Environment {
  QA = 'qa',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
  LOCALHOST = 'localhost',
}

export const getEnvironment = (): Environment => {
  return import.meta.env.VITE_REACT_APP_ENV as Environment;
};
