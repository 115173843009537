import React from 'react';
import styled from 'styled-components/macro';
import { type } from 'core/components';

import { Dropdown } from 'components/Dropdown/Dropdown';
import { useDispatch } from 'react-redux';
import {
  selectScansFromLatestSubmission,
  selectCustomer,
  selectSelectedCase,
  fetchTreatmentPlanStagings,
  selectLatestTreatmentPlanStaging,
  fetchCases,
} from 'pages/OrthoPrism/orthoSlice';
import {
  TreatmentPlanningSoftware,
  TreatmentPlanStagingStates,
  MaterialEvaluationTypes,
  AddMaterialEvaluationsDocument,
  AddMaterialEvaluationsMutation,
  AddMaterialEvaluationsMutationVariables,
  CaseSoftwareChoice,
} from 'generated/core/graphql';
import { useGQLMutation } from 'hooks/useGQL';
import { useNotificationContext } from 'core/context/NotificationContext';

import { useSelector } from 'react-redux';
import useAddTreatmentPlanStaging from 'hooks/useAddTreatmentPlanStaging';

const Body = styled.div<{
  rightMargin?: boolean;
}>`
  text-align: right;
  margin-right: ${({ rightMargin }) => rightMargin && '1.5rem'};
`;

const ActionLink = styled(type.Link)<{
  disabled?: boolean;
}>`
  font-weight: 300;
  color: ${({ disabled }) => (disabled ? 'gray' : '')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  &:hover {
    cursor: pointer;
  }
`;

const StagingActions = () => {
  // Only show staging actions in dev environment
  if (import.meta.env.VITE_REACT_APP_ENV === 'production') {
    return null;
  }
  const dispatch = useDispatch();
  const { showNotification } = useNotificationContext();
  const selectedCase = useSelector(selectSelectedCase);
  const customer = useSelector(selectCustomer);
  const scans = useSelector(selectScansFromLatestSubmission);
  const treatmentPlanStaging = useSelector(selectLatestTreatmentPlanStaging);
  const canAddTreatmentPlan =
    !treatmentPlanStaging ||
    (treatmentPlanStaging?.state ?? '') in
      [
        TreatmentPlanStagingStates.OrthoRejected,
        TreatmentPlanStagingStates.QcRejected,
      ];
  const canAcceptAsQc =
    treatmentPlanStaging?.state === TreatmentPlanStagingStates.QcReview;
  const canAcceptAsOrtho =
    treatmentPlanStaging?.state === TreatmentPlanStagingStates.OrthoReview;

  const [addTreatmentPlanStaging, { isLoading: addingTP }] =
    useAddTreatmentPlanStaging();

  const [addMaterialEvaluations, { loading: approvingTP }] = useGQLMutation<
    AddMaterialEvaluationsMutation,
    AddMaterialEvaluationsMutationVariables
  >(AddMaterialEvaluationsDocument, true);

  const handleCreateTreatmentPlan = async () => {
    if (!selectedCase || !customer || !scans || scans.length < 2) {
      showNotification('Please confirm that 2 scans are uploaded', 'error');
      return;
    }
    try {
      const selectedScans = scans.filter((scan) =>
        scan.materialType.name.includes('scan')
      );
      const patientId = parseInt(customer.id);
      if (!patientId) {
        return;
      }
      let software;
      if (
        selectedCase?.caseSoftware === CaseSoftwareChoice.Vision ||
        selectedCase?.caseSoftware === null
      ) {
        software = TreatmentPlanningSoftware.Vision;
      } else {
        software = TreatmentPlanningSoftware.Archform;
      }
      await addTreatmentPlanStaging({
        caseRef: selectedCase.caseRef,
        caseType:
          selectedCase.caseType.name === 'aligner' ? 'aligner' : 'retainer',
        lowerScanId: selectedScans[0].id,
        patientId,
        software,
        upperScanId: selectedScans[1].id,
      });

      dispatch(
        fetchCases({
          patientIds: [parseInt(customer?.id)],
        })
      );
      dispatch(fetchTreatmentPlanStagings({ caseRef: selectedCase.caseRef }));
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }
      showNotification(err.message, 'error');
    }
  };

  const approveTpAs = async (personel: string) => {
    if (!selectedCase || !customer || !treatmentPlanStaging) {
      showNotification('Please check if all materials is loaded', 'error');
      return;
    }
    const materialEvaluationType =
      personel === 'qc'
        ? MaterialEvaluationTypes.QualityControlEvaluation
        : MaterialEvaluationTypes.OrthodonticEvaluation;
    const materialEvaluationsInput = {
      approved: true,
      data: {},
      materialEvaluationType,
      materialId: treatmentPlanStaging?.id,
      rejectionReasons: [],
      repairable: false,
    };

    const materialEvaluation: AddMaterialEvaluationsMutationVariables = {
      caseRef: selectedCase?.caseRef,
      patientId: parseInt(customer?.id),
      annotatedFileLocations: [],
      materialEvaluationsInput: [materialEvaluationsInput],
    };
    try {
      await addMaterialEvaluations(materialEvaluation);
      dispatch(fetchTreatmentPlanStagings({ caseRef: selectedCase.caseRef }));
      dispatch(
        fetchCases({
          patientIds: [parseInt(customer?.id)],
        })
      );
      showNotification(`Treatment plan approved as ${personel}`, 'success');
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }
      showNotification(err.message, 'error');
    }
  };

  const loading = addingTP || approvingTP;

  return (
    <div>
      <Body rightMargin>
        <Dropdown
          showCaret
          title={
            <ActionLink data-testid="action-link">
              {loading ? `Loading` : `Staging actions`}
            </ActionLink>
          }
        >
          <ActionLink
            data-testid="action-link"
            onClick={() => canAddTreatmentPlan && handleCreateTreatmentPlan()}
            disabled={!canAddTreatmentPlan}
          >
            Add treatment plan
          </ActionLink>

          <ActionLink
            data-testid="action-link"
            onClick={() => canAcceptAsQc && approveTpAs('qc')}
            disabled={!canAcceptAsQc}
          >
            Approve as qc
          </ActionLink>

          <ActionLink
            data-testid="action-link"
            onClick={() => canAcceptAsOrtho && approveTpAs('ortho')}
            disabled={!canAcceptAsOrtho}
          >
            Approve as ortho
          </ActionLink>
        </Dropdown>
      </Body>
    </div>
  );
};

export default StagingActions;
