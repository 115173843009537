import React from 'react';
import { Heading } from 'core/components';
import {
  StyledSuccessImage,
  SuccessButtons,
  SuccessMessage,
  ButtonRow,
  ModalContent,
} from 'pages/PracticeAccountCreator/PracticeAccountCreator.css';
import SuccessImage from 'assets/no-patients.png';
import FullPageModal from 'components/FullPageModal/FullPageModal';

type SuccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
  practiceId?: string;
};

const SuccessModal = ({ isOpen, onClose, practiceId }: SuccessModalProps) => {
  const handleGoToPracticeMgmt = () => {
    window.location.href = `/management/practice/${practiceId}`;
  };

  const handleCreateNewPractice = () => {
    window.location.reload();
  };

  return (
    <FullPageModal isOpen={isOpen} hideFooter={true} onClose={onClose}>
      <ModalContent>
        <StyledSuccessImage src={SuccessImage} />
        <Heading variant={'h4'}>
          Practice and doctor created successfully!
        </Heading>
        <SuccessMessage>
          You’ve successfully created a new doctor and practice. The doctor will
          receive a welcome email with a link to set their password shortly.
        </SuccessMessage>

        <ButtonRow>
          <SuccessButtons
            buttonType="secondary"
            onClick={handleGoToPracticeMgmt}
          >
            Visit Practice Management
          </SuccessButtons>
          <SuccessButtons
            buttonType="secondary-outline"
            onClick={handleCreateNewPractice}
          >
            Create a new practice
          </SuccessButtons>
        </ButtonRow>
      </ModalContent>
    </FullPageModal>
  );
};

export default SuccessModal;
