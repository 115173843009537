import {
  ApolloCache,
  ApolloQueryResult,
  DocumentNode,
  MutationOptions,
  OperationVariables,
} from '@apollo/client';

import { coreClient } from 'gql/clients';

/* eslint eslint-comments/no-use: off */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * [MAR]: Why is this override acceptable?
 *
 * This is to provide an all-purpose value that can be used when using
 * `skip: true` on a query. This is a common pattern in Apollo Client
 * and one would otherwise have to provide all arguments to have valid
 * input (which may not be possible; this is the point, we're not ready,
 * so we skip) or would have to explicitly `as any` in every individual
 * skip situation, leading to one additional `any` warning per use all
 * because Apollo Client doesn't have more finegrained typing support
 * for this usage.
 */
type SkipQueryType = any;
export const SkipQuery: SkipQueryType = {};
/* eslint-enable */

/**
 * Decorator for an Apollo Client mutate function for use in the API (state/api) as a RTK Query.
 *
 * @param mutateFn  The Apollo Client mutate function
 * @param lens  The key in the data object that we want to extract
 * @param mutation  The mutation document (gql``)
 * @returns  A function that will call the mutate function and return the extracted data
 */
export const mutateDecorator = <
  TData,
  TVariables extends OperationVariables,
  TabContextProps extends Record<string, unknown>,
>(
  mutateFn: typeof coreClient.mutate<
    TData,
    TVariables,
    TabContextProps,
    ApolloCache<unknown>
  >,
  lens: keyof TData,
  mutation: DocumentNode
) => {
  type DataLens = TData[typeof lens];

  return async (
    variables: MutationOptions<
      TData,
      TVariables,
      TabContextProps,
      ApolloCache<unknown>
    >['variables']
  ): Promise<{ data: DataLens }> => {
    const { data, errors } = await mutateFn({
      mutation,
      variables,
    });

    if (errors) {
      return Promise.reject({
        status: 'CUSTOM_ERROR',
        error: errors[0].message,
      });
    }

    if (!data) {
      return Promise.reject({
        status: 'CUSTOM_ERROR',
        error: 'No data returned from mutation',
      });
    }

    return { data: data[lens] };
  };
};

/**
 * Decorator for an Apollo Client query function for use in the API (state/api) as a RTK Query.
 *
 * @param queryFn  The Apollo Client query function
 * @param lens  The key in the data object that we want to extract
 * @param query  The query document (gql``)
 * @returns  A function that will call the query function and return the extracted data
 */
export const queryDecorator = <TQuery, TVariables extends OperationVariables>(
  queryFn: typeof coreClient.query<TQuery, TVariables>,
  lens: keyof ApolloQueryResult<TQuery>['data'],
  query: DocumentNode
) => {
  type DataLens = ApolloQueryResult<TQuery>['data'][typeof lens];
  return async (variables: TVariables): Promise<{ data: DataLens }> => {
    const { data, error, errors } = await queryFn({
      query,
      variables,
    });

    if (error || errors) {
      return Promise.reject({
        status: 'CUSTOM_ERROR',
        error: error?.message || errors?.[0].message,
      });
    }

    if (!data) {
      return Promise.reject({
        status: 'CUSTOM_ERROR',
        error: 'No data returned from query',
      });
    }

    return { data: data[lens] };
  };
};
