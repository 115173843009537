import React from 'react';
import { Modal } from 'core/components';
import OrderShipping from 'components/Modals/OrderShippingModal/OrderShipping';

export type OrderShippingModalProps = {
  onClose: () => void;
  caseRef?: string | null;
  isOpen: boolean;
  patientName: string;
  internal: boolean;
};

const OrderShippingModal = ({
  isOpen,
  onClose,
  caseRef,
  patientName,
  internal,
}: OrderShippingModalProps) => {
  if (!caseRef) {
    return null;
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <OrderShipping
        internal={internal}
        caseRef={caseRef}
        patientName={patientName}
      />
    </Modal>
  );
};

export default OrderShippingModal;
