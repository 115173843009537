import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { FileInput } from 'styles/inputs.css';
import PlusCircleSVG from 'assets/dashed-circle-plus.svg?react';
import {
  Container,
  EmptyText,
  ImageTextWrap,
  Wrapper,
} from 'components/FileUpload/FileUpload.css';
import { useNotificationContext } from 'core/context/NotificationContext';
import {
  FILE_SIZE_LIMIT,
  FILE_SIZE_LIMIT_ERROR_MESSAGE,
  EMPTY_FILE_MESSAGE,
} from 'types';

type FileUploadProps = {
  allowMultipleFiles?: boolean;
  fileType?: string;
  helperImageSrc?: string;
  helperImgBackgroundSize?: string;
  hideText?: boolean;
  hideDottedCircle?: boolean;
  image?: any; // takes an image to be displayed
  isDisabled?: boolean;
  isHorizontal?: boolean;
  onSelectFile: (files: FileList | null) => void;
  showTextOverImage?: boolean;
  testId?: string;
  textOverride?: string;
  limitFileSize?: boolean;
  customInnerContent?: React.ReactNode;
};

const FileUpload = ({
  allowMultipleFiles = false,
  fileType = 'image/*',
  helperImageSrc = '',
  helperImgBackgroundSize = '',
  hideText = false,
  hideDottedCircle = false,
  image = '',
  isDisabled = false,
  isHorizontal = false,
  onSelectFile,
  showTextOverImage = false,
  testId = '',
  textOverride = '',
  limitFileSize = true,
  customInnerContent = null, //By default all file upload will have the same inner content, text with an icon, but if you want to customize it, you can pass in a custom element
}: FileUploadProps) => {
  const { showNotification } = useNotificationContext();
  const [isDropping, setIsDropping] = useState(false);
  const emptyText = textOverride || 'Drag or click to upload';
  const handleInputs = (
    data: DataTransfer | (EventTarget & HTMLInputElement)
  ) => {
    if (data.files) {
      if (limitFileSize && !checkFileSize(data.files)) {
        return;
      }
      const filesNames = [...data.files].map((file) => file.name);
      const filesTypes = [...data.files].map((file) => file.type);
      const errorMessage = `File type not supported. Please re-upload with a ${fileType} file type.`;
      const acceptExts = fileType
        .split(',')
        .map((type) => type.trim().split('.').pop() ?? '*');
      const acceptTypes = fileType
        .split(',')
        .map((type) => (type.includes('/') ? type.split('/')[0]?.trim() : '*'));

      if (
        fileType.trim() === '.stl' &&
        !checkFileExtension(filesNames, acceptExts)
      ) {
        // Special handling for .stl file, since input file can not capture the stl file type
        showNotification(errorMessage, 'error');
        return;
      } else if (
        !checkFileType(filesTypes, acceptTypes) ||
        !checkFileExtension(filesNames, acceptExts)
      ) {
        showNotification(errorMessage, 'error');
        return;
      }

      onSelectFile(data.files);
    }

    // Clear file from input to ensure onChange fires twice
    if ('value' in data) {
      data.value = '';
    }
  };

  const checkFileSize = (files: FileList | null) => {
    if (files) {
      const filesSizes = [...files].map((file) => file.size);
      if (filesSizes.some((size) => size > FILE_SIZE_LIMIT)) {
        showNotification(FILE_SIZE_LIMIT_ERROR_MESSAGE, 'error');
        Sentry.captureException(
          `Large file over ${
            FILE_SIZE_LIMIT / 1024 / 1024
          } MB upload was attempted`
        );
        return false;
      }

      if (filesSizes.some((size) => size === 0)) {
        showNotification(EMPTY_FILE_MESSAGE, 'error');
        Sentry.captureException(`Empty file upload was attempted`);
        return false;
      }
    }
    return true;
  };

  const checkFileType = (fileTypes: string[], acceptTypes: string[]) => {
    if (acceptTypes.some((type) => type?.includes('*'))) {
      return true;
    }
    return fileTypes.every((fileType) =>
      acceptTypes.some((acceptedType) =>
        acceptedType?.includes(fileType.split('/')[0])
      )
    );
  };

  const checkFileExtension = (fileNames: string[], acceptExts: string[]) => {
    // Handle widecard '*' on the accepted type. ex: image/*
    if (acceptExts.some((ext) => ext?.includes('*'))) {
      return true;
    }
    return fileNames.every((fileName) =>
      acceptExts.some((acceptedExt) =>
        acceptedExt?.includes(fileName.toLowerCase().split('.').pop() ?? '')
      )
    );
  };

  const handleDropFile = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDropping(false);
    handleInputs(e.dataTransfer);
  };

  const renderUploadText = () => {
    return (
      <>
        {!hideDottedCircle && <PlusCircleSVG aria-hidden />}
        {!hideText && (
          <EmptyText isHorizontal={isHorizontal}>{emptyText}</EmptyText>
        )}
      </>
    );
  };

  const innerContent = () => {
    if (customInnerContent) {
      return customInnerContent;
    } else {
      return (
        <>
          {image || renderUploadText()}
          {showTextOverImage && !!image && (
            <ImageTextWrap>{renderUploadText()}</ImageTextWrap>
          )}
        </>
      );
    }
  };

  return (
    <Wrapper>
      <Container
        helperImageSrc={helperImageSrc}
        helperImgBackgroundSize={helperImgBackgroundSize}
        isDisabled={isDisabled}
        isDropping={isDropping}
        isHorizontal={isHorizontal}
        onDragEnter={() => setIsDropping(true)}
        onDragLeave={() => setIsDropping(false)}
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDropFile}
        data-testid={testId}
      >
        {innerContent()}
        <FileInput
          data-testid={`${testId}-input`}
          accept={fileType}
          multiple={allowMultipleFiles}
          onChange={(e) => handleInputs(e.currentTarget)}
          type="file"
        />
      </Container>
    </Wrapper>
  );
};

export default FileUpload;
