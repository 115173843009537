import React, { useState } from 'react';
import { AlertCard, Grid } from 'core/components';
import {
  MaterialFragment,
  MaterialEvaluationFragment,
  MaterialEvaluationSetFragment,
} from 'pages/Patient/patientSlice';
import PhotoModal from 'pages/Patient/CaseCreator/PhotoModal';
import {
  AnnotatedAttachment,
  AttachmentHeader,
  StatusBadge,
  LinksContainer,
  LinkContainer,
  LinkItem,
  LinkTitle,
  EvaluationNoteText,
  IconContainer,
  AttachmentContainer,
  AttachmentsContainer,
} from 'pages/Patient/CaseCreator/RejectedMaterialsAlert.css';
import DownloadFileIcon from 'assets/download-file.svg?react';
import { Materials } from 'types/Material';
import { Maybe } from 'graphql/jsutils/Maybe';
import { AlertTypeEnum } from 'pages/Patient/types';

type RejectedAlertProps = {
  materialClassification: string;
  materials: MaterialFragment[];
  rejectedEvaluation: MaterialEvaluationFragment;
  rejectedEvaluationSet: MaterialEvaluationSetFragment;
};

type FileInfo = {
  filename?: Maybe<string>;
  url?: Maybe<string>;
};

const RejectedMaterialsAlert = ({
  materialClassification,
  materials,
  rejectedEvaluation,
  rejectedEvaluationSet,
}: RejectedAlertProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [currentModalFile, setCurrentModalFile] = useState<FileInfo>();

  const isScan = materialClassification === Materials.Scans;

  const getRejectedInfo = () => {
    const evaluatedMaterials = materials.filter((x) =>
      x.materialEvaluations.some(
        (me) => me.evaluationSet?.id === rejectedEvaluation.evaluationSet?.id
      )
    );

    const rejectionReasonLabels: string[] =
      rejectedEvaluation?.rejectionReasons || [];
    const evaluationNotes: string =
      rejectedEvaluation?.data?.evaluationNotes || '';

    return {
      rejectedFiles: evaluatedMaterials,
      rejectionReasonLabels,
      evaluationNotes,
      annotatedFiles: rejectedEvaluationSet?.annotatedFiles || [],
    };
  };

  const {
    rejectedFiles,
    rejectionReasonLabels,
    evaluationNotes,
    annotatedFiles,
  } = getRejectedInfo();

  return (
    <AlertCard type={AlertTypeEnum.Critical}>
      <Grid container direction="row">
        {rejectionReasonLabels.map((reason, i) => {
          return <StatusBadge key={i}>{reason}</StatusBadge>;
        })}
      </Grid>
      <Grid container direction="column">
        <LinksContainer>
          <LinkTitle>Rejected files:</LinkTitle>

          {rejectedFiles?.map((file: FileInfo, i) => {
            if (isScan) {
              return (
                <LinkContainer key={i}>
                  <LinkItem href={file.url || ''}>{file.filename}</LinkItem>
                </LinkContainer>
              );
            } else {
              return (
                <LinkContainer
                  key={i}
                  onClick={() => {
                    setCurrentModalFile(file);
                    setModalOpen(true);
                  }}
                  data-testid="link-container"
                >
                  <LinkItem>{file.filename}</LinkItem>
                </LinkContainer>
              );
            }
          })}
        </LinksContainer>
      </Grid>

      <EvaluationNoteText>{evaluationNotes}</EvaluationNoteText>

      {!!annotatedFiles.length && (
        <>
          <AttachmentHeader data-testid="attachments-header">
            Attachments:
          </AttachmentHeader>
          <AttachmentsContainer>
            {annotatedFiles.map((file, index) => {
              const i = index.toString();
              return (
                <AttachmentContainer key={i}>
                  <IconContainer>
                    <DownloadFileIcon />
                  </IconContainer>
                  <AnnotatedAttachment
                    onClick={() => {
                      setCurrentModalFile(file);
                      setModalOpen(true);
                    }}
                    data-testid={`annotated-photo-${i}`}
                  >
                    {file.filename}
                  </AnnotatedAttachment>
                </AttachmentContainer>
              );
            })}
          </AttachmentsContainer>
        </>
      )}

      {currentModalFile && (
        <PhotoModal
          isOpen={modalOpen}
          fileName={currentModalFile.filename || ''}
          url={currentModalFile.url || ''}
          onClose={() => {
            setModalOpen(false);
            setCurrentModalFile(undefined);
          }}
        />
      )}
    </AlertCard>
  );
};

export default RejectedMaterialsAlert;
