import React from 'react';
import styled from 'styled-components/macro';
import { Button, Modal, colors } from 'core/components';

import { Spacer } from 'styles/layout.css';

const ConfirmDialogContainer = styled.div`
  margin: 2rem;
  min-width: 400px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const StyledButton = styled(Button)<{ fontColor?: string }>`
  &:not(:disabled) {
    background-color: ${({ color }) => color};
    color: ${({ fontColor = colors.white }) => fontColor};

    &:hover {
      background-color: ${({ color }) => color};
      color: ${({ fontColor = colors.white }) => fontColor};
      filter: brightness(90%);
    }
    &:active {
      background-color: ${({ color }) => color};
      color: ${({ fontColor = colors.white }) => fontColor};
      filter: brightness(75%);
    }
  }
`;

type ConfirmDialogProps = {
  testId?: string;
  children: any;
  onClose?: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading?: boolean;
  isOpen: boolean;
  cancelButtonText?: string;
  showCancelButton?: boolean;
  confirmButtonText?: string;
  showCloseButton?: boolean;
  isConfirmDisabled?: boolean;
};

const ConfirmDialog = ({
  testId,
  children,
  isLoading = false,
  isOpen,
  onClose,
  onConfirm,
  onCancel,
  cancelButtonText = 'Cancel',
  showCancelButton = true,
  confirmButtonText = 'Confirm',
  showCloseButton = false,
  isConfirmDisabled = false,
}: ConfirmDialogProps) => {
  return (
    <Modal
      isOpen={isOpen}
      overlayColor="rgba(0, 0, 0, 0.73)"
      hideCloseButton={!showCloseButton}
      onClose={onClose || onCancel}
    >
      <ConfirmDialogContainer>
        <Spacer spacing="2rem" isVertical>
          <div>{children}</div>
          <ButtonContainer>
            <Spacer spacing="1rem">
              <StyledButton
                testId={testId}
                color={colors.blue}
                disabled={isLoading || isConfirmDisabled}
                isLoading={isLoading}
                onClick={onConfirm}
              >
                {confirmButtonText}
              </StyledButton>
              {showCancelButton && (
                <StyledButton
                  color={colors.blue10}
                  disabled={isLoading}
                  fontColor={colors.black90}
                  onClick={onCancel}
                >
                  {cancelButtonText}
                </StyledButton>
              )}
            </Spacer>
          </ButtonContainer>
        </Spacer>
      </ConfirmDialogContainer>
    </Modal>
  );
};

export default ConfirmDialog;
