import axios, { AxiosPromise } from 'axios';
import * as Sentry from '@sentry/react';

import {
  getAuthToken,
  getRefreshToken,
  removeAuthGroups,
  removeTokens,
  setTokens,
} from 'utils/auth';
import { RestMethod } from 'utils/api-types';
import { StringMap } from 'utils/types';

const logOut = () => {
  removeTokens();
  removeAuthGroups();
  window.location.pathname = '/login';
};

interface NewTokenPayload {
  access_token: string;
  refresh_token: string;
}

interface NewTokenResponse extends NewTokenPayload {
  fromExisting: boolean;
}

// The `getNewToken` function may be called by both graphql provider
// and fetchApi when making calls to REST endpoints.
// Below we enure that in a case of simultaneous calls to `getNewToken`,
// the second call to `jwt-auth/refresh/` will await the first one
// and both return the same new tokens.
let isGettingNewToken = false;
let newTokenPromise: AxiosPromise<NewTokenPayload>;

export const getNewToken = async (): Promise<NewTokenResponse> => {
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    logOut();
  }

  if (isGettingNewToken) {
    // await the same promise to get the same token
    const { data } = await newTokenPromise;

    return {
      ...data,
      fromExisting: true,
    };
  }

  try {
    isGettingNewToken = true;
    newTokenPromise = axios({
      method: 'POST',
      url: `${import.meta.env.VITE_REACT_APP_API_LEGACY}/api/v1/jwt-auth/refresh/`,
      data: {
        refresh: refreshToken,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const { data } = await newTokenPromise;
    isGettingNewToken = false;

    return {
      ...data,
      fromExisting: false,
    };
  } catch (err) {
    logOut();
    throw err;
  }
};

export const fetchApi = async (
  path: string,
  method: RestMethod,
  body: any = {},
  config: StringMap = {}
): Promise<any> => {
  const token = getAuthToken();

  try {
    const { data } = await axios({
      method,
      url: import.meta.env.VITE_REACT_APP_API_LEGACY + path,
      data: body,
      headers: {
        'Content-Type': 'application/json',
        ...(token && {
          authorization: `JWT ${token}`,
        }),
      },
      ...config,
    });

    return data;
  } catch (err: any) {
    const {
      data: { code = null },
      status,
    } = err.response;

    if (status === 401 && code === 'token_not_valid') {
      const res = await getNewToken();
      // Set tokens if they would not be set elsewhere
      if (!res.fromExisting) {
        setTokens(res.access_token, res.refresh_token);
      }

      return await fetchApi(path, method, body, config);
    }

    throw err;
  }
};

export const reportError = (err: any) => {
  Sentry.captureException(err);

  return Promise.reject(err);
};

interface LearnWorldsLoginParams {
  email: string;
  name: string;
  avatar?: string;
  redirectUrl?: string;
}

interface LearnWorldsLoginResponse {
  success: boolean;
  url: string;
  user_id: string;
}

/**
 * Initiates a LearnWorlds SSO login for the specified user
 * @param params User information for the SSO login
 * @returns The URL to redirect the user to
 */
export async function initiateLearnWorldsLogin(
  params: LearnWorldsLoginParams
): Promise<string> {
  // Get the LearnWorlds domain from environment variable
  const LEARNWORLDS_DOMAIN = import.meta.env.VITE_REACT_APP_LEARNWORLDS_DOMAIN;

  if (!LEARNWORLDS_DOMAIN) {
    throw new Error('LearnWorlds domain is not configured');
  }

  // Exposing these in frontend code is a security risk
  const CLIENT_ID = import.meta.env.VITE_REACT_APP_LEARNWORLDS_CLIENT_ID;
  const ACCESS_TOKEN = import.meta.env.VITE_REACT_APP_LEARNWORLDS_ACCESS_TOKEN;

  if (!CLIENT_ID || !ACCESS_TOKEN) {
    throw new Error('LearnWorlds client ID or access token is not configured');
  }

  // Split name into first and last name for username
  const nameParts = params.name.split(' ');
  const firstName = nameParts[0];
  const lastName = nameParts.length > 1 ? nameParts.slice(1).join(' ') : '';
  const username = `${firstName}${lastName ? ` ${lastName}` : ''}`;

  // Prepare the request data
  const requestData = {
    email: params.email,
    username: username,
    // Add avatar if provided
    ...(params.avatar && { avatar: params.avatar }),
    // Use provided redirectUrl or default to current origin
    redirectUrl: params.redirectUrl || `https://${LEARNWORLDS_DOMAIN}`,
  };

  try {
    // Make the direct API call to LearnWorlds
    const response = await axios.post<LearnWorldsLoginResponse>(
      `https://${LEARNWORLDS_DOMAIN}/admin/api/sso`,
      requestData,
      {
        headers: {
          'Lw-Client': CLIENT_ID,
          Authorization: `Bearer ${ACCESS_TOKEN}`,
          'Content-Type': 'application/json',
        },
      }
    );

    // Check if the request was successful
    if (response.data.success && response.data.url) {
      return response.data.url;
    } else {
      throw new Error('Invalid response from LearnWorlds');
    }
  } catch (error) {
    console.error('Error initiating LearnWorlds SSO:', error);
    throw error;
  }
}

/**
 * Opens a LearnWorlds SSO login in a new window
 * @param params User information for the SSO login
 * @returns Promise that resolves when the login process is initiated
 */
export async function openLearnWorldsLogin(
  params: LearnWorldsLoginParams
): Promise<void> {
  try {
    const url = await initiateLearnWorldsLogin(params);
    window.open(url, '_blank');
  } catch (error) {
    console.error('Error opening LearnWorlds login:', error);
    throw error;
  }
}
