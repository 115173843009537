import React, { useEffect, useState } from 'react';
import { Modal, Button, Grid } from 'core/components';
import {
  StyledModal,
  Header,
  StyledDivider,
  Body,
  Footer,
  CancelButton,
  ModalTitle,
} from 'components/Modals/OrderRetainersModal/OrderRetainersModal.css';
import { useDispatch } from 'react-redux';
import { useIsLoading } from 'state/system';
import { AppDispatch } from 'state/store';

import { AsyncThunk } from '@reduxjs/toolkit';
import { ReactSelectOption } from 'utils/types';
import {
  CustomerReactSelectStyles,
  StyledReactSelect,
} from 'styles/inputs.css';
import api from 'state/api';
import { useNotificationContext } from 'core/context/NotificationContext';

type CouponType =
  | 'createCandidEmployeeCoupon'
  | 'createPatterson4PackCoupon'
  | 'createCandidStarter4PackCoupon'
  | 'createTeamCaseCoupon';

type createPracticeCouponModalProps = {
  isOpen: boolean;
  onClose: () => void;
  createPracticeCoupon: AsyncThunk<void, { practiceId: string }, object>;
  couponName: CouponType;
};

type PracticeSelectOption = {
  label: string;
  value: string;
};
// CreateCouponModal is a generic function that allows users to search by practice name, and create a coupon for a specific practice
const CreatePracticeCouponModal = ({
  isOpen,
  onClose,
  createPracticeCoupon,
  couponName,
}: createPracticeCouponModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { showNotification } = useNotificationContext();

  const isFetchingPromotions = useIsLoading('promotions/fetchPromotions');
  const isCreatingCoupon = useIsLoading(`promotions/${couponName}`);

  const isLoading = isFetchingPromotions;

  const onSubmit = async () => {
    try {
      const practiceId = selectedPractice?.value?.toString() ?? '';
      // If you want to catch errors thrown by the dispatch function, you need to .unwrap() the dispatch action
      await dispatch(createPracticeCoupon({ practiceId })).unwrap();
      showNotification(`Coupon created successfully`, 'success');
      onClose();
    } catch (err) {
      const error = err as any;
      showNotification(`Failed to create coupon: ${error?.message}`, 'error');
    }
  };

  const [searchValue, setSearchValue] = useState<string>('');
  const [practiceOptions, setPracticeOptions] = useState<
    PracticeSelectOption[]
  >([]);
  const [selectedPractice, setSelectedPractice] =
    useState<ReactSelectOption<number>>();

  const { data: practices } = api.useGetFilteredPracticesQuery(
    {
      name: searchValue,
    },
    {
      selectFromResult: ({ data, ...rest }) => ({
        ...rest,
        data: data ?? [],
      }),
    }
  );

  useEffect(() => {
    if (searchValue && practices.length) {
      setPracticeOptions(
        practices.map((p) => ({
          value: p.id,
          label: p.name,
        }))
      );
    }
  }, [practices, searchValue]);

  const modalTitleMapping: Record<CouponType, string> = {
    createCandidEmployeeCoupon: 'Create Candid Employee Coupon',
    createPatterson4PackCoupon: 'Create Patterson 4 Pack Coupon',
    createCandidStarter4PackCoupon: 'Create Candid Starter 4 Pack Coupon',
    createTeamCaseCoupon: 'Create Candid Team Case Coupon',
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} hideCloseButton>
      <StyledModal>
        <Header>
          <ModalTitle>{modalTitleMapping[couponName]}</ModalTitle>
        </Header>
        <StyledDivider />
        <Body>
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            style={{ marginLeft: '-0.5rem', marginTop: 0 }}
          >
            <Grid item xs={12} md={12}>
              <StyledReactSelect
                placeholder="Enter practice name"
                styles={CustomerReactSelectStyles}
                options={practiceOptions}
                isDisabled={!practiceOptions}
                onChange={(value: ReactSelectOption<number>) => {
                  setSelectedPractice(value);
                }}
                value={selectedPractice}
                onInputChange={(inputValue: string) =>
                  setSearchValue(inputValue)
                }
              />
            </Grid>
          </Grid>
        </Body>
        <StyledDivider />
        <Footer>
          <CancelButton buttonType="text" onClick={onClose}>
            Cancel
          </CancelButton>
          <Button
            onClick={onSubmit}
            buttonType="secondary"
            isLoading={isLoading || isCreatingCoupon}
          >
            Submit
          </Button>
        </Footer>
      </StyledModal>
    </Modal>
  );
};

export default CreatePracticeCouponModal;
