import React, { useState, useEffect } from 'react';
import { Modal, Grid, Button } from 'core/components';
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Collapse,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';
import api, { ExtractReturnType } from 'state/api';
import {
  TreatmentObjectiveQuestionTypes,
  AnswerOptionInput,
  FollowUpQuestionInput,
} from 'generated/core/graphql';
import {
  StyledModal,
  Header,
  StyledDivider,
  Body,
  Footer,
  CancelButton,
  ModalTitle,
} from 'components/Modals/OrderRetainersModal/OrderRetainersModal.css';
import { FormTextField } from 'components/Modals/CreatePromotionModal/helper';
import { useNotificationContext } from 'core/context/NotificationContext';

const AnswerOptionInputForm = ({
  options,
  setOptions,
  type,
}: {
  options: AnswerOptionInput[];
  setOptions: (options: AnswerOptionInput[]) => void;
  type: TreatmentObjectiveQuestionTypes;
}) => {
  const [newOption, setNewOption] = useState<string>('');
  const [followUpQuestions, setFollowUpQuestions] = useState<
    FollowUpQuestionInput[]
  >([]);
  const [open, setOpen] = useState<boolean[]>([]);

  const handleDelete = (optionToDelete: AnswerOptionInput) => {
    setOptions(options.filter((option) => option.text !== optionToDelete.text));
  };

  const handleToggle = (index: number) => {
    const newOpen = [...open];
    newOpen[index] = !newOpen[index];
    setOpen(newOpen);
  };

  if (
    type !== TreatmentObjectiveQuestionTypes.MultipleChoice &&
    type !== TreatmentObjectiveQuestionTypes.MultiSelect
  ) {
    return null;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="flex-start"
      style={{ marginLeft: '-0.5rem', marginTop: 0 }}
    >
      <FormTextField
        label="Option"
        value={newOption}
        onChange={(e) => setNewOption(e.target.value)}
      />
      <Button
        onClick={() => {
          setOptions([...options, { text: newOption, followUpQuestions }]);
          setNewOption('');
          setFollowUpQuestions([]);
          setOpen([...open, false]);
        }}
        buttonType="secondary"
      >
        Add Option
      </Button>
      <List style={{ marginTop: '1rem', width: '100%' }}>
        {options.map((option, index) => (
          <React.Fragment key={index}>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={option.text} />
              <IconButton edge="end" onClick={() => handleDelete(option)}>
                <DeleteIcon />
              </IconButton>
              <IconButton edge="end" onClick={() => handleToggle(index)}>
                {open[index] ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </ListItem>
            <Collapse
              in={open[index]}
              timeout="auto"
              unmountOnExit
              style={{ backgroundColor: '#f5f5f5', padding: '1rem' }}
            >
              <FollowUpQuestionInputForm
                followUpQuestions={(option.followUpQuestions || []).filter(
                  (q) => q !== null
                )}
                setFollowUpQuestions={(newFollowUpQuestions) => {
                  const newOptions = [...options];
                  newOptions[index].followUpQuestions = newFollowUpQuestions;
                  setOptions(newOptions);
                }}
              />
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </Grid>
  );
};

const FollowUpQuestionInputForm = ({
  followUpQuestions,
  setFollowUpQuestions,
}: {
  followUpQuestions: FollowUpQuestionInput[];
  setFollowUpQuestions: (followUpQuestions: FollowUpQuestionInput[]) => void;
}) => {
  const [newFollowUpQuestion, setNewFollowUpQuestion] = useState<string>('');
  const [newFollowUpType, setNewFollowUpType] =
    useState<TreatmentObjectiveQuestionTypes>(
      TreatmentObjectiveQuestionTypes.MultiSelect
    );
  const [newFollowUpOptions, setNewFollowUpOptions] = useState<string[]>([]);
  const [newOption, setNewOption] = useState<string>('');

  const handleAddOption = () => {
    setNewFollowUpOptions([...newFollowUpOptions, newOption]);
    setNewOption('');
  };

  const handleDeleteOption = (optionToDelete: string) => {
    setNewFollowUpOptions(
      newFollowUpOptions.filter((option) => option !== optionToDelete)
    );
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        style={{ marginLeft: '-0.5rem', marginTop: 0 }}
      >
        <FormTextField
          label="Follow Up Question"
          value={newFollowUpQuestion}
          onChange={(e) => setNewFollowUpQuestion(e.target.value)}
        />
        <FormTextField
          label="Type"
          value={newFollowUpType}
          onChange={(e) =>
            setNewFollowUpType(
              e.target.value as TreatmentObjectiveQuestionTypes
            )
          }
          type="text"
          select
          options={Object.values(TreatmentObjectiveQuestionTypes).map(
            (type) => ({
              value: type,
              label: type,
            })
          )}
        />
        {(newFollowUpType === TreatmentObjectiveQuestionTypes.MultipleChoice ||
          newFollowUpType === TreatmentObjectiveQuestionTypes.MultiSelect) && (
          <>
            <FormTextField
              label="Option"
              value={newOption}
              onChange={(e) => setNewOption(e.target.value)}
            />
            <Button onClick={handleAddOption} buttonType="secondary">
              Add Option
            </Button>
            <List style={{ marginTop: '1rem', width: '100%' }}>
              {newFollowUpOptions.map((option, index) => (
                <ListItem key={index} style={{ paddingLeft: 0 }}>
                  <ListItemText primary={option} />
                  <IconButton
                    edge="end"
                    onClick={() => handleDeleteOption(option)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </>
        )}
        <Button
          onClick={() => {
            setFollowUpQuestions([
              ...followUpQuestions,
              {
                text: newFollowUpQuestion,
                type: newFollowUpType,
                options: newFollowUpOptions,
              },
            ]);
            setNewFollowUpQuestion('');
            setNewFollowUpType(TreatmentObjectiveQuestionTypes.MultiSelect);
            setNewFollowUpOptions([]);
          }}
          buttonType="secondary"
        >
          Add Follow Up Question
        </Button>
      </Grid>
      <List style={{ marginTop: '1rem', width: '100%' }}>
        {followUpQuestions.map((question, index) => (
          <ListItem key={index} style={{ paddingLeft: 0 }}>
            <ListItemText primary={`${question.text} (${question.type})`} />
            <IconButton
              edge="end"
              onClick={() =>
                setFollowUpQuestions(
                  followUpQuestions.filter((q) => q.text !== question.text)
                )
              }
            >
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </>
  );
};

type Question = ExtractReturnType<typeof api.useGetTemplateQuestionsQuery>[0];

type CreateQuestionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  question: Question | undefined;
};

export type CreateQuestionForm = {
  text: string;
  type: string;
  options?: AnswerOptionInput[];
  id?: string;
};

const CreateQuestionModal = ({
  isOpen,
  onClose,
  question,
}: CreateQuestionModalProps) => {
  const [formValues, setFormValues] = useState<CreateQuestionForm>({
    text: '',
    type: '',
    options: [],
    id: '',
  });
  const [createQuestion, createResult] = api.useCreateQuestionMutation();
  const { showNotification } = useNotificationContext();

  useEffect(() => {
    if (question) {
      setFormValues({
        text: question.text,
        type: question.type,
        options: question.options || [],
        id: question.id,
      });
    } else {
      setFormValues({
        text: '',
        type: '',
        options: [],
      });
    }
  }, [question]);

  const handleChange =
    (field: keyof CreateQuestionForm) =>
    (e: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
      setFormValues({ ...formValues, [field]: e.target.value });
    };

  const validateForm = () => {
    if (!formValues.text || !formValues.type) {
      alert('Please fill in all required fields');
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      await createQuestion({
        input: {
          text: formValues.text,
          type: formValues.type as TreatmentObjectiveQuestionTypes,
          options: formValues.options,
        },
      });
      onClose();
    } catch (e) {
      showNotification(`Error creating question: `, 'error');
      console.error(e);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} hideCloseButton>
      <StyledModal>
        <Header>
          <ModalTitle>
            {formValues?.id ? 'View Question' : 'Create Question'}
          </ModalTitle>
        </Header>
        <StyledDivider />
        <Body>
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            style={{ marginLeft: '-0.5rem', marginTop: 0 }}
          >
            <FormTextField
              label="Text"
              value={formValues.text}
              onChange={handleChange('text')}
            />
            <FormTextField
              label="Method"
              value={formValues.type}
              onChange={handleChange('type')}
              type="text"
              select
              options={Object.values(TreatmentObjectiveQuestionTypes).map(
                (method) => ({
                  value: method,
                  label: method,
                })
              )}
            />
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            style={{ marginLeft: '-0.5rem', marginTop: 0 }}
          >
            <AnswerOptionInputForm
              options={formValues.options || []}
              setOptions={(options) =>
                setFormValues({ ...formValues, options })
              }
              type={formValues.type as TreatmentObjectiveQuestionTypes}
            />
          </Grid>
        </Body>
        <StyledDivider />
        <Footer>
          <CancelButton buttonType="text" onClick={onClose}>
            Cancel
          </CancelButton>
          {!formValues.id && (
            <Button
              onClick={onSubmit}
              buttonType="secondary"
              isLoading={createResult.isLoading}
              disabled={createResult.isLoading}
            >
              Submit
            </Button>
          )}
        </Footer>
      </StyledModal>
    </Modal>
  );
};

export default CreateQuestionModal;
