import { FormikValues } from 'formik';
import {
  IntakeAnswer,
  TreatmentObjectiveTemplateSection,
  ToothChart,
  Template,
  TreatmentObjectiveQuestionTypes,
  Question,
} from 'generated/core/graphql';
import { RESERVED_QUESTION_KEYS } from 'types/treatmentObjective';
import { TemplateType } from 'generated/core/graphql';
import { getBrandSettings, SupportedBrand } from 'utils/brands';
import { MeQuery } from 'generated/legacy/graphql';

export const convertTemplateSectionToReadOnlyIntake = (
  templateSection: TreatmentObjectiveTemplateSection[],
  chiefComplaint: string | null | undefined,
  toothCharts: ToothChart | null | undefined
) => {
  const intakeAnswers = templateSection
    .map((section) => {
      return section.sections?.map((question) => {
        return question?.questions?.flatMap((question) => {
          const ret = [
            {
              answer: null,
              question: question?.text,
              explanation: question?.answer,
              questionKey: question?.questionId,
              listAnswer: null,
              questionType: null,
              isFollowupQuestion: false,
              __typename: 'IntakeAnswer',
            },
          ];

          if (question.followUpQuestions) {
            question.followUpQuestions.forEach((followUpQuestion) => {
              ret.push({
                answer: null,
                question: followUpQuestion.text,
                explanation: followUpQuestion.answer,
                questionKey: followUpQuestion.followUpQuestionId,
                listAnswer: null,
                questionType: null,
                isFollowupQuestion: true,
                __typename: 'IntakeAnswer',
              });
            });
          }
          return ret;
        });
      });
    })
    .flat(2);
  return [
    ...[
      chiefComplaint
        ? {
            answer: null,
            question: 'Chief Complaint',
            explanation: chiefComplaint,
            questionKey: RESERVED_QUESTION_KEYS.CHIEF_COMPLAINT,
            listAnswer: null,
            questionType: null,
            __typename: 'IntakeAnswer',
          }
        : [],
    ],
    ...[toothCharts ? convertToothChartToQuestions(toothCharts) : []],
    ...intakeAnswers,
  ] as IntakeAnswer[];
};

const convertToothChartToQuestions = (toothChart: ToothChart) => {
  const toothChartKeyToText: Record<string, string> = {
    cannotMove:
      'Do not move these teeth (i.e. implants, bridges, ankylosed teeth, etc.):',
    willExtract:
      'Mark any teeth that will be extracted prior to treatment (a virtual pontic may be placed):',
    eruptionCompensation: 'Mark any teeth that require eruption compensation:',
  };
  const questions = Object.keys(toothChart)
    .map((key) => {
      return {
        answer: null,
        question: `<p><strong>${toothChartKeyToText[key]}</strong></p>`,
        explanation: '',
        questionKey: key,
        listAnswer: (toothChart as Record<string, string[]>)[key],
        questionType: 'TOOTH_CHART',
        __typename: 'IntakeAnswer',
      };
    })
    .filter((question) => question.listAnswer.toString() !== 'ToothChart');
  return questions as IntakeAnswer[];
};

export const getTotalAndAnsweredQuestionsCount = ({
  values,
  systemTemplate,
  includeChiefComplaint = false,
}: {
  values: FormikValues;
  systemTemplate?: Template | null;
  includeChiefComplaint?: boolean;
}) => {
  if (!systemTemplate) {
    return { total: 0, current: 0 };
  }

  // TODO: In a follow up ticket this will dynamically be derived from the template
  // but for now toothCharts will be optional while chiefComplaint and all other followup questions are required

  const requiredQuestionsTypes = [
    TreatmentObjectiveQuestionTypes.MultipleChoice,
  ];
  const requiredQuestionIdsNonFollowupQuestions =
    systemTemplate.renderedQuestions.flat().filter((question) =>
      requiredQuestionsTypes.includes(
        //If we don't have a type we default to tooth chart because it's not required
        //Should be fixed on the back to be required
        question?.type ?? TreatmentObjectiveQuestionTypes.ToothChart
      )
    ) || [];
  const requiredQuestionIds = [];
  for (const requiredQuestion of requiredQuestionIdsNonFollowupQuestions) {
    requiredQuestionIds.push(requiredQuestion?.id);
    if (requiredQuestion?.options) {
      const answer = values[requiredQuestion?.id];
      if (answer) {
        const chosenOption = requiredQuestion.options.find(
          (o) => o.text == answer
        );
        if (chosenOption?.followUpQuestions) {
          const followupIds = chosenOption.followUpQuestions
            .filter((followupQuestion) => {
              return requiredQuestionsTypes.includes(followupQuestion.type);
            })
            .map((followUpQuestion) => followUpQuestion.followUpQuestionId);
          requiredQuestionIds.push(...followupIds);
        }
      }
    }
  }

  const requiredQuestionsAnsweredCount = [
    ...(includeChiefComplaint ? [RESERVED_QUESTION_KEYS.CHIEF_COMPLAINT] : []),
    ...requiredQuestionIds,
  ].reduce((acc, val) => {
    return val && values[val] ? acc + 1 : acc;
  }, 0);

  return {
    total: includeChiefComplaint
      ? requiredQuestionIds.length + 1
      : requiredQuestionIds.length,
    current: requiredQuestionsAnsweredCount,
  };
};

//Gets the questions from a template, and answers from a formik forms, and creates a treatment objective
export const getQuestionsAndAnswersFromForm = ({
  template,
  answers,
}: {
  template: Template;
  answers?: Record<string, string | string[] | undefined>;
}) => {
  if (!answers) {
    answers = {};
  }
  return {
    templateId: template.id,
    title: template.name,
    sections: template?.groupStructure?.groupOrder.map((title) => {
      const questions = template?.groupStructure?.orderInGroup[title].map(
        (questionId: string) => {
          //Get the first question that matches the Id
          const questionsInSection = template.renderedQuestions.find(
            (section) => section?.find((q) => q.id === questionId)
          );

          const question = questionsInSection?.find(
            (q) => q?.id === questionId
          );

          if (question) {
            let answer = answers[question.id];
            if (
              !answer ||
              question.type === TreatmentObjectiveQuestionTypes.MultiSelect
            ) {
              // answer is not found, this might be a multi select question
              const selectedOptions = question.options
                ?.filter((option) => {
                  return answers[`${question.id}-${option.text}`];
                })
                .map((option) => option.text);
              answer = selectedOptions;
            }
            return {
              questionId,
              answer: answer,
              followUpQuestions: getFollowUpQuestions(question, answers),
            };
          }
          return {
            questionId,
            answer: answers[questionId],
          };
        }
      );

      return {
        title,
        questions: questions,
      };
    }),
  };
};

//For a given question, get all of the followup answers
const getFollowUpQuestions = (
  question: Question,
  answers: Record<string, string | string[] | undefined>
) => {
  const choosenOption = question.options?.find(
    (option) => option.text === answers[question.id]
  );

  //We only have follow ups if the question has options for the answers
  if (choosenOption) {
    return choosenOption.followUpQuestions?.map((followUpQuestion) => {
      // get the answer for the follow up question
      let answer =
        followUpQuestion.followUpQuestionId &&
        answers[followUpQuestion.followUpQuestionId];
      if (
        !answer ||
        followUpQuestion.type === TreatmentObjectiveQuestionTypes.MultiSelect
      ) {
        // answer is not found, this might be a multi select question
        const selectedOptions = followUpQuestion.options?.filter(
          (option) =>
            answers[`${followUpQuestion.followUpQuestionId}-${option}`]
        );
        answer = selectedOptions;
      }
      return {
        followUpQuestionId: followUpQuestion.followUpQuestionId,
        answer: answer,
      };
    });
  }

  return [];
};

export const templateHasBeenCompletedByUser = (template: Template) => {
  const userAnswers = JSON.parse(template?.userAnswers?.root ?? '{}');
  const { total: required, current: complete } =
    getTotalAndAnsweredQuestionsCount({
      values: userAnswers,
      systemTemplate: template,
    });
  return required === complete;
};

export const getClinicalTemplatePayload = (
  userInfo: MeQuery['me'],
  brand: SupportedBrand
) => {
  if (userInfo) {
    const userClinicalTemplateId =
      userInfo?.accountPreferences?.doctor
        ?.alignerClinicalPreferencesTemplateId;
    return userClinicalTemplateId
      ? { templateId: userClinicalTemplateId }
      : {
          userId: Number(userInfo.id),
          brandId: getBrandSettings(brand).brandId,
          templateType: TemplateType.AccountPreferences,
        };
  }
};
