import api from 'state/api';
import { faker } from '@faker-js/faker';
import { useFormikContext } from 'formik';
import {
  PracticeLeadData,
  AccountLeadData,
} from 'pages/PracticeAccountCreator/types';
import {
  CreatePracticeAccountMutationVariables,
  OrganizationTypes,
} from 'generated/legacy/graphql';
import { FormikHelpers } from 'formik';

type SetFieldValue =
  FormikHelpers<CreatePracticeAccountMutationVariables>['setFieldValue'];
type ShowNotificationFn = (
  msg: string,
  type: 'success' | 'error' | 'info'
) => void;

type GetPracticeLeadDataFn = ReturnType<
  typeof api.useLazyGetPracticeLeadDataQuery
>[0];
type GetAccountLeadDataFn = ReturnType<
  typeof api.useLazyGetAccountLeadDataQuery
>[0];

type ActiveLoyaltyPrograms = NonNullable<
  ReturnType<typeof api.useGetActiveLoyaltyProgramQuery>['data']
>;

const generateDummyValues = (
  setFieldValue: ReturnType<typeof useFormikContext>['setFieldValue']
) => {
  const fullName = faker.name.fullName();
  const email = faker.internet.email();
  const practiceName = faker.company.name();
  const replacementHash = '##################';
  const salesforceChildAccountId =
    faker.helpers.replaceSymbols(replacementHash);
  const salesforceParentAccountId =
    faker.helpers.replaceSymbols(replacementHash);

  setFieldValue('practice.name', practiceName);
  setFieldValue(
    'practice.billingAccount.salesforceParentAccountId',
    salesforceParentAccountId
  );
  setFieldValue('practice.salesforceChildAccountId', salesforceChildAccountId);
  setFieldValue('practice.billingAccount.billingContact.name', fullName);
  setFieldValue('practice.billingAccount.billingContact.email', email);
  setFieldValue('practice.address.name', fullName);
  setFieldValue('userDoctors[0].fullName', fullName);
  setFieldValue('userDoctors[0].email', email);
};

const populateFetchedValues = (
  setFieldValue: ReturnType<typeof useFormikContext>['setFieldValue'],
  practiceLeadData: PracticeLeadData,
  accountLeadData: AccountLeadData,
  setLoyaltyOptions: (loyaltyOptions: PracticeLeadData) => void
) => {
  setFieldValue('practice.brandName', practiceLeadData.brand);
  setLoyaltyOptions(practiceLeadData);
  setFieldValue(
    'practice.billingAccount.billingContact.primaryContactNumber',
    accountLeadData.primaryContacts[0].phone
  );
  setFieldValue(
    'practice.billingAccount.billingContact.email',
    practiceLeadData.email
  );
  setFieldValue(
    'practice.address.phone',
    accountLeadData.primaryContacts[0].phone
  );
  setFieldValue('practice.address.zip', practiceLeadData.zip);
  setFieldValue('practice.address.addressLine1', practiceLeadData.address);
  setFieldValue('practice.address.city', practiceLeadData.city);
  setFieldValue('practice.address.stateCode', practiceLeadData.state);
  // setFieldValue('practice.address.countryCode', practiceLeadData.country);
};

export const overwriteUniqueFields = async (
  salesforceId: string,
  getPracticeLeadData: ReturnType<
    typeof api.useLazyGetPracticeLeadDataQuery
  >[0],
  getAccountLeadData: ReturnType<typeof api.useLazyGetAccountLeadDataQuery>[0],
  setFieldValue: ReturnType<typeof useFormikContext>['setFieldValue'],
  setInProgress: (inProgress: boolean) => void,
  setLoyaltyOptions: (loyaltyOptions: PracticeLeadData) => void
) => {
  setInProgress(true);
  const practiceLeadData = await getPracticeLeadData({
    id: salesforceId,
  }).unwrap();
  const accountLeadData = await getAccountLeadData({
    id: practiceLeadData.externalAccountId,
  }).unwrap();

  generateDummyValues(setFieldValue);

  if (practiceLeadData && accountLeadData) {
    populateFetchedValues(
      setFieldValue,
      practiceLeadData,
      accountLeadData,
      setLoyaltyOptions
    );
  }

  setInProgress(false);
};

export const initialFormValues: CreatePracticeAccountMutationVariables = {
  practice: {
    name: '',
    brandName: '',
    salesforceChildAccountId: '',
    address: {
      name: '',
      phone: '',
      zip: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      stateCode: '',
      // TODO: This is temporary and should be reset to an empty string once the smarty streets validation is implemented
      countryCode: 'US',
    },
    billingAccount: {
      salesforceParentAccountId: '',
      billingContact: {
        name: '',
        primaryContactNumber: '',
        email: '',
      },
      alignersContractRate: 0,
      whiteningContractRate: 0,
    },
    organizationType: OrganizationTypes.OfficeLocation,
    loyaltyProgramId: '',
    loyaltyTierId: '',
  },
  userDoctors: [
    {
      fullName: '',
      email: '',
    },
  ],
};

const validatePractice = (
  obj: Record<string, unknown>,
  errors: Record<string, string>,
  path = ''
) => {
  Object.keys(obj).forEach((key) => {
    const fullPath = path ? `${path}.${key}` : key;
    const value = obj[key];

    if (
      (value === '' || value === null || value === undefined) &&
      key !== 'addressLine2'
    ) {
      errors[fullPath] = 'This field is required';
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      validatePractice(value as Record<string, unknown>, errors, fullPath);
    }
  });
};

const validateDoctors = (
  userDoctors: CreatePracticeAccountMutationVariables['userDoctors'],
  errors: Record<string, string>
) => {
  if (
    !userDoctors ||
    (Array.isArray(userDoctors) && userDoctors.length === 0)
  ) {
    errors['userDoctors'] = 'At least one doctor is required';
  } else {
    if (Array.isArray(userDoctors)) {
      userDoctors.forEach((doctor, index) => {
        if (!doctor?.fullName) {
          errors[`userDoctors[${index}].fullName`] = 'Doctor Name is required';
        }
        if (!doctor?.email) {
          errors[`userDoctors[${index}].email`] = 'Doctor Email is required';
        }
      });
    }
  }
};

export const validateForm = (
  values: CreatePracticeAccountMutationVariables
) => {
  const errors: Record<string, string> = {};

  validatePractice(values, errors);
  validateDoctors(values.userDoctors, errors);

  return errors;
};

export const populateAllFields = (
  setFieldValue: SetFieldValue,
  setLoyaltyOptions: (loyaltyOptions: PracticeLeadData) => void,
  practiceLead: PracticeLeadData,
  accountLead: AccountLeadData
) => {
  const fullName = `${accountLead.primaryContacts[0].firstName} ${accountLead.primaryContacts[0].lastName}`;

  setFieldValue('practice.name', practiceLead.name);
  setFieldValue(
    'practice.salesforceChildAccountId',
    practiceLead.externalAccountId
  );
  setFieldValue('practice.brandName', practiceLead.brand);
  setFieldValue('practice.loyaltyProgramId', '123');
  setFieldValue('practice.loyaltyTierId', practiceLead.loyaltyTier);
  setLoyaltyOptions(practiceLead);

  setFieldValue(
    'practice.billingAccount.salesforceParentAccountId',
    accountLead.externalAccountId
  );
  setFieldValue('practice.billingAccount.billingContact.name', fullName);
  setFieldValue(
    'practice.billingAccount.billingContact.primaryContactNumber',
    accountLead.primaryContacts[0].phone
  );
  setFieldValue(
    'practice.billingAccount.billingContact.email',
    practiceLead.email
  );

  setFieldValue('practice.address.name', practiceLead.name);
  setFieldValue('practice.address.phone', accountLead.primaryContacts[0].phone);
  setFieldValue('practice.address.zip', practiceLead.zip);
  setFieldValue('practice.address.addressLine1', practiceLead.address);
  setFieldValue('practice.address.city', practiceLead.city);
  setFieldValue('practice.address.stateCode', practiceLead.state);
  // setFieldValue('practice.address.countryCode', practiceLead.country); // Re-enable when needed

  setFieldValue('userDoctors[0].fullName', fullName);
  setFieldValue('userDoctors[0].email', practiceLead.email);
};

export const autofillFormFields = async (
  salesforceChildAccountId: string,
  setFieldValue: SetFieldValue,
  setAccountBrand: (brand: string) => void,
  setInProgress: (loading: boolean) => void,
  getPracticeLeadData: GetPracticeLeadDataFn,
  getAccountLeadData: GetAccountLeadDataFn,
  showNotification: ShowNotificationFn,
  setLoyaltyOptions: (loyaltyOptions: PracticeLeadData) => void
) => {
  setInProgress(true);

  try {
    const practiceLead: PracticeLeadData = await getPracticeLeadData({
      id: salesforceChildAccountId,
    }).unwrap();

    if (practiceLead.externalAccountId) {
      const accountLead: AccountLeadData = await getAccountLeadData({
        id: practiceLead.externalAccountId,
      }).unwrap();
      populateAllFields(
        setFieldValue,
        setLoyaltyOptions,
        practiceLead,
        accountLead
      );
      setAccountBrand(practiceLead.brand);
    }
  } catch (error) {
    if (error && typeof error === 'object' && 'message' in error) {
      showNotification(
        `Error fetching account data: ${error.message}`,
        'error'
      );
    }
  }

  setInProgress(false);
};

export const setLoyaltyOptionsFromLead = async (
  practiceLead: PracticeLeadData,
  activeLoyaltyPrograms: ActiveLoyaltyPrograms,
  setFieldValue: SetFieldValue,
  setLoyaltyProgramOptions: (
    opts: { displayValue: string; value: string }[]
  ) => void,
  setLoyaltyTierOptions: (
    opts: { displayValue: string; value: string }[] | undefined
  ) => void,
  setEnableLoyaltySelection: (flag: boolean) => void
) => {
  setEnableLoyaltySelection(!!practiceLead?.nonStandardLoyaltyPricing);

  if (
    !practiceLead?.nonStandardLoyaltyPricing &&
    practiceLead?.loyaltyProgram &&
    practiceLead?.selectedLoyaltyTier
  ) {
    setLoyaltyProgramOptions([
      {
        displayValue: practiceLead.loyaltyProgram.name,
        value: practiceLead.loyaltyProgram.id,
      },
    ]);
    setLoyaltyTierOptions(
      practiceLead.loyaltyProgram.loyaltyProgramTiers?.map((tier) => ({
        displayValue: tier.name,
        value: tier.id,
      }))
    );
    setFieldValue('practice.loyaltyProgramId', practiceLead.loyaltyProgram.id);
    setFieldValue(
      'practice.loyaltyTierId',
      practiceLead.selectedLoyaltyTier.id
    );
  } else {
    if (activeLoyaltyPrograms) {
      setLoyaltyProgramOptions(
        activeLoyaltyPrograms.map((program: { name: string; id: string }) => ({
          displayValue: program.name,
          value: program.id,
        }))
      );
    }
  }
};
