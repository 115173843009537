import React, { ReactNode, useState } from 'react';
import { colors, Avatar, Divider } from 'core/components';
import { useHistory } from 'react-router-dom';
import { History } from 'history';

import { useAuthContext } from 'context/AuthContext';

import LogOut from 'assets/ic_log-out.svg?react';
import Bullhorn from 'assets/sup_bullhorn.svg?react';
import HelpCircle from 'assets/ic_help-circle.svg?react';
import OpenBook from 'assets/fi_book-open.svg?react';
import Eye from 'assets/ic_eye.svg?react';
import Arrow from 'assets/ic_chevron-right.svg?react';
import Settings from 'assets/ic_settings.svg?react';
import Dollar from 'assets/dollar.svg?react';
import Chat from 'assets/chat-icon.svg?react';
import { renderMobileDrawerHeader } from 'components/Header/utilities';
import {
  getBrandSettings,
  getBrandSupportedFeatures,
  getBrandFromDomain,
  convertToBrand,
  GLIDEWELL_BRAND_NAME,
  OLIV_BRAND_NAME,
} from 'utils/brands';

import {
  MenuButton,
  StyledPaper,
  MenuHeader,
  MenuBody,
  UserName,
  UserEmail,
  UserMenuItemContainer,
  MenuItemLabel,
  MobileMenuContainer,
  DesktopMenuContainer,
  DesktopPopover,
  ClearChip,
  LoyaltyButton,
  PriceSection,
  LoyaltyLink,
  PriceTextSpan,
  PriceSpan,
  LoyaltyMultiPracticeLinkContent,
} from 'components/Header/UserMenu.css';
import { openLearnWorldsLogin } from 'utils/api';
import { useNotificationContext } from 'core/context/NotificationContext';

import { MobileDrawer } from 'components/Header/Header.css';
import { LoyaltyTierInfo } from 'types/loyalty';
import { LoyaltyInfo } from 'components/Header/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CircularProgress from '@material-ui/core/CircularProgress';

type UserMenuItemProps = {
  icon: ReactNode;
  label: ReactNode | string;
  handleClick: () => Promise<void> | void;
  hidden?: boolean;
  isLoading?: boolean;
};

type UserMenuConfigProps = {
  push: { (path: string, state?: History.PoorMansUnknown): void };
  handleLogOut: () => void;
  monitoringLabel: string;
  helpCenterUrl: string;
  hideMarketingResources: boolean;
  handleLearnWorldsSSO: () => void;
};

type MenuContentsProps = {
  doctorFullName?: string;
  doctorEmail?: string | null;
  loyaltyInfo: LoyaltyInfo;
  handleLogOut: () => void;
  push: {
    (path: string, state?: History.PoorMansUnknown): void;
  };
  handleLearnWorldsSSO: () => void;
};

const userMenuConfig = ({
  push,
  handleLogOut,
  monitoringLabel,
  helpCenterUrl,
  hideMarketingResources,
  handleLearnWorldsSSO,
}: UserMenuConfigProps) => {
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>(
    {}
  );

  const setLoading = (label: string, isLoading: boolean) => {
    setLoadingStates((prev) => ({ ...prev, [label]: isLoading }));
  };

  return [
    {
      label: 'Preferences',
      icon: <Settings stroke={colors.black70} role="img" />,
      handleClick: () => push('/preferences'),
    },
    {
      label: `${monitoringLabel}`,
      icon: <Eye stroke={colors.black70} role="img" />,
      handleClick: () =>
        window.open(
          import.meta.env.VITE_REACT_APP_DENTAL_MONITORING_URL,
          '_blank'
        ),
    },
    {
      label: 'Marketing resources',
      icon: <Bullhorn stroke={colors.black70} role="img" />,
      handleClick: () => push('/marketing-resources'),
      hidden: hideMarketingResources,
    },
    {
      label: 'Help center',
      icon: (
        <HelpCircle
          width={'20px'}
          height={'20px'}
          stroke={colors.black70}
          role="img"
        />
      ),
      handleClick: () => {
        window.open(helpCenterUrl);
      },
    },
    {
      label: 'Contact us',
      icon: (
        <Chat
          width={'20px'}
          height={'20px'}
          stroke={colors.black70}
          role="img"
        />
      ),
      handleClick: () => push('/contact-us'),
    },
    {
      label: 'Learning Portal',
      icon: <OpenBook width={'20px'} height={'20px'} stroke={colors.black70} />,
      handleClick: async () => {
        setLoading('Learning Portal', true);
        try {
          await handleLearnWorldsSSO();
        } finally {
          setLoading('Learning Portal', false);
        }
      },
      isLoading: loadingStates['Learning Portal'],
    },
    {
      label: 'Log out',
      icon: <LogOut stroke={colors.black70} role="img" />,
      handleClick: handleLogOut,
    },
  ];
};

const getAvatarComponent = (doctorName: string | undefined) => {
  return (
    <Avatar
      style={{
        width: 28,
        height: 28,
        backgroundColor: colors.blue50,
        paddingTop: '0.25rem',
        fontSize: '1rem',
      }}
    >
      {doctorName && doctorName[0]}
    </Avatar>
  );
};

const getMenuButton = (doctorName: string | undefined, mobile: boolean) => {
  if (mobile) {
    return getAvatarComponent(doctorName);
  }
  return (
    <MenuButton
      border="default"
      borderRadius={4}
      padding={2}
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      {getAvatarComponent(doctorName)}

      {doctorName && <div style={{ marginLeft: '0.5rem' }}>{doctorName}</div>}
    </MenuButton>
  );
};

const UserMenuItem = (props: UserMenuItemProps) => {
  const { label, icon, handleClick, hidden, isLoading } = props;
  if (hidden) {
    return null;
  }
  return (
    <UserMenuItemContainer onClick={handleClick}>
      {isLoading ? <CircularProgress size={20} color="primary" /> : icon}
      <MenuItemLabel>{label}</MenuItemLabel>
    </UserMenuItemContainer>
  );
};

//If name is null, we never got a practice to get a tier.
//If name is not null but price is, we're a DSO, and we shouldn't display price
//If name and price are not null, then we're not a DSO, so display price
const renderLoyaltyButton = (loyaltyInfo: LoyaltyInfo) => {
  if (!loyaltyInfo.enableLoyaltyProviderView) {
    return;
  }
  const tierName = loyaltyInfo?.name;
  const tierInfo = LoyaltyTierInfo[loyaltyInfo.sortOrder];
  return (
    <LoyaltyButton data-testid="user-menu-loyalty-button">
      <LoyaltyLink to="/loyalty">
        {!loyaltyInfo.name ? (
          <LoyaltyMultiPracticeLinkContent>
            Loyalty program
          </LoyaltyMultiPracticeLinkContent>
        ) : (
          <>
            <ClearChip
              variant="default"
              size="small"
              label={tierName}
              icon={<img alt="" src={tierInfo?.icon} />}
            />
            {loyaltyInfo.price && loyaltyInfo.displayPrice && (
              <PriceSection>
                <PriceSpan>
                  {(loyaltyInfo.price / 100).toLocaleString('en-us', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </PriceSpan>
                <PriceTextSpan>per case</PriceTextSpan>
              </PriceSection>
            )}
          </>
        )}
        <Arrow stroke={colors.black70} role="img" />
      </LoyaltyLink>
    </LoyaltyButton>
  );
};

const MenuContents = ({
  doctorFullName,
  doctorEmail,
  loyaltyInfo,
  handleLogOut,
  push,
  handleLearnWorldsSSO,
}: MenuContentsProps) => {
  const avatarLetter = doctorFullName && doctorFullName[0];
  const {
    showLoyaltyInfo,
    monitoringLabel,
    helpCenterUrl,
    showLearningPortal,
  } = getBrandSettings(getBrandFromDomain());
  const { HideMarketingResourcesInUserMenu: hideMarketingResources } =
    getBrandSupportedFeatures(getBrandFromDomain());

  const {
    'enable-provider-facing-invoices': enableProviderFacingInvoices,
    'enable-learning-linkout': enableLearningLinkout,
  } = useFlags();

  const { userInfo } = useAuthContext();
  const notGlidewell =
    convertToBrand(userInfo?.brandInfo?.name, GLIDEWELL_BRAND_NAME) !==
    GLIDEWELL_BRAND_NAME;
  const notOliv =
    convertToBrand(userInfo?.brandInfo?.name, OLIV_BRAND_NAME) !==
    OLIV_BRAND_NAME;

  let menuItems = userMenuConfig({
    push,
    handleLogOut,
    monitoringLabel: monitoringLabel as string,
    helpCenterUrl: helpCenterUrl as string,
    hideMarketingResources,
    handleLearnWorldsSSO,
  });
  if (enableProviderFacingInvoices && notGlidewell && notOliv) {
    menuItems.splice(0, 0, {
      label: 'Payments',
      icon: <Dollar fill={colors.black70} role="img" />,
      handleClick: () => push('/invoice-summary'),
    });
  }
  if (!showLearningPortal || !enableLearningLinkout) {
    menuItems = menuItems.filter((item) => item.label !== 'Learning Portal');
  }

  return (
    <StyledPaper>
      <MenuHeader>
        <Avatar
          style={{
            width: 40,
            height: 40,
            backgroundColor: colors.blue50,
            paddingTop: '0.25rem',
            marginBottom: '1rem',
          }}
        >
          {avatarLetter}
        </Avatar>

        {doctorFullName && <UserName>{doctorFullName}</UserName>}
        <UserEmail>{doctorEmail}</UserEmail>
        {showLoyaltyInfo && renderLoyaltyButton(loyaltyInfo)}
      </MenuHeader>

      <Divider />

      <MenuBody>
        {menuItems.map((item) => (
          <UserMenuItem {...item} />
        ))}
      </MenuBody>
    </StyledPaper>
  );
};

type BaseUserMenuProps = {
  doctorFullName?: string;
  doctorEmail?: string | null;
  loyaltyInfo: LoyaltyInfo;
  handleLogOut: () => void;
  push: { (path: string, state?: History.PoorMansUnknown): void };
  handleLearnWorldsSSO: () => void;
  isMobile: boolean;
  logoSubString?: string;
};

const BaseUserMenu = ({
  doctorFullName,
  doctorEmail,
  loyaltyInfo,
  handleLogOut,
  push,
  handleLearnWorldsSSO,
  isMobile,
  logoSubString,
}: BaseUserMenuProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClick = () => {
    if (isMobile) {
      setIsOpen(!isOpen);
    }
  };

  const menuButton = getMenuButton(doctorFullName, isMobile);

  if (isMobile) {
    return (
      <MobileMenuContainer onClick={onClick}>
        {menuButton}
        <MobileDrawer anchor="top" open={isOpen}>
          {renderMobileDrawerHeader(onClick, logoSubString, loyaltyInfo)}
          <Divider />
          <MenuContents
            doctorFullName={doctorFullName}
            doctorEmail={doctorEmail}
            loyaltyInfo={loyaltyInfo}
            handleLogOut={handleLogOut}
            push={push}
            handleLearnWorldsSSO={handleLearnWorldsSSO}
          />
        </MobileDrawer>
      </MobileMenuContainer>
    );
  }

  return (
    <DesktopMenuContainer>
      <DesktopPopover anchor={menuButton} on={'click'} direction={'bottom'}>
        <MenuContents
          doctorFullName={doctorFullName}
          doctorEmail={doctorEmail}
          loyaltyInfo={loyaltyInfo}
          handleLogOut={handleLogOut}
          push={push}
          handleLearnWorldsSSO={handleLearnWorldsSSO}
        />
      </DesktopPopover>
    </DesktopMenuContainer>
  );
};

type MenuProps = {
  logoSubString: string | undefined;
  loyaltyInfo: LoyaltyInfo;
};

export const UserMenu = ({ logoSubString, loyaltyInfo }: MenuProps) => {
  const { handleLogOut, userInfo } = useAuthContext();
  const doctorData = userInfo?.doctor;
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { showNotification } = useNotificationContext();

  // Using the utility function for LearnWorlds SSO
  const handleLearnWorldsSSO = async () => {
    if (isLoading || !userInfo?.email || !doctorData?.fullName) {
      return;
    }
    setIsLoading(true);
    try {
      await openLearnWorldsLogin({
        email: userInfo?.email,
        name: doctorData?.fullName,
      });
    } catch (error) {
      console.error('Error navitating to the learning portal:', error);
      showNotification(`Error navitating to the learning portal`, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <BaseUserMenu
        isMobile={true}
        handleLogOut={handleLogOut}
        doctorFullName={doctorData?.fullName}
        doctorEmail={userInfo?.email}
        push={push}
        logoSubString={logoSubString}
        loyaltyInfo={loyaltyInfo}
        handleLearnWorldsSSO={handleLearnWorldsSSO}
      />
      <BaseUserMenu
        isMobile={false}
        handleLogOut={handleLogOut}
        doctorFullName={doctorData?.fullName}
        doctorEmail={userInfo?.email}
        push={push}
        loyaltyInfo={loyaltyInfo}
        handleLearnWorldsSSO={handleLearnWorldsSSO}
      />
    </>
  );
};
