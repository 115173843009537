import React, { useEffect, createRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CopyableText from 'components/CopyableText';
import { Popper } from '@material-ui/core';
import { Maybe } from 'generated/legacy/graphql';
import { useFlags } from 'launchdarkly-react-client-sdk';
import api from 'state/api';
import { useNotificationContext } from 'core/context/NotificationContext';
import { HRDividerLine } from 'styles/layout.css';
import {
  Wrapper,
  Content,
  Name,
  CustomerId,
  PinLoginLink,
  Value,
  EditButton,
  StyledPopper,
  StyledPointer,
  PopperHeader,
  PopperBody,
  PopperFooter,
  UpdateButton,
  DismissLink,
  HighLightedButton,
  UpChevron,
  DownChevron,
  Header,
  NameRow,
  SmallEditButton,
} from 'components/PatientHeader/PatientHeaderLite.css';
import PencilIcon from 'assets/pencil-2.svg?react';
import { Grid, Divider } from 'core/components';
import {
  patientAddressSimilarToPractice,
  Address,
} from 'utils/shippingAddress';
import { formatBirthday } from 'utils/customer';
import { EditPatientInfo } from 'pages/OrthoPrism/types';
import {
  selectCustomer,
  selectCustomerBrand,
  fetchCustomer,
} from 'pages/OrthoPrism/orthoSlice';
import BrandChip from 'components/BrandChip/BrandChip';
import KeyValueDisplay from 'components/KeyValueDisplay';
import TransferPatientModal, {
  Doctor,
  Practice as PracticeType,
} from 'components/Modals/TransferPatientModal';

type PracticeAddress = {
  name?: string | null | undefined;
};

type Practice = {
  id: string;
  name: string;
  address: PracticeAddress;
};

type Props = {
  id: string;
  fullName: string;
  preferredName?: Maybe<string>;
  extraPatientInfo: EditPatientInfo;
  practice?: Practice | null;
  handleOpenEditPatientModal: () => void;
  handleOpenEditClinicianModal: () => void;
};

const PatientHeaderLite = ({
  id,
  fullName,
  preferredName,
  extraPatientInfo,
  practice,
  handleOpenEditPatientModal,
  handleOpenEditClinicianModal,
}: Props) => {
  const {
    birthday,
    phone,
    email,
    addressLine1,
    addressLine2,
    businessName,
    city,
    stateCode,
    countryCode,
    zip,
    treatingProvider,
    candidClinician,
  } = extraPatientInfo;
  const customerInfo = useSelector(selectCustomer);
  const brandInfo = useSelector(selectCustomerBrand);
  const legalGuardian = customerInfo?.legalGuardian;
  const { showNotification } = useNotificationContext();
  const dispatch = useDispatch();

  const formattedPhone = phone?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  const [showPatientInfo, setShowPatientInfo] = useState(true);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const { 'transfer-patient': enableTransferPatient } = useFlags();
  const [updateCustomer] = api.useUpdateCustomerDocumentMutation();

  const handleOpenTransferModal = () => {
    setIsTransferModalOpen(true);
  };

  const handleConfirmTransfer = async (
    doctor: Doctor,
    practice: PracticeType
  ) => {
    if (customerInfo) {
      try {
        await updateCustomer({
          customerInput: {
            customerId: customerInfo?.id,
            updates: {
              referringDentistId: doctor.id,
              practiceId: practice.id,
            },
          },
        }).unwrap();
      } catch (error) {
        console.error('Error transferring patient:', error);
        showNotification('Failed to transfer patient', 'error');
        return;
      }
      showNotification('Patient transferred successfully', 'success');
      setIsTransferModalOpen(false);
      dispatch(fetchCustomer({ customerId: id }));
    }
  };

  type ExpandableButtonProps = {
    text: string;
    isExpanded: boolean;
    onClick: (isExpanded: boolean) => void;
  };
  const ExandableButton = ({
    text,
    isExpanded,
    onClick,
  }: ExpandableButtonProps) => {
    return (
      <HighLightedButton onClick={() => onClick(!isExpanded)}>
        <div>{`${isExpanded ? 'Hide' : 'Show'} ${text} `}</div>
        {isExpanded ? <UpChevron /> : <DownChevron />}
      </HighLightedButton>
    );
  };

  const GuardianInfo = () => {
    const { preferredName, firstName, lastName, middleName, phone, birthday } =
      customerInfo?.legalGuardian || {};
    return (
      <>
        <HRDividerLine />
        <Header>Guardian information</Header>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={6} md={4}>
            <KeyValueDisplay
              label="GUARDIAN NAME"
              value={`${firstName} ${middleName} ${lastName}`}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <KeyValueDisplay label="PREFERRED NAME" value={preferredName} />
          </Grid>
          <Grid item xs={6} md={4}>
            <KeyValueDisplay
              label="PHONE NUMBER"
              value={phone}
              data-testid="overline-heading"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <KeyValueDisplay
              label="DATE OF BIRTH"
              value={birthday}
              data-testid="overline-heading"
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const PatientInfo = () => {
    return (
      <>
        <HRDividerLine />

        <Header>Patient information</Header>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={6} md={4}>
            <KeyValueDisplay
              label="PREFERRED NAME"
              value={preferredName}
              data-testid="overline-heading"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <KeyValueDisplay
              label="DATE OF BIRTH"
              value={formatBirthday(birthday)}
              data-testid="overline-heading"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <KeyValueDisplay
              label="PATIENT HOME ADDRESS"
              value={
                <>
                  <Popper
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    placement={'right'}
                  >
                    <StyledPopper elevation={1}>
                      <StyledPointer />
                      <PopperHeader>Add a patient home address</PopperHeader>
                      <PopperBody>
                        Due to a recent update, we detected that you had your
                        practice address listed as the patient home address.
                      </PopperBody>

                      <PopperFooter>
                        <UpdateButton
                          onClick={() => {
                            handleOpenEditPatientModal();
                            handlePopperDismiss();
                          }}
                          buttonType="black-outline"
                        >
                          Update
                        </UpdateButton>

                        <DismissLink onClick={handlePopperDismiss}>
                          Dismiss
                        </DismissLink>
                      </PopperFooter>
                    </StyledPopper>
                  </Popper>

                  {businessName && (
                    <Value style={{ marginBottom: 0 }}>{businessName}</Value>
                  )}
                  <Value style={{ marginBottom: 0 }}>{addressLine1}</Value>
                  {addressLine2 && (
                    <Value style={{ marginBottom: 0 }}>{addressLine2}</Value>
                  )}
                  <Value>{`${city}, ${stateCode} ${zip}`}</Value>
                </>
              }
              ref={anchor}
              titleStyle={{ width: '215px' }}
              data-testid="overline-heading"
            />
          </Grid>

          <Grid item xs={6} md={4}>
            <KeyValueDisplay
              label="PATIENT EMAIL"
              value={email}
              data-testid="overline-heading"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <KeyValueDisplay label="PHONE" value={formattedPhone} />
          </Grid>
        </Grid>
        {legalGuardian && <GuardianInfo />}
      </>
    );
  };

  let addressMatch = patientAddressSimilarToPractice(
    {
      ...practice?.address,
      businessName: practice?.address?.name || '',
    } as Address,
    {
      addressLine1,
      addressLine2,
      businessName,
      city,
      stateCode,
      countryCode,
      zip,
    }
  );

  const anchor = createRef<HTMLInputElement>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    addressMatch = patientAddressSimilarToPractice(
      {
        ...practice?.address,
        businessName: practice?.address?.name,
      } as Address,
      {
        addressLine1,
        addressLine2,
        businessName,
        city,
        stateCode,
        countryCode,
        zip,
      }
    );
  }, [addressLine1, addressLine2, businessName, city, stateCode, zip]);

  useEffect(() => {
    if (addressMatch) {
      setAnchorEl(anchor.current);
    } else {
      setAnchorEl(null);
    }
  }, [addressMatch]);

  const handlePopperDismiss = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Wrapper>
        <Content>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={11}>
                <Name>
                  <NameRow>
                    {fullName}
                    <BrandChip brandInfo={brandInfo} />
                  </NameRow>
                  <CustomerId data-testid="ortho-prism-header-info">
                    {id}{' '}
                    <CopyableText
                      data-testid="ortho-prism-header-info"
                      label="Customer Id"
                      text={id}
                    />
                  </CustomerId>
                </Name>
              </Grid>
              <Grid item xs={1}>
                <EditButton
                  onClick={() => {
                    handleOpenEditPatientModal();
                  }}
                  isShort
                  buttonType="secondary"
                  leftIcon={<PencilIcon />}
                >
                  Edit
                </EditButton>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={6} md={4}>
                <KeyValueDisplay
                  label="TREATING PROVIDER"
                  value={
                    <>
                      {treatingProvider?.fullName +
                        ' - ' +
                        `[${treatingProvider?.id}] ` +
                        treatingProvider?.email}
                      {enableTransferPatient && (
                        <SmallEditButton
                          onClick={handleOpenTransferModal}
                          cursor="pointer"
                        />
                      )}
                    </>
                  }
                  data-testid="overline-heading"
                />
              </Grid>

              <Grid item xs={6} md={4}>
                <KeyValueDisplay
                  label="PROVIDER PRACTICE"
                  value={
                    <a
                      href={`/management/practice/${practice?.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      [{practice?.id}] {practice?.name}
                    </a>
                  }
                  data-testid="overline-heading"
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <KeyValueDisplay
                  label="CANDID CLINICIAN"
                  value={
                    <>
                      {candidClinician?.fullName ?? ''}
                      <SmallEditButton
                        onClick={() => {
                          handleOpenEditClinicianModal();
                        }}
                        cursor="pointer"
                      />
                    </>
                  }
                  data-testid="overline-heading"
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <PinLoginLink>
                  <a
                    href={`/pin-login/${treatingProvider?.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Go to Pin Login
                  </a>
                </PinLoginLink>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              {showPatientInfo && <PatientInfo />}
              <Grid item xs={12}>
                <ExandableButton
                  isExpanded={showPatientInfo}
                  text={'patient info'}
                  onClick={setShowPatientInfo}
                />
              </Grid>
            </Grid>
          </Grid>
        </Content>
        <Divider />
      </Wrapper>
      <TransferPatientModal
        isOpen={isTransferModalOpen}
        setIsOpen={setIsTransferModalOpen}
        onTransfer={handleConfirmTransfer}
        brandName={brandInfo?.name}
      />
    </>
  );
};

export default PatientHeaderLite;
