import React, { useEffect, useState } from 'react';
import { useNotificationContext } from 'core/context/NotificationContext';

import {
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
} from 'react-router-dom';
import { GetLoyaltyProgramsDocument } from 'generated/core/graphql';
import { useGQLQuery } from 'hooks/useGQL';

import Home from 'pages/LoyaltyProgram/Home';
import Viewer from 'pages/LoyaltyProgram/Viewer';
import Create from 'pages/LoyaltyProgram/Create';

const LoyaltyProgramPages = ({ match }: RouteComponentProps) => {
  const history = useHistory();
  const { showNotification } = useNotificationContext();
  const [getLoyaltyProgramData, { data: getLoyaltyProgramResult }] =
    useGQLQuery<any, any>(GetLoyaltyProgramsDocument);
  const [loyaltyPrograms, setLoyaltyPrograms] = useState([]);

  useEffect(() => {
    setLoyaltyPrograms(getLoyaltyProgramResult?.getLoyaltyPrograms || []);
  }, [getLoyaltyProgramResult]);

  useEffect(() => {
    getLoyaltyProgramData({});
  }, []);

  const handleCreateLoyaltyProgramSuccess = (programName: string) => {
    history.push('/loyalty-program');
    getLoyaltyProgramData({});
    showNotification(`${programName} is created`, 'success');
  };

  const handleUpdateLoyaltyProgramSuccess = (programName: string) => {
    getLoyaltyProgramData({});
    showNotification(`Program name updated to '${programName}'`, 'success');
  };

  const handleArchiveLoyaltyProgramSuccess = (programName: string) => {
    getLoyaltyProgramData({});
    showNotification(
      `Program '${programName} was successfully archived'`,
      'success'
    );
  };

  const handleUnarchiveLoyaltyProgramSuccess = (programName: string) => {
    getLoyaltyProgramData({});
    showNotification(
      `Program '${programName} was successfully unarchived'`,
      'success'
    );
  };

  return (
    <Switch>
      <Route path={match.path} exact>
        <Home loyaltyPrograms={loyaltyPrograms} />
      </Route>
      <Route path={`${match.path}/view/:id`} exact>
        <Viewer
          loyaltyPrograms={loyaltyPrograms}
          onSubmitSuccess={handleUpdateLoyaltyProgramSuccess}
          onArchiveSuccess={handleArchiveLoyaltyProgramSuccess}
          onUnarchiveSuccess={handleUnarchiveLoyaltyProgramSuccess}
        />
      </Route>
      <Route path={`${match.path}/create`} exact>
        <Create
          onSubmitSuccess={handleCreateLoyaltyProgramSuccess}
          showErrorNotification={(errorMessage: string) => {
            showNotification(errorMessage, 'error');
          }}
        />
      </Route>
    </Switch>
  );
};

export default LoyaltyProgramPages;
