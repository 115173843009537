import React, { useState } from 'react';
import { AccordionProps as Props } from '@material-ui/core/Accordion';
import useTheme from 'core/hooks/useTheme';
import { StyledMuiAccordion } from 'core/components/Accordion/Accordion.css';

interface AccordionProps extends Omit<Props, 'onChange'> {
  className?: string;
  onChange?: (
    event: React.ChangeEvent<Record<string, unknown>>,
    expanded: boolean
  ) => void;
}

const Accordion = (props: AccordionProps) => {
  const theme = useTheme();
  const { disabled, children, onChange, expanded, ...rest } = props;
  const initialElevation = expanded
    ? theme.elevation.elevation
    : theme.elevation.no_elevation;
  const [elevation, setElevation] = useState(initialElevation);

  const _onChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    expanded: boolean
  ) => {
    const isActive = expanded && !disabled;
    setElevation(
      isActive ? theme.elevation.elevation : theme.elevation.no_elevation
    );
    onChange && onChange(event, expanded);
  };

  return (
    <StyledMuiAccordion
      disabled={disabled}
      elevation={elevation}
      expanded={expanded}
      onChange={_onChange}
      {...rest}
    >
      {children}
    </StyledMuiAccordion>
  );
};

export default Accordion;
