import React, { useState, useEffect } from 'react';
import Dinero from 'dinero.js';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { MUIDataTableOptions } from 'mui-datatables';

import { Grid, Table, Box, type, Loading, Button } from 'core/components';
import AdjustmentsTable from 'pages/BillingManagement/Invoices/AdjustmentsTable';
import {
  GetInvoiceDocument,
  GetInvoiceQueryVariables,
  GetInvoiceQuery,
  AddLineItemAdjustment,
  MutationsAddLineItemAdjustmentArgs,
  InvoiceLineItemAdjustmentDocument,
  InvoiceLineItemAdjustmentType,
  ApplyCouponToInvoiceLineMutation,
  ApplyCouponToInvoiceLineMutationVariables,
  ApplyCouponToInvoiceLineDocument,
} from 'generated/core/graphql';
import { useGQLQuery, useGQLMutation } from 'hooks/useGQL';
import { PageHeader } from 'pages/BillingManagement/Invoices/styles';
import api from 'state/api';
import AlertCard from 'core/components/AlertCard';

const columns = [
  { name: 'invoiceDate', label: 'TPA Date' },
  { name: 'customerName', label: 'Customer Name' },
  { name: 'product', label: 'Product' },
  { name: 'quantity', label: 'Quantity' },
  { name: 'fee', label: 'Fee' },
  { name: 'tax', label: 'Tax' },
  { name: 'totalAmount', label: 'Adjusted Total' },
];

const DisplayField = ({
  title,
  value = '',
}: {
  title: string;
  value?: string;
}) => (
  <Box display="flex" flexDirection="column">
    <type.Overline>{title}</type.Overline>
    <type.BodySmall>{value}</type.BodySmall>
  </Box>
);

type InvoiceViewParams = {
  invoiceId: string;
};

const InvoiceView = () => {
  const [getPracticePaymentStatus, { data: practicePaymentStatus }] =
    api.useLazyGetPracticePaymentStatusQuery();
  const { invoiceId } = useParams<InvoiceViewParams>();
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [isSubmittingAdjustment, setIsSubmittingAdjustment] =
    useState<boolean>(false);
  const [displayAlertMessage, setDisplayAlertMessage] = useState<string>('');
  const [
    getInvoiceData,
    { data: getInvoiceDataResult, loading: isLoadingInvoice },
  ] = useGQLQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(
    GetInvoiceDocument
  );
  const invoice = getInvoiceDataResult?.invoice;

  const [postLineItemAdjustment] = useGQLMutation<
    AddLineItemAdjustment,
    MutationsAddLineItemAdjustmentArgs
  >(InvoiceLineItemAdjustmentDocument);

  const [applyCouponAtInvoiceLine] = useGQLMutation<
    ApplyCouponToInvoiceLineMutation,
    ApplyCouponToInvoiceLineMutationVariables
  >(ApplyCouponToInvoiceLineDocument, true);

  useEffect(() => {
    if (invoiceId) {
      getInvoiceData({ invoiceId: invoiceId });
    }
  }, [invoiceId]);

  useEffect(() => {
    if (invoice) {
      getPracticePaymentStatus({
        practiceId: invoice.practiceId.toString(),
      });
    }
  }, [invoice]);

  const isLoading = !displayAlertMessage && isLoadingInvoice;
  const handleAddLineItemAdjustment = async (
    payload: MutationsAddLineItemAdjustmentArgs
  ) => {
    setIsSubmittingAdjustment(true);
    await postLineItemAdjustment(payload);
    getInvoiceData({ invoiceId: invoiceId });
    setIsSubmittingAdjustment(false);
  };

  const handleApplyCouponToInvoiceLine = async (
    payload: ApplyCouponToInvoiceLineMutationVariables
  ) => {
    try {
      setIsSubmittingAdjustment(true);
      await applyCouponAtInvoiceLine(payload);
      await getInvoiceData({ invoiceId });
    } catch (e: any) {
      setDisplayAlertMessage(e.toString());
    } finally {
      setIsSubmittingAdjustment(false);
    }
  };

  const options: MUIDataTableOptions = {
    selectableRows: 'none',
    fixedHeader: true,
    pagination: false,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    rowsExpanded: expandedRows,
    isRowExpandable: (dataIndex, expandedRows) => {
      if (!expandedRows) {
        return true;
      }

      // Prevent expand/collapse of any row if a row is expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 1 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      ) {
        return false;
      }
      return true;
    },
    renderExpandableRow: (_rowData, rowMeta) => {
      const invoiceLineItem = invoice?.invoiceLineItems[rowMeta.dataIndex];
      const adjustments = invoiceLineItem?.invoiceLineItemAdjustments || [];

      return (
        <tr>
          <td colSpan={7}>
            <Box px={['1rem']} style={{ maxWidth: '1080px' }} margin={'auto'}>
              <AdjustmentsTable
                originalFee={invoiceLineItem?.adjustedTotalInCents || 0}
                adjustments={adjustments as InvoiceLineItemAdjustmentType[]}
                isSubmittingAdjustment={isSubmittingAdjustment}
                onAddLineItemAdjustment={(formValues) =>
                  handleAddLineItemAdjustment({
                    ...formValues,
                    invoiceLineItemId: invoiceLineItem?.id || '',
                  })
                }
                onApplyCouponToInvoiceLine={(couponCodes: string[]) => {
                  handleApplyCouponToInvoiceLine({
                    invoiceLineItemId: invoiceLineItem?.id || '',
                    couponCodes,
                  });
                }}
              />
            </Box>
          </td>
        </tr>
      );
    },
    onRowExpansionChange: (_curExpanded, allExpanded) => {
      setExpandedRows(allExpanded.map((row) => row.index));
    },
  };

  const mappedData = invoice?.invoiceLineItems?.map((lineItem) => {
    return {
      invoiceDate: moment.utc(lineItem.createdAt).format('MM/DD/YYYY'),
      customerName: `${lineItem.case?.customer?.firstName} ${lineItem.case?.customer?.lastName}`,
      product: `${lineItem.sku} - ${lineItem.orderItem?.productVariant?.label}`,
      fee: Dinero({
        amount: lineItem.subtotalBeforeTaxes || 0,
      }).toFormat(),
      tax: Dinero({
        amount: lineItem.taxAdjustmentsSum || 0,
      }).toFormat(),
      totalAmount: Dinero({
        amount: lineItem.adjustedTotalInCents || 0,
      }).toFormat(),
      quantity: lineItem.quantity,
    };
  });
  if (isLoading) {
    return <Loading isCentered />;
  }

  return (
    <Box py={['1rem']}>
      <PageHeader title="Invoice Summary" />
      {displayAlertMessage && (
        <AlertCard
          type="critical"
          header={displayAlertMessage}
          displayCloseButton
        />
      )}
      <Button
        disabled={!practicePaymentStatus?.portalLink}
        onClick={() => window.open(practicePaymentStatus?.portalLink, '_blank')}
      >
        View practice Yaypay status
      </Button>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent={['space-between']}
        p={['1rem']}
      >
        <DisplayField title="Invoice ID" value={invoice?.id} />
        <DisplayField title="Practice ID" value={String(invoice?.practiceId)} />
        <Box display="flex" flexDirection="column">
          <type.Overline>Practice Name</type.Overline>
          <type.BodySmall>
            <a
              href={`/management/practice/${invoice?.practiceId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {String(invoice?.practice?.name)}
            </a>
          </type.BodySmall>
        </Box>
        <DisplayField
          title="Period Start - End"
          value={`${moment
            .utc(invoice?.periodStart)
            .format('MM/DD/YYYY')} - ${moment
            .utc(invoice?.periodEnd)
            .format('MM/DD/YYYY')}`}
        />
        <DisplayField
          title="Due Date"
          value={moment.utc(invoice?.paymentDueDate).format('MM/DD/YYYY')}
        />
      </Box>
      <Grid item>
        <Table data={mappedData ?? []} columns={columns} options={options} />
      </Grid>
    </Box>
  );
};

export default InvoiceView;
