import React, { useState } from 'react';
import DocumentTitle from 'components/DocumentTitle';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Box } from 'core/components';
import TemplatesListView from 'pages/TreatmentObjectiveTemplates/TemplatesListView';
import QuestionsListView from 'pages/TreatmentObjectiveTemplates/QuestionsListView';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const TreatmentObjectiveTemplates = () => {
  const [value, setValue] = useState(0);

  return (
    <DocumentTitle title="Template Tool">
      <Box py={['1rem']} m={['auto']} style={{ maxWidth: '1360px' }}>
        <Box borderColor={'divider'} borderBottom={1}>
          <Tabs
            value={value}
            onChange={(_, newValue) => setValue(newValue)}
            aria-label="basic tabs example"
          >
            <Tab label="Templates" {...a11yProps(0)} />
            <Tab label="Questions" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <TemplatesListView />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <QuestionsListView />
        </TabPanel>
      </Box>
    </DocumentTitle>
  );
};

export default TreatmentObjectiveTemplates;
