import React from 'react';
import { Formik, FormikErrors } from 'formik';
import { Modal } from 'core/components';
import EditPracticeForm from 'components/EditPracticeModal/EditPracticeForm';

import {
  useEditPracticeInfoMutation,
  EditPracticeInfoInput,
  UsCanadaAdminRegions,
  GetPracticeQuery,
  AddressFields,
} from 'generated/legacy/graphql';
import { useNotificationContext } from 'core/context/NotificationContext';
import { hasMessageField } from 'utils/typeCheck';

export type EditPracticeInfo = {
  addressLine1: string;
  addressLine2: string;
  businessName: string;
  city: string;
  stateCode: string;
  countryCode: string;
  zip: string;
  accountId: string;
};

type EditPracticeModalProps = {
  isOpen?: boolean;
  onClose: () => void;
  onSuccess: () => void;
  practiceData: GetPracticeQuery['practice'];
};

const validateForm = (values: EditPracticeInfo) => {
  const errors: FormikErrors<EditPracticeInfo> = {};
  // Trim all string values before validating them
  Object.keys(values).forEach((key) => {
    const editPracticeInfoKey = key as keyof EditPracticeInfo;
    if (typeof values[editPracticeInfoKey] === 'string') {
      values[editPracticeInfoKey] = values[editPracticeInfoKey].trim();
    }
  });
  if (!values['addressLine1']) {
    errors['addressLine1'] = 'Please input practice address';
  }
  if (!values['city']) {
    errors['city'] = 'Please input practice city';
  }
  if (!values['zip']) {
    errors['zip'] = 'Please input practice zipcode';
  }
  if (!values['stateCode']) {
    errors['stateCode'] = 'Please input practice state';
  }
  if (!values['countryCode']) {
    errors['countryCode'] = 'Please input practice country';
  }
  return errors;
};

const EditPracticeModal = ({
  isOpen,
  onClose,
  onSuccess,
  practiceData,
}: EditPracticeModalProps) => {
  const { showNotification } = useNotificationContext();

  const practiceInfo = {
    accountId: practiceData?.account?.id ?? '',
    addressLine1: practiceData?.address?.addressLine1 ?? '',
    addressLine2: practiceData?.address?.addressLine2 ?? '',
    businessName: practiceData?.address?.name ?? '',
    city: practiceData?.address?.city ?? '',
    stateCode: practiceData?.address?.stateCode ?? '',
    countryCode: practiceData?.address?.countryCode ?? '',
    zip: practiceData?.address?.zip ?? '',
  };

  const submitButtonRef = React.useRef<HTMLButtonElement>(null);

  const [updatePracticeMutation, { loading }] = useEditPracticeInfoMutation();

  const handleSaveEditPractice = async (data: EditPracticeInfo) => {
    const updates: AddressFields = {
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      stateCode: data.stateCode as UsCanadaAdminRegions,
      countryCode: data.countryCode,
      zip: data.zip,
      name: data.businessName,
    };

    const practiceInput: EditPracticeInfoInput = {
      id: practiceData?.id ?? '',
      accountId: data.accountId,
      address: updates,
    };

    try {
      await updatePracticeMutation({
        variables: {
          input: practiceInput,
        },
      });
      onSuccess();
    } catch (error: unknown) {
      if (
        hasMessageField(error) &&
        error.message.includes('Unable to update address')
      ) {
        showNotification(
          'Unable to update address; the provided address is invalid.',
          'error'
        );
      } else {
        showNotification(
          'An error occurred while updating the address.',
          'error'
        );
      }
    }
  };

  const handleOnClose = () => {
    onClose();
  };

  const onSubmit = async (_: EditPracticeInfo) => {
    if (submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <Formik
        enableReinitialize
        initialValues={practiceInfo}
        onSubmit={onSubmit}
        validate={validateForm}
        validateOnBlur
      >
        {isOpen && (
          <EditPracticeForm
            handleCloseModal={handleOnClose}
            submitForm={handleSaveEditPractice}
            submitButtonRef={submitButtonRef}
            isSubmitting={loading}
            practice={practiceData}
          />
        )}
      </Formik>
    </Modal>
  );
};

export default EditPracticeModal;
