import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { FormikProps } from 'formik';

import { Modal, Button, Loading } from 'core/components';
import {
  StyledModal,
  Header,
  StyledDivider,
  Body,
  Footer,
  CancelButton,
  ModalTitle,
  MessageContainer,
  Message,
  Check,
} from 'components/Modals/OrderRetainersModal/OrderRetainersModal.css';
import { Grid } from 'core/components';
import { FormikInputWrapper } from 'components/FormikForms';
import { requiredValidator } from 'components/FormikForms/utils';
import {
  fetchCouponsForPatient,
  selectPatientQualifyForIFICoupon,
  createInitialFitIssueCoupon,
} from 'pages/Promotion/promotionsSlice';
import { useNotificationContext } from 'core/context/NotificationContext';
import { useDispatch, useSelector } from 'react-redux';
import { useIsLoading } from 'state/system';
import { AppDispatch } from 'state/store';
import {
  fetchPatient,
  selectPatient,
  resetPatientState,
} from 'pages/Patient/patientSlice';

type CreateInitialFitIssueCouponModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type CreateInitialFitIssueCouponModalFormValues = {
  patientId: string;
};

const CreateInitialFitIssueCouponModal = ({
  isOpen,
  onClose,
}: CreateInitialFitIssueCouponModalProps) => {
  const patientQualifiesForIFICoupon = useSelector(
    selectPatientQualifyForIFICoupon
  );
  const patient = useSelector(selectPatient);

  const dispatch = useDispatch<AppDispatch>();
  const { showNotification } = useNotificationContext();
  const isFetchingPatientCoupon = useIsLoading(
    'promotions/fetchCouponsForPatient'
  );
  const isFetchingPromotions = useIsLoading('promotions/fetchPromotions');
  const isCreatingCoupon = useIsLoading(
    'promotions/createInitialFitIssueCoupon'
  );

  const isLoading = isFetchingPatientCoupon || isFetchingPromotions;
  const formRef =
    useRef<FormikProps<CreateInitialFitIssueCouponModalFormValues>>(null);

  const fetchPatientInfo = async () => {
    if (!formRef.current?.values.patientId) {
      await dispatch(resetPatientState());
    } else {
      await dispatch(fetchPatient(formRef.current?.values.patientId));
    }
    await dispatch(fetchCouponsForPatient());
  };

  // fetch coupons for patient when "enter" key is pressed
  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        await fetchPatientInfo();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    dispatch(fetchCouponsForPatient());
  }, [patient?.id]);

  const onSubmit = async ({ patientId }: { patientId: string }) => {
    try {
      await dispatch(fetchCouponsForPatient());

      if (patientQualifiesForIFICoupon) {
        if (!patient) {
          showNotification(`Patient ${patientId} does not exist`, 'error');
          return;
        }
        await dispatch(createInitialFitIssueCoupon({ patientId }));
        showNotification(`Coupon created successfully`, 'success');
        onClose();
      } else {
        showNotification(
          `Patient ${patientId} is not eligible for Inital Fit Issue coupon`,
          'error'
        );
      }
    } catch (error: any) {
      showNotification(`Failed to create coupon ${error?.message}`, 'error');
    }
  };

  const renderCouponStatus = () => {
    if (!formRef.current?.values.patientId) {
      return null;
    }

    if (!patient?.id) {
      return (
        <MessageContainer>
          <Message error>Patient not found</Message>
        </MessageContainer>
      );
    }

    if (isFetchingPatientCoupon) {
      return (
        <MessageContainer>
          <Loading />
          <Message>Checking patient eligibility for IFI coupon</Message>
        </MessageContainer>
      );
    }

    const [message, error] = patientQualifiesForIFICoupon
      ? ['Eligible for IFI coupon', false]
      : ['Patient is not eligible for IFI coupon', true];

    return (
      <MessageContainer>
        {patientQualifiesForIFICoupon && <Check />}
        <Message error={error}>{message}</Message>
      </MessageContainer>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} hideCloseButton>
      <StyledModal>
        <Header>
          <ModalTitle>Create Initial Fit Coupon</ModalTitle>
        </Header>
        <StyledDivider />
        <Body>
          <Formik
            initialValues={{ patientId: '' }}
            onSubmit={onSubmit}
            validateOnChange={true}
            innerRef={formRef}
          >
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              style={{ marginLeft: '-0.5rem', marginTop: 0 }}
            >
              <Grid item xs={12} md={12}>
                <FormikInputWrapper
                  name="patientId"
                  label="Patient ID"
                  validate={requiredValidator}
                  type="text"
                  placeholder="Enter patient ID and press enter"
                  onBlur={async () => await fetchPatientInfo()}
                />
                {renderCouponStatus()}
              </Grid>
            </Grid>
          </Formik>
        </Body>
        <StyledDivider />
        <Footer>
          <CancelButton buttonType="text" onClick={onClose}>
            Cancel
          </CancelButton>
          <Button
            onClick={() => formRef.current?.submitForm()}
            buttonType="secondary"
            disabled={
              isLoading || isCreatingCoupon || !patientQualifiesForIFICoupon
            }
            isLoading={isLoading || isCreatingCoupon}
          >
            {patientQualifiesForIFICoupon ? 'Submit' : 'Not eligible'}
          </Button>
        </Footer>
      </StyledModal>
    </Modal>
  );
};

export default CreateInitialFitIssueCouponModal;
