import moment from 'moment';
import { useDispatch } from 'react-redux';

import {
  CreationChannels,
  useCreateCaseMutation,
  UsCanadaAdminRegions,
  useUpdatePatientInfoMutation,
  UpdateCustomerInput,
  useCreateCustomerMutation,
} from 'generated/legacy/graphql';
import { fetchPatient } from 'pages/Patient/patientSlice';

import { AddressFormType } from 'components/AddressForm/types';
import { FormProps } from 'pages/Patient/CaseCreator/BasicInfo/BasicInfoForm';
import { CaseTypeNames } from 'types/Case';
import { caseTypeForBirthday } from 'utils/case';
import {
  CreateCaseDocument,
  CreateCaseMutation,
  CreateCaseMutationVariables,
} from 'generated/core/graphql';
import { useGQLMutation } from 'hooks/useGQL';

type BaseCustomerFields = Pick<
  FormProps,
  | 'email'
  | 'firstName'
  | 'middleName'
  | 'lastName'
  | 'preferredName'
  | 'dateOfBirth'
  | 'phone'
  | 'legalGuardian'
>;

type CustomerAndCaseReqs = {
  referringDentistId: any;
  practiceId: any;
  shippingAddress: AddressFormType;
  requireOrthoReview: boolean;
  caseType: CaseTypeNames;
  forceCreationInCore: boolean;
} & BaseCustomerFields;

type UpdateCustomerReqs = {
  customerId: any;
  addressToUpdateId: any;
  shippingAddress: AddressFormType;
} & BaseCustomerFields;

export const useCreateCustomerAndCase = () => {
  const [createCase] = useCreateCaseMutation();
  const [createCustomer] = useCreateCustomerMutation();
  const [createCoreCase] = useGQLMutation<
    CreateCaseMutation,
    CreateCaseMutationVariables
  >(CreateCaseDocument);

  const createCustomerAndCase = async ({
    email,
    firstName,
    lastName,
    preferredName,
    dateOfBirth,
    referringDentistId,
    practiceId,
    phone,
    middleName,
    shippingAddress,
    legalGuardian,
    requireOrthoReview,
    caseType = CaseTypeNames.ALIGNER,
    forceCreationInCore,
  }: CustomerAndCaseReqs) => {
    const momentDob = moment(dateOfBirth || '');
    const birthday = momentDob.format('YYYY-MM-DD');

    const formattedShippingAddress = {
      firstName,
      lastName,
      businessName: shippingAddress.businessName!,
      addressLine1: shippingAddress.addressLine1!,
      addressLine2: shippingAddress.addressLine2!,
      stateCode: shippingAddress.stateCode! as UsCanadaAdminRegions,
      city: shippingAddress.city,
      zip: shippingAddress.zip,
      phone,
      countryCode: shippingAddress.countryCode,
    };

    const formattedLegalGuardian = legalGuardian?.firstName && {
      ...legalGuardian,
      birthday: moment(legalGuardian.birthday || '').format('YYYY-MM-DD'),
    };
    const variables = {
      customer: {
        email,
        firstName,
        middleName,
        lastName,
        preferredName,
        phone: phone!,
        birthday,
        referringDentistId,
        practiceId,
        shippingAddress: formattedShippingAddress,
        ...(formattedLegalGuardian && {
          legalGuardian: formattedLegalGuardian,
        }),
      },
    };
    const response = await createCustomer({
      variables,
    });

    const customerId = response.data?.createCustomer?.customer?.id;

    if (forceCreationInCore || caseType === CaseTypeNames.RETAINER) {
      await createCoreCase({
        caseOptions: {
          patientId: Number(customerId!),
          caseType:
            caseType === CaseTypeNames.RETAINER
              ? CaseTypeNames.RETAINER
              : CaseTypeNames.ALIGNER,
          practiceId: Number(practiceId),
        },
        workflowOptions: {
          bypassOrthoReview: !requireOrthoReview,
        },
      });
    } else {
      await createCase({
        variables: {
          customerId: customerId!,
          caseType: caseTypeForBirthday(birthday),
          journey: CreationChannels.Studio,
          data: {
            isGen2: true,
            requireOrthoReview: requireOrthoReview,
          },
        },
      });
    }

    return { customerId };
  };

  return {
    createCustomerAndCase,
  };
};

export const useUpdateCustomer = () => {
  const [updatePatientInfo] = useUpdatePatientInfoMutation();
  const dispatch = useDispatch();
  const updateCustomer = async ({
    customerId,
    addressToUpdateId,
    email,
    firstName,
    lastName,
    preferredName,
    dateOfBirth,
    phone,
    middleName,
    legalGuardian,
    shippingAddress,
  }: UpdateCustomerReqs) => {
    const momentDob = moment(dateOfBirth || '');
    const birthday = momentDob.format('YYYY-MM-DD');
    const updates =
      legalGuardian && legalGuardian.birthday
        ? {
            firstName,
            lastName,
            middleName,
            preferredName,
            birthday,
            phone,
            email,
            legalGuardian: {
              firstName: legalGuardian.firstName,
              middleName: legalGuardian.middleName,
              lastName: legalGuardian.lastName,
              birthday: moment(legalGuardian.birthday || '').format(
                'YYYY-MM-DD'
              ),
              preferredName: legalGuardian.preferredName,
              phone: legalGuardian.phone,
            },
          }
        : {
            firstName,
            lastName,
            middleName,
            preferredName,
            birthday,
            phone,
            email,
          };

    const customerInput: UpdateCustomerInput = {
      customerId,
      updates: updates,
    };

    await updatePatientInfo({
      variables: {
        customerInput,
        shippingAddressInput: {
          addressLine1: shippingAddress.addressLine1,
          addressLine2: shippingAddress.addressLine2,
          phone: shippingAddress.phone,
          city: shippingAddress.city,
          zip: shippingAddress.zip,
          stateCode: shippingAddress.stateCode as UsCanadaAdminRegions,
          businessName: null,
        },
        shippingAddressID: addressToUpdateId as string,
      },
    });

    dispatch(fetchPatient(customerId));
  };

  return { updateCustomer };
};
