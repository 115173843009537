import React, { useState, useMemo, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Modal, Grid, Button, SelectInput } from 'core/components';
import { List, ListItem, ListItemText, IconButton } from '@material-ui/core';
import {
  Delete as DeleteIcon,
  DragIndicator as DragIndicatorIcon,
} from '@material-ui/icons';
import api from 'state/api';
import { TemplateType } from 'generated/core/graphql';
import {
  StyledModal,
  Header,
  StyledDivider,
  Body,
  Footer,
  CancelButton,
  ModalTitle,
} from 'components/Modals/OrderRetainersModal/OrderRetainersModal.css';
import { FormTextField } from 'components/Modals/CreatePromotionModal/helper';
import { useDraggableList } from 'components/Modals/useDraggableList';
import { CaseTypeNames } from 'types/Case';
import { BRAND_DOMAIN_SETTINGS } from 'utils/brands';
import {
  CreateBaseTemplateModalProps,
  CreateBaseTemplateForm,
  GroupedQuestions,
  QuestionWithOrder,
  CaseTypeOptionType,
  Question,
} from 'components/Modals/CreateTemplateModal/type';
import { useNotificationContext } from 'core/context/NotificationContext';

const CreateBaseTemplateModal = ({
  isOpen,
  onClose,
}: CreateBaseTemplateModalProps) => {
  const [formValues, setFormValues] = useState<CreateBaseTemplateForm>({
    name: '',
    description: '',
    brandId: 0,
    userId: 0,
    questionsWithOrder: [],
    groupOrder: [],
    caseTypeName: { value: '', label: '' },
    defaultAnswers: '{}',
    isDefault: true,
    type: 'CASE_TEMPLATE',
    userAnswers: '{}',
  });
  const [createTemplate, createResult] = api.useCreateTemplateBaseMutation();
  const { showNotification } = useNotificationContext();
  const [getQuestions, { data: questionsData = [] }] =
    api.useLazyGetTemplateQuestionsQuery({});
  const mappedQuestions: Question[] = useMemo(() => {
    if (!questionsData) {
      return [];
    }
    return questionsData.map(
      ({ id, text, type, createdAt, updatedAt, options, templates }) =>
        ({
          id,
          text,
          type,
          createdAt: moment(createdAt).format('MM/DD/YYYY'),
          updatedAt: moment(updatedAt).format('MM/DD/YYYY'),
          options,
          templates,
        }) as Question
    );
  }, [questionsData]);

  const [selectedGroupIndex, setSelectedGroupIndex] = useState<number | null>(
    null
  );
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const [groupNameModalOpen, setGroupNameModalOpen] = useState(false);
  const [newGroupIndex, setNewGroupIndex] = useState<number | null>(null);

  useEffect(() => {
    getQuestions({});
  }, []);

  const handleChange =
    (field: keyof CreateBaseTemplateForm) =>
    (e: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
      setFormValues({ ...formValues, [field]: e.target.value });
    };

  const handleGroupChange = useCallback(
    (index: number, field: keyof GroupedQuestions) =>
      (e: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
        const newGroups = [...formValues.questionsWithOrder];
        newGroups[index] = { ...newGroups[index], [field]: e.target.value };
        setFormValues({ ...formValues, questionsWithOrder: newGroups });
      },
    [formValues]
  );

  const addQuestionToGroup = (groupIndex: number, questionId: string) => {
    const newGroups = [...formValues.questionsWithOrder];
    const group = newGroups[groupIndex];
    // Check if the question already exists in any group
    const questionExists = newGroups.some((g) =>
      g.questions.some((q) => q.questionId === questionId)
    );
    if (questionExists) {
      showNotification(
        'This question is already added to another group.',
        'error'
      );
      return;
    }
    group.questions.push({
      questionId,
      order: group.questions.length + 1,
    });
    setFormValues({ ...formValues, questionsWithOrder: newGroups });
  };

  const addNewGroup = () => {
    const newGroups = [...formValues.questionsWithOrder];
    newGroups.push({ group: '', questions: [] });
    setFormValues({ ...formValues, questionsWithOrder: newGroups });
    setNewGroupIndex(newGroups.length - 1);
    setGroupNameModalOpen(true);
  };

  const validateForm = () => {
    if (!formValues.name || !formValues.description) {
      alert('Please fill in all required fields');
      return false;
    }
    if (!formValues.brandId) {
      alert('Please select a brand');
      return false;
    }
    if (!formValues.caseTypeName.value) {
      alert('Please select a case type');
      return false;
    }
    if (formValues.questionsWithOrder.length === 0) {
      alert('Please add at least one group');
      return false;
    }
    if (
      formValues.questionsWithOrder.some(
        (group) => group.questions.length === 0
      )
    ) {
      alert('Each group must have at least one question');
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const payload = {
        ...formValues,
        questionsWithOrder: formValues.questionsWithOrder.map((group) => ({
          ...group,
          questions: group.questions.map((q) => ({
            questionId: q.questionId,
            order: q.order,
          })),
        })),

        caseTypeName: formValues.caseTypeName.value,
        groupOrder: formValues.questionsWithOrder.map((group) => group.group),
        type: TemplateType.CaseTemplate,
      };
      await createTemplate({
        input: payload,
      }).unwrap();
      showNotification('Template created successfully', 'success');
      onClose();
    } catch (e) {
      showNotification(`Error creating template: `, 'error');
      console.error(e);
    }
  };

  const { DraggableList, onDragEnd } = useDraggableList<
    GroupedQuestions | QuestionWithOrder
  >();

  interface DragResult {
    source: {
      index: number;
    };
    destination: {
      index: number;
    } | null;
  }

  const handleGroupDragEnd = (result: DragResult) => {
    if (!result.destination) {
      return;
    }

    const newGroups = [...formValues.questionsWithOrder];
    const [removed] = newGroups.splice(result.source.index, 1);
    newGroups.splice(result.destination.index, 0, removed);

    setFormValues({ ...formValues, questionsWithOrder: newGroups });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} hideCloseButton>
      <StyledModal>
        <Header>
          <ModalTitle>
            {formValues?.name ? 'View Template' : 'Create Template'}
          </ModalTitle>
        </Header>
        <StyledDivider />
        <Body>
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            style={{ marginLeft: '-0.5rem', marginTop: 0 }}
          >
            <Grid item xs={12}>
              <SelectInput
                label="Brand"
                options={Object.values(BRAND_DOMAIN_SETTINGS).map((brand) => ({
                  value: brand.brandId.toString(),
                  label: brand.label,
                }))}
                value={{
                  value: formValues.brandId.toString(),
                  label:
                    Object.values(BRAND_DOMAIN_SETTINGS).find(
                      (brand) => brand.brandId === formValues.brandId
                    )?.label || '',
                }}
                onChange={(option) => {
                  if (option && 'value' in option) {
                    setFormValues({
                      ...formValues,
                      brandId: parseInt(option.value as string),
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                label="Case Type"
                options={Object.values(CaseTypeNames).map((name) => ({
                  value: name,
                  label: name,
                }))}
                value={formValues.caseTypeName}
                onChange={(option) => {
                  setFormValues({
                    ...formValues,
                    caseTypeName: option as CaseTypeOptionType,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                label="Name"
                value={formValues.name}
                onChange={handleChange('name')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                label="Description"
                value={formValues.description}
                onChange={handleChange('description')}
              />
              <Button
                buttonType="secondary"
                onClick={addNewGroup}
                style={{ margin: '1rem' }}
              >
                Add New Group
              </Button>
            </Grid>
            <DraggableList
              items={formValues.questionsWithOrder}
              groupIndex={0}
              onDragEnd={handleGroupDragEnd}
              renderItem={(group, groupIndex) => (
                <div
                  key={groupIndex}
                  style={{
                    marginBottom: '1rem',
                    width: '-webkit-fill-available',
                    cursor: 'grab',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '1rem',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DragIndicatorIcon style={{ marginRight: '1rem' }} />
                    <FormTextField
                      label="Group"
                      value={group.group || ''}
                      onChange={handleGroupChange(groupIndex, 'group')}
                      style={{ marginRight: '1rem' }}
                      onClick={() => {
                        setSelectedGroupIndex(groupIndex);
                        setGroupNameModalOpen(true);
                      }}
                    />
                    <IconButton
                      onClick={() => {
                        const newGroups = [...formValues.questionsWithOrder];
                        newGroups.splice(groupIndex, 1);
                        setFormValues({
                          ...formValues,
                          questionsWithOrder: newGroups,
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                  <DraggableList
                    groupIndex={groupIndex}
                    items={'questions' in group ? group.questions : []}
                    onDragEnd={(result) =>
                      onDragEnd(result, groupIndex, formValues, setFormValues)
                    }
                    renderItem={(question, questionIndex) => {
                      const questionDetails = mappedQuestions.find(
                        (q) =>
                          'questionId' in question &&
                          q.id === question.questionId
                      );
                      return (
                        <div
                          key={
                            'questionId' in question
                              ? question.questionId
                              : groupIndex + '-' + questionIndex
                          }
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'grab',
                            paddingLeft: '2rem',
                          }}
                        >
                          <DragIndicatorIcon style={{ marginRight: '1rem' }} />
                          <FormTextField
                            label="Question"
                            value={
                              questionDetails
                                ? `(${questionDetails.id.slice(0, 3)}) ${questionDetails.text}`
                                : ''
                            }
                            disabled
                            style={{ marginRight: '1rem' }}
                            onChange={() => {}}
                          />
                          <IconButton
                            onClick={() => {
                              const newGroups = [
                                ...formValues.questionsWithOrder,
                              ];
                              newGroups[groupIndex].questions.splice(
                                questionIndex,
                                1
                              );
                              setFormValues({
                                ...formValues,
                                questionsWithOrder: newGroups,
                              });
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      );
                    }}
                  />
                  <Button
                    buttonType="secondary"
                    onClick={() => {
                      setSelectedGroupIndex(groupIndex);
                      setQuestionModalOpen(true);
                    }}
                  >
                    Add Question
                  </Button>
                </div>
              )}
            />
          </Grid>
        </Body>
        <StyledDivider />
        <Footer>
          <CancelButton buttonType="text" onClick={onClose}>
            Cancel
          </CancelButton>
          <Button
            onClick={onSubmit}
            buttonType="secondary"
            isLoading={createResult.isLoading}
            disabled={createResult.isLoading}
          >
            Submit
          </Button>
        </Footer>
      </StyledModal>
      {groupNameModalOpen && (
        <Modal
          isOpen={groupNameModalOpen}
          onClose={() => setGroupNameModalOpen(false)}
          hideCloseButton
        >
          <StyledModal>
            <Header>
              <ModalTitle>Enter Group Name</ModalTitle>
            </Header>
            <StyledDivider />
            <Body>
              <FormTextField
                label="Group Name"
                value={
                  newGroupIndex !== null
                    ? formValues.questionsWithOrder[newGroupIndex].group
                    : ''
                }
                onChange={(e) => {
                  if (newGroupIndex !== null) {
                    const newGroups = [...formValues.questionsWithOrder];
                    newGroups[newGroupIndex] = {
                      ...newGroups[newGroupIndex],
                      group: e.target.value,
                    };
                    setFormValues({
                      ...formValues,
                      questionsWithOrder: newGroups,
                    });
                  }
                }}
              />
            </Body>
            <StyledDivider />
            <Footer>
              <CancelButton
                buttonType="text"
                onClick={() => setGroupNameModalOpen(false)}
              >
                Cancel
              </CancelButton>
              <Button
                buttonType="secondary"
                onClick={() => {
                  setGroupNameModalOpen(false);
                }}
              >
                Add Group
              </Button>
            </Footer>
          </StyledModal>
        </Modal>
      )}
      {questionModalOpen && (
        <Modal
          isOpen={questionModalOpen}
          onClose={() => setQuestionModalOpen(false)}
          hideCloseButton
        >
          <StyledModal>
            <Header>
              <ModalTitle>Select a Question</ModalTitle>
            </Header>
            <StyledDivider />
            <Body>
              <List>
                {mappedQuestions.map((question) => (
                  <ListItem
                    key={question.id}
                    button
                    onClick={() => {
                      if (selectedGroupIndex !== null) {
                        addQuestionToGroup(selectedGroupIndex, question.id);
                        setQuestionModalOpen(false);
                      }
                    }}
                  >
                    <ListItemText primary={question.text} />
                  </ListItem>
                ))}
              </List>
            </Body>
            <StyledDivider />
            <Footer>
              <CancelButton
                buttonType="text"
                onClick={() => setQuestionModalOpen(false)}
              >
                Cancel
              </CancelButton>
            </Footer>
          </StyledModal>
        </Modal>
      )}
    </Modal>
  );
};

export default CreateBaseTemplateModal;
