import React, { useState } from 'react';
import {
  Row,
  TableOptions,
  useAsyncDebounce,
  useFilters,
  useRowSelect,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table';
import styled from 'styled-components/macro';
import { TextInput } from 'core/components';
import { InputColumnFilter } from 'components/ReactTable/Filters';

import ArrowSVG from 'assets/arrow-up-down.svg?react';
import {
  TableContainer,
  TableHeaderContainer,
  BodyContainer,
  RowContainer,
} from 'components/CandidTable/CandidTable.css';
import { RowMenu } from 'components/ReactTable/RowMenu';

const ReactTableContainer = styled(TableContainer)`
  height: fit-content;
  display: table;
`;

const HeaderIcon = styled.span`
  margin-left: 0.25rem;
`;

interface GlobalFilterProps {
  globalFilter: string;
  setGlobalFilter: (val?: string) => void;
}

const IconSortDesc = () => (
  <HeaderIcon>
    <ArrowSVG />
  </HeaderIcon>
);
const IconSortAsc = () => (
  <HeaderIcon>
    <ArrowSVG transform="rotate(180)" />
  </HeaderIcon>
);

const defaultColumnProps = {
  Filter: InputColumnFilter,
};

const SortableHeader = ({ column }: any) => {
  if (column.isSorted) {
    return column.isSortedDesc ? <IconSortDesc /> : <IconSortAsc />;
  }
  return null;
};

const GlobalFilter = ({ globalFilter, setGlobalFilter }: GlobalFilterProps) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      <TextInput
        value={value || ''}
        onChange={(e) => {
          setValue(e.currentTarget.value);
          onChange(e.currentTarget.value);
        }}
        placeholder="Search all..."
      />
    </span>
  );
};

type ReactTableProps<T extends object> = TableOptions<T> & {
  onRowClick?: (value: T, row: Row<T>, rows: Row<T>[]) => void;
  onDelete?: (value: T, row: Row<T>, rows: Row<T>[]) => void;
  onEdit?: (value: T, row: Row<T>, rows: Row<T>[]) => void;
  enableFilters?: boolean;
  enableSortBy?: boolean;
  enableGlobalFilter?: boolean;
  showMenu?: boolean;
};

export const ReactTable = <T extends object>({
  showMenu,
  onRowClick,
  onDelete,
  onEdit,
  columns,
  data,
  initialState,
  enableFilters,
  enableSortBy,
  enableGlobalFilter,
  defaultColumn = defaultColumnProps,
}: ReactTableProps<T>) => {
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    state,
    visibleColumns,
    setGlobalFilter,
  } = useTable<T>(
    {
      columns,
      data,
      initialState,
      defaultColumn,
      disableFilters: !enableFilters,
      disableSortBy: !enableSortBy,
      disableGlobalFilter: !enableGlobalFilter,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect
  );

  /*
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <ReactTableContainer
      {
        ...(getTableProps() as any) /* TODO: Casting is a quickfix */
      }
    >
      <TableHeaderContainer>
        {enableGlobalFilter && (
          <RowContainer>
            <th colSpan={visibleColumns.length}>
              <GlobalFilter
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </RowContainer>
        )}
        {headerGroups.map((headerGroup) => (
          <RowContainer
            {
              ...(headerGroup.getHeaderGroupProps() as any) /* TODO: Casting is a quickfix */
            }
          >
            {headerGroup.headers.map((column) => {
              const headerProps = column.getHeaderProps();
              const sortableProps = column.getSortByToggleProps();

              return (
                <th {...(headerProps as any) /* TODO: Casting is a quickfix */}>
                  <span {...sortableProps}>
                    {column.render('Header')}
                    {column.canSort && <SortableHeader column={column} />}
                  </span>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              );
            })}
          </RowContainer>
        ))}
      </TableHeaderContainer>
      <BodyContainer
        {
          ...(getTableBodyProps() as any) /* TODO: Casting is a quickfix */
        }
      >
        {rows.map((row, i) => {
          const onClick = onRowClick
            ? () => onRowClick(data[i], row, rows)
            : undefined;
          prepareRow(row);

          return (
            <React.Fragment>
              <RowContainer
                isSelected={row.isSelected}
                {
                  ...(row.getRowProps() as any) /* TODO: Casting is a quickfix */
                }
              >
                {row.cells.map((cell, j) => (
                  <>
                    <td
                      onClick={onClick}
                      {
                        ...(cell.getCellProps() as any) /* TODO: Casting is a quickfix */
                      }
                    >
                      {cell.render('Cell')}
                    </td>
                    {showMenu && j === row.cells.length - 1 ? (
                      <RowMenu
                        rowValue={data[i]}
                        row={row}
                        rows={rows}
                        onDelete={onDelete}
                        onEdit={onEdit}
                      />
                    ) : null}
                  </>
                ))}
              </RowContainer>
            </React.Fragment>
          );
        })}
      </BodyContainer>
    </ReactTableContainer>
  );
};
