import React from 'react';

import { LaunchNotesApiToken, LaunchNotesProjectId } from 'types/launchnotes';
import {
  ImgContainer,
  LaunchNotesContainer,
  RightContainer,
  RightFloatingContent,
  RightPanel,
  HeaderText,
  SubHeaderText,
} from 'pages/Login/LoginRightPanel.css';
import HappyRetainer from 'assets/happy-retainer.png';

type LoginRightPanelProps = {
  backgroundImgSrc: string;
  showLaunchNotes?: boolean;
};

const LoginRightPanel = ({
  backgroundImgSrc,
  showLaunchNotes,
}: LoginRightPanelProps) => {
  return (
    <RightPanel backgroundImgSrc={backgroundImgSrc}>
      {showLaunchNotes && (
        <RightContainer>
          <RightFloatingContent>
            <ImgContainer src={HappyRetainer} />
            <LaunchNotesContainer>
              <HeaderText>{'Our latest updates'}</HeaderText>
              <SubHeaderText>{"Here's what we've been up to"}</SubHeaderText>
              <launchnotes-embed-inline
                token={LaunchNotesApiToken}
                project={LaunchNotesProjectId}
                limit={3}
              />
            </LaunchNotesContainer>
          </RightFloatingContent>
        </RightContainer>
      )}
    </RightPanel>
  );
};

export default LoginRightPanel;
