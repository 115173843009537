import React from 'react';
import {
  Shipment,
  ShippingTrackerStatusChoices,
  ShippingStatuses,
} from 'generated/core/graphql';
import { useAuthContext } from 'context/AuthContext';
import { ACCESS_GROUPS } from 'types';
import api from 'state/api';
import KeyValueDisplay from 'components/KeyValueDisplay';

import { Button } from 'core/components';

const TrackingInfo = ({ shipment }: { shipment: Shipment }) => {
  const { checkHasAccess } = useAuthContext();
  const isAdmin = checkHasAccess(ACCESS_GROUPS.ADMIN);
  const [updateShippingStatus, { isLoading, data }] =
    api.useUpdateShipmentStatusMutation();

  const publicUrl = shipment?.tracker?.publicUrl;
  const updatedTrackerStatus = data?.shipment?.tracker?.status;
  const showMarkDeliveredButton =
    isAdmin &&
    shipment?.tracker?.status !== ShippingTrackerStatusChoices.Delivered &&
    updatedTrackerStatus !== ShippingTrackerStatusChoices.Delivered;
  return (
    <>
      <KeyValueDisplay
        label={'Tracking Status'}
        value={updatedTrackerStatus || shipment.tracker?.status}
      />
      <KeyValueDisplay
        label={'Tracking ID'}
        value={
          <div
            style={{
              marginBottom: '0.5rem',
            }}
          >
            <h5 style={{ marginBottom: '0' }}>
              {publicUrl ? (
                <a href={publicUrl} target="_blank" rel="noreferrer">
                  {shipment?.tracker?.trackingId}
                </a>
              ) : (
                shipment?.tracker?.trackingId
              )}
            </h5>
            {showMarkDeliveredButton && (
              <Button
                buttonSize="small"
                buttonType="secondary-outline"
                isLoading={isLoading}
                style={{
                  height: '1.5rem',
                }}
                onClick={() =>
                  updateShippingStatus({
                    shipmentId: shipment.id,
                    status: ShippingStatuses.Delivered,
                  })
                }
              >
                Mark delivered
              </Button>
            )}
          </div>
        }
      />
    </>
  );
};

export default TrackingInfo;
