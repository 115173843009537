import React, { useContext, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AlertCard, Loading } from 'core/components';

import DocumentTitle from 'components/DocumentTitle';
import PatientHeader from 'components/PatientHeader';

import { CustomerData } from 'pages/XrayUpload/types';
import { XrayUploadContext } from 'pages/XrayUpload/XrayUploadProvider';
import UploadForm from 'pages/XrayUpload/UploadForm';
import api from 'state/api';
import { PageSection } from 'styles/layout.css';

type MatchParams = {
  caseRef: string;
  id: string;
};

const formatCustomerInfo = (customerData: CustomerData) => {
  const {
    date_of_birth: dob,
    first_name: firstName,
    id,
    last_name: lastName,
  } = customerData;
  const [firstInitial] = firstName;
  const name = `${firstInitial}. ${lastName}`;

  return {
    id: id.toString(),
    dob,
    name,
  };
};

const XrayUploadOverview = ({ match }: RouteComponentProps<MatchParams>) => {
  const { customerData, getXrayData, isFetchingData } =
    useContext(XrayUploadContext);

  const { caseRef, id } = match.params;
  const customerInfo = customerData && formatCustomerInfo(customerData);

  const [getCase, { data: caseData, isFetching: isFetchingCases }] =
    api.useLazyGetCaseQuery();

  const isLoading = !customerData || isFetchingData || isFetchingCases;

  useEffect(() => {
    getXrayData(id, caseRef);
    getCase({ caseRef });
  }, [id, caseRef]);

  if (isLoading) {
    return (
      <DocumentTitle data-private title="Loading…">
        <Loading isCentered />;
      </DocumentTitle>
    );
  }

  return (
    <DocumentTitle
      data-private
      title={`${customerInfo?.name} - X-ray uploader`}
    >
      <PatientHeader
        customerInfo={customerInfo!}
        showTopLinks
        providerFacingCaseState={caseData?.caseState?.providerFacing}
        isLoading={isLoading}
      />
      {caseData?.caseState?.isActive ? (
        <UploadForm caseRef={caseRef} />
      ) : (
        <PageSection isNarrow>
          <AlertCard
            header="Case is not active"
            displayIcon={true}
            body="This case is not active and cannot be edited. Please contact engineering if you believe this is an error."
            type="warning"
          />
        </PageSection>
      )}
    </DocumentTitle>
  );
};

export default XrayUploadOverview;
