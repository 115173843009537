import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import {
  Grid,
  TextInput,
  Heading,
  Button,
  Box,
  Text,
  colors,
} from 'core/components';

import {
  CreateLoyaltyProgram,
  CreateLoyaltyProgramDocument,
  MutationsCreateLoyaltyProgramArgs,
  LoyaltyProgram,
  LoyaltyProgramTier,
} from 'generated/core/graphql';
import { useGQLMutation } from 'hooks/useGQL';
import {
  validateLoyaltyProgramForm,
  LoyaltyProgramTierFormType,
} from 'pages/LoyaltyProgram/helpers';
import { StyledBackLink, PageContainer } from 'pages/LoyaltyProgram/shared';
import TrashIcon from 'assets/trash.svg?react';
import EditIcon from 'assets/edit.svg?react';
import PlusIcon from 'assets/ic_plus.svg?react';

const StyledPlusIcon = styled(PlusIcon)`
  path {
    stroke: red;
  }
`;
const TrashIconContainer = styled(Grid)`
  position: relative;

  svg {
    position: absolute;
    bottom: 24px;
    left: 2px;
  }
`;

const EditIconContainer = styled(Grid)`
  cursor: pointer;
`;

const EditRow = ({
  rule,
  onInputChange,
  onClickDelete,
  hideTrashIcon,
  rowIndex,
}: {
  rule: LoyaltyProgramTierFormType;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickDelete: () => void;
  hideTrashIcon: boolean;
  rowIndex: number;
}) => {
  return (
    <Grid container spacing={3} wrap="nowrap">
      <Grid item xs={4}>
        <TextInput
          name="name"
          label="Tier name"
          onChange={onInputChange}
          value={rule['name']}
          testId={`tier-name-${rowIndex}`}
          required
        />
      </Grid>
      <Grid item xs={4}>
        <Box style={{ position: 'relative' }}>
          <TextInput
            name="discount"
            label="Discount"
            onChange={onInputChange}
            value={String(rule['discount'] || '')}
            style={{ color: colors.red50, paddingLeft: '24px' }}
            required
            testId={`tier-discount-${rowIndex}`}
          />
          <Text
            style={{
              position: 'absolute',
              left: '12px',
              bottom: '8px',
              color: colors.red50,
            }}
          >
            $
          </Text>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box style={{ position: 'relative' }}>
          <TextInput
            type="number"
            name="tpasRequired"
            label="TPAs required"
            onChange={onInputChange}
            value={String(rule['tpasRequired'] || '')}
            required
            testId={`tier-tpas-${rowIndex}`}
          />
          <Text
            style={{
              position: 'absolute',
              right: '12px',
              bottom: '8px',
              color: colors.text60,
            }}
          >
            cases
          </Text>
        </Box>
      </Grid>
      <TrashIconContainer item zeroMinWidth>
        {!hideTrashIcon && (
          <TrashIcon onClick={onClickDelete} style={{ cursor: 'pointer' }} />
        )}
      </TrashIconContainer>
    </Grid>
  );
};

const RULE_TEMPLATE = { name: '', discount: null, tpasRequired: null };

const LoyaltyProgramCreator = ({
  onSubmitSuccess,
  showErrorNotification,
}: {
  onSubmitSuccess: (name: string) => void;
  showErrorNotification: (error: string) => void;
}) => {
  const { state: initialState }: { state: { program: LoyaltyProgram } } =
    useLocation();

  const [rules, setRules] = useState<any[]>([RULE_TEMPLATE]);
  const [programName, setProgramName] = useState<string>(
    'Default Program Name'
  );
  const [isEdittingTitle, setIsEdittingTitle] = useState<boolean>(false);

  useEffect(() => {
    if (initialState && initialState.program) {
      const { name, loyaltyProgramTiers } = initialState.program;
      setProgramName(`${name} Duplicate`);
      const rules = loyaltyProgramTiers
        .sort(
          (a: LoyaltyProgramTier, b: LoyaltyProgramTier) =>
            a.sortOrder - b.sortOrder
        )
        .map((tier: LoyaltyProgramTier) => ({
          name: tier.name,
          discount: tier.loyaltyTierProducts[0].discount,
          tpasRequired: tier?.entryRule?.args?.minNumber,
        }));

      setRules(rules);
    }
  }, [initialState]);

  const addRule = () => {
    setRules([...rules, RULE_TEMPLATE]);
  };

  const handleNameInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.currentTarget;

    setProgramName(value);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value, name } = event.currentTarget;

    const newRules = rules.map((rule, ruleIndex) => {
      if (index === ruleIndex) {
        return { ...rule, [name]: value };
      }

      return rule;
    });

    setRules(newRules);
  };

  const handleDelete = (ruleIndex: number) => {
    const newRules = rules.filter((_rule, index) => ruleIndex !== index);

    setRules(newRules);
  };
  const [postLoyaltyProgram] = useGQLMutation<
    CreateLoyaltyProgram,
    MutationsCreateLoyaltyProgramArgs
  >(CreateLoyaltyProgramDocument);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const DEFAULT_INTERVAL = 6;
    const loyaltyProgram = {
      name: programName,
      interval: DEFAULT_INTERVAL,
      tiers: mapToTiers(),
    };
    const form = {
      name: programName,
      rules,
    };
    try {
      await validateLoyaltyProgramForm(form);
      await postLoyaltyProgram(loyaltyProgram);
      onSubmitSuccess(programName);
    } catch (error) {
      showErrorNotification(JSON.stringify(error));
    }
  };

  const mapToTiers = () => {
    // At launch, we only support aligner sku
    const ALIGNER_SKU = 'AK00007';
    return rules.map((rule, index) => ({
      name: rule.name,
      entryRule: {
        name: 'min_products',
        args: { minNumber: Number(rule.tpasRequired) },
      },
      sortOrder: index,
      productTiers: [{ sku: ALIGNER_SKU, discount: Number(rule.discount) }],
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <PageContainer container justifyContent="center">
        <Grid
          container
          sm={8}
          direction="column"
          justifyContent="space-between"
        >
          <Grid item style={{ marginBottom: '2rem' }}>
            <StyledBackLink />
          </Grid>
          <Grid item style={{ marginBottom: '1.5rem' }}>
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item>
                {isEdittingTitle ? (
                  <TextInput
                    value={programName}
                    onChange={handleNameInputChange}
                    onBlur={() => setIsEdittingTitle(false)}
                    style={{ width: '400px' }}
                    testId={`edit-program-name-input`}
                  />
                ) : (
                  <Text
                    style={{ fontSize: '1.5rem', fontWeight: 700 }}
                    onClick={() => setIsEdittingTitle(true)}
                    data-testid={`edit-program-name`}
                  >
                    {programName}
                  </Text>
                )}
              </Grid>
              <EditIconContainer item onClick={() => setIsEdittingTitle(true)}>
                {!isEdittingTitle && <EditIcon />}
              </EditIconContainer>
            </Grid>
          </Grid>
          <Grid item>
            <Heading variant={'h4'} style={{ marginBottom: '1rem' }}>
              Tier rules
            </Heading>
          </Grid>
          <Grid item>
            {rules.map((rule, index) => (
              <EditRow
                rule={rule}
                onInputChange={(event) => handleInputChange(event, index)}
                onClickDelete={() => handleDelete(index)}
                hideTrashIcon={index === 0}
                rowIndex={index}
              />
            ))}
          </Grid>
          <Grid item>
            <Button
              buttonType="secondary-outline"
              onClick={addRule}
              leftIcon={<StyledPlusIcon />}
              style={{ marginTop: '3rem' }}
            >
              Add a new tier
            </Button>
          </Grid>
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item>
              <Button buttonType="secondary" type="submit">
                Create
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </PageContainer>
    </form>
  );
};

export default LoyaltyProgramCreator;
