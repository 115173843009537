import React, { useMemo } from 'react';
import { Column } from 'react-virtualized';
import { Skeleton } from 'core/components';

import VirtualizedSearchTable, {
  monitoringLinkRenderer,
  brandedCustomerCellRenderer,
  privateCellRenderer,
} from 'components/SearchTablePage/table';
import { toSentenceCase } from 'utils/string';
import { getCaseTypeLabel } from 'utils/case';
import { useAuthContext } from 'context/AuthContext';

export const OrthoPrismSearchTable = () => {
  const { isProDoctor } = useAuthContext();
  const customerCellBaseUrl = isProDoctor ? 'patient' : 'ortho-prism';

  const columns = useMemo(
    () => [
      <Column
        label="ID"
        dataKey="brandedId"
        key="brandedId"
        width={100}
        cellRenderer={({ cellData }) =>
          brandedCustomerCellRenderer(
            cellData.id,
            customerCellBaseUrl,
            cellData.brandName
          )
        }
      />,
      <Column
        label="First name"
        dataKey="firstName"
        key="firstName"
        headerStyle={{ textTransform: 'none' }}
        width={125}
        flexGrow={1}
        cellRenderer={({ cellData }) => privateCellRenderer(cellData)}
      />,
      <Column
        label="Last name"
        dataKey="lastName"
        key="lastName"
        width={125}
        flexGrow={1}
        headerStyle={{ textTransform: 'none' }}
      />,
      <Column
        label="Case type"
        dataKey="caseType"
        key="caseType"
        width={250}
        flexGrow={1}
        headerStyle={{ textTransform: 'none' }}
        cellRenderer={({ cellData }) =>
          cellData !== '-' ? (
            getCaseTypeLabel(cellData ?? '')
          ) : (
            <Skeleton width={100} />
          )
        }
      />,
      <Column
        label="Latest case status"
        dataKey="internal"
        key="internal"
        headerStyle={{ textTransform: 'none' }}
        width={250}
        flexGrow={1}
        cellRenderer={({ cellData }) =>
          cellData !== '-' ? toSentenceCase(cellData) : <Skeleton width={100} />
        }
      />,
      <Column
        label="Treating Provider"
        dataKey="treatingProvider"
        key="treatingProvider"
        width={150}
        flexGrow={1}
        headerStyle={{ textTransform: 'none' }}
      />,
      <Column
        label="Other"
        dataKey="email"
        key="email"
        width={70}
        flexGrow={0}
        flexShrink={0}
        cellRenderer={({ cellData }) => monitoringLinkRenderer(cellData)}
        className="dropdown-cell"
      />,
    ],
    []
  );

  return (
    <VirtualizedSearchTable
      columns={columns}
      filterType="internal_status"
      title="Patients"
    />
  );
};
