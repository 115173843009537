import React from 'react';
import { useSelector } from 'react-redux';
import { selectActiveCase } from 'pages/Patient/patientSlice';
import { CaseTypeNames } from 'types/Case';
import RetainerCard from 'pages/Patient/ProductSelectionModal/RetainerCard';
import { Title } from 'pages/Patient/ProductSelectionModal/Selection.css';
import {
  ModalContainer,
  SelectionContainer,
  StyledNewScansIcon,
  StyledLastStepIcon,
} from 'pages/Patient/ProductSelectionModal/Selection.css';
import {
  RetainerProduct,
  RetainerTypes,
} from 'pages/Patient/ProductSelectionModal/types';
import { theme } from 'core/components';
import { PROVIDER_FACING_STATUSES } from 'types/caseStatus';

type RetainerSelectionProps = {
  updateSelectedRetainer: (retainer: RetainerTypes) => void;
  selectedRetainer: RetainerTypes | null;
};

const RetainerSelection = ({
  selectedRetainer,
  updateSelectedRetainer,
}: RetainerSelectionProps) => {
  const activeCase = useSelector(selectActiveCase);
  const RetainerProducts: RetainerProduct[] = [
    {
      header: 'Use new scans',
      body: 'For optimal results, send us new scans to create retainers based on the patient’s exact anatomy after aligners.',
      Icon: <StyledNewScansIcon />,
      chipText: 'PREFERRED',
      isAvailable: () => true,
      retainerType: RetainerTypes.NewScans,
    },
    {
      header: 'Re-use the last step',
      body: 'If the patient’s anatomy matches the final stage of the current aligners plan, you can create a retainer out of the last step.',
      Icon: <StyledLastStepIcon style={{ stroke: theme.colors.blue50 }} />,
      isAvailable: () =>
        activeCase?.caseType.name === CaseTypeNames.ALIGNER &&
        activeCase?.caseState?.providerFacing !==
          PROVIDER_FACING_STATUSES.IN_RETENTION,
      retainerType: RetainerTypes.LastStep,
    },
    {
      header: 'Re-order current retainer set',
      body: 'If the patient’s anatomy matches the final stage of the current aligners plan, you can order the same retainer set again.',
      Icon: <StyledLastStepIcon style={{ stroke: theme.colors.blue50 }} />,
      isAvailable: () =>
        activeCase?.caseType.name === CaseTypeNames.RETAINER ||
        activeCase?.caseState?.providerFacing ===
          PROVIDER_FACING_STATUSES.IN_RETENTION,
      retainerType: RetainerTypes.Reorder,
    },
  ];

  return (
    <ModalContainer>
      <Title>Choose how you want to create this patient’s retainers</Title>
      <SelectionContainer>
        {RetainerProducts.filter((product) => product.isAvailable()).map(
          (product) => {
            return (
              <RetainerCard
                key={product.retainerType}
                header={product.header}
                body={product.body}
                Icon={product.Icon}
                chipText={product.chipText}
                isSelected={product.retainerType === selectedRetainer}
                onClick={() => updateSelectedRetainer(product.retainerType)}
              />
            );
          }
        )}
      </SelectionContainer>
    </ModalContainer>
  );
};

export default RetainerSelection;
