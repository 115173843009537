import React, { useEffect, useMemo, useState } from 'react';
import {
  CustomerReactSelectStyles,
  StyledReactSelect,
} from 'styles/inputs.css';
import { memoizeOne } from 'utils/memoizer';
import { Nullable, ReactSelectOption } from 'utils/types';
import { PageSection } from 'styles/layout.css';
import {
  Flex,
  LightText,
  StyledBalanceBox,
  StyledChip,
  StyledTable,
} from 'pages/BillingManagement/Invoices/styles';
import { Button, colors, Loading } from 'core/components';
import { BillingYaypayCustomerInvoiceStatusChoices } from 'generated/core/graphql';
import { TabButton, TabsContainer } from 'components/Tabs/Tabs.css';
import { MUIDataTableOptions } from 'mui-datatables';
import api from 'state/api';
import { useAuthContext } from 'context/AuthContext';

export const PracticeInvoices = () => {
  const transformData = useMemo(
    () => memoizeOne(<T,>(data: Nullable<T[]>) => data ?? []),
    []
  );
  const [fetchPracticesByName, { data: practices }] =
    api.useLazyGetFilteredPracticesQuery({
      selectFromResult: ({ data, ...rest }) => ({
        ...rest,
        data: transformData(data),
      }),
    });

  const { userInfo } = useAuthContext();

  const [selectedPractice, setSelectedPractice] =
    useState<ReactSelectOption<number>>();
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] =
    useState<BillingYaypayCustomerInvoiceStatusChoices>(
      BillingYaypayCustomerInvoiceStatusChoices.Unpaid
    );
  const [practiceOptions, setPracticeOptions] = useState<
    ReactSelectOption<number>[]
  >([]);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    fetchPracticesByName({
      name: searchValue,
    });
  }, [searchValue]);
  // Ideally move this to a selector + redux
  useEffect(() => {
    // Enable searching on all practices if user is staff

    if (userInfo?.isStaff && practices.length) {
      setPracticeOptions(
        practices.map((p) => ({ value: parseInt(p.id), label: p.name }))
      );
    } else if (userInfo?.doctor?.practices) {
      setPracticeOptions(
        [...(userInfo?.doctor?.practices || {})] //need to copy to sort
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((p) => {
            return { value: parseInt(p.id), label: p.name };
          })
      );
    }
  }, [userInfo, practices]);

  const [fetchPracticePaymentStatus, { data: practicePaymentStatus }] =
    api.useLazyGetPracticePaymentStatusQuery({
      selectFromResult: ({ data, ...rest }) => ({
        data: {
          ...data,
          invoices:
            data?.invoices.filter(
              (invoice) =>
                invoice.status.toString() === selectedInvoiceStatus.toString()
            ) ?? [],
        },
        ...rest,
      }),
    });

  useEffect(() => {
    if (!selectedPractice) {
      setSelectedPractice(practiceOptions[0]);
    }
  }, [practiceOptions]);

  useEffect(() => {
    if (selectedPractice?.value) {
      fetchPracticePaymentStatus({
        practiceId: selectedPractice.value.toString(),
      });
    }
  }, [selectedPractice?.value]);

  const columns = [
    { label: 'Due Date', name: 'dueDate' },
    { label: 'Invoice #', name: 'invoiceId' },
    { label: 'Amount Due', name: 'balanceDue' },
  ];

  const options: MUIDataTableOptions = {
    search: false,
    pagination: false,
    sort: false,
    selectableRows: 'none',
  };

  const mappedInvoices = practicePaymentStatus?.invoices.map((invoice) => ({
    dueDate: invoice.dueDate,
    invoiceId: invoice.invoiceId,
    balanceDue: `$${invoice.balanceDue.toFixed(2)}`,
  }));

  return (
    <PageSection isNarrow>
      <Flex style={{ gap: '32px' }} direction="column">
        <Flex style={{ justifyContent: 'space-between' }}>
          <Flex direction="column">
            <h2>Invoices</h2>
            <LightText
              style={{
                color: colors.black70,
              }}
            >
              Invoices must be paid within 30 days from the date received
            </LightText>
          </Flex>
          <Flex style={{ height: 'max-content' }}>
            <Button
              buttonType="secondary"
              disabled={!practicePaymentStatus?.portalLink}
              onClick={() => window.open(practicePaymentStatus?.portalLink)}
            >
              {userInfo?.isStaff ? 'View in Yaypay' : 'Pay Balance'}
            </Button>
          </Flex>
        </Flex>
        {practiceOptions.length > 0 &&
          (searchValue !== '' || practiceOptions.length !== 1) && (
            <Flex style={{ width: '50%', zIndex: 999 }}>
              <StyledReactSelect
                placeholder={
                  userInfo?.isStaff
                    ? 'Enter practice name'
                    : 'Select a Practice'
                }
                styles={CustomerReactSelectStyles}
                options={practiceOptions}
                isDisabled={!practiceOptions}
                onChange={(value: ReactSelectOption<number>) =>
                  setSelectedPractice(value)
                }
                value={selectedPractice}
                onInputChange={(inputValue: string) =>
                  setSearchValue(inputValue)
                }
              />
            </Flex>
          )}
        {selectedPractice ? (
          <>
            <BalanceOverview
              unpaidBalance={practicePaymentStatus?.balance}
              pastDueBalance={practicePaymentStatus?.balancePastDue}
              pastDueBalanceDaysLate={practicePaymentStatus?.daysLate}
            />
            <Flex direction="column">
              <TabsContainer>
                <TabButton
                  isSelected={
                    selectedInvoiceStatus ===
                    BillingYaypayCustomerInvoiceStatusChoices.Unpaid
                  }
                  onClick={() =>
                    setSelectedInvoiceStatus(
                      BillingYaypayCustomerInvoiceStatusChoices.Unpaid
                    )
                  }
                >
                  Open invoice
                </TabButton>
                <TabButton
                  isSelected={
                    selectedInvoiceStatus ===
                    BillingYaypayCustomerInvoiceStatusChoices.Paid
                  }
                  onClick={() =>
                    setSelectedInvoiceStatus(
                      BillingYaypayCustomerInvoiceStatusChoices.Paid
                    )
                  }
                >
                  Closed invoices
                </TabButton>
              </TabsContainer>
              <StyledTable
                data={mappedInvoices}
                loading={false}
                columns={columns}
                options={options}
              />
            </Flex>
          </>
        ) : (
          <Flex style={{ justifyContent: 'center', marginTop: '2rem' }}>
            <Loading />
          </Flex>
        )}
      </Flex>
    </PageSection>
  );
};

const BalanceOverview = ({
  unpaidBalance,
  pastDueBalance,
  pastDueBalanceDaysLate,
}: {
  unpaidBalance?: number;
  pastDueBalance?: number;
  pastDueBalanceDaysLate?: number | null;
}) => {
  return (
    <Flex style={{ justifyContent: 'space-between', gap: '2rem' }}>
      <BalanceBox
        title="Total due"
        amount={unpaidBalance ? unpaidBalance : 0}
      />
      <BalanceBox
        title="Past due balance"
        amount={pastDueBalance ? pastDueBalance : 0}
        isError={!!pastDueBalance}
        errorText={`${pastDueBalanceDaysLate} days`}
      />
    </Flex>
  );
};

const BalanceBox = ({
  title,
  amount,
  errorText,
  isError = false,
}: {
  title: string;
  amount?: number;
  isError?: boolean;
  errorText?: string;
}) => {
  return (
    <StyledBalanceBox direction="column">
      <LightText>{title}</LightText>
      <Flex style={{ justifyContent: 'space-between' }}>
        <h2>${amount?.toFixed(2)}</h2>
        {isError && <StyledChip label={errorText} />}
      </Flex>
    </StyledBalanceBox>
  );
};
