import React from 'react';
import { Grid } from 'core/components';

import {
  OrderDetailWrapper,
  OrderDetailHeader,
  OrderDetailContents,
  OrderDetailFieldTitle,
  OrderDetailFieldContainer,
  ShippingText,
} from 'components/Modals/OrderShippingModal/OrderShipping.css';

import { GenericAddress } from 'components/Modals/OrderShippingModal/types';

type AddressLines = {
  addressLine: string;
  cityLine: string;
};

const getAddressLines = (
  shipping_address: GenericAddress | undefined
): AddressLines => {
  if (shipping_address) {
    return {
      addressLine: shipping_address.address_lines.join(', '),
      cityLine: `${shipping_address.city}, ${shipping_address.state_code} ${shipping_address.zip}`,
    };
  } else {
    return {
      addressLine: '',
      cityLine: '',
    };
  }
};

const renderAddressField = (shipping_address: GenericAddress | undefined) => {
  const name = shipping_address?.name;
  const company = shipping_address?.company;
  const addressLines = getAddressLines(shipping_address);

  return (
    <>
      {name && <ShippingText>{name}</ShippingText>}
      {company && <ShippingText>{company}</ShippingText>}
      <ShippingText>{addressLines['addressLine']}</ShippingText>
      <ShippingText>{addressLines['cityLine']}</ShippingText>
    </>
  );
};

type OrderShippingAddressDetailsProps = {
  short_order_ref: string;
  shipping_address: GenericAddress | undefined;
};

const OrderShippingAddressDetails = ({
  short_order_ref,
  shipping_address,
}: OrderShippingAddressDetailsProps) => {
  return (
    <OrderDetailWrapper>
      <OrderDetailHeader>{`${'Intended Shipping Address'} - Order Ref ${short_order_ref}`}</OrderDetailHeader>
      <OrderDetailContents>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <OrderDetailFieldContainer>
              <OrderDetailFieldTitle>{'Ship Address'}</OrderDetailFieldTitle>
              <div>{renderAddressField(shipping_address)}</div>
            </OrderDetailFieldContainer>
          </Grid>
        </Grid>
      </OrderDetailContents>
    </OrderDetailWrapper>
  );
};

export default OrderShippingAddressDetails;
