import { DentalNotationOptions } from 'generated/legacy/graphql';

export const convertFromPalmerToUniversal = (
  quadrant: number,
  index: number
) => {
  if (quadrant === 1) {
    return index + 8;
  } else if (quadrant === 2) {
    return 9 - index;
  } else if (quadrant === 4) {
    return 25 - index;
  } else {
    return index + 24;
  }
};

export const convertFromFDIToUniversal = (quadrant: number, index: number) => {
  if (quadrant === 1) {
    return index - 12;
  } else if (quadrant === 2) {
    return 19 - index;
  } else if (quadrant === 3) {
    return index - 16;
  } else {
    return 55 - index;
  }
};

export const getQuadrants = (notation: DentalNotationOptions) => {
  const palmerQuadrants = [
    [1, 8], // 1st quadrant: Teeth 1 to 8 (Upper Right)
    [1, 8], // 2nd quadrant: Teeth 1 to 8 (Upper Left)
    [1, 8], // 3rd quadrant: Teeth 1 to 8 (Lower Left)
    [1, 8], // 4th quadrant: Teeth 1 to 8 (Lower Right)
  ];
  const universalQuadrants = [
    [9, 16], // 1st quadrant: Teeth 9 to 16 (Upper Right)
    [1, 8], // 2nd quadrant: Teeth 1 to 8 (Upper Left)
    [25, 32], // 3rd quadrant: Teeth 25 to 32 (Lower Left)
    [17, 24], // 4th quadrant: Teeth 17 to 24 (Lower Right)
  ];
  const fdiQuadrants = [
    [21, 28], // 1st quadrant: Teeth 21 to 28 (Upper Right)
    [11, 18], // 2nd quadrant: Teeth 11 to 18 (Upper Left)
    [41, 48], // 3rd quadrant: Teeth 41 to 48 (Lower Left)
    [31, 38], // 4th quadrant: Teeth 31 to 38 (Lower Right)
  ];

  if (notation === DentalNotationOptions.Palmer) {
    return palmerQuadrants;
  } else if (notation === DentalNotationOptions.Fdi) {
    return fdiQuadrants;
  } else {
    return universalQuadrants;
  }
};
