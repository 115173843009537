import React from 'react';

import {
  InputContainer,
  FloatingLabel,
  Wrap,
  StyledTextInput,
} from 'core/components/TextInput/TextInput.css';
import { Label, ErrorText } from 'core/shared/forms.css';

type TextInputProps = {
  className?: string;
  errorText?: string;
  floatingLabel?: string;
  isError?: boolean;
  label?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  testId?: string;
  type?: string;
  value?: string;
};

const TextInput = ({
  className,
  errorText,
  floatingLabel,
  isError,
  label,
  name,
  placeholder,
  required = false,
  testId,
  type = 'text',
  value,
  ...otherProps
}: TextInputProps & React.HTMLProps<HTMLInputElement>) => {
  const showError = isError || !!errorText;
  const formattedId = name ? name.replace(/\W/g, '').toLowerCase() : undefined;

  return (
    <Wrap className={className}>
      {label && <Label>{label}</Label>}
      <InputContainer>
        <StyledTextInput
          aria-label={name}
          aria-placeholder={placeholder}
          aria-required={required}
          data-testid={testId}
          id={formattedId}
          name={name}
          placeholder={placeholder}
          required={required}
          showError={showError}
          type={type}
          value={value}
          {...(otherProps as Record<string, unknown>)}
        />
        {floatingLabel && (
          <FloatingLabel htmlFor={formattedId}>{floatingLabel}</FloatingLabel>
        )}
      </InputContainer>
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </Wrap>
  );
};

export default TextInput;
