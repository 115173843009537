import React from 'react';
import styled from 'styled-components';
import { OrderStatus, OrderType } from 'generated/core/graphql';
import { useAuthContext } from 'context/AuthContext';
import { ACCESS_GROUPS } from 'types';
import api from 'state/api';
import KeyValueDisplay from 'components/KeyValueDisplay';

import { Button } from 'core/components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FULFILLABLE_ORDER_STATUSES = [OrderStatus.Draft, OrderStatus.Processing];

const OrderStatusDisplay = ({ order }: { order: OrderType }) => {
  const { checkHasAccess } = useAuthContext();
  const isAdmin = checkHasAccess(ACCESS_GROUPS.ADMIN);
  const [updateOrder, { isLoading, data }] = api.useUpdateOrderMutation();

  const updatedOrderStatus = data?.order?.status;
  const orderStatus = updatedOrderStatus || order?.status;
  const showFulfillOrderButton =
    isAdmin && orderStatus && FULFILLABLE_ORDER_STATUSES.includes(orderStatus);
  return (
    <KeyValueDisplay
      label={'Order status'}
      value={
        <Container>
          <h5>{orderStatus}</h5>

          {showFulfillOrderButton && (
            <Button
              buttonSize="small"
              buttonType="secondary-outline"
              isLoading={isLoading}
              style={{
                height: '1.5rem',
              }}
              onClick={() => {
                updateOrder({
                  orderId: order.id,
                  status: OrderStatus.Fulfilled,
                });
              }}
            >
              Fulfill order
            </Button>
          )}
        </Container>
      }
    />
  );
};

export default OrderStatusDisplay;
