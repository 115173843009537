import { Maybe } from 'graphql/jsutils/Maybe';

import { Case, CaseTypes } from 'generated/legacy/graphql';
import { PROVIDER_FACING_STATUSES } from 'types/caseStatus';
import { RequiredNonNullable } from 'utils/typeCheck';
import { CaseSoftwareChoice } from 'generated/core/graphql';
import moment from 'moment';

type RequiredCaseFields = RequiredNonNullable<Case, 'caseRef' | 'customerRef'> &
  RequiredNonNullable<Case, 'created'> &
  RequiredNonNullable<Case, 'isActive'>;

export type DecodedCase = Omit<Case, 'treatmentplans' | 'data'> & {
  caseSoftware?: Maybe<CaseSoftwareChoice>;
} & RequiredCaseFields;

const maxTeenAndYouthAge = 17;

export const caseTypeForBirthday = (birthday: string) => {
  const current_date = moment();
  const age = current_date.diff(birthday, 'years');

  if (age <= maxTeenAndYouthAge) {
    return CaseTypes.CproTeenAligner;
  }

  return CaseTypes.CpioAdultAligner;
};

export const getCaseTypeLabel = (caseLabel: string): string => {
  return !caseLabel ? '' : caseLabel;
};

// Get a temporary mask provider facing status when ff is not on

export const availableActionsForProviderFacingStatus = (
  providerFacingStatus?: PROVIDER_FACING_STATUSES
) => {
  return {
    canOrderRetainers:
      providerFacingStatus &&
      [
        PROVIDER_FACING_STATUSES.IN_TREATMENT,
        PROVIDER_FACING_STATUSES.TREATMENT_COMPLETED,
        PROVIDER_FACING_STATUSES.CASE_COMPLETED,
      ].includes(providerFacingStatus),
    canCreateDmAccount:
      providerFacingStatus &&
      [
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_IN_PRODUCTION,
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_SHIPPED,
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_DELIVERED,
        PROVIDER_FACING_STATUSES.IN_TREATMENT,
      ].includes(providerFacingStatus),
    canSendDmActivationEmail:
      providerFacingStatus &&
      [PROVIDER_FACING_STATUSES.IN_TREATMENT].includes(providerFacingStatus),
    canConfirmAlignerDelivery:
      providerFacingStatus &&
      [
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_IN_PRODUCTION,
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_SHIPPED,
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_DELIVERED,
      ].includes(providerFacingStatus),
    canViewTpLinkPdf:
      providerFacingStatus &&
      [
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_IN_PRODUCTION,
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_SHIPPED,
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_DELIVERED,
        PROVIDER_FACING_STATUSES.IN_TREATMENT,
      ].includes(providerFacingStatus),
  };
};
