import styled from 'styled-components';
import { Table } from 'core/components';

export const StyledTable = styled(Table)`
  width: 100%;
  z-index: 0;
  & td {
    padding: 16px;
    margin: 0;
  }
  & th {
    padding: 4px 16px;
  }
`;
