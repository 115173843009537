import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import GraphQLProvider from 'gql/GraphQLProvider';
import {
  BaseStyles,
  NotificationProvider,
  ThemeProvider,
  ZendeskProvider,
} from 'core/components';
import store from 'state/store';
import AuthProvider, { AuthConsumer } from 'components/AuthProvider';
import GlobalErrorBoundary from 'components/GlobalErrorBoundary';
import Header from 'components/Header';
import Pages from 'app/Pages';

import { GlobalStyles } from 'app/App.css';
import styled from 'styled-components/macro';
import { getBrandSettings, getBrandFromDomain } from 'utils/brands';

const Main = styled.main`
  grid-row: 2 / -1;
`;

const zendeskSettings = {
  offset: {
    vertical: '56px',
  },
  position: { vertical: 'top' },
  contactForm: {
    suppress: true,
  },
  talk: {
    nickname: 'Tooth Tools',
    suppress: false,
  },
  launcher: {
    chatLabel: { '*': 'Support' },
    label: { '*': 'Support' },
  },
  chat: {
    suppress: true,
  },
  helpCenter: {
    suppress: true,
  },
};
const App = () => {
  const { zendeskWidgetKey } = getBrandSettings(getBrandFromDomain());
  return (
    <ThemeProvider>
      <BaseStyles />
      <GlobalStyles />
      <GlobalErrorBoundary>
        <GraphQLProvider>
          <Provider store={store}>
            <Router>
              <NotificationProvider>
                <ZendeskProvider
                  hideByDefault
                  settings={zendeskSettings}
                  widgetKey={zendeskWidgetKey as string}
                >
                  <AuthProvider>
                    <AuthConsumer>
                      {({ isLoggedIn }) => isLoggedIn && <Header />}
                    </AuthConsumer>

                    <Main role="main">
                      <Pages />
                    </Main>
                  </AuthProvider>
                </ZendeskProvider>
              </NotificationProvider>
            </Router>
          </Provider>
        </GraphQLProvider>
      </GlobalErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
