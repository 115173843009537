import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectActiveCase,
  selectPatient,
  setPendingClarificationResponse,
  submitCase as submitCaseThunk,
  submitCoreCase as submitCoreCaseThunk,
} from 'pages/Patient/patientSlice';
import { ProviderFacingStates } from 'types/Case';
import { useGQLMutation } from 'hooks/useGQL';
import {
  selectAreTreatmentGoalsReadyForSubmit,
  selectAreDiagnosticMaterialsReadyForSubmit,
  selectUnsubmittedScans,
  selectUnsubmittedXrays,
  selectUnsubmittedTreatmentGoals,
  selectPrismLastSubmissionState,
  selectHasPhotosForCaseSubmission,
  transitionPrismSubmission,
  selectLastSubmission,
  selectIsSubmitted,
  selectPendingClarificationResponse,
} from 'pages/Patient/patientSlice';
import {
  AddSubmissionsMutation,
  AddSubmissionsMutationVariables,
  AddSubmissionsDocument,
  SubmissionInput,
  RefreshCaseStateMutation,
  RefreshCaseStateMutationVariables,
  RefreshCaseStateDocument,
} from 'generated/core/graphql';
import { AppDispatch } from 'state/store';
import { LegacyMaterialStates } from 'types/Material';
import { SubmissionTransitions } from 'generated/legacy/graphql';
import useSubmitClarificationResponse from 'pages/Patient/CaseCreator/useSubmitNeedsClarification';
import { useFlags } from 'launchdarkly-react-client-sdk';

/**
 * If a prism submission already exists that hasn't been rejected, users have the ability
 * to edit the prism photos. However even if they upload photos the state on the aggregate
 * and submission won't change. In order for the new photos to go through the flow properly
 * we'll need to reject the submission so the aggregate can go into evaluation.
 */
const preparePrismSubmissionForCase = (
  prismSubmissionRef: string,
  prismSubmissionState: string,
  hasPhotosToSubmit: boolean,
  dispatch: AppDispatch
) => {
  if (!hasPhotosToSubmit || !prismSubmissionRef) {
    return;
  }
  const transitionInput = {
    submissionRef: prismSubmissionRef,
    rejectionNotes: 'Provider requested to upload new photos',
    thunkOptions: {
      refreshPrismAggregates: false,
    },
  };
  // note clarification provided doesn't actually exist in prism and is
  // interpreted in our sate selectors but it's equivalent to SUBMITTED
  const missingInfoStates = [
    LegacyMaterialStates.SUBMITTED,
    LegacyMaterialStates.CLARIFICATION_PROVIDED,
  ];
  type MissingInfoStates = (typeof missingInfoStates)[number];
  if (missingInfoStates.includes(prismSubmissionState as MissingInfoStates)) {
    return dispatch(
      transitionPrismSubmission({
        ...transitionInput,
        transition: SubmissionTransitions.RejectMissingInformation,
      })
    );
  }

  // need to unapprove first and then reject the prism submission
  // there is no direct way to get approved to reject
  if (prismSubmissionState === LegacyMaterialStates.APPROVED) {
    return dispatch(
      transitionPrismSubmission({
        ...transitionInput,
        transition: SubmissionTransitions.ForceReverseApproval,
      })
    ).then(() => {
      return dispatch(
        transitionPrismSubmission({
          ...transitionInput,
          transition: SubmissionTransitions.RejectMissingInformation,
        })
      );
    });
  }
};

const useSubmitCase = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { 'enable-create-cases-in-core': submitCaseInCore } = useFlags();

  const [isSubmittingCase, setIsSubmittingCase] = useState<boolean>(false);

  const [addSubmissions] = useGQLMutation<
    AddSubmissionsMutation,
    AddSubmissionsMutationVariables
  >(AddSubmissionsDocument, true);

  const [refreshCaseState] = useGQLMutation<
    RefreshCaseStateMutation,
    RefreshCaseStateMutationVariables
  >(RefreshCaseStateDocument, true);

  const diagnosticMaterialsComplete = useSelector(
    selectAreDiagnosticMaterialsReadyForSubmit
  );
  const treatmentGoalsComplete = useSelector(
    selectAreTreatmentGoalsReadyForSubmit
  );
  const unsubmittedScans = useSelector(selectUnsubmittedScans);
  const unsubmittedXrays = useSelector(selectUnsubmittedXrays);
  const unSubmittedTreatmentgoal = useSelector(selectUnsubmittedTreatmentGoals);

  const activeCase = useSelector(selectActiveCase);
  const patient = useSelector(selectPatient);
  // prism selectors
  const lastPrismSubmission = useSelector(selectLastSubmission);
  const prismSubmissionState = useSelector(selectPrismLastSubmissionState);
  const hasPhotosToSubmit = useSelector(selectHasPhotosForCaseSubmission);
  const isCaseSubmitted = useSelector(selectIsSubmitted);

  const { submitNeedsClarificationResponse } = useSubmitClarificationResponse();

  const pendingClarificationResponse = useSelector(
    selectPendingClarificationResponse
  );

  const caseRef = activeCase?.caseRef;
  const patientId = Number(patient?.id);

  type SubmitCaseProps = {
    onSuccess?: () => void;
    onError?: () => void;
    isCore?: boolean;
  };

  const submitCase = async ({
    onSuccess,
    onError,
    isCore = false,
  }: SubmitCaseProps = {}) => {
    try {
      setIsSubmittingCase(true);

      if (!caseRef) {
        throw new Error('No case found, please try again');
      }

      // currently, case submissions != core submission so we need
      // to make several requests before making the case submission request
      const preCaseSubmitPromises: Promise<any>[] = [];
      const pendingSubmissions: SubmissionInput[] = [];

      const prismPreCaseSubmitPromise = preparePrismSubmissionForCase(
        lastPrismSubmission?.ref,
        prismSubmissionState,
        hasPhotosToSubmit,
        dispatch
      );
      if (prismPreCaseSubmitPromise) {
        preCaseSubmitPromises.push(prismPreCaseSubmitPromise);
      }

      if (unsubmittedScans.length) {
        pendingSubmissions.push({
          patientId,
          materialIds: unsubmittedScans.map(({ id }) => id),
          caseRef,
        });
      }

      if (unsubmittedXrays.length) {
        pendingSubmissions.push({
          patientId,
          materialIds: unsubmittedXrays.map(({ id }) => id),
          caseRef,
        });
      }

      if (unSubmittedTreatmentgoal) {
        pendingSubmissions.push({
          patientId,
          materialIds: [unSubmittedTreatmentgoal.id],
          caseRef,
        });
      }

      if (pendingClarificationResponse?.responseInfo) {
        const { response, respondedByEmail } =
          pendingClarificationResponse?.responseInfo || {};

        const needsClarificationPromises = submitNeedsClarificationResponse(
          response,
          respondedByEmail,
          pendingClarificationResponse,
          caseRef
        );
        preCaseSubmitPromises.push(...needsClarificationPromises);
        dispatch(setPendingClarificationResponse(null));
      }

      // If there are no pre-case submit promises and no pending submissions,
      // refresh the case state to ensure materials state and case_state are in sync
      if (
        !preCaseSubmitPromises.length &&
        !pendingSubmissions.length &&
        isCore
      ) {
        await refreshCaseState({ caseRef });
      } else if (pendingSubmissions.length) {
        // If there are pending submissions, add them using the addSubmissions mutation
        preCaseSubmitPromises.push(
          addSubmissions({
            inputData: pendingSubmissions,
          })
        );
      }

      // wait for all pre-case submit promises to resolve
      await Promise.all(preCaseSubmitPromises);

      // core cases don't get submitted
      // state is calculated based on the state of materials
      if (submitCaseInCore) {
        const submitCaseResult = await dispatch(
          submitCoreCaseThunk({ caseRef })
        );
        if ('error' in submitCaseResult) {
          throw new Error(submitCaseResult.error.message);
        }
      } else if (!isCore) {
        const submitCaseResult = await dispatch(submitCaseThunk({ caseRef }));
        if ('error' in submitCaseResult) {
          throw new Error(submitCaseResult.error.message);
        }
      }
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      if (onError) {
        onError();
      } else {
        throw err;
      }
    } finally {
      setIsSubmittingCase(false);
    }
  };

  // Check if the providerFacingState is in a state where the case can be submitted
  const providerFacingState = activeCase?.caseState?.providerFacing;
  const canSubmitCase =
    (providerFacingState === ProviderFacingStates.INCOMPLETE_SUBMISSION ||
      providerFacingState === ProviderFacingStates.ACTION_NEEDED) &&
    diagnosticMaterialsComplete &&
    treatmentGoalsComplete;

  return {
    canSubmitCase,
    isSubmittingCase,
    isCaseSubmitted,
    submitCase,
  };
};

export default useSubmitCase;
