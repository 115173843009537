import React, { useState, useRef } from 'react';
import {
  FormContainer,
  PageContainer,
} from 'pages/PracticeAccountCreator/PracticeAccountCreator.css';

import FormSections from 'pages/PracticeAccountCreator/FormSections';

import AccountCreatorSidebar from 'pages/PracticeAccountCreator/Sidebar';
import { Formik, FormikHelpers } from 'formik';
import {
  validateForm,
  initialFormValues,
} from 'pages/PracticeAccountCreator/utils';
import api from 'state/api';
import { CreatePracticeAccountMutationVariables } from 'generated/legacy/graphql';
import SuccessModal from 'pages/PracticeAccountCreator/SuccessModal';

const AccountCreatorView = () => {
  const [accountBrand, setAccountBrand] = useState<string>('');
  const [salesforceChildAccountId, setSalesforceChildAccountId] = useState('');
  const [createPracticeAccount, { data: createPracticeAccountData }] =
    api.useCreatePracticeAccountMutation();
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const onSubmit = async (
    values: CreatePracticeAccountMutationVariables,
    { resetForm }: FormikHelpers<CreatePracticeAccountMutationVariables>
  ) => {
    if (submitButtonRef.current) {
      submitButtonRef.current.click();
    } else {
      handleCreatePracticeAccount(values, resetForm);
    }
  };

  const handleCreatePracticeAccount = (
    values: CreatePracticeAccountMutationVariables,
    resetForm?: () => void
  ) => {
    createPracticeAccount({
      practice: values.practice,
      userDoctors: values.userDoctors,
    })
      .unwrap()
      .then(() => {
        setIsSuccessModalOpen(true);
        resetForm?.();
      })
      .catch((error) => {
        console.error('Error creating practice account:', error);
      });
  };

  return (
    <>
      <PageContainer>
        <AccountCreatorSidebar accountBrand={accountBrand} />

        <FormContainer>
          <Formik
            initialValues={initialFormValues}
            onSubmit={onSubmit}
            validate={validateForm}
          >
            <FormSections
              salesforceChildAccountId={salesforceChildAccountId}
              setSalesforceChildAccountId={setSalesforceChildAccountId}
              setAccountBrand={setAccountBrand}
              submitButtonRef={submitButtonRef}
              submitForm={(values) => handleCreatePracticeAccount(values)} // optional if used elsewhere
            />
          </Formik>
        </FormContainer>
      </PageContainer>

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        practiceId={createPracticeAccountData?.practice?.id}
      />
    </>
  );
};

export default AccountCreatorView;
