import React, { ReactNode } from 'react';

import DocumentTitle from 'components/DocumentTitle';
import {
  PageSection,
  PageBody,
  ResourceContainers,
  StyledArrow,
  Title,
} from 'pages/MarketingResources/MarketingResources.css';
import ResourceContainer from 'components/ResourceContainer/ResourceContainer';
import {
  IgnitionKit,
  InOfficeMarketing,
} from 'components/ResourceContainer/constants';
import { useAuthContext } from 'context/AuthContext';
import { getBrandSettings, getBrandFromDomain } from 'utils/brands';

const MarketingResources = () => {
  const { userInfo } = useAuthContext();
  const configs = userInfo?.brandInfo?.configs?.marketingResources;
  const digitalIgnitionKitUrl = configs?.digitalIgnitionKit ?? '';
  const orderFormUrl = configs?.orderForm ?? '';
  const { marketingEmail } = getBrandSettings(getBrandFromDomain());

  const defaultButtonContent = (text: string): ReactNode => {
    return (
      <>
        <div>{text}</div>
        <StyledArrow />
      </>
    );
  };
  return (
    <DocumentTitle title="Marketing resources">
      <PageSection>
        <PageBody>
          <Title>Marketing resources</Title>
          <ResourceContainers>
            <ResourceContainer
              title={IgnitionKit.title}
              bodyContent={IgnitionKit.body}
              buttonContent={defaultButtonContent(IgnitionKit.buttonCTA)}
              maxWidth="680px"
              buttonAction={() => window.open(digitalIgnitionKitUrl, '_blank')}
              imageUrl={IgnitionKit.imageUrl}
            />

            <ResourceContainer
              title={InOfficeMarketing.title}
              bodyContent={InOfficeMarketing.body}
              buttonContent={defaultButtonContent(InOfficeMarketing.buttonCTA)}
              maxWidth="680px"
              buttonAction={() => window.open(orderFormUrl, '_blank')}
              imageUrl={InOfficeMarketing.imageUrl}
            />
          </ResourceContainers>
          <div>
            For more support with your practice's marketing, reach out to{' '}
            <a
              href="mailto:marketing@candidco.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              {marketingEmail}
            </a>
            .
          </div>
        </PageBody>
      </PageSection>
    </DocumentTitle>
  );
};

export default MarketingResources;
