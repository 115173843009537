import { useContext, useState, useEffect, useCallback } from 'react';
import { ZendeskContext } from 'core/components';
import { getBrandSettings, getBrandFromDomain } from 'utils/brands';
import api from 'state/api';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MeQuery } from 'generated/legacy/graphql';
import { useNotificationContext } from 'core/context/NotificationContext';
import { hasMessageField } from 'utils/typeCheck';

// these variables are used to prevent unnecessary login attempts to
// Zendesk. They live outside of the hook to be sure they're singular and
// persist across renders.
// While React context could be used, it's overkill for this use case,
// as these values are "private" to this hook; users of the hook don't
// need to know their values.
let authenticating = false;
let authenticated = false;

// This variable is used to cache the first JWT token fetched for the user.
// We cache only the first token so that the widget can use the result of the
// "test" fetch to authenticate the user without making an extra request
// automatically as the result of providing the jwtCallback to the widget.
// the jwtCallback will clear the cache after using the cached JWT token,
// and fetch a fresh, uncached JWT token for all subsequent calls.
let cachedJwt: string | null = null;

type ZendeskLoginFailedError = {
  message: string;
  reason: string;
  type: string;
};

const { useLazyGetZendeskJwtQuery } = api;

export enum WidgetVersion {
  Classic = 'classic',
  Messenger = 'messenger',
}
/**
 * Custom wrapper for the Zendesk Web Widget to assist with the migration from the current
 * classic widget to the new widget that has breaking changes.  Once this is verified to work
 * we can remove this code and port it over to Enamel's implementation of the Zendesk widget.
 */
const useZendesk = (userInfo: MeQuery['me']) => {
  const { 'enable-zendesk-widget-auth': enableZendeskWidgetAuth } = useFlags();
  const { showNotification } = useNotificationContext();

  const [getZendeskJwt] = useLazyGetZendeskJwtQuery();

  const jwtCallback = useCallback(
    async (cb: (jwt?: string | null) => void) => {
      /**
       * If the user info is available, fetch the JWT token for the user and pass it to the
       * callback function. That callback handles logging in the user to the Zendesk widget.
       * The widget also automatically calls this when the widget encounters a 401.
       *
       * This function will prefer to use a cached JWT token if it exists, and will fetch a new
       * JWT token if the cached token is not available. The cached token is cleared after use.
       */
      const useJwt = (jwt?: string | null) => {
        // when the widget consumes the JWT token, clear the cache.
        cb(jwt);
        cachedJwt = null;
      };

      if (userInfo) {
        authenticating = true;
        if (cachedJwt) {
          useJwt(cachedJwt);
          return;
        }
        return getZendeskJwt({ name: userInfo.doctor?.fullName })
          .unwrap()
          .then((jwt) => {
            useJwt(jwt);
          });
      }
    },
    [userInfo, getZendeskJwt]
  );

  const loginCallback = useCallback((response?: ZendeskLoginFailedError) => {
    // If the login fails, surface the error in a notification to the user.
    if (response) {
      showNotification(`${response.message}: ${response.reason}`, 'error');
    } else {
      authenticated = true;
    }
    authenticating = false;
  }, []);

  const { showZendesk } = getBrandSettings(getBrandFromDomain());
  const zendeskClassicProps = useContext(ZendeskContext);
  const { isWidgetLoaded } = zendeskClassicProps;
  const [widgetVersion, setWidgetVersion] = useState<string | null>(null);
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);

  useEffect(() => {
    if (!isWidgetLoaded) {
      return;
    }

    if (window?.zE?.widget === WidgetVersion.Messenger) {
      if (
        userInfo &&
        enableZendeskWidgetAuth &&
        !(authenticating || authenticated)
      ) {
        jwtCallback((jwtToCache) => {
          // Getting to the point means the JWT token was fetched without error,
          // so we can pass the jwtCallback to the widget.
          // We've also already retrieved a JWT token for the widget to use, so
          // we can cache it for the callback to use on the first call.
          if (jwtToCache) {
            cachedJwt = jwtToCache;
            window.zE(
              `${WidgetVersion.Messenger}`,
              'loginUser',
              jwtCallback,
              loginCallback
            );
          }
        }).catch((error) => {
          if (
            hasMessageField(error) &&
            // This error is expected for local development environments.
            error.message !==
              'Environment is not configured for Zendesk JWT generation.'
          ) {
            showNotification(error.message, 'error');
          }
        });
      }

      window.zE(`${WidgetVersion.Messenger}:on`, 'open', () => {
        setIsWidgetOpen(true);
      });

      window.zE(`${WidgetVersion.Messenger}:on`, 'close', () => {
        setIsWidgetOpen(false);
      });
    }
    setWidgetVersion(window?.zE?.widget);
  }, [window?.zE, jwtCallback]);

  const openSupportPage = () => {
    return window.open('/contact-us', '_blank');
  };

  const handleShowWidget = () => {
    if (isWidgetLoaded) {
      window.zE(WidgetVersion.Messenger, 'show');
    }
  };

  const handleOpenWidget = () => {
    if (isWidgetLoaded) {
      window.zE(WidgetVersion.Messenger, 'open');
    }
  };

  const handleCloseWidget = () => {
    if (isWidgetLoaded) {
      window.zE(WidgetVersion.Messenger, 'close');
    }
  };

  const logoutWidget = () => {
    if (isWidgetLoaded && enableZendeskWidgetAuth) {
      window.zE(WidgetVersion.Messenger, 'logoutUser');
      authenticated = false;
    }
  };

  if (widgetVersion === WidgetVersion.Classic) {
    // Only return the classic widget methods if the classic widget is detected
    return {
      showZendesk,
      openSupportPage,
      widgetVersion,
      logoutWidget,
      ...zendeskClassicProps,
    };
  }

  if (widgetVersion === WidgetVersion.Messenger) {
    return {
      showZendesk,
      openSupportPage,
      widgetVersion,
      isWidgetOpen,
      isWidgetLoaded,
      handleOpenWidget,
      handleShowWidget,
      handleCloseWidget,
      logoutWidget,
      // Stub out deprecated methods if classic widget has not been detected
      setCustomerInfo: () => {},
      setDepartment: () => {},
    };
  }

  // Return empty stubs if neither widget is detected
  return {
    showZendesk,
    openSupportPage,
    widgetVersion,
    isWidgetOpen,
    isWidgetLoaded: () => {},
    handleOpenWidget: () => {},
    handleShowWidget: () => {},
    handleCloseWidget: () => {},
    logoutWidget: () => {},
    setCustomerInfo: () => {},
    setDepartment: () => {},
  };
};

export default useZendesk;
