import React from 'react';
import { ActionSection } from 'pages/TpCentralEnhanced/Actions/components';
import { useTranslation } from 'react-i18next';
import { TreatmentPlanStaging } from 'generated/core/graphql';
import { GridContainer } from 'styles/layout.css';
import { Grid } from 'core/components';
import { Overline } from 'styles/text.css';

const SafeLink = ({ href, text }: { href?: string | null; text: string }) => {
  if (!href) {
    return <span>No link found</span>;
  }
  return (
    <a rel="noreferrer" target="_blank" href={href}>
      {text}
    </a>
  );
};

export const ValidationContainer = ({
  isLoading = true,
  selectedTP,
}: {
  isLoading: boolean;
  selectedTP: TreatmentPlanStaging;
}) => {
  const { t } = useTranslation();
  return (
    <ActionSection
      title="Validation"
      buttonTitle={t('common.open')}
      isDisabled={isLoading}
      disabledText={t('tp_central_enhanced.this_is_disabled')}
    >
      <GridContainer numColumns={3}>
        <Grid>
          <Overline style={{ justifyContent: 'center' }}>
            Archform Version
          </Overline>
          {selectedTP?.data?.software?.version}
        </Grid>
        <Grid>
          <Overline style={{ justifyContent: 'center' }}>Production</Overline>
          <SafeLink
            text={'Webeditor link'}
            href={selectedTP?.diagnosticFields?.editorUrlProd}
          />
        </Grid>
        <Grid>
          <Overline style={{ justifyContent: 'center' }}>Preview</Overline>
          <SafeLink
            text={'Webeditor link'}
            href={selectedTP?.diagnosticFields?.editorUrlStage}
          />
        </Grid>
        <Grid>
          <Overline style={{ justifyContent: 'center' }}>
            Webviewer version
          </Overline>
          {selectedTP?.data?.software?.webviewerCliVersion}
        </Grid>
        <Grid>
          <Overline style={{ justifyContent: 'center' }}>Production</Overline>
          <SafeLink
            text={'Webviewer link'}
            href={selectedTP?.diagnosticFields?.viewUrlProd}
          />
        </Grid>
        <Grid>
          <Overline style={{ justifyContent: 'center' }}>Preview</Overline>
          <SafeLink
            text={'Webviewer link'}
            href={selectedTP?.diagnosticFields?.viewUrlStage}
          />
        </Grid>
        <Grid />
      </GridContainer>
    </ActionSection>
  );
};
