import React, { useState } from 'react';
import { Modal, Divider, Grid, type, theme } from 'core/components';
import {
  useDoctorsQuery,
  useUpdateCustomerMutation,
  UpdateCustomerInput,
} from 'generated/legacy/graphql';
import {
  ActionButtons,
  FormContainer,
  StyledButton,
  StyledFormikSelectWrapper,
  StyledGrid,
} from 'pages/OrthoPrism/EditClinicianModal/EditClinicianModal.css';
import { Formik, FormikValues } from 'formik';
import { useNotificationContext } from 'core/context/NotificationContext';

type EditClinicianModalProps = {
  isOpen?: boolean;
  onClose: () => void;
  onSuccess: () => void;
  clinician: string | undefined;
  customerId: string;
};

const EditClinicianModal = ({
  isOpen,
  onClose,
  onSuccess,
  clinician,
  customerId,
}: EditClinicianModalProps) => {
  const { showNotification } = useNotificationContext();
  const [updateCustomer] = useUpdateCustomerMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCancel = () => {
    onClose();
  };

  const onSubmit = async (e: FormikValues) => {
    setIsSubmitting(true);
    const values = e.target;
    const doctorId = values.orthodontist.value;

    try {
      if (doctorId) {
        const customerInput: UpdateCustomerInput = {
          customerId: customerId,
          updates: { doctorId },
        };
        await updateCustomer({
          variables: {
            customerInput,
          },
        });
      }

      handleCancel();
    } catch (err: unknown) {
      showNotification('Error updating info', 'error');
      setIsSubmitting(false);
    }
    onSuccess();
  };

  const { data: orthoData } = useDoctorsQuery({
    variables: {
      specialty: 'orthodontist',
    },
  });

  const orthos = orthoData?.doctors || [];

  const orthoOptions = orthos.map((ortho) => ({
    value: ortho.id.toString(),
    displayValue: ortho.fullName,
  }));

  const initialValues = {
    orthodontist:
      orthoOptions.find((ortho) => ortho.displayValue === clinician)?.value ||
      '',
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCancel}
      closeButtonColor={theme.colors.text60}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        <FormContainer
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(e);
          }}
        >
          <Grid container>
            <StyledGrid item xs={11}>
              <type.H4>{'Candid clinician'}</type.H4>
            </StyledGrid>
            <StyledGrid item xs={12}>
              <Divider />
            </StyledGrid>
            <StyledGrid item xs={11}>
              <StyledFormikSelectWrapper
                name="orthodontist"
                type="select"
                label="Orthodontist"
                options={orthoOptions}
              />
            </StyledGrid>
            <StyledGrid item xs={12}>
              <Divider />
            </StyledGrid>
          </Grid>
          <ActionButtons>
            <StyledButton
              onClick={handleCancel}
              type="button"
              buttonType="tertiary"
            >
              Cancel
            </StyledButton>
            <StyledButton
              buttonType="secondary"
              isShort
              isLoading={isSubmitting}
              type="submit"
            >
              Save
            </StyledButton>
          </ActionButtons>
        </FormContainer>
      </Formik>
    </Modal>
  );
};

export default EditClinicianModal;
