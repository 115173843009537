import {
  EditorFooterContainer,
  IconButtonContainer,
  IconButton,
  EditorHeaderContainer,
  LengthTracker,
  NoteEditorContainer,
  StyledTextArea,
  StyledEditIcon,
  StyledPlusIcon,
  StyledTrashIcon,
  LastModifiedText,
  StartText,
} from 'pages/Patient/PatientDetail/NoteEditor/NoteEditor.css';
import { Button, Skeleton } from 'core/components';
import { Maybe, Note } from 'generated/core/graphql';
import React, { useEffect, useRef, useState } from 'react';
import api from 'state/api';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getBrandFromDomain, getBrandSupportedFeatures } from 'utils/brands';
import moment from 'moment';
import ConfirmDialog from 'components/ConfirmDialog';
import { useAuthContext } from 'context/AuthContext';
import { useNotificationContext } from 'core/context/NotificationContext';
const { UsePatientNotes: brandEnablesPatientNotes } =
  getBrandSupportedFeatures(getBrandFromDomain());

type NoteEditorProps = { patientId: string; patientReferringDentistId: string };

type EditorHeaderProps = {
  userIsReferringDentist: boolean;
  canEditNoteInput: boolean;
  setIsEditingNote: (value: boolean) => void;
  handleClearNote: () => void;
  noteHasText: boolean;
};

type EditorBodyProps = {
  loading: boolean;
  noteTextInputValue: string;
  setNoteTextInputValue: (value: string) => void;
  isEditingNote: boolean;
};

type EditorButtonsProps = {
  note: Maybe<Note>;
  isEditingNote: boolean;
  handleCancelEdit: () => void;
  isSavingNote: boolean;
  handleSaveNote: () => void;
  canSaveNote: boolean;
};

const NOTE_MAX_LENGTH = 250;

const ClearIconButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) => (
  <IconButton onClick={onClick} disabled={disabled} type="button">
    <StyledTrashIcon />
  </IconButton>
);

const CreateOrEditIconButton = ({
  onClick,
  disabled,
  showCreateIcon,
}: {
  onClick: () => void;
  disabled: boolean;
  showCreateIcon: boolean;
}) => (
  <IconButton onClick={onClick} disabled={disabled} type="button">
    {showCreateIcon ? <StyledPlusIcon /> : <StyledEditIcon />}
  </IconButton>
);

const EditorHeader = ({
  userIsReferringDentist,
  canEditNoteInput,
  setIsEditingNote,
  handleClearNote,
  noteHasText,
}: EditorHeaderProps) => {
  const [isClearNoteDialogOpen, setIsClearNoteDialogOpen] = useState(false);

  const handleConfirmClearNote = () => {
    handleClearNote();
    setIsClearNoteDialogOpen(false);
  };

  return (
    <EditorHeaderContainer>
      <h3>Internal Notes</h3>
      {userIsReferringDentist && (
        <IconButtonContainer>
          {noteHasText && (
            <ClearIconButton
              onClick={() => setIsClearNoteDialogOpen(true)}
              disabled={!canEditNoteInput}
            />
          )}
          <CreateOrEditIconButton
            onClick={() => setIsEditingNote(true)}
            disabled={!canEditNoteInput}
            showCreateIcon={!noteHasText}
          />
        </IconButtonContainer>
      )}
      <ConfirmDialog
        isOpen={isClearNoteDialogOpen}
        onClose={() => setIsClearNoteDialogOpen(false)}
        onCancel={() => setIsClearNoteDialogOpen(false)}
        onConfirm={handleConfirmClearNote}
      >
        Are you sure you want to clear this note?
      </ConfirmDialog>
    </EditorHeaderContainer>
  );
};

const EditorBody = ({
  loading,
  noteTextInputValue,
  setNoteTextInputValue,
  isEditingNote,
}: EditorBodyProps) => {
  const textareRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (isEditingNote) {
      textareRef.current?.focus();
      textareRef.current?.setSelectionRange(
        textareRef.current.value.length,
        textareRef.current.value.length
      );
    }
  }, [isEditingNote]);

  return loading ? (
    <Skeleton variant="rect" height={'3rem'} />
  ) : (
    <StyledTextArea
      ref={textareRef}
      value={noteTextInputValue}
      onChange={(e) => setNoteTextInputValue(e.target.value)}
      disabled={!isEditingNote}
    />
  );
};

const EditorButtons = ({
  handleCancelEdit,
  isSavingNote,
  handleSaveNote,
  canSaveNote,
}: EditorButtonsProps) => (
  <>
    <Button
      onClick={handleCancelEdit}
      type="button"
      buttonSize="small"
      buttonType="tertiary"
    >
      Cancel
    </Button>
    <Button
      onClick={handleSaveNote}
      disabled={!canSaveNote}
      isLoading={isSavingNote}
      type="button"
      buttonSize="small"
      buttonType="secondary"
    >
      Save
    </Button>
  </>
);

const NoteEditor = ({
  patientId,
  patientReferringDentistId,
}: NoteEditorProps) => {
  const { showNotification } = useNotificationContext();
  const { userInfo } = useAuthContext();
  const userIsReferringDentist =
    userInfo?.doctor?.id === patientReferringDentistId;

  const { 'enable-patient-notes': featureFlagEnablesPatientNotes } = useFlags();

  const enablePatientNotes =
    brandEnablesPatientNotes || featureFlagEnablesPatientNotes;

  const {
    useGetPatientNoteQuery,
    useUpdatePatientNoteMutation,
    useCreatePatientNoteMutation,
  } = api;

  const { data: noteData, isLoading: isLoadingNote } = useGetPatientNoteQuery(
    {
      patientId,
    },
    {
      skip: !enablePatientNotes,
    }
  );

  const [note, setNote] = useState<Maybe<Note>>(noteData ?? null);
  const [noteTextInputValue, setNoteTextInputValue] = useState(
    noteData?.text || ''
  );
  const [isEditingNote, setIsEditingNote] = useState(false);
  const [createNote, { isLoading: isCreatingNote }] =
    useCreatePatientNoteMutation();
  const [updateNote, { isLoading: isUpdatingNote }] =
    useUpdatePatientNoteMutation();

  useEffect(() => {
    if (noteData) {
      setNote(noteData);
      setNoteTextInputValue(noteData.text || '');
    }
  }, [noteData]);

  const isSavingNote = isCreatingNote || isUpdatingNote;

  // you can edit the input if the note isn't loading, and
  // it's not already being edited or saved
  const canEditNoteInput =
    userIsReferringDentist && !isLoadingNote && !isEditingNote && !isSavingNote;

  // you can create a note if it doesn't exist and it's not
  // still being loaded, or already being created
  const canCreateNote = !isLoadingNote && !isCreatingNote && !note;

  // you can update a note if it does exist after
  // being loaded, and it's not already being updated
  const canUpdateNote = !isLoadingNote && !isUpdatingNote && !!note;

  // you can save a note if you can create or update it
  const canSaveNote = canUpdateNote || canCreateNote;

  const noteHasText = !!note?.text;

  // we should display the note if it is still loading;
  // we should also display the note if it loaded and it has text;
  // we should also display the note if it loaded and it's empty,
  //     but only if the user is the referring dentist (since they can
  //     create the note)
  const displayNote = isLoadingNote || noteHasText || userIsReferringDentist;

  // we should display the editor body and footer if the note is still loading,
  // if the note loaded and has text, or if the user is editing the note.
  const displayEditorBodyAndFooter =
    isLoadingNote || noteHasText || isEditingNote;

  const displayStartText =
    !displayEditorBodyAndFooter && userIsReferringDentist;

  const noteTextIsOverMaxLength = noteTextInputValue.length > NOTE_MAX_LENGTH;

  const handleCreateNote = async () => {
    try {
      const result = await createNote({
        input: { patientId, text: noteTextInputValue },
      }).unwrap();
      if (!result?.note) {
        showNotification('Failed to create note', 'error');
        return;
      }
      setNote(result.note);
      setIsEditingNote(false);
    } catch (e) {
      if (e instanceof Object && 'message' in e) {
        showNotification(e.message as string, 'error');
      }
    }
  };

  const handleUpdateNote = async (updatedNote: Note) => {
    try {
      const result = await updateNote({
        input: { noteId: updatedNote.id, text: noteTextInputValue },
      }).unwrap();
      if (!result?.note) {
        showNotification('Failed to save note', 'error');
        return;
      }
      setNote(result.note);
      setIsEditingNote(false);
    } catch (e) {
      if (e instanceof Object && 'message' in e) {
        showNotification(e.message as string, 'error');
      }
    }
  };

  const handleClearNote = async () => {
    try {
      const result =
        note &&
        (await updateNote({
          input: { noteId: note?.id, text: '' },
        }).unwrap());
      if (!result?.note) {
        showNotification('Failed to clear note', 'error');
        return;
      }
    } catch (e) {
      if (e instanceof Object && 'message' in e) {
        showNotification(e.message as string, 'error');
      }
    }
  };

  const handleCancelEdit = () => {
    setIsEditingNote(false);
    setNoteTextInputValue(note?.text || '');
  };

  const handleSaveNote = async () => {
    if (!note) {
      handleCreateNote();
    } else {
      handleUpdateNote(note);
    }
  };

  return (
    enablePatientNotes &&
    displayNote && (
      <NoteEditorContainer>
        <EditorHeader
          userIsReferringDentist={userIsReferringDentist}
          canEditNoteInput={canEditNoteInput}
          setIsEditingNote={setIsEditingNote}
          handleClearNote={handleClearNote}
          noteHasText={noteHasText}
        />
        {displayEditorBodyAndFooter && (
          <>
            <EditorBody
              loading={isLoadingNote}
              noteTextInputValue={noteTextInputValue}
              setNoteTextInputValue={setNoteTextInputValue}
              isEditingNote={isEditingNote}
            />
            <EditorFooterContainer>
              {isEditingNote ? (
                <>
                  <LengthTracker isOverMaxLength={noteTextIsOverMaxLength}>
                    {`${noteTextInputValue.length}/${NOTE_MAX_LENGTH}`}
                  </LengthTracker>
                  <EditorButtons
                    note={note}
                    isEditingNote={isEditingNote}
                    handleCancelEdit={handleCancelEdit}
                    isSavingNote={isSavingNote}
                    handleSaveNote={handleSaveNote}
                    canSaveNote={canSaveNote}
                  />
                </>
              ) : (
                noteHasText && (
                  <LastModifiedText>
                    <i>Last updated:</i>
                    <i>
                      {moment(note?.updatedAt ?? note?.createdAt).format(
                        'M/DD/YYYY h:mm a'
                      )}
                    </i>
                  </LastModifiedText>
                )
              )}
            </EditorFooterContainer>
          </>
        )}
        {displayStartText && (
          <StartText>
            Click "+" to add an internal note about this patient'
          </StartText>
        )}
      </NoteEditorContainer>
    )
  );
};

export default NoteEditor;
