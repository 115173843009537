import {
  Maybe,
  JourneyComponent,
  JourneyState,
} from 'generated/legacy/graphql';
import { RequiredNonNullable } from 'utils/typeCheck';

export const ALIGNER_JOURNEY_TYPE = 'aligner_journey';
export const ALIGNER_PRODUCTION_LEG = 'production_aligner_leg';

type ValidJourneyState = RequiredNonNullable<
  JourneyState,
  'state' | 'transition'
>;

const isValidJourneyState = (
  journeyState: Maybe<JourneyState>
): journeyState is ValidJourneyState => {
  return !!journeyState?.state && !!journeyState?.transition;
};

type ValidJourneyComponentData = RequiredNonNullable<
  JourneyComponent,
  'component'
> & {
  states: ValidJourneyState[];
};

export const isValidJourneyComponentData = (
  componentData: Maybe<JourneyComponent>
): componentData is ValidJourneyComponentData => {
  return (
    !!componentData?.component &&
    !!componentData?.states &&
    componentData.states.every(isValidJourneyState)
  );
};

export const ScanJourneyTransitions = {
  RESET: 'reset',
  MAIL_PVS: 'mail_pvs',
  SYNC_SCANS: 'sync_scans',
  UPLOAD: 'upload',
  REQUEST_ACTION: 'request_clinician_action',
  REQUEST_REVIEW: 'request_review',
  COMPLETE: 'complete',
};
