import React, { useState } from 'react';
import { Grid, Loading } from 'core/components';
import moment from 'moment';

import PencilSVG from 'assets/pencil.svg?react';
import CopyableText from 'components/CopyableText';
import {
  SaveButton,
  OrderDetailWrapper,
  OrderDetailHeader,
  OrderDetailContents,
  OrderDetailFieldTitle,
  EditableFieldWrapper,
  OrderDetailFieldContainer,
  ShippingText,
  ActionContainer,
  ViewTrackingButton,
} from 'components/Modals/OrderShippingModal/OrderShipping.css';

import {
  EditableFieldProps,
  OrderDetailFieldProps,
  OrderDetailContainerProps,
  OrderItemsWithShipment,
} from 'components/Modals/OrderShippingModal/types';
import { useGQLMutation } from 'hooks/useGQL';
import {
  ShippingTrackerStatusChoices,
  UpdateShipment,
  UpdateShipmentDocument,
  UpdateShipmentMutationVariables,
} from 'generated/core/graphql';
import { CoreShippingDetails } from 'components/Modals/OrderShippingModal/types';
import { OpenInNew } from '@material-ui/icons';
import { useNotificationContext } from 'core/context/NotificationContext';

const EditableField = ({ onChange, value }: EditableFieldProps) => {
  const textInput = React.createRef<HTMLInputElement>();
  const focusInput = () => {
    textInput.current?.focus();
  };

  return (
    <EditableFieldWrapper onClick={focusInput}>
      <input
        ref={textInput}
        onChange={onChange}
        placeholder="--"
        type="text"
        value={value ?? ''}
      />
      <PencilSVG />
    </EditableFieldWrapper>
  );
};

const OrderDetailField = ({
  title,
  value,
  editable,
  copyable = false,
  onChange = () => null,
}: OrderDetailFieldProps) => {
  const displayValue = value || '–';

  return (
    <OrderDetailFieldContainer>
      <OrderDetailFieldTitle>{title}</OrderDetailFieldTitle>
      <div>
        {typeof displayValue === 'string' ? (
          <>
            {editable ? (
              <EditableField onChange={onChange} value={displayValue} />
            ) : (
              displayValue
            )}
            {copyable && (
              <CopyableText label="Tracking Number" text={displayValue} />
            )}
          </>
        ) : (
          displayValue
        )}
      </div>
    </OrderDetailFieldContainer>
  );
};

const OrderDetailForm = ({
  isInbound,
  shipmentDetail,
  selectedOrderItem,
  internal,
}: {
  isInbound: boolean;
  shipmentDetail: CoreShippingDetails;
  selectedOrderItem?: OrderItemsWithShipment;
  internal: boolean;
}) => {
  const {
    shipped_at,
    delivered_at,
    status,
    carrier,
    tracking_id,
    shipmentRef,
    publicUrl,
  } = shipmentDetail;
  const { showNotification } = useNotificationContext();
  const [updateShipment] = useGQLMutation<
    UpdateShipment,
    UpdateShipmentMutationVariables
  >(UpdateShipmentDocument);

  const [tracking_id_value, setTrackingIdValue] = useState(tracking_id);
  const [isUpdatingOrder, setIsUpdatingOrder] = useState(false);

  const inboundShipmentItemData = isInbound ? shipmentDetail : undefined;
  const outboundShipmentItemData = !isInbound ? selectedOrderItem : undefined;

  const displayShippingDetails = //don't show shipping status/tracking details to providers if the order is pretransit
    internal || status !== ShippingTrackerStatusChoices.PreTransit;

  if (!inboundShipmentItemData && !outboundShipmentItemData) {
    return null;
  }

  const { shipping_address: shippingAddress } = outboundShipmentItemData || {};

  const renderAddressField = () => {
    if (outboundShipmentItemData) {
      const name = shippingAddress?.name;
      const addressLines = getAddressLines();
      return (
        <>
          {addressLines && name && <ShippingText>{name}</ShippingText>}
          {addressLines ? (
            <>
              <ShippingText>{addressLines['addressLine']}</ShippingText>
              <ShippingText>{addressLines['cityLine']}</ShippingText>
            </>
          ) : (
            <ShippingText>No address on order</ShippingText>
          )}
        </>
      );
    }
  };

  /**
   * Updates the tracking number of a shipment
   */
  const updateShipmentTracking = async (
    shipmentRef: string,
    trackingNumber: string
  ) => {
    try {
      setIsUpdatingOrder(true);
      await updateShipment({
        shipmentId: shipmentRef,
        updates: {
          trackingId: trackingNumber,
        },
      });

      showNotification(
        'Successfully updated the shipment tracking number',
        'success'
      );
      setIsUpdatingOrder(false);
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      setIsUpdatingOrder(false);
      showNotification(err.message, 'error');
    }
  };

  const getAddressLines = () => {
    const orderShippingAddress = outboundShipmentItemData?.shipping_address;
    if (orderShippingAddress) {
      return {
        addressLine: orderShippingAddress?.address_lines.join(', '),
        cityLine: `${orderShippingAddress?.city}, ${orderShippingAddress?.state_code} ${orderShippingAddress?.zip}`,
      };
    }
    return null;
  };

  return (
    <Grid container spacing={1}>
      {!isInbound && (
        <Grid item xs={12}>
          <OrderDetailField title="SHIP ADDRESS" value={renderAddressField()} />
        </Grid>
      )}
      {displayShippingDetails && (
        <>
          <Grid item xs={6}>
            <OrderDetailField
              title="SHIP DATE"
              value={
                shipped_at ? moment(shipped_at).format('M/D/YY, h:mma') : '–'
              }
            />
          </Grid>
          <Grid item xs={6}>
            <OrderDetailField
              title="DELIVERY DATE"
              value={
                delivered_at
                  ? moment(delivered_at).format('M/D/YY, h:mma')
                  : '–'
              }
            />
          </Grid>
          <Grid item xs={6}>
            <OrderDetailField title="SHIPPING STATUS" value={status} />
          </Grid>
          <Grid item xs={6}>
            <OrderDetailField title="CARRIER" value={carrier} />
          </Grid>
          <Grid item xs={12}>
            <OrderDetailField
              title="TRACKING NUMBER"
              value={tracking_id_value ? tracking_id_value : '–'}
              copyable={!internal && !!tracking_id}
              editable={internal}
              onChange={(e: any) => setTrackingIdValue(e.target.value)}
            />
          </Grid>
        </>
      )}
      {!!shippingAddress && (
        <Grid item xs={12}>
          <OrderDetailField
            title="SEND TRACKING UPDATES TO PATIENT"
            value={
              outboundShipmentItemData?.sent_patient_shipping_update
                ? 'Yes'
                : 'No'
            }
          />
        </Grid>
      )}
      {displayShippingDetails && (
        <ActionContainer>
          {!isUpdatingOrder &&
            shipmentRef &&
            tracking_id_value &&
            tracking_id_value !== tracking_id && (
              <SaveButton
                onClick={() => {
                  updateShipmentTracking(shipmentRef, tracking_id_value);
                }}
              >
                Save
              </SaveButton>
            )}
          {isUpdatingOrder && <Loading />}
          {publicUrl && (
            <Grid item xs={12}>
              <ViewTrackingButton
                buttonType="secondary-outline"
                onClick={() => {
                  window.open(publicUrl, '_blank', 'noopener');
                }}
              >
                <OpenInNew /> View tracking
              </ViewTrackingButton>
            </Grid>
          )}
        </ActionContainer>
      )}
    </Grid>
  );
};

const OrderDetailContainer = ({
  internal = false,
  isInbound = false,
  selectedOrderItem,
  shipmentDetail,
  title = 'Shipment details',
}: OrderDetailContainerProps) => {
  /*
    Replacements metadata format:
    {"AKREPL0001": {"aligner_stages": ["U01", "L01"]}}
  */

  const replacements: unknown[] = [];
  return (
    <OrderDetailWrapper>
      <OrderDetailHeader>{`${title} - Order Ref ${selectedOrderItem?.short_order_ref}`}</OrderDetailHeader>
      <OrderDetailContents>
        <OrderDetailForm
          internal={internal}
          isInbound={isInbound}
          shipmentDetail={shipmentDetail}
          selectedOrderItem={selectedOrderItem}
        />
        {replacements?.length > 0 && (
          <div style={{ marginTop: '1rem' }}>
            <OrderDetailField
              title="REPLACEMENTS"
              value={replacements.toString()}
            />
          </div>
        )}
      </OrderDetailContents>
    </OrderDetailWrapper>
  );
};

export default OrderDetailContainer;
