import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Button, colors, mediaQueries } from 'core/components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import HeaderLogo from 'components/Header/Logo';
import { useDispatch, useSelector } from 'react-redux';
import {
  Header,
  HeaderContent,
  HeaderTitle,
  StatusContainer,
} from 'pages/Patient/CaseCreator/CaseCreator.css';

import {
  selectPatientName,
  selectActiveCase,
  resetPatientState,
} from 'pages/Patient/patientSlice';
import useSubmitCase from 'pages/Patient/CaseCreator/useSubmitCase';
import ExitModal from 'pages/Patient/CaseCreator/ExitModal';
import ZendeskWidget from 'components/ZendeskWidget';
import { getCaseTypeLabel } from 'utils/case';
import { useNotificationContext } from 'core/context/NotificationContext';

const CaseCreatorHeader = () => {
  const { canSubmitCase, isCaseSubmitted } = useSubmitCase();
  const { 'case-creator-auto-save': enableAutoSave } = useFlags();
  const activeCase = useSelector(selectActiveCase);
  const caseType = getCaseTypeLabel(activeCase?.caseType?.label ?? '');
  const tabletSize = useMediaQuery(mediaQueries.tabletAndAbove);
  const patientName = useSelector(selectPatientName);
  const dispatch = useDispatch();
  const location = useLocation();

  //When the user is leaving this page, the next page we'll navigate to
  const [exitPath, setExitPath] = useState('');
  const { showNotification } = useNotificationContext();
  const headerPrefix =
    (location.pathname.includes('checkout') ? 'Order retainers' : null) ||
    (activeCase ? caseType : 'New Patient');
  let title = `${headerPrefix}${patientName ? ` • ${patientName}` : ''}`;
  const { push } = useHistory();
  const [isCaseUnsubmittedModalOpen, setIsCaseUnsubmittedModalOpen] =
    useState(false);

  const [isPatientUncreatedModalOpen, setIsPatientUncreatedModalOpen] =
    useState(false);
  const { isSubmittingCase, submitCase } = useSubmitCase();

  const onSubmit = () => {
    submitCase({
      onSuccess: () => setIsCaseUnsubmittedModalOpen(false),
      onError: () => showNotification('Failed to submit case', 'error'),
    });
  };

  const exit = (newPath: string) => {
    push(newPath);
    dispatch(resetPatientState());
  };

  const onExit = (path: string) => {
    let newPath;
    if (path === '/') {
      newPath = '/action-items';
    } else if (path === '/loyalty') {
      newPath = '/loyalty';
    } else if (activeCase) {
      newPath = `/patient/${activeCase.patientId}`;
    } else {
      newPath = path;
    }
    // New UX relies on auto-save, if ff is off revert to old behavior
    if (enableAutoSave) {
      setExitPath(newPath);
      if (canSubmitCase && !isCaseSubmitted) {
        setIsCaseUnsubmittedModalOpen(true);
      } else if (!patientName && location.pathname.includes('basic-info')) {
        setIsPatientUncreatedModalOpen(true);
      } else {
        exit(newPath);
      }
    } else {
      exit(newPath);
    }
  };

  if (!tabletSize && patientName) {
    title = patientName ? patientName : '';
  }
  return (
    <Header>
      <HeaderContent>
        {tabletSize && (
          <HeaderLogo logoSubString={'Professional'} canExit={false} />
        )}

        <HeaderTitle data-private>{title}</HeaderTitle>
        <StatusContainer>
          {tabletSize && <ZendeskWidget text="Support" />}
          <Button
            buttonSize="small"
            buttonType="black-outline"
            style={{
              borderColor: colors.black70,
              color: colors.black70,
              fontSize: '16px',
            }}
            onClick={() => onExit('/action-items')}
          >
            Exit
          </Button>
        </StatusContainer>
      </HeaderContent>
      {enableAutoSave && (
        <>
          <ExitModal
            title="Are you sure?"
            body="It looks like your case is ready to submit. Do you want to submit it
          or do you plan to make changes later?"
            onAction={onSubmit}
            actionText="Submit"
            onExit={() => exit(exitPath)}
            exitText="Exit without submitting"
            isOpen={isCaseUnsubmittedModalOpen}
            isSubmitting={isSubmittingCase}
          />
          <ExitModal
            title="Exit without creating patient?"
            body="Exiting this flow before submitting all basic info will not create a
          patient record. Do you want to exit without creating a patient?"
            onAction={() => setIsPatientUncreatedModalOpen(false)}
            actionText="Complete basic info"
            onExit={() => exit(exitPath)}
            exitText="Exit"
            isOpen={isPatientUncreatedModalOpen}
          />
        </>
      )}
    </Header>
  );
};

export default CaseCreatorHeader;
