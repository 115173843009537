import FuzzySet from 'fuzzyset';

import { GenericAddressType } from 'generated/legacy/graphql';
import { Address as CoreAddress } from 'generated/core/graphql';

/**
 * Address match detection for patient & practice addresses.
 *
 * For a while, pro doctors have entered their own practice address
 * as the patient address in the customer creator to ensure the
 * aligners are shipped to their office. Now that we allow them to choose
 * the shipping address at the time of TP approval, we need to detect
 * previous cases where doctors may need to update the patients
 * actual home address.
 *
 * @param  {Object} practiceAddress   Address Object for practice
 * @param  {Object} patientAddress    Address Object for patient
 * @return {Boolean}                  Match Detected
 */

export type Address = {
  businessName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateCode: string;
  countryCode: string;
  zip: string;
};

const defaultAddress = {
  businessName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  stateCode: '',
  countryCode: '',
  zip: '',
};

/**
 * Convert address between core and legacy types.
 */
export const coreShippingAddressToGenericAddress = (
  address: CoreAddress | null | undefined
): GenericAddressType => {
  if (address) {
    return {
      addressLine1: address.addressLines[0],
      addressLine2: address.addressLines[1] || '',
      city: address.city,
      stateCode: address.adminRegion || '',
      zip: address.postalCode,
      id: '',
      billingcontactSet: [],
      personSet: {
        edges: [],
        pageInfo: { hasNextPage: false, hasPreviousPage: false },
      },
      practiceSet: {
        edges: [],
        pageInfo: { hasNextPage: false, hasPreviousPage: false },
      },
    };
  } else {
    return {
      addressLine1: '',
      addressLine2: '',
      city: '',
      stateCode: '',
      zip: '',
      id: '',
      billingcontactSet: [],
      personSet: {
        edges: [],
        pageInfo: { hasNextPage: false, hasPreviousPage: false },
      },
      practiceSet: {
        edges: [],
        pageInfo: { hasNextPage: false, hasPreviousPage: false },
      },
    };
  }
};

export const patientAddressSimilarToPractice = (
  practiceAddress: Address = defaultAddress,
  patientAddress: Address = defaultAddress
) => {
  const {
    businessName: practiceBusinessName,
    addressLine1: practiceAddressLine1,
    addressLine2: practiceAddressLine2,
    city: practiceCity,
    stateCode: practiceStateCode,
    countryCode: practiceCountryCode,
    zip: practiceZip,
  } = practiceAddress;
  const {
    businessName: patientBusinessName,
    addressLine1: patientAddressLine1,
    addressLine2: patientAddressLine2,
    city: patientCity,
    stateCode: patientStateCode,
    countryCode: patientCountryCode,
    zip: patientZip,
  } = patientAddress;

  // If zip and state do not match, there's a high chance
  // it is a unique address & not a match
  const zipMatch = patientZip === practiceZip;
  const stateMatch = patientStateCode === practiceStateCode;
  const countryMatch = patientCountryCode === practiceCountryCode;

  if (!zipMatch || !stateMatch || !countryMatch) {
    return false;
  }

  try {
    // https://glench.github.io/fuzzyset.js/
    // After adding a string to the set, you can pass in a new string with
    // "get", and you are returned an array with the score (% match) and
    // the passed in string.

    // It's possible for slight city typos, but if there's a large gap,
    // we should assume its a different address & return false
    const citySet = FuzzySet();
    citySet.add(practiceCity);
    const cityMatchScore = citySet.get(patientCity)[0][0];
    if (cityMatchScore < 0.8) {
      return false;
    }

    let businessMatchScore = 0;
    if (
      patientBusinessName &&
      patientBusinessName.length &&
      practiceBusinessName &&
      practiceBusinessName.length
    ) {
      const businessSet = FuzzySet();
      businessSet.add(practiceBusinessName);
      businessMatchScore = businessSet.get(patientBusinessName)[0][0];
    }

    const addressSet = FuzzySet();
    addressSet.add(`${practiceAddressLine1} ${practiceAddressLine2}`);
    const addressMatchScore = addressSet.get(
      `${patientAddressLine1} ${patientAddressLine2}`
    )[0][0];

    // If patient has business name, we are very confident it would
    // be a practice. Therefore we can be more lenient with the minimum score
    // threshold (here, allowing a 40% match vs a 60% match)
    const minMatchScore =
      patientBusinessName && businessMatchScore > 0.5 ? 0.4 : 0.6;
    if (addressMatchScore >= minMatchScore) {
      return true;
    }
  } catch (e) {
    // Error could be a library issue or a flat out mismatch,
    // in both cases we want to return false
    return false;
  }

  return false;
};
