import React, { useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';

import { colors } from 'core/components';
import {
  convertFromPalmerToUniversal,
  convertFromFDIToUniversal,
} from 'components/ToothChart/utils';
import { TOOH_CHART_ACTIONS } from 'types/treatmentObjective';
import { DentalNotationOptions } from 'generated/legacy/graphql';
import DoNotMove from 'assets/universalToothChartIcons/DoNotMoveIcon.svg?react';
import Extract from 'assets/universalToothChartIcons/ExtractIcon.svg?react';
import Eruption from 'assets/universalToothChartIcons/EruptionIcon.svg?react';

export type ConsolidatedToothChartType = (TOOH_CHART_ACTIONS | null)[];

export const ToothStateMetadata: Record<
  TOOH_CHART_ACTIONS,
  {
    id: TOOH_CHART_ACTIONS;
    ctaLabel: string;
    legendCopy: string;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    fillColor?: string;
    strokeColor?: string;
  }
> = {
  [TOOH_CHART_ACTIONS.CANNOT_MOVE]: {
    id: TOOH_CHART_ACTIONS.CANNOT_MOVE,
    ctaLabel: 'Do not move',
    legendCopy: 'Do not move',
    icon: DoNotMove,
    fillColor: colors.black20,
    strokeColor: colors.black70,
  },
  [TOOH_CHART_ACTIONS.WILL_EXTRACT]: {
    id: TOOH_CHART_ACTIONS.WILL_EXTRACT,
    ctaLabel: 'Will be extracted',
    legendCopy: 'Pre-treatment extraction',
    icon: Extract,
    fillColor: colors.red10,
    strokeColor: colors.red50,
  },
  [TOOH_CHART_ACTIONS.ERUPTION_COMPENSATION]: {
    id: TOOH_CHART_ACTIONS.ERUPTION_COMPENSATION,
    ctaLabel: 'Eruption compensation',
    legendCopy: 'Eruption compensation',
    icon: Eruption,
    fillColor: colors.orange10,
    strokeColor: colors.orange50,
  },
};

export const combineToothChartValues = (
  charts: Record<TOOH_CHART_ACTIONS, number[]>
): ConsolidatedToothChartType => {
  const combined: ConsolidatedToothChartType = [];

  for (const action in charts) {
    const values = charts[action as TOOH_CHART_ACTIONS] || [];
    values.forEach((value: number) => {
      combined[value] = action as TOOH_CHART_ACTIONS;
    });
  }

  return combined;
};

export const splitMergedToothChart = (
  merged: ConsolidatedToothChartType
): Record<TOOH_CHART_ACTIONS, number[]> => {
  const split: Record<TOOH_CHART_ACTIONS, number[]> = {
    [TOOH_CHART_ACTIONS.CANNOT_MOVE]: [],
    [TOOH_CHART_ACTIONS.WILL_EXTRACT]: [],
    [TOOH_CHART_ACTIONS.ERUPTION_COMPENSATION]: [],
  };

  merged.forEach((value: TOOH_CHART_ACTIONS | null, index: number) => {
    if (value) {
      split[value].push(index);
    }
  });

  return split;
};

/**
 * Utility hook containg logic for the consolidated tooth chart.
 * Allows us to maintain existing data structures (3x individual tooth charts) for better compatibility with existing charts,
 * while presenting a single consolidated tooth chart view to the user.
 *
 * Ex: Formik values are still 3x arrays (Record<TOOH_CHART_ACTIONS, number[]>):
 *
 * {
 *  cannotMove: [32, 16],
 *  willExtract: [5],
 *  eruptionCompensation: [24],
 * }
 *
 * But are merged to a single locally managed array for the tooth chart ((TOOH_CHART_ACTIONS | null)[]):
 *
 * [null × 5, 'willExtract', null × 10, 'cannotMove', null × 7, 'eruptionCompensation', null × 7, 'cannotMove']
 *
 * @param dentalNotation - The dental notation to use for the tooth chart
 */
const useConsolidatedToothChart = ({
  dentalNotation,
}: {
  dentalNotation?: DentalNotationOptions;
}) => {
  const [consolidatedToothChart, setConsolidatedToothChart] =
    useState<ConsolidatedToothChartType>([]);
  const [selectedToothNote, setSelectedToothNote] =
    useState<TOOH_CHART_ACTIONS>(TOOH_CHART_ACTIONS.CANNOT_MOVE);
  const { setFieldValue } = useFormikContext();

  const [{ value: cannotMoveValue }] = useField({
    name: TOOH_CHART_ACTIONS.CANNOT_MOVE,
  });
  const [{ value: virtualExtractionsValue }] = useField({
    name: TOOH_CHART_ACTIONS.WILL_EXTRACT,
  });
  const [{ value: eruptionCompensationValue }] = useField({
    name: TOOH_CHART_ACTIONS.ERUPTION_COMPENSATION,
  });
  const values = {
    [TOOH_CHART_ACTIONS.CANNOT_MOVE]: cannotMoveValue,
    [TOOH_CHART_ACTIONS.WILL_EXTRACT]: virtualExtractionsValue,
    [TOOH_CHART_ACTIONS.ERUPTION_COMPENSATION]: eruptionCompensationValue,
  };

  useEffect(() => {
    // Updates the consolidated tooth chart whenever the underlying formik values change
    setConsolidatedToothChart(combineToothChartValues(values));
  }, [cannotMoveValue, virtualExtractionsValue, eruptionCompensationValue]);

  const onToothClick = (quadrant: number, index: number) => {
    if (dentalNotation === DentalNotationOptions.Palmer) {
      index = convertFromPalmerToUniversal(quadrant, index);
    } else if (dentalNotation === DentalNotationOptions.Fdi) {
      index = convertFromFDIToUniversal(quadrant, index);
    }

    // Updates the underlying formik values with the new correct tooth state
    for (const action of Object.values(TOOH_CHART_ACTIONS)) {
      const oldValuesForAction = values[action] ? values[action] : [];

      if (selectedToothNote === action && !oldValuesForAction.includes(index)) {
        setFieldValue(action, [...oldValuesForAction, index]);
      } else {
        setFieldValue(
          action,
          oldValuesForAction.filter((value: number) => value !== index)
        );
      }
    }
  };

  return {
    selectedToothNote,
    consolidatedToothChart,
    onToothClick,
    setSelectedToothNote,
  };
};

export default useConsolidatedToothChart;
