import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Loading, type } from 'core/components';

import PatientHeader from 'components/PatientHeader/PatientHeader';
import DocumentTitle from 'components/DocumentTitle';
import {
  OpenTPSoftware,
  SyncTPToCandidButton,
  UploadManufacturingFiles,
} from 'pages/TpCentralEnhanced/Actions';
import {
  HeaderSection,
  TPVersionSection,
} from 'pages/TpCentralEnhanced/Actions/components';
import { VersionDetails } from 'pages/TpCentralEnhanced/Actions/components';
import { TpCentralEnhancedContext } from 'pages/TpCentralEnhanced/TpCentralEnhancedProvider';
import { MatchParams } from 'pages/TpCentralEnhanced/types';
import { getIsApproved, getTpState } from 'pages/TpCentralEnhanced/utils';
import { useTranslation } from 'react-i18next';
import { HeaderContainer } from 'styles/layout.css';
import MaterialSubmissionHistory from 'components/MaterialSubmissionHistory';
import { SubmissionFragment } from 'pages/OrthoPrism/orthoSlice';
import {
  CaseSoftwareChoice,
  Material,
  TreatmentPlanStaging,
} from 'generated/core/graphql';
import { getSubmissionsFromMaterials } from 'pages/Case/TreatmentPlan/PlanDetailsContainer';
import styled from 'styled-components/macro';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getCaseTypeLabel } from 'utils/case';
import { ValidationContainer } from 'pages/TpCentralEnhanced/Actions/ValidationContainer';

const HistoryColumn = styled.div`
  position: relative;
  width: 100%;
`;
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 600px;
  align-self: center;
  gap: 1rem;
  margin-top: 1rem;
`;
const TpUploadOverview = ({ match }: RouteComponentProps<MatchParams>) => {
  const {
    caseData,
    customerInfo,
    getTreatmentPlanStagingData,
    isFetchingData,
    treatmentPlanStagingData,
    setProviderState,
    parts,
  } = useContext(TpCentralEnhancedContext);
  const { 'tp-central-ui-enhancements': tpCentralEnhancedFlag } = useFlags();
  const caseType = getCaseTypeLabel(caseData?.caseType?.label);
  const fakeTp = { id: 1, iteration: 0 };
  const { t } = useTranslation();

  // caseData could be undefined, and we want the default to require ortho review
  const requireOrthoReview = caseData?.requireOrthoReview;
  const bypassOrthoReview =
    typeof requireOrthoReview == 'boolean' ? !requireOrthoReview : false;

  const selectedTreatmentPlan =
    treatmentPlanStagingData?.selectedTreatmentPlan || fakeTp;
  // manufacturingFilesExist is used to set the isComplete flag for the UploadManufacturingFiles component
  const [manufacturingFilesExist, setManufacturingFilesExist] = useState(false);
  const [hasStartedTreatmentPlanning, setHasStartedTreatmentPlanning] =
    useState(false);

  const selectedTreatmentPlanIteration = selectedTreatmentPlan?.iteration ?? 0;
  const treatmentPlanStagingMaterials =
    treatmentPlanStagingData?.treatmentPlanStagingMaterials || [];
  // Retrieve and format customer data
  const { caseRef, id: patientId } = match.params;
  const isLoading =
    !customerInfo || !treatmentPlanStagingData || isFetchingData;

  useEffect(() => {
    getTreatmentPlanStagingData(caseRef, patientId);
  }, [caseRef, patientId]);

  // Call fetchVisionSaveData only when selectedTreatmentPlan changes
  useEffect(() => {
    setHasStartedTreatmentPlanning(selectedTreatmentPlanIteration > 0);
    if (selectedTreatmentPlan?.treatmentPlanToolings?.length) {
      setManufacturingFilesExist(
        selectedTreatmentPlan?.treatmentPlanToolings?.length > 0
      );
    }
  }, [selectedTreatmentPlan]);

  // // Define treatment plan variables
  const latestTreatmentPlanStaging =
    treatmentPlanStagingData?.latestTreatmentPlanStaging;

  //True if it's the selected treatment plan is the latest treatment plan, AND it's approved
  const selectedTPVersionIsApproved = getIsApproved(
    selectedTreatmentPlan,
    latestTreatmentPlanStaging,
    caseData
  );

  //True if it's the latest treatment plan is approved, regardless of the selected treatment plan
  const latestTPVersionIsApproved = getIsApproved(
    latestTreatmentPlanStaging,
    latestTreatmentPlanStaging,
    caseData
  );

  const updatedPlans = [fakeTp, ...treatmentPlanStagingMaterials];

  return (
    <DocumentTitle
      title={
        isLoading
          ? t('common.loading')
          : `${customerInfo.name} - Treatment Plan Central`
      }
    >
      <PageContainer>
        {customerInfo ? (
          <PatientHeader
            customerInfo={customerInfo}
            caseRef={caseRef}
            isLoading={isLoading}
            providerFacingCaseState={caseData?.caseState?.internal}
            krakenCaseId={caseData?.krakenCaseIdentifier}
            showTopLinks
            showCaseActions
          />
        ) : (
          <div
            style={{
              background: '#fafafa',
              width: '100%',
              height: '132px',
            }}
          >
            <Loading isCentered />
          </div>
        )}

        <ActionsContainer>
          <HeaderSection caseType={caseType} />
          <TPVersionSection
            isLoading={isLoading}
            selectedTreatmentPlan={selectedTreatmentPlan}
            treatmentPlans={updatedPlans}
            onClick={(plan: any) => {
              setProviderState((draft) => {
                draft.treatmentPlanStagingData.selectedTreatmentPlan = plan;
                return draft;
              });
            }}
          />

          {selectedTreatmentPlan && (
            <VersionDetails
              treatmentPlan={selectedTreatmentPlan}
              state={getTpState(
                selectedTreatmentPlan,
                latestTreatmentPlanStaging,
                caseData
              )}
            />
          )}
          <OpenTPSoftware
            caseRef={caseData?.caseRef}
            software={caseData?.caseSoftware}
            isLoading={isLoading}
            selectedTP={selectedTreatmentPlan}
          />
          <SyncTPToCandidButton
            isComplete={hasStartedTreatmentPlanning}
            caseRef={caseRef}
            customerRef={patientId}
            isDisabled={
              hasStartedTreatmentPlanning || latestTPVersionIsApproved
            }
            software={caseData?.caseSoftware ?? CaseSoftwareChoice.Vision}
            bypassOrthoReview={bypassOrthoReview}
          />
          <UploadManufacturingFiles
            customerRef={patientId}
            caseData={caseData}
            isComplete={manufacturingFilesExist}
            isDisabled={
              !selectedTPVersionIsApproved ||
              selectedTreatmentPlan.id !== latestTreatmentPlanStaging.id
            }
            isApproved={selectedTPVersionIsApproved}
            selectedTreatmentPlan={selectedTreatmentPlan}
            parts={parts}
          />
          {selectedTreatmentPlan && (
            <ValidationContainer
              selectedTP={selectedTreatmentPlan}
              isLoading={isLoading}
            />
          )}
          {tpCentralEnhancedFlag && (
            <HistoryContainer
              treatmentPlanStagings={
                treatmentPlanStagingData?.treatmentPlanStagingMaterials
              }
            />
          )}
        </ActionsContainer>
      </PageContainer>
    </DocumentTitle>
  );
};

const HistoryContainer = ({
  treatmentPlanStagings,
}: {
  treatmentPlanStagings: TreatmentPlanStaging[];
}) => {
  const materials = (treatmentPlanStagings || []) as Material[];
  const submissions = useMemo(
    () => getSubmissionsFromMaterials(materials),
    [materials]
  );

  return (
    <HistoryColumn>
      <HeaderContainer>
        <type.H4>History</type.H4>
      </HeaderContainer>
      <MaterialSubmissionHistory
        submissions={submissions as SubmissionFragment[]}
        includeHeader={false}
        materialName="Treatment plan"
      />
    </HistoryColumn>
  );
};

export default TpUploadOverview;
