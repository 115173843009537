import React, { useEffect, useState, FormEvent, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { colors, Button, mediaQueries, type, Grid } from 'core/components';
import { isNotNil } from 'utils/typeCheck';
import { ErrorText, Input as FormInput } from 'styles/inputs.css';
import { ShippingRow } from 'components/SearchPages/Rows';
import {
  EnrichedActiveCaseForShipping,
  enrichShippingCase,
  useShippingContext,
} from 'pages/Shipping/utils';
import { useGQLQuery } from 'hooks/useGQL';
import {
  GetCasesDocument,
  GetCasesQuery,
  GetCasesQueryVariables,
} from 'generated/core/graphql';
import { coreShippingAddressToGenericAddress } from 'utils/shippingAddress';
import * as Sentry from '@sentry/react';
import { ApolloError } from '@apollo/client';
import { apolloErrorToString } from 'utils/apollo';

const SmallHeading = styled(type.H4)`
  margin-bottom: 1rem;
`;

const SearchForm = styled.form`
  display: flex;
`;

const Input = styled(FormInput)`
  flex: 1;
  width: 50%;
  max-width: 12rem;
  margin-right: 1rem;
`;

const SubmitButton = styled(Button)`
  @media ${mediaQueries.mobile} {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

const SearchResults = styled.section`
  padding-top: 5rem;
`;

const CasesTable = styled.div`
  border-top: 1px solid ${colors.black20};
`;

const ShippingSearchPage: React.FC<{ pushTo: string }> = ({
  pushTo = 'shipping',
}) => {
  const enrichCase = useCallback(enrichShippingCase(), []);
  const { setSelectedOrder, clearState } = useShippingContext();
  const [search, setSearch] = useState<string>('');
  const [cases, setCases] = useState<EnrichedActiveCaseForShipping[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orderQueryError, setOrderQueryError] = useState<string | null>(null);
  const [getCases, { data: casesData, errors: caseQueryError }] = useGQLQuery<
    GetCasesQuery,
    GetCasesQueryVariables
  >(GetCasesDocument);

  const { push } = useHistory();
  const { search: searchParams } = useLocation();
  const urlParams = new URLSearchParams(searchParams);
  const searchText = urlParams.get('searchTerm');

  const getQueryVariablesForGetCases = (value: string): Record<string, any> => {
    const parsedValue = Number(value);
    const queryVariables: Record<string, any> = {};
    if (!isNaN(parsedValue)) {
      queryVariables['patientIds'] = [parsedValue];
    } else {
      queryVariables['krakenCaseRefs'] = [value];
    }
    return queryVariables;
  };

  useEffect(() => {
    if (searchText) {
      setOrderQueryError(null);
      setIsLoading(true);
      setSearch(searchText);
      const queryVariables = getQueryVariablesForGetCases(searchText);
      getCases(queryVariables);
    }
  }, [searchText]);

  useEffect(() => {
    const setEnrichedCases = async () => {
      const enrichedCasesPromises =
        casesData?.getCases
          ?.filter((value) => {
            if (isNotNil(value) && value.patientId) {
              return true;
            } else if (!value.patientId) {
              Sentry.captureException(
                'this case has no patientId, so we will not search for its order items'
              );
            }
            return false;
          })
          .map(enrichCase) || [];
      Promise.all(enrichedCasesPromises)
        .then((res) => {
          setCases(res);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err instanceof ApolloError) {
            setOrderQueryError(apolloErrorToString(err));
          } else {
            setOrderQueryError('An error occurred while fetching cases');
          }
        });
    };
    if (casesData) {
      setEnrichedCases();
    }
  }, [casesData]);

  const onSubmitSearch = async (e: FormEvent) => {
    e.preventDefault();
    clearState();
    setCases([]);
    if (search) {
      setOrderQueryError(null);
      setIsLoading(true);
      push(`/${pushTo}?searchTerm=${search}`);
      const queryVariables = getQueryVariablesForGetCases(search);
      getCases(queryVariables);
    }
  };

  const selectCase = (caseItem: EnrichedActiveCaseForShipping) => {
    const orderItem = caseItem?.customerOrders?.filter(
      (orderItem) => orderItem.order.caseRef === caseItem?.caseRef
    )[0];
    setSelectedOrder({
      id: orderItem?.order?.id,
      journeyState: caseItem?.caseState?.providerFacing,
      patientId: caseItem?.patientId,
      krakenId: caseItem?.manufacturerExternalRef,
      shippingAddress: coreShippingAddressToGenericAddress(
        orderItem?.order.shippingAddress
      ),
      orderItems: caseItem?.customerOrders?.filter(
        (orderItem) => orderItem.order.caseRef === caseItem.caseRef
      ),
      associatedCase: caseItem,
    });
  };

  const Content = () => {
    if (isLoading) {
      return null;
    }

    if (caseQueryError || orderQueryError) {
      return (
        <ErrorText>{caseQueryError?.message || orderQueryError}</ErrorText>
      );
    }

    if (!cases || cases.length === 0) {
      return <p>There are no Pro cases for this customer.</p>;
    }
    return (
      <SearchResults>
        <CasesTable>
          {cases.map((caseItem: EnrichedActiveCaseForShipping, idx: any) => (
            <ShippingRow
              key={caseItem?.caseRef || idx}
              caseItem={caseItem}
              onSelect={() => selectCase(caseItem)}
            />
          ))}
        </CasesTable>
      </SearchResults>
    );
  };

  return (
    <Grid container direction="column">
      <SmallHeading>Search by customer ID, or Kraken Supplier ID</SmallHeading>
      <SearchForm>
        <Input
          name="searchTerm"
          placeholder="e.g. XJTUVX"
          required
          type="text"
          onChange={(e: { target: { value: any } }) =>
            setSearch(e.target.value)
          }
          value={search}
        />
        <SubmitButton
          buttonType="secondary"
          disabled={isLoading}
          isLoading={isLoading}
          isShort
          onClick={(e) => {
            onSubmitSearch(e);
          }}
          type="submit"
        >
          Search
        </SubmitButton>
      </SearchForm>
      <Content />
    </Grid>
  );
};

export default ShippingSearchPage;
