import { centeredLayout } from 'styles/layout.css';
import styled from 'styled-components/macro';
import { Button, Paper, type, colors } from 'core/components';
import ArrowLeft from 'assets/ic_chevron-left.svg?react';
import Pencil from 'assets/pencil.svg?react';

export const EditButton = styled(Button)`
  max-width: 10rem;
`;

export const Wrapper = styled.header`
  background: ${({ theme }) => theme.colors.black05};
`;

export const Content = styled.div`
  ${centeredLayout};
  padding: 1.75rem 1.25rem;

  @media ${({ theme }) => theme.mediaQueries.tabletAndAbove} {
    display: flex;
    align-items: flex-end;
    padding: 1.75rem 2rem 1.5rem;
  }
`;

export const Name = styled.h2`
  ${({ theme }) => theme.text.headings.h2};
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${({ theme }) => theme.mediaQueries.tabletAndAbove} {
    max-width: 40%;
  }
`;

export const CustomerId = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[5]};
  font-weight: ${({ theme }) => theme.fontWeights.default};
  line-height: 1.3;
`;

export const Value = styled.h5``;

export const PinLoginLink = styled.div`
  margin-top: -0.5rem;
  font-size: ${({ theme }) => theme.fontSizes[2]};
`;

export const StyledPopper = styled(Paper)`
  width: 313px;
  padding: 1.25rem;
  border-radius: 8px !important;
  background-color: ${colors.blue50} !important;
`;

export const StyledPointer = styled.div`
  position: absolute;
  margin-left: -27px;
  margin-top: 65px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid ${colors.blue50};
`;

export const PopperHeader = styled(type.H5)`
  color: ${colors.white};
`;

export const PopperBody = styled.div`
  color: ${colors.white};
  font-size: 0.875rem;
`;

export const PopperFooter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 1rem;
`;

export const UpdateButton = styled(Button)`
  max-width: 10rem;
  color: ${colors.white};
  border-color: ${colors.white};

  &:hover {
    background-color: ${colors.blue50};
    color: ${colors.white};
    filter: brightness(90%);
  }
`;

export const DismissLink = styled(type.H5)`
  margin-left: 1.5rem;
  margin-bottom: 0;
  color: ${colors.white};
  filter: brightness(70%);
  font-weight: ${type.weight.bold};
  cursor: pointer;

  &:hover {
    filter: brightness(60%);
  }
`;

export const HighLightedButton = styled(type.BodySmall)`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.blue50};
  cursor: pointer;
  margin-bottom: 0;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  align-items: center;
`;

export const UpChevron = styled(ArrowLeft)`
  transform: rotate(90deg);
  height: 16px;
  width: 16px;
`;

export const DownChevron = styled(ArrowLeft)`
  transform: rotate(-90deg);
  height: 16px;
  width: 16px;
`;

export const Header = styled(type.H5)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-left: 8px;
`;

export const NameRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const SmallEditButton = styled(Pencil)`
  margin-left: 8px;
  scale: 1.5;
  fill-opacity: 0;
  stroke: ${({ theme }) => theme.colors.text70};
`;
