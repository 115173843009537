import { ACCESS_GROUPS } from 'types';
import { useAuthContext } from 'context/AuthContext';

/*
 * function that checks if the user is a candid ortho user.
 * @returns whether the user is in ortho
 */
export function useIsOrtho(): boolean {
  const { checkHasAccess } = useAuthContext();

  return checkHasAccess && checkHasAccess(ACCESS_GROUPS.ORTHO);
}
