import React from 'react';

import ArrowRightIcon from 'core/assets/icons/arrow-right-v2.svg?react';

import Loading from 'core/components/Loading/Loading';

import { Container, LoadingContainer } from 'core/components/Button/Button.css';

const BUTTON_TYPES = [
  'primary',
  'primary-outline',
  'secondary',
  'secondary-outline',
  'tertiary',
  'black-outline',
  'text',
] as const;

const BUTTON_SIZES = ['jumbo', 'large', 'medium', 'small'] as const;

export type ButtonType = (typeof BUTTON_TYPES)[number];
export type ButtonSize = (typeof BUTTON_SIZES)[number];

type ButtonProps = {
  arrow?: boolean;
  buttonType?: ButtonType;
  buttonSize?: ButtonSize;
  children: React.ReactNode | React.ReactNode[];
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  isFixedOnMobile?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
  isShort?: boolean;
  name?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit' | 'reset';
  testId?: string;
};

const Button = ({
  buttonType = 'primary',
  buttonSize = 'medium',
  children,
  leftIcon,
  rightIcon,
  className,
  disabled,
  isFixedOnMobile,
  isFullWidth,
  isLoading,
  name,
  onClick,
  type = 'button',
  testId,
  arrow, // @deprecated since version 2.7.0
  isShort, // @deprecated since version 2.7.0
  ...otherProps
}: ButtonProps & React.HTMLProps<HTMLButtonElement>) => {
  // `isShort` and `arrow` are deprecated in v2.  Map to v2 equivalent
  if (arrow) {
    rightIcon = <ArrowRightIcon />;
  }
  if (isShort) {
    buttonSize = 'medium';
  }

  return (
    <Container
      buttonType={buttonType}
      buttonSize={buttonSize}
      className={className}
      data-testid={testId}
      disabled={disabled}
      isFixedOnMobile={isFixedOnMobile}
      isFullWidth={isFullWidth}
      isLoading={isLoading}
      name={name}
      onClick={onClick}
      type={type}
      hasIcon={!!leftIcon || !!rightIcon}
      {...(otherProps as Record<string, unknown>)}
    >
      {isLoading && (
        <LoadingContainer>
          <Loading isCentered />
        </LoadingContainer>
      )}
      {leftIcon && <span className="left-icon">{leftIcon}</span>}
      <span>{children}</span>
      {rightIcon && <span className="right-icon">{rightIcon}</span>}
    </Container>
  );
};

export default Button;
