import React, {
  useMemo,
  Context,
  createContext,
  useState,
  useEffect,
} from 'react';
import moment from 'moment';
import {
  CreateCouponsForBundleMutation,
  CreateCouponsForBundleDocument,
  PromotionCouponCriteriaCriteriaTypeChoices,
  CreateCouponsForBundleMutationVariables,
} from 'generated/core/graphql';
import { coreClient } from 'gql/clients';
import { ApolloError, useLazyQuery } from '@apollo/client';
import {
  GetPracticesQuery,
  GetPracticesQueryVariables,
  GetPracticesDocument,
} from 'generated/legacy/graphql';
import { Brand } from 'generated/core/graphql';
import { useNotificationContext } from 'core/context/NotificationContext';
import * as Sentry from '@sentry/browser';

type PromotionContextProps = {
  bundleCreationVisible: boolean;
  createBundle: typeof createBundle;
  setBundleCreationVisible: (loading: boolean) => void;
  practiceOptions: PracticeSelectOption[];
  selectedPractice: PracticeSelectOption;
  setSelectedPractice: (practice: PracticeSelectOption) => void;
  getPracticeError: ApolloError | undefined;
};

export type PracticeSelectOption = {
  label: string;
  value: string;
};

type BundleReturnType = {
  couponId: string;
  couponCode: string;
  redemptionLimit: number;
  startsAt: string;
  endsAt: string;
  couponCriteria:
    | {
        criteriaType: PromotionCouponCriteriaCriteriaTypeChoices;
        practiceIds: string[];
      }[]
    | undefined;
  promotionName: string;
};

const createBundle = async (
  practiceId: string
): Promise<BundleReturnType[]> => {
  try {
    const result = await coreClient.mutate<
      CreateCouponsForBundleMutation,
      CreateCouponsForBundleMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: CreateCouponsForBundleDocument,
      variables: {
        expireAt: moment().add(90, 'days').toISOString(),
        criteria: {
          practiceIds: [practiceId],
        },
      },
    });

    return [
      result.data?.caseBundleCoupon?.coupon,
      result.data?.teamCaseBundleCoupon?.coupon,
    ].map((couponResult) => {
      return {
        couponId: couponResult?.id as string,
        couponCode: couponResult?.code as string,
        redemptionLimit: couponResult?.redemptionLimit as number,
        startsAt: couponResult?.startsAt as string,
        endsAt: couponResult?.endsAt as string,
        couponCriteria:
          couponResult?.couponCriteria.map((criteria) => ({
            criteriaType:
              criteria.criteriaType as PromotionCouponCriteriaCriteriaTypeChoices,
            practiceIds: criteria.argsJson?.practiceIds as string[],
          })) ?? [],
        promotionName: couponResult?.promotion?.promotionName as string,
      };
    });
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    return [];
  }
};

const PromotionContext: Context<PromotionContextProps> =
  createContext<PromotionContextProps>({
    bundleCreationVisible: false,
    createBundle,
    setBundleCreationVisible: () => {},
    practiceOptions: [],
    selectedPractice: { label: '', value: '' },
    setSelectedPractice: () => {},
    getPracticeError: undefined,
  });

export const PromotionContextProvider: React.FC = ({ children }) => {
  const { showNotification } = useNotificationContext();
  const [bundleCreationVisible, setBundleCreationVisible] =
    useState<boolean>(false);

  const [selectedPractice, setSelectedPractice] =
    useState<PracticeSelectOption>({ label: '', value: '' });
  const [getPractices, { data: practiceData, error: getPracticeError }] =
    useLazyQuery<GetPracticesQuery, GetPracticesQueryVariables>(
      GetPracticesDocument
    );
  const practiceOptions = useMemo<PracticeSelectOption[]>(() => {
    const practices = practiceData?.practices;
    if (typeof practices === 'undefined' || practices === null) {
      return [];
    }
    return practices
      .filter((p) => p.brand?.name === Brand.CandidPro)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map<PracticeSelectOption>((practice) => ({
        label: practice.name,
        value: practice.id,
      }));
  }, [practiceData]);

  useEffect(() => {
    getPractices();
  }, []);

  useEffect(() => {
    if (typeof getPracticeError !== 'undefined') {
      console.error(getPracticeError);
      showNotification(
        getPracticeError?.message ?? 'An error fetching practices has occured.',
        'error'
      );
    }
  }, [getPracticeError]);

  const value: PromotionContextProps = useMemo(
    () => ({
      bundleCreationVisible,
      setBundleCreationVisible,
      createBundle,
      selectedPractice,
      setSelectedPractice,
      practiceOptions,
      getPracticeError,
    }),
    [
      bundleCreationVisible,
      setBundleCreationVisible,
      createBundle,
      selectedPractice,
      setSelectedPractice,
      practiceOptions,
      getPracticeError,
    ]
  );

  return (
    <PromotionContext.Provider value={value}>
      {children}
    </PromotionContext.Provider>
  );
};
