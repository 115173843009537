import React from 'react';

import {
  FormikRadioGroup,
  FormikToothChart,
  FormikTextAreaWrapper,
  FormikCheckboxWrapper,
} from 'components/FormikForms';

import {
  FollowUpQuestion as FollowUpQuesionType,
  Question as QuestionType,
  TreatmentObjectiveQuestionTypes,
} from 'generated/core/graphql';
import { DentalNotationOptions } from 'generated/legacy/graphql';

import { renderRichTextAsHtml } from 'utils/RichText';
import {
  QuestionContainer,
  QuestionLabel,
} from 'components/TreatmentObjectives/styles.css';
import ConsolidatedToothChart from 'components/ToothChart/ConsolidatedToothChart';

const seedFollowUpQuestion = (
  followUpQuestion: FollowUpQuesionType
): QuestionInputType => ({
  id: followUpQuestion.followUpQuestionId || '',
  text: followUpQuestion.text,
  type: followUpQuestion.type,
  options: followUpQuestion?.options?.map((option) => ({ text: option })) || [],
});

type QuestionInputType = Pick<QuestionType, 'id' | 'type' | 'text' | 'options'>;

const QuestionMultipleChoiceInput = ({
  question,
  disabled,
}: {
  question: QuestionInputType;
  disabled?: boolean;
}) => {
  if (!question.options) {
    return null;
  }

  return (
    <FormikRadioGroup
      type="radio"
      name={question.id}
      disabled={disabled}
      options={question.options.map((option) => ({
        displayValue: option.text,
        value: option.text,
        children: option?.followUpQuestions?.map((followUpQuestion) => {
          const followup = seedFollowUpQuestion(followUpQuestion);

          return (
            <Question
              key={question.id}
              question={followup}
              disabled={disabled}
              isFollowupQuestion
            />
          );
        }),
      }))}
    />
  );
};

const QuestionMultiSelectInput = ({
  question,
  disabled,
}: {
  question: QuestionInputType;
  disabled?: boolean;
}) => {
  return (
    <div>
      {question?.options?.map((option) => {
        const key = `${question.id}-${option.text}`;
        return (
          <FormikCheckboxWrapper
            key={key}
            name={key}
            type="checkbox"
            label={option.text}
            disabled={disabled}
          >
            {option?.followUpQuestions?.map((followUpQuestion) => {
              const followup = seedFollowUpQuestion(followUpQuestion);
              return (
                <Question
                  key={followUpQuestion.followUpQuestionId}
                  question={followup}
                  disabled={disabled}
                  isFollowupQuestion
                />
              );
            })}
          </FormikCheckboxWrapper>
        );
      })}
    </div>
  );
};

const QuestionTextInput = ({
  question,
  disabled,
}: {
  question: QuestionInputType;
  disabled?: boolean;
}) => {
  return (
    <FormikTextAreaWrapper
      type="textarea"
      name={question.id}
      disabled={disabled}
    />
  );
};

const Question = ({
  question,
  isFollowupQuestion,
  dentalNotation,
  disabled,
  displayConsolidatedToothChart = false,
}: {
  question: QuestionInputType;
  isFollowupQuestion?: boolean;
  dentalNotation?: DentalNotationOptions;
  disabled?: boolean;
  displayConsolidatedToothChart?: boolean;
}) => {
  return (
    <QuestionContainer isFollowupQuestion={isFollowupQuestion}>
      <QuestionLabel>{renderRichTextAsHtml(question.text)}</QuestionLabel>
      {(() => {
        switch (question.type) {
          case TreatmentObjectiveQuestionTypes.Text:
            return (
              <QuestionTextInput question={question} disabled={disabled} />
            );
          case TreatmentObjectiveQuestionTypes.MultiSelect:
            return (
              <QuestionMultiSelectInput
                question={question}
                disabled={disabled}
              />
            );
          case TreatmentObjectiveQuestionTypes.MultipleChoice:
            return (
              <QuestionMultipleChoiceInput
                question={question}
                disabled={disabled}
              />
            );
          case TreatmentObjectiveQuestionTypes.ToothChart:
            return displayConsolidatedToothChart ? (
              <ConsolidatedToothChart
                name={question.id}
                dentalNotation={dentalNotation}
                disabled={disabled}
              />
            ) : (
              <FormikToothChart
                name={question.id}
                dentalNotation={dentalNotation}
                disabled={disabled}
              />
            );
          default:
            return null;
        }
      })()}
    </QuestionContainer>
  );
};

export default Question;
