import React from 'react';

import { Grid } from 'core/components';
import {
  CheckBox as CheckBoxContainer,
  CheckBoxesContainer,
  Container,
} from 'components/ToothChart/ToothChart.css';
import { DentalNotationOptions } from 'generated/legacy/graphql';
import { mediaQueries } from 'core/components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DynamicTooth from 'components/ToothChart/DynamicTooth';
import {
  convertFromPalmerToUniversal,
  convertFromFDIToUniversal,
  getQuadrants,
} from 'components/ToothChart/utils';

type ToothChartProps = {
  values: number[];
  onChange?: (value: number[]) => void;
  readOnly?: boolean;
  disabled?: boolean;
  notation?: DentalNotationOptions;
};

/*
 * A component that renders an array of check boxes representing tooth positions
 * The positions are 1 indexed, and are tied to the id of the rendered checkbox
 *
 * Values is passed in as a list of ints (ex: 3,6,23) indicating which check boxes are checked in universal notation
 *
 */
const ToothChart = ({
  values,
  readOnly = false,
  onChange,
  disabled = false,
  notation = DentalNotationOptions.Universal,
}: ToothChartProps) => {
  const isPalmer = notation === DentalNotationOptions.Palmer;
  const isFDI = notation === DentalNotationOptions.Fdi;
  const tabletSize = useMediaQuery(mediaQueries.tabletAndAbove);
  const onCheckBoxChanged = (
    quadrant: number,
    index: number,
    isChecked: boolean
  ) => {
    if (notation === DentalNotationOptions.Palmer) {
      index = convertFromPalmerToUniversal(quadrant, index);
    } else if (notation === DentalNotationOptions.Fdi) {
      index = convertFromFDIToUniversal(quadrant, index);
    }
    const newValues = values.map((e) => e);
    if (isChecked && !values.includes(index)) {
      newValues.push(index);
    } else if (!isChecked) {
      const position = newValues.indexOf(index);
      if (position >= 0) {
        newValues.splice(position, 1);
      }
    }

    if (onChange) {
      onChange(newValues);
    }
  };

  const [
    [startUpperRight, endUpperRight],
    [startUpperLeft, endUpperLeft],
    [startLowerLeft, endLowerLeft],
    [startLowerRight, endLowerRight],
  ] = getQuadrants(notation);

  return (
    <Container container xs={12} disabled={disabled}>
      {tabletSize && (
        <Grid item xs={1}>
          R
        </Grid>
      )}
      <Grid item container xs={tabletSize ? 10 : 12}>
        <Grid container item xs={12} direction="row">
          <Grid
            item
            xs={6}
            style={{
              borderRight: '1px dotted #c9c9c9c9',
              borderBottom: '1px dotted #c9c9c9c9',
            }}
          >
            {!tabletSize && 'R'}
            <CheckBoxes
              start={startUpperLeft}
              end={endUpperLeft}
              quadrant={2}
              values={values}
              readOnly={readOnly}
              onChange={onCheckBoxChanged}
              reversed={isPalmer || isFDI}
              disabled={disabled}
              notation={notation}
            />
          </Grid>
          <Grid item xs={6} style={{ borderBottom: '1px dotted #c9c9c9c9' }}>
            {!tabletSize && 'L'}
            <CheckBoxes
              start={startUpperRight}
              end={endUpperRight}
              quadrant={1}
              values={values}
              readOnly={readOnly}
              onChange={onCheckBoxChanged}
              reversed={false}
              disabled={disabled}
              notation={notation}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} direction="row">
          <Grid item xs={6} style={{ borderRight: '1px dotted #c9c9c9c9' }}>
            <CheckBoxes
              start={startLowerLeft}
              end={endLowerLeft}
              quadrant={3}
              values={values}
              readOnly={readOnly}
              onChange={onCheckBoxChanged}
              reversed={true}
              disabled={disabled}
              notation={notation}
            />
          </Grid>
          <Grid item xs={6}>
            <CheckBoxes
              start={startLowerRight}
              end={endLowerRight}
              quadrant={4}
              values={values}
              readOnly={readOnly}
              onChange={onCheckBoxChanged}
              reversed={!(isPalmer || isFDI)}
              disabled={disabled}
              notation={notation}
            />
          </Grid>
        </Grid>
      </Grid>
      {tabletSize && (
        <Grid item xs={1}>
          L
        </Grid>
      )}
    </Container>
  );
};

type ToothCheckBoxesProps = {
  quadrant: number;
  start: number;
  end: number;
  values: number[];
  readOnly: boolean;
  onChange: (quadrant: number, id: number, value: boolean) => void;
  reversed: boolean;
  disabled?: boolean;
  notation: DentalNotationOptions;
};

/*
 * One quadrant of the tooth chart. Responsible for rendering the array of checkboxes, and label for
 * each quadrant. The start and end values are inclusive, so start=1 end=3 will render 1,2,3
 */
const CheckBoxes = ({
  quadrant,
  start,
  end,
  values,
  readOnly,
  onChange,
  reversed,
  disabled,
  notation,
}: ToothCheckBoxesProps) => {
  const isPalmer = notation === DentalNotationOptions.Palmer;
  const isFDI = notation === DentalNotationOptions.Fdi;
  //Creates a list if indicies from start to end, inclusive on both ends
  //Note element is unused here because it's all undefined
  let range: number[] = Array.from(
    new Array(end - start + 1),
    (_, index) => index + start
  );

  if (reversed) {
    range = range.reverse();
  }

  const isChecked = (index: number) => {
    if (isPalmer) {
      index = convertFromPalmerToUniversal(quadrant, index);
    } else if (isFDI) {
      index = convertFromFDIToUniversal(quadrant, index);
    }
    return values.includes(index);
  };

  return (
    <CheckBoxesContainer>
      {range.map((index) => {
        const isCheckedValue = isChecked(index);
        return (
          <CheckBoxContainer
            bottomRow={[3, 4].includes(quadrant)} // bottom row quadrants have a different style
            key={index}
          >
            <DynamicTooth
              index={index.toString()}
              checked={isCheckedValue}
              readOnly={readOnly}
              onChange={onChange}
              disabled={disabled!}
              quadrant={quadrant}
              notation={notation}
            />
            <label>{index}</label>
          </CheckBoxContainer>
        );
      })}
    </CheckBoxesContainer>
  );
};

export default ToothChart;
