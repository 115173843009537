import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  CreateUserTemplateMutation,
  CreateUserTemplateMutationVariables,
  CreateUserTemplateDocument,
  GetUserTemplatesDocument,
  GetUserTemplatesQuery,
  GetUserTemplatesQueryVariables,
  UpdateUserTemplateDocument,
  UpdateUserTemplateMutation,
  UpdateUserTemplateMutationVariables,
  ActionItemsOverviewDocument,
  ActionItemsOverviewQuery,
  ActionItemsOverviewQueryVariables,
  AddTreatmentPlanStagingDocument,
  AddTreatmentPlanStagingMutation,
  AddTreatmentPlanStagingMutationVariables,
  CancelOrderDocument,
  CancelOrderMutation,
  CancelOrderMutationVariables,
  CreateOrderDocument,
  CreateOrderMutation,
  CreateOrderMutationVariables,
  GenerateTemplatedTreatmentPlanToolingFileDocument,
  GenerateTemplatedTreatmentPlanToolingFileMutation,
  GenerateTemplatedTreatmentPlanToolingFileMutationVariables,
  GetAllOrdersDocument,
  GetAllOrdersQuery,
  GetAllOrdersQueryVariables,
  GetCaseDocument,
  GetCaseQuery,
  GetCaseQueryVariables,
  GetCasesDocument,
  GetCasesForSearchDocument,
  GetCasesForSearchQuery,
  GetCasesForSearchQueryVariables,
  GetCasesQuery,
  GetCasesQueryVariables,
  GetInvoicesForCaseDocument,
  GetInvoicesQuery,
  GetInvoicesQueryVariables,
  GetInvoicesForCaseQuery,
  GetInvoicesForCaseQueryVariables,
  GetManufacturingRequestStatusDocument,
  GetManufacturingRequestStatusQuery,
  GetManufacturingRequestStatusQueryVariables,
  GetLatestManufacturingRequestStatusDocument,
  GetLatestManufacturingRequestStatusQuery,
  GetLatestManufacturingRequestStatusQueryVariables,
  GetLatestViewerUpdateRequestStatusDocument,
  GetLatestViewerUpdateRequestStatusQuery,
  GetLatestViewerUpdateRequestStatusQueryVariables,
  GetMaterialUploadDataDocument,
  GetMaterialUploadDataQuery,
  GetMaterialUploadDataQueryVariables,
  GetMostRecentlyApprovedTpStagingForPatientDocument,
  GetMostRecentlyApprovedTpStagingForPatientQuery,
  GetMostRecentlyApprovedTpStagingForPatientQueryVariables,
  GetOrdersDocument,
  GetOrdersQuery,
  GetOrdersQueryVariables,
  GetOrderItemsCoreDocument,
  GetOrderItemsCoreQuery,
  GetOrderItemsCoreQueryVariables,
  GetPracticePaymentStatusDocument,
  GetPracticePaymentStatusQuery,
  GetPracticePaymentStatusQueryVariables,
  GetProductVariantsDocument,
  GetProductVariantsQuery,
  GetProductVariantsQueryVariables,
  GetSystemTemplatesDocument,
  GetSystemTemplatesQuery,
  GetSystemTemplatesQueryVariables,
  GetTreatmentPlanningSoftwareOpeningPayloadDocument,
  GetTreatmentPlanningSoftwareOpeningPayloadQuery,
  GetTreatmentPlanningSoftwareOpeningPayloadQueryVariables,
  GetTreatmentPlanStagingsByPatientIdDocument,
  GetTreatmentPlanStagingsByPatientIdQuery,
  GetTreatmentPlanStagingsByPatientIdQueryVariables,
  ProcessManufacturingRequestDocument,
  ProcessManufacturingRequestMutation,
  ProcessManufacturingRequestMutationVariables,
  SaveManufacturingFilesDocument,
  SaveManufacturingFilesMutation,
  SaveManufacturingFilesMutationVariables,
  SubmitUserTreatmentObjectiveDocument,
  SubmitUserTreatmentObjectiveMutation,
  SubmitUserTreatmentObjectiveMutationVariables,
  GetTemplateDocument,
  GetTemplateQuery,
  GetTemplateQueryVariables,
  GetOrCreateTemplateDocument,
  GetOrCreateTemplateQuery,
  GetOrCreateTemplateQueryVariables,
  GetLatestTreatmentObjectiveWithQuestionsDocument,
  GetLatestTreatmentObjectiveWithQuestionsQuery,
  GetLatestTreatmentObjectiveWithQuestionsQueryVariables,
  GetTreatmentPlanStagingByIdQuery,
  GetTreatmentPlanStagingByIdQueryVariables,
  GetTreatmentPlanStagingByIdDocument,
  CreateDfaTreatmentPlanMutation,
  CreateDfaTreatmentPlanDocument,
  CreateDfaTreatmentPlanMutationVariables,
  ClearTreatmentPlanningDraftFilesDocument,
  ClearTreatmentPlanningDraftFilesMutation,
  ClearTreatmentPlanningDraftFilesMutationVariables,
  UpdateOrderMutation,
  UpdateOrderMutationVariables,
  UpdateOrderDocument,
  GetShipmentForOrderItemQuery,
  GetShipmentForOrderItemQueryVariables,
  GetShipmentForOrderItemDocument,
  UpdateShipmentStatusMutation,
  UpdateShipmentStatusMutationVariables,
  UpdateShipmentStatusDocument,
  ReinitiateTreatmentPlanningMutation,
  ReinitiateTreatmentPlanningMutationVariables,
  ReinitiateTreatmentPlanningDocument,
  CreatePatientNoteMutation,
  CreatePatientNoteMutationVariables,
  CreatePatientNoteDocument,
  UpdatePatientNoteMutation,
  UpdatePatientNoteMutationVariables,
  UpdatePatientNoteDocument,
  GetPatientNoteQuery,
  GetPatientNoteDocument,
  GetPatientNotesDocument,
  GetPatientNotesQuery,
  GetPatientNotesQueryVariables,
  GetPatientNoteQueryVariables,
  CreateCaseDocument,
  CreateCaseMutation,
  CreateCaseMutationVariables,
  GetLatestManufacturingRequestQuery,
  GetLatestManufacturingRequestQueryVariables,
  GetLatestManufacturingRequestDocument,
  GetLatestViewerUpdateRequestQuery,
  GetLatestViewerUpdateRequestQueryVariables,
  GetLatestViewerUpdateRequestDocument,
  GetAccountManagementRepDocument,
  GetAccountManagementRepQuery,
  GetAccountManagementRepQueryVariables,
  UpdateLoyaltyProgramNameDocument,
  UpdateLoyaltyProgramNameMutation,
  UpdateLoyaltyProgramNameMutationVariables,
  ArchiveLoyaltyProgramDocument,
  ArchiveLoyaltyProgramMutation,
  ArchiveLoyaltyProgramMutationVariables,
  UnarchiveLoyaltyProgramDocument,
  UnarchiveLoyaltyProgramMutation,
  UnarchiveLoyaltyProgramMutationVariables,
  CreatePromotionDocument,
  CreatePromotionMutation,
  CreatePromotionMutationVariables,
  GetTemplateQuestionsDocument,
  GetTemplateQuestionsQuery,
  GetTemplateQuestionsQueryVariables,
  CreateQuestionMutationDocument,
  CreateQuestionMutation,
  CreateQuestionMutationMutationVariables,
  CreateTemplateBaseDocument,
  CreateTemplateBaseMutation,
  CreateTemplateBaseMutationVariables,
  PracticeLeadQuery,
  PracticeLeadQueryVariables,
  PracticeLeadDocument,
  AccountLeadQuery,
  AccountLeadQueryVariables,
  AccountLeadDocument,
  GetZendeskJwtQuery,
  GetZendeskJwtQueryVariables,
  GetZendeskJwtDocument,
  GetActiveLoyaltyProgramsQuery,
  GetActiveLoyaltyProgramsQueryVariables,
  GetActiveLoyaltyProgramsDocument,
  GetInvoicesDocument,
} from 'generated/core/graphql';

import {
  CustomerDocument,
  CustomerQuery,
  CustomerQueryVariables,
  CustomerSearchDocument,
  CustomerSearchQuery,
  CustomerSearchQueryVariables,
  GetAllPracticesDocument,
  GetAllPracticesQuery,
  GetAllPracticesQueryVariables,
  GetPracticeDocument,
  GetPracticeQuery,
  GetPracticeQueryVariables,
  GetPracticesDocument,
  GetPracticesQuery,
  GetPracticesQueryVariables,
  MeDocument,
  MeQuery,
  MeQueryVariables,
  SearchDoctorsDocument,
  SearchDoctorsQuery,
  SearchDoctorsQueryVariables,
  SetPreferencesDocument,
  SetPreferencesMutation,
  SetPreferencesMutationVariables,
  UpdateCustomerDocument,
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables,
  MassTransferCustomersDocument,
  MassTransferCustomersMutation,
  MassTransferCustomersMutationVariables,
  PrismAggregatesSearchQueryVariables,
  PrismAggregatesSearchQuery,
  PrismAggregatesSearchDocument,
  CreatePracticeAccountDocument,
  CreatePracticeAccountMutation,
  CreatePracticeAccountMutationVariables,
} from 'generated/legacy/graphql';

import { coreClient, client as legacyClient } from 'gql/clients';
import { mutateDecorator, queryDecorator } from 'state/api/utils';

const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: 'invalid://unknown/use_queryFn_instead',
  }),
  tagTypes: [
    'ActionItem',
    'Aggregate',
    'Doctor',
    'Case',
    'Patient',
    'Invoice',
    'LoyaltyPlan',
    'Note',
    'Order',
    'Part',
    'Patient',
    'OrderItem',
    'Practice',
    'PracticePaymentStatus',
    'ProductVariant',
    'Shipment',
    'TreatmentPlanStaging',
    'TreatmentPlanTooling',
    'User',
    'UserTemplate',
    'SystemTemplate',
    'LatestTreatmentObjectiveWithQuestions',
    'UserClinicalPreferencesTemplate',
    'AccountManagementRep',
    'Question',
  ],
  keepUnusedDataFor: 60 /* Default is 60; in seconds */,
  endpoints: (builder) => ({
    /* action item */
    getActionItemsOverview: builder.query({
      providesTags: ['ActionItem'],
      queryFn: queryDecorator(
        coreClient.query<
          ActionItemsOverviewQuery,
          ActionItemsOverviewQueryVariables
        >,
        'actionItemsOverview',
        ActionItemsOverviewDocument
      ),
    }),
    /* user */
    getMe: builder.query({
      providesTags: ['User'],
      queryFn: queryDecorator(
        legacyClient.query<MeQuery, MeQueryVariables>,
        'me',
        MeDocument
      ),
    }),

    /* cases */
    createCase: builder.mutation({
      invalidatesTags: ['Case'],
      queryFn: mutateDecorator(
        coreClient.mutate<CreateCaseMutation, CreateCaseMutationVariables>,
        'createCase',
        CreateCaseDocument
      ),
    }),
    getCasesForPatients: builder.query({
      providesTags: ['Case'],
      queryFn: queryDecorator(
        coreClient.query<
          GetCasesForSearchQuery,
          GetCasesForSearchQueryVariables
        >,
        'getCases',
        GetCasesForSearchDocument
      ),
    }),
    getCases: builder.query({
      providesTags: ['Case'],
      queryFn: queryDecorator(
        coreClient.query<GetCasesQuery, GetCasesQueryVariables>,
        'getCases',
        GetCasesDocument
      ),
    }),
    getCase: builder.query({
      providesTags: ['Case'],
      queryFn: queryDecorator(
        coreClient.query<GetCaseQuery, GetCaseQueryVariables>,
        'getCase',
        GetCaseDocument
      ),
    }),

    /* patient */
    getPatient: builder.query({
      providesTags: ['Patient'],
      queryFn: queryDecorator(
        legacyClient.query<CustomerQuery, CustomerQueryVariables>,
        'customer',
        CustomerDocument
      ),
    }),
    getPatients: builder.query({
      providesTags: ['Patient'],
      queryFn: queryDecorator(
        legacyClient.query<CustomerSearchQuery, CustomerSearchQueryVariables>,
        'customerSearch',
        CustomerSearchDocument
      ),
    }),

    /* loyalty program */
    getActiveLoyaltyProgram: builder.query({
      providesTags: ['LoyaltyPlan'],
      queryFn: queryDecorator(
        coreClient.query<
          GetActiveLoyaltyProgramsQuery,
          GetActiveLoyaltyProgramsQueryVariables
        >,
        'getLoyaltyPrograms',
        GetActiveLoyaltyProgramsDocument
      ),
    }),
    archiveLoyaltyProgram: builder.mutation({
      invalidatesTags: ['LoyaltyPlan'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          ArchiveLoyaltyProgramMutation,
          ArchiveLoyaltyProgramMutationVariables
        >,
        'archiveLoyaltyProgram',
        ArchiveLoyaltyProgramDocument
      ),
    }),
    unarchiveLoyaltyProgram: builder.mutation({
      invalidatesTags: ['LoyaltyPlan'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          UnarchiveLoyaltyProgramMutation,
          UnarchiveLoyaltyProgramMutationVariables
        >,
        'unarchiveLoyaltyProgram',
        UnarchiveLoyaltyProgramDocument
      ),
    }),
    updateLoyaltyProgram: builder.mutation({
      invalidatesTags: ['LoyaltyPlan'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          UpdateLoyaltyProgramNameMutation,
          UpdateLoyaltyProgramNameMutationVariables
        >,
        'updateLoyaltyProgram',
        UpdateLoyaltyProgramNameDocument
      ),
    }),

    /* manufacturing */
    getManufacturingRequestStatus: builder.query({
      providesTags: [],
      queryFn: queryDecorator(
        coreClient.query<
          GetManufacturingRequestStatusQuery,
          GetManufacturingRequestStatusQueryVariables
        >,
        'getManufacturingRequestStatus',
        GetManufacturingRequestStatusDocument
      ),
    }),
    getLatestManufacturingRequestStatus: builder.query({
      providesTags: [],
      queryFn: queryDecorator(
        coreClient.query<
          GetLatestManufacturingRequestStatusQuery,
          GetLatestManufacturingRequestStatusQueryVariables
        >,
        'getLatestManufacturingRequestStatus',
        GetLatestManufacturingRequestStatusDocument
      ),
    }),
    getLatestManufacturingRequest: builder.query({
      providesTags: [],
      queryFn: queryDecorator(
        coreClient.query<
          GetLatestManufacturingRequestQuery,
          GetLatestManufacturingRequestQueryVariables
        >,
        'getLatestManufacturingRequest',
        GetLatestManufacturingRequestDocument
      ),
    }),
    getLatestViewerUpdateRequestStatus: builder.query({
      providesTags: [],
      queryFn: queryDecorator(
        coreClient.query<
          GetLatestViewerUpdateRequestStatusQuery,
          GetLatestViewerUpdateRequestStatusQueryVariables
        >,
        'getLatestViewerUpdateRequestStatus',
        GetLatestViewerUpdateRequestStatusDocument
      ),
    }),
    getLatestViewerUpdateRequest: builder.query({
      providesTags: [],
      queryFn: queryDecorator(
        coreClient.query<
          GetLatestViewerUpdateRequestQuery,
          GetLatestViewerUpdateRequestQueryVariables
        >,
        'getLatestViewerUpdateRequest',
        GetLatestViewerUpdateRequestDocument
      ),
    }),
    processManufacturingRequest: builder.mutation({
      invalidatesTags: ['Part', 'TreatmentPlanTooling'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          ProcessManufacturingRequestMutation,
          ProcessManufacturingRequestMutationVariables
        >,
        'processManufacturingRequest',
        ProcessManufacturingRequestDocument
      ),
    }),

    /* prism */
    getPrismAggregates: builder.query({
      providesTags: ['Aggregate'],
      queryFn: queryDecorator(
        legacyClient.query<
          PrismAggregatesSearchQuery,
          PrismAggregatesSearchQueryVariables
        >,
        'prismAggregates',
        PrismAggregatesSearchDocument
      ),
    }),

    /* s3 presigned */
    getMaterialUploadData: builder.query({
      providesTags: [],
      queryFn: queryDecorator(
        coreClient.query<
          GetMaterialUploadDataQuery,
          GetMaterialUploadDataQueryVariables
        >,
        'getMaterialUploadData',
        GetMaterialUploadDataDocument
      ),
    }),

    /* order */
    createOrder: builder.mutation({
      invalidatesTags: ['Order'],
      queryFn: mutateDecorator(
        coreClient.mutate<CreateOrderMutation, CreateOrderMutationVariables>,
        'createOrder',
        CreateOrderDocument
      ),
    }),
    updateOrder: builder.mutation({
      invalidatesTags: ['Order'],
      queryFn: mutateDecorator(
        coreClient.mutate<UpdateOrderMutation, UpdateOrderMutationVariables>,
        'updateOrder',
        UpdateOrderDocument
      ),
    }),
    cancelOrder: builder.mutation({
      invalidatesTags: ['Order'],
      queryFn: mutateDecorator(
        coreClient.mutate<CancelOrderMutation, CancelOrderMutationVariables>,
        'cancelOrder',
        CancelOrderDocument
      ),
    }),
    getOrders: builder.query({
      providesTags: ['Order'],
      queryFn: queryDecorator(
        coreClient.query<GetOrdersQuery, GetOrdersQueryVariables>,
        'orders',
        GetOrdersDocument
      ),
    }),
    getAllOrders: builder.query({
      providesTags: ['Order'],
      queryFn: queryDecorator(
        coreClient.query<GetAllOrdersQuery, GetAllOrdersQueryVariables>,
        'orders',
        GetAllOrdersDocument
      ),
    }),
    getOrderItemsCore: builder.query({
      providesTags: ['OrderItem'],
      queryFn: queryDecorator(
        coreClient.query<
          GetOrderItemsCoreQuery,
          GetOrderItemsCoreQueryVariables
        >,
        'orderItems',
        GetOrderItemsCoreDocument
      ),
    }),
    getShipmentForOrderItem: builder.query({
      providesTags: ['Shipment'],
      queryFn: queryDecorator(
        coreClient.query<
          GetShipmentForOrderItemQuery,
          GetShipmentForOrderItemQueryVariables
        >,
        'getShipmentForOrderItem',
        GetShipmentForOrderItemDocument
      ),
    }),
    updateShipmentStatus: builder.mutation({
      invalidatesTags: ['Shipment'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          UpdateShipmentStatusMutation,
          UpdateShipmentStatusMutationVariables
        >,
        'updateShipmentStatus',
        UpdateShipmentStatusDocument
      ),
    }),
    getInvoices: builder.query({
      providesTags: ['Invoice'],
      queryFn: queryDecorator(
        coreClient.query<GetInvoicesQuery, GetInvoicesQueryVariables>,
        'invoices',
        GetInvoicesDocument
      ),
    }),

    getInvoicesForCase: builder.query({
      providesTags: ['Invoice'],
      queryFn: queryDecorator(
        coreClient.query<
          GetInvoicesForCaseQuery,
          GetInvoicesForCaseQueryVariables
        >,
        'getInvoicesForCase',
        GetInvoicesForCaseDocument
      ),
    }),

    /* practice */
    getPractices: builder.query({
      providesTags: ['Practice'],
      queryFn: queryDecorator(
        legacyClient.query<GetAllPracticesQuery, GetAllPracticesQueryVariables>,
        'practices',
        GetAllPracticesDocument
      ),
    }),
    getFilteredPractices: builder.query({
      providesTags: ['Practice'],
      queryFn: queryDecorator(
        legacyClient.query<GetPracticesQuery, GetPracticesQueryVariables>,
        'practices',
        GetPracticesDocument
      ),
    }),
    getPractice: builder.query({
      providesTags: ['Practice'],
      queryFn: queryDecorator(
        legacyClient.query<GetPracticeQuery, GetPracticeQueryVariables>,
        'practice',
        GetPracticeDocument
      ),
    }),
    setPreferences: builder.mutation({
      queryFn: mutateDecorator(
        legacyClient.mutate<
          SetPreferencesMutation,
          SetPreferencesMutationVariables
        >,
        'setPreferences',
        SetPreferencesDocument
      ),
    }),
    searchDoctor: builder.query({
      providesTags: ['Doctor'],
      queryFn: queryDecorator(
        legacyClient.query<SearchDoctorsQuery, SearchDoctorsQueryVariables>,
        'searchDoctors',
        SearchDoctorsDocument
      ),
    }),
    updateCustomerDocument: builder.mutation({
      invalidatesTags: ['Patient'],
      queryFn: mutateDecorator(
        legacyClient.mutate<
          UpdateCustomerMutation,
          UpdateCustomerMutationVariables
        >,
        'updateCustomer',
        UpdateCustomerDocument
      ),
    }),
    massTransferCustomers: builder.mutation({
      invalidatesTags: ['Practice'],
      queryFn: mutateDecorator(
        legacyClient.mutate<
          MassTransferCustomersMutation,
          MassTransferCustomersMutationVariables
        >,
        'massTransferCustomers',
        MassTransferCustomersDocument
      ),
    }),

    /* practice payment status */
    getPracticePaymentStatus: builder.query({
      providesTags: ['PracticePaymentStatus'],
      queryFn: queryDecorator(
        coreClient.query<
          GetPracticePaymentStatusQuery,
          GetPracticePaymentStatusQueryVariables
        >,
        'practicePaymentStatus',
        GetPracticePaymentStatusDocument
      ),
    }),

    /* product variants */
    getProductVariants: builder.query({
      providesTags: ['ProductVariant'],
      queryFn: queryDecorator(
        coreClient.query<
          GetProductVariantsQuery,
          GetProductVariantsQueryVariables
        >,
        'productVariants',
        GetProductVariantsDocument
      ),
    }),

    /* treatment plan staging */
    addTreatmentPlanStaging: builder.mutation({
      invalidatesTags: ['TreatmentPlanStaging'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          AddTreatmentPlanStagingMutation,
          AddTreatmentPlanStagingMutationVariables
        >,
        'addTreatmentPlanStaging',
        AddTreatmentPlanStagingDocument
      ),
    }),

    getTreatmentPlanStagingForPatient: builder.query({
      providesTags: ['TreatmentPlanStaging'],
      queryFn: queryDecorator(
        coreClient.query<
          GetTreatmentPlanStagingsByPatientIdQuery,
          GetTreatmentPlanStagingsByPatientIdQueryVariables
        >,
        'getTreatmentPlanStagingMaterials',
        GetTreatmentPlanStagingsByPatientIdDocument
      ),
    }),
    getTreatmentPlanStagingById: builder.query({
      providesTags: ['TreatmentPlanStaging'],
      queryFn: queryDecorator(
        coreClient.query<
          GetTreatmentPlanStagingByIdQuery,
          GetTreatmentPlanStagingByIdQueryVariables
        >,
        'getTreatmentPlanStagingById',
        GetTreatmentPlanStagingByIdDocument
      ),
    }),
    createDfaTreatmentPlan: builder.mutation({
      invalidatesTags: ['TreatmentPlanStaging'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          CreateDfaTreatmentPlanMutation,
          CreateDfaTreatmentPlanMutationVariables
        >,
        'createDfaTreatmentPlan',
        CreateDfaTreatmentPlanDocument
      ),
    }),
    getMostRecentlyApprovedTpStagingForPatient: builder.query({
      providesTags: ['TreatmentPlanStaging'],
      queryFn: queryDecorator(
        coreClient.query<
          GetMostRecentlyApprovedTpStagingForPatientQuery,
          GetMostRecentlyApprovedTpStagingForPatientQueryVariables
        >,
        'getMostRecentlyApprovedTpStagingForPatient',
        GetMostRecentlyApprovedTpStagingForPatientDocument
      ),
    }),
    generateTemplatedTreatmentPlanToolingFile: builder.mutation({
      invalidatesTags: ['TreatmentPlanTooling'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          GenerateTemplatedTreatmentPlanToolingFileMutation,
          GenerateTemplatedTreatmentPlanToolingFileMutationVariables
        >,
        'generateTemplatedTreatmentPlanToolingFile',
        GenerateTemplatedTreatmentPlanToolingFileDocument
      ),
    }),

    /* workflows */
    getTreatmentPlanningSoftwareOpeningPayload: builder.query({
      providesTags: [],
      queryFn: queryDecorator(
        coreClient.query<
          GetTreatmentPlanningSoftwareOpeningPayloadQuery,
          GetTreatmentPlanningSoftwareOpeningPayloadQueryVariables
        >,
        'getTreatmentPlanningSoftwareOpeningPayload',
        GetTreatmentPlanningSoftwareOpeningPayloadDocument
      ),
    }),
    saveManufacturingFiles: builder.mutation({
      invalidatesTags: ['Part', 'TreatmentPlanTooling'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          SaveManufacturingFilesMutation,
          SaveManufacturingFilesMutationVariables
        >,
        'saveManufacturingFiles',
        SaveManufacturingFilesDocument
      ),
    }),
    /* materials */
    createTemplate: builder.mutation({
      invalidatesTags: ['UserTemplate'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          CreateUserTemplateMutation,
          CreateUserTemplateMutationVariables
        >,
        'createTemplate',
        CreateUserTemplateDocument
      ),
    }),
    getUserTemplates: builder.query({
      providesTags: ['UserTemplate'],
      queryFn: queryDecorator(
        coreClient.query<GetUserTemplatesQuery, GetUserTemplatesQueryVariables>,
        'getUserTemplates',
        GetUserTemplatesDocument
      ),
    }),
    getUserClinicalPreferencesTemplate: builder.query({
      providesTags: ['UserClinicalPreferencesTemplate'],
      queryFn: queryDecorator(
        coreClient.query<GetUserTemplatesQuery, GetUserTemplatesQueryVariables>,
        'getUserTemplates',
        GetUserTemplatesDocument
      ),
    }),
    updateUserTemplate: builder.mutation({
      invalidatesTags: ['UserClinicalPreferencesTemplate'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          UpdateUserTemplateMutation,
          UpdateUserTemplateMutationVariables
        >,
        'updateTemplate',
        UpdateUserTemplateDocument
      ),
    }),
    getLatestTreatmentObjectiveWithQuestions: builder.query({
      providesTags: ['LatestTreatmentObjectiveWithQuestions'],
      queryFn: queryDecorator(
        coreClient.query<
          GetLatestTreatmentObjectiveWithQuestionsQuery,
          GetLatestTreatmentObjectiveWithQuestionsQueryVariables
        >,
        'getLatestTreatmentObjectiveWithQuestions',
        GetLatestTreatmentObjectiveWithQuestionsDocument
      ),
    }),
    getSystemTemplates: builder.query({
      providesTags: ['SystemTemplate'],
      queryFn: queryDecorator(
        coreClient.query<
          GetSystemTemplatesQuery,
          GetSystemTemplatesQueryVariables
        >,
        'getSystemTemplates',
        GetSystemTemplatesDocument
      ),
    }),
    getTemplate: builder.query({
      providesTags: ['UserClinicalPreferencesTemplate'],
      queryFn: queryDecorator(
        coreClient.query<GetTemplateQuery, GetTemplateQueryVariables>,
        'getTemplate',
        GetTemplateDocument
      ),
    }),
    getOrCreateTemplate: builder.query({
      providesTags: ['UserClinicalPreferencesTemplate'],
      queryFn: queryDecorator(
        coreClient.query<
          GetOrCreateTemplateQuery,
          GetOrCreateTemplateQueryVariables
        >,
        'getOrCreateTemplate',
        GetOrCreateTemplateDocument
      ),
    }),
    submitTreatmentObjective: builder.mutation({
      invalidatesTags: ['LatestTreatmentObjectiveWithQuestions'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          SubmitUserTreatmentObjectiveMutation,
          SubmitUserTreatmentObjectiveMutationVariables
        >,
        'submitTreatmentObjective',
        SubmitUserTreatmentObjectiveDocument
      ),
    }),
    clearTreatmentPlanningDraftFiles: builder.mutation({
      queryFn: mutateDecorator(
        coreClient.mutate<
          ClearTreatmentPlanningDraftFilesMutation,
          ClearTreatmentPlanningDraftFilesMutationVariables
        >,
        'clearTreatmentPlanningDraftFiles',
        ClearTreatmentPlanningDraftFilesDocument
      ),
    }),
    reinitiateTreatmentPlanning: builder.mutation({
      queryFn: mutateDecorator(
        coreClient.mutate<
          ReinitiateTreatmentPlanningMutation,
          ReinitiateTreatmentPlanningMutationVariables
        >,
        'reinitiateTreatmentPlanning',
        ReinitiateTreatmentPlanningDocument
      ),
    }),

    /* note */
    getPatientNote: builder.query({
      providesTags: ['Note'],
      queryFn: queryDecorator(
        coreClient.query<GetPatientNoteQuery, GetPatientNoteQueryVariables>,
        'getNote',
        GetPatientNoteDocument
      ),
    }),
    getPatientNotes: builder.query({
      providesTags: ['Note'],
      queryFn: queryDecorator(
        coreClient.query<GetPatientNotesQuery, GetPatientNotesQueryVariables>,
        'getNotes',
        GetPatientNotesDocument
      ),
    }),
    createPatientNote: builder.mutation({
      invalidatesTags: ['Note'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          CreatePatientNoteMutation,
          CreatePatientNoteMutationVariables
        >,
        'createNote',
        CreatePatientNoteDocument
      ),
    }),
    updatePatientNote: builder.mutation({
      invalidatesTags: ['Note'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          UpdatePatientNoteMutation,
          UpdatePatientNoteMutationVariables
        >,
        'updateNote',
        UpdatePatientNoteDocument
      ),
    }),
    getPracticeLeadData: builder.query({
      providesTags: ['Practice'],
      queryFn: queryDecorator(
        coreClient.query<PracticeLeadQuery, PracticeLeadQueryVariables>,
        'getPracticeLeadData',
        PracticeLeadDocument
      ),
    }),
    getAccountLeadData: builder.query({
      providesTags: ['Practice'],
      queryFn: queryDecorator(
        coreClient.query<AccountLeadQuery, AccountLeadQueryVariables>,
        'getAccountLeadData',
        AccountLeadDocument
      ),
    }),
    getAccountManagementRep: builder.query({
      providesTags: ['AccountManagementRep'],
      queryFn: queryDecorator(
        coreClient.query<
          GetAccountManagementRepQuery,
          GetAccountManagementRepQueryVariables
        >,
        'getAccountManagementRepForSalesforceChildAccount',
        GetAccountManagementRepDocument
      ),
    }),
    createPracticeAccount: builder.mutation({
      queryFn: mutateDecorator(
        legacyClient.mutate<
          CreatePracticeAccountMutation,
          CreatePracticeAccountMutationVariables
        >,
        'createPracticeAccount',
        CreatePracticeAccountDocument
      ),
    }),
    createPromotion: builder.mutation({
      // invalidatesTags: ['Promotion'], TODO: Move Promotion fetch here and invalidate
      queryFn: mutateDecorator(
        coreClient.mutate<
          CreatePromotionMutation,
          CreatePromotionMutationVariables
        >,
        'createPromotion',
        CreatePromotionDocument
      ),
    }),
    getTemplateQuestions: builder.query({
      providesTags: ['Question'],
      queryFn: queryDecorator(
        coreClient.query<
          GetTemplateQuestionsQuery,
          GetTemplateQuestionsQueryVariables
        >,
        'getQuestions',
        GetTemplateQuestionsDocument
      ),
    }),
    createQuestion: builder.mutation({
      invalidatesTags: ['Question'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          CreateQuestionMutation,
          CreateQuestionMutationMutationVariables
        >,
        'question',
        CreateQuestionMutationDocument
      ),
    }),
    createTemplateBase: builder.mutation({
      invalidatesTags: ['SystemTemplate'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          CreateTemplateBaseMutation,
          CreateTemplateBaseMutationVariables
        >,
        'createTemplate',
        CreateTemplateBaseDocument
      ),
    }),
    /* zendesk jwt */
    getZendeskJwt: builder.query({
      keepUnusedDataFor: 0, // No reason to keep the JWT in the cache
      queryFn: queryDecorator(
        coreClient.query<GetZendeskJwtQuery, GetZendeskJwtQueryVariables>,
        'getZendeskJwt',
        GetZendeskJwtDocument
      ),
    }),
  }),
});

export default api;
