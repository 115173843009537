import { convertToBrand, getBrandSKUs } from 'utils/brands';
import Dinero from 'dinero.js';
import { Discount } from 'generated/core/graphql';

export type CatalogItemType = {
  question: string;
  title: string;
  description: string;
  price: Dinero.Dinero;
  imageSrc: string;
  optionType: SelectionOptionTypes;
  sku: string;
  loyaltySku?: string;
  quantity: number;
  totalPriceAfterDiscounts: Dinero.Dinero;
  totalPriceAfterLoyalty: Dinero.Dinero;
  totalPriceBeforeDiscounts: Dinero.Dinero;
  providerFacingProductName: string;
  appliedDiscounts?: Discount[];
};

export enum SelectionOptionTypes {
  Toggle,
  QuantityDropDown,
  None,
}

export enum ProductTypes {
  Retainer = 'Retainer',
  Aligner = 'Aligner',
  Refinement = 'Refinement',
  Replacement = 'Replacement',
}

export const defaultItemsByProductType = (brandName: string) => {
  const brand = convertToBrand(brandName);
  const { aligner, refinement, replacement, retainer } = getBrandSKUs(brand);

  return {
    [ProductTypes.Aligner]: {
      ...defaultItem,
      providerFacingProductName: aligner.providerFacingProductName,
      title: aligner.providerFacingProductName,
      description: aligner.description,
      sku: aligner.sku,
      imageSrc: aligner.imageSrc,
    },
    [ProductTypes.Refinement]: {
      ...defaultItem,
      providerFacingProductName: refinement.providerFacingProductName,
      title: refinement.providerFacingProductName,
      description: refinement.description,
      sku: refinement.sku,
      imageSrc: refinement.imageSrc,
    },
    [ProductTypes.Retainer]: {
      ...defaultItem,
      providerFacingProductName: retainer.providerFacingProductName,
      title: aligner.providerFacingProductName,
      description: retainer.description,
      sku: retainer.sku,
      imageSrc: retainer.imageSrc,

      optionType: SelectionOptionTypes.QuantityDropDown,
    },
    [ProductTypes.Replacement]: {
      ...defaultItem,
      providerFacingProductName: replacement.providerFacingProductName,
      title: replacement.providerFacingProductName,
      description: replacement.description,
      sku: replacement.sku,
      imageSrc: replacement.imageSrc,
    },
  };
};
const defaultItem = {
  title: '',
  question: '',
  optionType: SelectionOptionTypes.None,
  quantity: 1,
  totalPriceAfterDiscounts: Dinero({ amount: 0 }),
  totalPriceAfterLoyalty: Dinero({ amount: 0 }),
  totalPriceBeforeDiscounts: Dinero({ amount: 0 }),

  price: Dinero({ amount: 0 }),
};
