import React from 'react';
import Tabs, { Tab } from 'components/Tabs';
import { Formik } from 'formik';
import { DoctorPreferences } from 'generated/legacy/graphql';
import AutoSaveForm from 'pages/AccountPreferencesPage/AutoSaveForm';
import { SetPreferencesMutation } from 'generated/legacy/graphql';

type SetPreferencesData = SetPreferencesMutation['setPreferences'];

type PreferencesTabbedMenuProps = {
  preferenceTabs: Tab[];
  initialValues: DoctorPreferences;
  userId: string | undefined;
  preferencesData: SetPreferencesData;
};

const PreferencesTabbedMenu = ({
  preferenceTabs,
  initialValues,
  userId,
  preferencesData,
}: PreferencesTabbedMenuProps) => {
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={() => {}}
      >
        <>
          <Tabs tabs={preferenceTabs} />
          <AutoSaveForm
            userId={Number(userId)}
            initialValues={initialValues}
            preferencesDataFromAsigningClinicalTemplateId={preferencesData}
          />
        </>
      </Formik>
    </>
  );
};
export default PreferencesTabbedMenu;
