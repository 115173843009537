import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { DoctorPreferences } from 'generated/legacy/graphql';
import api from 'state/api';
import { SetPreferencesMutation } from 'generated/legacy/graphql';

type SetPreferencesData = SetPreferencesMutation['setPreferences'];

import { StyledSnackbar } from 'pages/AccountPreferencesPage/AccountPreferencesView.css';

const AutoSaveForm = ({
  userId,
  initialValues,
  preferencesDataFromAsigningClinicalTemplateId,
}: {
  userId: number;
  preferencesDataFromAsigningClinicalTemplateId?: SetPreferencesData;
  initialValues: DoctorPreferences;
}) => {
  const { values, dirty, resetForm } = useFormikContext<DoctorPreferences>();

  const [setPreferences, { data: preferencesData }] =
    api.useSetPreferencesMutation();

  useEffect(() => {
    if (preferencesData) {
      const resetValues = JSON.parse(preferencesData.updatedPreferences);

      resetForm({
        values: resetValues,
      });
      setIsSnackbarOpen(true);
    }
  }, [preferencesData]);

  useEffect(() => {
    if (preferencesDataFromAsigningClinicalTemplateId) {
      const resetValues = JSON.parse(
        preferencesDataFromAsigningClinicalTemplateId.updatedPreferences
      );

      resetForm({
        values: resetValues,
      });
    }
  }, [preferencesDataFromAsigningClinicalTemplateId]);

  useEffect(() => {
    const input = {
      ...initialValues,
      ...values,
    };

    if (dirty) {
      setPreferences({
        input: {
          userId,
          preferences: JSON.stringify(input),
        },
      });
    }
  }, [values]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  return (
    <StyledSnackbar
      open={isSnackbarOpen}
      message="Preferences updated"
      autoHideDuration={2000}
      onClose={() => setIsSnackbarOpen(false)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    />
  );
};

export default AutoSaveForm;
