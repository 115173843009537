import React, { forwardRef } from 'react';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import styled from 'styled-components';

interface CustomChipProps extends Omit<ChipProps, 'size'> {
  size?: 'tiny' | 'small' | 'medium';
  customColor?: string;
}

const CustomChip = forwardRef((props: CustomChipProps, ref) => {
  const { size, customColor, children, ...rest } = props;
  const sizeProp = size === 'tiny' ? 'small' : size;

  return (
    <Chip innerRef={ref} size={sizeProp} {...rest}>
      {children}
    </Chip>
  );
});
CustomChip.displayName = 'CustomChip';

const StyledChip = styled(CustomChip)`
  ${({ size, customColor }) => `
    & .MuiChip-labelSmall{
      padding-left: ${size == 'tiny' ? '4px' : '8px'};
      padding-right: ${size == 'tiny' ? '4px' : '8px'};
    }

    &.MuiChip-sizeSmall{
      height: ${size == 'tiny' ? '18px' : '24px'};
    }

    &.MuiChip-root{
      padding-top: 2px;
      font-size: ${size == 'tiny' ? '12px' : '14px'};
      min-width: ${size == 'tiny' ? '18px' : '24px'};
      background-color: ${customColor ?? ''};
    }
`}
`;

export default StyledChip;
