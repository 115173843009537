import React, { useEffect, useMemo } from 'react';
import DowngradeLoyaltyTierAlert from 'pages/ActionItems/Alerts/DowngradeLoyaltyTierAlert';
import UpgradeLoyaltyTierAlert from 'pages/ActionItems/Alerts/UpgradeLoyaltyTierAlert';
import { useAuthContext } from 'context/AuthContext';
import {
  GetCurrentPracticeLoyaltyProgramDocument,
  GetCurrentPracticeLoyaltyProgramQuery,
  GetCurrentPracticeLoyaltyProgramQueryVariables,
  GetPracticeLoyaltyQuotesDocument,
  GetPracticeLoyaltyQuotesQuery,
  GetPracticeLoyaltyQuotesQueryVariables,
} from 'generated/core/graphql';
import { useGQLQuery } from 'hooks/useGQL';
import styled from 'styled-components/macro';
import moment from 'moment';
import { isPracticeGrandfathered } from 'pages/LoyaltyProviderView/utilities';

const AlertContainer = styled.div`
  margin-top: 1rem;
`;

const LoyaltyAlert = () => {
  const [
    getCurrentPracticeLoyaltyProgram,
    { data: getCurrentPracticeLoyaltyProgramResult },
  ] = useGQLQuery<
    GetCurrentPracticeLoyaltyProgramQuery,
    GetCurrentPracticeLoyaltyProgramQueryVariables
  >(GetCurrentPracticeLoyaltyProgramDocument);

  const [getPracticeLoyaltyQuotes, { data: getPracticeLoyaltyQuotesResult }] =
    useGQLQuery<
      GetPracticeLoyaltyQuotesQuery,
      GetPracticeLoyaltyQuotesQueryVariables
    >(GetPracticeLoyaltyQuotesDocument);

  // Use let instead of const so we can use them as dependencies in useEffect
  const [casesNeededToEnterNextTier, setCasesNeededToEnterNextTier] =
    React.useState(0);

  // This is defaulted to true until determined otherwise because
  // if the practice is grandfathered it shouldn't display
  const [practiceIsGrandfathered, setPracticeIsGrandfathered] =
    React.useState(true);
  const { userInfo } = useAuthContext();
  const practice =
    userInfo?.doctor?.practices?.length === 1
      ? userInfo?.doctor?.practices[0]
      : null;

  useEffect(() => {
    if (practice && !practice.parentOrganization) {
      getCurrentPracticeLoyaltyProgram({ practiceId: practice.id });
      getPracticeLoyaltyQuotes({ practiceId: practice.id });
    }
  }, [userInfo]);

  useEffect(() => {
    if (getPracticeLoyaltyQuotesResult) {
      setPracticeIsGrandfathered(
        isPracticeGrandfathered(
          getPracticeLoyaltyQuotesResult?.getPracticeLoyaltyQuotes
        )
      );
    }
  }, [getPracticeLoyaltyQuotesResult]);

  const practiceLoyaltyInfo =
    getCurrentPracticeLoyaltyProgramResult?.getCurrentPracticeLoyaltyProgram;
  const currentTier = practiceLoyaltyInfo?.loyaltyProgramTier;
  const currentTierName = currentTier?.name;
  const currentTierEntryRule = currentTier?.entryRule?.args?.minNumber;
  const caseCount = practiceLoyaltyInfo?.caseCount;
  const currentTierSortOrder = currentTier?.sortOrder;

  const isWithinMonthOfEvaluationPeriodEnd =
    moment(practiceLoyaltyInfo?.evaluationPeriodEnd).diff(moment(), 'days') <
    31;
  const evaluationPeriodInterval =
    practiceLoyaltyInfo?.loyaltyProgramTier?.loyaltyProgram?.interval;

  const displayDowngradeAlert = useMemo(() => {
    if (!currentTierSortOrder || practiceIsGrandfathered) {
      return false;
    }
    const upcomingTierSortOrder = currentTierSortOrder + 1;
    const upcomingTier =
      practiceLoyaltyInfo?.loyaltyProgramTier?.loyaltyProgram?.loyaltyProgramTiers.find(
        (tier) => tier.sortOrder === upcomingTierSortOrder
      );
    if (!currentTierEntryRule || !caseCount) {
      return false;
    }
    const casesNeededToMaintainTier = currentTierEntryRule - caseCount;
    if (
      !practiceLoyaltyInfo ||
      (caseCount !== 0 && !caseCount) ||
      (currentTierSortOrder !== 0 && !currentTierSortOrder) ||
      (currentTierEntryRule !== 0 && !currentTierEntryRule) ||
      (practice && practice.parentOrganization) ||
      !currentTierName ||
      !practice?.id
    ) {
      return false;
    }

    const upcomingTierEntryRule = upcomingTier?.entryRule?.args?.minNumber;

    if (upcomingTierEntryRule !== 0 && !upcomingTierEntryRule) {
      return false;
    }

    const casesNeededToEnterNextTier = upcomingTierEntryRule - caseCount;
    setCasesNeededToEnterNextTier(casesNeededToEnterNextTier);

    // Do not display alert if already at the lowest tier
    return (
      isWithinMonthOfEvaluationPeriodEnd &&
      casesNeededToMaintainTier < 6 &&
      casesNeededToMaintainTier > 0 &&
      currentTierSortOrder !== 0
    );
  }, [getCurrentPracticeLoyaltyProgramResult, userInfo]);

  const displayUpgradeAlert = useMemo(() => {
    if (
      !currentTierSortOrder ||
      (isPracticeGrandfathered as any) /* TODO: Casting is a quickfix */
    ) {
      return false;
    }
    const upcomingTierSortOrder = currentTierSortOrder + 1;
    const upcomingTier =
      practiceLoyaltyInfo?.loyaltyProgramTier?.loyaltyProgram?.loyaltyProgramTiers.find(
        (tier) => tier.sortOrder === upcomingTierSortOrder
      );
    return casesNeededToEnterNextTier < 6 && !!upcomingTier;
  }, [getCurrentPracticeLoyaltyProgramResult, userInfo]);

  if (!currentTierSortOrder) {
    return null;
  }
  const upcomingTierSortOrder = currentTierSortOrder + 1;
  const upcomingTier =
    practiceLoyaltyInfo?.loyaltyProgramTier?.loyaltyProgram?.loyaltyProgramTiers.find(
      (tier) => tier.sortOrder === upcomingTierSortOrder
    );
  if (
    !practiceLoyaltyInfo?.evaluationPeriodEnd ||
    !evaluationPeriodInterval ||
    !upcomingTier?.name
  ) {
    return null;
  }
  return (
    <AlertContainer>
      {displayDowngradeAlert && (
        <DowngradeLoyaltyTierAlert
          evaluationPeriodEnd={practiceLoyaltyInfo?.evaluationPeriodEnd || ''}
          caseCount={caseCount || 0}
          currentTierName={currentTierName || ''}
          interval={evaluationPeriodInterval}
          currentTierEntryRule={currentTierEntryRule || 0}
        />
      )}

      {displayUpgradeAlert && (
        <AlertContainer>
          <UpgradeLoyaltyTierAlert
            upcomingTierName={upcomingTier.name}
            casesNeededToEnterNextTier={casesNeededToEnterNextTier}
            getPracticeLoyaltyQuotesResult={getPracticeLoyaltyQuotesResult}
            evaluationPeriodEnd={
              getCurrentPracticeLoyaltyProgramResult
                ?.getCurrentPracticeLoyaltyProgram.evaluationPeriodEnd || ''
            }
            evaluationPeriodInterval={evaluationPeriodInterval}
          />
        </AlertContainer>
      )}
    </AlertContainer>
  );
};

export default LoyaltyAlert;
