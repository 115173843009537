import { useTransitionJourneyMutation } from 'generated/legacy/graphql';
import { ALIGNER_PRODUCTION_LEG } from 'utils/journey';
import { JourneyTransition } from 'types/journey';
import { StartMonitoringOptions } from 'generated/legacy/graphql';
import {
  StartMonitoringPatientMutation,
  StartMonitoringPatientMutationVariables,
  StartMonitoringPatientDocument,
} from 'generated/core/graphql';
import { useGQLMutation } from 'hooks/useGQL';
import { Patient } from 'types/fromApi';

const useMarkAkAsDelivered = () => {
  const [startMonitoringPatientFn, { loading: isStartingMonitoring }] =
    useGQLMutation<
      StartMonitoringPatientMutation,
      StartMonitoringPatientMutationVariables
    >(StartMonitoringPatientDocument, true);
  const [transitionJourney, { loading: isTransistionJourneySubmitting }] =
    useTransitionJourneyMutation();

  const markAkAsDelivered = async ({
    caseRef,
    patient,
  }: {
    caseRef: string;
    patient: Patient;
  }) => {
    const startMonitoringPreference =
      patient?.referringDentist?.user?.accountPreferences?.doctor
        ?.startMonitoringPreference;

    await transitionJourney({
      variables: {
        caseRef: caseRef,
        component: ALIGNER_PRODUCTION_LEG,
        transition: JourneyTransition.ForceAlignerKitDelivered,
      },
    });

    if (startMonitoringPreference === StartMonitoringOptions.Automatic) {
      await startMonitoringPatientFn({
        patientId: patient.id,
      });
    }
  };

  return {
    isLoading: isTransistionJourneySubmitting || isStartingMonitoring,
    markAkAsDelivered,
  };
};

export default useMarkAkAsDelivered;
