export const flexibleWidthProps = (isDesktop: boolean) => {
  return {
    style: {
      width: !isDesktop ? undefined : 'calc((100vw - 220px)/4)',
    },
  };
};

export const fixedWidthProps = (isDesktop: boolean) => {
  return {
    style: {
      width: !isDesktop ? undefined : '220px',
    },
  };
};

export const dollarFormat = (amount: number) => {
  return `${(amount / 100).toFixed(2)}`;
};

export const renderDateTime = (dateTime: string) =>
  new Date(dateTime)
    .toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZoneName: 'longOffset',
    })
    //get rid of time zone name and any commas
    .replace(new RegExp('[A-Z,]+', 'g'), '');
