import { AlertCard } from 'core/components';
import { ItiType } from 'generated/core/graphql';
import React from 'react';
import styled from 'styled-components/macro';
import { PROVIDER_FACING_STATUSES } from 'types/caseStatus';

const IFIMessage = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.default};
  > span {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
`;

const Container = styled.div`
  margin-bottom: 2rem;
`;
type RefinementsRequestedAlertCardProps = {
  currentStep: number | undefined;
  itiType: ItiType | null | undefined;
  providerFacingState: string | null | undefined;
  notes: string | null | undefined;
};

const RefinementsRequestedAlertCard = ({
  currentStep,
  itiType,
  providerFacingState,
  notes,
}: RefinementsRequestedAlertCardProps) => {
  const caseClosedForRefinements =
    providerFacingState &&
    [
      PROVIDER_FACING_STATUSES.CASE_CLOSED_INITIAL_FIT_ISSUE,
      PROVIDER_FACING_STATUSES.REFINEMENTS_REQUESTED,
    ].includes(providerFacingState as PROVIDER_FACING_STATUSES);

  // Legacy refinement cases won't have this refinement data, so the alert card
  // should not display. If refinement data is partially backfilled then those
  // fields should still display
  const caseHasCurrentStepData = typeof currentStep === 'number';

  const caseMissingRefinementData =
    !caseHasCurrentStepData && !itiType && !notes;

  if (!caseClosedForRefinements && caseMissingRefinementData) {
    return null;
  }

  const isInitialFitIssue = itiType === ItiType.InitialFitIssue;

  // A standard refinement is a midcourse correction or end of treatment refinement, opposed to an IFI

  const StandardRefinementAlert = (
    <AlertCard displayIcon={false} type="default">
      <IFIMessage>
        Refinements requested when the patient was on step{' '}
        <span>{currentStep}</span> of their aligners.
      </IFIMessage>
    </AlertCard>
  );

  const InitialFitIssueAlert = (
    <AlertCard
      displayIcon={false}
      type="default"
      header={"Provider's description of initial fit issue"}
    >
      {notes}
    </AlertCard>
  );

  return (
    <Container>
      {isInitialFitIssue ? InitialFitIssueAlert : StandardRefinementAlert}
    </Container>
  );
};

export default RefinementsRequestedAlertCard;
