import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { useParams, useHistory } from 'react-router-dom';
import { Accordion, colors } from 'core/components';
import {
  CaseTrackerSkeleton,
  MaterialsSkeleton,
} from 'pages/Patient/PatientDetail/Skeletons';
import {
  selectCases,
  selectSelectedCase,
  setSelectedCaseRef,
  fetchOrders,
  selectProviderFacingStatus,
  selectSortedTreatmentPlanStagings,
} from 'pages/Patient/patientSlice';
import { CaseTypeNames } from 'types/Case';
import {
  statusToActiveStepMapping,
  ACCORDIONS,
  statusToDefaultAccordionExpanded,
} from 'types/caseStatus';
import CaseTab, { TabLabelSkeleton } from 'components/CaseTab/CaseTab';
import CaseStatus from 'pages/Patient/PatientDetail/CaseStatus/CaseStatus';
import MoreRetainers from 'pages/Patient/PatientDetail/MoreRetainers';
import {
  AccordionContainer,
  AccordionDetails,
  AccordionSummary,
  CaseTabsContainer,
  Wrapper,
  Title,
} from 'pages/Patient/PatientDetail/PatientDetail.css';
import CaseTracker from 'pages/Patient/PatientDetail/CaseTracker';
import {
  alignerStepConfig,
  retainerStepConfig,
} from 'pages/Patient/PatientDetail/CaseTrackerConfigs';
import DiagnosticMaterialsSection from 'pages/Patient/PatientDetail/DiagnosticMaterials';
import IncompleteSubmission from 'pages/Patient/PatientDetail/IncompleteSubmission';
import QualityTicketSection from 'pages/Patient/PatientDetail/QualityTickets';
import { PROVIDER_FACING_STATUSES } from 'types/caseStatus';
import { RouteParams } from 'pages/Patient/CaseCreator/types';
import {
  usePatientLoadingStates,
  getActiveStepForCaseType,
  isTpStateRelevantToProvider,
} from 'pages/Patient/utils';
import { ProviderFacingStates } from 'types/Case';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { BaseSkeleton } from 'components/Skeletons';
import { coreClient } from 'gql/clients';
import {
  OrderStatus,
  QualityTickets,
  QualityTicketsDocument,
  QualityTicketsQuery,
  QualityTicketsQueryVariables,
} from 'generated/core/graphql';
import api from 'state/api';

const { useLazyGetShipmentForOrderItemQuery, useLazyGetOrderItemsCoreQuery } =
  api;

const MainContent = () => {
  const dispatch = useDispatch();
  const { 'enable-create-quality-ticket': enableQualityTickets } = useFlags();
  const [qualityTickets, setQualityTickets] = useState<QualityTickets[]>([]);
  const [trackingLink, setTrackingLink] = useState('');
  const { id, caseRef: caseRefParam } = useParams<RouteParams>();
  const { replace } = useHistory();
  const { areMaterialsFetching, isFetchingPatient, isFetchingCases } =
    usePatientLoadingStates();
  const isLoading = isFetchingPatient || isFetchingCases;
  const cases = useSelector(selectCases);
  const selectedCase = useSelector(selectSelectedCase);
  const providerFacingStatus = useSelector(selectProviderFacingStatus);

  const [
    getOrderItems,
    { data: orderItemsData, isFetching: isFetchingOrderItems },
  ] = useLazyGetOrderItemsCoreQuery();
  const [getShipmentForOrderItem, { isFetching: isFetchingShipment }] =
    useLazyGetShipmentForOrderItemQuery();

  const orderItems = useMemo(
    () => orderItemsData?.edges.map((edge) => edge.node),
    [orderItemsData]
  );

  const trackingLinkLoading = isFetchingShipment || isFetchingOrderItems;

  const tps = useSelector(selectSortedTreatmentPlanStagings);
  const caseTrackerActiveStep = getActiveStepForCaseType({
    caseTypeName: selectedCase?.caseType?.name,
    providerFacingStatus,
  });

  const hasProReview = tps.some(isTpStateRelevantToProvider);

  const showDiagnosticMaterials =
    caseTrackerActiveStep >=
      statusToActiveStepMapping[
        PROVIDER_FACING_STATUSES.TREATMENT_PLAN_READY_FOR_YOUR_REVIEW
      ] ||
    (caseTrackerActiveStep >=
      statusToActiveStepMapping[
        PROVIDER_FACING_STATUSES.TREATMENT_PLANNING_IN_PROGRESS
      ] &&
      tps.length > 1) ||
    selectedCase?.caseState?.providerFacing ===
      ProviderFacingStates.CASE_CANCELED ||
    hasProReview;
  const showIncompleteSubmission =
    providerFacingStatus === PROVIDER_FACING_STATUSES.INCOMPLETE_SUBMISSION;

  const [expandCaseTracker, setExpandCaseTracker] = useState(false);
  const [expandDiagnosticMaterials, setExpandDiagnosticMaterials] =
    useState(false);
  const [expandQualityTickets, setExpandQualityTickets] = useState(false);

  useEffect(() => {
    selectedCase?.caseRef &&
      getOrderItems({
        caseRef: selectedCase?.caseRef,
        orderStatus: [OrderStatus.Processing, OrderStatus.Fulfilled],
      });
  }, [selectedCase?.caseRef]);

  useEffect(() => {
    // get public tracking url for aligner good associated with the case
    orderItems &&
      (async () => {
        const { id: alignerGoodOrderItemRef } =
          orderItems?.find(
            (orderItem) =>
              orderItem?.productVariant.product.productType === 'ALIGNER_GOOD'
          ) || {};
        if (!alignerGoodOrderItemRef) {
          return;
        }
        const { tracker } =
          (await getShipmentForOrderItem({
            orderItemRef: alignerGoodOrderItemRef,
          }).unwrap()) ?? {};
        const { publicUrl } = tracker ?? {};
        publicUrl && setTrackingLink(publicUrl);
      })();
  }, [orderItems]);

  useEffect(() => {
    if (!providerFacingStatus || showIncompleteSubmission || !tps) {
      return;
    }

    const defaultExpanded =
      statusToDefaultAccordionExpanded[providerFacingStatus];

    //If we have tp's to show, override the default status, to prioritize showing the tps
    const shouldExpandCaseTracker =
      providerFacingStatus ===
        PROVIDER_FACING_STATUSES.TREATMENT_PLANNING_IN_PROGRESS && hasProReview
        ? false
        : defaultExpanded[ACCORDIONS.CASE_TRACKER];

    setExpandCaseTracker(shouldExpandCaseTracker);
    setExpandDiagnosticMaterials(
      defaultExpanded[ACCORDIONS.DIAGNOSTIC_MATERIALS]
    );
  }, [providerFacingStatus, tps]);

  useEffect(() => {
    // We're already on the correct case -> no need to do anything
    if (selectedCase?.caseRef === caseRefParam) {
      return;
    }

    if (selectedCase?.caseRef) {
      // Correct case is loaded but not present in url -> update url
      const newPath = generatePath('/patient/:id(\\d+)/cases/:caseRef', {
        id,
        caseRef: selectedCase.caseRef,
      });

      replace(newPath);

      dispatch(fetchOrders({ caseRef: selectedCase.caseRef }));
    } else if (caseRefParam) {
      // Incorrect/No case is loaded -> load the correct case
      dispatch(setSelectedCaseRef(caseRefParam));
    }
  }, [caseRefParam, selectedCase?.caseRef]);

  useEffect(() => {
    if (!selectedCase) {
      return;
    }

    dispatch(fetchOrders({ caseRef: selectedCase.caseRef }));
  }, [selectedCase?.caseRef]);

  useEffect(() => {
    if (!selectedCase?.caseRef) {
      return;
    }
    if (
      enableQualityTickets &&
      (providerFacingStatus === PROVIDER_FACING_STATUSES.IN_TREATMENT ||
        providerFacingStatus === PROVIDER_FACING_STATUSES.IN_RETENTION)
    ) {
      coreClient
        .query<QualityTicketsQuery, QualityTicketsQueryVariables>({
          query: QualityTicketsDocument,
          variables: {
            caseRef: selectedCase?.caseRef,
          },
        })
        .then((res) => {
          if (res.data?.getQualityTicketsData) {
            setQualityTickets(res.data.getQualityTicketsData);
          }
        });
    }
  }, [selectedCase?.caseRef, enableQualityTickets]);

  useEffect(() => {
    if (qualityTickets.length) {
      setExpandQualityTickets(true);
    }
  }, [qualityTickets]);

  const onCaseTabClick = (caseRef: string) => {
    dispatch(setSelectedCaseRef(caseRef));
  };

  const isCaseTrackerLoading =
    isLoading ||
    (providerFacingStatus === PROVIDER_FACING_STATUSES.ACTION_NEEDED &&
      areMaterialsFetching);
  let stepConfigs;

  switch (selectedCase?.caseType?.name) {
    case CaseTypeNames.RETAINER:
      stepConfigs = retainerStepConfig;
      break;
    case CaseTypeNames.ALIGNER:
    default:
      stepConfigs = alignerStepConfig;
  }
  const showMoreRetainersSection =
    providerFacingStatus === PROVIDER_FACING_STATUSES.IN_RETENTION;

  return (
    <Wrapper>
      <CaseTabsContainer>
        {isLoading ? (
          <TabLabelSkeleton />
        ) : (
          cases.map((caseData) => (
            <CaseTab
              key={caseData.caseRef}
              isSelected={caseData.caseRef === selectedCase?.caseRef}
              label={caseData.caseType.label}
              onClick={() => onCaseTabClick(caseData.caseRef)}
              created_at={caseData.createdAt}
              isActive={!!caseData.isActive}
            />
          ))
        )}
      </CaseTabsContainer>
      <CaseStatus trackingLink={trackingLink} />
      <AccordionContainer
        visible={enableQualityTickets && qualityTickets.length}
      >
        {isLoading ? (
          <BaseSkeleton isReversed />
        ) : (
          <Accordion elevation={0} expanded={expandQualityTickets}>
            <AccordionSummary
              style={{ background: colors.white }}
              onClick={() => setExpandQualityTickets(!expandQualityTickets)}
            >
              <Title data-testid="pdp-case-section-accordion">
                Reported issues
              </Title>
            </AccordionSummary>
            <QualityTicketSection qualityTickets={qualityTickets} />
          </Accordion>
        )}
      </AccordionContainer>
      {showIncompleteSubmission ? (
        <IncompleteSubmission />
      ) : (
        <>
          {showMoreRetainersSection && <MoreRetainers />}
          {isCaseTrackerLoading ? (
            <CaseTrackerSkeleton />
          ) : (
            <AccordionContainer visible={caseTrackerActiveStep >= 0}>
              {!(caseTrackerActiveStep < 0) && (
                <Accordion elevation={0} expanded={expandCaseTracker}>
                  <AccordionSummary
                    style={{ background: colors.white }}
                    onClick={() => {
                      setExpandCaseTracker(!expandCaseTracker);
                    }}
                  >
                    <Title data-testid="pdp-case-section-accordion">
                      Case tracker
                    </Title>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CaseTracker
                      activeStep={caseTrackerActiveStep}
                      stepConfigs={stepConfigs}
                      trackingLink={trackingLink}
                      trackingLinkLoading={trackingLinkLoading}
                    />
                  </AccordionDetails>
                </Accordion>
              )}
            </AccordionContainer>
          )}
          {isLoading && <MaterialsSkeleton />}
          <AccordionContainer visible={showDiagnosticMaterials}>
            <Accordion elevation={0} expanded={expandDiagnosticMaterials}>
              <AccordionSummary
                style={{ background: colors.white }}
                onClick={() => {
                  setExpandDiagnosticMaterials(!expandDiagnosticMaterials);
                }}
              >
                <Title data-testid="pdp-case-section-accordion">
                  Diagnostic materials
                </Title>
              </AccordionSummary>
              <AccordionDetails>
                <DiagnosticMaterialsSection />
              </AccordionDetails>
            </Accordion>
          </AccordionContainer>
        </>
      )}
    </Wrapper>
  );
};

export default MainContent;
