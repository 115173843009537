import React from 'react';
import {
  ContactContainer,
  ContactSection,
  ContactText,
  ContactTypeTitle,
  UserSvg,
  MailSvg,
  PhoneSvg,
} from 'pages/ContactUs/ContactUs.css';
import {
  PhoneNumber,
  PhoneNumberContainer,
} from 'pages/ContactUs/ContactUs.css';
import { colors, type } from 'core/components';
import { AccountManagementRep } from 'generated/core/graphql';
import { formatPhoneNumber } from 'utils/customer';

type AccountManagerInfoProps = {
  accountManagementRep: AccountManagementRep;
};

const ContactSectionComponent = ({
  icon: Icon,
  title,
  content,
}: {
  icon: typeof MailSvg;
  title: string;
  content: React.ReactNode;
}) => (
  <ContactSection>
    <Icon stroke={colors.black70} />
    <ContactText>
      <ContactTypeTitle>{title}</ContactTypeTitle>
      {content}
    </ContactText>
  </ContactSection>
);

const AccountManagerInfo = ({
  accountManagementRep,
}: AccountManagerInfoProps) => {
  const { email, phone, name } = accountManagementRep;

  return (
    <ContactContainer>
      {name && (
        <ContactSectionComponent icon={UserSvg} title="NAME" content={name} />
      )}
      {phone && (
        <ContactSectionComponent
          icon={PhoneSvg}
          title="PHONE"
          content={
            <PhoneNumberContainer>
              <PhoneNumber>
                {formatPhoneNumber(phone).formatedPhoneNumber}
              </PhoneNumber>
            </PhoneNumberContainer>
          }
        />
      )}
      {email && (
        <ContactSectionComponent
          icon={MailSvg}
          title="EMAIL"
          content={<type.Link>{email}</type.Link>}
        />
      )}
    </ContactContainer>
  );
};

export default AccountManagerInfo;
