import { MaterialReviewTransitions } from 'generated/legacy/graphql';
import {
  AddMaterialEvaluationsMutation,
  AddMaterialEvaluationsMutationVariables,
  AddMaterialEvaluationsDocument,
  MaterialEvaluationTypes,
  ScanTypes,
  GetMaterialUploadDataDocument,
  GetMaterialUploadDataQuery,
  GetMaterialUploadDataQueryVariables,
  MaterialStates,
} from 'generated/core/graphql';
import React, { useState } from 'react';
import {
  Form,
  FormRow,
  Label,
  QuestionHeading,
  Radio,
  RadioGroup,
  SmallHeading,
  TextAreaFullWidth,
  ButtonRow,
  DeleteButton,
  UploadContainer,
  AttachmentRow,
  FileName,
  ReviewRadiosRow,
  NotApplicableContainer,
} from 'pages/OrthoPrism/MaterialReview/MaterialReviewForm.css';
import { Button, SelectInput } from 'core/components';
import FileUpload from 'components/FileUpload';
import { ACCESS_GROUPS } from 'types';
import readFile from 'utils/readFile';
import ImgIcon from 'assets/img-icon.svg?react';
import { FileInfo } from 'pages/OrthoPrism/utils';
import NeedsClarificationForm from 'pages/OrthoPrism/NeedsClarificationForm';
import { useSelector } from 'react-redux';

import {
  selectIsInClarificationStep,
  selectSelectedCase,
  selectMaterialEvaluationRejectionReasonsMap,
  selectXrayMaterialState,
  selectScanMaterialState,
  MaterialFragment,
} from 'pages/OrthoPrism/orthoSlice';
import { useGQLMutation, useGQLQuery } from 'hooks/useGQL';
import { ValueType } from 'react-select';
import { ReactSelectOption, StringMap } from 'utils/types';
import axios from 'axios';
import { useAuthContext } from 'context/AuthContext';

type Props = {
  materials: MaterialFragment[];
  materialName: string;
  onSubmit: (
    reloadScans: boolean,
    reloadXrays: boolean,
    reloadPhotos: boolean
  ) => void;
};

const MaterialReview = ({ materials, materialName, onSubmit }: Props) => {
  const { checkHasAccess } = useAuthContext();
  const [evaluationNotes, setEvaluationNotes] = useState<string>('');
  const [files, setFiles] = useState<FileInfo[]>([]);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [
    selectedMaterialRejectionReasons,
    setSelectedMaterialRejectionReasons,
  ] = useState<ReactSelectOption[]>([]);
  const selectedCase = useSelector(selectSelectedCase);
  const isInClarificationStep = useSelector(selectIsInClarificationStep);
  const xrayMaterialState = useSelector(selectXrayMaterialState);
  const scanMaterialState = useSelector(selectScanMaterialState);
  const materialEvaluationRejectionReasonsMap = useSelector(
    selectMaterialEvaluationRejectionReasonsMap
  );

  const isScan = materialName === 'scans';
  const isXray = materialName === 'x-rays';

  const materialState = isXray ? xrayMaterialState : scanMaterialState;

  const rejectionReasons =
    materialEvaluationRejectionReasonsMap[materials[0].materialType.name] || [];
  const showClarificationOption = !isInClarificationStep;

  const hasFiles = !!files.length;
  const patientId = selectedCase?.patientId!;

  const checkMaterialsValid = () => {
    if (!isScan) {
      return true;
    }

    const requiredScanTypes = [ScanTypes.LowerScan, ScanTypes.UpperScan];

    return requiredScanTypes.every((scanType) =>
      materials.some((material) => material.materialType.name === scanType)
    );
  };

  const materialsInvalid = !checkMaterialsValid();

  const [transitionState, setTransitionState] = useState<
    MaterialReviewTransitions | undefined
  >(materialsInvalid ? MaterialReviewTransitions.Reject : undefined);

  const [addMaterialEvaluations] = useGQLMutation<
    AddMaterialEvaluationsMutation,
    AddMaterialEvaluationsMutationVariables
  >(AddMaterialEvaluationsDocument);

  const [getMaterialUploadUrl] = useGQLQuery<
    GetMaterialUploadDataQuery,
    GetMaterialUploadDataQueryVariables
  >(GetMaterialUploadDataDocument);

  const canSubmit = () => {
    // Submission is valid if it is an approval. Otherwise some other option must be selected and noted must be added
    // For rejections, a rejection reason is required in addition to evaluation notes
    return (
      transitionState === MaterialReviewTransitions.Approve ||
      (transitionState &&
        evaluationNotes.trim().length > 0 &&
        (transitionState !== MaterialReviewTransitions.Reject ||
          selectedMaterialRejectionReasons.length))
    );
  };

  const handleProblemsChange = (
    value: ValueType<ReactSelectOption<string>> | null | undefined
  ) => {
    const selectedOptions = value || [];
    setSelectedMaterialRejectionReasons(
      selectedOptions as ReactSelectOption<string>[]
    );
  };

  const uploadAttachments = async () => {
    // upload attachment files to s3
    return await Promise.all(
      files.map(async ({ file }) => {
        const uploadData = await getMaterialUploadUrl({
          patientId,
          fileName: file.name,
        });
        const fields: StringMap = uploadData?.getMaterialUploadData.fields;
        const url = uploadData?.getMaterialUploadData.url!;

        const data = new FormData();
        Object.entries(fields).forEach(([key, value]) =>
          data.append(key, value)
        );
        data.append('file', file);

        try {
          await axios({
            method: 'POST',
            url,
            data,
            headers: {
              'Content-Type': file.type,
            },
          });
        } catch (err) {
          throw new Error('Attachment could not be uploaded, Try again');
        }
        return fields['key'];
      })
    );
  };

  const handleSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault();

    try {
      // Set local isSubmitting flag, this track the uploadFileToS3 sync call as well
      setIsSubmitting(true);

      // Loop over the attachments and upload them to S3
      // Enforcing here that attachments cannot be included with an approval
      let awsStorageLocations: string[] = [];
      let evaluationNotesToSubmit: string | null = null;

      if (transitionState !== MaterialReviewTransitions.Approve) {
        awsStorageLocations = await uploadAttachments();
        evaluationNotesToSubmit = evaluationNotes;
      }
      const rejectionReasonsToSubmit =
        transitionState === MaterialReviewTransitions.Reject
          ? selectedMaterialRejectionReasons.map((r) => r.label)
          : [];

      await addMaterialEvaluations({
        caseRef: selectedCase?.caseRef!,
        patientId,
        annotatedFileLocations: awsStorageLocations,
        materialEvaluationsInput: materials.map((m) => ({
          materialId: m.id,
          materialEvaluationType: MaterialEvaluationTypes.OrthodonticEvaluation,
          approved: transitionState === MaterialReviewTransitions.Approve,
          rejectionReasons: rejectionReasonsToSubmit,
          data: {
            evaluationNotes: evaluationNotesToSubmit,
          },
          repairable: transitionState === MaterialReviewTransitions.NeedsRepair,
        })),
      });

      setIsSubmitted(true);
      onSubmit(isScan, isXray, false);
    } finally {
      // After all submission is done, set back the flag
      setIsSubmitting(false);
      setEvaluationNotes('');
    }
  };

  const onChangeTransition = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTransitionState(e.target.value as MaterialReviewTransitions);
  };

  const onChangeNotes = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEvaluationNotes(e.target.value);
  };

  const handleSelectFiles = async (files: FileList | null) => {
    if (!files) {
      return;
    }

    const fileArray = Array.from(files);
    const selected = await Promise.all(
      fileArray.map(async (file) => {
        const fileSrc = await new Promise<string>((resolve) =>
          readFile(file, resolve)
        );

        return {
          file,
          fileSrc,
        };
      })
    );

    setFiles((prevFiles) => [...prevFiles, ...selected]);
  };

  const handleDeleteFile = (fileName: string) => {
    setFiles((prevFiles) =>
      prevFiles.filter(({ file }) => file.name !== fileName)
    );
  };

  if (!materialState || !checkHasAccess(ACCESS_GROUPS.MATERIAL_REVIEW)) {
    return null;
  }

  if (!selectedCase?.isActive) {
    return (
      <NotApplicableContainer>
        <h3>Review is not applicable</h3>
        <p>Case has been archived</p>
      </NotApplicableContainer>
    );
  }

  if (
    materialState?.state !== MaterialStates.AwaitingOrthodonticEvaluation ||
    isSubmitted
  ) {
    return null;
  }

  const isLoading = isSubmitting;

  return (
    <Form>
      <ReviewRadiosRow>
        <QuestionHeading>Are these {materialName} acceptable?</QuestionHeading>
        <RadioGroup role="radiogroup">
          <Label disabled={materialsInvalid}>
            <Radio
              type="radio"
              name="acceptance"
              data-testid={`${materialName}-accept`}
              onChange={onChangeTransition}
              value={MaterialReviewTransitions.Approve}
              disabled={materialsInvalid}
            />
            Accept
          </Label>
          <Label>
            <Radio
              type="radio"
              name="acceptance"
              data-testid={`${materialName}-reject`}
              onChange={onChangeTransition}
              value={MaterialReviewTransitions.Reject}
              checked={transitionState === MaterialReviewTransitions.Reject}
            />
            Reject
          </Label>
          {isScan && (
            <Label disabled={materialsInvalid}>
              <Radio
                type="radio"
                name="acceptance"
                data-testid={`${materialName}-repair`}
                onChange={onChangeTransition}
                value={MaterialReviewTransitions.NeedsRepair}
                disabled={materialsInvalid}
              />
              Send for repair
            </Label>
          )}
          {showClarificationOption && (
            <Label disabled={materialsInvalid}>
              <Radio
                type="radio"
                name="acceptance"
                onChange={onChangeTransition}
                value={MaterialReviewTransitions.QcRequestedClarification}
                disabled={materialsInvalid}
              />
              Needs clarification
            </Label>
          )}
        </RadioGroup>
      </ReviewRadiosRow>
      {transitionState === MaterialReviewTransitions.QcRequestedClarification &&
      showClarificationOption ? (
        <NeedsClarificationForm onSubmit={onSubmit} />
      ) : (
        <>
          {transitionState === MaterialReviewTransitions.Reject && (
            <FormRow data-testid={`${materialName}-problem-select`}>
              <QuestionHeading>Problems*</QuestionHeading>
              <SelectInput
                placeholder="Select Problem"
                data-testid={`${materialName}-problems`}
                options={rejectionReasons.map((r) => ({
                  label: r.label!,
                  value: r.name!,
                }))}
                onChange={handleProblemsChange}
                isMulti={true}
                id="problem-input"
              />
            </FormRow>
          )}
          {transitionState &&
            transitionState !== MaterialReviewTransitions.Approve && (
              <>
                {isScan && (
                  <FormRow>
                    <SmallHeading>Attachment(s)</SmallHeading>
                    {files.map((f) => (
                      <AttachmentRow key={f.file.name}>
                        <FileName src={f.fileSrc}>
                          <ImgIcon role="document" />
                          {' ' + f.file.name}
                        </FileName>
                        <DeleteButton
                          disabled={isLoading}
                          onClick={() => handleDeleteFile(f.file.name)}
                          type="button"
                        />
                      </AttachmentRow>
                    ))}
                    <UploadContainer isSmall={hasFiles}>
                      <FileUpload
                        fileType=".jpg, .jpeg, .png"
                        allowMultipleFiles
                        isHorizontal={hasFiles}
                        isDisabled={isLoading}
                        onSelectFile={handleSelectFiles}
                        textOverride={hasFiles ? 'Add another' : ''}
                        testId={`${materialName}-attachment`}
                      />
                    </UploadContainer>
                  </FormRow>
                )}

                <FormRow>
                  <SmallHeading>
                    Notes to
                    {transitionState === MaterialReviewTransitions.NeedsRepair
                      ? ' repair team'
                      : ' doctor'}
                    *
                  </SmallHeading>
                  <TextAreaFullWidth
                    data-testid={`${materialName}-notes`}
                    maxLength={5000}
                    onChange={onChangeNotes}
                    placeholder="Notes or instructions"
                  />
                </FormRow>
              </>
            )}
          <ButtonRow>
            <Button
              buttonType="secondary"
              data-testid={`${materialName}-submit`}
              disabled={!canSubmit() || isLoading}
              isLoading={isLoading}
              isShort
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </ButtonRow>
        </>
      )}
    </Form>
  );
};

export default MaterialReview;
