import {
  PromotionMethods,
  PromotionEffectTypes,
  PromoCriteriaTypes,
  PromotionCriteriaInputArgs,
  PromotionEffectArgsInput,
  PromotionsQuery,
} from 'generated/core/graphql';
import React, { useState, useEffect } from 'react';
import { Modal, Grid, Button } from 'core/components';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { useNotificationContext } from 'core/context/NotificationContext';
import {
  StyledModal,
  Header,
  StyledDivider,
  Body,
  Footer,
  CancelButton,
  ModalTitle,
} from 'components/Modals/OrderRetainersModal/OrderRetainersModal.css';
import api from 'state/api';
import {
  CreatePromotionInput,
  FormTextField,
  CriteriaArgsFields,
  EffectArgsFields,
} from 'components/Modals/CreatePromotionModal/helper';

type Promotion = PromotionsQuery['promotions'][0];

type CreatePromotionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  promotion?: Promotion | null; // Add promotion prop for viewing
  refetchPromotions?: () => void;
};

const formDefaultValues: CreatePromotionInput = {
  name: '',
  method: PromotionMethods.Code,
  effectDescription: '',
  effectType: PromotionEffectTypes.PercentageDiscount,
  effectArgsJson: JSON.stringify(
    {
      percentageDiscount: {
        percentage: 0,
        skus: [],
      },
    },
    null,
    4
  ),
  criteriaType: PromoCriteriaTypes.MetafieldCriteria,
  criteriaArgsJson: JSON.stringify({ productSkus: [] }, null, 4),
  isActive: false,
  priorityScore: 0,
};

const CreatePromotionModal: React.FC<CreatePromotionModalProps> = ({
  isOpen,
  onClose,
  promotion,
  refetchPromotions,
}) => {
  const { showNotification } = useNotificationContext();
  const [formValues, setFormValues] =
    useState<CreatePromotionInput>(formDefaultValues);

  const [createPromotion, createResult] = api.useCreatePromotionMutation();

  useEffect(() => {
    if (createResult.isSuccess) {
      showNotification('Promotion saved successfully', 'success');
    } else if (createResult.isError) {
      showNotification('Failed to save promotion', 'error');
    }
  }, [createResult]);

  useEffect(() => {
    if (promotion) {
      setFormValues({
        id: promotion.id,
        name: promotion.name,
        method: promotion.method.toString() as PromotionMethods,
        effectDescription: promotion.effects[0].description || '',
        effectType:
          promotion.effects[0].effectType?.toString() as PromotionEffectTypes,
        effectArgsJson: JSON.stringify(
          promotion.effects[0].argsJson || {},
          null,
          4
        ),
        criteriaType:
          promotion.promoCriteria[0].criteriaType?.toString() as PromoCriteriaTypes,
        criteriaArgsJson: JSON.stringify(
          promotion.promoCriteria[0].argsJson || {},
          null,
          4
        ),
        isActive: promotion.isActive,
        priorityScore: promotion.priorityScore,
      });
    } else {
      setFormValues(formDefaultValues);
    }
  }, [promotion]);

  const handlePromotionEffectTypeChange = (
    effectType: PromotionEffectTypes
  ) => {
    const effectArgs = {
      [PromotionEffectTypes.PercentageDiscount]: {
        percentageDiscount: { percentage: 0, skus: [] },
      },
      [PromotionEffectTypes.ProductCredit]: {
        productCredit: { creditInCents: 0, skus: [] },
      },
      [PromotionEffectTypes.VolumePricing]: {
        volumePricing: {
          skus: [],
          tiers: [{ priceInCents: 0, quantity: 0 }],
        },
      },
      [PromotionEffectTypes.Message]: {
        message: { message: '' },
      },
    };
    setFormValues({
      ...formValues,
      effectType,
      effectArgsJson: JSON.stringify(
        effectArgs[effectType as PromotionEffectTypes],
        null,
        4
      ),
    });
  };

  const handleCriteriaTypeChange = (criteriaType: PromoCriteriaTypes) => {
    const criteriaArgs = {
      [PromoCriteriaTypes.ProductInclusionList]: { productSkus: [] },
      [PromoCriteriaTypes.MetafieldCriteria]: {
        metafieldCriteria: {
          key: '',
          operator: 'EQUALS',
          type: 'STRING',
          value: '',
        },
      },
    };
    // only setFormValues if criteriaType is different
    if (criteriaType !== formValues.criteriaType) {
      setFormValues({
        ...formValues,
        criteriaType,
        criteriaArgsJson: JSON.stringify(criteriaArgs[criteriaType], null, 4),
      });
    }
  };

  const handleChange =
    (field: keyof CreatePromotionInput) =>
    (e: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
      setFormValues({ ...formValues, [field]: e.target.value });
    };

  const handleCheckboxChange =
    (field: keyof CreatePromotionInput) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormValues({ ...formValues, [field]: e.target.checked });
    };

  const validateForm = () => {
    const effectArgs = JSON.parse(formValues.effectArgsJson || '{}');
    const percentageDiscount = effectArgs.percentageDiscount || {};
    const productCredit = effectArgs.productCredit || {};

    if (
      !formValues.name ||
      !formValues.method ||
      !formValues.effectDescription ||
      !formValues.effectType ||
      !formValues.effectArgsJson ||
      !formValues.criteriaType ||
      !formValues.criteriaArgsJson ||
      formValues.priorityScore === undefined
    ) {
      showNotification('Please fill in all required fields', 'error');
      return false;
    }

    if (formValues.priorityScore < 0) {
      showNotification('Priority Score cannot be negative', 'error');
      return false;
    }

    if (
      formValues.effectType === PromotionEffectTypes.PercentageDiscount &&
      (percentageDiscount.percentage < 0 || percentageDiscount.percentage > 100)
    ) {
      showNotification('Percentage must be between 0 and 100', 'error');
      return false;
    }

    if (
      formValues.effectType === PromotionEffectTypes.ProductCredit &&
      productCredit.creditInCents > 100000
    ) {
      showNotification('Product credit cannot be greater than $1000', 'error');
      return false;
    }

    return true;
  };

  const onSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const input = {
      ...formValues,
      priorityScore: parseInt(formValues.priorityScore?.toString() || '0', 10),
      effectArgsJson: JSON.parse(
        formValues.effectArgsJson || '{}'
      ) as PromotionEffectArgsInput,
      criteriaArgsJson: JSON.parse(
        formValues.criteriaArgsJson || '{}'
      ) as PromotionCriteriaInputArgs,
    };

    // Ensure integer conversion for specific fields
    if (input.effectArgsJson.percentageDiscount) {
      input.effectArgsJson.percentageDiscount.percentage = parseInt(
        input.effectArgsJson.percentageDiscount.percentage.toString(),
        10
      );
    }
    if (input.effectArgsJson.productCredit) {
      input.effectArgsJson.productCredit.creditInCents = parseInt(
        input.effectArgsJson.productCredit.creditInCents.toString(),
        10
      );
    }
    if (input.effectArgsJson.volumePricing) {
      input.effectArgsJson.volumePricing.tiers =
        input.effectArgsJson.volumePricing.tiers?.map((tier) => ({
          ...tier,
          priceInCents: parseInt(tier?.priceInCents?.toString() || '0', 10),
          quantity: parseInt(tier?.quantity?.toString() || '0', 10),
        }));
    }

    await createPromotion({ input });

    setFormValues(formDefaultValues);

    if (refetchPromotions) {
      refetchPromotions();
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} hideCloseButton>
      <StyledModal>
        <Header>
          <ModalTitle>
            {formValues.id ? 'View Promotion' : 'Create Promotion'}
          </ModalTitle>
        </Header>
        <StyledDivider />
        <Body>
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            style={{ marginLeft: '-0.5rem', marginTop: 0 }}
          >
            <FormTextField
              label="Name"
              value={formValues.name}
              onChange={handleChange('name')}
            />
            <FormTextField
              label="Method"
              value={formValues.method}
              onChange={handleChange('method')}
              type="text"
              select
              options={Object.values(PromotionMethods).map((method) => ({
                value: method,
                label: method,
              }))}
            />
            <FormTextField
              label="Effect Description"
              value={formValues.effectDescription}
              onChange={handleChange('effectDescription')}
            />
            <FormTextField
              label="Effect Type"
              value={formValues.effectType}
              onChange={(e) =>
                handlePromotionEffectTypeChange(
                  e.target.value as PromotionEffectTypes
                )
              }
              type="text"
              select
              options={Object.values(PromotionEffectTypes)
                .filter((e) => e !== PromotionEffectTypes.Message) // Message effect type is not supported
                .map((effectType) => ({
                  value: effectType,
                  label: effectType,
                }))}
            />
            <EffectArgsFields
              formValues={formValues}
              setFormValues={setFormValues}
            />
            <FormTextField
              label="Criteria Type"
              value={formValues.criteriaType || ''}
              onChange={(e) =>
                handleCriteriaTypeChange(e.target.value as PromoCriteriaTypes)
              }
              type="text"
              select
              options={Object.values(PromoCriteriaTypes).map(
                (criteriaType) => ({
                  value: criteriaType,
                  label: criteriaType,
                })
              )}
            />
            <CriteriaArgsFields
              formValues={formValues}
              setFormValues={setFormValues}
            />
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formValues.isActive}
                    onChange={handleCheckboxChange('isActive')}
                  />
                }
                label="Is Active"
              />
            </Grid>
            <FormTextField
              label="Priority Score"
              value={formValues.priorityScore || 0}
              onChange={handleChange('priorityScore')}
              type="number"
            />
          </Grid>
        </Body>
        <StyledDivider />
        <Footer>
          <CancelButton buttonType="text" onClick={onClose}>
            Cancel
          </CancelButton>
          {!formValues.id && (
            <Button
              onClick={onSubmit}
              buttonType="secondary"
              isLoading={createResult.isLoading}
              disabled={createResult.isLoading}
            >
              Submit
            </Button>
          )}
        </Footer>
      </StyledModal>
    </Modal>
  );
};

export default CreatePromotionModal;
