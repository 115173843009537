import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loading, AlertCard } from 'core/components';
import moment from 'moment';

import { useIsLoading } from 'state/system';
import { formatIntakeSections } from 'utils/intake';
import VersionPills, { useVersionPills } from 'components/StyledVersionPills';
import { determineIntakeName } from 'utils/intake';
import { DentalNotationOptions } from 'generated/legacy/graphql';
import {
  IntakeSection,
  TreatmentObjectiveTemplateSection,
} from 'generated/core/graphql';

import {
  selectCompletedIntakeForms,
  fetchIntakeForms,
  selectSelectedCase,
  selectPatientDoctorPreferences,
  selectCustomer,
} from 'pages/OrthoPrism/orthoSlice';
import SurveySection from 'components/Intake/SurveySection';
import * as S from 'pages/OrthoPrism/Intake/Intake.css';
import { useHistory } from 'react-router-dom';
import { LinkItem } from 'pages/OrthoPrism/OrthoPrism.css';
import TreatmentObjectives from 'components/TreatmentObjectives/TreatmentObjectives';
import { PROVIDER_FACING_STATUSES } from 'types/caseStatus';
import { DATE_TIME_FORMAT } from 'types/treatmentObjective';
import { convertTemplateSectionToReadOnlyIntake } from 'utils/treatmentObjective';

import useTreatmentObjective from 'hooks/useTreatmentObjective';
import { Formik } from 'formik';
import { Template } from 'generated/core/graphql';
import { SupportedBrand } from 'utils/brands';

const Intake = () => {
  const intakeForms = useSelector(selectCompletedIntakeForms);
  const doctorPreferences = useSelector(selectPatientDoctorPreferences);
  const selectedCase = useSelector(selectSelectedCase);
  const patient = useSelector(selectCustomer);
  const vpProps = useVersionPills(intakeForms ?? [], true);
  const intakeForm = intakeForms?.[vpProps.currentIndex];
  const { push } = useHistory();
  const [formattedIntakeSections, setFormattedIntakeSections] = useState<
    IntakeSection[]
  >([]);
  const [[intakeName, submissionSubtext], setIntakeInfo] = useState(['', '']);

  const {
    initialValues,
    originatedTemplate,
    systemTemplates,
    latestTreatmentObjectiveWithQuestions,
    clinicalPreferencesTemplate,
  } = useTreatmentObjective({
    userId: patient?.referringDentist?.id,
    caseType: selectedCase?.caseType.name,
    caseRef: selectedCase?.caseRef,
  });

  const isLoading = useIsLoading(fetchIntakeForms.typePrefix);

  useEffect(() => {
    if (latestTreatmentObjectiveWithQuestions) {
      const { chiefComplaint, toothCharts, templateSections } =
        latestTreatmentObjectiveWithQuestions.data;

      setFormattedIntakeSections([
        {
          answers: convertTemplateSectionToReadOnlyIntake(
            templateSections as TreatmentObjectiveTemplateSection[],
            chiefComplaint,
            toothCharts
          ),
          label: 'Treatment Objectives',
        },
      ]);

      const intakeSubmissionTime = moment(
        latestTreatmentObjectiveWithQuestions.createdAt
      ).format(DATE_TIME_FORMAT);
      const submissionSubtext = `Submitted by ${latestTreatmentObjectiveWithQuestions.createdByEmail}, at ${intakeSubmissionTime}`;

      setIntakeInfo(['Treatment Objectives', submissionSubtext]);
    } else if (intakeForm) {
      const intakeName = determineIntakeName(selectedCase?.isGen2);
      setFormattedIntakeSections(
        formatIntakeSections(intakeForm.data.sections)
      );
      const intakeSubmissionTime = moment(intakeForm?.signedDate!).format(
        DATE_TIME_FORMAT
      );
      const submissionSubtext = `Submitted by ${intakeForm?.createdByEmail}, at ${intakeSubmissionTime}`;
      setIntakeInfo([intakeName, submissionSubtext]);
    }
  }, [latestTreatmentObjectiveWithQuestions, intakeForm]);

  if (!intakeForm && !latestTreatmentObjectiveWithQuestions) {
    return null;
  }

  if (isLoading || !selectedCase) {
    return <Loading />;
  }

  if (
    !formattedIntakeSections ||
    selectedCase?.caseState?.internal ===
      PROVIDER_FACING_STATUSES.INCOMPLETE_SUBMISSION
  ) {
    const alertHeaderText = selectedCase?.isGen2
      ? `${intakeName} not yet submitted`
      : '';
    const alertBodyText = selectedCase?.isGen2
      ? 'Continue submission'
      : `${intakeName} not yet submitted`;
    return (
      <AlertCard type="warning" header={alertHeaderText} displayIcon={true}>
        {selectedCase?.isGen2 ? (
          <LinkItem
            onClick={() =>
              push(`/patient/${selectedCase?.patientId}/case-creator`)
            }
          >
            Continue submission
          </LinkItem>
        ) : (
          <>{alertBodyText}</>
        )}
      </AlertCard>
    );
  }

  return (
    <S.Wrapper>
      {!selectedCase?.isGen2 && <VersionPills {...vpProps} />}
      <S.HeaderContainer>
        <div>
          <S.DetailHeader>{intakeName}</S.DetailHeader>
          <S.SubmissionInformation>{submissionSubtext}</S.SubmissionInformation>
        </div>
      </S.HeaderContainer>

      {latestTreatmentObjectiveWithQuestions && originatedTemplate ? (
        <Formik
          initialValues={initialValues}
          onSubmit={() => {}}
          enableReinitialize
        >
          <TreatmentObjectives
            systemTemplates={
              [originatedTemplate || systemTemplates] as Template[]
            }
            showDescription={false}
            canEditSubmission={false}
            dentalNotation={
              doctorPreferences?.dentalNotation as DentalNotationOptions
            }
            clinicalPreferencesTemplate={
              clinicalPreferencesTemplate as Template
            }
            patientBrand={patient?.user?.brandInfo?.name as SupportedBrand}
          />
        </Formik>
      ) : (
        formattedIntakeSections.map((section, index) => {
          return (
            <SurveySection
              section={section}
              displayDivider={index < formattedIntakeSections.length - 1}
              key={index}
              dentalNotation={
                doctorPreferences?.dentalNotation as DentalNotationOptions
              }
            />
          );
        })
      )}
    </S.Wrapper>
  );
};

export default Intake;
