import { PartnerOrgType } from 'generated/legacy/graphql';
import { useAuthContext } from 'context/AuthContext';

export function usePartnerOrgs(): {
  partnerOrgs: Pick<PartnerOrgType, 'name' | 'logoUrl'>[];
} {
  const { userInfo } = useAuthContext();

  const partnerOrgs =
    userInfo?.doctor?.practices
      ?.map((practice) => practice?.partnerOrgAccount?.partnerOrg)
      .filter((partnerOrg): partnerOrg is PartnerOrgType => !!partnerOrg) || [];

  return {
    partnerOrgs,
  };
}
