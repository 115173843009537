import { changePassword } from 'api/login';
import { useMemo } from 'react';
import { Button } from 'core/components';
import DocumentTitle from 'components/DocumentTitle';
import { FormikInputWithIcon } from 'components/FormikForms';
import { passwordValidator } from 'components/FormikForms/utils';
import { Formik } from 'formik';
import {
  SplitContainer,
  LoginContainer,
  LoginForm,
  InputFields,
  Logo,
} from 'pages/Login/Login.css';
import React from 'react';
import { getBrandSettings, getBrandFromDomain } from 'utils/brands';
import withNotifications from 'components/withNotifications';
import EyeToggleButton from 'components/EyeToggleButton';

type FormProps = {
  new_password: string;
  confirm_new_password: string;
};

type PasswordResetConfirmationProps = {
  showNotification: (message: string, variant: string) => void;
};

const extractToken = (url: string, showCreatePasswordFlow: boolean) => {
  // Url will be in form of /account/reset/confirm/ENCODED_USER_ID/TOKEN/?initial, the initial param is optional
  const VALID_URL_LENGTH = 5;
  const urlParts = url.split('/');

  if (urlParts.length < VALID_URL_LENGTH) {
    return {
      uid: '',
      token: '',
    };
  }

  const uid = showCreatePasswordFlow
    ? urlParts[urlParts.length - 3]
    : urlParts[urlParts.length - 2];
  const token = showCreatePasswordFlow
    ? urlParts[urlParts.length - 2]
    : urlParts[urlParts.length - 1];

  return {
    uid,
    token,
  };
};

const PasswordResetConfirmation = ({
  showNotification,
}: PasswordResetConfirmationProps) => {
  /*
  This component provides the UX for setting and 'creating' a password.
  This is because when we create an account, we randomly generate a password and then
  prompt the user to create their password, which in actuality is resetting the password.
  */
  const [hidePassword, setHidePassword] = React.useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = React.useState(true);

  const initialValues: FormProps = {
    new_password: '',
    confirm_new_password: '',
  };

  const { label, logoSrc } = getBrandSettings(getBrandFromDomain());

  const showCreatePasswordFlow = useMemo(() => {
    return window.location.search.includes('?initial');
  }, []);

  const handleSubmit = async ({
    new_password,
    confirm_new_password,
  }: FormProps) => {
    if (new_password !== confirm_new_password) {
      showNotification('Passwords do not match', 'error');
      return;
    }

    const { uid, token } = extractToken(
      window.location.pathname,
      showCreatePasswordFlow
    );

    if (!uid || !token) {
      showNotification('Invalid reset link', 'error');
      return;
    }

    try {
      await changePassword(new_password, token, uid);
    } catch (err: unknown) {
      if (err instanceof Error) {
        showNotification(err.message || 'Failed to change password', 'error');
      } else {
        showNotification('Failed to change password', 'error');
      }
      return;
    }
    const notificationText = showCreatePasswordFlow
      ? 'Password created successfully'
      : 'Password changed successfully';
    showNotification(notificationText, 'success');
    window.location.href = '/login';
  };
  return (
    <DocumentTitle title="Password reset">
      <SplitContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <LoginContainer>
              <LoginForm>
                {logoSrc && (
                  <Logo alt={label as string} src={logoSrc as string} />
                )}
                <InputFields>
                  <FormikInputWithIcon
                    testId="new-password-input"
                    autoComplete="username"
                    name="new_password"
                    type={hidePassword ? 'password' : 'text'}
                    placeholder={
                      showCreatePasswordFlow ? 'Password' : 'New password'
                    }
                    validate={passwordValidator}
                    customTrailingIcon={
                      <EyeToggleButton
                        hide={hidePassword}
                        setHide={setHidePassword}
                      />
                    }
                  />
                </InputFields>
                <InputFields>
                  <FormikInputWithIcon
                    testId="confirm-new-password-input"
                    autoComplete="username"
                    name="confirm_new_password"
                    type={hideConfirmPassword ? 'password' : 'text'}
                    placeholder="Confirm password"
                    validate={passwordValidator}
                    customTrailingIcon={
                      <EyeToggleButton
                        hide={hideConfirmPassword}
                        setHide={setHideConfirmPassword}
                      />
                    }
                  />
                </InputFields>
                <Button
                  testId="login-btn"
                  buttonType="secondary"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type="submit"
                >
                  {showCreatePasswordFlow
                    ? 'Create password'
                    : 'Reset password'}
                </Button>
              </LoginForm>
            </LoginContainer>
          )}
        </Formik>
      </SplitContainer>
    </DocumentTitle>
  );
};

export default withNotifications(PasswordResetConfirmation);
