import React, { useCallback, useState } from 'react';
import { Column } from 'react-virtualized';
import moment from 'moment';
import { Skeleton } from 'core/components';
import { VirtualizedTable } from 'components/VirtualizedTable';
import {
  GetAllOrdersQueryVariables,
  GetAllOrdersQuery,
  OrderType,
  OrderStatus,
  AddressInput,
} from 'generated/core/graphql';
import { OrderListType } from 'pages/OrdersAdmin/OrdersAdmin';
import {
  TableContainer,
  ErrorMessage,
  Action,
} from 'pages/OrdersAdmin/OrderTable.css';
import { Dropdown } from 'components/Dropdown/Dropdown';
import api from 'state/api';
import { getAddressInputFromAddressForm } from 'components/AddressForm/utils';
import { SelectedAddress } from 'components/AddressForm/types';
import UpdateOrderModal from 'pages/OrdersAdmin/UpdateOrderModal';
import CancelOrderModal from 'pages/OrdersAdmin/CancelOrderModal';
import ViewOrderItemsModal from 'pages/OrdersAdmin/ViewOrderItemsModal';
import { useNotificationContext } from 'core/context/NotificationContext';

const HEADER_HEIGHT = 50;
const ROW_HEIGHT = 50;
const ADDRESS_UPDATABLE_STATUSES = [OrderStatus.Draft, OrderStatus.Processing];
const CANCELABLE_STATUSES = [
  OrderStatus.Draft,
  OrderStatus.Processing,
  OrderStatus.Fulfilled,
];

const { useCancelOrderMutation, useUpdateOrderMutation } = api;

const OrderTable = ({
  isLoading,
  orders,
  setOrders,
  nextPageArgs,
  practiceIdToNameMap,
  getOrdersError,
  currentOrderData,
  getOrders,
}: {
  isLoading: boolean;
  orders: OrderListType;
  setOrders: (orders: OrderListType) => void;
  nextPageArgs: GetAllOrdersQueryVariables;
  practiceIdToNameMap: { [key: number]: string };
  getOrdersError: boolean;
  currentOrderData: GetAllOrdersQuery['orders'] | undefined;
  getOrders: (args: GetAllOrdersQueryVariables) => void;
}) => {
  const loadingOrdersPlaceholder = Array.from({ length: 30 }).map(() => ({
    shortOrderRef: null,
    patientId: null,
    practiceName: null,
    status: null,
    createdAt: null,
  }));

  const { showNotification } = useNotificationContext();
  const [updateOrder] = useUpdateOrderMutation();
  const [cancelOrder] = useCancelOrderMutation();
  const [editingOrder, setEditingOrder] = useState<OrderType | null>(null);
  const [resultAddress, setResultAddress] = useState<
    AddressInput | null | undefined
  >(null);
  const [addressFormIsValid, setAddressFormIsValid] = useState<boolean>(true);
  const [addressFormSubmitBtnRef] = useState<
    React.RefObject<HTMLButtonElement>
  >(React.createRef());
  const [cancellingOrder, setCancellingOrder] = useState<OrderType | null>(
    null
  );
  const [pendingOrderCancellation, setPendingOrderCancellation] =
    useState<boolean>(false);
  const [justModifiedOrderRef, setJustModifiedOrderRef] = useState<
    string | null
  >(null);
  const [viewingOrder, setViewingOrder] = useState<OrderType | null>(null);

  const updateOrderAddress = useCallback(
    async (addressInput: AddressInput) => {
      if (editingOrder) {
        const orderInput = {
          orderId: editingOrder.id,
          address: addressInput,
        };
        try {
          const updatedOrder = await updateOrder(orderInput).unwrap();
          if (!updatedOrder?.order) {
            showNotification('Error updating order address: no order returned');
            return;
          }
          setOrders(
            orders.map((order) =>
              order.id === updatedOrder?.order?.id
                ? { ...order, shippingAddress: addressInput }
                : order
            )
          );
          showNotification('Order updated successfully', 'success');
          setJustModifiedOrderRef(editingOrder.shortOrderRef);
          setTimeout(() => setJustModifiedOrderRef(null), 5000);
          setEditingOrder(null);
        } catch (e: unknown) {
          if (e instanceof Object && 'message' in e) {
            showNotification(
              `Error updating order address: ${e.message as string}`,
              'error'
            );
          } else {
            showNotification('Error updating order address', 'error');
          }
        }
      }
    },
    [editingOrder, orders, setOrders, showNotification, updateOrder]
  );

  const handleConfirmedAddress = useCallback(
    (confirmedAddress: SelectedAddress) => {
      if (editingOrder) {
        const addressInput = getAddressInputFromAddressForm(confirmedAddress);
        if (!addressInput) {
          showNotification(
            'Error updating order: unable to get address input from form',
            'error'
          );
          return;
        }
        updateOrderAddress(addressInput);
      }
    },
    [editingOrder, updateOrderAddress, showNotification]
  );

  const confirmCancelOrder = useCallback(async () => {
    try {
      setPendingOrderCancellation(true);
      if (cancellingOrder) {
        const cancelledOrder = await cancelOrder({
          orderId: cancellingOrder.id,
        }).unwrap();
        if (!cancelledOrder?.order) {
          showNotification(
            'Error cancelling order: no order returned',
            'error'
          );
        } else {
          setOrders(
            orders.map((order) =>
              order.id === cancelledOrder?.order?.id
                ? { ...order, status: cancelledOrder.order.status }
                : order
            )
          );
          showNotification('Order cancelled successfully', 'success');
          setJustModifiedOrderRef(cancellingOrder.shortOrderRef);
          setTimeout(() => setJustModifiedOrderRef(null), 5000);
        }
      }
    } catch (e: unknown) {
      if (e instanceof Object && 'message' in e) {
        showNotification(
          `Error cancelling order: ${e.message as string}`,
          'error'
        );
      } else {
        showNotification('Error cancelling order', 'error');
      }
    }
    setCancellingOrder(null);
    setPendingOrderCancellation(false);
  }, [cancellingOrder, cancelOrder, orders, setOrders, showNotification]);

  return (
    <TableContainer>
      <VirtualizedTable
        rowCount={isLoading ? loadingOrdersPlaceholder.length : orders.length}
        rows={isLoading ? loadingOrdersPlaceholder : orders}
        headerHeight={HEADER_HEIGHT}
        rowHeight={ROW_HEIGHT}
        loadMoreRows={() => {
          if (!isLoading && currentOrderData?.pageInfo.hasNextPage) {
            getOrders(nextPageArgs);
          }
        }}
        loading={isLoading}
        rowClassName={({ index }: { index: number }) => {
          return orders[index]?.shortOrderRef === justModifiedOrderRef
            ? 'just-modified'
            : '';
        }}
      >
        <Column
          label="Short Order ref"
          dataKey="shortOrderRef"
          flexGrow={0.1}
          flexShrink={1}
          width={1}
          cellRenderer={({ cellData }) =>
            cellData || <Skeleton width={'50%'} />
          }
        />
        <Column
          label="Patient ID"
          dataKey="patientId"
          flexGrow={0.1}
          flexShrink={1}
          width={1}
          cellRenderer={({ cellData, rowData }) =>
            rowData.shortOrderRef ? cellData : <Skeleton width={'50%'} />
          }
        />
        <Column
          label="Practice"
          dataKey="practiceName"
          flexGrow={0.25}
          flexShrink={1}
          width={1}
          cellRenderer={({ rowData }) =>
            practiceIdToNameMap[rowData.practiceId] || (
              <Skeleton width={'50%'} />
            )
          }
        />
        <Column
          label="Status"
          dataKey="status"
          flexGrow={0.1}
          flexShrink={1}
          width={1}
          cellRenderer={({ cellData }) =>
            cellData || <Skeleton width={'50%'} />
          }
        />
        <Column
          label="Created at"
          dataKey="createdAt"
          flexGrow={0.15}
          flexShrink={1}
          width={1}
          cellRenderer={({ cellData }) =>
            cellData ? (
              moment(cellData).format('MM/DD/YYYY, h:mm a')
            ) : (
              <Skeleton width={'50%'} />
            )
          }
        />
        <Column
          label=""
          dataKey="actions"
          flexGrow={0.2}
          flexShrink={1}
          width={1}
          cellRenderer={({ rowData }) => (
            <Dropdown showCaret title={<Action>Quick actions</Action>}>
              <Action
                onClick={() => {
                  setViewingOrder(rowData);
                }}
              >
                View order
              </Action>
              <Action
                $disabled={!ADDRESS_UPDATABLE_STATUSES.includes(rowData.status)}
                onClick={(e) => {
                  if (!ADDRESS_UPDATABLE_STATUSES.includes(rowData.status)) {
                    e.stopPropagation();
                    return;
                  }
                  setEditingOrder(rowData);
                }}
              >
                Update address
              </Action>
              <Action
                $warning
                $disabled={!CANCELABLE_STATUSES.includes(rowData.status)}
                onClick={(e) => {
                  if (!CANCELABLE_STATUSES.includes(rowData.status)) {
                    e.stopPropagation();
                    return;
                  }
                  setCancellingOrder(rowData);
                }}
              >
                Cancel order
              </Action>
            </Dropdown>
          )}
          className="actions-cell"
        />
      </VirtualizedTable>
      {getOrdersError && (
        <>
          <ErrorMessage>
            We encountered an error while fetching the orders.
          </ErrorMessage>
        </>
      )}
      {cancellingOrder && (
        <CancelOrderModal
          cancellingOrder={cancellingOrder}
          setCancellingOrder={setCancellingOrder}
          pendingOrderCancellation={pendingOrderCancellation}
          confirmCancelOrder={confirmCancelOrder}
        />
      )}
      {editingOrder && (
        <UpdateOrderModal
          editingOrder={editingOrder}
          setEditingOrder={setEditingOrder}
          setJustModifiedOrderRef={setJustModifiedOrderRef}
          resultAddress={resultAddress}
          setResultAddress={setResultAddress}
          addressFormSubmitBtnRef={addressFormSubmitBtnRef}
          handleConfirmedAddress={handleConfirmedAddress}
          setAddressFormIsValid={setAddressFormIsValid}
          addressFormIsValid={addressFormIsValid}
          updateOrderAddress={updateOrderAddress}
        />
      )}
      {viewingOrder && (
        <ViewOrderItemsModal
          viewingOrder={viewingOrder}
          setViewingOrder={setViewingOrder}
        />
      )}
    </TableContainer>
  );
};

export default OrderTable;
