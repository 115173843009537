import React, { useEffect, useState } from 'react';
import KeyValueDisplay from 'components/KeyValueDisplay';
import { Divider, Grid, Loading, Modal } from 'core/components';
import {
  ModalBody,
  ModalTitle,
  StyledOverline,
  OrderItemContainer,
  SkuQuantityContainer,
  StyledDivider,
  ColumnContainer,
  InnerContainer,
} from 'pages/OrdersAdmin/Modals.css';
import api from 'state/api';

import {
  GetOrderItemsCoreQuery,
  Shipment,
  OrderType,
} from 'generated/core/graphql';
import TrackingInfo from 'pages/OrdersAdmin/TrackingInfo';
import OrderStatus from 'pages/OrdersAdmin/OrderStatus';

const { useLazyGetOrderItemsCoreQuery } = api;

type ViewOrderItemsModalProps = {
  viewingOrder: OrderType;
  setViewingOrder: (order: OrderType | null) => void;
};

type OrderItemType = GetOrderItemsCoreQuery['orderItems']['edges'][0]['node'];

const ViewOrderItemsModal = ({
  viewingOrder,
  setViewingOrder,
}: ViewOrderItemsModalProps) => {
  const [getOrderItems, { isFetching }] = useLazyGetOrderItemsCoreQuery();
  const [orderItems, setOrderItems] = useState<OrderItemType[] | null>(null);

  useEffect(() => {
    if (viewingOrder) {
      getOrderItems({
        orderItemIds: viewingOrder.orderItems.map((oi) => oi.id),
      })
        .unwrap()
        .then((data) => {
          setOrderItems(data['edges'].map((edge) => edge['node']));
        });
    } else {
      setOrderItems(null);
    }
  }, [viewingOrder, getOrderItems]);

  return (
    <Modal isOpen={!!viewingOrder} onClose={() => setViewingOrder(null)}>
      <ModalTitle>
        <h3>View order</h3>
      </ModalTitle>
      <Divider />
      <ModalBody>
        <Grid container xs={12} spacing={4}>
          <Grid item xs={6}>
            <ColumnContainer>
              <KeyValueDisplay label={'Order ref'} value={viewingOrder?.id} />
              {viewingOrder?.caseRef && (
                <KeyValueDisplay
                  label={'Case ref'}
                  value={viewingOrder?.caseRef}
                />
              )}
              <OrderStatus order={viewingOrder} />
              <KeyValueDisplay
                label={'Shipping address'}
                value={
                  viewingOrder?.shippingAddress ? (
                    <h5>
                      {viewingOrder.shippingAddress.name}
                      {viewingOrder.shippingAddress.name &&
                        viewingOrder.shippingAddress.company && <br />}
                      {viewingOrder.shippingAddress.company}
                      <br />
                      {viewingOrder.shippingAddress.addressLines.join(', ')}
                      <br />
                      {viewingOrder.shippingAddress.city},{' '}
                      {viewingOrder.shippingAddress.adminRegion}{' '}
                      {viewingOrder.shippingAddress.postalCode}
                    </h5>
                  ) : (
                    '—'
                  )
                }
              />
            </ColumnContainer>
          </Grid>
          <Grid item xs={6} alignItems="baseline">
            <ColumnContainer>
              <StyledOverline>
                Order items ({orderItems?.length ?? (isFetching ? '...' : 0)})
              </StyledOverline>
              {isFetching && (
                <Grid item xs={12}>
                  <InnerContainer>
                    <Loading />
                  </InnerContainer>
                </Grid>
              )}
              {!isFetching && orderItems?.length ? (
                orderItems.map((orderItem, index) => {
                  const shipments = orderItem?.relatedShipment?.shipments || [];

                  return (
                    <OrderItemContainer key={index}>
                      <KeyValueDisplay
                        label={'Order item id'}
                        value={orderItem?.id}
                      />
                      <SkuQuantityContainer>
                        <div>
                          <KeyValueDisplay
                            label={'Sku'}
                            value={<h4>{orderItem?.productVariant.sku}</h4>}
                          />
                        </div>
                        <div>
                          <KeyValueDisplay
                            label={'Quantity'}
                            value={orderItem?.quantity}
                          />
                        </div>
                      </SkuQuantityContainer>
                      <StyledDivider />
                      <KeyValueDisplay
                        label={'Shipping address'}
                        value={
                          orderItem?.order.shippingAddress ? (
                            <h5>
                              {orderItem.order.shippingAddress.name}
                              {orderItem.order.shippingAddress.name &&
                                orderItem.order.shippingAddress.company && (
                                  <br />
                                )}
                              {orderItem.order.shippingAddress.company}
                              <br />
                              {orderItem.order.shippingAddress.addressLines.join(
                                ', '
                              )}
                              <br />
                              {orderItem.order.shippingAddress.city},{' '}
                              {orderItem.order.shippingAddress.adminRegion}{' '}
                              {orderItem.order.shippingAddress.postalCode}
                            </h5>
                          ) : (
                            '—'
                          )
                        }
                      />
                      {shipments.map((shipment) => (
                        <TrackingInfo
                          shipment={shipment as Shipment}
                          key={shipment.id}
                        />
                      ))}
                    </OrderItemContainer>
                  );
                })
              ) : (
                <></>
              )}
              {!isFetching && !orderItems?.length && (
                <InnerContainer>No order items found</InnerContainer>
              )}
            </ColumnContainer>
          </Grid>
        </Grid>
      </ModalBody>
    </Modal>
  );
};

export default ViewOrderItemsModal;
