import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components/macro';
import { ApolloError } from '@apollo/client';
import { type } from 'core/components';

import { useTransitionJourneyMutation } from 'generated/legacy/graphql';
import { Dropdown } from 'components/Dropdown/Dropdown';
import ConfirmDialog from 'components/ConfirmDialog';
import { ReactSelectOption } from 'components/FormikForms';
import withNotifications from 'components/withNotifications';
import { JourneyTransition } from 'types/journey';
import {
  Label,
  StyledReactSelect,
  CustomerReactSelectStyles,
} from 'styles/inputs.css';
import { apolloErrorToString } from 'utils/apollo';
import { useIsOnlyCandidPro } from 'hooks/useIsOnlyCandidPro';
import { AUTH_GROUPS } from 'types';
import { useGQLMutation } from 'hooks/useGQL';
import {
  MaterialEvaluationTypes,
  RemoveMaterialEvaluationMutation,
  RemoveMaterialEvaluationDocument,
  RemoveMaterialEvaluationMutationVariables,
  TreatmentPlanStagingStates,
} from 'generated/core/graphql';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTreatmentPlanStagings,
  selectSortedTreatmentPlanStagings,
} from 'pages/OrthoPrism/orthoSlice';
import { Sort, sortByCreated } from 'utils/prism';
import * as Sentry from '@sentry/browser';
import api, { ExtractReturnType } from 'state/api';
import useReinitiateTreatmentPlanning from 'hooks/useReinitiateTreatmentPlanning';
import { ALIGNER_JOURNEY_TYPE } from 'utils/journey';
import { useAuthContext } from 'context/AuthContext';

const Body = styled.div<{
  rightMargin?: boolean;
}>`
  text-align: right;
  margin-right: ${({ rightMargin }) => rightMargin && '1.5rem'};
`;

const CloseCaseWrap = styled.div`
  min-height: 15rem;
`;

const CaseLink = styled(type.Link)<{
  action?: CaseAction;
  disabled?: boolean;
}>`
  font-weight: 300;
  color: ${({ disabled }) => (disabled ? 'gray' : '')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  &:hover {
    cursor: pointer;
  }
`;

const CloseCaseReasonDict = {
  PATIENT_REQUESTED_CANCELLATION: 'Patient requested cancellation',
  DUPLICATE_CASE: 'Duplicate case',
  UNRECOVERABLE_MATERIALS: 'Unrecoverable materials',
  PATIENT_RECEIVING_DENTAL_WORK: 'Patient is receiving dental work',
  IN_TREATMENT_ISSUE: 'In treatment Issue',
  OTHER: 'Other',
} as const;

type GetCaseQuery = ExtractReturnType<typeof api.useLazyGetCasesQuery>[0];

type CloseCaseReason =
  (typeof CloseCaseReasonDict)[keyof typeof CloseCaseReasonDict];
type CloseCaseReasonOption = ReactSelectOption<CloseCaseReason>;

const customerDescriptions: Record<CaseAction, string> = {
  reopenCase: 'Reopen this case',
  closeCase: 'Close this case',
  setToCNT: 'Set status to "could not treat"',
  revertQC: 'Revert the Treatment Plan to QC Review',
  clearTreatmentPlanningDraftFiles: 'Delete Treatment Planning drafts',
  reinitiateTreatmentPlanning:
    'Switch Treatment Planning Software and Reinitiate Treatment Planning',
};

enum CaseAction {
  reopenCase = 'reopenCase',
  closeCase = 'closeCase',
  setToCNT = 'setToCNT',
  revertQC = 'revertQC',
  clearTreatmentPlanningDraftFiles = 'clearTreatmentPlanningDraftFiles',
  reinitiateTreatmentPlanning = 'reinitiateTreatmentPlanning',
}

const caseActionList = Object.keys(CaseAction) as CaseAction[];

type CaseActionWrapProps = {
  customerId: string;
  caseRef: string;
  showNotification: (message: string, variant: string) => void;
};

type CaseActionProps = Omit<CaseActionWrapProps, 'caseRef'> & {
  hiddenCaseActions?: CaseAction[];
  selectedCase: GetCaseQuery;
  fetchCases: () => void;
  closeCaseReasonOptions: CloseCaseReasonOption[];
};

const hideCaseActionsConditions: { (): boolean }[] = [
  (): boolean => useIsOnlyCandidPro(),
];

const CaseActionsWrap: FunctionComponent<CaseActionWrapProps> = ({
  customerId,
  caseRef,
  showNotification,
}) => {
  const [getCases, { data: cases }] = api.useLazyGetCasesQuery();

  const fetchCases = () => {
    if (customerId) {
      getCases({ patientIds: [Number(customerId)] });
    }
  };

  useEffect(() => {
    if (!cases) {
      fetchCases();
    }
  }, []);

  const activeCase = useMemo(() => {
    return cases ? cases.find((c) => c.caseRef === caseRef) : null;
  }, [cases]);
  const hiddenCaseActions = [];
  const hasActiveCase = cases?.some((caseItem) => caseItem.isActive);
  const casesLength = cases?.length || 0;
  const closeCaseReasonOptions: CloseCaseReasonOption[] = [
    {
      label: 'Patient requested cancellation',
      value: 'Patient requested cancellation',
    },
    {
      label: 'Duplicate case',
      value: 'Duplicate case',
    },
    {
      label: 'Unrecoverable materials',
      value: 'Unrecoverable materials',
    },
    {
      label: 'Patient is receiving dental work',
      value: 'Patient is receiving dental work',
    },
    {
      label: 'In Treatment Issue',
      value: 'In treatment Issue',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ];

  if (casesLength > 1 && hasActiveCase) {
    hiddenCaseActions.push(CaseAction.reopenCase);
  }

  if (!activeCase) {
    return (
      <div>
        <Body rightMargin>No case selected</Body>
      </div>
    );
  }

  return (
    <CaseActionsComponent
      {...{
        hiddenCaseActions,
        selectedCase: activeCase,
        customerId,
        fetchCases,
        closeCaseReasonOptions,
        showNotification,
      }}
    />
  );
};

const renderDialogBody = (
  action: CaseAction | null,
  setCloseCaseReason: (reason: CloseCaseReason) => void,
  closeCaseReason: string | null,
  closeCaseReasonOptions: CloseCaseReasonOption[]
) => {
  let copy: ReactNode;
  switch (action) {
    case CaseAction.closeCase: {
      copy = (
        <CloseCaseWrap>
          <Label htmlFor="close-case-reason">
            Reason for closing this case
          </Label>
          <StyledReactSelect
            id="close-case-reason"
            label="Reason"
            options={closeCaseReasonOptions}
            onChange={(option: CloseCaseReasonOption) =>
              setCloseCaseReason(option.value)
            }
            value={closeCaseReasonOptions.find(
              ({ value }) => value === closeCaseReason
            )}
            styles={CustomerReactSelectStyles}
          />
        </CloseCaseWrap>
      );
      break;
    }
    case CaseAction.setToCNT: {
      copy = 'Set the status to "Could not treat"?';
      break;
    }
    case CaseAction.reopenCase: {
      copy = (
        <>
          <type.H5>Are you sure you want to reopen this case?</type.H5>
          <div>This will take the case out of a cancelled state.</div>
        </>
      );
      break;
    }
    case CaseAction.revertQC: {
      copy = 'Revert treatment plan to QC Review?';
      break;
    }
    case CaseAction.clearTreatmentPlanningDraftFiles: {
      copy = (
        <type.H5>This will reset the in-progress TP, are you sure?</type.H5>
      );
      break;
    }
    case CaseAction.reinitiateTreatmentPlanning: {
      copy = (
        <type.H5>
          Switching TP software is a final action. All current progress on the
          treatment plan will be lost including all versions, reviews and notes.
          Please confirm to continue.
        </type.H5>
      );
      break;
    }
    default: {
      copy = '';
      break;
    }
  }

  return <div>{copy}</div>;
};

const CaseActionsComponent = ({
  hiddenCaseActions,
  selectedCase,
  showNotification,
  fetchCases,
  closeCaseReasonOptions,
}: CaseActionProps) => {
  const { checkHasAccess, userInfo } = useAuthContext();
  const [shouldOpenDialog, setShouldOpenDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState<CaseAction | null>(null);
  const [closeCaseReason, setCloseCaseReason] =
    useState<CloseCaseReason | null>(null);
  const sortedTreatmentPlans = useSelector(selectSortedTreatmentPlanStagings);
  const dispatch = useDispatch();
  const latestTreatmentPlan = sortedTreatmentPlans[0];
  const tpState = latestTreatmentPlan?.state;

  useEffect(() => {
    if (selectedCase.caseRef) {
      dispatch(fetchTreatmentPlanStagings({ caseRef: selectedCase.caseRef }));
    }
  }, [selectedCase.caseRef]);

  const onSuccessActions = (message: string) => {
    showNotification(message, 'success');
  };

  const showErrorNotification = (err: ApolloError) => {
    const apolloError = apolloErrorToString(err);
    const errorMessage = apolloError || 'There has been an error';
    showNotification(errorMessage, 'error');
  };

  const [transitionJourney] = useTransitionJourneyMutation({
    onError: showErrorNotification,
    onCompleted: () => {
      setCurrentAction(null);
      fetchCases();

      switch (currentAction) {
        case CaseAction.reopenCase:
          onSuccessActions(
            `Successfully reopened case ${selectedCase.caseRef}`
          );
          break;
        case CaseAction.setToCNT:
          onSuccessActions(
            `Set status to "could not treat" for case ${selectedCase.caseRef}`
          );
          break;
        case CaseAction.closeCase:
          if (closeCaseReason === CloseCaseReasonDict.IN_TREATMENT_ISSUE) {
            onSuccessActions(
              `Set status to "In treatment issue" for case ${selectedCase.caseRef}`
            );
          } else {
            onSuccessActions(
              `Successfully closed case ${selectedCase.caseRef}`
            );
          }
          setCloseCaseReason(null);
          break;
        default:
          break;
      }
    },
  });
  const [clearTreatmentPlanningDraftFiles] =
    api.useClearTreatmentPlanningDraftFilesMutation();
  const { executor, message, status } = useReinitiateTreatmentPlanning({
    caseRef: selectedCase.caseRef,
    currentSoftware: selectedCase?.caseSoftware,
    notes: `Switching TP software from CaseAction. UserID: ${userInfo?.id}`,
  });
  const [removeEvaluationSet] = useGQLMutation<
    RemoveMaterialEvaluationMutation,
    RemoveMaterialEvaluationMutationVariables
  >(RemoveMaterialEvaluationDocument);

  const handleClick = (action: CaseAction) => {
    setCurrentAction(action);
    switch (action) {
      default: {
        setShouldOpenDialog(true);
        break;
      }
    }
  };
  useEffect(() => {
    if (message && status) {
      showNotification(message, status);
      fetchCases();
    }
  }, [message, status]);

  const triggerReinitiateTreatmentPlanning = async () => {
    await executor();
    setCurrentAction(null);
  };

  const triggerClearTreatmentPlanningDraftFiles = async (caseRef: string) => {
    try {
      const rsp = await clearTreatmentPlanningDraftFiles({
        caseRef,
      }).unwrap();
      setCurrentAction(null);
      onSuccessActions(
        `Successfully moved the following files to a backup location on S3 ${rsp?.filesMoved?.join(
          ', '
        )}`
      );
    } catch (e) {
      if (e && typeof e === 'object' && 'message' in e) {
        showNotification(e?.message as string, 'error');
      }
    }
  };

  const handleConfirm = () => {
    const { caseRef } = selectedCase;
    const latestTPId = latestTreatmentPlan?.id || '';
    const journey = ALIGNER_JOURNEY_TYPE;

    switch (currentAction) {
      case CaseAction.reopenCase: {
        transitionJourney({
          variables: {
            caseRef,
            component: journey,
            transition: JourneyTransition.ForceCaseActive,
          },
        });

        break;
      }
      case CaseAction.closeCase: {
        if (closeCaseReason === CloseCaseReasonDict.IN_TREATMENT_ISSUE) {
          transitionJourney({
            variables: {
              caseRef,
              component: journey,
              transition: JourneyTransition.ForceInTreatmentIssue,
            },
          });
        } else {
          transitionJourney({
            variables: {
              caseRef,
              component: journey,
              transition: JourneyTransition.ForceCaseCanceled,
              transitionReason: closeCaseReason,
            },
          });
        }

        break;
      }
      case CaseAction.setToCNT: {
        transitionJourney({
          variables: {
            caseRef,
            component: journey,
            transition: JourneyTransition.ForceCouldNotTreat,
          },
        });
        break;
      }
      case CaseAction.revertQC: {
        if (latestTPId) {
          const minDate = '0001-01-01T00:00:00Z';
          const latestQCMaterialEvaluation =
            latestTreatmentPlan?.materialEvaluations
              ?.filter(
                (p) =>
                  p.evaluationType.name ===
                  MaterialEvaluationTypes.QualityControlEvaluation
              )
              ?.sort((a, b) =>
                sortByCreated(Sort.Desc)(
                  { created: a?.createdAt ?? minDate },
                  { created: b?.createdAt ?? minDate }
                )
              )[0];
          const evaluationSetId = latestQCMaterialEvaluation?.evaluationSet?.id;
          if (evaluationSetId) {
            removeEvaluationSet({
              evaluationSetId,
            })
              .then(() => {
                dispatch(
                  fetchTreatmentPlanStagings({ caseRef: selectedCase.caseRef })
                );
              })
              .catch((err) => {
                showErrorNotification(err);
                Sentry.captureException(err);
              });
          }
        }
        break;
      }
      case CaseAction.clearTreatmentPlanningDraftFiles: {
        if (caseRef) {
          triggerClearTreatmentPlanningDraftFiles(caseRef);
        }
        break;
      }
      case CaseAction.reinitiateTreatmentPlanning: {
        if (caseRef) {
          triggerReinitiateTreatmentPlanning();
        }
        break;
      }
      default: {
        break;
      }
    }

    setShouldOpenDialog(false);
  };

  const renderCaseActions = () => {
    return caseActionList
      .filter((caseAction) => {
        const isActive = selectedCase.isActive;

        if (hiddenCaseActions?.includes(caseAction)) {
          return false;
        }
        if (caseAction === CaseAction.revertQC) {
          if (
            !checkHasAccess([AUTH_GROUPS.TREATMENT_PLAN_QA]) ||
            (tpState !== TreatmentPlanStagingStates.OrthoReview &&
              tpState !== TreatmentPlanStagingStates.QcRejected)
          ) {
            return false;
          }
        }

        // Only show relevant actions depending on whether current case is active
        if (
          (isActive && caseAction === CaseAction.reopenCase) ||
          (!isActive &&
            [CaseAction.closeCase, CaseAction.setToCNT].includes(caseAction))
        ) {
          return false;
        }
        if (caseAction === CaseAction.clearTreatmentPlanningDraftFiles) {
          if (!checkHasAccess([AUTH_GROUPS.TPMX])) {
            return false;
          }
        }
        if (caseAction === CaseAction.reinitiateTreatmentPlanning) {
          if (!checkHasAccess([AUTH_GROUPS.TPMX])) {
            return false;
          }
        }
        return true;
      })
      .map((caseAction) => {
        return (
          <CaseLink
            action={caseAction}
            key={caseAction}
            onClick={() => handleClick(caseAction)}
          >
            {customerDescriptions[caseAction]}
          </CaseLink>
        );
      });
  };

  const caseActions = renderCaseActions();

  if (hideCaseActionsConditions.some((condition) => condition())) {
    return null;
  }

  if (!caseActions.length) {
    return null;
  }

  return (
    <div>
      <Body rightMargin>
        <Dropdown showCaret title={<CaseLink>Case actions</CaseLink>}>
          {caseActions}
        </Dropdown>
      </Body>
      <ConfirmDialog
        isOpen={shouldOpenDialog}
        onCancel={() => {
          setShouldOpenDialog(false);
          setTimeout(() => {
            setCurrentAction(null);
          }, 300);
        }}
        onConfirm={handleConfirm}
        isConfirmDisabled={
          currentAction === CaseAction.closeCase && !closeCaseReason
        }
        showCancelButton={!!currentAction}
        confirmButtonText="Confirm"
      >
        {renderDialogBody(
          currentAction,
          setCloseCaseReason,
          closeCaseReason,
          closeCaseReasonOptions
        )}
      </ConfirmDialog>
    </div>
  );
};

export default withNotifications(CaseActionsWrap);
