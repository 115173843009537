import React, { useMemo } from 'react';
import Dinero from 'dinero.js';
import { HRDividerLine } from 'styles/layout.css';
import {
  TotalContainer,
  ItemContainer,
  PriceInfoContainer,
  Total,
  LineItemContainer,
  BulletedLineItem,
  DiscountDescription,
  DiscountAmount,
  TotalPrice,
} from 'components/TotalBox/TotalBox.css';
import { Discount, Maybe } from 'generated/core/graphql';
import { findCachedLineItem } from 'pages/Patient/Checkout/utils';
import { PartialLineItem } from 'components/TotalBox/types';

import { CatalogItemType } from 'types/checkout';
import AndTariffsLink from 'components/TotalBox/AndTariffsLink';

const TotalSummary = ({
  totalQuantity,
  subtotal,
  displayTariffsHeadsup,
}: {
  totalQuantity: number;
  subtotal: number;
  displayTariffsHeadsup: boolean;
}) => (
  <PriceInfoContainer>
    <LineItemContainer>
      <Total>
        Total (sales tax <AndTariffsLink hidden={!displayTariffsHeadsup} /> not
        included)
      </Total>
    </LineItemContainer>
    <div>
      {totalQuantity ? (
        <TotalPrice>{Dinero({ amount: subtotal }).toFormat()}</TotalPrice>
      ) : (
        '—'
      )}
    </div>
  </PriceInfoContainer>
);

const LineItemDiscount = ({ discount }: { discount: Discount }) => (
  <PriceInfoContainer>
    <DiscountDescription>{discount.description}</DiscountDescription>
    <DiscountAmount>
      -{Dinero({ amount: discount.reductionInCents }).toFormat()}
    </DiscountAmount>
  </PriceInfoContainer>
);

const TotalsLineItem = ({
  item,
  lineItemDiscountsCache,
}: {
  item: CatalogItemType;
  lineItemDiscountsCache: PartialLineItem[];
}) => {
  const priceDisplay =
    item.quantity > 0
      ? item.totalPriceBeforeDiscounts.toFormat()
      : 'Select quantity above';
  const discountedQuantity = Dinero({
    amount:
      item.totalPriceBeforeDiscounts.getAmount() -
      item.totalPriceAfterDiscounts.getAmount(),
  });

  const lineItemWithDiscount = findCachedLineItem(
    item.sku,
    item.quantity,
    lineItemDiscountsCache
  );

  return (
    <>
      <PriceInfoContainer>
        <LineItemContainer>
          <ItemContainer>{item.providerFacingProductName}</ItemContainer>
          <ItemContainer>
            <BulletedLineItem>{priceDisplay}</BulletedLineItem>
          </ItemContainer>
        </LineItemContainer>
        <div>{item.quantity ? priceDisplay : '—'}</div>
      </PriceInfoContainer>
      {discountedQuantity.getAmount() > 0 && (
        <>
          {lineItemWithDiscount?.discounts?.map(
            (discount: Maybe<Discount>, index: number) =>
              discount && <LineItemDiscount discount={discount} key={index} />
          )}
        </>
      )}
    </>
  );
};

const TotalBox = ({
  orderItems,
  lineItemDiscountsCache,
  displayTariffsHeadsup,
}: {
  orderItems: CatalogItemType[];
  lineItemDiscountsCache: PartialLineItem[];
  displayTariffsHeadsup: boolean;
}) => {
  // subtotal and totalQuantity update when the quantity updates on the order item
  const subtotal = useMemo(() => {
    return orderItems.reduce(
      (total, item) => total + item.totalPriceAfterDiscounts.getAmount(),
      0
    );
  }, [orderItems]);

  const totalQuantity = useMemo(() => {
    return orderItems.reduce((acc, item) => acc + item.quantity, 0);
  }, [orderItems]);

  return (
    <TotalContainer>
      {orderItems.map((item: CatalogItemType, index) => (
        <TotalsLineItem
          key={index}
          item={item}
          lineItemDiscountsCache={lineItemDiscountsCache}
        />
      ))}
      <HRDividerLine spacing="0.5rem" mobileSpacing="0.25rem" />
      <TotalSummary
        totalQuantity={totalQuantity}
        subtotal={subtotal}
        displayTariffsHeadsup={displayTariffsHeadsup}
      />
    </TotalContainer>
  );
};

export default TotalBox;
