import React, { FormEvent, useMemo, useState } from 'react';
import { MUIDataTableMeta } from 'mui-datatables';

import {
  fetchContractRates,
  fetchUserEmails,
  getContractRates,
  getSelectedPractice,
  getSelectedPracticeLoyaltyStatus,
  getUserEmails,
} from 'pages/PracticeManagement/shared/slice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from 'core/components';
import { useParams } from 'react-router-dom';
import { PracticeManagerParams } from 'pages/PracticeManagement/PracticeManager';
import { useMediaQuery } from '@material-ui/core';
import { ACCESS_GROUPS } from 'types';
import {
  AddContractRatesDocument,
  AddContractRatesMutation,
  AddContractRatesMutationVariables,
  ContractRateType,
  GetProductVariantsQuery,
  OrdersProductVariantBrandChoices,
  OrdersProductVariantFulfillmentChoices,
} from 'generated/core/graphql';
import { useIsLoading } from 'state/system';
import {
  dollarFormat,
  fixedWidthProps,
  flexibleWidthProps,
  renderDateTime,
} from 'pages/PracticeManagement/PricingTable/utils';
import {
  Flex,
  StyledButton,
  StyledDivider,
  RateInput,
  RateWrapper,
  SearchIcon,
  SearchInput,
  SearchWrapper,
  StyledTable,
} from 'pages/PracticeManagement/PricingTable/PricingTable.css';
import { useGQLMutation } from 'hooks/useGQL';
import { useAuthContext } from 'context/AuthContext';
import { useNotificationContext } from 'core/context/NotificationContext';

type ProductVariant = GetProductVariantsQuery['productVariants'][0];

type ProductWithContractRate = {
  product: ProductVariant;
  contractRate: ContractRateType | null;
  currentValue: number | null;
  touched: boolean;
};

const getBrand = (practiceBrand: string): OrdersProductVariantBrandChoices => {
  switch (practiceBrand) {
    case 'candid_pro':
      return OrdersProductVariantBrandChoices.Candid;
    case 'glidewell':
      return OrdersProductVariantBrandChoices.ProMonitoring;
    case 'oliv':
      return OrdersProductVariantBrandChoices.Oliv;
    default:
      throw new Error(`Unsupported brand: ${practiceBrand}`);
  }
};

// TODO: This is a temporary solution to filter out products that are not relevant to the pricing table
// We will update once consolidation between goods and services is complete
const relevantFulfillment = (
  productFulfillment: OrdersProductVariantFulfillmentChoices
): boolean => {
  return (
    productFulfillment ==
      OrdersProductVariantFulfillmentChoices.Krakenrequireprocessing ||
    productFulfillment ==
      OrdersProductVariantFulfillmentChoices.Krakenselfservice ||
    productFulfillment == OrdersProductVariantFulfillmentChoices.Notapplicable
  );
};

export const PricingTable = () => {
  const dispatch = useDispatch();
  const { showNotification } = useNotificationContext();
  const { checkHasAccess, userInfo, products } = useAuthContext();
  const { practiceId } = useParams<PracticeManagerParams>();
  const contractRates = useSelector(getContractRates);
  const selectedPractice = useSelector(getSelectedPractice);
  const practiceBrand: OrdersProductVariantBrandChoices = selectedPractice
    ?.brand?.name
    ? getBrand(selectedPractice.brand.name)
    : OrdersProductVariantBrandChoices.Generic;
  const selectedPracticeLoyaltyStatus = useSelector(
    getSelectedPracticeLoyaltyStatus
  );

  const [contractRatesHaveDisplayed, setContractRatesHaveDisplayed] =
    // State to track if contract rates have been displayed at least once;
    // This is used to prevent the contract rates from disappearing behind
    // a loading spinner while saving changes
    useState(false);

  const [productsWithRates, setProductsWithRates] = useState<
    ProductWithContractRate[]
  >([]);
  const [search, setSearch] = useState('');
  const userEmails = useSelector(getUserEmails);
  const [addContractRates] = useGQLMutation<
    AddContractRatesMutation,
    AddContractRatesMutationVariables
  >(AddContractRatesDocument);

  const relevantProducts = useMemo(
    () =>
      products?.filter((product) => {
        if (!product) {
          return false;
        }
        return (
          (product.brand === OrdersProductVariantBrandChoices.Generic ||
            product.brand === practiceBrand) &&
          relevantFulfillment(product.fulfillment)
        );
      }) ?? [],
    [products, practiceBrand]
  );

  useEffect(() => {
    if (!practiceId) {
      return;
    }

    dispatch(fetchContractRates({ practiceId }));
  }, [dispatch, practiceId]);

  useEffect(() => {
    if (contractRates) {
      getCreatedByEmails(contractRates);
      const relevantProductsWithRates: ProductWithContractRate[] =
        relevantProducts.map((product) => {
          const contractRate =
            contractRates.find((rate) => rate.sku === product.sku) || null;
          return {
            product,
            contractRate: contractRate,
            currentValue: contractRate ? Number(contractRate?.rateInCents) : 0,
            touched: false,
          };
        });
      setProductsWithRates(relevantProductsWithRates);
    }
  }, [contractRates]);

  const getCreatedByEmails = (rates: ContractRateType[]) => {
    const userIds = rates.map((rate) => rate.createdBy);
    userIds.forEach(
      (id) => id && dispatch(fetchUserEmails({ id: id.toString() }))
    );
  };

  const handleRateInputChange = (
    e: FormEvent<HTMLInputElement>,
    item: ProductWithContractRate
  ) => {
    const inputValue = e.currentTarget.value;
    const validInput =
      /^\d*\.{1}\d{1,3}$/.test(inputValue) || inputValue === '';

    // the following is true if the user is increasing the decimal places;
    // you can only have 2 decimal places, so we want to multiply the input by 10
    const increaseInDecimalPlaces = /^\d*\.?\d{3}$/.test(inputValue) ? 10 : 1;

    // the following is true if the user is decreasing the decimal places;
    // you can only have 2 decimal places, so we want to divide the input by 10
    const decreaseInDecimalPlaces = /^\d*\.?\d{1}$/.test(inputValue) ? 0.1 : 1;

    // if the input is valid, we want to multiply the input by 10 if the user is increasing the decimal places
    // and divide by 10 if the user is decreasing the decimal
    const decimalPlaceModifier =
      increaseInDecimalPlaces * decreaseInDecimalPlaces;

    if (validInput) {
      const newValue = Number(inputValue) * 100 * decimalPlaceModifier || 0;
      setProductsWithRates((prev) =>
        prev.map((p) =>
          p.product.sku === item.product.sku
            ? {
                ...p,
                currentValue:
                  Number(inputValue) * 100 * decimalPlaceModifier || 0,
                touched: newValue !== (p.contractRate?.rateInCents ?? null),
              }
            : p
        )
      );
    }
  };

  const handleSave = async () => {
    if (!productsWithRates) {
      return;
    }
    const changedRates = productsWithRates.filter((item) => item.touched);
    if (changedRates.length === 0) {
      return;
    }

    const accountId = selectedPractice?.account?.id;
    if (!accountId) {
      throw new Error("Can't save contract rates without knowing Account ID");
    }

    const contractRatesInput = changedRates.map((item) => ({
      sku: item.product.sku,
      rateInCents: item.currentValue || 0,
      accountId: Number(accountId),
      createdBy: Number(userInfo?.id),
    }));
    try {
      await addContractRates({ contractRates: contractRatesInput });
      showNotification('Contract rates saved successfully', 'success');
    } catch (error) {
      showNotification('Error saving contract rates', 'error');
    }
    dispatch(fetchContractRates({ practiceId }));
  };

  const areContractRatesLoading = useIsLoading(fetchContractRates.type);
  useEffect(() => {
    if (!areContractRatesLoading && contractRates) {
      setContractRatesHaveDisplayed(true);
    }
  }, [areContractRatesLoading, contractRates]);

  const isDesktop = useMediaQuery('(min-width: 960px)');
  const canEdit = checkHasAccess(ACCESS_GROUPS.AUTOMATED_BILLING_ADMIN);

  const hasUnsavedChanges = productsWithRates.some((item) => item.touched);

  const tableData = productsWithRates.filter((item) =>
    search
      ? item.product.sku.toLowerCase().includes(search.toLowerCase())
      : true
  );

  const priceAfterLoyalty = (item: ProductWithContractRate) => {
    const tierName =
      selectedPracticeLoyaltyStatus?.loyaltyProgram.currentTier.name;
    if (!tierName) {
      return '—';
    }

    const tier =
      selectedPracticeLoyaltyStatus?.loyaltyProgram.tiersInProgram.find(
        (tier) => tier.name === tierName
      );
    if (!tier) {
      return '—';
    }

    const productDiscount = tier.loyaltyTierProducts.find(
      (product) => product.sku === item.product.sku
    );
    if (!productDiscount) {
      return '—';
    }

    const originalPrice =
      item.contractRate?.rateInCents ?? item.product.defaultPriceInCents ?? 0;
    if (!originalPrice) {
      return '—';
    }

    return `$${dollarFormat(originalPrice - productDiscount.discount)}`;
  };

  if (!selectedPractice) {
    return <Loading />;
  }

  return (
    <>
      <Flex>
        <SearchWrapper>
          <SearchInput
            name="search"
            type="text"
            value={search}
            onChange={(e) => {
              setSearch(e.currentTarget.value);
            }}
            placeholder="Search"
          />
          <SearchIcon />
        </SearchWrapper>
        <StyledButton
          buttonType="secondary"
          isLoading={areContractRatesLoading}
          onClick={handleSave}
          disabled={!(canEdit && hasUnsavedChanges)}
        >
          Save changes
        </StyledButton>
      </Flex>
      <StyledDivider />
      {areContractRatesLoading && !contractRatesHaveDisplayed ? (
        // Don't show loading spinner if contract rates have already been displayed
        <Loading />
      ) : (
        <StyledTable
          data={tableData}
          columns={[
            {
              name: 'product',
              label: 'SKU',
              options: {
                setCellProps: () => flexibleWidthProps(isDesktop),
                setCellHeaderProps: () => flexibleWidthProps(isDesktop),
                customBodyRender: (product: ProductVariant) => {
                  return product.sku;
                },
              },
            },
            {
              name: 'product',
              label: 'Default Rate',
              options: {
                customBodyRender: (product: ProductVariant) => {
                  return product.defaultPriceInCents
                    ? `$${dollarFormat(product.defaultPriceInCents)}`
                    : '—';
                },
                setCellProps: () => fixedWidthProps(isDesktop),
              },
            },
            {
              name: 'currentValue',
              label: 'Contract Rate',
              options: {
                customBodyRender: (
                  currentValue: number,
                  tableMeta: MUIDataTableMeta
                ) => {
                  const item = tableData[tableMeta.rowIndex];

                  if (canEdit && productsWithRates) {
                    return (
                      <RateWrapper>
                        <p>$</p>
                        <RateInput
                          inputMode="numeric"
                          value={dollarFormat(currentValue)}
                          onSelect={(e) => {
                            const input = e.currentTarget;
                            setTimeout(() => {
                              input.setSelectionRange(12, 12);
                            }, 0);
                          }}
                          onChange={(e) => {
                            e.currentTarget.value.length < 11 &&
                              handleRateInputChange(e, item);
                          }}
                          $touched={item.touched}
                          disabled={areContractRatesLoading}
                        ></RateInput>
                      </RateWrapper>
                    );
                  } else {
                    return `$${dollarFormat(currentValue)}`;
                  }
                },
                setCellProps: () => fixedWidthProps(isDesktop),
              },
            },
            {
              name: 'product',
              label: 'Rate after Loyalty',
              options: {
                customBodyRender: (
                  _product: ProductVariant,
                  tableMeta: MUIDataTableMeta
                ) => {
                  const item = tableData[tableMeta.rowIndex];
                  if (!item.contractRate) {
                    return '—';
                  }

                  return priceAfterLoyalty(item);
                },
                setCellProps: () => fixedWidthProps(isDesktop),
              },
            },
            {
              // We update this field from the FE when the rate is modified;
              // it is not the original creator of the contract rate for this SKU
              name: 'contractRate',
              label: 'Last Modified By',
              options: {
                customBodyRender: (contractRate: ContractRateType | null) => {
                  if (!contractRate) {
                    return '-';
                  }

                  return contractRate.createdBy
                    ? userEmails[contractRate.createdBy]
                    : '—';
                },
                setCellProps: () => flexibleWidthProps(isDesktop),
                setCellHeaderProps: () => flexibleWidthProps(isDesktop),
              },
            },
            {
              // Existing backend logic updates this field when the rate is modified;
              // it is not the original creation date of the contract rate
              name: 'contractRate',
              label: 'Last Modified At',
              options: {
                customBodyRender: (contractRate: ContractRateType | null) => {
                  if (!contractRate) {
                    return '-';
                  }

                  return contractRate.createdAt
                    ? renderDateTime(contractRate.createdAt)
                    : '—';
                },
                setCellProps: () => flexibleWidthProps(isDesktop),
                setCellHeaderProps: () => flexibleWidthProps(isDesktop),
              },
            },
          ]}
          options={{
            selectableRows: 'none',
            search: false,
          }}
        />
      )}
    </>
  );
};
