import { ValuesOf } from 'utils/types';

import { PhotoType, SubmissionItemType } from 'generated/legacy/graphql';

export type RejectionReason = {
  name: string;
  label: string;
  reasons?: RejectionReason[];
};

export const SubmissionState = {
  Submitted: 'submitted',
  Approved: 'approved',
  RejectedPhotos: 'rejected_photos',
  RejectedCustomer: 'rejected_customer',
  RejectedMissingInfo: 'rejected_missing_information',
} as const;
export type SubmissionState = ValuesOf<typeof SubmissionState>;

export const SubmissionItemState = {
  Submitted: 'submitted',
  Approved: 'approved',
  Rejected: 'rejected',
} as const;
export type SubmissionItemState = ValuesOf<typeof SubmissionItemState>;

export type TabProps = {
  refreshMaterials?: (
    reloadScans: boolean,
    reloadXrays: boolean,
    reloadPhotos: boolean,
    caseRef: string
  ) => void;
};

export type PhotoData = {
  photo?: PhotoType;
  submissionItem?: SubmissionItemType;
  state: {
    isRejected: boolean;
    rejectionReasons?: RejectionReason[];
  };
};

export type PhotoMap = {
  [key: string]: PhotoData;
};

export type PhotoApprovalState = {
  quality: boolean;
  formatting: boolean;
  customer: boolean;
};

export enum PatientReasonsType {
  CouldNotTreat = 'could_not_treat',
  NeedsDentalWork = 'needs_dental_work',
  MissingInformation = 'missing_information',
  WhiteningOnly = 'approved_for_whitening_only',
}

export enum PhotoReasonsType {
  Quality = 'photo_quality',
  Formatting = 'photo_formatting',
}

type Doctor = {
  id?: string;
  fullName?: string;
  email?: string;
};

export type EditPatientInfo = {
  birthday: string;
  phone: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  businessName: string;
  city: string;
  stateCode: string;
  countryCode: string;
  zip: string;
  guardian_preferredName: string;
  guardian_firstName: string;
  guardian_middleName: string;
  guardian_lastName: string;
  guardian_phone: string;
  guardian_birthday: string;
  treatingProvider?: Doctor | null;
  candidClinician?: Doctor | null;
};
