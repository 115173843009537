export enum TOOH_CHART_ACTIONS {
  CANNOT_MOVE = 'cannotMove',
  ERUPTION_COMPENSATION = 'eruptionCompensation',
  WILL_EXTRACT = 'willExtract',
}

export const RESERVED_QUESTION_KEYS = {
  CHIEF_COMPLAINT: 'chiefComplaint',
  ...TOOH_CHART_ACTIONS,
};

export const DATE_TIME_FORMAT = 'MMM Do YYYY h:mm A';
