import React from 'react';
import {
  Sidebar,
  SidebarLink,
  SidebarTitle,
} from 'pages/PracticeAccountCreator/PracticeAccountCreator.css';
import { ANCHOR_TAGS } from 'pages/PracticeAccountCreator/types';
import BrandChip from 'components/BrandChip/BrandChip';

const AccountCreatorSidebar = ({ accountBrand }: { accountBrand: string }) => {
  return (
    <Sidebar>
      {accountBrand && <BrandChip brandInfo={{ name: accountBrand }} />}
      <SidebarTitle>ACCOUNT DETAILS</SidebarTitle>
      <SidebarLink href={`#${ANCHOR_TAGS.PRACTICE_INFORMATION}`}>
        Practice Information
      </SidebarLink>
      <SidebarLink href={`#${ANCHOR_TAGS.BILLING_INFORMATION}`}>
        Billing Information
      </SidebarLink>
      <SidebarTitle>CONTACT DETAILS</SidebarTitle>
      <SidebarLink href={`#${ANCHOR_TAGS.ADDRESS_INFORMATION}`}>
        Address Information
      </SidebarLink>
      <SidebarLink href={`#${ANCHOR_TAGS.DOCTOR_INFORMATION}`}>
        Doctor Information
      </SidebarLink>
    </Sidebar>
  );
};

export default AccountCreatorSidebar;
