import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import * as Sentry from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';
import { defineCustomElements } from '@launchnotes/embed/dist/loader';
import 'i18n';
import { reportError } from 'utils/api';
import App from 'app/App';
import { getLDKey } from 'utils/auth';

defineCustomElements(window);

Sentry.init({
  dsn: 'https://7cb3be0693414207bc4cfe712f778746@sentry.io/1395293',
  environment: import.meta.env.VITE_REACT_APP_ENV,
});

// Initialize Datadog
if (
  import.meta.env.VITE_REACT_APP_DATADOG_APP_ID &&
  import.meta.env.VITE_REACT_APP_DATADOG_TOKEN
) {
  datadogRum.init({
    applicationId: import.meta.env.VITE_REACT_APP_DATADOG_APP_ID,
    clientToken: import.meta.env.VITE_REACT_APP_DATADOG_TOKEN,
    site: 'datadoghq.com',
    service: 'frontend-tools',
    env: import.meta.env.VITE_REACT_APP_ENV,
    version: import.meta.env.VITE_REACT_APP_GIT_SHA || 'not-set',
    sampleRate: 25,
    sessionReplaySampleRate: 0,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingOrigins: [/https:\/\/.*\.candidco\.com/],
  });
}

datadogRum.startSessionReplayRecording();

// Intercept all API error responses and report to Sentry
axios.interceptors.response.use(undefined, reportError);

(async () => {
  const ldUserKey = getLDKey();
  const user = ldUserKey
    ? {
        key: ldUserKey,
      }
    : undefined;
  const LDProvider = await asyncWithLDProvider({
    clientSideID: import.meta.env
      .VITE_REACT_APP_LAUNCH_DARKLY_CLIENT_ID as string,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    user,
  });

  ReactDOM.render(
    <StrictMode>
      <LDProvider>
        <React.Suspense fallback={'loading'}>
          <App />
        </React.Suspense>
      </LDProvider>
    </StrictMode>,
    document.getElementById('root')
  );
})();
