import React, { useState, useEffect, useMemo } from 'react';
import { ChildAccountInputBox } from 'pages/PracticeAccountCreator/ChildAccountInput';
import api from 'state/api';
import { ANCHOR_TAGS } from 'pages/PracticeAccountCreator/types';
import {
  FormikContainer,
  ButtonContainer,
  StyledLoading,
} from 'pages/PracticeAccountCreator/PracticeAccountCreator.css';
import { CreatePracticeAccountMutationVariables } from 'generated/legacy/graphql';
import Footer from 'pages/PracticeAccountCreator/Footer';
import { SupportedBrand } from 'utils/brands';

import { useFormikContext } from 'formik';
import FormSection from 'components/FormSection/FormSection';
import {
  FormikInputWrapper,
  FormikOption,
  FormikSelectWrapper,
} from 'components/FormikForms';
import { useNotificationContext } from 'core/context/NotificationContext';
import { DevelopmentActions } from 'pages/PracticeAccountCreator/DevelopmentActions';
import {
  BRANDS,
  convertToBrand,
  getBrandSettings,
  GLIDEWELL_BRAND_NAME,
} from 'utils/brands';
import {
  autofillFormFields,
  setLoyaltyOptionsFromLead,
} from 'pages/PracticeAccountCreator/utils';
import { PracticeLeadData } from 'pages/PracticeAccountCreator/types';
import AddressForm from 'components/AddressForm/AddressForm';
import { AddressFormType } from 'components/AddressForm/types';
import { validPartnerOrgId } from 'components/FormikForms/utils';

const PracticeInformation = ({
  id,
  enableBrandSelection,
  enableLoyaltySelection,
  loyaltyProgramOptions,
  loyaltyTierOptions,
  shouldDisplayPartnerOrgExternalId,
}: {
  id: string;
  enableBrandSelection: boolean;
  enableLoyaltySelection: boolean;
  loyaltyProgramOptions: FormikOption[];
  loyaltyTierOptions: FormikOption[] | undefined;
  shouldDisplayPartnerOrgExternalId: boolean;
}) => {
  const brandOptions = Object.values(BRANDS).map((brand) => ({
    displayValue: brand.providerFacingName,
    value: brand.snakeCaseBrand,
  }));

  return (
    <div id={id}>
      <FormikInputWrapper
        name="practice.name"
        type="text"
        disabled
        label="Practice Name"
      />
      <FormikSelectWrapper
        name="practice.brandName"
        type="select"
        disabled={!enableBrandSelection}
        label="Practice Brand"
        options={brandOptions}
      />
      <FormikSelectWrapper
        name="practice.loyaltyProgramId"
        type="select"
        disabled={!enableLoyaltySelection}
        label="Loyalty Program"
        options={loyaltyProgramOptions}
      />
      <FormikSelectWrapper
        name="practice.loyaltyTierId"
        type="select"
        disabled={!enableLoyaltySelection || !loyaltyTierOptions?.length}
        label="Loyalty Tier"
        options={loyaltyTierOptions || []}
      />
      {shouldDisplayPartnerOrgExternalId && (
        <FormikInputWrapper
          label="Partner Org External ID*"
          name="practice.partnerOrgAccountExternalId"
          validate={(value) => validPartnerOrgId(value, 'Glidewell')}
          type="text"
        />
      )}
    </div>
  );
};

const BillingInformation = ({
  id,
  shouldDisplayBillingFields,
}: {
  id: string;
  shouldDisplayBillingFields: boolean;
}) => (
  <div id={id}>
    {shouldDisplayBillingFields ? (
      <>
        <FormikInputWrapper
          name="practice.billingAccount.billingContact.name"
          type="text"
          disabled
          label="Billing Contact Name"
        />
        <FormikInputWrapper
          name="practice.billingAccount.billingContact.primaryContactNumber"
          type="text"
          disabled
          label="Billing Contact Phone"
        />
        <FormikInputWrapper
          name="practice.billingAccount.billingContact.email"
          type="text"
          disabled
          label="Billing Contact Email"
        />
      </>
    ) : (
      <h5>Glidewell Billing Account</h5>
    )}
  </div>
);

const AddressInformation = ({
  id,
  submitButtonRef,
  submitForm,
}: {
  id: string;
  submitButtonRef: React.RefObject<HTMLButtonElement>;
  submitForm: (values: CreatePracticeAccountMutationVariables) => void;
}) => (
  <div id={id}>
    <FormikInputWrapper
      name="practice.address.name"
      type="text"
      label="Address Name"
    />
    <AddressForm
      hideBusinessName={true}
      addressPath="practice.address."
      getAddressData={(values: CreatePracticeAccountMutationVariables) =>
        values.practice.address ?? {}
      }
      submitForm={submitForm}
      updateAddress={(
        value: AddressFormType,
        originalValues: CreatePracticeAccountMutationVariables
      ) => {
        return {
          ...originalValues,
          practice: {
            ...originalValues.practice,
            address: {
              addressLine1: value.addressLine1 ?? '',
              addressLine2: value.addressLine2 ?? '',
              city: value.city ?? '',
              stateCode: value.stateCode ?? '',
              zip: value.zip ?? '',
              //Smarty street doesn't return these fields so use the original.
              countryCode: originalValues.practice.address?.countryCode ?? '',
              phone: originalValues.practice.address?.phone ?? '',
              name: originalValues.practice.address?.name ?? '',
            },
          },
        };
      }}
      submitButtonRef={submitButtonRef}
    />
    <FormikInputWrapper
      name="practice.address.phone"
      type="text"
      disabled
      label="Phone"
    />
  </div>
);

const DoctorInformation = ({ id }: { id: string }) => (
  <div id={id}>
    <FormikInputWrapper
      name="userDoctors[0].fullName"
      type="text"
      disabled
      label="Doctor Name"
    />
    <FormikInputWrapper
      name="userDoctors[0].email"
      type="text"
      disabled
      label="Doctor Email"
    />
  </div>
);

const FormSections = ({
  salesforceChildAccountId,
  setSalesforceChildAccountId,
  setAccountBrand,
  submitButtonRef,
  submitForm,
}: {
  salesforceChildAccountId: string;
  setSalesforceChildAccountId: (id: string) => void;
  setAccountBrand: (brand: string) => void;
  submitButtonRef: React.RefObject<HTMLButtonElement>;
  submitForm: (values: CreatePracticeAccountMutationVariables) => void;
}) => {
  const [inProgress, setInProgress] = useState(false);
  const [enableBrandSelection, setEnableBrandSelection] = useState(false);
  const [enableLoyaltySelection, setEnableLoyaltySelection] = useState(false);
  const [loyaltyProgramOptions, setLoyaltyProgramOptions] = useState<
    FormikOption[]
  >([]);
  const [loyaltyTierOptions, setLoyaltyTierOptions] = useState<
    FormikOption[] | undefined
  >([]);

  const [getPracticeLeadData] = api.useLazyGetPracticeLeadDataQuery({});
  const [getAccountLeadData] = api.useLazyGetAccountLeadDataQuery({});
  const { data: activeLoyaltyPrograms } = api.useGetActiveLoyaltyProgramQuery(
    {}
  );

  const { setFieldValue, values, dirty } =
    useFormikContext<CreatePracticeAccountMutationVariables>();
  const { showNotification } = useNotificationContext();

  const brand = values?.practice?.brandName;
  useEffect(() => {
    if (brand) {
      setFieldValue(
        'practice.partnerOrgName',
        getBrandSettings(brand as SupportedBrand).label
      );
    }
  }, [brand]);

  const setLoyaltyOptions = async (practiceLead: PracticeLeadData) => {
    await setLoyaltyOptionsFromLead(
      practiceLead,
      activeLoyaltyPrograms,
      setFieldValue,
      setLoyaltyProgramOptions,
      setLoyaltyTierOptions,
      setEnableLoyaltySelection
    );
  };

  useEffect(() => {
    if (enableLoyaltySelection && activeLoyaltyPrograms) {
      setLoyaltyTierOptions(
        activeLoyaltyPrograms
          ?.find(
            (program: { id: string }) =>
              program.id === values?.practice?.loyaltyProgramId
          )
          ?.loyaltyProgramTiers.map((tier: { name: string; id: string }) => ({
            displayValue: tier.name,
            value: tier.id,
          }))
      );
    }
  }, [values?.practice?.loyaltyProgramId, activeLoyaltyPrograms]);

  const brandName = values.practice.brandName;
  useEffect(() => {
    if (brandName) {
      setAccountBrand(brandName);
    }
  }, [brandName]);

  const shouldDisplayBillingFields = useMemo(() => {
    if (brandName) {
      return convertToBrand(brandName) !== GLIDEWELL_BRAND_NAME;
    }
    return false;
  }, [brandName]);
  return (
    <>
      <ButtonContainer>
        <ChildAccountInputBox
          externalId={salesforceChildAccountId}
          setExternalId={setSalesforceChildAccountId}
          autofill={(overrideId = '') =>
            // The overrideId variable is passed in when the page's child-account-id query param is hydrated
            // This is needed as we want to set the ID in the form, and call the autofill function with the same ID
            autofillFormFields(
              salesforceChildAccountId || overrideId,
              setFieldValue,
              setAccountBrand,
              setInProgress,
              getPracticeLeadData,
              getAccountLeadData,
              showNotification,
              setLoyaltyOptions
            )
          }
          isLoading={inProgress}
        />
        <DevelopmentActions
          setSalesforceChildAccountId={setSalesforceChildAccountId}
          setInProgress={setInProgress}
          setEnableBrandSelection={setEnableBrandSelection}
          setLoyaltyOptions={setLoyaltyOptions}
        />
      </ButtonContainer>

      {inProgress && <StyledLoading />}

      {dirty && (
        <FormikContainer>
          <FormSection
            title="Practice Information"
            description="Details about the practice"
            id={ANCHOR_TAGS.PRACTICE_INFORMATION}
          >
            <PracticeInformation
              id={ANCHOR_TAGS.PRACTICE_INFORMATION}
              enableBrandSelection={enableBrandSelection}
              enableLoyaltySelection={enableLoyaltySelection}
              loyaltyProgramOptions={loyaltyProgramOptions}
              loyaltyTierOptions={loyaltyTierOptions}
              shouldDisplayPartnerOrgExternalId={brand === GLIDEWELL_BRAND_NAME}
            />
          </FormSection>
          <FormSection
            title="Billing Information"
            description="Billing details"
            id={ANCHOR_TAGS.BILLING_INFORMATION}
          >
            <BillingInformation
              id={ANCHOR_TAGS.BILLING_INFORMATION}
              shouldDisplayBillingFields={shouldDisplayBillingFields}
            />
          </FormSection>
          <FormSection
            title="Address Information"
            description="Practice address details"
            id={ANCHOR_TAGS.ADDRESS_INFORMATION}
          >
            <AddressInformation
              id={ANCHOR_TAGS.ADDRESS_INFORMATION}
              submitButtonRef={submitButtonRef}
              submitForm={submitForm}
            />
          </FormSection>
          <FormSection
            title="Doctor Information"
            description="Details of the associated doctor"
            id={ANCHOR_TAGS.DOCTOR_INFORMATION}
          >
            <DoctorInformation id={ANCHOR_TAGS.DOCTOR_INFORMATION} />
          </FormSection>
        </FormikContainer>
      )}

      <Footer />
    </>
  );
};

export default FormSections;
