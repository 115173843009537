import React, { useMemo, useState, useEffect } from 'react';
import { Formik, FormikProps } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components/macro';
import { Button, Heading, colors } from 'core/components';
import CloseSVG from 'assets/close.svg?react';
import {
  FormikInputWithIcon,
  FormikOption,
} from 'components/FormikForms/index';
import { LinkButton } from 'styles/inputs.css';
import { Spacer } from 'styles/layout.css';
import { initialCustomerSearchValues } from 'components/SearchTablePage/constants';
import { CustomerSearchProps } from 'components/SearchTablePage/types';
import { PROVIDER_FACING_STATUSES } from 'types/caseStatus';
import { useLocation, useHistory } from 'react-router-dom';
import { SearchableInternalCaseStates } from 'types/Case';
import DoctorSelector from 'components/SearchTablePage/SearchSidebar/DoctorSelector';
import {
  FilterStatus,
  FilterType,
  getFilter,
} from 'components/SearchTablePage/SearchSidebar/filters';
import FilterDrawer from 'components/SearchTablePage/table/FilterDrawer';

export { FilterStatus };
export type { FilterType };

export enum SearchPageLocation {
  OrthoPrism = 'ortho-prism-search',
  Case = 'case-search',
  Patient = 'patient-search',
}

type Props = {
  drawer?: {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
  };

  filterDoctors?: boolean;
  filterType: FilterType;
  search: false | 'instant' | 'delayed';
  onSubmit: (values: CustomerSearchProps) => void;
  showClearFilter?: boolean;
};

export const SearchSidebar = (props: Props) => {
  const { drawer, filterType, search, onSubmit, showClearFilter } = props;

  const searchParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  const history = useHistory();

  const instantSearch = !search || search === 'instant';

  const FilterValues = useMemo(() => {
    switch (filterType) {
      case 'internal_status':
        return SearchableInternalCaseStates;
      case 'journey':
        return [];
      case 'provider_facing_status':
        return PROVIDER_FACING_STATUSES;
    }
  }, [filterType]);

  const filterComponent = useMemo(() => {
    switch (filterType) {
      case 'internal_status':
        return FilterStatus.InternalCaseState;
      case 'journey':
        return undefined;
      case 'provider_facing_status':
        return FilterStatus.ProviderFacingStatus;
    }
  }, [filterType]);

  const paramFilterValue = useMemo<string>(() => {
    return (
      Object.values(FilterValues).find(
        (value) =>
          value.toLowerCase() === searchParams.get('status')?.toLowerCase()
      ) ?? ''
    );
  }, [searchParams.get('status') || '']);

  const [doctorsFilter, setDoctorsFilter] = useState<
    FormikOption<string>[] | undefined
  >(undefined);
  const [filterValue, setFilterValue] = useState<string>(paramFilterValue);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (filterValue) {
      searchParams.set('status', filterValue.toString());
    } else {
      searchParams.delete('status');
    }
    onSubmit({
      ...initialCustomerSearchValues,
      doctors: doctorsFilter?.map((doctor) => doctor.value) ?? [],
      searchInput: searchText,
      filterComponent,
      filterValue: filterValue?.toString() || '',
    });
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [doctorsFilter, filterValue]);

  const handleReset = (values: CustomerSearchProps) => {
    // Search the value again after clearing the search
    onSubmit(values);
  };

  useEffect(() => {
    if (searchText.trim().length !== 1) {
      instantSearch &&
        onSubmit({
          ...initialCustomerSearchValues,
          doctors: doctorsFilter?.map((doctor) => doctor.value) ?? [],
          searchInput: searchText,
          filterComponent,
          filterValue: filterValue?.toString() || '',
        });
    }
  }, [searchText]);

  const sidebar = (
    <ControlledSearchSidebar
      doctorsFilter={doctorsFilter}
      extraTitleComponent={
        drawer ? (
          <CloseSVG
            onClick={() => drawer.setIsOpen(false)}
            style={{
              cursor: 'pointer',
            }}
          />
        ) : null
      }
      filterByDoctors={props.filterDoctors ?? false}
      filterType={filterType}
      filterValue={filterValue}
      handleReset={handleReset}
      search={search}
      onSubmit={onSubmit}
      paramFilterValue={paramFilterValue}
      searchText={searchText}
      setDoctorsFilter={setDoctorsFilter}
      setFilterValue={setFilterValue}
      setSearchText={setSearchText}
      showClearFilter={showClearFilter}
    />
  );

  return drawer ? (
    <FilterDrawer
      BackdropProps={{
        invisible: true,
      }}
      anchor="right"
      open={drawer.isOpen}
      onClose={() => drawer.setIsOpen(false)}
    >
      {sidebar}
    </FilterDrawer>
  ) : (
    sidebar
  );
};

const ControlledSearchSidebar = ({
  doctorsFilter,
  extraTitleComponent,
  filterByDoctors,
  filterType,
  filterValue,
  handleReset,
  search,
  onSubmit,
  paramFilterValue,
  searchText,
  setDoctorsFilter,
  setFilterValue,
  setSearchText,
  showClearFilter,
}: Pick<Props, 'filterType' | 'search' | 'onSubmit'> & {
  doctorsFilter: FormikOption<string>[] | undefined;
  extraTitleComponent?: React.ReactNode;
  filterByDoctors: boolean;
  filterValue: string;
  handleReset: (values: CustomerSearchProps) => void;
  paramFilterValue: string;
  searchText: string;
  setDoctorsFilter: (value: FormikOption<string>[] | undefined) => void;
  setFilterValue: (value: string) => void;
  setSearchText: (value: string) => void;
  showClearFilter?: boolean;
}) => {
  const Filter = useMemo(() => getFilter(filterType), [filterType]);

  const instantSearch = !search || search === 'instant';

  return (
    <Container>
      <Heading
        variant="h4"
        style={{
          display: 'flex',
          marginBottom: '1rem',
        }}
      >
        <span
          style={{
            flex: '1',
          }}
        >
          Filters
        </span>
        {extraTitleComponent}
      </Heading>
      <Formik
        initialValues={{
          ...initialCustomerSearchValues,
          provider_facing_status: paramFilterValue,
          INTERNAL: paramFilterValue,
        }}
        onSubmit={onSubmit}
        onReset={handleReset}
      >
        {(props: FormikProps<CustomerSearchProps>) => {
          const hasChanges =
            !instantSearch ||
            !!filterValue.length ||
            (filterByDoctors && !!doctorsFilter?.length);

          const clearFilter = () => {
            setFilterValue('');
            props.setFieldValue('provider_facing_status', '');
            props.setFieldValue('INTERNAL', '');
            filterByDoctors && setDoctorsFilter([]);
          };

          return (
            <form onSubmit={props.handleSubmit} onReset={props.handleReset}>
              <Spacer isVertical spacing="2rem" mobileSpacing="1rem">
                {search !== false && (
                  <FormikInputWithIcon
                    leadingIcon={<SearchIcon />}
                    trailingIcon={
                      <CloseIcon
                        style={{
                          height: '16px',
                          width: '16px',
                          color: '#BCBCBC',
                        }}
                      />
                    }
                    placeholder={'Search for patient'}
                    type="text"
                    name="searchInput"
                    testId="customer-search-input"
                    onChange={(e) => {
                      props.setFieldValue('searchInput', e.target.value);
                      setSearchText(e.target.value);
                    }}
                    onClear={() => setSearchText('')}
                    value={searchText}
                  />
                )}
                <div>
                  <Spacer isVertical spacing="1rem">
                    <Filter
                      onClear={clearFilter}
                      onSelectChange={({ value }) =>
                        ['internal_status', 'provider_facing_status'].indexOf(
                          filterType
                        ) > -1 && setFilterValue(value)
                      }
                    />
                    {filterByDoctors && (
                      <DoctorSelector
                        onClear={() => setDoctorsFilter(undefined)}
                        onSelectionChange={setDoctorsFilter}
                        value={doctorsFilter}
                      />
                    )}
                  </Spacer>
                </div>
                {!instantSearch && (
                  <Button
                    type="submit"
                    buttonType="secondary"
                    testId="customer-search-btn"
                  >
                    Search
                  </Button>
                )}
                {showClearFilter && hasChanges && (
                  <StyledButton
                    type={!instantSearch ? 'reset' : 'button'}
                    onClick={instantSearch ? clearFilter : undefined}
                  >
                    <IconContainer>
                      <CloseIcon />
                    </IconContainer>
                    <StyledLinkButton>Clear Filters</StyledLinkButton>
                  </StyledButton>
                )}
              </Spacer>
            </form>
          );
        }}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem;
`;

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
`;

const StyledLinkButton = styled(LinkButton)`
  margin-top: 1px;
  margin-left: 0.5rem;
`;

const IconContainer = styled.div`
  height: 1rem;
  width: 1rem;
  color: ${colors.blue};

  svg {
    width: 100%;
    height: 100%;
  }
`;
