/**
 * From T, pick a set of properties whose keys are in the union K.
 * Make each property required and non-nullable.
 */
export type RequiredNonNullable<T, K extends keyof T> = {
  [P in K]-?: NonNullable<T[K]>;
};

/**
 * Checks if value is not null or undefined.
 */
export const isNotNil = <T>(val: T | null | undefined): val is T => {
  return !!val;
};

/**
 * Checks if the value looks like a message object (e.g. an error response).
 */
export const hasMessageField = (obj: unknown): obj is { message: string } => {
  return (
    !!obj &&
    typeof obj === 'object' &&
    'message' in obj &&
    typeof obj.message === 'string'
  );
};
