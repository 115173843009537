import styled from 'styled-components';
import { Table, theme } from 'core/components';
import User from 'assets/ic_user.svg?react';
import RightArrowSVG from 'assets/arrow-right.svg?react';

export const StyledTable = styled(Table)`
  width: 100%;
  z-index: 0;
  & td {
    padding: 16px;
    margin: 0;
  }
  & th {
    padding: 4px 16px;
  }
`;

export const ButtonContainer = styled.div`
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
`;

export const UserSvg = styled(User)`
  path {
    stroke: ${theme.colors.black70};
  }

  width: 20px;
  height: 20px;
`;

export const ArrowSVG = styled(RightArrowSVG)`
  path {
    stroke: ${theme.colors.black70};
  }

  width: 12px;
  height: 12px;
`;
