import { Button } from 'core/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useMemo, useState } from 'react';

import {
  AccountLeadQuery,
  AccountLeadDocument,
  AccountLeadQueryVariables,
  PracticeLeadQuery,
  PracticeLeadDocument,
  PracticeLeadQueryVariables,
} from 'generated/core/graphql';
import { OrganizationTypes } from 'generated/legacy/graphql';
import { coreClient } from 'gql/clients';
import { Input } from 'styles/inputs.css';
import { ApolloQueryResult } from '@apollo/client';

import { isValidExternalId } from 'utils/accountCreation';

type AccountLeadData =
  ApolloQueryResult<AccountLeadQuery>['data']['getAccountLeadData'];
type PracticeLeadData =
  ApolloQueryResult<PracticeLeadQuery>['data']['getPracticeLeadData'];

function AccountCreationAutofill({
  createNewAddress,
  createNewBilling,
  setFieldValue,
}: {
  createNewAddress: () => void;
  createNewBilling: () => void;
  setFieldValue: (field: string, value: unknown) => void;
}) {
  const { 'enable-account-creation-sf-autofill': enabled } = useFlags();
  if (!enabled) {
    return null;
  }

  const [externalId, setExternalId] = useState<string>('');
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const autoFill = useCallback(async () => {
    try {
      setError(null);
      setInProgress(true);

      const [accountSettled, practiceSettled] = await Promise.allSettled([
        coreClient.query<AccountLeadQuery, AccountLeadQueryVariables>({
          query: AccountLeadDocument,
          variables: {
            id: externalId,
          },
        }),
        coreClient.query<PracticeLeadQuery, PracticeLeadQueryVariables>({
          query: PracticeLeadDocument,
          variables: {
            id: externalId,
          },
        }),
      ]);

      const accountResult =
        (accountSettled.status === 'fulfilled' &&
          accountSettled.value.data.getAccountLeadData) ||
        null;
      const practiceResult =
        (practiceSettled.status === 'fulfilled' &&
          practiceSettled.value.data.getPracticeLeadData) ||
        null;

      if (accountResult) {
        setFieldsForAccount(
          accountResult,
          createNewAddress,
          createNewBilling,
          setFieldValue
        );
      } else if (practiceResult) {
        const nestedAccountResult = await coreClient.query<
          AccountLeadQuery,
          AccountLeadQueryVariables
        >({
          query: AccountLeadDocument,
          variables: {
            id: practiceResult.externalAccountId,
          },
        });

        setFieldsForPractice(
          practiceResult,
          nestedAccountResult.data.getAccountLeadData,
          createNewAddress,
          createNewBilling,
          setFieldValue
        );
      } else {
        const error: Error | null =
          (practiceSettled.status === 'rejected' && practiceSettled.reason) ||
          (accountSettled.status === 'rejected' && accountSettled.reason);

        if (error) {
          setError(error.message);
        } else {
          setError('Failed to find account or practice with provided id');
        }
      }
    } finally {
      setInProgress(false);
    }
  }, [externalId]);

  const isValid = useMemo(() => isValidExternalId(externalId), [externalId]);
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <div>Auto-fill with account id:</div>
        <Input
          value={externalId}
          onChange={(e) => setExternalId(e.target.value)}
        />
        <Button
          buttonType="secondary"
          onClick={autoFill}
          disabled={!isValid || inProgress}
        >
          Fill
        </Button>
      </div>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <div
        style={{
          margin: '1rem 0',
          fontWeight: 'bolder',
        }}
      >
        OR
      </div>
    </>
  );
}

function setFieldsForPractice(
  practice: PracticeLeadData,
  account: AccountLeadData,
  createNewAddress: () => void,
  createNewBilling: () => void,
  setFieldValue: (field: string, value: unknown) => void
) {
  setFieldValue('practice.name', practice.name);
  setFieldValue('practice.organizationType', OrganizationTypes.OfficeLocation);
  setFieldValue(
    'practice.salesforceChildAccountId',
    practice.externalPracticeId
  );

  setAddressInfo(practice, createNewAddress, setFieldValue);
  setBillingInfo(account, createNewBilling, setFieldValue);
}

function setFieldsForAccount(
  account: AccountLeadData,
  createNewAddress: () => void,
  createNewBilling: () => void,
  setFieldValue: (field: string, value: unknown) => void
) {
  setFieldValue('practice.name', account.name);
  setFieldValue(
    'practice.organizationType',
    OrganizationTypes.ManagingOrganization
  );

  setAddressInfo(account, createNewAddress, setFieldValue);
  setBillingInfo(account, createNewBilling, setFieldValue);
}

function setAddressInfo(
  data: AccountLeadData | PracticeLeadData,
  createNewAddress: () => void,
  setFieldValue: (field: string, value: unknown) => void
) {
  createNewAddress();

  setFieldValue('practice.address.addressLine1', data.address);
  setFieldValue('practice.address.city', data.city);
  setFieldValue('practice.address.name', data.name);
  setFieldValue('practice.address.phone', data.phone);
  setFieldValue('practice.address.stateCode', data.state);
  setFieldValue('practice.address.zip', data.zip);
}

function setBillingInfo(
  account: AccountLeadData,
  createNewBilling: () => void,
  setFieldValue: (field: string, value: unknown) => void
) {
  createNewBilling();

  setFieldValue(
    'practice.billingAccount.salesforceParentAccountId',
    account.externalAccountId
  );

  if (account.primaryContacts.length) {
    const primaryContact = account.primaryContacts[0];
    setFieldValue(
      'practice.billingAccount.billingContact.name',
      `${primaryContact.firstName} ${primaryContact.lastName}`
    );
    setFieldValue(
      'practice.billingAccount.billingContact.email',
      primaryContact.email
    );
    setFieldValue(
      'practice.billingAccount.billingContact.primaryContactNumber',
      primaryContact.phone
    );
  }
}

export default AccountCreationAutofill;
