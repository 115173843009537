import React from 'react';
import {
  LegacyToothWrapper,
  ToothWrapper,
} from 'components/ToothChart/DynamicTooth.css';

import { DentalNotationOptions } from 'generated/legacy/graphql';
import {
  convertFromPalmerToUniversal,
  convertFromFDIToUniversal,
} from 'components/ToothChart/utils';

import UniversalToothIcon1 from 'assets/universalToothChartIcons/1.svg?react';
import UniversalToothIcon2 from 'assets/universalToothChartIcons/2.svg?react';
import UniversalToothIcon3 from 'assets/universalToothChartIcons/3.svg?react';
import UniversalToothIcon4 from 'assets/universalToothChartIcons/4.svg?react';
import UniversalToothIcon5 from 'assets/universalToothChartIcons/5.svg?react';
import UniversalToothIcon6 from 'assets/universalToothChartIcons/6.svg?react';
import UniversalToothIcon7 from 'assets/universalToothChartIcons/7.svg?react';
import UniversalToothIcon8 from 'assets/universalToothChartIcons/8.svg?react';
import UniversalToothIcon9 from 'assets/universalToothChartIcons/9.svg?react';
import UniversalToothIcon10 from 'assets/universalToothChartIcons/10.svg?react';
import UniversalToothIcon11 from 'assets/universalToothChartIcons/11.svg?react';
import UniversalToothIcon12 from 'assets/universalToothChartIcons/12.svg?react';
import UniversalToothIcon13 from 'assets/universalToothChartIcons/13.svg?react';
import UniversalToothIcon14 from 'assets/universalToothChartIcons/14.svg?react';
import UniversalToothIcon15 from 'assets/universalToothChartIcons/15.svg?react';
import UniversalToothIcon16 from 'assets/universalToothChartIcons/16.svg?react';
import UniversalToothIcon17 from 'assets/universalToothChartIcons/17.svg?react';
import UniversalToothIcon18 from 'assets/universalToothChartIcons/18.svg?react';
import UniversalToothIcon19 from 'assets/universalToothChartIcons/19.svg?react';
import UniversalToothIcon20 from 'assets/universalToothChartIcons/20.svg?react';
import UniversalToothIcon21 from 'assets/universalToothChartIcons/21.svg?react';
import UniversalToothIcon22 from 'assets/universalToothChartIcons/22.svg?react';
import UniversalToothIcon23 from 'assets/universalToothChartIcons/23.svg?react';
import UniversalToothIcon24 from 'assets/universalToothChartIcons/24.svg?react';
import UniversalToothIcon25 from 'assets/universalToothChartIcons/25.svg?react';
import UniversalToothIcon26 from 'assets/universalToothChartIcons/26.svg?react';
import UniversalToothIcon27 from 'assets/universalToothChartIcons/27.svg?react';
import UniversalToothIcon28 from 'assets/universalToothChartIcons/28.svg?react';
import UniversalToothIcon29 from 'assets/universalToothChartIcons/29.svg?react';
import UniversalToothIcon30 from 'assets/universalToothChartIcons/30.svg?react';
import UniversalToothIcon31 from 'assets/universalToothChartIcons/31.svg?react';
import UniversalToothIcon32 from 'assets/universalToothChartIcons/32.svg?react';

const universalToothIconList = [
  UniversalToothIcon1,
  UniversalToothIcon2,
  UniversalToothIcon3,
  UniversalToothIcon4,
  UniversalToothIcon5,
  UniversalToothIcon6,
  UniversalToothIcon7,
  UniversalToothIcon8,
  UniversalToothIcon9,
  UniversalToothIcon10,
  UniversalToothIcon11,
  UniversalToothIcon12,
  UniversalToothIcon13,
  UniversalToothIcon14,
  UniversalToothIcon15,
  UniversalToothIcon16,
  UniversalToothIcon17,
  UniversalToothIcon18,
  UniversalToothIcon19,
  UniversalToothIcon20,
  UniversalToothIcon21,
  UniversalToothIcon22,
  UniversalToothIcon23,
  UniversalToothIcon24,
  UniversalToothIcon25,
  UniversalToothIcon26,
  UniversalToothIcon27,
  UniversalToothIcon28,
  UniversalToothIcon29,
  UniversalToothIcon30,
  UniversalToothIcon31,
  UniversalToothIcon32,
];

const getToothIconAsset = ({
  quadrant,
  index,
  notation = DentalNotationOptions.Universal,
}: {
  quadrant: number;
  index: number;
  notation?: DentalNotationOptions;
}) => {
  let universalIndex = index;
  if (notation === DentalNotationOptions.Palmer) {
    universalIndex = convertFromPalmerToUniversal(quadrant, index);
  } else if (notation === DentalNotationOptions.Fdi) {
    universalIndex = convertFromFDIToUniversal(quadrant, index);
  }

  return universalToothIconList[universalIndex - 1] || UniversalToothIcon1;
};

export type LegacyDynamicToothProps = {
  index: string;
  checked: boolean;
  readOnly: boolean;
  onChange: (quadrant: number, index: number, value: boolean) => void;
  disabled: boolean;
  quadrant: number;
  notation?: DentalNotationOptions;
};

const LegacyDynamicTooth = ({
  checked,
  quadrant,
  index,
  readOnly,
  disabled,
  notation = DentalNotationOptions.Universal,
  onChange,
}: LegacyDynamicToothProps) => {
  const ToothIcon = getToothIconAsset({
    quadrant,
    index: Number(index),
    notation,
  });

  return (
    <LegacyToothWrapper
      onClick={() => {
        if (!disabled) {
          onChange(quadrant, parseInt(index), !checked);
        }
      }}
      isSelected={checked}
      // LegacyCheckbox has distinct UI for disabled and readOnly but they're the same for DynamicTooth
      disabled={disabled || readOnly}
    >
      {<ToothIcon />}
    </LegacyToothWrapper>
  );
};

type DynamicToothProps = {
  quadrant: number;
  index: string;
  disabled?: boolean;
  notation?: DentalNotationOptions;
  onToothClick: (quadrant: number, index: number) => void;
  fillColor?: string;
  strokeColor?: string;
  flipIcon: boolean;
  SelectedIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  HoverIcon: React.FC<React.SVGProps<SVGSVGElement>>;
};

export const DynamicTooth = ({
  quadrant,
  index,
  disabled,
  notation = DentalNotationOptions.Universal,
  onToothClick,
  fillColor,
  strokeColor,
  flipIcon,
  SelectedIcon,
  HoverIcon,
}: DynamicToothProps) => {
  const ToothIcon = getToothIconAsset({
    quadrant,
    index: Number(index),
    notation,
  });

  return (
    <ToothWrapper
      onClick={() => {
        if (!disabled) {
          onToothClick(quadrant, parseInt(index));
        }
      }}
      isSelected={!!SelectedIcon}
      disabled={disabled}
      fillColor={fillColor}
      strokeColor={strokeColor}
      flipIcon={flipIcon}
    >
      <ToothIcon />
      {SelectedIcon ? (
        <SelectedIcon className="filledIcon" />
      ) : (
        <HoverIcon className="hoverIcon" />
      )}
    </ToothWrapper>
  );
};

export default LegacyDynamicTooth;
