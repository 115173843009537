import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppDispatch } from 'state/store';

import {
  ActionButton,
  OrthoPrismButton,
  ArrayContainer,
  ArrayItem,
  Container,
  Header,
  PatientName,
  PatientTitle,
  PhotoReviewType,
  StateBadge,
} from 'pages/Prism/Sidebar/Sidebar.css';
import {
  createSubmission,
  fetchAggregate,
  selectAggregate,
  selectAggregateState,
  selectCurrentView,
  selectCustomer,
  selectFullName,
  selectIsCreatingSubmission,
  selectIsZippingPhotos,
  selectPhotoViewNames,
  selectSelectedPhotos,
  selectSubmissionDate,
  updateView,
  zipPhotos,
  transitionPhoto,
} from 'pages/Prism/prismSlice';
import { selectCurrentSubmissionSet } from 'pages/OrthoPrism/orthoSlice';
import { snakeCaseToCapitalizedWords } from 'utils/string';
import History from 'components/PrismHistory';
import { EvaluationState } from 'pages/Prism/types';
import {
  Section,
  SectionHeading,
} from 'pages/Prism/ReviewPanel/ReviewPanel.css';
import { HRDividerLine } from 'styles/layout.css';
import {
  CustomerType,
  PhotoType,
  SubmissionType,
  SubmissionItemTransitions,
} from 'generated/legacy/graphql';
import { toAggregateLabel } from 'utils/prism';
import SubmissionNoteModal from 'pages/Prism/Sidebar/SubmissionNoteModal';
import { ACCESS_GROUPS } from 'types';
import { useIsEnhancedMaterialReviewJourney } from 'hooks/useIsEnhancedMaterialReviewJourney';
import { useAuthContext } from 'context/AuthContext';
// Context
//
// What is "ABO"? ABO is American Board of Orthodontists and our
// orthos describe the 8 photo layout as an ABO array. So, when you see
// `aboPhotos` or `abo<something>` that's what that dicates.
//
// ```
// x x x
// x o x
// x x x
// ```
//

type SidebarProps = {
  photosIneligibleForReview?: boolean;
};

const Sidebar = ({ photosIneligibleForReview }: SidebarProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { push } = useHistory();
  const aggregate = useSelector(selectAggregate);
  const aggregateState = useSelector(selectAggregateState) ?? '';
  const customer = useSelector(selectCustomer);
  const currentView = useSelector(selectCurrentView);
  const fullName = useSelector(selectFullName);
  const isCreatingSubmission = useSelector(selectIsCreatingSubmission);
  const isZippingPhotos = useSelector(selectIsZippingPhotos);
  const photoSelections = useSelector(selectSelectedPhotos);
  const submissionDate = useSelector(selectSubmissionDate);
  const submissionSet = useSelector(selectCurrentSubmissionSet);
  const [treatmentViewNames, itiViewNames] = useSelector(selectPhotoViewNames);

  const humanReadableAggregateState =
    snakeCaseToCapitalizedWords(aggregateState);
  const aggregateType = aggregate?.aggregateType;
  const { patientRejectionReasons = [] } = aggregateType ?? {};

  const primaryHistory = [
    { data: 'created', created: aggregate?.createdAt! },
  ].concat(aggregate?.stateData?.history ?? []);

  const photoViewNames = treatmentViewNames.concat(itiViewNames);

  const photos = Object.values(photoSelections).filter(Boolean);

  const [showModal, setShowModal] = useState(false);
  const { checkHasAccess } = useAuthContext();
  const isXtendops = checkHasAccess(ACCESS_GROUPS.XTENDOPS);
  const isEnhancedMaterialReviewJourney = useIsEnhancedMaterialReviewJourney();

  const handleApproveUnreviewedPhotos = async () => {
    photos.forEach(async (photo) => {
      if (photo?.stateData?.data !== EvaluationState.approved) {
        await dispatch(
          transitionPhoto({
            photoRef: photo?.ref,
            transition: SubmissionItemTransitions.Approve,
          })
        );
      }
    });
  };

  const handleSubmitToOrtho = async () => {
    const allApproved = photos.every(
      (photo) => photo?.stateData?.data === EvaluationState.approved
    );

    if (!allApproved) {
      handleApproveUnreviewedPhotos();
    }

    await dispatch(fetchAggregate(aggregate?.ref));

    if (!isXtendops) {
      setShowModal(true);
    } else {
      await dispatch(
        createSubmission({
          aggregateRef: aggregate!.ref as string,
          submissionPhotoRefs: photoViewNames.map(
            (name) => photoSelections[name]?.ref as string
          ),
          initialNote: '',
        })
      );
    }
  };

  const handleZipPhotos = () =>
    dispatch(
      zipPhotos({
        photos: photos as PhotoType[],
        customer: customer as CustomerType,
      })
    );

  const isNotSubmittableToOrtho = !!submissionDate || isCreatingSubmission;

  const renderArrayItems = useCallback(
    (name) => {
      const photo = photoSelections[name];
      const status = photo?.stateData?.data;
      const photoUrl = photosIneligibleForReview ? '' : photo?.photoUrl ?? '';
      return (
        <ArrayItem
          key={name}
          view={name}
          isApproved={status === 'approved'}
          isRejected={status === 'rejected'}
          isSelected={name === currentView?.name}
          onClick={() => dispatch(updateView(name))}
          photo={photoUrl}
          title={photo?.photoType.label ?? ''}
          data-private
          data-testid={'PrismPhotoReview-' + name}
        />
      );
    },
    [photoSelections, dispatch, currentView, photosIneligibleForReview]
  );
  return (
    <Container>
      <Header>
        <PatientTitle>PATIENT</PatientTitle>
        <PatientName>{fullName}</PatientName>
        {aggregateState && (
          <StateBadge
            state={aggregateState}
            data-testid="PrismPhotoReview-Status"
          >
            {humanReadableAggregateState}
          </StateBadge>
        )}
      </Header>
      <Section>
        <PhotoReviewType>
          {toAggregateLabel(aggregate?.aggregateType?.label)}
        </PhotoReviewType>
        <ArrayContainer>
          {treatmentViewNames.map(renderArrayItems)}
        </ArrayContainer>
        {!!itiViewNames.length && (
          <>
            <HRDividerLine />
            <ArrayContainer iti>
              {itiViewNames.map(renderArrayItems)}
            </ArrayContainer>
          </>
        )}
        {!!photos.length && (
          <ActionButton
            buttonType="secondary-outline"
            disabled={isZippingPhotos}
            isFullWidth
            isLoading={isZippingPhotos}
            isShort
            onClick={handleZipPhotos}
            data-testid="PrismPhotoReview-downloadPhotos"
          >
            Download Photos
          </ActionButton>
        )}
        <ActionButton
          buttonType="secondary"
          disabled={isNotSubmittableToOrtho || !customer?.doctor}
          isFullWidth
          isLoading={isCreatingSubmission}
          isShort
          onClick={handleSubmitToOrtho}
          data-testid="PrismPhotoReview-submitForOrtho"
        >
          {isEnhancedMaterialReviewJourney
            ? 'Submit for QC'
            : 'Submit for ortho'}
        </ActionButton>
        <OrthoPrismButton
          buttonType="secondary-outline"
          disabled={isCreatingSubmission || !customer}
          isFullWidth
          isShort
          onClick={() => push(`/ortho-prism/${customer?.id}`)}
        >
          View patient in Ortho Prism
        </OrthoPrismButton>
        <SubmissionNoteModal
          isVisible={showModal}
          aggregate={aggregate}
          photoViewNames={photoViewNames}
          onClose={() => setShowModal(false)}
        />
      </Section>
      <HRDividerLine />
      <Section>
        <SectionHeading data-testid="section-heading">
          Submission history
        </SectionHeading>
        <History
          selectedPhotoType={null}
          photoSubmissionHistory={undefined}
          patientRejectionReasons={patientRejectionReasons!}
          primaryHistory={primaryHistory}
          submissionSet={submissionSet as SubmissionType[]}
          testId="SubmissionHistory"
        />
      </Section>
    </Container>
  );
};

export default Sidebar;
