import React from 'react';
import { TooltipProps as MuiTooltipProps } from '@material-ui/core';
import {
  HelpToolTipIcon,
  StyledMuiTooltip,
} from 'core/components/Tooltip/Tooltip.css';

// Turn children prop to optional props
type TooltipProps = Omit<MuiTooltipProps, 'children'> & {
  children?: React.ReactElement<any, any>;
};

const ONE_SECOND = 1000;
const Tooltip = ({ title, children, ...props }: TooltipProps) => (
  <StyledMuiTooltip
    enterTouchDelay={0}
    leaveTouchDelay={6 * ONE_SECOND} // for mobile to just click to view
    title={title} // for mobile to view the tooltip longer
    {...props}
  >
    {children ?? <HelpToolTipIcon />}
  </StyledMuiTooltip>
);

export default Tooltip;
