import styled from 'styled-components/macro';

export const LegacyToothWrapper = styled.div<{
  isSelected: boolean;
  disabled?: boolean;
}>`
  position: relative;
  height: 24px;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  svg {
    ${({ theme, isSelected, disabled }) => `
    display: block;
    width: 100%;
    height: 100%;
  
    path {
      ${
        isSelected
          ? `
          fill: ${disabled ? theme.colors.black40 : theme.colors.blue10};
          stroke: ${disabled ? theme.colors.black50 : theme.colors.blue50};
        `
          : `
          fill: ${disabled ? theme.colors.black05 : theme.colors.white};
          stroke: ${disabled ? theme.colors.black30 : theme.colors.black50};
        `
      }
    }
`}
`;

export const ToothWrapper = styled.div<{
  isSelected: boolean;
  disabled?: boolean;
  fillColor?: string;
  strokeColor?: string;
  flipIcon?: boolean;
}>`
  ${({ theme, isSelected, disabled, fillColor, strokeColor, flipIcon }) => `
  position: relative;
  height: 40px;
  cursor: ${disabled ? 'not-allowed' : 'pointer'};

  svg {
    display: block;
    width: 100%;
    height: 100%;

    path {
      vector-effect: non-scaling-stroke;
      ${
        isSelected
          ? `
          fill: ${fillColor};
          stroke: ${strokeColor};
        `
          : `
          fill: ${theme.colors.white};
          stroke: ${theme.colors.black50};
        `
      }
      
    }
  }

  &:hover {
    svg {
      path {
        ${!(disabled || isSelected) ? `stroke: ${theme.colors.black70};` : ''}  
      }
    }
    .hoverIcon {
      display: ${disabled ? 'none' : 'block'};
    }
  }

  .filledIcon, .hoverIcon {
    position: absolute;
    width: 16px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    ${flipIcon && `rotate: 180deg;`}
  }

  .hoverIcon { 
    display: none;
  }
`}
`;
