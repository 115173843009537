import React, {
  createContext,
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';

import api, { ExtractReturnType } from 'state/api';
import { hasMessageField } from 'utils/typeCheck';

type Cases = ExtractReturnType<typeof api.useGetCasesQuery>;
type Customer = ExtractReturnType<typeof api.useGetPatientQuery>;

type CustomerWithCases = Customer & {
  cases: Cases;
};

export const CustomerCasesContext = createContext({} as CaseContextProps);

interface CaseContextProps {
  getCustomerWithCasesData: (customerId: string) => void;
  customerWithCases: Maybe<CustomerWithCases>;
  loadingCustomerWithCases: boolean;
  customerWithCasesError: Maybe<string>;
}

const CustomerCasesProvider: FunctionComponent = ({ children }) => {
  const [customerWithValidCases, setCustomerWithValidCases] =
    useState<CustomerWithCases | null>(null);
  const [
    getCustomer,
    {
      data: customerData,
      isLoading: customerIsLoading,
      error: customerError,
      isSuccess: customerIsSuccess,
    },
  ] = api.useLazyGetPatientQuery();
  const [
    getCases,
    {
      data: casesData,
      isLoading: casesIsLoading,
      error: casesError,
      isSuccess: casesIsSuccess,
    },
  ] = api.useLazyGetCasesQuery();

  const isLoading = customerIsLoading || casesIsLoading;
  const isSuccess = customerIsSuccess && casesIsSuccess;
  const isError = customerError || casesError;
  const message = hasMessageField(isError) ? isError.message : null;

  useEffect(() => {
    if (isSuccess) {
      setCustomerWithValidCases({
        ...customerData,
        cases: casesData,
      });
    }
  }, [isSuccess, customerData, casesData]);

  const getCustomerWithCasesData = useCallback((customerId: string) => {
    Promise.all([
      getCustomer({
        customerId,
      }),
      getCases({
        patientIds: +customerId,
      }),
    ]);
  }, []);

  return (
    <CustomerCasesContext.Provider
      value={{
        customerWithCases: customerWithValidCases,
        getCustomerWithCasesData,
        loadingCustomerWithCases: isLoading,
        customerWithCasesError: message,
      }}
    >
      {children}
    </CustomerCasesContext.Provider>
  );
};

export default CustomerCasesProvider;
