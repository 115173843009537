import {
  PromotionEffectTypes,
  PromoCriteriaTypes,
  PromotionMethods,
  CriteriaValueType,
  CriteriaOperator,
} from 'generated/core/graphql';
import React, { useState } from 'react';
import { Grid, Chip } from 'core/components';
import { TextField, MenuItem } from '@material-ui/core';

export type CreatePromotionInput = {
  id?: string;
  name: string;
  method: PromotionMethods;
  effectDescription: string;
  effectType: PromotionEffectTypes;
  effectArgsJson?: string;
  criteriaType?: PromoCriteriaTypes;
  criteriaArgsJson?: string;
  isActive?: boolean;
  priorityScore?: number;
};

export type VolumePricingTier = {
  priceInCents: number;
  quantity: number;
};

export const FormTextField: React.FC<{
  label: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  type?: string;
  select?: boolean;
  options?: { value: string | number; label: string }[];
  style?: React.CSSProperties;
  disabled?: boolean;
}> = ({
  label,
  value,
  onChange,
  type = 'text',
  select = false,
  options,
  style = {},
  disabled,
  onClick,
}) => (
  <Grid item xs={12}>
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      type={type}
      select={select}
      fullWidth
      style={style}
      disabled={disabled}
      onClick={onClick}
    >
      {select &&
        options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </TextField>
  </Grid>
);

export const RenderChips: React.FC<{
  label: string;
  values: string[];
  onAdd: (chip: string) => void;
  onDelete: (chip: string) => void;
  placeholder: string;
}> = ({ label, values, onAdd, onDelete, placeholder }) => {
  const [chipValue, setChipValue] = useState('');

  return (
    <Grid item xs={12}>
      <TextField
        label={label}
        value={chipValue}
        onChange={(e) => setChipValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && chipValue) {
            onAdd(chipValue);
            setChipValue('');
          }
        }}
        placeholder={placeholder}
        fullWidth
      />
      <div>
        {values.map((value) => (
          <Chip
            key={value}
            label={value}
            onDelete={() => onDelete(value)}
            style={{ margin: '4px' }}
          />
        ))}
      </div>
    </Grid>
  );
};

export const CriteriaArgsFields: React.FC<{
  formValues: CreatePromotionInput;
  setFormValues: React.Dispatch<React.SetStateAction<CreatePromotionInput>>;
}> = ({ formValues, setFormValues }) => {
  const criteriaArgs = JSON.parse(formValues.criteriaArgsJson || '{}');
  const productSkus: string[] = criteriaArgs.productSkus || [];
  const metafieldCriteria = criteriaArgs.metafieldCriteria || {};
  const operators = Object.values(CriteriaOperator);
  const types = Object.values(CriteriaValueType);

  switch (formValues.criteriaType) {
    case PromoCriteriaTypes.ProductInclusionList:
      return (
        <RenderChips
          label="Product SKUs"
          values={productSkus}
          onAdd={(chip) => {
            productSkus.push(chip);
            setFormValues({
              ...formValues,
              criteriaArgsJson: JSON.stringify({
                ...criteriaArgs,
                productSkus,
              }),
            });
          }}
          onDelete={(chip) => {
            const updatedSkus = productSkus.filter(
              (sku: string) => sku !== chip
            );
            setFormValues({
              ...formValues,
              criteriaArgsJson: JSON.stringify({
                ...criteriaArgs,
                productSkus: updatedSkus,
              }),
            });
          }}
          placeholder="Add a SKU"
        />
      );
    case PromoCriteriaTypes.MetafieldCriteria:
      return (
        <>
          <Grid item xs={12}>
            <TextField
              label="Key"
              value={metafieldCriteria.key || ''}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  criteriaArgsJson: JSON.stringify({
                    ...criteriaArgs,
                    metafieldCriteria: {
                      ...metafieldCriteria,
                      key: e.target.value,
                    },
                  }),
                })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Operator"
              value={metafieldCriteria.operator || ''}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  criteriaArgsJson: JSON.stringify({
                    ...criteriaArgs,
                    metafieldCriteria: {
                      ...metafieldCriteria,
                      operator: e.target.value,
                    },
                  }),
                })
              }
              select
              fullWidth
            >
              {operators.map((operator) => (
                <MenuItem key={operator} value={operator}>
                  {operator}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Type"
              value={metafieldCriteria.type || ''}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  criteriaArgsJson: JSON.stringify({
                    ...criteriaArgs,
                    metafieldCriteria: {
                      ...metafieldCriteria,
                      type: e.target.value,
                    },
                  }),
                })
              }
              select
              fullWidth
            >
              {types.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Value"
              value={metafieldCriteria.value || ''}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  criteriaArgsJson: JSON.stringify({
                    ...criteriaArgs,
                    metafieldCriteria: {
                      ...metafieldCriteria,
                      value: e.target.value,
                    },
                  }),
                })
              }
              fullWidth
            />
          </Grid>
        </>
      );
    default:
      return null;
  }
};

export const EffectArgsFields: React.FC<{
  formValues: CreatePromotionInput;
  setFormValues: React.Dispatch<React.SetStateAction<CreatePromotionInput>>;
}> = ({ formValues, setFormValues }) => {
  const effectArgs = JSON.parse(formValues.effectArgsJson || '{}');
  const percentageDiscount = effectArgs.percentageDiscount || {};
  const productCredit = effectArgs.productCredit || {};
  const volumePricing = effectArgs.volumePricing || {};
  const skus: string[] =
    percentageDiscount.skus || productCredit.skus || volumePricing.skus || [];

  switch (formValues.effectType) {
    case PromotionEffectTypes.PercentageDiscount:
      return (
        <>
          <Grid item xs={12}>
            <TextField
              label="Percentage"
              value={percentageDiscount.percentage || 0}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  effectArgsJson: JSON.stringify({
                    ...effectArgs,
                    percentageDiscount: {
                      ...percentageDiscount,
                      percentage: parseInt(e.target.value),
                    },
                  }),
                })
              }
              fullWidth
            />
          </Grid>
          <RenderChips
            label="SKUs"
            values={skus}
            onAdd={(chip) => {
              skus.push(chip);
              setFormValues({
                ...formValues,
                effectArgsJson: JSON.stringify({
                  ...effectArgs,
                  percentageDiscount: {
                    ...percentageDiscount,
                    skus,
                  },
                }),
              });
            }}
            onDelete={(chip) => {
              const updatedSkus = skus.filter((sku: string) => sku !== chip);
              setFormValues({
                ...formValues,
                effectArgsJson: JSON.stringify({
                  ...effectArgs,
                  percentageDiscount: {
                    ...percentageDiscount,
                    skus: updatedSkus,
                  },
                }),
              });
            }}
            placeholder="Add a SKU"
          />
        </>
      );
    case PromotionEffectTypes.ProductCredit:
      return (
        <>
          <Grid item xs={12}>
            <TextField
              label="Credit in Cents"
              value={productCredit.creditInCents || 0}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  effectArgsJson: JSON.stringify({
                    ...effectArgs,
                    productCredit: {
                      ...productCredit,
                      creditInCents: parseInt(e.target.value),
                    },
                  }),
                })
              }
              fullWidth
            />
          </Grid>
          <RenderChips
            label="SKUs"
            values={skus}
            onAdd={(chip) => {
              skus.push(chip);
              setFormValues({
                ...formValues,
                effectArgsJson: JSON.stringify({
                  ...effectArgs,
                  productCredit: {
                    ...productCredit,
                    skus,
                  },
                }),
              });
            }}
            onDelete={(chip) => {
              const updatedSkus = skus.filter((sku: string) => sku !== chip);
              setFormValues({
                ...formValues,
                effectArgsJson: JSON.stringify({
                  ...effectArgs,
                  productCredit: {
                    ...productCredit,
                    skus: updatedSkus,
                  },
                }),
              });
            }}
            placeholder="Add a SKU"
          />
        </>
      );
    case PromotionEffectTypes.VolumePricing:
      return (
        <>
          <RenderChips
            label="SKUs"
            values={skus}
            onAdd={(chip) => {
              skus.push(chip);
              setFormValues({
                ...formValues,
                effectArgsJson: JSON.stringify({
                  ...effectArgs,
                  volumePricing: {
                    ...volumePricing,
                    skus,
                  },
                }),
              });
            }}
            onDelete={(chip) => {
              const updatedSkus = skus.filter((sku: string) => sku !== chip);
              setFormValues({
                ...formValues,
                effectArgsJson: JSON.stringify({
                  ...effectArgs,
                  volumePricing: {
                    ...volumePricing,
                    skus: updatedSkus,
                  },
                }),
              });
            }}
            placeholder="Add a SKU"
          />
          {volumePricing.tiers.map((tier: VolumePricingTier, index: number) => (
            <React.Fragment key={index}>
              <Grid item xs={6}>
                <TextField
                  label="Price in Cents"
                  value={tier.priceInCents || ''}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      effectArgsJson: JSON.stringify({
                        ...effectArgs,
                        volumePricing: {
                          ...volumePricing,
                          tiers: volumePricing.tiers.map(
                            (t: VolumePricingTier, i: number) =>
                              i === index
                                ? { ...t, priceInCents: e.target.value }
                                : t
                          ),
                        },
                      }),
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Quantity"
                  value={tier.quantity || ''}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      effectArgsJson: JSON.stringify({
                        ...effectArgs,
                        volumePricing: {
                          ...volumePricing,
                          tiers: volumePricing.tiers.map(
                            (t: VolumePricingTier, i: number) =>
                              i === index
                                ? { ...t, quantity: e.target.value }
                                : t
                          ),
                        },
                      }),
                    })
                  }
                  fullWidth
                />
              </Grid>
            </React.Fragment>
          ))}
        </>
      );
    default:
      return null;
  }
};
