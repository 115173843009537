import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CropMetadataInput,
  PhotoType,
  PhotoTypes,
  RejectionReason,
  SubmissionItemTransitions,
  SubmissionType,
} from 'generated/legacy/graphql';
import { AppDispatch } from 'state/store';
import { NavDirection } from 'utils/types';
import PrismPhotoReview from 'components/PrismPhotoReview';
import History from 'components/PrismHistory';
import { FileInput } from 'styles/inputs.css';

import {
  navigateToView,
  selectAggregateState,
  selectCurrentView,
  selectCurrentViewRejectionReasons,
  selectIsEditing,
  selectIsTransitioningPhoto,
  selectIsUploadingPhoto,
  selectPhotosInView,
  selectPhotoViewNames,
  selectSelectedPhotos,
  setIsEditing,
  transitionPhoto,
  updateSelectedPhoto,
  uploadPhoto,
} from 'pages/Prism/prismSlice';
import { canUpdatePhotoOrView } from 'pages/Prism/utils';
import MovePhotoModal from 'pages/Prism/ReviewPanel/MovePhotoModal';
import {
  Container,
  EmptyText,
  Heading,
  Section,
  SectionHeading,
  ImageThumbButton,
  ImageThumbWrap,
  MoveButton,
  OriginalPhoto,
  UploadedImages,
  StatusDot,
} from 'pages/Prism/ReviewPanel/ReviewPanel.css';
import LeftArrowSVG from 'assets/arrow-left.svg?react';
import RightArrowSVG from 'assets/arrow-right.svg?react';
import { Row, NavButton, NavButtons, PhotoNav } from 'styles/layout.css';
import { NON_EDITABLE_AGGREGATE_STATES } from 'types';
import {
  selectCurrentAggregate,
  selectCurrentSubmissionSet,
} from 'pages/OrthoPrism/orthoSlice';

type Props = {
  aggregateRef: string;
  caseRef: string;
};

const ReviewPanel = ({ aggregateRef }: Props) => {
  const [isMoveModalVisible, showMoveModal] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const currentView = useSelector(selectCurrentView);
  const aggregateState = useSelector(selectAggregateState);
  const currentAggregate = useSelector(selectCurrentAggregate);
  const aggregateType = currentAggregate?.aggregateType;
  const { patientRejectionReasons = [] } = aggregateType ?? {};
  const [treatmentViewNames, itiViewNames] = useSelector(selectPhotoViewNames);
  const photosInView = useSelector(selectPhotosInView);
  const selectedPhotos = useSelector(selectSelectedPhotos);
  const isEditing = useSelector(selectIsEditing);
  const isTransitioningPhoto = useSelector(selectIsTransitioningPhoto);
  const isUploadingPhoto = useSelector(selectIsUploadingPhoto);
  const submissionSet = useSelector(selectCurrentSubmissionSet);
  const rejectionReasons: RejectionReason[] = useSelector(
    selectCurrentViewRejectionReasons
  );

  const viewName = currentView?.name!;
  const currentPhoto = selectedPhotos[viewName];
  const currentPhotoHistory = currentPhoto?.stateData?.history || [];
  const currentSubmissionItemHistory =
    currentPhoto?.submissionitemSet?.find(
      (value) => value?.photo?.ref === currentPhoto?.ref
    )?.stateData?.history || [];
  const isLoading = isUploadingPhoto;

  const setEditingState = (editingState: boolean) => {
    if (editingState === isEditing) {
      return;
    }
    dispatch(setIsEditing(editingState));
  };

  const handleSelectPhoto = (photo: PhotoType) => {
    dispatch(updateSelectedPhoto(viewName, photo));
  };

  const handleClickNav = (direction: NavDirection) => {
    dispatch(navigateToView(direction));
  };

  const handleClickUpload = (e: React.MouseEvent<HTMLInputElement>) => {
    if (!canUpdatePhotoOrView(isEditing, isUploadingPhoto)) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleUploadPhoto = (files: FileList | null) => {
    if (!files) {
      return;
    }

    const [file] = Array.from(files);
    dispatch(
      uploadPhoto({
        aggregateRef,
        photoType: viewName as PhotoTypes,
        file,
        refetchAggregate: true,
      })
    );
  };

  const handleTransformPhoto = async (
    file: File | Blob | null,
    transformMeta?: CropMetadataInput
  ) => {
    if (!file) {
      return;
    }

    await dispatch(
      uploadPhoto({
        aggregateRef,
        photoType: viewName as PhotoTypes,
        file,
        prevPhotoRef: currentPhoto?.ref,
        cropMetadata: transformMeta,
        refetchAggregate: false,
      })
    );
  };

  const mlAutoClassificationMatchesView =
    currentView?.name === currentPhoto?.mlClass;

  return (
    <Container>
      <Row>
        <Heading>{currentView?.label}</Heading>
        <PhotoNav>
          <NavButtons>
            <NavButton
              onClick={() => {
                handleClickNav(NavDirection.left);
              }}
              type="button"
            >
              <LeftArrowSVG role="img" />
            </NavButton>
            <NavButton
              onClick={() => {
                handleClickNav(NavDirection.right);
              }}
              type="button"
            >
              <RightArrowSVG role="img" />
            </NavButton>
          </NavButtons>
        </PhotoNav>
      </Row>
      <PrismPhotoReview
        aspectRatio={currentView?.aspectRatio as number}
        isTransitioningPhoto={isTransitioningPhoto}
        isEditing={isEditing}
        isLoading={isLoading}
        onSaveEdits={handleTransformPhoto}
        onSelectPhoto={handleUploadPhoto}
        photo={currentPhoto}
        rejectionReasons={rejectionReasons}
        setEditingState={setEditingState}
        isEditingEnabled={
          !NON_EDITABLE_AGGREGATE_STATES.includes(aggregateState ?? '')
        }
        handleRejection={(reasons) => {
          dispatch(
            transitionPhoto({
              photoRef: currentPhoto?.ref,
              transition: SubmissionItemTransitions.Reject,
              rejectionReasons: reasons,
              rejectionNotes: '',
            })
          );
          handleClickNav(NavDirection.right);
        }}
        handleApproval={() => {
          dispatch(
            transitionPhoto({
              photoRef: currentPhoto?.ref,
              transition: SubmissionItemTransitions.Approve,
            })
          );
          handleClickNav(NavDirection.right);
        }}
        viewName={viewName}
      />
      <Section>
        <SectionHeading data-testid="section-heading">
          Uploaded images
        </SectionHeading>
        {!photosInView?.length ? (
          <EmptyText>No images uploaded</EmptyText>
        ) : (
          <UploadedImages>
            <ImageThumbButton as="label" title="Upload photo">
              <FileInput
                accept="image/*,image/heic"
                onChange={(e) => handleUploadPhoto(e.target.files)}
                onClick={handleClickUpload}
                type="file"
              />
            </ImageThumbButton>
            {photosInView.map((photo) => {
              const { id, photoUrl, prevVersion, stateData } = photo;
              const status = stateData?.data;
              const isSelected = id === currentPhoto?.id;

              return (
                <ImageThumbWrap key={id} data-private>
                  <ImageThumbButton
                    isApproved={status === 'approved'}
                    isRejected={status === 'rejected'}
                    isSelected={isSelected}
                    onClick={() => handleSelectPhoto(photo)}
                    photo={photoUrl!}
                  />
                  {isSelected && (
                    <MoveButton
                      buttonType="secondary"
                      isFullWidth
                      onClick={() => showMoveModal(true)}
                    >
                      Move
                    </MoveButton>
                  )}
                  {prevVersion && (
                    <OriginalPhoto alt="" src={prevVersion.photoUrl!} />
                  )}
                </ImageThumbWrap>
              );
            })}
            <MovePhotoModal
              currentViewName={viewName}
              currentViewLabel={currentView?.label!}
              evaluationId={''} // @TODO add/replace
              isVisible={isMoveModalVisible}
              onClose={() => showMoveModal(false)}
              photoTypes={{ treatmentViewNames, itiViewNames }}
              photoViewMap={selectedPhotos}
              photo={currentPhoto!}
            />
          </UploadedImages>
        )}
      </Section>
      <Section>
        <SectionHeading data-testid="section-heading">
          Image Autoclassification
        </SectionHeading>
        {currentPhoto?.mlClass ? (
          <>
            {mlAutoClassificationMatchesView ? (
              <p>
                <StatusDot status="success" />
                Autoclassification believes this photo is in the correct ABO
                position.
              </p>
            ) : (
              <p>
                <StatusDot status="failure" />
                Autoclassification believes this photo is in the wrong ABO
                position. It identified the image as "{currentPhoto?.mlClass}"
              </p>
            )}
          </>
        ) : (
          <p>
            <StatusDot status="none" /> No autoclassification is available for
            this image
          </p>
        )}
      </Section>

      <Section>
        <SectionHeading data-testid="section-heading">
          Photo history
        </SectionHeading>
        <History
          selectedPhotoType={currentPhoto?.photoType}
          photoSubmissionHistory={currentSubmissionItemHistory}
          patientRejectionReasons={patientRejectionReasons!}
          primaryHistory={currentPhotoHistory}
          submissionSet={submissionSet as SubmissionType[]}
          testId="PhotoHistory"
        />
      </Section>
    </Container>
  );
};

export default ReviewPanel;
