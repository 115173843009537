import { createContext, useContext } from 'react';

export type NotificationVariant = 'error' | 'info' | 'success';

type NotificationContext = {
  showNotification: (message: string, variant?: NotificationVariant) => void;
};

export const NotificationContext = createContext({} as NotificationContext);
export const useNotificationContext = () => useContext(NotificationContext);
