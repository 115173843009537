import {
  AddTreatmentPlanStagingMutationVariables,
  TreatmentPlanningSoftware,
  TreatmentPlanStagingTypes,
} from 'generated/core/graphql';
import api from 'state/api';

type CreationResource = NonNullable<
  AddTreatmentPlanStagingMutationVariables['creationResources']
>;

type CaseType = 'aligner' | 'retainer';

type CreateTreatmentPlanStagingVariables = {
  caseRef: string;
  caseType: CaseType;
  patientId: number;
  software: TreatmentPlanningSoftware;
  lowerScanId: string;
  upperScanId: string;
};

const RESOURCES: Record<
  TreatmentPlanningSoftware,
  Record<CaseType, CreationResource>
> = {
  [TreatmentPlanningSoftware.Vision]: {
    aligner: {
      vision: {
        draftPlanMetadataAwsLocation:
          'materials/vision_aligner_template/planmetadata.json',
        draftReportAwsLocation: 'materials/vision_aligner_template/report.json',
        draftViewAwsLocation: 'materials/vision_aligner_template/viewer.zip',
        draftPlanAwsLocation: 'materials/vision_aligner_template/plan.zip',
      },
    },
    retainer: {
      vision: {
        draftPlanMetadataAwsLocation:
          'materials/vision_retainer_template/planmetadata.json',
        draftReportAwsLocation:
          'materials/vision_retainer_template/report.json',
        draftViewAwsLocation: 'materials/vision_retainer_template/viewer.zip',
        draftPlanAwsLocation: 'materials/vision_retainer_template/plan.zip',
      },
    },
  },
  [TreatmentPlanningSoftware.Archform]: {
    aligner: {
      archform: {
        draftArchformAwsLocation:
          'materials/archform_aligner_template/viewer.archform',
      },
    },
    retainer: {
      archform: {
        draftArchformAwsLocation:
          'materials/archform_retainer_template/viewer.archform',
      },
    },
  },
};

export default () => {
  const [mutation, rest] = api.useAddTreatmentPlanStagingMutation();

  const executor = async ({
    caseRef,
    caseType,
    patientId,
    software,
    lowerScanId,
    upperScanId,
  }: CreateTreatmentPlanStagingVariables) =>
    await mutation({
      patientId,
      caseRef,
      creationResources: RESOURCES[software][caseType],
      scanIds: {
        lowerScanId,
        upperScanId,
      },
      notes: '',
      treatmentPlanStagingType: TreatmentPlanStagingTypes.AlignerStaging,
      software,
    });

  return [executor, rest] as const;
};
