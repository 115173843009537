import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBrandSupportedFeatures } from 'utils/brands';
import { useNotificationContext } from 'core/context/NotificationContext';
import { useIsLoading } from 'state/system';
import * as Sentry from '@sentry/react';

import {
  selectPatient,
  fetchPatient,
  selectActiveCase,
  selectPatientBrandName,
  approveTreatmentPlanActions,
} from 'pages/Patient/patientSlice';
import {
  applyPromotionsToCart,
  selectRefinementCheckoutMessage,
} from 'pages/Promotion/promotionsSlice';

import { AddressSelection } from 'components/AddressSelection/AddressSelection';
import { scanIntervalDaystoNumber } from 'pages/Patient/utils';
import { StyledButton } from 'pages/Patient/Footer.css';
import TotalBox from 'components/TotalBox/TotalBox';
import CouponCodeInputBox from 'pages/Patient/Checkout/CouponCodeInputBox';
import ArrowIcon from 'assets/arrow-right-line.svg?react';

import {
  Label,
  Radio,
  RadioGroup,
} from 'pages/Patient/PatientDetail/DiagnosticMaterials/TreatmentPlanTab/ProReviewForm.css';
import {
  Container,
  CheckoutContainer,
  Message,
  PlaceOrder,
  QuestionHeading,
  QuestionBody,
} from 'pages/Patient/Checkout/Checkout.css';
import { HRDividerLine } from 'styles/layout.css';
import { ScanIntervalDaysOptions } from 'generated/legacy/graphql';
import useGetDiscountedPrice from 'hooks/useGetDiscountedPrice';
import { Discount } from 'generated/core/graphql';
import Dinero from 'dinero.js';
import { defaultItemsByProductType, ProductTypes } from 'types/checkout';
import CatalogItem from 'components/CatalogItem';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAuthContext } from 'context/AuthContext';

const Checkout = ({
  productType = ProductTypes.Refinement,
  onClose,
}: {
  productType: ProductTypes;
  onClose: () => void;
}) => {
  const {
    'display-tariffs-headsup': displayTariffsHeadsupByFeatureFlag,
    'enable-create-cases-in-core': updateCaseInCore,
  } = useFlags();
  const { userInfo, currentPracticeLoyaltyTier } = useAuthContext();
  const scanIntervalPreference =
    userInfo?.accountPreferences?.doctor?.scanIntervalDays;
  const isApplyingPromotions = useIsLoading(applyPromotionsToCart.typePrefix);

  const brandName = useSelector(selectPatientBrandName);
  const checkoutMessage = useSelector(selectRefinementCheckoutMessage);
  const activeCase = useSelector(selectActiveCase);
  const patient = useSelector(selectPatient);
  const dispatch = useDispatch();

  const { showNotification } = useNotificationContext();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [scanIntervalDays, setScanIntervalDays] =
    useState<ScanIntervalDaysOptions>(
      scanIntervalPreference || ScanIntervalDaysOptions.Fourteen
    );
  const [couponCode, setCouponCode] = useState('');

  const [selectedShippingAddress, setSelectedShippingAddress] = useState<any>();
  const [sendPatientUpdate, setSendPatientUpdate] = useState<boolean>(false);
  const { cart, couponError, appliedCouponCode } = useGetDiscountedPrice({
    productType,
    couponCode,
    patientId: patient?.id ?? '',
    practiceId: patient?.practice?.id ?? '',
  });

  const totalBeforeDiscounts = useMemo(() => {
    const totalDinero = cart.reduce(
      (acc, item) => acc.add(item.totalPriceBeforeDiscounts),
      Dinero({ amount: 0 })
    );
    return totalDinero;
  }, [cart]);

  const totalAfterDiscounts = useMemo(() => {
    const totalDinero = cart.reduce(
      (acc, item) => acc.add(item.totalPriceAfterDiscounts),
      Dinero({ amount: 0 })
    );
    return totalDinero;
  }, [cart]);

  const getLoyaltyDiscount = (sku: string | undefined) => {
    if (!currentPracticeLoyaltyTier || !sku) {
      return 0;
    }
    const productDiscountList =
      currentPracticeLoyaltyTier?.loyaltyTierProducts.filter(
        (product) => product.sku === sku
      );

    if (productDiscountList.length === 0) {
      return 0;
    }

    return productDiscountList[0].discount;
  };

  const loyaltyDiscount = useMemo(() => {
    const discountAmount = getLoyaltyDiscount(
      defaultItemsByProductType(brandName)[productType].sku
    );
    const loyaltyDiscount: Discount = {
      couponCode: '',
      description: `Loyalty discount at ${currentPracticeLoyaltyTier?.name} tier for ${productType.toLocaleLowerCase()} product `,
      reductionInCents: discountAmount,
    };
    return loyaltyDiscount;
  }, [currentPracticeLoyaltyTier]);

  const {
    DisplayPricesToProviders: displayPrices,
    DisplayZoomDisclaimer: displayZoomDisclaimer,
    DisplayTariffsHeadsup: displayTariffsHeadsupByBrand,
  } = getBrandSupportedFeatures(brandName);

  const displayTariffsHeadsup =
    displayTariffsHeadsupByFeatureFlag && displayTariffsHeadsupByBrand;

  const addressFormSubmitBtnRef = React.createRef<HTMLButtonElement>();

  const postOrderSubmit = () => {
    showNotification('Order confirmed', 'success');
    if (patient?.id) {
      dispatch(fetchPatient(patient?.id));
    }
    onClose();
  };

  const prepareShippingAddress = (otherAddress?: any) => {
    if (otherAddress) {
      return {
        address: {
          ...otherAddress,
          firstName: patient?.firstName,
          lastName: patient?.lastName,
        },
        addressType: 'other',
      };
    }
    return {
      address: selectedShippingAddress.value,
      addressType: selectedShippingAddress.addressType,
    };
  };

  const onChangeScanInterval = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScanIntervalDays(event.target.value as ScanIntervalDaysOptions);
  };

  const submitOrder = async (otherAddress?: any) => {
    if (!activeCase?.caseRef || !patient?.id) {
      Sentry.captureException(
        'Missing case ref or patient id when submitting the order'
      );
      showNotification(
        'There was an issue submitting your order, please refresh and try again.',
        'error'
      );
      return;
    }
    if (!cart.length) {
      Sentry.captureException('No order items found when submitting the order');
      showNotification(
        'There was an issue submitting your order, please refresh and try again.',
        'error'
      );
      return;
    }
    setIsSubmitting(true);
    const shippingInfo = prepareShippingAddress(otherAddress);
    const orderInput = {
      caseRef: activeCase?.caseRef,
      customerId: patient?.id,
      shippingAddress: shippingInfo.address,
      shippingAddressType: shippingInfo.addressType,
      sendPatientUpdate,
      clientCouponCode: appliedCouponCode,
      scanIntervalDays: scanIntervalDaystoNumber(scanIntervalDays),
      updateCaseInCore,
    };

    const result = await dispatch(approveTreatmentPlanActions(orderInput));
    setIsSubmitting(false);
    if ((result as any)?.error) {
      Sentry.captureException((result as any)?.error);
      const errorMessage = (result as any)?.error.message ?? '';
      showNotification(
        `Problem submitting order. ${errorMessage} Please try again or contact customer support`,
        'error'
      );
    } else {
      postOrderSubmit();
    }
  };
  return (
    <Container>
      <CheckoutContainer>
        <h1>Review & submit</h1>
        {cart.map((item, i) => {
          return (
            <CatalogItem
              item={item}
              displayPrices={displayPrices}
              isLoading={isApplyingPromotions}
              key={i}
            />
          );
        })}
        {displayPrices && (
          <TotalBox
            orderItems={cart}
            loyaltyDiscount={loyaltyDiscount}
            supportQuantity={false}
            isLoading={isApplyingPromotions}
            message={checkoutMessage}
            displayZoomDisclaimer={displayZoomDisclaimer}
            displayTariffsHeadsup={displayTariffsHeadsup}
          />
        )}
        <CouponCodeInputBox
          couponCode={couponCode}
          appliedCouponCode={appliedCouponCode}
          couponError={couponError}
          setCouponCode={setCouponCode}
        />
        <HRDividerLine />
        <div>
          <QuestionHeading>Aligner wear schedule</QuestionHeading>
          <QuestionBody>
            Choose how long each aligner step should be worn. This will impact
            when monitoring scans will be sent.
          </QuestionBody>
          <RadioGroup role="radiogroup" style={{ marginTop: 0 }}>
            <Label style={{ marginBottom: '4px' }}>
              <Radio
                type="radio"
                name="scanSchedule"
                value={ScanIntervalDaysOptions.Seven}
                onChange={onChangeScanInterval}
                checked={scanIntervalDays === ScanIntervalDaysOptions.Seven}
              />
              7-day wear schedule
            </Label>
            <Label style={{ marginBottom: '40px' }}>
              <Radio
                type="radio"
                name="scanSchedule"
                value={ScanIntervalDaysOptions.Fourteen}
                onChange={onChangeScanInterval}
                checked={scanIntervalDays === ScanIntervalDaysOptions.Fourteen}
              />
              14-day wear schedule
            </Label>
          </RadioGroup>
          <HRDividerLine />
        </div>

        <QuestionHeading>Where do you want to ship this order?</QuestionHeading>
        <AddressSelection
          setResultAddress={setSelectedShippingAddress}
          setResultSendPatientUpdate={setSendPatientUpdate}
          addressFormSubmitBtnRef={addressFormSubmitBtnRef}
          handleConfirmedAddress={submitOrder}
          patientId={patient?.id}
        />
        <PlaceOrder>
          <Message>
            {displayPrices ? `Total: ${totalAfterDiscounts.toFormat()}` : ''}
          </Message>
          <StyledButton
            disabled={
              isSubmitting ||
              cart.some((item) => item?.quantity === 0) ||
              (brandName != 'glidewell' && totalBeforeDiscounts.isZero())
            }
            onClick={async () => {
              if (addressFormSubmitBtnRef.current) {
                addressFormSubmitBtnRef.current.click();
                return;
              }
              return await submitOrder();
            }}
            buttonType="secondary"
            rightIcon={<ArrowIcon style={{ width: '1rem' }} />}
            isLoading={isSubmitting}
          >
            Place order
          </StyledButton>
        </PlaceOrder>
      </CheckoutContainer>
    </Container>
  );
};

export default Checkout;
