import React from 'react';

import {
  SectionInputLabelGroup,
  Subsection,
  StyledFormikInputWrapper,
  AddressContainer,
} from 'pages/AccountCreation/AccountCreation.css';
import {
  emailValidator,
  requiredValidator,
  validSfAccountId,
} from 'components/FormikForms/utils';
import { useFormikContext } from 'formik';
import {
  CreatePracticeAccountMutationVariables,
  OrganizationTypes,
} from 'generated/legacy/graphql';
import { Tooltip } from 'core/components';
import AddressForm from 'components/AddressForm/AddressForm';
import { AddressFormType } from 'components/AddressForm/types';

export interface IFormikContext {
  values: {
    practice: {
      loyaltyTierId: string;
      address?: {
        countryCode: string;
      };
      name?: string;
      organizationType?: OrganizationTypes;
      loyaltyProgramId?: string;
      partnerOrgName?: string;
      partnerOrganizationExternalId?: string;
      brandName?: string;
    };
  };
  isSubmitting?: boolean;
  isValid?: boolean;
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

interface IBillingSection {
  grandfatheredProgramID: string | undefined;
  dsoProgramID: string | undefined;
}

type AddressSectionProps = {
  submitButtonRef: React.RefObject<HTMLButtonElement>;
  submitForm: (values: CreatePracticeAccountMutationVariables) => void;
};

export const AddressSection = ({
  submitButtonRef,
  submitForm,
}: AddressSectionProps) => {
  return (
    <AddressContainer>
      <StyledFormikInputWrapper
        testId="input-address-name"
        label="Address Name*"
        name="practice.address.name"
        type="text"
        validate={requiredValidator}
      />
      <AddressForm
        hideBusinessName={true}
        addressPath="practice.address."
        getAddressData={(values: CreatePracticeAccountMutationVariables) =>
          values.practice.address ?? {}
        }
        submitForm={submitForm}
        updateAddress={(
          value: AddressFormType,
          originalValues: CreatePracticeAccountMutationVariables
        ) => {
          return {
            ...originalValues,
            practice: {
              ...originalValues.practice,
              address: {
                addressLine1: value.addressLine1 ?? '',
                addressLine2: value.addressLine2 ?? '',
                city: value.city ?? '',
                stateCode: value.stateCode ?? '',
                zip: value.zip ?? '',
                //Smarty street doesn't return theses fields so use the original.
                countryCode: originalValues.practice.address?.countryCode ?? '',
                phone: originalValues.practice.address?.phone ?? '',
                name: originalValues.practice.address?.name ?? '',
              },
            },
          };
        }}
        submitButtonRef={submitButtonRef}
      />
      <StyledFormikInputWrapper
        testId="input-address-phone"
        label="Phone Number*"
        name="practice.address.phone"
        type="text"
        validate={requiredValidator}
      />
    </AddressContainer>
  );
};

export const BillingAccountSection = ({
  grandfatheredProgramID,
  dsoProgramID,
}: IBillingSection) => {
  const {
    values: {
      practice: { loyaltyProgramId },
    },
  }: IFormikContext = useFormikContext();
  // If the Loyalty program is an SMB one, the fee selection should be disabled
  const shouldBeDisabled =
    loyaltyProgramId !== grandfatheredProgramID &&
    loyaltyProgramId !== dsoProgramID &&
    loyaltyProgramId !== undefined;
  return (
    <Subsection>
      <SectionInputLabelGroup>
        <StyledFormikInputWrapper
          testId="input-billing-account-contract-rate"
          label="Aligner Contract Rate* (Value editable when Loyalty Program is a DSO or Grandfathered Program)"
          disabled={shouldBeDisabled}
          name="practice.billingAccount.alignersContractRate"
          validate={requiredValidator}
          type="number"
        />
      </SectionInputLabelGroup>
      <SectionInputLabelGroup>
        <StyledFormikInputWrapper
          testId="input-billing-account-whitening-rate"
          label="Whitening Contract Rate* (Value editable when Loyalty Program is a DSO or Gradfathered Program)"
          name="practice.billingAccount.whiteningContractRate"
          disabled={shouldBeDisabled}
          validate={requiredValidator}
          type="number"
        />
      </SectionInputLabelGroup>
      <SectionInputLabelGroup>
        <StyledFormikInputWrapper
          testId="input-billing-account-contact-name"
          label="Billing Contact Name*"
          name="practice.billingAccount.billingContact.name"
          validate={requiredValidator}
          type="text"
        />
      </SectionInputLabelGroup>
      <SectionInputLabelGroup>
        <StyledFormikInputWrapper
          testId="input-billing-account-contact-email"
          label="Billing Contact Email*"
          name="practice.billingAccount.billingContact.email"
          validate={emailValidator}
          type="text"
        />
      </SectionInputLabelGroup>
      <SectionInputLabelGroup>
        <StyledFormikInputWrapper
          testId="input-billing-account-contact-number"
          label="Primary Contact Number*"
          name="practice.billingAccount.billingContact.primaryContactNumber"
          validate={requiredValidator}
          type="text"
        />
        <StyledFormikInputWrapper
          testId="input-billing-account-parent-salesforce-id"
          label="Parent Account Salesforce ID*"
          name="practice.billingAccount.salesforceParentAccountId"
          validate={validSfAccountId}
          type="text"
        />
        <Tooltip title="You can find the Salesforce ID in the URL Bar when viewing the parent account." />
      </SectionInputLabelGroup>
    </Subsection>
  );
};
