import React from 'react';
import moment from 'moment';

import {
  MuiPickersUtilsProvider,
  DatePickerProps as Props,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
import MomentUtils from '@date-io/moment';

type DatePickerProps = Props;

const DatePicker = (props: DatePickerProps) => {
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <KeyboardDatePicker
        autoOk
        inputVariant="outlined"
        TextFieldComponent={TextField}
        variant="inline"
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
