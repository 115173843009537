import React, { useMemo, useState } from 'react';
import { Column, SortDirection, SortDirectionType } from 'react-virtualized';
import { sortBy as sortByFn } from 'lodash';
import Dinero from 'dinero.js';
import styled from 'styled-components';

import api from 'state/api';
import { VirtualizedTable } from 'components/VirtualizedTable';
import ProductModal from 'pages/ProductVariants/ProductModal';
import { GetProductVariantsQuery } from 'generated/core/graphql';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 16px;
`;

const TableWrapper = styled.div`
  flex: 1;
`;

export type ProductVariantType = GetProductVariantsQuery['productVariants'][0];

const ProductVariants = () => {
  const { data: productVariantsData, isFetching: isFetchingProductVariants } =
    api.useGetProductVariantsQuery({});
  const [sortBy, setSortBy] = useState<string>('brand');
  const [sortDirection, setSortDirection] = useState<SortDirectionType>(
    SortDirection.DESC
  );
  const [selectedProductVariant, setSelectedProductVariant] = useState<
    ProductVariantType | undefined
  >();
  const [showProductModal, setShowProductModal] = useState<boolean>(false);

  const rows = useMemo(() => {
    if (productVariantsData) {
      // Flatten nested product data so we can utilize react-virtualized table functionality easier
      const flatProductVariants = productVariantsData.map((variant) => ({
        ...variant,
        productType: variant.product?.productType,
      }));

      const sortedRows = sortByFn(flatProductVariants, [sortBy]);
      return sortDirection === SortDirection.ASC
        ? sortedRows.reverse()
        : sortedRows;
    }

    return [];
  }, [sortBy, sortDirection, productVariantsData]);

  if (isFetchingProductVariants || productVariantsData?.length === 0) {
    return null;
  }
  return (
    <PageWrapper>
      <h2>Product Variants</h2>
      <TableWrapper>
        <VirtualizedTable
          rows={rows}
          rowCount={rows?.length}
          headerHeight={50}
          rowHeight={50}
          loadMoreRows={() => {}}
          loading={isFetchingProductVariants}
          rowStyle={{ cursor: 'pointer' }}
          sort={({
            sortBy,
            sortDirection,
          }: {
            sortBy: string;
            sortDirection: SortDirectionType;
          }) => {
            setSortBy(sortBy);
            setSortDirection(sortDirection);
          }}
          onRowClick={({ rowData }: { rowData: ProductVariantType }) => {
            setSelectedProductVariant(rowData);
            setShowProductModal(true);
          }}
          sortBy={sortBy}
          sortDirection={sortDirection}
        >
          <Column label="brand" dataKey="brand" width={1} flexGrow={1} />
          <Column
            label="Product Type"
            dataKey="productType"
            width={1}
            flexGrow={1}
          />
          <Column label="Sku" dataKey="sku" width={1} flexGrow={1} />
          <Column label="label" dataKey="label" width={1} flexGrow={1} />
          <Column
            label="fulfillment"
            dataKey="fulfillment"
            width={1}
            flexGrow={1}
          />
          <Column
            label="Default Price"
            dataKey="defaultPriceInCents"
            width={1}
            flexGrow={1}
            cellDataGetter={({ rowData }) =>
              Dinero({
                amount: rowData?.defaultPriceInCents,
                currency: 'USD',
              }).toFormat('$0,0.00')
            }
          />
          <Column
            label="description"
            dataKey="description"
            width={1}
            flexGrow={1}
          />
        </VirtualizedTable>
      </TableWrapper>
      <ProductModal
        isOpen={showProductModal}
        onClose={() => {
          setShowProductModal(false);
        }}
        productVariantData={selectedProductVariant}
      />
    </PageWrapper>
  );
};

export default ProductVariants;
