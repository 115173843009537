import React, { useState } from 'react';
import {
  Container,
  ContentContainer,
  Stroke,
  AlertHeading,
  LinkItem,
  LinksContainer,
  LinkContainer,
  IconContainer,
  CloseButtonContainer,
  Content,
  Body,
} from 'core/components/AlertCard/AlertCard.css';

import WarningIcon from 'core/assets/icons/alert-icon-warning.svg?react';
import CriticalIcon from 'core/assets/icons/alert-icon-critical.svg?react';
import SuccessIcon from 'core/assets/icons/alert-icon-success.svg?react';
import CloseSVG from 'core/assets/icons/close-alt.svg?react';

const ALERT_TYPES = [
  'critical',
  'warning',
  'success',
  'default',
  'info',
  'action',
] as const;

export type alertType = (typeof ALERT_TYPES)[number];

type LinkType = {
  linkText: string;
  url: string;
};

type AlertProps = {
  type: alertType;
  children?: React.ReactNode | React.ReactNode[];
  testId?: string;
  header?: string;
  body?: string;
  displayIcon?: boolean;
  links?: LinkType[];
  displayCloseButton?: boolean;
  actionContent?: React.ReactNode;
};

const AlertCard = ({
  type,
  children,
  testId,
  header,
  body,
  displayIcon,
  links,
  displayCloseButton,
  actionContent,
  ...otherProps
}: AlertProps & React.HTMLProps<HTMLButtonElement>) => {
  const [displayAlertCard, setDisplayAlertCard] = useState<boolean>(true);

  const onClose = () => {
    setDisplayAlertCard(false);
  };

  if (!displayAlertCard) {
    return null;
  }

  const determineIcon = (type: alertType) => {
    if (type == 'critical') {
      return CriticalIcon;
    } else if (type == 'warning' || type == 'default') {
      return WarningIcon;
    } else if (type == 'success') {
      return SuccessIcon;
    }
  };

  const Icon = determineIcon(type);

  return (
    <Container
      type={type}
      data-testid={testId}
      {...(otherProps as Record<string, unknown>)}
    >
      <Stroke type={type} {...(otherProps as Record<string, unknown>)} />
      <ContentContainer>
        {displayIcon && (
          <IconContainer>
            <Icon />
          </IconContainer>
        )}

        <Content>
          {header && <AlertHeading>{header}</AlertHeading>}
          {body && <Body>{body}</Body>}
          {links && (
            <LinksContainer>
              {links?.map((link: LinkType) => {
                return (
                  <LinkContainer>
                    <LinkItem href={link.url}>{link.linkText}</LinkItem>
                  </LinkContainer>
                );
              })}
            </LinksContainer>
          )}
          {children}
        </Content>
        {displayCloseButton && (
          <CloseButtonContainer onClick={onClose}>
            <CloseSVG role="img" />
          </CloseButtonContainer>
        )}
        {actionContent}
      </ContentContainer>
    </Container>
  );
};

export default AlertCard;
