import React from 'react';
import moment from 'moment';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { Grid, Table, Heading, Button, colors, Text } from 'core/components';
import { LoyaltyProgram } from 'generated/core/graphql';
import { PageContainer } from 'pages/LoyaltyProgram/shared';
import PlusIcon from 'assets/ic_plus.svg?react';
const StyledPlusIcon = styled(PlusIcon)`
  path {
    stroke: red;
  }
`;

const columns = [
  { name: 'name', label: 'Loyalty Program' },
  { name: 'product', label: 'Product' },
  { name: 'createdAt', label: 'Created on' },
  {
    name: 'id',
    label: 'Actions',
    options: {
      customBodyRender: (value: string) => {
        return (
          <Link to={`/loyalty-program/view/${value}`}>
            <Button buttonType="secondary-outline" buttonSize="small">
              View
            </Button>
          </Link>
        );
      },
    },
  },
  {
    name: 'archived',
    label: 'Active',
    options: {
      customBodyRender: (archived: {
        archivedAt: string | null;
        archivedBy: string | null;
      }) => {
        if (archived.archivedAt) {
          const archivedMessage = `Inactive (archived on ${moment(archived.archivedAt).format('MM/DD/YYYY')} by User#${archived.archivedBy})`;
          return <Text color={colors.red90}>{archivedMessage}</Text>;
        } else {
          return <Text color={colors.green90}>Active</Text>;
        }
      },
    },
  },
];

const Home = ({
  loyaltyPrograms = [],
}: {
  loyaltyPrograms: LoyaltyProgram[];
}) => {
  const mappedData = loyaltyPrograms.map((program: LoyaltyProgram) => {
    return {
      id: program.id,
      name: program.name,
      product: 'Clear Aligner',
      createdAt: moment(program.createdAt).format('MM/DD/YYYY'),
      archived: {
        archivedAt: program.archivedAt,
        archivedBy: program.archivedBy,
      },
    };
  });
  return (
    <PageContainer container direction="column">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        style={{ padding: '0 1rem' }}
      >
        <Grid item>
          <Heading variant={'h4'}>Pricing Rule Setup</Heading>
        </Grid>
        <Grid item>
          <Link to={`/loyalty-program/create`}>
            <Button buttonType="secondary" leftIcon={<StyledPlusIcon />}>
              Create Loyalty program
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid item>
        <Table
          data={mappedData}
          columns={columns}
          options={{
            selectableRows: 'none',
            fixedHeader: false,
            pagination: false,
          }}
        />
      </Grid>
    </PageContainer>
  );
};

export default Home;
