import React from 'react';
import { Modal, Button, Divider } from 'core/components';
import { getAddressInputFromAddressForm } from 'components/AddressForm/utils';
import { AddressSelection } from 'components/AddressSelection/AddressSelection';
import {
  AddressContainer,
  ModalActions,
  ModalBody,
  ModalTitle,
} from 'pages/OrdersAdmin/Modals.css';
import { AddressInput, OrderType } from 'generated/core/graphql';
import { SelectedAddress } from 'components/AddressForm/types';

type UpdateOrderModalProps = {
  editingOrder: OrderType | null;
  setEditingOrder: (order: OrderType | null) => void;
  setJustModifiedOrderRef: (orderRef: string | null) => void;
  setResultAddress: (address: AddressInput | null | undefined) => void;
  addressFormSubmitBtnRef: React.RefObject<HTMLButtonElement>;
  handleConfirmedAddress: (address: SelectedAddress) => void;
  setAddressFormIsValid: (isValid: boolean) => void;
  addressFormIsValid: boolean;
  resultAddress: AddressInput | null | undefined;
  updateOrderAddress: (address: AddressInput) => void;
};

const UpdateOrderModal = ({
  editingOrder,
  setEditingOrder,
  setJustModifiedOrderRef,
  setResultAddress,
  addressFormSubmitBtnRef,
  handleConfirmedAddress,
  setAddressFormIsValid,
  addressFormIsValid,
  resultAddress,
  updateOrderAddress,
}: UpdateOrderModalProps) => {
  return (
    <Modal
      isOpen={!!editingOrder}
      onClose={() => {
        setJustModifiedOrderRef(null);
        setEditingOrder(null);
      }}
      closeOnOverlayClick
    >
      <ModalTitle>
        <h3>Update address</h3>
      </ModalTitle>
      <Divider />
      <ModalBody>
        <AddressContainer>
          <p>
            Current address for order <b>{editingOrder?.shortOrderRef}</b>:
          </p>
          {editingOrder?.shippingAddress?.name && (
            <b>{editingOrder?.shippingAddress?.name}</b>
          )}
          {editingOrder?.shippingAddress?.company && (
            <b>{editingOrder?.shippingAddress?.company}</b>
          )}
          <b>{editingOrder?.shippingAddress?.addressLines.join(', ')}</b>
          <b>
            {editingOrder?.shippingAddress?.city},{' '}
            {editingOrder?.shippingAddress?.adminRegion}{' '}
            {editingOrder?.shippingAddress?.postalCode}
          </b>
        </AddressContainer>
        {editingOrder?.patientId ? (
          <AddressSelection
            patientId={editingOrder?.patientId?.toString()}
            practiceId={editingOrder?.practiceId.toString()}
            setResultAddress={(address) =>
              setResultAddress(getAddressInputFromAddressForm(address.value))
            }
            addressFormSubmitBtnRef={addressFormSubmitBtnRef}
            handleConfirmedAddress={handleConfirmedAddress}
            setAddressFormIsValid={setAddressFormIsValid}
            allowEditPatientAddress={false}
            key={editingOrder?.shortOrderRef}
          />
        ) : (
          <AddressSelection
            practiceId={editingOrder?.practiceId.toString()}
            setResultAddress={(address) =>
              setResultAddress(getAddressInputFromAddressForm(address.value))
            }
            addressFormSubmitBtnRef={addressFormSubmitBtnRef}
            handleConfirmedAddress={handleConfirmedAddress}
            setAddressFormIsValid={setAddressFormIsValid}
            key={editingOrder?.shortOrderRef}
          />
        )}
      </ModalBody>
      <Divider />
      <ModalActions>
        <Button
          onClick={() => setEditingOrder(null)}
          type="button"
          buttonType="tertiary"
        >
          Cancel
        </Button>
        <Button
          disabled={!addressFormIsValid}
          onClick={() => {
            if (addressFormSubmitBtnRef.current) {
              addressFormSubmitBtnRef.current.click();
              return;
            }
            if (resultAddress) {
              updateOrderAddress(resultAddress);
            }
          }}
          type="button"
          buttonType="secondary"
        >
          Update
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default UpdateOrderModal;
