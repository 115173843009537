import React, { useEffect, useState } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useNotificationContext } from 'core/context/NotificationContext';

import NotFound from 'pages/NotFound';
import {
  GetFormsQuery,
  GetFormsQueryVariables,
  GetFormsDocument,
  Queries,
} from 'generated/core/graphql';
import { useLazyQuery } from 'utils/lazyQuery';
import { coreClient } from 'gql/clients';

import { IntakeFormsProvider } from 'pages/IntakeForms/IntakeFormsContext';
import IntakeFormsSection from 'pages/IntakeForms/IntakeFormsSection';
import IntakeFormsOverview from 'pages/IntakeForms/IntakeFormsOverview';

const IntakeForms = ({ match }: RouteComponentProps) => {
  const [formsData, setFormsData] = useState<Queries['getForms']>();
  const { showNotification } = useNotificationContext();

  const getAllIntakeForms = useLazyQuery<GetFormsQuery, GetFormsQueryVariables>(
    GetFormsDocument,
    coreClient
  );

  const getFormsData = async (returnError?: boolean) => {
    try {
      const {
        data: { getForms },
      } = await getAllIntakeForms({});
      setFormsData(getForms as Queries['getForms']);
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      if (returnError) {
        throw err;
      }

      showNotification(err.message, 'error');
    }
  };

  useEffect(() => {
    getFormsData();
  }, []);
  return (
    <IntakeFormsProvider
      value={{
        formsData,
        refreshData: getFormsData,
      }}
    >
      <Switch>
        <Route component={IntakeFormsOverview} exact path={match.path} />
        <Route
          component={IntakeFormsSection}
          exact
          path={`${match.path}/:formName/:sectionIdx/:formType`}
        />
        <Route component={NotFound} />
      </Switch>
    </IntakeFormsProvider>
  );
};

export default IntakeForms;
