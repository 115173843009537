enum PracticeAccessLevel {
  MEMBER = 0,
  VIEWER = 10,
  EDITOR = 20,
  SUPER_ADMIN = 100,
}

export const useHasCustomerAccess = (
  userAccessLevel: number | null | undefined,
  minAccessLevel: PracticeAccessLevel = PracticeAccessLevel.SUPER_ADMIN
) => (userAccessLevel ?? 0) >= minAccessLevel;
