import React, { useMemo, useEffect, useState } from 'react';
import moment from 'moment';
import { MUIDataTableOptions } from 'mui-datatables';
import { Table, Box, Loading, Button } from 'core/components';
import { Grid } from '@material-ui/core';
import styled from 'styled-components/macro';
import DocumentTitle from 'components/DocumentTitle';
import api from 'state/api';
import { CaseTypeNames } from 'types/Case';
import CreateBaseTemplateModal from 'components/Modals/CreateTemplateModal/CreateBaseTemplateModal';
import { TemplateType } from 'generated/core/graphql';

const StyledTable = styled(Table)<{ loading: boolean }>`
  tr {
    cursor: pointer;
  }
  opacity: ${({ loading }) => (loading ? 0.3 : 1)};
`;

const StyledLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const TemplatesListView = () => {
  const [createTemplatenModalOpen, setCreateTemplatenModalOpen] =
    useState(false);
  const [
    getSystemTemplates,
    { data: systemTemplates = [], isLoading: isFetchingTemplates },
  ] = api.useLazyGetSystemTemplatesQuery({});
  useEffect(() => {
    getSystemTemplates({
      caseType: CaseTypeNames.ALIGNER,
      type: TemplateType.CaseTemplate,
    });
  }, []);
  const mappedTemplates = useMemo(() => {
    if (!systemTemplates) {
      return [];
    }
    return systemTemplates.map(
      ({
        id,
        name,
        updatedAt,
        createdAt,
        description,
        groupStructure,
        type,
      }) => ({
        id,
        name,
        updatedAt: moment(updatedAt).format('MM/DD/YYYY'),
        createdAt: moment(createdAt).format('MM/DD/YYYY'),
        description,
        questions: groupStructure?.orderInGroup
          ? Object.values(groupStructure.orderInGroup)
              .flat()
              .map((q) => q)
          : [],
        type,
      })
    );
  }, [systemTemplates]);

  const columns = [
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated At',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];
  const options: MUIDataTableOptions = {
    selectableRows: 'none',
    fixedHeader: true,
    filter: false,
    serverSide: true,
    count: mappedTemplates.length,
    tableBodyMaxHeight: 'calc(80vh)',
    filterType: 'textField',
    onRowClick: (_rowData, _rowMeta) => {},
  };

  if (isFetchingTemplates) {
    return <Loading isCentered />;
  }

  // TODO:
  // - Add a button to create a new template
  // - Add a button to soft delete a template
  // - Add filters for template type
  // - Add filters for case type

  return (
    <DocumentTitle title="Template Tool - List View">
      <Box py={['1rem']} m={['auto']}>
        <Box display="flex" flexDirection="column">
          {mappedTemplates && isFetchingTemplates && (
            <StyledLoader>
              <Loading />
            </StyledLoader>
          )}
          <Grid container spacing={2} direction="row" justifyContent="flex-end">
            <Grid
              item
              style={{
                marginRight: '1rem',
              }}
            ></Grid>
          </Grid>
          <Button
            buttonType="secondary"
            onClick={() => {
              setCreateTemplatenModalOpen(true);
            }}
          >
            Create Template
          </Button>
          <CreateBaseTemplateModal
            isOpen={createTemplatenModalOpen}
            onClose={() => setCreateTemplatenModalOpen(false)}
          />
          <StyledTable
            title="Templates"
            data={mappedTemplates}
            columns={columns}
            options={options}
            loading={isFetchingTemplates}
          />
        </Box>
      </Box>
    </DocumentTitle>
  );
};

export default TemplatesListView;
