type ResultFn<TArgs extends unknown[], TEntry> = (...args: TArgs) => TEntry;

function isEqualArgs(args1: unknown[] | null, args2: unknown[]): boolean {
  if (!args1) {
    return false;
  }

  const _args1_ = args1.length;
  if (_args1_ !== args2.length) {
    return false;
  }

  for (let i = 0; i < _args1_; ++i) {
    if (args1[i] !== args2[i]) {
      return false;
    }
  }

  return true;
}

/**
 * Memoizes a function  caches the result of the last call.
 *
 * @param fn Memoized function
 * @returns
 */
export function memoizeOne<TArgs extends unknown[], TEntry>(
  fn: ResultFn<TArgs, TEntry>
): ResultFn<TArgs, TEntry> {
  let lastArgs: unknown[] | null = null;
  let lastResult: TEntry;

  return (...args: TArgs): TEntry =>
    isEqualArgs(lastArgs, args)
      ? lastResult
      : (lastArgs = args) && (lastResult = fn(...args));
}
