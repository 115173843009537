import React, { useEffect, useState } from 'react';
import {
  FooterContainer,
  StyledButton,
} from 'pages/PracticeAccountCreator/PracticeAccountCreator.css';
import { CreatePracticeAccountMutationVariables } from 'generated/legacy/graphql';
import { useFormikContext } from 'formik';

const Footer = () => {
  const [disabled, setDisabled] = useState(true);
  const {
    isValid,
    dirty,
    handleSubmit: rootSubmit,
  } = useFormikContext<CreatePracticeAccountMutationVariables>();

  useEffect(() => {
    if (isValid && dirty) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [isValid, dirty]);
  return (
    <>
      <FooterContainer>
        <StyledButton
          disabled={disabled}
          onClick={() => rootSubmit()}
          buttonType="secondary"
          isLoading={false}
          type="submit"
        >
          Create practice
        </StyledButton>
      </FooterContainer>
    </>
  );
};

export default Footer;
