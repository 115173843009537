import React, { useMemo, useCallback, useState } from 'react';
import { ValueType } from 'react-select';
import { useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { Button, SelectInput } from 'core/components';
import { AUTH_GROUPS } from 'types';

import { RejectionReason } from 'pages/OrthoPrism/types';
import * as TreatmentPlans from 'pages/OrthoPrism/treatmentPlans';
import * as TreatmentPlanStagings from 'pages/OrthoPrism/treatmentPlanStagings';
import { selectSelectedCase } from 'pages/OrthoPrism/orthoSlice';

import Reasons from 'pages/OrthoPrism/Plan/TreatmentReviewForm/Reasons';
import Notes from 'pages/OrthoPrism/Plan/TreatmentReviewForm/Notes';
import RejectionDetails from 'pages/OrthoPrism/Plan/TreatmentReviewForm/RejectionDetails';
import { RejectionDetailProblem } from 'pages/OrthoPrism/Plan/utils';

import {
  FormRow,
  Label,
  MainReasons,
  OtherReasons,
  QuestionHeading,
  Radio,
  RadioGroup,
  ReasonOptions,
  SmallHeading,
  ButtonRow,
} from 'pages/OrthoPrism/Plan/TreatmentReviewForm/TreatmentReviewForm.css';

import { Nullable } from 'utils/types';
import { MaterialEvaluationTypes } from 'generated/core/graphql';

const reasonToOption = (r: RejectionReason) => ({
  label: r.label,
  value: r.name,
});

type ReasonOption = {
  label: string;
  value: string;
};

type Props = {
  rejectionReasons: RejectionReason[];
  isSubmitting: boolean;
  onSubmitTreatmentPlanStaging?: (
    args: TreatmentPlanStagings.TreatmentPlanStagingSubmitCallbackArgs
  ) => void;
  onClickLaunchDFAViewer?: () => void;
  dfaViewerButtonVisible?: boolean;
};

const OrthoForm = ({
  rejectionReasons,
  isSubmitting,
  onSubmitTreatmentPlanStaging = () => {},
  onClickLaunchDFAViewer = () => {},
  dfaViewerButtonVisible = false,
}: Props) => {
  /*
     The Ortho form offers the following top level review choices:

     - Accept
     - Reject
     - Missing Information

     Each of the values for these options is a valid state transition name and is
     used as the `transition` argument for the treatment pan state transition.

     If the Ortho chooses Reject, they are presented with a select menu of available
     top level reasons.

     Unless they select `Missing Information`, which then sets the topLevelReason
     to `missing_information` and they skip the drop down. They are still presented
     with the sub-reason checklist though.
   */
  const [transition, setTransition] = useState<
    TreatmentPlans.ReviewOption | undefined
  >();
  const isAccepted = TreatmentPlans.isAcceptOption(transition);
  const isRejected = TreatmentPlans.isRejectionOption(transition);

  const { pathname } = useLocation();
  const isQc: boolean = useMemo(
    () => pathname.includes('tp-quality-control'),
    [pathname]
  );

  const [topLevelReasons, missingInformation] = useMemo(() => {
    const missing = rejectionReasons.find(
      (r) => r.name === TreatmentPlans.ReviewOption.MissingInformation
    );
    const rest = rejectionReasons.filter(
      (r) => r.name !== TreatmentPlans.ReviewOption.MissingInformation
    );

    return [rest, missing];
  }, [rejectionReasons]);

  const reasonOptions = topLevelReasons.map(reasonToOption);

  const onChangeTransition = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if (!TreatmentPlans.isReviewOption(value)) {
        console.error(
          `Unrecognized treatment plan review option ${e.target.value}`
        );
        return;
      }

      setTransition(value);

      // clear out subselections on changing transition
      setSelectedSubReasons({});

      if (
        value === TreatmentPlans.ReviewOption.MissingInformation &&
        missingInformation
      ) {
        setTopLevelReason(missingInformation);
      } else {
        // clear this out so we don't accidentally post rejection reasons
        // with acceptances
        setTopLevelReason(null);
      }
    },
    []
  );

  // The selected top level reason, can be set in the dropdown or in the top level radio
  // list
  const [topLevelReason, setTopLevelReason] =
    useState<Nullable<RejectionReason>>(null);
  const onSelectTopLevelRejectionReason = useCallback(
    (o: ValueType<ReasonOption>) => {
      const option = Array.isArray(o) ? o[0] : o; // react select's abstractions leaking thru
      const selected = topLevelReasons.find((r) => r.name === option?.value);
      if (!selected) {
        setTopLevelReason(null);
        return;
      }
      // clear out subselections on changing the top level reason
      setSelectedSubReasons({});
      setTopLevelReason(selected);
    },
    []
  );

  // we only render the dropdown of top level reasons if the Ortho chooses "reject"
  const TopLevelReasonSelector: React.ReactElement | false = !isQc &&
    TreatmentPlans.isRejectionOption(transition) && (
      <FormRow>
        <SmallHeading>Patient rejection reason</SmallHeading>
        <SelectInput
          onChange={onSelectTopLevelRejectionReason}
          options={reasonOptions}
        />
      </FormRow>
    );

  // populate the sub-reason checkboxes; separate out the "other" category, if there is one.
  const mainRejectionCategories = TreatmentPlans.mainRejectionCategories(
    topLevelReason?.reasons
  );
  const otherRejectionCategory = TreatmentPlans.otherRejectionCategory(
    topLevelReason?.reasons
  );

  // track selected sub-rejection reason names
  const [selectedSubReasons, setSelectedSubReasons] = useState<
    Record<string, boolean>
  >({});
  const onToggleReason = useCallback(
    (reason: RejectionReason, selected: boolean) => {
      setSelectedSubReasons({
        ...selectedSubReasons,
        [reason.name]: selected,
      });
    },
    [selectedSubReasons]
  );

  const [notes, setNotes] = useState<string | undefined>(undefined);
  const onNotesChange = setNotes;

  const submitArgs = useMemo(() => {
    // grab all the keys (reason names) for entries with values of `true`
    const subReasons = Object.entries(selectedSubReasons)
      .filter(([_k, v]) => v)
      .map(([k]) => k);

    return {
      transition,
      notes: notes,
      reason: topLevelReason?.name ?? undefined,
      subReasons: subReasons.length ? subReasons : undefined,
    };
  }, [transition, selectedSubReasons, topLevelReason, notes]);

  // Qc Rejection details/problems
  const [problems, setProblems] = useState<Array<RejectionDetailProblem>>([
    {
      id: '1',
      isEditing: true,
      topic: null,
      photos: [],
      notes: '',
    },
  ]);

  const handleAddRemoveProblems = useCallback(
    (type: 'add' | 'remove', problemId?: string): void => {
      setProblems((oldProblems) => {
        let newProblems: typeof oldProblems;
        if (type === 'add') {
          // Add a new problem
          newProblems = [
            // Close the other open problem items
            ...oldProblems.map((p) => ({ ...p, isEditing: false })),
            {
              // Create temporary ID so that new problem can be tracked easily locally
              id: (oldProblems.length + 1).toString(),
              isEditing: true,
              topic: null,
              photos: [],
              notes: '',
            },
          ];
        } else {
          // Remove a problem
          newProblems = oldProblems.filter((o) => o.id !== problemId);
        }
        return newProblems;
      });
    },
    []
  );

  let originalEditedProblem: any;
  const handleEditProblem = useCallback(
    <F extends keyof RejectionDetailProblem>(
      problemId: string,
      field: F,
      value: RejectionDetailProblem[F]
    ): void => {
      setProblems((oldProblems) => {
        // Create new reference
        // If changing isEditing to true, close all other problems
        const newProblems =
          field === 'isEditing' && value === true
            ? oldProblems.map((p) => ({ ...p, isEditing: false }))
            : [...oldProblems];
        // Find the problem to edit
        const problemToEdit = newProblems.find((p) => p.id === problemId);

        if (!originalEditedProblem) {
          originalEditedProblem = { ...problemToEdit };
        }

        // //If the editing problem has changed revert it
        if (
          problemToEdit &&
          originalEditedProblem &&
          originalEditedProblem.id !== problemToEdit.id
        ) {
          originalEditedProblem = { ...problemToEdit };
        }
        if (problemToEdit) {
          // Change the passed in field on the problemToEdit to the passed in value
          problemToEdit[field] = value;
        }
        return newProblems;
      });
    },
    []
  );

  const handleProblemDone = useCallback((type: 'save' | 'cancel'): void => {
    setProblems((oldProblems) => {
      //Handle the case where an element was newly added but cancelled
      if (type === 'cancel') {
        if (!originalEditedProblem) {
          //A fresh problem was created, but not edited. It should be deleted
          const editedProblem = oldProblems.find((p) => p.isEditing)?.id;
          return oldProblems.filter((o) => o.id !== editedProblem);
        } else if (
          originalEditedProblem.notes === '' &&
          originalEditedProblem.photos.length === 0 &&
          originalEditedProblem.topic === null
        ) {
          //A fresh problem was created then edited. It should still be deleted
          return oldProblems.filter((o) => o.id !== originalEditedProblem!.id);
        } else {
          //An existing problem was edited. It should be reverted to it's previous value
          return oldProblems.map((op) => {
            if (op.id === originalEditedProblem!.id) {
              return { ...originalEditedProblem, isEditing: false };
            } else {
              return op;
            }
          });
        }
      } else {
        originalEditedProblem = null;
        return oldProblems.map((p) => ({ ...p, isEditing: false }));
      }
    });
  }, []);

  const validForPro =
    TreatmentPlans.isProAcceptTreatmentPlan(submitArgs) ||
    TreatmentPlans.isRejectTreatmentPlan(submitArgs) ||
    TreatmentPlans.isProRejectTreatmentPlan(submitArgs);

  const validForQc =
    TreatmentPlans.isQcAcceptTreatmentPlan(submitArgs) ||
    (TreatmentPlans.isQcRejectTreatmentPlan(submitArgs) &&
      problems.length > 0 &&
      problems.every(
        (p) => !!p.topic && p.notes.length > 0 && p.photos.length > 0
      ));

  const isValid = isQc ? validForQc : validForPro;
  const canAddProblems =
    problems.length === 0 || problems.every((p) => !p.isEditing);
  const allProblemsValid = problems.every(
    (p) =>
      !p.isEditing ||
      (p.notes !== '' && p.topic !== null && p.photos.length > 0)
  );

  const selectedCase = useSelector(selectSelectedCase);

  const handleSubmit = useCallback(
    async (e: React.SyntheticEvent): Promise<void> => {
      e.preventDefault();
      if (TreatmentPlans.isOrthoSubmissionArgs(submitArgs)) {
        onSubmitTreatmentPlanStaging({
          transition: submitArgs.transition,
          notes: submitArgs?.notes,
          reasons: [
            submitArgs?.reason || '',
            ...(submitArgs?.subReasons || []),
          ],
          problems: problems,
        });
      }
    },
    [submitArgs, isQc]
  );

  const submitButtonLabel = (): string => {
    const destination =
      selectedCase?.workflow?.productionRequirements?.[0].evaluationRequirements.find(
        (req) =>
          req?.evaluationType === MaterialEvaluationTypes.OrthodonticEvaluation
      )
        ? 'Ortho'
        : 'Candid Pro Doctor';
    return `Submit${isAccepted && isQc ? ` to ${destination}` : ''}`;
  };

  // As of right now, treatment_plan_qa should only be able to take action in the QC tool but not ortho review
  const readOnlyPermissionsForActions = isQc
    ? []
    : [AUTH_GROUPS.TREATMENT_PLAN_QA, AUTH_GROUPS.TREATMENT_PLAN_TECH];

  return (
    <form onSubmit={handleSubmit}>
      <FormRow>
        <QuestionHeading>Is the treatment plan acceptable?</QuestionHeading>
        <RadioGroup role="radiogroup">
          <Label>
            <Radio
              type="radio"
              name="acceptance"
              errorText="You do not have permissions to accept a treatment plan"
              onChange={onChangeTransition}
              forbiddenGroups={readOnlyPermissionsForActions}
              value={
                isQc
                  ? TreatmentPlans.ReviewOption.QcAccept
                  : TreatmentPlans.ReviewOption.ProAccept
              }
            />
            Accept
          </Label>
          <Label>
            <Radio
              type="radio"
              name="acceptance"
              errorText="You do not have the permissions to reject a treatment plan"
              onChange={onChangeTransition}
              value={
                isQc
                  ? TreatmentPlans.ReviewOption.QcReject
                  : TreatmentPlans.ReviewOption.Reject
              }
              forbiddenGroups={readOnlyPermissionsForActions}
            />
            Reject
          </Label>
        </RadioGroup>
      </FormRow>
      {TopLevelReasonSelector}
      {mainRejectionCategories && (
        <ReasonOptions>
          <p>Select all that apply</p>
          <MainReasons>
            {mainRejectionCategories.map((reason) => (
              <li key={reason.name}>
                <Reasons
                  category={reason}
                  selections={selectedSubReasons}
                  onChange={onToggleReason}
                />
              </li>
            ))}
          </MainReasons>
          {otherRejectionCategory && (
            <OtherReasons>
              <Reasons
                category={otherRejectionCategory}
                selections={selectedSubReasons}
                onChange={onToggleReason}
              />
            </OtherReasons>
          )}
        </ReasonOptions>
      )}
      {isQc && isRejected && (
        <RejectionDetails
          problems={problems}
          handleAddRemoveProblems={handleAddRemoveProblems}
          handleEditProblem={handleEditProblem}
          handleProblemDone={handleProblemDone}
        />
      )}
      {!isQc && transition && (
        <Notes tpState={transition} onChange={onNotesChange} />
      )}
      <ButtonRow>
        {isQc && isRejected && (
          <Button
            buttonType="secondary-outline"
            isShort
            disabled={!canAddProblems}
            onClick={(e) => {
              e.preventDefault();
              handleAddRemoveProblems('add');
            }}
          >
            Add defect
          </Button>
        )}
        <Button
          buttonType="secondary"
          disabled={
            !isValid ||
            isSubmitting ||
            (isQc && isRejected && !allProblemsValid)
          }
          isLoading={isSubmitting}
          isShort
          type="submit"
        >
          {submitButtonLabel()}
        </Button>
        {dfaViewerButtonVisible && (
          <Button
            buttonType="secondary"
            onClick={() => {
              onClickLaunchDFAViewer();
            }}
            isShort
          >
            Launch 3D Controls
          </Button>
        )}
      </ButtonRow>
    </form>
  );
};

export default OrthoForm;
