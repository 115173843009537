import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { MUIDataTableOptions, MUIDataTableMeta } from 'mui-datatables';
import { Table, Box, Loading, Button } from 'core/components';
import { Grid } from '@material-ui/core';
import styled from 'styled-components/macro';
import { useIsLoading } from 'state/system';
import DocumentTitle from 'components/DocumentTitle';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPromotions,
  Promotion,
  fetchPromotions,
} from 'pages/Promotion/promotionsSlice';
import { PromotionContextProvider } from 'pages/Promotion/Context';
import CreatePromotionModal from 'components/Modals/CreatePromotionModal';
import { useAuthContext } from 'context/AuthContext';
import { ACCESS_GROUPS } from 'types';

const StyledTable = styled(Table)<{ loading: boolean }>`
  tr {
    cursor: pointer;
  }
  opacity: ${({ loading }) => (loading ? 0.3 : 1)};
`;

const StyledLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const PromotionsListView = () => {
  const promotionData = useSelector(selectPromotions);
  const dispatch = useDispatch();
  const { checkHasAccess } = useAuthContext();
  const isAdmin = checkHasAccess(ACCESS_GROUPS.ADMIN);
  const [createPromotionModalOpen, setCreatePromotionModalOpen] =
    useState(false);
  const [selectedPromotion, setSelectedPromotion] = useState<Promotion | null>(
    null
  );
  const isFetchingPromotions = useIsLoading('promotions/fetchPromotions');
  const mappedPromotions = useMemo(() => {
    if (!promotionData) {
      return [];
    }
    return promotionData.map(
      ({
        id,
        name,
        updatedAt,
        createdAt,
        isActive,
        method,
        promoCriteria,
        effects,
        priorityScore,
      }) => ({
        id,
        name,
        updatedAt: moment.utc(updatedAt).format('MM/DD/YYYY'),
        createdAt: moment.utc(createdAt).format('MM/DD/YYYY'),
        status: isActive ? 'Active' : 'Inactive',
        method,
        promoCriteria,
        effects,
        priorityScore,
        isActive,
      })
    );
  }, [promotionData]);

  const columns = [
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated At',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'method',
      label: 'Method',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'actions',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_value: string, tableMeta: MUIDataTableMeta) => {
          return (
            <div>
              <Button
                buttonType="secondary"
                onClick={() => handleViewPromotion(tableMeta.rowIndex)}
              >
                View
              </Button>
            </div>
          );
        },
      },
    },
  ];
  const options: MUIDataTableOptions = {
    selectableRows: 'none',
    fixedHeader: true,
    filter: false,
    serverSide: true,
    count: mappedPromotions.length,
    tableBodyMaxHeight: 'calc(80vh)',
    filterType: 'textField',
    onRowClick: (_rowData, _rowMeta) => {},
  };

  const handleViewPromotion = (index: number) => {
    setSelectedPromotion(mappedPromotions[index]);
    setCreatePromotionModalOpen(true);
  };

  if (isFetchingPromotions) {
    return <Loading isCentered />;
  }

  return (
    <PromotionContextProvider>
      <DocumentTitle title="Promotion Tool - List View">
        <Box py={['1rem']} m={['auto']}>
          <Box display="flex" flexDirection="column">
            {mappedPromotions && isFetchingPromotions && (
              <StyledLoader>
                <Loading />
              </StyledLoader>
            )}
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-end"
            >
              <Grid
                item
                style={{
                  marginRight: '1rem',
                }}
              ></Grid>
            </Grid>
            {isAdmin && (
              <Button
                buttonType="secondary"
                onClick={() => {
                  setSelectedPromotion(null);
                  setCreatePromotionModalOpen(true);
                }}
              >
                Create Promotion
              </Button>
            )}
            <CreatePromotionModal
              isOpen={createPromotionModalOpen}
              onClose={() => {
                setCreatePromotionModalOpen(false);
              }}
              promotion={selectedPromotion}
              refetchPromotions={() => {
                dispatch(fetchPromotions({}));
              }}
            />
            <StyledTable
              title="Promotions"
              data={mappedPromotions}
              columns={columns}
              options={options}
              loading={isFetchingPromotions}
            />
          </Box>
        </Box>
      </DocumentTitle>
    </PromotionContextProvider>
  );
};

export default PromotionsListView;
