import React, { useEffect } from 'react';
import {
  ContactHeaderSection,
  ContactSectionTitle,
  ContactHeader,
  PageBody,
  Title,
  ContactBody,
  PageSection,
} from 'pages/ContactUs/ContactUs.css';
import ContactInfo from 'pages/ContactUs/ContactInfo';
import AccountManagerInfo from 'pages/ContactUs/AccountManagerInfo';
import { getBrandSettings, getBrandFromDomain } from 'utils/brands';
import DocumentTitle from 'components/DocumentTitle';
import { useAuthContext } from 'context/AuthContext';
import api from 'state/api';
import { Skeleton } from 'core/components';
import { useFlags } from 'launchdarkly-react-client-sdk';

const ContactSection = ({
  title,
  children,
  isLoading,
  visible = true,
}: {
  title: string;
  children: React.ReactNode;
  isLoading?: boolean;
  visible?: boolean;
}) => (
  <>
    {isLoading ? (
      <ContactSectionSkeleton />
    ) : (
      visible && (
        <>
          <ContactSectionTitle>{title}</ContactSectionTitle>
          {children}
        </>
      )
    )}
  </>
);

const ContactSectionSkeleton = () => (
  <>
    <Skeleton height={20} width={180} />
    <Skeleton height={20} width={200} />
    <Skeleton height={20} width={200} />
    <Skeleton height={20} width={200} />
  </>
);

const ContactUs = () => {
  const { 'enable-ae-support-contact': enableAccountManagementRepFeatureFlag } =
    useFlags();
  const { userInfo } = useAuthContext();
  const [
    getAccountManagementRep,
    { data: accountManagementRep, isLoading: isLoadingAccountManagementRep },
  ] = api.useLazyGetAccountManagementRepQuery();
  const brand = getBrandFromDomain();
  const {
    caseSupport,
    billingSupport,
    showHelpSubHeading,
    showAccountManagementRep,
  } = getBrandSettings(brand);

  useEffect(() => {
    const accountId = userInfo?.doctor?.practices[0]?.salesforceChildAccountId;
    if (accountId && showAccountManagementRep) {
      getAccountManagementRep({ salesforceChildAccountId: accountId });
    }
  }, [userInfo?.doctor?.id]);

  const renderAccountManagementRep =
    enableAccountManagementRepFeatureFlag && showAccountManagementRep;

  return (
    <DocumentTitle title="Help center">
      <PageSection>
        <PageBody>
          <Title>Contact us</Title>
          <ContactBody>
            <ContactHeaderSection>
              <ContactHeader>We're here to help</ContactHeader>
              <ContactSectionTitle>
                {showHelpSubHeading && renderAccountManagementRep
                  ? 'For general support'
                  : 'Have a technical, clinical, or patient status question? Here are two more ways to connect with our Provider Support Team:'}
              </ContactSectionTitle>
            </ContactHeaderSection>
            <ContactInfo
              email={caseSupport.email}
              phoneNumbers={caseSupport.phoneNumbers ?? []}
              phoneNumbersHours={caseSupport.phoneNumbersHours}
              liveChatHours={caseSupport.liveChatHours}
              contactSupportOptions={caseSupport.contactSupportOptions}
            />
            {billingSupport && (
              <ContactSection title="For billing/finance support">
                <ContactInfo
                  email={billingSupport.email}
                  phoneNumbers={billingSupport.phoneNumbers}
                  phoneNumbersHours={billingSupport.phoneNumbersHours}
                  liveChatHours={billingSupport.liveChatHours}
                  contactSupportOptions={billingSupport.contactSupportOptions}
                />
              </ContactSection>
            )}
            {renderAccountManagementRep && (
              <ContactSection
                title="Your account manager"
                isLoading={isLoadingAccountManagementRep}
                visible={!!accountManagementRep}
              >
                {accountManagementRep && (
                  <AccountManagerInfo
                    accountManagementRep={accountManagementRep}
                  />
                )}
              </ContactSection>
            )}
          </ContactBody>
        </PageBody>
      </PageSection>
    </DocumentTitle>
  );
};

export default ContactUs;
