import React, { useMemo } from 'react';
import {
  Maybe,
  PatientRejectionReason,
  PhotoTypeType,
  RejectionReason,
  StateDataType,
  SubmissionType,
} from 'generated/legacy/graphql';

import config from 'components/PrismHistory/config';

import {
  DateTime,
  Details,
  EmptyText,
  HistoryList,
  Notes,
  NotesText,
  ReasonList,
  ReasonTitle,
} from 'components/PrismHistory/History.css';
import moment from 'moment';
import { PRISM_AGGREGATE_STATES, PRISM_HISTORY_EVENTS } from 'types';
import {
  formatSubmissionHistory,
  countSubmissionAndRejectionEvents,
  StateDataTypeExtra,
} from 'utils/prism';

type Props = {
  primaryHistory: StateDataType[];
  photoSubmissionHistory?: StateDataType[] | undefined;
  patientRejectionReasons: PatientRejectionReason[];
  selectedPhotoType?: PhotoTypeType | undefined | null;
  submissionSet: SubmissionType[];
  testId: string;
};

const History = ({
  primaryHistory,
  patientRejectionReasons,
  photoSubmissionHistory,
  selectedPhotoType = null,
  submissionSet,
  testId,
}: Props) => {
  // Label StateData objects that belong to the submission history
  const sortedPhotoHistory = useMemo(() => {
    return formatSubmissionHistory(
      selectedPhotoType != null,
      photoSubmissionHistory,
      submissionSet,
      primaryHistory
    );
  }, [
    primaryHistory,
    submissionSet,
    selectedPhotoType,
    photoSubmissionHistory,
  ]);

  const eventCounts = countSubmissionAndRejectionEvents(sortedPhotoHistory)!;

  if (!sortedPhotoHistory.length) {
    return (
      <EmptyText data-testid="PrismPhotoReview-PhotoHistory-ReasonTitle">
        No history
      </EmptyText>
    );
  }

  /* Using the dictionary below, count each occurrence of any rejection or submission event
      in order to label the displayed event pertaining to a submission with its *version number*

      E.g. Given the following history data:
        ---
        created
        collection
        ...
        submission_under_review
        rejected_photos
        ...
        submission_under_review
        rejected_photos
        ...
        submission_under_review
        rejected_customer
        ---

        Count the 'submission_under_review' and 'rejected_xxx' events using the eventCounts dict:
        {
          'submission_under_review': 3
          'rejected': 3
        }

        Next, while traversing the sortedPhotoHistory collection and generating list items, decrement the
          counts in eventCounts in order to keep track of the submission/rejection version number to be displayed.
  */

  const renderListItems = (
    reasons: Maybe<RejectionReason>[],
    showDash: boolean
  ) =>
    reasons.map((reason) => (
      <li key={reason?.name}>
        {showDash ? '-' : ''} {reason?.label}
      </li>
    ));

  const renderPatientRejectionList = (
    reasonsWithCategory: Maybe<RejectionReason>[]
  ) => {
    // Dragons: assuming that the first Reason in the reasons array will define the category (NMI, CNT, NDW)
    const [reasonCategory, ...reasons] = reasonsWithCategory;
    const relevantPatientReasons = patientRejectionReasons.find((category) =>
      reasonCategory?.name.includes(category.name)
    );
    const reasonNames = reasons.map((r) => r!.name);
    return relevantPatientReasons?.reasons
      ?.filter((r) =>
        reasonNames.find((reasonName) => reasonName.includes(r.name))
      )
      .map((mainReason) => {
        const subs = new Set(mainReason.reasons?.map((r) => r.name));
        return (
          <>
            <ReasonTitle>{mainReason.label}</ReasonTitle>
            <ReasonList isOrtho={true}>
              {renderListItems(
                reasons.filter((r) => subs.has(r!.name)),
                false
              )}
            </ReasonList>
          </>
        );
      });
  };

  const renderReasonList = (
    _ortho: boolean,
    reasons: Maybe<RejectionReason>[]
  ) => (
    <ReasonList isOrtho={true}>
      {renderPatientRejectionList(reasons)}
    </ReasonList>
  );

  const renderRejectionTitle = (count: number, label: string) =>
    `V${count}: Submission rejected - ${label}`;

  return (
    <HistoryList>
      {sortedPhotoHistory.map((item: StateDataTypeExtra, i) => {
        let eventType = item.data;

        // We don't need to show the "Redo photos uploaded" event which occurs upon
        // rejection - we only care about when new photos are actually submitted.
        if (eventType === PRISM_AGGREGATE_STATES.COLLECTION) {
          return null;
        }

        const prevEvent = sortedPhotoHistory[i + 1]?.data;
        // Prism was originally designed to not show submitted events, but they are the only way to know if we have recieved clarification, or reversed approval
        // So here we check if the submission directly follows clarification, else we skip it entirely.
        if (eventType === PRISM_HISTORY_EVENTS.ORTHO_SUBMITTED) {
          if (prevEvent === PRISM_HISTORY_EVENTS.ORTHO_NEEDS_CLARIFICATION) {
            eventType = PRISM_HISTORY_EVENTS.CLARIFICATION_PROVIDED;
          } else if (prevEvent === PRISM_HISTORY_EVENTS.ORTHO_APPROVED) {
            eventType = PRISM_HISTORY_EVENTS.REOPENED;
          } else {
            return null;
          }
        }
        const dateTime = item.created;
        const user = item.user?.email;
        const rejectionReasons = item.extra?.rejectionReasons;
        const rejectionNotes = item.extra?.rejectionNotes;
        const initialNote = item.initialNote;

        let { Icon, label } = config[eventType] ?? {};

        // text specific to candid pro patient timelines
        if (eventType === PRISM_AGGREGATE_STATES.SUBMISSION) {
          if (prevEvent === PRISM_HISTORY_EVENTS.ORTHO_SUBMITTED) {
            // When prism history sees submission_under_review, it assumes a new submission was created
            // but in the case that this was approved, then reopened, that's not actually true, so skip
            return null;
          }
          label = 'Photos submitted to reviewer by';
        }
        if (eventType === PRISM_HISTORY_EVENTS.ORTHO_APPROVED) {
          label = 'Prepared by reviewer';
        }

        let reasonTitle = label + (user ? `: ${user}` : '');

        let details;

        switch (eventType) {
          case PRISM_AGGREGATE_STATES.SUBMISSION: {
            if (eventCounts[eventType] > 1) {
              reasonTitle = `Submission V${eventCounts[eventType]} created`;
              details = user;
            }
            eventCounts[eventType]--;
            break;
          }
          case PRISM_HISTORY_EVENTS.ORTHO_REJECTED_CUSTOMER: {
            reasonTitle = renderRejectionTitle(
              eventCounts[PRISM_HISTORY_EVENTS.GENERIC_REJECTED],
              rejectionReasons?.[0]?.label!
            );
            details =
              rejectionReasons?.length &&
              renderReasonList(true, rejectionReasons);
            eventCounts[PRISM_HISTORY_EVENTS.GENERIC_REJECTED]--;
            break;
          }
          case PRISM_HISTORY_EVENTS.ORTHO_REJECTED_MISSING_INFO: {
            reasonTitle = renderRejectionTitle(
              eventCounts[PRISM_HISTORY_EVENTS.GENERIC_REJECTED],
              'Missing info'
            );
            details =
              rejectionReasons?.length &&
              renderReasonList(true, rejectionReasons);
            eventCounts[PRISM_HISTORY_EVENTS.GENERIC_REJECTED]--;
            break;
          }
          case PRISM_HISTORY_EVENTS.ORTHO_REJECTED_PHOTOS: {
            // Dragons: assuming that the format of photo reasons will be "photo_xxx"
            const photoReason = rejectionReasons?.[0]?.name?.split('_')[1]!;
            reasonTitle = renderRejectionTitle(
              eventCounts[PRISM_HISTORY_EVENTS.GENERIC_REJECTED],
              photoReason
            );
            const rejections = rejectionReasons?.filter((_, i) => i !== 0);
            details = rejectionReasons?.length && (
              <>
                <ReasonTitle
                  data-testid={
                    'PrismPhotoReview-' + testId + '-OrthoRejection-ReasonTitle'
                  }
                >
                  {selectedPhotoType
                    ? `${selectedPhotoType.label} photo rejected`
                    : ''}
                </ReasonTitle>
                <ReasonList isOrtho={false}>
                  {renderListItems(rejections!, true)}
                </ReasonList>
              </>
            );
            eventCounts[PRISM_HISTORY_EVENTS.GENERIC_REJECTED]--;
            break;
          }
          case PRISM_HISTORY_EVENTS.PHOTO_REJECTED: {
            details = rejectionReasons?.length && (
              <>
                <ReasonTitle
                  data-testid={
                    'PrismPhotoReview-' + testId + '-Rejection-ReasonTitle'
                  }
                >
                  {selectedPhotoType
                    ? `${selectedPhotoType.label} photo rejected`
                    : ''}
                </ReasonTitle>
                <ReasonList isOrtho={false}>
                  {renderListItems(rejectionReasons, true)}
                </ReasonList>
              </>
            );
            break;
          }
          // happens when approved for whitening only, aligner rejection
          // reasons will be present
          case PRISM_HISTORY_EVENTS.ORTHO_APPROVED: {
            details =
              rejectionReasons?.length &&
              renderReasonList(true, rejectionReasons);
            break;
          }
        }

        return (
          <li key={i}>
            {Icon && <Icon aria-hidden />}
            <DateTime>
              {moment(dateTime).format('MMM. D, YYYY [at] h:mma')}
            </DateTime>
            <ReasonTitle
              data-testid={'PrismPhotoReview-' + testId + '-ReasonTitle'}
            >
              {reasonTitle}
            </ReasonTitle>
            {details && (
              <Details data-testid={'PrismPhotoReview-' + testId + '-Details'}>
                {details}
              </Details>
            )}
            {initialNote && (
              <Notes>
                <ReasonTitle>Tech note</ReasonTitle>
                <NotesText>{initialNote}</NotesText>
              </Notes>
            )}
            {rejectionNotes && (
              <Notes>
                <ReasonTitle>Notes</ReasonTitle>
                <NotesText>{rejectionNotes}</NotesText>
              </Notes>
            )}
          </li>
        );
      })}
    </HistoryList>
  );
};

export default History;
