import { MeQuery } from 'generated/legacy/graphql';
import {
  LoyaltyProgramTier,
  GetPracticeLoyaltyQuotesQuery,
  GetProductVariantsQuery,
} from 'generated/core/graphql';
import { createContext, useContext } from 'react';

export type AuthState = {
  authGroups: string[] | null;
  impersonatorId: string | null;
  isLoggedIn: boolean;
  userInfo: MeQuery['me'];
  isProDoctor: boolean;
  currentPracticeLoyaltyTier: LoyaltyProgramTier | null;
  loyaltyQuotesResult:
    | GetPracticeLoyaltyQuotesQuery['getPracticeLoyaltyQuotes']
    | null;
};

type ContextProps = {
  checkHasAccess: (accessGroups: string[]) => boolean;
  handleLogIn: (
    accessToken: string,
    refreshToken: string,
    authGroups: string[],
    email: string
  ) => void;
  handleLogOut: () => void;
  products: GetProductVariantsQuery['productVariants'] | undefined;
} & AuthState;

export const AuthContext = createContext({} as ContextProps);
export const useAuthContext = () => useContext(AuthContext);
