import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { ACCESS_GROUPS } from 'types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import DocumentTitle from 'components/DocumentTitle';
import {
  PageHeader,
  PageHeaderContent,
  PageHeading,
  PageSection,
} from 'styles/layout.css';

import {
  SectionHeading,
  ToolGrid,
  ToolLink,
  ToolSection,
  LoadingContainer,
} from 'pages/Home/Home.css';
import { Loading } from 'core/components';
import { useAuthContext } from 'context/AuthContext';

const Home = () => {
  const { checkHasAccess, userInfo } = useAuthContext();
  const history = useHistory();
  const isStaff = checkHasAccess(ACCESS_GROUPS.STAFF);
  const isXrayStaff = checkHasAccess(ACCESS_GROUPS.XRAY);
  const isSTLStaff = checkHasAccess(ACCESS_GROUPS.STL);
  const isMaterialReviewer = checkHasAccess(ACCESS_GROUPS.MATERIAL_REVIEW);
  const isOrtho = checkHasAccess(ACCESS_GROUPS.ORTHO);
  const isCandidPro = checkHasAccess(ACCESS_GROUPS.CANDID_PRO);
  const isJourneyAdmin = checkHasAccess(ACCESS_GROUPS.JOURNEY_ADMIN);
  const isSupport = checkHasAccess(ACCESS_GROUPS.SUPPORT);
  const isAdmin = checkHasAccess(ACCESS_GROUPS.ADMIN);
  const isQc = checkHasAccess(ACCESS_GROUPS.QUALITY_CONTROL);
  const isTpTech = checkHasAccess(ACCESS_GROUPS.TP_TECH);
  const isProDoctor = isCandidPro && !!userInfo?.doctor?.practices?.length;
  const hasBillingViewPermissions = checkHasAccess(
    ACCESS_GROUPS.AUTOMATED_BILLING_READ
  );
  const hasBillingWritePermissions = checkHasAccess(
    ACCESS_GROUPS.AUTOMATED_BILLING_ADMIN
  );
  const hasPracticeManagementPermissions = checkHasAccess(
    ACCESS_GROUPS.PRACTICE_MANAGEMENT
  );
  const hasPromotionReadAccess = checkHasAccess(ACCESS_GROUPS.PROMOTIONS_READ);
  const isPracticeManagementViewable =
    hasBillingViewPermissions || hasPracticeManagementPermissions;
  const {
    'enable-order-admin': enableOrderAdmin,
    'enable-treatment-objective-admin-page': enableTreatmentObjectiveAdminPage,
    enableRapidAccountOnboarding,
  } = useFlags();

  const nonCandidProPermissionGroups = [
    isStaff,
    isXrayStaff,
    isSTLStaff,
    isMaterialReviewer,
    isOrtho,
    isJourneyAdmin,
    isSupport,
    isAdmin,
    isQc,
    isTpTech,
    hasBillingViewPermissions,
    hasBillingWritePermissions,
    hasPracticeManagementPermissions,
  ];

  const userInNonCandidProPermissionGroups = nonCandidProPermissionGroups.some(
    (group) => group
  );

  if (isProDoctor && !userInNonCandidProPermissionGroups) {
    history.replace('/action-items');
  }

  if (!userInfo) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  return (
    <DocumentTitle title="Tooth tools">
      <Fragment>
        <PageHeader>
          <PageHeaderContent>
            <PageHeading data-testid="page-header">Tooth tools</PageHeading>
          </PageHeaderContent>
        </PageHeader>
        <PageSection isNarrow>
          {(isAdmin || isJourneyAdmin) && (
            <ToolSection>
              <SectionHeading data-testid="section-heading">
                Admin Tools
              </SectionHeading>
              <ToolGrid>
                {isAdmin && (
                  <>
                    <li data-testid="homepage-link">
                      <ToolLink to="/test-user-creator">
                        Test user creator
                      </ToolLink>
                      <p>Create a test user at a specific journey state</p>
                    </li>
                    <li data-testid="homepage-link">
                      <ToolLink to="/intake-forms">Intake forms</ToolLink>
                      <p>View and edit intake forms</p>
                    </li>
                    <li data-testid="product-variants-link">
                      <ToolLink to="/product-variants">
                        Product variants
                      </ToolLink>
                      <p>View product variants</p>
                    </li>
                    {enableTreatmentObjectiveAdminPage && (
                      <li data-testid="homepage-link">
                        <ToolLink to="/treatment-objective-templates">
                          Treatment Objectives
                        </ToolLink>
                        <p>
                          View and edit treatment objective templates and
                          questions
                        </p>
                      </li>
                    )}
                  </>
                )}
                {isJourneyAdmin && (
                  <li data-testid="homepage-link">
                    <ToolLink to="/journey">Journey admin</ToolLink>
                    <p>Update journey for a patient and case</p>
                  </li>
                )}
                {hasPromotionReadAccess && (
                  <li data-testid="homepage-link">
                    <ToolLink to="/promotions">Promotion admin</ToolLink>
                    <p>Create coupons and view promotions</p>
                  </li>
                )}
              </ToolGrid>
            </ToolSection>
          )}
          {isStaff && (
            <ToolSection>
              <SectionHeading data-testid="section-heading">
                Orthodontist Tools
              </SectionHeading>
              <ToolGrid isOrtho>
                <li data-testid="homepage-link">
                  <ToolLink to="/ortho-prism">Ortho Prism</ToolLink>
                  <p>
                    Search cases, review patient photos, X-rays, and treatment
                    plans
                  </p>
                </li>
              </ToolGrid>
            </ToolSection>
          )}
          {isStaff && (
            <ToolSection>
              <SectionHeading data-testid="section-heading">
                Case Management Tools
              </SectionHeading>
              <ToolGrid>
                <li data-testid="homepage-link">
                  <ToolLink to="/ortho-prism">Ortho Prism</ToolLink>
                  <p>
                    Search cases, review patient photos, X-rays, and treatment
                    plans
                  </p>
                </li>
                {isMaterialReviewer && (
                  <li data-testid="homepage-link">
                    <ToolLink to="/prism">Photo review tool</ToolLink>
                    <p>Prepare photos for orthodontists</p>
                  </li>
                )}
                {isSTLStaff && (
                  <li data-testid="homepage-link">
                    <ToolLink to="/stl-upload">STL uploader</ToolLink>
                    <p>Upload patient scans</p>
                  </li>
                )}
                {isXrayStaff && (
                  <li data-testid="homepage-link">
                    <ToolLink to="/xray-upload">X-ray uploader</ToolLink>
                    <p>Upload patient X-rays</p>
                  </li>
                )}
              </ToolGrid>
            </ToolSection>
          )}
          {isStaff && (
            <ToolSection>
              <SectionHeading data-testid="section-heading">
                Support Tools
              </SectionHeading>
              <ToolGrid>
                {(isSupport || isPracticeManagementViewable) && (
                  <>
                    <li data-testid="homepage-link">
                      <ToolLink to="/account/new">
                        Create Account & Practice
                      </ToolLink>
                      <p>Support's tool to create a new practice & account</p>
                    </li>
                    <li data-testid="homepage-link">
                      <ToolLink to="/management/practice">
                        Practice Management
                      </ToolLink>
                      <p>Manage practice information and loyalty program</p>
                    </li>
                  </>
                )}
                <li data-testid="homepage-link">
                  <ToolLink to="/pin-login">Pin Login</ToolLink>
                  <p>Log in as a doctor</p>
                </li>
                {isSupport && (
                  <li data-testid="homepage-link">
                    <ToolLink to="/orders-portal">Orders</ToolLink>
                    <p>Create new orders</p>
                  </li>
                )}
                {(isAdmin || (isSupport && enableOrderAdmin)) && (
                  <li data-testid="homepage-link">
                    <ToolLink to="/orders-admin">Orders Admin</ToolLink>
                    <p>View existing orders</p>
                  </li>
                )}
              </ToolGrid>
            </ToolSection>
          )}
          {enableRapidAccountOnboarding && (
            <ToolSection>
              <SectionHeading data-testid="section-heading">
                Activation & Expansion Tools
              </SectionHeading>
              <ToolGrid>
                <li data-testid="homepage-link">
                  <ToolLink to="/new-practice">
                    Create practice & account
                  </ToolLink>
                  <p>AE's tool for creating a new practice</p>
                </li>
              </ToolGrid>
            </ToolSection>
          )}
          {isStaff && (
            <ToolSection>
              <SectionHeading data-testid="section-heading">
                Candid Shipping Tools
              </SectionHeading>
              <ToolGrid>
                <li data-testid="homepage-link">
                  <ToolLink to="/shipping">Create Shipping</ToolLink>
                  <p>Create shipment from normal workflow</p>
                </li>
                <li data-testid="homepage-link">
                  <ToolLink to="/shipping/add">Add Shipment</ToolLink>
                  <p>Add existing shipment tracking into system</p>
                </li>
                <li data-testid="homepage-link">
                  <ToolLink to="/shipping/export">
                    Export Shipment Tool
                  </ToolLink>
                  <p>Export shipments for customs</p>
                </li>
              </ToolGrid>
            </ToolSection>
          )}
          {hasBillingViewPermissions && (
            <ToolSection>
              <SectionHeading data-testid="section-heading">
                Candid Professional - Billing Management
              </SectionHeading>
              <ToolGrid>
                {hasBillingWritePermissions && (
                  <li data-testid="homepage-link">
                    <ToolLink to="/billing/invoices">Invoices</ToolLink>
                    <p>Internal invoice management tool</p>
                  </li>
                )}
                <li data-testid="homepage-link">
                  <ToolLink to="/invoice-summary">
                    Provider facing invoices
                  </ToolLink>
                  <p>View Yaypay payment statuses</p>
                </li>
              </ToolGrid>
            </ToolSection>
          )}

          {isCandidPro && (
            <ToolSection>
              <SectionHeading data-testid="section-heading">
                Candid Pro Doctor-Facing Tools
              </SectionHeading>
              <ToolGrid>
                {isProDoctor && (
                  <>
                    <li data-testid="homepage-link">
                      <ToolLink to="/action-items">Action items</ToolLink>
                      <p>Take action on patient cases</p>
                    </li>
                  </>
                )}
                <li data-testid="homepage-link">
                  <ToolLink to={'/patient'}>Patients</ToolLink>
                  <p>View all your patients and their cases</p>
                </li>
              </ToolGrid>
            </ToolSection>
          )}
          {(isQc || isTpTech) && (
            <ToolSection>
              <SectionHeading data-testid="section-heading">
                Treatment planning tools
              </SectionHeading>
              <ToolGrid>
                <li data-testid="homepage-link">
                  <ToolLink to="/tp-quality-control">Quality control</ToolLink>
                </li>
              </ToolGrid>
            </ToolSection>
          )}
        </PageSection>
      </Fragment>
    </DocumentTitle>
  );
};

export default Home;
