import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { hasMessageField } from 'utils/typeCheck';
import api from 'state/api';
import {
  ArrowSVG,
  ButtonContainer,
  StyledTable,
  UserSvg,
} from 'pages/PracticeManagement/Doctors/Doctors.css';
import { DoctorType, PracticeType } from 'generated/legacy/graphql';
import { useNotificationContext } from 'core/context/NotificationContext';
import TransferPatientModal, {
  Doctor,
  Practice,
} from 'components/Modals/TransferPatientModal';
import { fetchPractice } from 'pages/PracticeManagement/shared/slice';

interface DoctorsProps {
  practice?: PracticeType | null;
  doctors?: DoctorType[];
}

export const Doctors: React.FC<DoctorsProps> = ({ practice, doctors = [] }) => {
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState<Doctor | undefined>(
    undefined
  );
  const { 'transfer-patient': enableTransferPatient } = useFlags();
  const { showNotification } = useNotificationContext();
  const dispatch = useDispatch();

  const [massTransferCustomers] = api.useMassTransferCustomersMutation();

  const handleOpenTransferModal = () => {
    setIsTransferModalOpen(true);
  };

  const handleConfirmTransfer = async (
    newDoctor: Doctor,
    newPractice: Practice
  ) => {
    if (selectedDoctor && practice && newDoctor && newPractice) {
      let res;
      try {
        res = await massTransferCustomers({
          input: {
            referringDentistId: selectedDoctor.id,
            practiceId: practice.id,
            newReferringDentistId: newDoctor.id,
            newPracticeId: newPractice.id,
          },
        }).unwrap();
      } catch (error: unknown) {
        if (hasMessageField(error)) {
          showNotification(error.message, 'error');
        } else {
          showNotification('Failed to transfer patients', 'error');
        }
        return;
      }
      const successMessage = `${res?.patientCount} Patients transferred successfully from ${selectedDoctor.fullName} to ${newDoctor.fullName}`;
      showNotification(successMessage, 'success');
      setIsTransferModalOpen(false);
      dispatch(fetchPractice({ id: practice.id }));
    } else {
      showNotification(
        'Invalid transfer input data, please try again.',
        'error'
      );
    }
  };

  return (
    <>
      <StyledTable
        data={doctors}
        columns={[
          {
            name: 'pin_login',
            label: 'Pin Login',
            options: {
              sort: false,
              customBodyRender: (_, tableMeta) => {
                const doctorId = tableMeta.rowData[1];
                return (
                  <a
                    href={`/pin-login/${doctorId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    📌
                  </a>
                );
              },
            },
          },
          {
            name: 'id',
            label: 'ID',
          },
          {
            name: 'fullName',
            label: 'Full Name',
          },
          {
            name: 'email',
            label: 'Email',
          },
          {
            name: 'practices',
            label: 'Practices',
            options: {
              customBodyRender: (practices: { id: string; name: string }[]) => {
                return (
                  <div style={{ maxHeight: '5rem', overflowY: 'auto' }}>
                    {practices.map((practice) => (
                      <div key={practice.id}>
                        <a
                          href={`/management/practice/${practice.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          [{practice.id}] {practice.name}
                        </a>
                        <br />
                      </div>
                    ))}
                  </div>
                );
              },
            },
          },
          {
            name: 'patientCountAtThisPractice',
            label: 'Patients at this practice',
            options: {
              sort: false,
              customBodyRender: (_, tableMeta) => {
                const doctorId = tableMeta.rowData[1];
                const doctor = doctors.find((d) => d.id === doctorId);
                // Find the patient count object & value for the current practice
                const patientCount = doctor?.patientCountByPractice?.find(
                  (p) => p?.practiceId === practice?.id
                )?.patientCount;
                return patientCount !== undefined ? patientCount : 'N/A';
              },
            },
          },
          ...(enableTransferPatient
            ? [
                {
                  name: 'mass_transfer_patients',
                  label: 'Mass Transfer Patients',
                  options: {
                    sort: false,
                    customBodyRender: (
                      _: unknown,
                      tableMeta: { rowData: DoctorType[] }
                    ) => {
                      const doctorId = tableMeta.rowData[0];
                      return (
                        <ButtonContainer
                          onClick={() => {
                            setSelectedDoctor(
                              doctors.find((d) => d.id === String(doctorId))
                            );
                            handleOpenTransferModal();
                          }}
                        >
                          <UserSvg /> <ArrowSVG /> <UserSvg />
                        </ButtonContainer>
                      );
                    },
                  },
                },
              ]
            : []),
        ]}
        options={{
          selectableRows: 'none',
          search: false,
        }}
      />
      <TransferPatientModal
        isOpen={isTransferModalOpen}
        setIsOpen={setIsTransferModalOpen}
        onTransfer={handleConfirmTransfer}
        originDoctor={selectedDoctor}
        brandName={practice?.brand?.name}
      />
    </>
  );
};
