import * as Sentry from '@sentry/react';
import { formatFilesNames } from 'utils/materials';
import { MaterialEvaluationTypes } from 'generated/core/graphql';
import { StringMap } from 'utils/types';
import axios from 'axios';
import { CUST_CREATOR_ERROR_MESSAGES } from 'types';
import { ProblemCategory } from 'utils/types';

export type RejectionDetailProblem = {
  id: string;
  isEditing: boolean;
  topic: ProblemCategory | null;
  photos: Array<File>;
  notes: string;
};

export const getAwsFileLocations = async (
  formattedFiles: File[],
  getTreatmentPlanStagingUploadUrl: any,
  customerId: string
) => {
  const uploadTreatmentPlanStagingPromises = formattedFiles.map(
    async (file) => {
      const uploadData = await getTreatmentPlanStagingUploadUrl({
        patientId: parseInt(customerId),
        fileName: file.name,
      });
      const fields: StringMap = uploadData?.getMaterialUploadData.fields;
      const url = uploadData?.getMaterialUploadData.url!;
      const awsFileLocation = uploadData?.getMaterialUploadData.awsLocation;

      const data = new FormData();
      Object.entries(fields).forEach(([key, value]) => data.append(key, value));
      data.append('file', file);

      try {
        await axios({
          method: 'POST',
          url,
          data,
          headers: {
            'Content-Type': file.type,
          },
        });
      } catch (err) {
        Sentry.captureException(err);
        throw new Error(
          CUST_CREATOR_ERROR_MESSAGES.treatment_details_submission
        );
      }

      return {
        awsFileLocation: awsFileLocation ?? '',
        filename: file.name,
      };
    }
  );
  const awsFileLocations = await Promise.all(
    uploadTreatmentPlanStagingPromises
  );
  return awsFileLocations;
};

export const getFormattedFileNamesFromProblems = (
  problems: RejectionDetailProblem[]
) => {
  const problemFiles = problems
    .map((p: RejectionDetailProblem) => p.photos)
    .flat(1);
  return formatFilesNames(problemFiles);
};

export const getMaterialEvaluationInput = (
  problems: RejectionDetailProblem[],
  notes: string | undefined,
  materialEvaluationType: MaterialEvaluationTypes,
  approved: boolean,
  rejectionReasons: string[],
  selectedPlanId: string
) => {
  if (problems.length && problems[0].topic !== null) {
    return problems.map((p: RejectionDetailProblem) => ({
      materialId: selectedPlanId!,
      materialEvaluationType: materialEvaluationType,
      approved: approved,
      data: {
        evaluationNotes: p.notes,
      },
      repairable: false,
      rejectionReasons: rejectionReasons,
    }));
  } else {
    return [
      {
        materialId: selectedPlanId!,
        materialEvaluationType: materialEvaluationType,
        approved: approved,
        data: {
          evaluationNotes: notes,
        },
        repairable: false,
        rejectionReasons: rejectionReasons,
      },
    ];
  }
};
