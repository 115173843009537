import { useEffect, useMemo, useState } from 'react';
import Dinero from 'dinero.js';
import {
  ApplyPromotionsToCartDocument,
  ApplyPromotionsToCartQuery,
  ApplyPromotionsToCartQueryVariables,
  GetCurrentPracticeLoyaltyTierDocument,
  GetCurrentPracticeLoyaltyTierQuery,
  GetCurrentPracticeLoyaltyTierQueryVariables,
  GetPracticeLoyaltyQuotesDocument,
  GetPracticeLoyaltyQuotesQuery,
  GetPracticeLoyaltyQuotesQueryVariables,
  IneligiblePromotion,
} from 'generated/core/graphql';
import { useGQLQuery } from 'hooks/useGQL';
import {
  CatalogItemType,
  defaultItemsByProductType,
  ProductTypes,
} from 'types/checkout';
import { useAuthContext } from 'context/AuthContext';

/*
 * Order of operations:
 * 1. Get the base price of the product
 * 2. Apply loyalty discount if applicable.
 * 3. Get the promotion price. If a coupon was passed in, apply that as well.
 * 4. Once the price has been calculated, generate the cart with the passed in order item, and set the calculated prices
 */
const useGetDiscountedPrice = ({
  productType,
  couponCode,
  patientId,
  practiceId,
}: {
  productType: ProductTypes;
  couponCode?: string;
  patientId: string;
  practiceId: string;
}) => {
  const [catalogItem, setCatalogItem] = useState<CatalogItemType | null>(null);
  const [couponError, setCouponError] = useState<
    IneligiblePromotion | undefined | null
  >();

  const { userInfo, products } = useAuthContext();

  const [getLoyaltyProgramTier, { data: loyaltyTierResult }] = useGQLQuery<
    GetCurrentPracticeLoyaltyTierQuery,
    GetCurrentPracticeLoyaltyTierQueryVariables
  >(GetCurrentPracticeLoyaltyTierDocument);
  const [getLoyaltyQuotes, { data: loyaltyQuotesResult }] = useGQLQuery<
    GetPracticeLoyaltyQuotesQuery,
    GetPracticeLoyaltyQuotesQueryVariables
  >(GetPracticeLoyaltyQuotesDocument);

  const [appliedCouponCode, setAppliedCouponCode] = useState<string | null>(
    null
  );

  const [applyPromotionsToCart, { data }] = useGQLQuery<
    ApplyPromotionsToCartQuery,
    ApplyPromotionsToCartQueryVariables
  >(ApplyPromotionsToCartDocument);

  const product = userInfo
    ? defaultItemsByProductType(userInfo?.brandInfo?.name ?? '')[productType]
    : null;

  useEffect(() => {
    if (practiceId) {
      getLoyaltyProgramTier({ practiceId });
      getLoyaltyQuotes({ practiceId });
    }
  }, [practiceId]);

  //The base price before any loyalty discount
  // 1. Attempt to get the contract rate
  // 2. Fallback to the product table
  // 3. Fail
  const priceBeforeDiscounts: Dinero.Dinero = useMemo(() => {
    if (!loyaltyQuotesResult || !product || !products) {
      return Dinero({ amount: 0 });
    }

    const contractRate = loyaltyQuotesResult.getPracticeLoyaltyQuotes
      ?.filter((q) => q.tierName === 'Contract Rate')[0]
      .products?.filter((p) => p?.sku === product.sku)[0]?.quote;

    //0 could be a legit value
    if (contractRate != undefined) {
      return Dinero({ amount: contractRate });
    }

    //If we get here we don't have a contract rate for the product,
    //This is unforinate, but expected to happen sometimes. Fall back to the price in the product table
    const productFromQuery = products?.find((p) => p?.sku === product.sku);
    if (productFromQuery?.defaultPriceInCents != undefined) {
      return Dinero({ amount: productFromQuery.defaultPriceInCents });
    }

    //If we get here, we have a product that doesn't exist in our system, and that is BAD
    throw new Error(`Unable to find product ${product.sku} in product table`);
  }, [products, productType, loyaltyQuotesResult, userInfo]);

  //The discounted price will only change with
  //Base price becausue we calculated it (required)
  //Coupon code to apply a coupon
  //Loyalty tier to apply a loyalty discount (required)
  //promotions to apply a promotion
  //
  // Attempt to get the price from the loyalty tier. Fall back to the price before discounts, if not found
  useEffect(() => {
    if (
      !priceBeforeDiscounts ||
      !loyaltyTierResult?.getCurrentPracticeLoyaltyProgram ||
      !loyaltyQuotesResult?.getPracticeLoyaltyQuotes ||
      !product
    ) {
      return;
    }
    const getLoyaltyPrice = () => {
      const currentLoyaltyTier =
        loyaltyTierResult.getCurrentPracticeLoyaltyProgram?.loyaltyProgramTier
          ?.name;
      const productQuote = loyaltyQuotesResult?.getPracticeLoyaltyQuotes
        ?.filter((tier) => tier.tierName === currentLoyaltyTier)[0]
        .products?.filter((p) => p?.sku === product.sku);

      if (productQuote?.length === 1 && productQuote[0]?.quote) {
        return Dinero({ amount: productQuote[0].quote });
      }

      return priceBeforeDiscounts;
    };

    const priceAfterLoyalty = getLoyaltyPrice();

    const cartInput = [
      {
        ...product,
        quantity: 1,
        totalPriceBeforeDiscounts: priceAfterLoyalty,
      },
    ];

    applyPromotionsToCart({
      input: {
        patientId: patientId,
        practiceId: practiceId,
        lineItems: cartInput.map((item) => ({
          sku: item.sku,
          quantity: item.quantity,
          originalPriceInCents: item.totalPriceBeforeDiscounts.getAmount(),
        })),
        couponCodes: couponCode ? [couponCode] : [],
      },
    });
  }, [
    priceBeforeDiscounts,
    couponCode,
    loyaltyTierResult,
    loyaltyQuotesResult,
    userInfo,
  ]);

  //Once the promotion query has finished, we should have the final result
  //If an error occurred when setting the coupon, set that as well
  useEffect(() => {
    if (!product) {
      return;
    }
    const applyPromotionsToCartResult = data?.applyPromotionsToCart;
    if (applyPromotionsToCartResult) {
      const discounts =
        applyPromotionsToCartResult?.lineItems.find(
          (li) => li?.sku === product.sku && li.quantity === product.quantity
        )?.discounts ?? [];
      setCatalogItem({
        ...product,
        quantity: 1,
        totalPriceBeforeDiscounts: priceBeforeDiscounts,
        totalPriceAfterLoyalty: Dinero({
          amount: applyPromotionsToCartResult.subtotalBeforeDiscounts,
        }),
        totalPriceAfterDiscounts: Dinero({
          amount: applyPromotionsToCartResult?.totalAfterDiscounts,
        }),
        appliedDiscounts: discounts,
      });

      if (applyPromotionsToCartResult?.ineligiblePromotions?.length) {
        setCouponError(
          applyPromotionsToCartResult
            .ineligiblePromotions[0] as IneligiblePromotion
        );
      } else {
        setCouponError(null);
      }

      const appliedCoupon = applyPromotionsToCartResult?.appliedCoupons
        ? applyPromotionsToCartResult?.appliedCoupons[0]
        : null;
      setAppliedCouponCode(appliedCoupon?.code ?? null);
    }
  }, [data, userInfo]);

  //Return the item inside of a cart to maintain parity with what existed before
  //as well as the error
  return {
    cart: catalogItem ? [catalogItem] : [],
    couponError,
    appliedCouponCode,
  };
};

export default useGetDiscountedPrice;
