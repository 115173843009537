// Commonly used PropTypes that can be imported
import PropTypes from 'prop-types';

import { AUTH_GROUP_TYPES } from 'types';

export const children = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.arrayOf(PropTypes.node),
]);

export const routerHistory = PropTypes.shape({
  action: PropTypes.string,
  block: PropTypes.func,
  go: PropTypes.func,
  goBack: PropTypes.func,
  goForward: PropTypes.func,
  length: PropTypes.number,
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object,
  }),
  push: PropTypes.func,
  replace: PropTypes.func,
});

export const routerLocation = PropTypes.shape({
  hash: PropTypes.string,
  key: PropTypes.string,
  pathname: PropTypes.string,
  search: PropTypes.string,
  state: PropTypes.object,
});

export const routerMatch = (params) =>
  PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape(params),
    path: PropTypes.string,
    url: PropTypes.string,
  });

export const authGroups = PropTypes.oneOf(AUTH_GROUP_TYPES);
