export enum JourneyTransition {
  ForceCaseActive = 'force_case_active',
  ForceCaseExpired = 'force_case_expired',
  ForceCaseCanceled = 'force_case_canceled',
  ForceCouldNotTreat = 'force_could_not_treat',
  ForceNeedsDentalWork = 'force_needs_dental_work',
  ForceCaseCompleted = 'force_case_completed',
  ForceInTreatmentIssue = 'force_in_treatment_issue',
  ForceInTreatment = 'force_in_treatment',
  ForceOrthoReview = 'force_ortho_review',
  ForceQcReview = 'force_qc_review',
  ForceRefinementsNeeded = 'force_additional_aligners_requested',
  ForceAlignerKitDelivered = 'force_aligner_kit_delivered',
  ForceInitialFitIssue = 'force_case_closed:_initial_fit_issue',
  ForceReadyForTreatmentPlanning = 'force_ready_for_treatment_planning',
  Complete = 'complete',
}
