import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ACCESS_GROUPS } from 'types';
import ProtectedRoute from 'components/ProtectedRoute';
import Invoices from 'pages/BillingManagement/Invoices';
import Patient from 'pages/Patient';
import Home from 'pages/Home';
import IntakeForms from 'pages/IntakeForms';
import NotFound from 'pages/NotFound';
import OrthoPrism from 'pages/OrthoPrism';
import Prism from 'pages/Prism';
import LoyaltyProgram from 'pages/LoyaltyProgram';
import STLUpload from 'pages/STLUpload';
import XrayUpload from 'pages/XrayUpload';
import Journey from 'pages/Journey';
import TestUserCreator from 'pages/TestUserCreator';
import TpCentralEnhanced from 'pages/TpCentralEnhanced';
import ShippingPage from 'pages/Shipping';
import PromotionPage from 'pages/Promotion';
import TpQualityControl from 'pages/TpQualityControl';
import LoyaltyProviderPage from 'pages/LoyaltyProviderView';
import AccountPreferencesPage from 'pages/AccountPreferencesPage';
import ActionItems from 'pages/ActionItems';
import TreatmentObjectiveTemplates from 'pages/TreatmentObjectiveTemplates';
import MarketingResources from 'pages/MarketingResources';
import AccountCreation from 'pages/AccountCreation';
import PracticeAccountCreator from 'pages/PracticeAccountCreator/PracticeAccountCreatorView';
import TreatmentPlanViewer from 'pages/TreatmentPlanViewer';
import PinLogin from 'pages/PinLogin';
import PracticeSettings from 'pages/PracticeManagement/shared';
import ExportShipmentsPage from 'pages/Shipping/ExportShipmentsPage';
import Login from 'pages/Login/Login';
import TokenLogin from 'pages/Login/TokenLogin';
import PasswordResetRequest from 'pages/Login/PasswordResetRequest';
import PasswordResetConfirmation from 'pages/Login/PasswordResetConfirmation';
import PasswordResetRequestSuccess from 'pages/Login/PasswordResetRequestSuccess';
import {
  getBrandSettings,
  getBrandFromDomain,
  convertToBrand,
  GLIDEWELL_BRAND_NAME,
} from 'utils/brands';
import ContactUsPage from 'pages/ContactUs';
import { PracticeInvoices } from 'pages/BillingManagement/Invoices/PracticeInvoice';
import { useFlags } from 'launchdarkly-react-client-sdk';
import OrdersPortal from 'pages/OrdersPortal';
import OrdersAdmin from 'pages/OrdersAdmin';
import ProductVariants from 'pages/ProductVariants';
import TreatmentPlanEditor from 'pages/TreatmentPlanEditor';
import AppReloader from 'components/AppReloader';
import { useAuthContext } from 'context/AuthContext';

const Pages = () => {
  const { userInfo } = useAuthContext();
  const {
    'enable-provider-facing-invoices': enableProviderFacingInvoices,
    'enable-treatment-objective-admin-page': enableTreatmentObjectiveAdminPage,
  } = useFlags();

  const { showLoyaltyInfo, helpCenterUrl: zendeskHelpCenterUrl } =
    getBrandSettings(getBrandFromDomain());
  const notGlidewell =
    convertToBrand(userInfo?.brandInfo?.name!) !== GLIDEWELL_BRAND_NAME;

  return (
    <>
      <AppReloader />
      <Switch>
        <Route component={Login} exact path="/login" />
        <Route component={TokenLogin} exact path="/login/token" />
        <Route component={PasswordResetRequest} exact path="/account/reset" />
        <Route
          component={PasswordResetRequestSuccess}
          path="/account/reset/success"
        />
        <Route
          component={PasswordResetConfirmation}
          path="/account/reset/confirm"
        />
        {/* The softsmile-viewer route is marked for deprecation as it is not generic */}
        <Route component={TreatmentPlanViewer} path="/softsmile-viewer" />
        <Route component={TreatmentPlanViewer} path="/treatment-plan-viewer" />
        <Route component={TreatmentPlanEditor} path="/treatment-plan-editor" />
        <ProtectedRoute component={Home} exact path="/" />
        <ProtectedRoute
          accessGroups={ACCESS_GROUPS.MATERIAL_REVIEW}
          component={Prism}
          path="/prism"
        />
        <ProtectedRoute
          accessGroups={[
            ...ACCESS_GROUPS.STAFF,
            ...ACCESS_GROUPS.ORTHO,
            ...ACCESS_GROUPS.CANDID_PRO,
          ]}
          component={OrthoPrism}
          path="/ortho-prism"
        />
        <ProtectedRoute
          accessGroups={[...ACCESS_GROUPS.PROMOTIONS_READ]}
          component={PromotionPage}
          path="/promotions"
        />
        <ProtectedRoute
          accessGroups={ACCESS_GROUPS.STAFF}
          component={PracticeSettings}
          path="/management/practice"
        />
        <ProtectedRoute
          accessGroups={ACCESS_GROUPS.ADMIN}
          component={LoyaltyProgram}
          path="/loyalty-program"
        />
        <ProtectedRoute
          accessGroups={[
            ...ACCESS_GROUPS.STAFF,
            ...ACCESS_GROUPS.ORTHO,
            ...ACCESS_GROUPS.CANDID_PRO,
          ]}
          component={Patient}
          path="/patient"
        />
        <ProtectedRoute
          accessGroups={ACCESS_GROUPS.ADMIN}
          component={TestUserCreator}
          path="/test-user-creator"
        />
        <ProtectedRoute
          accessGroups={ACCESS_GROUPS.ADMIN}
          component={IntakeForms}
          path="/intake-forms"
        />
        <ProtectedRoute
          accessGroups={ACCESS_GROUPS.STL}
          component={STLUpload}
          path="/stl-upload"
        />
        <ProtectedRoute
          accessGroups={ACCESS_GROUPS.XRAY}
          component={XrayUpload}
          path="/xray-upload"
        />
        <ProtectedRoute
          component={TpCentralEnhanced}
          path="/tp-central-enhanced"
        />
        <ProtectedRoute
          accessGroups={ACCESS_GROUPS.STAFF}
          component={ExportShipmentsPage}
          path="/shipping/export"
        />
        <ProtectedRoute
          accessGroups={ACCESS_GROUPS.STAFF}
          component={ShippingPage}
          path="/shipping/add"
        />
        <ProtectedRoute
          accessGroups={ACCESS_GROUPS.STAFF}
          component={ShippingPage}
          path="/shipping"
        />
        <ProtectedRoute
          accessGroups={ACCESS_GROUPS.JOURNEY_ADMIN}
          component={Journey}
          path="/journey"
        />
        {enableTreatmentObjectiveAdminPage && (
          <ProtectedRoute
            accessGroups={ACCESS_GROUPS.ADMIN}
            component={TreatmentObjectiveTemplates}
            path="/treatment-objective-templates"
          />
        )}
        <ProtectedRoute
          accessGroups={ACCESS_GROUPS.SUPPORT}
          component={AccountCreation}
          path="/account/new"
        />
        <ProtectedRoute
          accessGroups={ACCESS_GROUPS.ADMIN}
          component={PracticeAccountCreator}
          path="/new-practice"
        />
        <ProtectedRoute
          accessGroups={[...ACCESS_GROUPS.SUPPORT, ...ACCESS_GROUPS.ADMIN]}
          component={OrdersPortal}
          path="/orders-portal"
        />
        <ProtectedRoute
          accessGroups={[...ACCESS_GROUPS.ADMIN, ...ACCESS_GROUPS.SUPPORT]}
          component={OrdersAdmin}
          path="/orders-admin"
        />
        <ProtectedRoute
          accessGroups={ACCESS_GROUPS.ADMIN}
          component={ProductVariants}
          path="/product-variants"
        />
        <ProtectedRoute
          accessGroups={ACCESS_GROUPS.CANDID_PRO}
          component={ActionItems}
          path="/action-items"
        />
        <ProtectedRoute
          accessGroups={[
            ...ACCESS_GROUPS.STAFF,
            ...ACCESS_GROUPS.ORTHO,
            ...ACCESS_GROUPS.CANDID_PRO,
          ]}
          component={MarketingResources}
          path="/marketing-resources"
        />
        <Route
          path="/help-center"
          component={() => {
            window.location.href = zendeskHelpCenterUrl as string;
            return null;
          }}
        />
        <ProtectedRoute
          accessGroups={[
            ...ACCESS_GROUPS.STAFF,
            ...ACCESS_GROUPS.ORTHO,
            ...ACCESS_GROUPS.CANDID_PRO,
          ]}
          component={ContactUsPage}
          path="/contact-us"
        />
        <ProtectedRoute
          accessGroups={[
            ...ACCESS_GROUPS.SUPPORT,
            ...ACCESS_GROUPS.ADMIN,
            ...ACCESS_GROUPS.SUPPORT_MANAGER,
          ]}
          component={PinLogin}
          path="/pin-login/:doctorId?"
        />
        <ProtectedRoute
          accessGroups={[
            ...ACCESS_GROUPS.STAFF,
            ...ACCESS_GROUPS.AUTOMATED_BILLING_ADMIN,
          ]}
          component={Invoices}
          path="/billing/invoices"
        />
        {notGlidewell && enableProviderFacingInvoices && (
          <ProtectedRoute
            component={PracticeInvoices}
            accessGroups={[...ACCESS_GROUPS.CANDID_PRO]}
            exact
            path={`/invoice-summary`}
          />
        )}

        <ProtectedRoute
          accessGroups={[
            ...ACCESS_GROUPS.QUALITY_CONTROL,
            ...ACCESS_GROUPS.TP_TECH,
          ]}
          component={TpQualityControl}
          path="/tp-quality-control"
        />
        {showLoyaltyInfo && (
          <ProtectedRoute
            accessGroups={ACCESS_GROUPS.CANDID_PRO}
            component={LoyaltyProviderPage}
            path="/loyalty"
          />
        )}
        <ProtectedRoute
          accessGroups={ACCESS_GROUPS.CANDID_PRO}
          component={AccountPreferencesPage}
          path="/preferences"
        />
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

export default Pages;
