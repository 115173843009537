import React, { ReactNode, useState } from 'react';
import { renderMobileDrawerHeader } from 'components/Header/utilities';
import { Divider, Grid } from 'core/components';

import {
  HeaderLinks,
  HamburgerIcon,
  MobileNavContainer,
  CloseIcon,
  DesktopNavContainer,
  StyledLinkContainer,
  StyledLink,
  HeaderLinksMobile,
  MobileDrawer,
} from 'components/Header/Header.css';
import { LoyaltyInfo } from 'components/Header/types';
import { useAuthContext } from 'context/AuthContext';

const navLinkPaths = {
  ACTION_ITEMS: '/action-items',
  ORTHO_PRISM: '/ortho-prism',
  PATIENT_SEARCH: '/patient',
};

const constructLinkContainer = (
  currentPath: string,
  destination: string,
  label: string
) => (
  <StyledLinkContainer
    data-testid="header-nav"
    currentPage={currentPath === destination}
  >
    <StyledLink to={destination}>{label}</StyledLink>
  </StyledLinkContainer>
);

const getLinks = (currentPath: string, searchPage: string) => (
  <>
    {constructLinkContainer(
      currentPath,
      navLinkPaths.ACTION_ITEMS,
      'Action items'
    )}
    {constructLinkContainer(currentPath, searchPage, 'Patients')}
  </>
);

const getSearchPage = (isProDoctor: boolean) =>
  isProDoctor ? navLinkPaths.PATIENT_SEARCH : navLinkPaths.ORTHO_PRISM;

type MobileNavProps = {
  logoSubString: string | undefined;
  loyaltyInfo: LoyaltyInfo;
};

export const MobileHeaderNav = ({
  logoSubString,
  loyaltyInfo,
}: MobileNavProps) => {
  const { isProDoctor } = useAuthContext();
  const searchPage = getSearchPage(isProDoctor);
  const currentPath = window.location.pathname;
  const [Anchor, setAnchor] = useState<ReactNode>(HamburgerIcon);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClick = () => {
    const currentAnchor = Anchor === isOpen ? CloseIcon : HamburgerIcon;
    setAnchor(currentAnchor);
    setIsOpen(!isOpen);
  };

  return (
    <MobileNavContainer onClick={onClick}>
      <HamburgerIcon
        onClick={onClick}
        style={{
          width: '1.5rem',
          marginRight: '-.25rem',
          paddingBottom: '4px',
        }}
      />

      <MobileDrawer open={isOpen} anchor={'top'} variant="temporary">
        {renderMobileDrawerHeader(onClick, logoSubString, loyaltyInfo)}

        <Divider />

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <Grid item xs={1} />

          <Grid container xs={7} justifyContent="center">
            <HeaderLinksMobile onClick={onClick}>
              {getLinks(currentPath, searchPage)}
            </HeaderLinksMobile>
          </Grid>

          <Grid item xs={1} />
        </Grid>
      </MobileDrawer>
    </MobileNavContainer>
  );
};

export const DesktopHeaderNav = () => {
  const currentPath = window.location.pathname;
  const { isProDoctor } = useAuthContext();
  const searchPage = getSearchPage(isProDoctor);
  return (
    <DesktopNavContainer>
      <HeaderLinks>{getLinks(currentPath, searchPage)}</HeaderLinks>
    </DesktopNavContainer>
  );
};
