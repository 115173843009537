import { PRISM_AGGREGATE_STATES, PRISM_HISTORY_EVENTS } from 'types';

import SubmittedSVG from 'assets/submitted.svg?react';
import ThumbsDownSVG from 'assets/thumbs-down-filled.svg?react';
import ThumbsUpSVG from 'assets/thumbs-up-filled.svg?react';
import UploadSVG from 'assets/upload.svg?react';
import AddedSVG from 'assets/dashed-circle-plus.svg?react';
import MissingInfoSVG from 'assets/missing-info.svg?react';
import QuestionSVG from 'assets/question-icon.svg?react';
import RefreshSVG from 'assets/ic_refresh-ccw.svg?react';
import styled from 'styled-components/macro';

const StyledRefreshIcon = styled(RefreshSVG)`
  > path {
    stroke: ${({ theme }) => theme.colors.black70};
  }
`;

export default {
  [PRISM_HISTORY_EVENTS.PHOTO_UPLOADED]: {
    Icon: UploadSVG,
    label: 'Photo uploaded',
  },
  [PRISM_HISTORY_EVENTS.PHOTO_APPROVED]: {
    Icon: ThumbsUpSVG,
    label: 'Photo approved',
  },
  [PRISM_HISTORY_EVENTS.PHOTO_REJECTED]: {
    Icon: ThumbsDownSVG,
    label: 'Photo rejected',
  },
  [PRISM_HISTORY_EVENTS.ORTHO_SUBMITTED]: {
    Icon: SubmittedSVG,
    label: 'Submitted to ortho',
  },
  [PRISM_HISTORY_EVENTS.ORTHO_APPROVED]: {
    Icon: ThumbsUpSVG,
    label: 'Ortho approved',
  },
  [PRISM_HISTORY_EVENTS.ORTHO_REJECTED]: {
    Icon: ThumbsDownSVG,
    label: 'Ortho rejected',
  },
  [PRISM_HISTORY_EVENTS.ORTHO_REJECTED_CUSTOMER]: {
    Icon: ThumbsDownSVG,
    label: 'Ortho rejected',
  },
  [PRISM_HISTORY_EVENTS.ORTHO_REJECTED_PHOTOS]: {
    Icon: ThumbsDownSVG,
    label: 'Ortho rejected photos',
  },
  [PRISM_HISTORY_EVENTS.ORTHO_REJECTED_MISSING_INFO]: {
    Icon: MissingInfoSVG,
    label: 'Ortho missing information',
  },
  [PRISM_AGGREGATE_STATES.CREATED]: {
    Icon: AddedSVG,
    label: 'New photo review created',
  },
  [PRISM_AGGREGATE_STATES.EVALUATION]: {
    Icon: UploadSVG,
    label: 'All photos uploaded',
  },
  [PRISM_AGGREGATE_STATES.SUBMISSION]: {
    Icon: SubmittedSVG,
    label: 'Photos submitted to ortho',
  },
  [PRISM_AGGREGATE_STATES.NOT_TREATABLE]: {
    Icon: ThumbsDownSVG,
    label: 'Not treatable',
  },
  [PRISM_HISTORY_EVENTS.ORTHO_NEEDS_CLARIFICATION]: {
    Icon: QuestionSVG,
    label: 'Clarification needed by CandidPro Reviewer',
  },
  [PRISM_HISTORY_EVENTS.CLARIFICATION_PROVIDED]: {
    Icon: QuestionSVG,
    label: 'Clarification provided by Pro Clinician',
  },
  [PRISM_HISTORY_EVENTS.REOPENED]: {
    Icon: StyledRefreshIcon,
    label: 'Photo review re-opened by CandidPro Reviewer',
  },
};
