import React, { useRef, useCallback } from 'react';
import { useNotificationContext } from 'core/context/NotificationContext';
import styled from 'styled-components/macro';
import { hideVisually } from 'polished';
import copyIcon from 'assets/ic_copy.svg';

const CopyButton = styled.button`
  width: 1.5rem;
  height: 1.25rem;
  margin: 0.25rem 0 0 0.5rem;
  vertical-align: top;
  background: url(${copyIcon}) round;

  &:hover {
    opacity: 0.85;
  }
`;

const CopyTextarea = styled.textarea`
  ${hideVisually()};
`;

type Props = {
  label: string;
  text: string | number;
};

const CopyableText = ({ text, label }: Props) => {
  const copyEl = useRef<HTMLTextAreaElement>(null);
  const { showNotification } = useNotificationContext();

  const onCopy = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!copyEl.current) {
        return;
      }

      copyEl.current.select();
      document.execCommand('copy');
      showNotification(`Copied ${label}`, 'success');
    },
    [copyEl.current]
  );

  return (
    <>
      <CopyButton onClick={onCopy} title={`Copy ${label}`} type="button" />
      <CopyTextarea ref={copyEl} value={text} readOnly />
    </>
  );
};

export default CopyableText;
