import React from 'react';

import {
  Center,
  Container,
  Dot,
  Text,
} from 'core/components/Loading/Loading.css';

type LoadingProps = {
  color?: string;
  isCentered?: boolean;
};

const Loading = ({ color = 'inherit', isCentered }: LoadingProps) => {
  const loader = (
    <Container color={color}>
      <Dot aria-hidden delay={-0.25} />
      <Dot aria-hidden delay={-0.125} />
      <Dot aria-hidden delay={0} />
      <Text>Loading…</Text>
    </Container>
  );

  if (isCentered) {
    return <Center>{loader}</Center>;
  }

  return loader;
};

export default Loading;
