import styled from 'styled-components/macro';
import { Input } from 'styles/inputs.css';
import { type } from 'core/components';
import {
  Button,
  colors,
  mediaQueries,
  Loading,
  Tooltip,
} from 'core/components';

export const ChildAccountInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex-grow: 2;
  margin-bottom: 2rem;
`;
export const AccountIdInputBox = styled(Input)`
  border: none;
`;

export const StyledSuccessImage = styled.img`
  height: 300px;
  width: 300px;
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 4px;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  margin-right: 1rem;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  nth-child(-n + 3) {
    border: 2px solid orange;
    margin-bottom: 1px;
  }
`;

export const FormSections = styled.div`
  position: relative;

  margin: 0;

  @media ${({ theme }) => theme.mediaQueries.desktop} {
    max-width: 760px;
    margin: 0 16px;
    top: 80px;
  }
`;

export const SidebarLink = styled.a`
  display: block;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.text90};
  line-height: 150%;

  :hover {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text90};
  }
`;

export const SidebarTitle = styled(type.Overline)`
  color: ${({ theme }) => theme.colors.black70};
  font-weight: 500;
  margin-bottom: 16px;
  letter-spacing: 0.48px;
  line-height: 120%;

  :not(:first-child) {
    margin-top: 32px;
  }
`;

export const Sidebar = styled.div`
  display: block;
  top: 120px;
  position: sticky;
  height: 100%;
  padding-left: 16px;
  width: 200px;
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 30px; /* Adjust as needed */
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.colors.black30};
  border-radius: 16px;
  box-shadow: 0px 1px 4px 0px ${({ theme }) => theme.colors.text20};
  scroll-margin-top: 200px;
`;

export const Page = styled.div`
  max-width: 1080px;
  margin: 32px auto;

  padding: 0 32px;
`;

export const Header = styled.h3`
  ${({ theme }) => theme.text.headings.h3}
  margin-bottom: 32px;

  @media ${({ theme }) => theme.mediaQueries.desktop} {
    display: none;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem;

  @media ${({ theme }) => theme.mediaQueries.tablet} {
    max-width: 90%;
    margin: 0 auto;
    padding: 0 16px;
  }
`;

export const FormikContainer = styled.div`
  width: 600px;
`;

export const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100vw;
  height: 76px;
  padding: 0 2rem;
  background-color: white;
  border-top: solid 1px ${colors.black20};
`;

export const StyledButton = styled(Button)`
  .right-icon {
    svg {
      path {
        fill: ${({ disabled }) => (disabled ? colors.black50 : colors.white)};
        stroke: none;
      }
    }
  }
  width: 100%;
  // TODO: standarize
  @media ${mediaQueries.tabletAndAbove} {
    width: auto;
    margin-left: 24px;
    white-space: nowrap;
  }
`;

export const SuccessButtons = styled(Button)`
  width: 15rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
`;

export const StyledLoading = styled(Loading)`
  margin-top: 1rem;
`;

export const GreyTooltip = styled(Tooltip)`
  color: ${({ theme }) => theme.colors.black70};
`;

export const ButtonTooltipContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SuccessMessage = styled.div`
  width: 60%;
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 0.5rem;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  min-width: 700px;
  text-align: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
`;
