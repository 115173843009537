import { useEffect } from 'react';
import { useNotificationContext } from 'core/context/NotificationContext';
import {
  fetchCustomer,
  fetchPlanRejectionReasons,
  fetchPrismAggregates,
  fetchScans,
  fetchXrays,
  useFriendlyError,
} from 'pages/OrthoPrism/orthoSlice';

const useErrorToast = (prefix: string) => {
  const { showNotification } = useNotificationContext();

  const error = useFriendlyError(prefix);

  useEffect(() => {
    if (error) {
      showNotification(error, 'error');
    }
  }, [error]);
};

const Notifications = () => {
  useErrorToast(fetchCustomer.type);
  useErrorToast(fetchPlanRejectionReasons.typePrefix);
  useErrorToast(fetchScans.typePrefix);
  useErrorToast(fetchXrays.typePrefix);
  useErrorToast(fetchPrismAggregates.typePrefix);

  return null;
};

export default Notifications;
