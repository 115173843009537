import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Loading, theme } from 'core/components';

import { CUST_CREATOR_ERROR_MESSAGES } from 'types';

import { Materials } from 'types/Material';
import { materialNeedsClinicianAction } from 'pages/Patient/utils';

import {
  selectScans,
  selectScanMaterialState,
  selectAreScansReadyToSubmit,
  fetchScans,
  selectLatestScanMaterialEvaluationSet,
  selectLatestScanMaterialEvaluations,
  selectUnsubmittedScans,
  ScanFragment,
  selectScanSubmissions,
  removeScan,
  removeMaterial,
  addScans,
  uploadScan,
  selectIsSubmitted,
  selectActiveCaseScanTypes,
} from 'pages/Patient/patientSlice';
import { useIsLoading } from 'state/system';
import {
  LinkText,
  UploadContainer,
  AlertContainer,
  Container,
  TextContainer,
} from 'pages/Patient/CaseCreator/DiagnosticMaterials/Scans/UploadScans.css';
import {
  AddScanMutation,
  AddScanMutationVariables,
  AddScanDocument,
  MaterialStates,
  StateTransitions,
  ScanTypes,
} from 'generated/core/graphql';
import { useGQLMutation } from 'hooks/useGQL';
import { selectActiveCase, selectPatient } from 'pages/Patient/patientSlice';
import RejectedMaterialsAlert from 'pages/Patient/CaseCreator/RejectedMaterialsAlert';
import ScanUploadTile from 'pages/Patient/CaseCreator/DiagnosticMaterials/Scans/ScanUploadTile';
import { useAuthContext } from 'context/AuthContext';
import { DEFAULT_LINKS } from 'utils/brands';

import {
  ListContainer,
  ListItem,
  ListItemText,
} from 'pages/Patient/CaseCreator/DiagnosticMaterials/Scans/Styles.css';
import { useImmer } from 'use-immer';
import { AppDispatch } from 'state/store';
import MaterialsTable from 'components/MaterialsTable';
import { MaterialEditBanner } from 'pages/Patient/CaseCreator/DiagnosticMaterials/MaterialEditBanner';
import { useNotificationContext } from 'core/context/NotificationContext';

type ScanViewModel = {
  materialId: string | null;
  filename: string | null;
  isRemoving: boolean;
  isUploading: boolean;
};

type ScanViewModels = {
  [key: string]: ScanViewModel;
};

const UploadScans = ({
  hideUploadTiles = false,
}: {
  hideUploadTiles: boolean;
}) => {
  const { showNotification } = useNotificationContext();
  const [isCopyingScans, setIsCopyingScans] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const isSubmitted = useSelector(selectIsSubmitted);
  const isLoadingScans = useIsLoading(fetchScans.typePrefix);
  const dispatch = useDispatch<AppDispatch>();
  const patient = useSelector(selectPatient);
  const activeCase = useSelector(selectActiveCase);
  const scans = useSelector(selectScans);
  const unsubmittedScans = useSelector(selectUnsubmittedScans);
  const scanSubmissions = useSelector(selectScanSubmissions);
  const scanMaterialState = useSelector(selectScanMaterialState);
  const activeCaseScanTypes = useSelector(selectActiveCaseScanTypes);
  const scansReadyToSubmit = useSelector(selectAreScansReadyToSubmit);
  const latestScanEvaluationSet = useSelector(
    selectLatestScanMaterialEvaluationSet
  );
  const latestScanEvaluations = useSelector(
    selectLatestScanMaterialEvaluations
  );
  const latestScanEvaluation = latestScanEvaluations?.[0];
  const isRejected =
    scanMaterialState?.transition === StateTransitions.Reject &&
    !scansReadyToSubmit;
  const latestScanSubmission = scanSubmissions?.[0];
  const latestScanSubmissionScans =
    latestScanSubmission && !isRejected
      ? scans.filter((scan) =>
          scan.submissions.some(
            (submission) => submission.id === latestScanSubmission.id
          )
        )
      : [];
  const { userInfo } = useAuthContext();
  const { scansExportStl, scansUnzipExportedScans } =
    userInfo?.brandInfo?.configs?.zendesk || {};

  const [scanViewModels, setScanViewModels] = useImmer<ScanViewModels>(
    activeCaseScanTypes.reduce((acc, scanType) => {
      acc[scanType.name] = {
        materialId: null,
        filename: null,
        isRemoving: false,
        isUploading: false,
      };
      return acc;
    }, {} as ScanViewModels)
  );

  useEffect(() => {
    setScanViewModels((draft) =>
      unsubmittedScans.reduce((acc, scan) => {
        const scanType = scan.materialType.name;
        if (!acc[scanType].materialId) {
          acc[scanType] = {
            materialId: scan?.id!,
            filename: scan?.filename!,
            isRemoving: false,
            isUploading: false,
          };
        }
        return acc;
      }, draft as ScanViewModels)
    );
  }, [unsubmittedScans]);

  const [addScan] = useGQLMutation<AddScanMutation, AddScanMutationVariables>(
    AddScanDocument,
    true
  );

  const patientId = Number(patient?.id);
  const caseRef = activeCase?.caseRef!;
  const isLoading = isLoadingScans;

  const uploadPrompt = isRejected
    ? 'Please upload a new set of STL files. '
    : 'Drop STL files in the area below. ';

  const handleSelectFiles = async (
    selectedFiles: FileList | null,
    scanType: ScanTypes
  ) => {
    if (selectedFiles?.length !== 1) {
      showNotification('Only one file can be added at a time', 'error');
      return;
    }

    const [file] = selectedFiles;

    if (file.size === 0) {
      showNotification(CUST_CREATOR_ERROR_MESSAGES.empty_stl_file, 'error');
      Sentry.captureException('Empty STL file upload was attempted');
      return;
    }

    try {
      setScanViewModels((draft) => {
        draft[scanType].isUploading = true;
      });

      await dispatch(
        uploadScan({
          file,
          patientId,
          caseRef,
          scanType,
        })
      ).unwrap();
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      showNotification(err.message, 'error');
      setScanViewModels((draft) => {
        draft[scanType].isUploading = false;
      });
    }
  };

  const handleDeleteFile = async (scanType: ScanTypes) => {
    try {
      setScanViewModels((draft) => {
        draft[scanType].isRemoving = true;
      });
      const materialId = scanViewModels[scanType].materialId;
      if (!materialId) {
        throw Error(
          'Problem deleting scan, please try again or refresh the page'
        );
      }
      await dispatch(removeMaterial({ materialId })).unwrap();
      dispatch(removeScan({ materialId }));
      setScanViewModels((draft) => {
        draft[scanType] = {
          filename: null,
          materialId: null,
          isUploading: false,
          isRemoving: false,
        };
      });
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      showNotification(err.message, 'error');
      setScanViewModels((draft) => {
        draft[scanType].isRemoving = false;
      });
    }
  };

  const handleEditScans = useCallback(async () => {
    try {
      setIsCopyingScans(true);
      setIsEditing(true);

      const copiedScans = await Promise.all(
        latestScanSubmissionScans.map(async (scan) => {
          const addScanResult = await addScan({
            awsFileLocation: scan.awsFileLocation!,
            data: {
              captureDate: scan.data!.captureDate,
            },
            patientId: scan.patientId,
            caseRef: scan.caseRef!,
            scanType: scan.materialType.name as ScanTypes,
            filename: scan.filename!,
          });
          return addScanResult?.addScan?.scan!;
        })
      );

      dispatch(addScans({ materials: copiedScans as ScanFragment[] }));
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      showNotification(err.message, 'error');
    } finally {
      setIsCopyingScans(false);
    }
  }, [latestScanSubmissionScans]);

  if (isLoading || isCopyingScans) {
    return <Loading isCentered />;
  }

  const hasNoClinicianAction = !materialNeedsClinicianAction(scanMaterialState);
  const showMaterialBanner =
    hasNoClinicianAction ||
    scanMaterialState?.state === MaterialStates.NeedsClarification ||
    // It's possible for the lab to upload scans (that were synced with scanner)
    // before the provider submits the case, so we need to properly reflect the
    // presence of submitted scans here.
    (latestScanSubmissionScans.length > 0 &&
      scanMaterialState?.state === MaterialStates.Uploaded);
  const bypassMaterialBanner = !!unsubmittedScans.length || isEditing;

  if (showMaterialBanner && !bypassMaterialBanner) {
    return (
      <>
        <MaterialEditBanner
          handleEdit={handleEditScans}
          materialTypeLabel="Scans"
          submitedDateTime={latestScanSubmission?.createdAt}
          submitedBy={latestScanSubmission?.createdByEmail}
          allowEdit={!isSubmitted && !hasNoClinicianAction}
        />
        <MaterialsTable
          materials={latestScanSubmissionScans! as ScanFragment[]}
        />
      </>
    );
  }

  return (
    <Container>
      {isRejected && (
        <AlertContainer>
          <RejectedMaterialsAlert
            materialClassification={Materials.Scans}
            materials={scans}
            rejectedEvaluationSet={latestScanEvaluationSet}
            rejectedEvaluation={latestScanEvaluation}
          />
        </AlertContainer>
      )}
      <TextContainer>
        <div>
          {uploadPrompt} Make sure scans match the following requirements:
        </div>
        <ListContainer>
          <ListItem>
            <ListItemText>
              Two separate <strong>.stl files</strong> for upper and lower
              scans. If your scanner requires a separate bite scan, please
              upload a third scan and mark the scan type as "bite".
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              The bite scan should be accurate and match the correct bite in the
              photos.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              There’s at least 3-5mm of gingiva throughout the scans.
            </ListItemText>
          </ListItem>
        </ListContainer>
        <div>More guidance on scans:</div>
        <ListContainer>
          <ListItem>
            <LinkText
              href={scansExportStl || DEFAULT_LINKS.SCANS_EXPORT_STL}
              target="_blank"
              rel="noreferrer noopener"
              style={{ color: theme.colors.blue50 }}
            >
              How to export STL files from my scanner
            </LinkText>
          </ListItem>
          <ListItem>
            <LinkText
              href={
                scansUnzipExportedScans ||
                DEFAULT_LINKS.SCANS_UNZIP_EXPORTED_SCANS
              }
              rel="noreferrer noopener"
              target="_blank"
              style={{ color: theme.colors.blue50 }}
            >
              How to unzip exported scans
            </LinkText>
          </ListItem>
        </ListContainer>
      </TextContainer>

      {!hideUploadTiles && (
        <UploadContainer>
          {activeCaseScanTypes.map(({ name: scanType, optional }) => (
            <ScanUploadTile
              key={scanType}
              filename={scanViewModels[scanType].filename}
              scanType={scanType}
              isUploading={scanViewModels[scanType].isUploading}
              isRemoving={scanViewModels[scanType].isRemoving}
              uploadCallback={(files) => handleSelectFiles(files, scanType)}
              removeCallback={() => handleDeleteFile(scanType)}
              optional={!!optional}
            />
          ))}
        </UploadContainer>
      )}
    </Container>
  );
};

export default UploadScans;
