import React, { ReactElement } from 'react';
import {
  ContactContainer,
  ContactSection,
  ContactText,
  ContactTypeTitle,
  HoursText,
  PhoneNumberSubtext,
  ChatSvg,
  MailSvg,
  PhoneSvg,
} from 'pages/ContactUs/ContactUs.css';
import {
  SupportContactOption,
  LIVE_CHAT_SUPPORT_OPTION,
  EMAIL_SUPPORT_OPTION,
  PHONE_SUPPORT_OPTION,
  DEFAULT_SUPPORT_OPTIONS,
} from 'utils/brands';
import {
  PhoneNumber,
  PhoneNumberContainer,
} from 'pages/ContactUs/ContactUs.css';
import { colors, type } from 'core/components';
import useZendesk from 'hooks/useZendesk';
import { formatPhoneNumber } from 'utils/customer';
import { useAuthContext } from 'context/AuthContext';

type PhoneNumberType = {
  phoneNumber: string;
  subtext: string;
};

type ContactInfoProps = {
  phoneNumbers: PhoneNumberType[];
  email: string;
  phoneNumbersHours: string | undefined;
  liveChatHours: string | undefined;
  contactSupportOptions?: SupportContactOption[];
};

const ContactInfo = ({
  phoneNumbers,
  email,
  phoneNumbersHours,
  liveChatHours,
  contactSupportOptions,
}: ContactInfoProps) => {
  const { userInfo } = useAuthContext();
  const { handleOpenWidget, showZendesk } = useZendesk(userInfo);

  const supportOptions = contactSupportOptions
    ? contactSupportOptions
    : DEFAULT_SUPPORT_OPTIONS;

  const liveChatSection = (): ReactElement | undefined => {
    if (showZendesk) {
      return (
        <ContactSection>
          <ChatSvg width={'20px'} height={'20px'} />
          <ContactText>
            <ContactTypeTitle>LIVE CHAT</ContactTypeTitle>
            <type.Link
              onClick={() => {
                handleOpenWidget();
              }}
            >
              Start a live chat
            </type.Link>
            <HoursText>
              {liveChatHours || 'Weekdays 9:00 am - 8:00 pm EST'}
            </HoursText>
          </ContactText>
        </ContactSection>
      );
    }
  };

  const emailSection = (): ReactElement => {
    return (
      <ContactSection>
        <MailSvg stroke={colors.black70} />
        <ContactText>
          <ContactTypeTitle>EMAIL</ContactTypeTitle>
          <type.Link>{email}</type.Link>
        </ContactText>
      </ContactSection>
    );
  };

  const phonesSection = (): ReactElement | undefined => {
    if (phoneNumbers.length) {
      return (
        <ContactSection>
          <PhoneSvg />
          <ContactText>
            <ContactTypeTitle>PHONE</ContactTypeTitle>

            {phoneNumbers.map((phoneNumber, idx) => (
              <PhoneNumberContainer key={idx}>
                <PhoneNumber>
                  {
                    formatPhoneNumber(phoneNumber.phoneNumber)
                      .formatedPhoneNumber
                  }
                </PhoneNumber>
                •<PhoneNumberSubtext>{phoneNumber.subtext}</PhoneNumberSubtext>
              </PhoneNumberContainer>
            ))}
            <HoursText>
              {phoneNumbersHours || 'Weekdays 9:00 am - 8:00 pm EST'}
            </HoursText>
          </ContactText>
        </ContactSection>
      );
    }
  };

  const contactOptions: Map<
    SupportContactOption,
    () => ReactElement | undefined
  > = new Map();
  contactOptions.set(LIVE_CHAT_SUPPORT_OPTION, liveChatSection);
  contactOptions.set(EMAIL_SUPPORT_OPTION, emailSection);
  contactOptions.set(PHONE_SUPPORT_OPTION, phonesSection);

  const renderContactOptions = (): ReactElement[] => {
    const selectedContactOptions: ReactElement[] = [];
    supportOptions.map((option: SupportContactOption) => {
      const sectionRenderer = contactOptions.get(option);
      if (sectionRenderer) {
        const content = sectionRenderer();
        if (content) {
          selectedContactOptions.push(content);
        }
      }
    });

    return selectedContactOptions;
  };

  return <ContactContainer>{renderContactOptions()}</ContactContainer>;
};

export default ContactInfo;
