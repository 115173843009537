import React from 'react';
import { StyledTable } from 'pages/PracticeManagement/Invoices/Invoices.css';
import api from 'state/api';
import { PracticeType } from 'generated/legacy/graphql';
import moment from 'moment';

interface InvoicesProps {
  practice?: PracticeType | null;
}

export const Invoices: React.FC<InvoicesProps> = ({ practice }) => {
  const { data: invoicesResp } = api.useGetInvoicesQuery({
    practiceId: practice?.id ?? '',
  });

  return (
    <>
      <StyledTable
        data={invoicesResp?.edges.map((edge) => edge.node) || []}
        columns={[
          {
            name: 'id',
            label: 'Invoice ID',
          },
          {
            name: 'preriodStartPeriodEndDate',
            label: 'Period Start - End Date',
            options: {
              customBodyRender: (_, tableMeta) => {
                const rowData = invoicesResp?.edges[tableMeta.rowIndex]?.node;
                const startDate = rowData?.periodStart;
                const endDate = rowData?.periodEnd;
                return (
                  <div>
                    {startDate && endDate
                      ? `${moment.utc(startDate).format('MM/DD/YYYY')} - ${moment.utc(endDate).format('MM/DD/YYYY')}`
                      : ''}
                  </div>
                );
              },
            },
          },
          {
            name: 'paymentDueDate',
            label: 'Due Date',
            options: {
              customBodyRender: (value) => {
                return value ? moment.utc(value).format('MM/DD/YYYY') : '';
              },
            },
          },
        ]}
        options={{
          selectableRows: 'none',
          search: false,
          onRowClick: (_, { dataIndex }) => {
            const rowData = invoicesResp?.edges[dataIndex]?.node;
            if (rowData?.id) {
              window.open(`/billing/invoices/${rowData.id}`, '_blank');
            }
          },
          rowHover: true,
          setRowProps: () => ({
            style: { cursor: 'pointer' },
          }),
        }}
      />
    </>
  );
};
