import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import * as propTypes from 'utils/propTypes';
import { useAuthContext } from 'context/AuthContext';

type Props = {
  accessGroups?: string[];
  component: React.ElementType;
};

const ProtectedRoute = ({
  accessGroups,
  component: Component,
  ...otherProps
}: Props & RouteProps) => {
  const { checkHasAccess, isLoggedIn } = useAuthContext();

  return (
    <Route
      {...otherProps}
      render={(props) => {
        if (!isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { redirectPath: props.location?.pathname },
              }}
            />
          );
        }

        if (accessGroups && !checkHasAccess(accessGroups)) {
          return <Redirect to="/" />;
        }

        if (!Component) {
          return null;
        }

        return <Component {...props} />;
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  accessGroups: PropTypes.arrayOf(propTypes.authGroups.isRequired),
  component: PropTypes.func.isRequired,
};

export default ProtectedRoute;
