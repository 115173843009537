import React from 'react';
import {
  Container,
  Title,
  Description,
  TitleContainer,
} from 'components/FormSection/FormSection.css';

const FormSection = ({
  title,
  description,
  children,
  id,
}: {
  title: string;
  description: string | React.ReactNode;
  children?: React.ReactNode;
  id?: string;
}) => {
  return (
    <Container id={id}>
      <TitleContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TitleContainer>
      {children}
    </Container>
  );
};

export default FormSection;
