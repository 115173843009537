import React from 'react';
import {
  Border,
  Container,
  Description,
  InnerContainer,
  Radio,
  Title,
  Flex,
  ButtonContainer,
  ModalContainer,
  ModalButtonContainer,
} from 'components/ShipmentDetails/ShipmentDetails.css';
import {
  Button,
  Grid,
  Box,
  Text,
  colors,
  type,
  Modal,
  SelectInput,
  Loading,
} from 'core/components';
import {
  Address,
  ServiceLevel,
  SupportedCarriers,
} from 'generated/core/graphql';

import {
  buildToAddress,
  EnrichedOrderType,
  IRate,
  useShippingContext,
} from 'pages/Shipping/utils';

import { GetOrderItemsCoreWithCustomerQuery } from 'generated/core/graphql';
import { useTranslation } from 'react-i18next';

type DropdownOption = {
  label: string;
  value: string;
};

type OrderItemType =
  GetOrderItemsCoreWithCustomerQuery['orderItems']['edges'][0]['node'];

interface IPropsShipmentRates {
  shippingRate: {
    carrier: SupportedCarriers;
    title: string;
    serviceLevels: ServiceLevel[];
  };
  selectedRate: IRate | null;
  setSelectedRate: (rate: IRate) => void;
}

interface IPropsBoxSizeInformation {
  boxSize?: {
    length: number;
    width: number;
    height: number;
  };
  message?: string;
}
const RMS69_BOX = {
  length: 12,
  width: 10,
  height: 5,
};
const RMS67_BOX = {
  length: 8,
  width: 5,
  height: 3,
};
const RMS61_BOX = {
  length: 8,
  width: 5,
  height: 3,
};
const productTypeMap = {
  ALIGNER_GOOD: {
    boxSize: RMS69_BOX,
    message: '',
  },
  ALIGNER_KIT: {
    boxSize: RMS69_BOX,
    message: '',
  },
  RETAINER: {
    boxSize: RMS67_BOX,
    message: '',
  },
  REPLACEMENT_TRAY: {
    boxSize: RMS67_BOX,
    message: 'RMS-0069 Box is needed if order item contains more than 5 steps',
  },
  WHITENING_ZOOM: {
    boxSize: RMS61_BOX,
    message: '',
  },
  REFINEMENTS: {
    boxSize: RMS67_BOX,
    message: '',
  },
};

type ProductType = keyof typeof productTypeMap;

interface IPropsShipmentInformation {
  address: Address;
}

const ShipmentRatesSelection = ({
  shippingRate,
  selectedRate,
  setSelectedRate,
}: IPropsShipmentRates) => {
  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justifyContent="space-between"
    >
      <Grid
        onClick={() => setSelectedRate({ carrier: shippingRate.carrier })}
        style={{ cursor: 'pointer' }}
      >
        <Radio
          type="radio"
          name="ShippingCarriers"
          checked={selectedRate?.carrier === shippingRate.carrier}
          onClick={() => setSelectedRate({ carrier: shippingRate.carrier })}
        />
        {shippingRate.title}
      </Grid>

      {selectedRate?.carrier === shippingRate.carrier && (
        <Grid
          style={{ display: 'flex', gap: '24px' }}
          direction="row"
          justifyContent="space-between"
        >
          Select Service Level
          <Grid style={{ gap: '24px', display: 'flex' }}>
            {shippingRate.serviceLevels.map((serviceLevel) => (
              <Grid>
                <Radio
                  type="radio"
                  name="ShippingServiceLevels"
                  checked={selectedRate?.serviceLevel === serviceLevel}
                  onClick={() =>
                    setSelectedRate({
                      carrier: shippingRate.carrier,
                      serviceLevel,
                    })
                  }
                />
                {serviceLevel}
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const ShipmentStatus = ({ status }: { status: string }) => {
  return (
    <Grid container direction="row">
      {status}
    </Grid>
  );
};

const BoxSizeInformation = ({ boxSize, message }: IPropsBoxSizeInformation) => {
  return (
    <Grid container direction="row">
      {boxSize?.length || 'X'}" x {boxSize?.width || 'Y'}" x{' '}
      {boxSize?.height || 'Z'}"
      <br />
      {message}
    </Grid>
  );
};

const Actions = () => {
  const {
    selectedOrderItem,
    shippingLabelUrl,
    loading,
    isFormValid,
    shipItem,
    printLabel,
    regenerateLabel,
    setIsModalOpen,
    deviceName,
    handleDeviceSelection,
    availablePrinters,
    loadingGetPrinters,
  } = useShippingContext();

  const dropdownOptions = availablePrinters?.map((value) => {
    return {
      label: value.name ?? '',
      value: value.name ?? '',
    };
  });

  if (shippingLabelUrl) {
    return (
      <Box>
        <Flex>
          <Button
            buttonType="secondary"
            onClick={() => regenerateLabel()}
            disabled={!isFormValid}
            isLoading={loading}
          >
            Regenerate Label
          </Button>
          <Button
            isLoading={loading}
            buttonType="secondary-outline"
            disabled={!shippingLabelUrl}
            onClick={() => printLabel()}
          >
            Print Label
          </Button>
        </Flex>
        <Box mt=".5rem">
          <Description>Select a device printer</Description>
          {loadingGetPrinters ? (
            <>
              <Loading />
            </>
          ) : (
            <>
              <SelectInput
                placeholder="Select A Printer"
                defaultValue={{
                  label: deviceName ?? '',
                  value: deviceName ?? '',
                }}
                onChange={(e) =>
                  handleDeviceSelection((e as DropdownOption).value)
                }
                options={dropdownOptions}
              />
            </>
          )}
        </Box>
      </Box>
    );
  }
  return (
    <ButtonContainer>
      <Button
        buttonType="secondary"
        onClick={() =>
          selectedOrderItem!.quantity > 1 ? setIsModalOpen(true) : shipItem()
        }
        disabled={!isFormValid}
        isLoading={loading}
      >
        Create Shipment
      </Button>
    </ButtonContainer>
  );
};

const ShipmentInformation = ({ address }: IPropsShipmentInformation) => {
  return (
    <Grid container direction="row" justifyContent="space-between">
      <Grid>
        <Box>
          <Text color={address.name ? colors.text80 : colors.red50}>
            {address.name ?? 'Name is a required field'}
          </Text>
          {address?.company && (
            <Text color={colors.text80}>{address.company}</Text>
          )}
          <Text color={address.addressLines ? colors.text80 : colors.red50}>
            {(
              address.addressLines ?? ['Address Lines are a required field.']
            ).join(' ')}
          </Text>
        </Box>
        <Flex>
          <Text color={address.city ? colors.text80 : colors.red50}>
            {address.city ?? 'City is a required field'},{' '}
          </Text>
          <Text
            ml="5px"
            color={address.adminRegion ? colors.text80 : colors.red50}
          >
            {address.adminRegion ?? 'Admin Region is a required field.'}
          </Text>
          <Text
            ml="5px"
            color={address.postalCode ? colors.text80 : colors.red50}
          >
            {address.postalCode ?? 'Postal Code is a required field.'}
          </Text>
        </Flex>
      </Grid>
      <Actions />
    </Grid>
  );
};

const ShipmentDetails = ({
  orderItem,
  order,
  selectedRate,
  setSelectedRate,
}: {
  orderItem?: OrderItemType | null;
  order?: EnrichedOrderType | null;
  selectedRate: IRate;
  setSelectedRate({ carrier, serviceLevel }: IRate): void;
}) => {
  const {
    loading,
    isModalOpen,
    setIsModalOpen,
    shipItem,
    isFormValid,
    shipmentStatus,
  } = useShippingContext();
  const { t } = useTranslation();
  if (!orderItem) {
    return <Container empty />;
  }
  const shippingRates = [
    {
      carrier: SupportedCarriers.Ups,
      title: SupportedCarriers.Ups,
      serviceLevels: [ServiceLevel.NextDay, ServiceLevel.Standard],
    },
    {
      carrier: SupportedCarriers.Upssurepost,
      title: 'UPS SurePost',
      serviceLevels: [ServiceLevel.Standard],
    },
  ];

  const address = buildToAddress(
    orderItem.order.shippingAddress || null,
    order,
    orderItem.order.customer || null
  );
  let boxSize, message;
  const productType: ProductType | undefined = orderItem.productVariant.product
    .productType as ProductType | undefined;
  if (productType && `${productType}` in productTypeMap) {
    boxSize = productTypeMap[productType].boxSize;
    message = productTypeMap[productType].message;
  }
  return (
    <Container>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalContainer>
          <type.H2>
            {t('shipping.order_item_quantity_greater_than_one')}
            "1"
            <br />
          </type.H2>
          <div>
            {t('shipping.quantity_description')}
            <b>{orderItem.productVariant?.label}</b>: {orderItem.quantity}
            <br />
            {t('shipping.confirm')}
          </div>
          <ModalButtonContainer>
            <Button
              buttonType="secondary"
              onClick={() => {
                setIsModalOpen(false);
                shipItem();
              }}
              disabled={!isFormValid}
              isLoading={loading}
            >
              {t('shipping.create_shipment_button')}
            </Button>
            <Button onClick={() => setIsModalOpen(false)} isLoading={loading}>
              {t('common.cancel')}
            </Button>
          </ModalButtonContainer>
        </ModalContainer>
      </Modal>
      <Title padding>Shipment details</Title>
      <Border />
      <InnerContainer>
        <Title>Shipping rates</Title>
        {shippingRates.map((shippingRate, index) => (
          <ShipmentRatesSelection
            key={index}
            shippingRate={shippingRate}
            selectedRate={selectedRate}
            setSelectedRate={setSelectedRate}
          />
        ))}
        <Border />
        <Title>Shipment status</Title>
        <ShipmentStatus status={shipmentStatus ?? ''} />
        <Border />
        <Title>Box size</Title>
        <BoxSizeInformation boxSize={boxSize} message={message} />
        <Border />
        <Title>Shipping destination</Title>
        <ShipmentInformation address={address!} />
      </InnerContainer>
    </Container>
  );
};

export default ShipmentDetails;
