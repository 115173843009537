import React, { useState } from 'react';
import DocumentTitle from 'components/DocumentTitle';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import {
  TreatmentPlanIframe,
  Container,
  ModalContainer,
  ButtonContainer,
  TextArea,
} from 'pages/TreatmentPlanEditor/TreatmentPlanEditor.css';
import Button from 'core/components/Button';
import { Loading, Modal } from 'core/components';
import api from 'state/api';
import { MaterialEvaluationTypes } from 'generated/core/graphql';
import { useNotificationContext } from 'core/context/NotificationContext';

const TreatmentPlanEditor = ({ match }: RouteComponentProps) => {
  const query = useQuery();
  const tpStagingId = query.get('treatment-plan-staging-id');
  const evaluationType = query.get('evaluation-type');
  const { showNotification } = useNotificationContext();
  const { data: tpStagingData, isLoading: tpStagingLoading } =
    api.useGetTreatmentPlanStagingByIdQuery(
      {
        id: tpStagingId ?? '',
      },
      {
        skip: !tpStagingId,
      }
    );
  const [createDfaTreatmentPlan, { isLoading: createDfaTreatmentPlanLoading }] =
    api.useCreateDfaTreatmentPlanMutation();

  const [isSubmissionModalOpen, setIsSubmissionModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [modificationNotes, setModificationNotes] = useState('');

  const handleDfaModification = async () => {
    if (
      !(
        evaluationType &&
        [
          MaterialEvaluationTypes.ProClinicianEvaluation,
          MaterialEvaluationTypes.OrthodonticEvaluation,
        ].includes(evaluationType as MaterialEvaluationTypes)
      )
    ) {
      showNotification('Invalid Evaluation Type', 'error');
      return;
    }
    try {
      await createDfaTreatmentPlan({
        treatmentPlanStagingId: tpStagingId as string,
        evaluationType: evaluationType as MaterialEvaluationTypes,
        notes: modificationNotes,
      }).unwrap();
      showNotification('New Treatment Plan Created Successfully', 'success');
      setIsSubmissionModalOpen(false);
      setIsSuccessModalOpen(true);
      setModificationNotes('');
    } catch (e) {
      showNotification(
        'Failed to create new Treatment Plan, please contact Support',
        'error'
      );
    }
  };
  return (
    <Switch>
      <Route path={match.path} exact>
        <DocumentTitle title="Treatment Plan Editor">
          <Modal
            onClose={() => {}}
            isOpen={isSuccessModalOpen}
            hideCloseButton={true}
          >
            <ModalContainer>
              <h1>Treatment Plan Modification successfully submitted</h1>
              <ButtonContainer>
                <Button buttonType="secondary" onClick={() => window.close()}>
                  Close
                </Button>
              </ButtonContainer>
            </ModalContainer>
          </Modal>
          <Modal
            onClose={() => setIsSubmissionModalOpen(false)}
            isOpen={isSubmissionModalOpen}
          >
            <ModalContainer>
              <h1>Submit Modification</h1>
              <p>
                Please provide a brief summary of your modification to help the
                clinical team accurately and efficently restage this treatment
                plan.
              </p>
              <p>
                Confirm you saved your draft before submiting your
                modifications.
              </p>
              <TextArea
                placeholder="Please provide notes for this modification"
                value={modificationNotes}
                onChange={(e) => setModificationNotes(e?.target?.value)}
              />
              <ButtonContainer>
                <Button
                  buttonType="black-outline"
                  onClick={() => setIsSubmissionModalOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  buttonType="secondary"
                  onClick={handleDfaModification}
                  disabled={createDfaTreatmentPlanLoading || !modificationNotes}
                >
                  Submit Modifications
                </Button>
              </ButtonContainer>
            </ModalContainer>
          </Modal>
          <Container>
            <Button
              buttonType="secondary"
              onClick={() => setIsSubmissionModalOpen(true)}
            >
              Submit Modification
            </Button>
          </Container>
          {!tpStagingLoading && tpStagingData?.editorUrl ? (
            <TreatmentPlanIframe
              title={'Dynamic Treatment Plan Viewer'}
              src={tpStagingData?.editorUrl}
            />
          ) : (
            <Loading />
          )}
        </DocumentTitle>
      </Route>
    </Switch>
  );
};

export default TreatmentPlanEditor;
