import styled from 'styled-components/macro';

import { Button } from 'core/components';

export const ToothActionButtons = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
`;

export const ToothStateButton = styled(Button)<{
  isSelected?: boolean;
  disabled?: boolean;
  strokeColor?: string;
}>`
  ${({ theme, isSelected, disabled, strokeColor }) =>
    isSelected || disabled
      ? `
      background-color:${strokeColor};
      color: ${theme.colors.white};
      
      &:hover {
        background-color: ${strokeColor};
      }
      `
      : `
    border-color: ${theme.colors.black50};
    color: ${theme.colors.black50};



    &:hover {
      background-color: ${theme.colors.black05};
      border-color: ${theme.colors.black70};
      color: ${theme.colors.black70};
    }
  `}

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
  span {
    align-items: center;
    display: flex;

    svg {
      margin-right: 4px;
      transform: scale(1.2);
    }
    path {
      stroke: ${({ theme, isSelected, disabled }) =>
        isSelected || disabled ? theme.colors.white : theme.colors.black50};
    }
  }
`;
