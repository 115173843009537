import { NotificationVariant } from 'core/context/NotificationContext';
import styled, { css } from 'styled-components';

export const contentStyles = css<{ variant?: NotificationVariant | null }>`
  && {
    padding-left: 1.25rem;
    background-repeat: no-repeat;
    background-position: 0.875rem center;
    background-size: 1.25rem 1.25rem;

    ${({ variant, theme }) =>
      variant === 'info' &&
      css`
        color: ${theme.palette.text.main};
        background-color: ${theme.palette.info.main};
      `};

    ${({ variant, theme }) =>
      variant === 'success' &&
      css`
        padding-left: 2.5rem;
        color: ${theme.palette.success.contrastText};
        background-color: ${theme.palette.success.main};
        background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cg fill="%23ffffff"%3E%3Cpath fill="none" d="M0 0h24v24H0z" /%3E%3Cpath d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /%3E%3C/g%3E%3C/svg%3E');
      `};

    ${({ variant, theme }) =>
      variant === 'error' &&
      css`
        padding-left: 2.5rem;
        color: ${theme.palette.error.contrastText};
        background-color: ${theme.palette.error.main};
        background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cg fill="%23ffffff"%3E%3Cpath fill="none" d="M0 0h24v24H0z" /%3E%3Cpath d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" /%3E%3C/g%3E%3C/svg%3E');
      `};
  }

  .message {
    padding: 0.6875rem 0 0.4375rem;
    font-family: ${({ theme }) =>
      `${theme.fonts.modernEra},${theme.fonts.sansSerifStack}`};
    line-height: 1.3;
  }
`;

export const CloseButton = styled.button`
  width: 1.125rem;
  height: 1.125rem;
  fill: currentColor;
`;
