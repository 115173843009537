import api, { ExtractReturnType } from 'state/api';

export type PracticeLeadData = ExtractReturnType<
  typeof api.useLazyGetPracticeLeadDataQuery
>;

export type AccountLeadData = ExtractReturnType<
  typeof api.useLazyGetAccountLeadDataQuery
>;

export enum ANCHOR_TAGS {
  PRACTICE_INFORMATION = 'practice_information',
  BILLING_INFORMATION = 'billing_information',
  ADDRESS_INFORMATION = 'address_information',
  DOCTOR_INFORMATION = 'doctor_information',
}
