import React from 'react';

export const AndTariffsLink = ({ hidden }: { hidden: boolean }) => (
  <span hidden={hidden}>
    and{' '}
    <a
      rel="noreferrer"
      target="_blank"
      href="https://candidpro.zendesk.com/hc/en-us/articles/30373170739223-Tariff"
    >
      tariffs
    </a>{' '}
  </span>
);

export default AndTariffsLink;
