import React from 'react';

import { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Menu,
  MenuItem,
  Typography,
  Box,
  CircularProgress,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MUIDataTable, { Responsive, MUIDataTableMeta } from 'mui-datatables';
import api, { ExtractReturnType } from 'state/api';

export type Doctor = ExtractReturnType<typeof api.useSearchDoctorQuery>[0];
export type Practice = ExtractReturnType<
  typeof api.useSearchDoctorQuery
>[0]['practices'][0];
type SelectedRowType = {
  index: number;
  dataIndex: number;
};
interface TransferPatientModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onTransfer: (doctor: Doctor, selectedPractice: Practice) => void;
  brandName?: string | null;
  originDoctor?: Doctor | null;
}

export default function TransferPatientModal({
  isOpen,
  setIsOpen,
  onTransfer,
  brandName,
  originDoctor,
}: TransferPatientModalProps) {
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [selectedDoctor, setSelectedDoctor] = useState<Doctor | null>(null);
  const [doctorPractices, setDoctorPractices] = useState<
    Record<string, Practice>
  >({});
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [activeDoctorId, setActiveDoctorId] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchDoctor, searchResult] = api.useLazySearchDoctorQuery();

  // Debounced search function
  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (searchQuery.trim()) {
        setIsLoading(true);
        try {
          await searchDoctor({
            search: searchQuery,
            brandName,
          }).unwrap();
        } catch (error) {
          console.error('Error searching doctors:', error);
          setDoctors([]);
        } finally {
          setIsLoading(false);
        }
      } else {
        setDoctors([]);
      }
    }, 300); // 300ms delay

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery, brandName]);

  // useEffect to update doctors when search result changes
  useEffect(() => {
    if (searchResult.data) {
      setDoctors(searchResult.data);
      // Initialize doctorPractices with the first practice of each doctor
      setDoctorPractices(() => {
        const newDoctorPractices: Record<string, Practice> = {};
        searchResult.data?.forEach((doctor) => {
          newDoctorPractices[doctor.id] = doctor.practices[0];
        });
        return newDoctorPractices;
      });
    }
  }, [searchResult.data]);

  const mappedDoctor = useMemo(() => {
    return doctors.map((doctor) => ({
      id: doctor.id,
      fullName: doctor.fullName,
      email: doctor.email,
      practices: doctor.practices,
    }));
  }, [doctors]);

  // Create a custom theme for MUIDataTable
  const handleOpenPracticeMenu = (
    event: React.MouseEvent<HTMLElement>,
    doctorId: string
  ) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
    setActiveDoctorId(doctorId);
  };

  const handleClosePracticeMenu = () => {
    setMenuAnchorEl(null);
    setActiveDoctorId(null);
  };

  const handleSelectPractice = (doctorId: string, practice: Practice) => {
    setDoctorPractices((prev) => ({
      ...prev,
      [doctorId]: practice,
    }));
    handleClosePracticeMenu();
  };

  const handleTransfer = async () => {
    if (selectedDoctor) {
      await onTransfer(
        selectedDoctor,
        doctorPractices[selectedDoctor.id] || selectedDoctor.practices[0]
      );
      resetState();
    }
  };

  // Reset selected doctor when modal closes
  const resetState = () => {
    setIsOpen(false);
    setSelectedDoctor(null);
    setDoctorPractices({});
    setSearchQuery('');
    setDoctors([]);
  };

  const handleClose = () => {
    setIsOpen(false);
    resetState();
  };

  // Custom practice selection component for the table
  const PracticeSelector = useCallback(
    ({ doctorId, practices }: { doctorId: string; practices: Practice[] }) => {
      const selectedPractice = doctorPractices[doctorId] || practices[0]?.name;
      return (
        <Button
          variant="outlined"
          size="small"
          endIcon={<ArrowDropDownIcon />}
          onClick={(e) => handleOpenPracticeMenu(e, doctorId)}
        >
          {selectedPractice?.name}
        </Button>
      );
    },
    [doctorPractices]
  );

  // Define columns for MUIDataTable
  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'fullName',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'practices',
      label: 'Practice',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_value: string[], tableMeta: MUIDataTableMeta) => {
          const doctorId = tableMeta.rowData[0]; // ID is in the first column
          const practices =
            doctors.find((d) => d.id === doctorId)?.practices || [];
          return <PracticeSelector doctorId={doctorId} practices={practices} />;
        },
      },
    },
  ];

  // Define options for MUIDataTable
  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: 'single' as const,
    selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: true,
    responsive: 'standard' as Responsive,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10],
    tableBodyHeight: '300px',
    search: true,
    searchOpen: true,
    searchPlaceholder: 'Search by ID, name, or email',
    onSearchChange: (searchText: string | null) => {
      setSearchQuery(searchText || '');
    },
    onRowSelectionChange: (
      _rowsSelected: SelectedRowType[],
      allRowsSelected: SelectedRowType[]
    ) => {
      if (allRowsSelected.length > 0) {
        const index = allRowsSelected[0].index;
        setSelectedDoctor(doctors[index]);
      } else {
        setSelectedDoctor(null);
      }
    },
    textLabels: {
      body: {
        noMatch: searchQuery.trim()
          ? isLoading
            ? 'Searching...'
            : 'No doctors found'
          : 'Enter ID, name, or email to search for doctors',
      },
    },
  };
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {originDoctor
          ? `Transfer all patients from ${originDoctor.fullName} to another doctor/practice`
          : 'Transfer patient to another doctor/practice'}
      </DialogTitle>

      <DialogContent>
        <MUIDataTable
          title={
            isLoading ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress size={24} />
                <Typography>Searching doctors...</Typography>
              </Box>
            ) : (
              ''
            )
          }
          data={mappedDoctor}
          columns={columns}
          options={options}
        />
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleClosePracticeMenu}
        >
          {activeDoctorId &&
            doctors
              .find((d) => d.id === activeDoctorId)
              ?.practices.map((practice: Practice) => (
                <MenuItem
                  key={`${activeDoctorId}-${practice.id}`}
                  onClick={() => handleSelectPractice(activeDoctorId, practice)}
                >
                  {practice.name}
                  {(doctorPractices[activeDoctorId] ||
                    doctors.find((d) => d.id === activeDoctorId)
                      ?.practices[0]) === practice && (
                    <CheckIcon fontSize="small" />
                  )}
                </MenuItem>
              ))}
        </Menu>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={async () => handleTransfer()}
          color="primary"
          variant="contained"
          disabled={!selectedDoctor || !doctorPractices[selectedDoctor?.id]}
        >
          Transfer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
