import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import moment from 'moment';
import { DocumentNode } from 'graphql';

import {
  Maybe,
  PrismAggregatesDocument,
  PrismAggregatesQuery,
  PrismAggregatesQueryVariables,
  SubmissionItemTransitions,
  TransitionPrismSubmissionDocument,
  TransitionPrismSubmissionMutation,
  TransitionPrismSubmissionMutationVariables,
  TransitionPrismSubmissionItemDocument,
  TransitionPrismSubmissionItemMutation,
  TransitionPrismSubmissionItemMutationVariables,
  TransitionSubmissionInput,
  TransitionSubmissionItemInput,
  SubmissionTransitions,
  PhotoTypeFieldsFragment,
  CustomerQueryVariables,
  CustomerDocument,
  CustomerQuery,
  AddressType,
} from 'generated/legacy/graphql';

import {
  GetCasesQuery,
  GetCasesQueryVariables,
  GetCasesDocument,
  GetIntakeFormByCaseRefQuery,
  GetIntakeFormByCaseRefQueryVariables,
  GetIntakeFormByCaseRefDocument,
  GetXraysByCaseRefQuery,
  GetXraysByCaseRefQueryVariables,
  GetXraysByCaseRefDocument,
  GetScansByCaseRefQuery,
  GetScansByCaseRefQueryVariables,
  GetScansByCaseRefDocument,
  GetMaterialEvaluationRejectionReasonsQuery,
  GetMaterialEvaluationRejectionReasonsQueryVariables,
  GetMaterialEvaluationRejectionReasonsDocument,
  Intake,
  GetFormQuery,
  GetTreatmentPlanStagingsByCaseRefDocument,
  GetTreatmentPlanStagingsByCaseRefQuery,
  GetTreatmentPlanStagingsByCaseRefQueryVariables,
  TreatmentPlanStaging,
  MaterialStates,
  MaterialEvaluationTypes,
  CaseState as RawCaseState,
  CancelCaseMutationVariables,
  CancelCaseMutation,
  CancelCaseDocument,
  ReopenCaseMutation,
  ReopenCaseMutationVariables,
  ReopenCaseDocument,
} from 'generated/core/graphql';

import { client as apolloClient, coreClient } from 'gql/clients';
import { ApolloClientType } from 'gql/GraphQLProvider';
import { RootState } from 'state/store';
import { registerFriendlyErrors } from 'state/system/slice';
import { autoQuery } from 'state/system';
import { sortByDate, sortByCreated, Sort, splitList } from 'utils/prism';
import { fetchRejectionReasons as fetchRejectionReasonsREST } from 'api/case';
import { Patient } from 'types/fromApi';

import {
  PhotoData,
  PhotoReasonsType,
  PatientReasonsType,
  RejectionReason,
} from 'pages/OrthoPrism/types';

import { TabLabelType, TabLabel } from 'components/Compare';

import { LegacyMaterialStates } from 'types/Material';
import { FileInfo } from 'utils/materials';
import { OperationVariables } from '@apollo/client';
import { ProviderFacingStates } from 'types/Case';
import { Nullable } from 'utils/types';

// TODO move to better place & try to get MaterialTypes from gql
type EvaluationRejectionReason =
  GetMaterialEvaluationRejectionReasonsQuery['getMaterialEvaluationRejectionReasons'][0];
type MaterialEvaluationRejectionReasonMap = {
  [key: string]: EvaluationRejectionReason[] | undefined;
};

export type CoreCase = GetCasesQuery['getCases'][0];

// TODO remove 'overview' after refactor (tdror)
export type CaseState = Omit<
  RawCaseState,
  'case' | 'overview' | 'isActive' | 'transitionReason'
>;

type OrthoState = {
  currentTabId: TabLabelType;
  customer: Nullable<Patient>;
  cases: GetCasesQuery['getCases'];
  hasChanges: boolean;
  intakeForms: GetIntakeFormByCaseRefQuery['getIntakeMaterialsByCaseRef'];
  planRejectionReasons: RejectionReason[];
  materialEvaluationRejectionReasonsMap: MaterialEvaluationRejectionReasonMap;
  prismAggregates: PrismAggregatesQuery['prismAggregates'];
  selectedCase: Maybe<CoreCase>;
  treatmentPlanStagings: GetTreatmentPlanStagingsByCaseRefQuery['getTreatmentPlanStagingsByCaseRef'];
  xrays: GetXraysByCaseRefQuery['getXrayMaterialsByCaseRef'];
  scans: GetScansByCaseRefQuery['getScanMaterialsByCaseRef'];
  rxForm: GetFormQuery['getForm'];
  airwayForm: GetFormQuery['getForm'];
  postSubmitPrismClarificationInfo: NeedsClarificationInfo | null;
};

const initialState: OrthoState = {
  currentTabId: TabLabel.TreatmentGoals,
  customer: null,
  cases: [],
  hasChanges: false,
  intakeForms: [],
  planRejectionReasons: [],
  materialEvaluationRejectionReasonsMap: {},
  prismAggregates: null,
  selectedCase: null,
  treatmentPlanStagings: [],
  xrays: [],
  scans: [],
  rxForm: null,
  airwayForm: null,
  postSubmitPrismClarificationInfo: null,
};

type SetterPayload<K extends keyof OrthoState> = {
  key: K;
  value: OrthoState[K];
};

const createGraphQLFetcher = <
  QueryType,
  VariableType extends OperationVariables,
>(
  actionType: string,
  query: DocumentNode,
  setter: (data: Maybe<QueryType>) => SetterPayload<keyof OrthoState>,
  client: ApolloClientType = apolloClient
) =>
  createAsyncThunk(actionType, async (variables: VariableType, store) => {
    try {
      const { data } = await client.query<QueryType, VariableType>({
        query,
        variables,
      });

      if (setter) {
        store.dispatch(orthoSlice.actions.fetched(setter(data)));
      }

      return data;
    } catch (e) {
      if (setter) {
        store.dispatch(orthoSlice.actions.fetched(setter(null)));
      }
      throw e;
    }
  });

export const fetchCustomer =
  autoQuery.createQueryAction<CustomerQueryVariables>(
    'ortho/fetchCustomer',
    CustomerDocument,
    'ortho/setCustomer'
  );

export const fetchCases = createGraphQLFetcher<
  GetCasesQuery,
  GetCasesQueryVariables
>(
  'ortho/fetchCases',
  GetCasesDocument,
  (data) => {
    const cases = data?.getCases || initialState.cases;
    cases.sort(sortByCreated(Sort.Desc));
    return {
      key: 'cases',
      value: cases,
    };
  },
  coreClient
);

export const fetchTreatmentPlanStagings = createGraphQLFetcher<
  GetTreatmentPlanStagingsByCaseRefQuery,
  GetTreatmentPlanStagingsByCaseRefQueryVariables
>(
  'ortho/fetchTreatmentPlanStagings',
  GetTreatmentPlanStagingsByCaseRefDocument,
  (data) => {
    const treatmentPlanStagings =
      data?.getTreatmentPlanStagingsByCaseRef ||
      initialState.treatmentPlanStagings;
    treatmentPlanStagings.sort(sortByCreated(Sort.Desc));
    return {
      key: 'treatmentPlanStagings',
      value: treatmentPlanStagings,
    };
  },
  coreClient
);

export const fetchIntakeForms = createGraphQLFetcher<
  GetIntakeFormByCaseRefQuery,
  GetIntakeFormByCaseRefQueryVariables
>(
  'ortho/fetchIntakeForms',
  GetIntakeFormByCaseRefDocument,
  (data) => ({
    key: 'intakeForms',
    value: data?.getIntakeMaterialsByCaseRef || initialState.intakeForms,
  }),
  coreClient
);

export const fetchScans = createGraphQLFetcher<
  GetScansByCaseRefQuery,
  GetScansByCaseRefQueryVariables
>(
  'ortho/fetchScans',
  GetScansByCaseRefDocument,
  (data) => {
    const scans = data?.getScanMaterialsByCaseRef || initialState.scans;
    scans.sort(sortByCreated(Sort.Desc));
    return {
      key: 'scans',
      value: scans,
    };
  },
  coreClient
);

export const fetchXrays = createGraphQLFetcher<
  GetXraysByCaseRefQuery,
  GetXraysByCaseRefQueryVariables
>(
  'ortho/fetchXrays',
  GetXraysByCaseRefDocument,
  (data) => {
    const xrays = data?.getXrayMaterialsByCaseRef || initialState.xrays;
    xrays.sort(sortByCreated(Sort.Desc));
    return {
      key: 'xrays',
      value: xrays,
    };
  },
  coreClient
);

export const fetchPrismAggregates = createGraphQLFetcher<
  PrismAggregatesQuery,
  PrismAggregatesQueryVariables
>('ortho/fetchPrismAggregates', PrismAggregatesDocument, (data) => {
  if (!data) {
    return {
      key: 'prismAggregates',
      value: initialState.prismAggregates,
    };
  }

  const aggregates = data.prismAggregates ?? [];
  const sortedAggregates = [...aggregates].sort((a, b) =>
    sortByDate(a?.createdAt!, b?.createdAt!)
  );

  return {
    key: 'prismAggregates',
    value: sortedAggregates,
  };
});

export const fetchMaterialEvaluationRejectionReasonsMap = createGraphQLFetcher<
  GetMaterialEvaluationRejectionReasonsQuery,
  GetMaterialEvaluationRejectionReasonsQueryVariables
>(
  'ortho/fetchMaterialEvaluationRejectionReasons',
  GetMaterialEvaluationRejectionReasonsDocument,
  (data) => {
    if (!data) {
      return {
        key: 'materialEvaluationRejectionReasonsMap',
        value: initialState.materialEvaluationRejectionReasonsMap,
      };
    }
    // graphql makes it weird to return a map
    // this maps the rejection reasons to the material types
    const reasonMapping = {} as MaterialEvaluationRejectionReasonMap;
    const materialEvaluationRejectionReasons =
      data.getMaterialEvaluationRejectionReasons || [];
    materialEvaluationRejectionReasons.forEach((reason) => {
      reason.materialTypes.forEach((materialType) => {
        if (!reasonMapping[materialType.name]) {
          reasonMapping[materialType.name] = [];
        }
        reasonMapping[materialType.name]!.push(reason);
      });
    });
    return {
      key: 'materialEvaluationRejectionReasonsMap',
      value: reasonMapping,
    };
  },
  coreClient
);

export const fetchPlanRejectionReasons = createAsyncThunk(
  'ortho/fetchPlanRejectionReasons',
  fetchRejectionReasonsREST
);

export const transitionPhoto = createAsyncThunk(
  'ortho/transitionPhoto',
  async (input: TransitionSubmissionItemInput) => {
    const { data } = await apolloClient.mutate<
      TransitionPrismSubmissionItemMutation,
      TransitionPrismSubmissionItemMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: TransitionPrismSubmissionItemDocument,
      variables: {
        input,
      },
    });

    return data;
  }
);

export const cancelCoreCase = createAsyncThunk(
  'patient/cancelCase',
  async (input: CancelCaseMutationVariables) => {
    const { data } = await coreClient.mutate<
      CancelCaseMutation,
      CancelCaseMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: CancelCaseDocument,
      variables: input,
    });

    return data;
  }
);

export const reopenCoreCase = createAsyncThunk(
  'patient/reopenCase',
  async (input: ReopenCaseMutationVariables) => {
    const { data } = await coreClient.mutate<
      ReopenCaseMutation,
      ReopenCaseMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: ReopenCaseDocument,
      variables: input,
    });

    return data;
  }
);

type TransitionPrismSubbmissionThunkInput = TransitionSubmissionInput & {
  thunkOptions?: {
    refreshPrismAggregates?: boolean;
  };
};

const transitionPrismSubmission = createAsyncThunk(
  'ortho/transitionPrismSubmission',
  async (thunkInput: TransitionPrismSubbmissionThunkInput, store) => {
    const { thunkOptions, ...input } = thunkInput;
    const { data } = await apolloClient.mutate<
      TransitionPrismSubmissionMutation,
      TransitionPrismSubmissionMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: TransitionPrismSubmissionDocument,
      variables: {
        input,
      },
    });

    // default to older functionality
    const refreshOnSuccess = thunkOptions?.refreshPrismAggregates ?? true;
    if (refreshOnSuccess) {
      const currentCase = selectSelectedCase(store.getState() as RootState);
      const caseRef = currentCase?.caseRef;

      if (caseRef) {
        store.dispatch(fetchPrismAggregates({ caseRef }));
      }
    }

    return data;
  }
);

type SubmitPrismReviewArgs = {
  submissionItems: PhotoData[];
  submissionArgs: TransitionSubmissionInput;
};

export const submitPrismReview = createAsyncThunk(
  'ortho/submitPrismReview',
  async ({ submissionItems, submissionArgs }: SubmitPrismReviewArgs, store) => {
    // Reject any rejected submission items (photos) and approve the rest,
    // before final Prism submission transition
    const [maybeFormatting] = submissionArgs.rejectionReasons ?? [];
    // Reject underlying photos related to a formatted version of a photo if and only if the submission was rejected
    // for formatting reasons.
    const rejectPhoto = maybeFormatting?.name !== PhotoReasonsType.Formatting;
    await Promise.all(
      submissionItems.map(({ submissionItem, state }) => {
        const { isRejected, rejectionReasons } = state;
        const transitionData = isRejected
          ? {
              transition: SubmissionItemTransitions.Reject,
              rejectionReasons,
              rejectPhoto,
            }
          : {
              transition:
                submissionArgs.transition ===
                SubmissionTransitions.ForceReverseApproval
                  ? SubmissionItemTransitions.ForceReverseApproval
                  : SubmissionItemTransitions.Approve,
            };

        return store.dispatch(
          transitionPhoto({
            submissionItemRef: submissionItem?.ref as string,
            ...transitionData,
          })
        );
      })
    );
    return store.dispatch(transitionPrismSubmission(submissionArgs));
  }
);

const friendlyErrors = {
  [fetchCustomer.type]: `Sorry, that user either does not exist or cannot be fetched at this time`,
  [fetchScans.typePrefix]: `We could not fetch scans`,
  [fetchXrays.typePrefix]: `We could not fetch x-rays`,
  [fetchPlanRejectionReasons.typePrefix]: `Something went wrong while retrieving treatment plan rejection reasons`,
  [fetchMaterialEvaluationRejectionReasonsMap.typePrefix]: `Something went wrong while retrieving treatment plan rejection reasons`,
  [fetchPrismAggregates.typePrefix]: `We could not fetch this patient's photos at this time`,
};

export const useFriendlyError = registerFriendlyErrors(friendlyErrors);

type AddMaterialsPayload<T extends MaterialFragment> = {
  materials: T[];
};
type RemoveMaterialPayload = {
  materialId: string;
};

type AddClarificationRequestPayload = {
  clarificationRequest: ClarificationRequestsFragment;
  materialClassification: 'scan' | 'xray';
};

type AddPhotoPayload = {
  photo: PhotoTypeFieldsFragment;
  aggregateRef: string;
  newAggregateState: string;
};

const orthoSlice = createSlice({
  name: 'ortho',
  initialState,
  reducers: {
    resetState: () => initialState,
    setCurrentTabId: (state, action: PayloadAction<TabLabelType>) => {
      state.currentTabId = action.payload;
    },
    setHasChanges: (state, action: PayloadAction<boolean>) => {
      state.hasChanges = action.payload;
    },
    setSelectedCase: (state, action: PayloadAction<string>) => {
      const selectedCase = state.cases?.find(
        (c) => c?.caseRef === action.payload
      );
      state.selectedCase = selectedCase ?? null;
    },
    setCustomer: (state, action: PayloadAction<CustomerQuery>) => {
      state.customer = action.payload.customer;
    },
    setGetCases: (state, action: PayloadAction<GetCasesQuery>) => {
      state.cases = action.payload.getCases;
    },
    setClarificationRequest: (
      state: OrthoState,
      action: PayloadAction<AddClarificationRequestPayload>
    ) => {
      const { clarificationRequest, materialClassification } = action.payload;
      if (materialClassification === 'scan') {
        state.scans = state.scans.map((s) => ({
          ...s,
          clarificationRequests: s.clarificationRequests.map((cr) => {
            if (cr.id === clarificationRequest.id) {
              return clarificationRequest;
            }
            return cr;
          }),
        }));
      }
      if (materialClassification === 'xray') {
        state.xrays = state.xrays.map((x) => ({
          ...x,
          clarificationRequests: x.clarificationRequests.map((cr) => {
            if (cr.id === clarificationRequest.id) {
              return clarificationRequest;
            }
            return cr;
          }),
        }));
      }
    },
    setPostSubmitPrismClarificationInfo: (
      state: OrthoState,
      action: PayloadAction<NeedsClarificationInfo>
    ) => {
      state.postSubmitPrismClarificationInfo = action.payload;
    },
    addPhoto: (state, action: PayloadAction<AddPhotoPayload>) => {
      // we currently hack the aggregate state by passing in the expected state
      // see comments around hackyGetAggregateStatePostUploadPhoto
      const { photo, aggregateRef, newAggregateState } = action.payload;
      state.prismAggregates =
        state.prismAggregates?.map((agg) => {
          if (agg?.ref !== aggregateRef) {
            return agg;
          }
          const newAgg = {
            ...agg,
            photoSet: [photo, ...agg.photoSet],
          };
          if (newAgg.stateData?.data) {
            newAgg.stateData.data = newAggregateState;
          }
          return newAgg;
        }) || [];
    },
    addScans: (
      state,
      action: PayloadAction<AddMaterialsPayload<ScanFragment>>
    ) => {
      const { materials } = action.payload;
      state.scans = [...materials, ...state.scans];
    },
    addXrays: (
      state,
      action: PayloadAction<AddMaterialsPayload<XrayFragment>>
    ) => {
      const { materials } = action.payload;
      state.xrays = [...materials, ...state.xrays];
    },
    removeScan: (state, action: PayloadAction<RemoveMaterialPayload>) => {
      const { materialId } = action.payload;
      state.scans = state.scans.filter((m) => m.id !== materialId);
    },
    removeXray: (state, action: PayloadAction<RemoveMaterialPayload>) => {
      const { materialId } = action.payload;
      state.xrays = state.xrays.filter((m) => m.id !== materialId);
    },
    fetched<K extends keyof OrthoState>(
      state: OrthoState,
      action: PayloadAction<SetterPayload<K>>
    ) {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlanRejectionReasons.fulfilled, (state, action) => {
      state.planRejectionReasons = action.payload
        .map(toStandardReason)
        .filter(
          (r: RejectionReason) => r.name !== PatientReasonsType.WhiteningOnly
        );
    });
  },
});

type LegacyReason = {
  id: number;
  slug: string;
  name: string;
  description: string;
  reasons?: LegacyReason[];
};

const toStandardReason = (r: LegacyReason): RejectionReason => ({
  name: r.slug,
  label: r.name,
  reasons: r.reasons?.map(toStandardReason),
});

export const {
  resetState,
  setCurrentTabId,
  setHasChanges,
  setSelectedCase,
  addScans,
  removeScan,
} = orthoSlice.actions;

export const selectCustomer = (state: RootState) => state.ortho.customer;
export const selectCases = (state: RootState) => state.ortho.cases;
export const selectPlanRejectionReasons = (state: RootState) =>
  state.ortho.planRejectionReasons;
export const selectMaterialEvaluationRejectionReasonsMap = (state: RootState) =>
  state.ortho.materialEvaluationRejectionReasonsMap;
const selectIntakeForms = (state: RootState) => state.ortho.intakeForms;
const selectPrismAggregates = (state: RootState) => state.ortho.prismAggregates;
export const selectCurrentTabId = (state: RootState) =>
  state.ortho.currentTabId;
export const selectHasChanges = (state: RootState) => state.ortho.hasChanges;
export const selectSelectedCase = (state: RootState) =>
  state.ortho.selectedCase;

export const selectCustomerBrand = (state: RootState) =>
  state.ortho.customer?.user.brandInfo;

export const selectHomeAddress = createSelector(
  selectCustomer,
  (patient) =>
    patient?.addresses?.find(
      (a) => a.addressType === 'Shipping Address'
    ) as AddressType
);

/**
 * Case Selectors
 */

export const selectSelectedCaseState = createSelector(
  selectSelectedCase,
  (selectedCase) => selectedCase?.caseState ?? null
);

export const selectLatestCase = createSelector(
  selectCases,
  (cases) => cases?.[0]
);

export const selectActiveCase = createSelector(selectCases, (cases) =>
  cases?.find((c) => c?.isActive)
);

export const selectIsPatientElgiibleForRefinement = createSelector(
  selectActiveCase,
  (activeCase) => {
    if (!activeCase) {
      return true;
    }

    if (
      activeCase.caseState?.providerFacing ===
        ProviderFacingStates.IN_TREATMENT ||
      activeCase.caseState?.providerFacing ===
        ProviderFacingStates.IN_RETENTION ||
      activeCase.caseState?.providerFacing ===
        ProviderFacingStates.ALIGNER_KIT_DELIVERED
    ) {
      return true;
    }
    return false;
  }
);

export const selectSubsequentCase = createSelector(
  selectCases,
  selectSelectedCase,
  (cases, selectedCase) => {
    if (!selectedCase) {
      return null;
    }
    const { caseRef } = selectedCase;
    const subsequentCases = cases.find(
      (c) => caseRef.toString() === c?.precedingCaseRef?.toString()
    );
    return subsequentCases;
  }
);

export const selectBypassOrthoReview = createSelector(
  selectSelectedCase,
  (selectedCase) => {
    // if there are no production requirements, we can bypass ortho review
    if (!selectedCase?.workflow?.productionRequirements) {
      return true;
    }

    const allEvaluationsTypesNeeded =
      selectedCase.workflow.productionRequirements.flatMap(
        (req) => req.evaluationRequirements
      );
    // bypass ortho review if the 'ortho_evaluation' is not present
    return !allEvaluationsTypesNeeded?.some(
      (ev) =>
        ev?.evaluationType === MaterialEvaluationTypes.OrthodonticEvaluation
    );
  }
);

/**
 * Customer Selectors
 */

export const selectShortName = createSelector(selectCustomer, (customer) => {
  if (!customer) {
    return '';
  }
  const [firstInitial] = customer.firstName;
  return `${firstInitial}. ${customer.lastName}`;
});

export const selectCustomerId = createSelector(selectCustomer, (customer) => {
  if (!customer) {
    return '';
  }
  return customer.id;
});

export const selectFullName = createSelector(selectCustomer, (customer) => {
  if (!customer) {
    return '';
  }
  return `${customer.firstName} ${customer.lastName}`;
});

export const selectEmail = createSelector(selectCustomer, (customer) => {
  return customer?.user.email ?? '';
});

/**
 * Intake Selectors
 */

const selectSortedIntakeForms = createSelector(
  selectIntakeForms,
  // need to create a copy of the array before sorting otherwise
  // you get a TypeError for altering a read only object
  (intakes) => ([...(intakes ?? [])] as Intake[]).sort(sortByCreated(Sort.Desc))
);

export const selectCompletedIntakeForms = createSelector(
  selectSortedIntakeForms,
  (intakes) => intakes.filter((intake) => !intake.isDraft)
);

export const selectTreatmentGoalsState = createSelector(
  selectSelectedCaseState,
  (caseState) => caseState?.treatmentGoals
);

/**
 * Prism Selectors
 */

// HIC SUNT DRACONES, assuming only one aggregate per collection right
// now, this will need to change once new aggregates are added.
export const selectCurrentAggregate = createSelector(
  selectPrismAggregates,
  (aggregates) => aggregates?.[0]
);

export const selectPhotoViewNames = createSelector(
  selectCurrentAggregate,
  (aggregate) =>
    splitList(
      aggregate?.aggregateType.requiredPhotoTypes.map(({ name }) => name) ?? [],
      (name) => name.endsWith('_w_aligners')
    )
);

export const selectCurrentPhotoSet = createSelector(
  selectCurrentAggregate,
  (aggregate) => aggregate?.photoSet ?? []
);

// UGH this doesn't come back from the server in the right
// order. Manually sorting in here.
export const selectCurrentSubmissionSet = createSelector(
  selectCurrentAggregate,
  (aggregate) =>
    [...(aggregate?.submissionSet || [])].sort(sortByCreated(Sort.Asc))
);

export const selectLastSubmission = createSelector(
  selectCurrentSubmissionSet,
  (submissionSet) => submissionSet[submissionSet.length - 1] ?? null
);

/**
 * There's no easy way to update the prism clarification provided state without
 * refetching the prism aggregates. This is slow and not an ideal user experience
 *
 * This object is only set after a clinician responds to clarificaiton for prism
 */
const selectPostSubmitPrismClarificationInfo = (state: RootState) =>
  state.ortho.postSubmitPrismClarificationInfo;

/**
 * Helper selector used to help determine the prism state in the prism state selector.
 */
const selectPrismLastSubmissionState = createSelector(
  selectLastSubmission,
  selectPostSubmitPrismClarificationInfo,
  (lastSubmission, postSubmitPrismClarificationInfo) => {
    if (postSubmitPrismClarificationInfo) {
      // hack when user submits clarification, instead of refetching data for bad UX
      // we set this object for the local state to change
      return LegacyMaterialStates.CLARIFICATION_PROVIDED;
    }
    const prismSubmissionState = lastSubmission?.stateData?.data;
    const submissionHistoryLength =
      lastSubmission?.stateData?.history?.length ?? 0;
    // greater than one means more than just the first submitted row in the history
    const submissionHadOrthoFeedback = submissionHistoryLength > 1;
    if (
      prismSubmissionState === LegacyMaterialStates.SUBMITTED &&
      submissionHadOrthoFeedback
    ) {
      const stateBeforeCurrent =
        lastSubmission?.stateData?.history?.[submissionHistoryLength - 2];
      if (
        stateBeforeCurrent?.data === LegacyMaterialStates.NEEDS_CLARIFICATION
      ) {
        return LegacyMaterialStates.CLARIFICATION_PROVIDED;
      }
    }
    return prismSubmissionState;
  }
);

/**
 * Treatment Plan Selectors
 */

export const selectTreatmentPlanStagings = (state: RootState) =>
  state.ortho.treatmentPlanStagings;

export const selectSortedTreatmentPlanStagings = createSelector(
  selectTreatmentPlanStagings,
  (plans) => {
    if (!plans) {
      return [] as TreatmentPlanStaging[];
    }

    return [...plans].sort(
      (p1, p2) =>
        new Date(p2.createdAt).getTime() - new Date(p1.createdAt).getTime()
    ) as TreatmentPlanStaging[];
  }
);

export const selectLatestTreatmentPlanStaging = createSelector(
  selectSortedTreatmentPlanStagings,
  (treatmentPlans) => treatmentPlans?.[treatmentPlans.length - 1]
);

export const selectTreatmentPlanStagingState = createSelector(
  selectSelectedCaseState,
  (caseState) => caseState?.treatmentPlanStaging
);

/**
 * CORE XRAYS
 *
 * PLEASE REFACTOR THIS IN THE FUTURE!!!
 * We are inefficently requesting data and copying selectors
 */

export type XrayFragment =
  GetXraysByCaseRefQuery['getXrayMaterialsByCaseRef'][0];
export const selectXrays = (state: RootState) => state.ortho.xrays;

export type SubmissionFragment = XrayFragment['submissions'][0];
type SubmissionMap = {
  [key: string]: SubmissionFragment;
};

// helper function to export until we find a good home for it
const getSubmissionsFromXrays = (xrays: XrayFragment[]) => {
  const submissionMap: SubmissionMap = {};
  xrays.forEach((xray) => {
    xray.submissions.forEach((submission) => {
      if (!submissionMap[submission.id]) {
        submissionMap[submission.id] = submission;
      }
    });
  });
  const submissions = Object.values(submissionMap);
  submissions.sort(sortByCreated(Sort.Desc));
  return submissions;
};

export const selectXraySubmissions = createSelector(
  selectXrays,
  getSubmissionsFromXrays
);

const selectLatestXraySubmission = createSelector(
  selectXraySubmissions,
  (submissions) => submissions[0]
);

export const selectXraysFromLatestSubmission = createSelector(
  selectXrays,
  selectLatestXraySubmission,
  (xrays, submission) =>
    xrays.filter((xray) => xray.submissions.some((s) => s.id === submission.id))
);

type ClarificationRequestsFragment = XrayFragment['clarificationRequests'][0];
type ClarificationRequestsMap = {
  [key: string]: ClarificationRequestsFragment;
};
const selectXrayClarificationRequests = createSelector(selectXrays, (xrays) => {
  const clarificationRequestsMap: ClarificationRequestsMap = {};
  xrays.forEach((xray) => {
    xray.clarificationRequests.forEach((clarification) => {
      if (!clarificationRequestsMap[clarification.id]) {
        clarificationRequestsMap[clarification.id] = clarification;
      }
    });
  });
  const clarificationRequests = Object.values(clarificationRequestsMap);
  clarificationRequests.sort(sortByCreated(Sort.Desc));
  return clarificationRequests;
});

export const selectLatestXrayClarification = createSelector(
  selectXrayClarificationRequests,
  (clarificationRequests) => clarificationRequests[0]
);

type MaterialEvaluationFragment = MaterialFragment['materialEvaluations'][0];
type MaterialEvaluationMap = {
  [key: string]: MaterialEvaluationFragment;
};

// helper function to export until we find a good home for it
const getEvaluationsFromXrays = (xrays: XrayFragment[]) => {
  const materialEvaluationMap: MaterialEvaluationMap = {};
  xrays.forEach((xray) => {
    xray.materialEvaluations.forEach((materialEvaluation) => {
      if (!materialEvaluationMap[materialEvaluation.id]) {
        materialEvaluationMap[materialEvaluation.id] = materialEvaluation;
      }
    });
  });
  const materialEvaluations = Object.values(materialEvaluationMap);
  materialEvaluations.sort(sortByCreated(Sort.Desc));
  return materialEvaluations;
};

const selectXrayMaterialEvaluations = createSelector(
  selectXrays,
  getEvaluationsFromXrays
);

type MaterialEvaluationSetFragment = NonNullable<
  MaterialFragment['materialEvaluations'][0]['evaluationSet']
>;
type MaterialEvaluationSetMap = {
  [key: string]: MaterialEvaluationSetFragment;
};

// gets the evaluations from the latest evaluation set
// TODO change our data fetching and resolvers so that
// a lot of this massaging is on the backend
const selectMaterialEvaluationSets = createSelector(
  selectXrayMaterialEvaluations,
  (materialEvaluations) => {
    const materialEvaluationSetMap: MaterialEvaluationSetMap = {};
    materialEvaluations.forEach((materialEvaluation) => {
      const evalSet = materialEvaluation.evaluationSet;
      if (!!evalSet && !materialEvaluationSetMap[evalSet.id]) {
        materialEvaluationSetMap[evalSet.id] = evalSet;
      }
    });
    const materialEvaluationSets = Object.values(materialEvaluationSetMap);
    materialEvaluationSets.sort(sortByCreated(Sort.Desc));
    return materialEvaluationSets;
  }
);

const selectLatestXrayMaterialEvaluationSet = createSelector(
  selectMaterialEvaluationSets,
  (materialEvaluationSets) => materialEvaluationSets[0]
);

export const selectLatestXrayMaterialEvaluations = createSelector(
  selectXrayMaterialEvaluations,
  selectLatestXrayMaterialEvaluationSet,
  (materialEvaluations, latestMaterialEvaluationSet) => {
    if (!latestMaterialEvaluationSet) {
      return [];
    }
    return materialEvaluations.filter(
      (evals) => latestMaterialEvaluationSet.id === evals.evaluationSet?.id
    );
  }
);

export const selectXrayMaterialState = createSelector(
  selectSelectedCaseState,
  (caseState) => caseState?.xrays
);

/**
 * CORE SCANS
 *
 * PLEASE REFACTOR THIS IN THE FUTURE!!!
 * We are inefficently requesting data and copying selectors
 */

export type ScanFragment =
  GetScansByCaseRefQuery['getScanMaterialsByCaseRef'][0];
export const selectScans = (state: RootState) => state.ortho.scans;

export const selectUnsubmittedScans = createSelector(selectScans, (scans) =>
  scans.filter((scan) => !scan.submissions?.length)
);

// helper function to export until we find a good home for it
const getSubmissionsFromMaterials = (materials: MaterialFragment[]) => {
  const submissionMap: SubmissionMap = {};
  materials.forEach((material) => {
    material.submissions.forEach((submission) => {
      if (!submissionMap[submission.id]) {
        submissionMap[submission.id] = submission;
      }
    });
  });
  const submissions = Object.values(submissionMap);
  submissions.sort(sortByCreated(Sort.Desc));
  return submissions;
};

export const selectScanSubmissions = createSelector(
  selectScans,
  getSubmissionsFromMaterials
);

const selectLatestScanSubmission = createSelector(
  selectScanSubmissions,
  (submissions) => submissions[0]
);

export const selectScansFromLatestSubmission = createSelector(
  selectScans,
  selectLatestScanSubmission,
  (scans, submission) =>
    scans.filter((scan) => scan.submissions.some((s) => s.id === submission.id))
);

const selectScanClarificationRequests = createSelector(selectScans, (scans) => {
  const clarificationRequestsMap: ClarificationRequestsMap = {};
  scans.forEach((scan) => {
    scan.clarificationRequests.forEach((clarification) => {
      if (!clarificationRequestsMap[clarification.id]) {
        clarificationRequestsMap[clarification.id] = clarification;
      }
    });
  });
  const clarificationRequests = Object.values(clarificationRequestsMap);
  clarificationRequests.sort(sortByCreated(Sort.Desc));
  return clarificationRequests;
});

export const selectLatestScanClarification = createSelector(
  selectScanClarificationRequests,
  (clarificationRequests) => clarificationRequests[0]
);

// helper function to export until we find a good home for it
const getEvaluationsFromScans = (scans: ScanFragment[]) => {
  const materialEvaluationMap: MaterialEvaluationMap = {};
  scans.forEach((scan) => {
    scan.materialEvaluations.forEach((materialEvaluation) => {
      if (!materialEvaluationMap[materialEvaluation.id]) {
        materialEvaluationMap[materialEvaluation.id] = materialEvaluation;
      }
    });
  });
  const materialEvaluations = Object.values(materialEvaluationMap);
  materialEvaluations.sort(sortByCreated(Sort.Desc));
  return materialEvaluations;
};

const selectScanMaterialEvaluations = createSelector(
  selectScans,
  getEvaluationsFromScans
);

// gets the evaluations from the latest evaluation set
// TODO change our data fetching and resolvers so that
// a lot of this massaging is on the backend
const selectScanMaterialEvaluationSets = createSelector(
  selectScanMaterialEvaluations,
  (materialEvaluations) => {
    const materialEvaluationSetMap: MaterialEvaluationSetMap = {};
    materialEvaluations.forEach((materialEvaluation) => {
      const evalSet = materialEvaluation.evaluationSet;
      if (!!evalSet && !materialEvaluationSetMap[evalSet.id]) {
        materialEvaluationSetMap[evalSet.id] = evalSet;
      }
    });
    const materialEvaluationSets = Object.values(materialEvaluationSetMap);
    materialEvaluationSets.sort(sortByCreated(Sort.Desc));
    return materialEvaluationSets;
  }
);

const selectLatestScanMaterialEvaluationSet = createSelector(
  selectScanMaterialEvaluationSets,
  (materialEvaluationSets) => materialEvaluationSets[0]
);

export const selectLatestScanMaterialEvaluations = createSelector(
  selectScanMaterialEvaluations,
  selectLatestScanMaterialEvaluationSet,
  (materialEvaluations, latestMaterialEvaluationSet) => {
    if (!latestMaterialEvaluationSet) {
      return [];
    }
    return materialEvaluations.filter(
      (evals) => latestMaterialEvaluationSet.id === evals.evaluationSet?.id
    );
  }
);

export const selectScanMaterialState = createSelector(
  selectSelectedCaseState,
  (caseState) => caseState?.scans
);

/**
 * GENERAL SELECTORS
 * (selectors that use a combination of the above selectors)
 */

export type MaterialFragment = ScanFragment | XrayFragment;

export const selectIsInClarificationStep = createSelector(
  selectLastSubmission,
  selectXrayMaterialState,
  selectScanMaterialState,
  (lastPrismSubmission, xrayMaterialState, scanMaterialState) => {
    return (
      lastPrismSubmission?.stateData?.data ===
        LegacyMaterialStates.NEEDS_CLARIFICATION ||
      xrayMaterialState?.state === MaterialStates.NeedsClarification ||
      scanMaterialState?.state === MaterialStates.NeedsClarification
    );
  }
);

type NeedsClarificationInfo = {
  xrayClarificationRequestId?: string;
  scanClarificationRequestId?: string;
  prismSubmissionRef?: string;
  attachments?: FileInfo[];
  question: string;
  createdAt: string;
  responseInfo?: {
    response: string;
    respondedAt?: string | null;
    respondedByEmail?: string | null;
  };
};

// NOTE!!: do not use this directly, use selectClarificationRequestInfo
const selectXrayClarificationInfo = createSelector(
  selectLatestXrayClarification,
  (latestXrayClarification) => {
    if (!latestXrayClarification) {
      return null;
    }
    return {
      xrayClarificationRequestId: latestXrayClarification.id,
      question: latestXrayClarification.question,
      createdAt: latestXrayClarification.createdAt,
      responseInfo: latestXrayClarification.response
        ? {
            response: latestXrayClarification.response,
            respondedAt: latestXrayClarification.respondedAt,
            respondedByEmail: latestXrayClarification.respondedByEmail,
          }
        : undefined,
    } as NeedsClarificationInfo;
  }
);

// NOTE!!: do not use this directly, use selectClarificationRequestInfo
const selectScanClarificationInfo = createSelector(
  selectLatestScanClarification,
  (latestScanClarification) => {
    if (!latestScanClarification) {
      return null;
    }
    return {
      scanClarificationRequestId: latestScanClarification.id,
      attachments: latestScanClarification.attachments,
      question: latestScanClarification.question,
      createdAt: latestScanClarification.createdAt,
      responseInfo: latestScanClarification.response
        ? {
            response: latestScanClarification.response,
            respondedAt: latestScanClarification.respondedAt,
            respondedByEmail: latestScanClarification.respondedByEmail,
          }
        : undefined,
    } as NeedsClarificationInfo;
  }
);

// NOTE!!: do not use this directly, use selectClarificationRequestInfo
const selectPrismClarificationInfo = createSelector(
  selectPrismLastSubmissionState,
  selectLastSubmission,
  selectPostSubmitPrismClarificationInfo,
  (
    prismSubmissionState,
    lastPrismSubmission,
    postSubmitPrismClarificationInfo
  ) => {
    if (postSubmitPrismClarificationInfo) {
      // hack when user submits clarification, instead of refetching data for bad UX
      // we set this object for the local state to change
      return postSubmitPrismClarificationInfo;
    }
    const isNeedsOrProvidedClarification =
      prismSubmissionState === LegacyMaterialStates.NEEDS_CLARIFICATION ||
      prismSubmissionState === LegacyMaterialStates.CLARIFICATION_PROVIDED;

    if (!isNeedsOrProvidedClarification || !lastPrismSubmission?.stateData) {
      return null;
    }
    // default to needs clarification
    const history = lastPrismSubmission?.stateData.history!;
    const currentStateData = history[history.length - 1];
    let question = currentStateData.extra?.rejectionNotes || '';
    let response;
    let respondedAt;
    let respondedByEmail;
    // update if clarification provided
    if (prismSubmissionState === LegacyMaterialStates.CLARIFICATION_PROVIDED) {
      const needsClarificationStateData = history[history.length - 2];
      question = needsClarificationStateData.extra?.rejectionNotes || '';
      response = currentStateData.extra?.rejectionNotes;
      respondedAt = currentStateData.created;
      respondedByEmail = currentStateData.user?.email;
    }
    return {
      prismSubmissionRef: lastPrismSubmission.ref,
      question,
      createdAt: currentStateData.created,
      responseInfo: response
        ? {
            response,
            respondedAt,
            respondedByEmail,
          }
        : undefined,
    } as NeedsClarificationInfo;
  }
);

/**
 * Provides the latest clarification info and doesn't take state into account
 * Clarification requests are each separate http requests and objects but they
 * have the same question / response so we need to combine them into one object.
 */
export const selectClarificationRequestInfo = createSelector(
  selectPrismClarificationInfo,
  selectScanClarificationInfo,
  selectXrayClarificationInfo,
  (prismClarificationInfo, scanClarificationInfo, xrayClarificationInfo) => {
    const clarificationInfos = [
      scanClarificationInfo,
      prismClarificationInfo,
      xrayClarificationInfo,
    ];

    // Filter out null values and sort by descending before reducing.
    // This way, can be confident that the first item is the latest and we should try to merge into it
    return clarificationInfos
      .flatMap((info) => (info ? info : []))
      .sort((a, b) => {
        const aDate = moment(a.createdAt);
        const bDate = moment(b.createdAt);
        // sort by descending
        return aDate.isAfter(bDate) ? -1 : 1;
      })
      .reduce(
        (acc, currentValue) => {
          if (!acc) {
            return currentValue;
          }
          // if the question and response are the same, we can assume it's the same clarification request info
          const isEqual =
            acc.question === currentValue.question &&
            acc.responseInfo?.response === currentValue.responseInfo?.response;
          if (!isEqual) {
            return acc;
          }
          return {
            ...acc,
            ...currentValue,
          };
        },
        null as NeedsClarificationInfo | null
      );
  }
);

export const selectPatientDoctorPreferences = createSelector(
  selectCustomer,
  (patient) => {
    return patient?.referringDentist?.user?.accountPreferences?.doctor;
  }
);

export default orthoSlice.reducer;
