import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { type } from 'core/components';

import { CaseSource } from 'generated/core/graphql';
import { ThirdPartiesAvailableForSyncing } from 'generated/legacy/graphql';
import { Dropdown } from 'components/Dropdown/Dropdown';
import ConfirmDialog from 'components/ConfirmDialog';
import withNotifications from 'components/withNotifications';
import useSyncToThirdParty from 'hooks/useSyncToThirdParty';
import { useIsOnlyCandidPro } from 'hooks/useIsOnlyCandidPro';

const Body = styled.div<{
  rightMargin?: boolean;
}>`
  text-align: right;
  margin-right: ${({ rightMargin }) => rightMargin && '1.5rem'};
`;

const CustomerLink = styled(type.Link)<{
  action?: CustomerAction;
  disabled?: boolean;
}>`
  font-weight: 300;
  color: ${({ disabled }) => (disabled ? 'gray' : '')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  &:hover {
    cursor: pointer;
  }
`;

type Case = {
  isActive: boolean;
  caseRef: string;
  source?: string;
};

const customerDescriptions: Record<CustomerAction, string> = {
  syncToSalesforce: 'Sync to Salesforce',
  syncToZendesk: 'Sync to Zendesk',
};

enum CustomerAction {
  syncToSalesforce = 'syncToSalesforce',
  syncToZendesk = 'syncToZendesk',
}

const hideQuickActionsConditions: { (): boolean }[] = [
  (): boolean => useIsOnlyCandidPro(),
];

type CustomerActionProps = {
  customerId: string;
  cases: Case[];
  showNotification: (message: string, variant: string) => void;
  hideActions?: CustomerAction[];
};

const renderCustomerBody = (action?: CustomerAction) => {
  let copy;
  switch (action) {
    case CustomerAction.syncToSalesforce: {
      copy = 'Sync the customer to Saleforce?';
      break;
    }
    case CustomerAction.syncToZendesk: {
      copy = 'Sync the customer to Zendesk?';
      break;
    }

    default: {
      copy = '';
      break;
    }
  }

  return <div>{copy}</div>;
};

const CustomerActionsComponent = ({
  customerId,
  cases,
  showNotification,
  hideActions = [],
}: CustomerActionProps) => {
  const [shouldOpenDialog, setShouldOpenDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState<
    CustomerAction | undefined
  >(undefined);
  const { syncToThirdParty } = useSyncToThirdParty();

  const coreCases = cases.filter((c) => c.source === CaseSource.Core);

  const handleClick = (action: CustomerAction) => {
    setCurrentAction(action);
    setShouldOpenDialog(true);
  };

  const handleConfirm = () => {
    switch (currentAction) {
      case CustomerAction.syncToSalesforce: {
        showNotification(`Syncing customer to Salesforce`, 'message');
        syncToThirdParty({
          coreCases,
          thirdParty: ThirdPartiesAvailableForSyncing.Salesforce,
          customerId,
          onCompleted: () => {
            showNotification(
              `Successfully synced customer to Salesforce`,
              'success'
            );
          },
        });
        break;
      }
      case CustomerAction.syncToZendesk: {
        showNotification(`Syncing customer to Zendesk`, 'message');
        syncToThirdParty({
          coreCases,
          thirdParty: ThirdPartiesAvailableForSyncing.Zendesk,
          customerId,
          onCompleted: () => {
            showNotification(
              `Successfully synced customer to Zendesk`,
              'success'
            );
          },
        });
        break;
      }

      default: {
        break;
      }
    }

    setShouldOpenDialog(false);
    setTimeout(() => {
      setCurrentAction(undefined);
    }, 300);
  };

  const links = Object.keys(CustomerAction)
    .filter((ca) => {
      if (hideActions.includes(ca as CustomerAction)) {
        return false;
      }
      return true;
    })
    .map((ca: string) => {
      return (
        <CustomerLink
          action={ca as CustomerAction}
          key={ca}
          onClick={() => handleClick(ca as CustomerAction)}
        >
          {customerDescriptions[ca as CustomerAction]}
        </CustomerLink>
      );
    });

  if (hideQuickActionsConditions.some((condition) => condition())) {
    return null;
  }

  return (
    <div>
      <Body>
        <Dropdown showCaret title={<CustomerLink>Quick actions</CustomerLink>}>
          {links}
        </Dropdown>
      </Body>
      <ConfirmDialog
        isOpen={shouldOpenDialog}
        onCancel={() => {
          setShouldOpenDialog(false);
          setTimeout(() => {
            setCurrentAction(undefined);
          }, 300);
        }}
        onConfirm={() => {
          handleConfirm();
        }}
        showCancelButton={!!currentAction}
        confirmButtonText={'Confirm'}
      >
        {renderCustomerBody(currentAction)}
      </ConfirmDialog>
    </div>
  );
};

export default withNotifications(CustomerActionsComponent);
