import React, { useEffect, useMemo, useState } from 'react';

import UploadScans from 'pages/Patient/CaseCreator/DiagnosticMaterials/Scans/UploadScans';
import SyncScans from 'pages/Patient/CaseCreator/DiagnosticMaterials/Scans/SyncScans';

import UploadSvg from 'assets/fi_upload.svg?react';
import MailSvg from 'assets/icons/small/mail.svg?react';
import SyncSvg from 'assets/ic_zap.svg?react';
import PvsScan from 'pages/Patient/CaseCreator/DiagnosticMaterials/Scans/PvsScans';
import { Container } from 'pages/Patient/CaseCreator/DiagnosticMaterials/Scans/Scans.css';
import {
  StlTab,
  TabIds,
  TabId,
} from 'pages/Patient/CaseCreator/DiagnosticMaterials/Scans/types';
import { useSelector } from 'react-redux';
import { WorkflowUploadSources, MaterialStates } from 'generated/core/graphql';
import {
  selectActiveCase,
  selectScansUploadType,
  selectPatientBrandName,
} from 'pages/Patient/patientSlice';
import { getBrandSupportedFeatures } from 'utils/brands';
import Tabs from 'components/Tabs';
import DownArrowIcon from 'assets/fi_arrow-down.svg?react';
import {
  BottomPanel,
  SmallButton,
  BottonPannelText,
} from 'pages/Patient/styles.css';
import { ProviderFacingStates } from 'types/Case';
import { useAuthContext } from 'context/AuthContext';

type ScansProps = {
  onClickNext?: () => void;
};

const Scans = ({ onClickNext }: ScansProps) => {
  const activeCase = useSelector(selectActiveCase);
  const scansUploadType = useSelector(selectScansUploadType);
  const brandName = useSelector(selectPatientBrandName);
  const areMaterialsUnderReview =
    activeCase?.caseState?.providerFacing ===
    ProviderFacingStates.MATERIALS_UNDER_REVIEW;
  const { PvsScanFlow: enablePvsScanFlow } =
    getBrandSupportedFeatures(brandName);

  //The current selected tab
  //Changes when the switches tabs
  const [activeTabId, setActiveTabId] = useState<TabId | null>(null);

  const tabs = useMemo(() => {
    const tabs: StlTab[] = [
      {
        id: TabIds.UploadStls,
        name: 'Upload STL',
        Component: () => (
          <UploadScans hideUploadTiles={areMaterialsUnderReview} />
        ),
        Icon: UploadSvg,
      },
    ];

    if (enablePvsScanFlow) {
      tabs.push({
        id: TabIds.PvsImpressions,
        name: 'Mail impressions',
        Component: () => <PvsScan isReadOnly={areMaterialsUnderReview} />,
        Icon: MailSvg,
      });
    }

    tabs.push({
      id: TabIds.SyncWithScanner,
      name: 'Sync with scanner',
      Component: () => <SyncScans isReadOnly={areMaterialsUnderReview} />,
      Icon: SyncSvg,
    });

    return tabs;
  }, [enablePvsScanFlow]);

  const { userInfo } = useAuthContext();
  const { accountPreferences } = userInfo || {};
  const defaultTab =
    accountPreferences?.doctor?.scanSubmissionPreference || TabIds.UploadStls;

  useEffect(() => {
    const areScansUploaded =
      activeCase?.caseState?.scans?.state === MaterialStates.Uploaded;
    switch (scansUploadType) {
      case WorkflowUploadSources.Pvs:
        setActiveTabId(TabIds.PvsImpressions);
        break;
      case WorkflowUploadSources.ThirdPartySync:
        setActiveTabId(TabIds.SyncWithScanner);
        break;
      default:
        if (WorkflowUploadSources.Provider && areScansUploaded) {
          // Scans are already uploaded by the provider so show UploadStl tab to the user
          setActiveTabId(TabIds.UploadStls);
        } else if (!activeTabId) {
          // Show the default tab selected in user's account preferences
          setActiveTabId(defaultTab);
        }
      // Otherwise, there's already an activetab selected so don't change it
    }
  }, [scansUploadType]);

  return (
    <Container>
      <Tabs
        tabs={tabs}
        initialTabId={activeTabId}
        renderFooter={(activeTabId) => (
          <BottomPanel>
            {!areMaterialsUnderReview && activeTabId === TabIds.UploadStls && (
              <BottonPannelText>Two scans required</BottonPannelText>
            )}
            {!!onClickNext && (
              <SmallButton rightIcon={<DownArrowIcon />} onClick={onClickNext}>
                Next material
              </SmallButton>
            )}
          </BottomPanel>
        )}
      />
    </Container>
  );
};

export default Scans;
