import React, { useEffect, useMemo } from 'react';
import { Button } from 'core/components';
import CopySVG from 'assets/ic_copy.svg?react';
import { isValidExternalId } from 'utils/accountCreation';
import {
  AccountIdInputBox,
  ChildAccountInputContainer,
  IconContainer,
  InputContainer,
} from 'pages/PracticeAccountCreator/PracticeAccountCreator.css';
import useURLSearchParams from 'hooks/useURLSearchParams';

export const ChildAccountInputBox = ({
  externalId,
  setExternalId,
  autofill,
  isLoading,
}: {
  externalId: string;
  setExternalId: (externalId: string) => void;
  autofill: (childAccountIdInput: string) => void;
  isLoading: boolean;
}) => {
  const params = useURLSearchParams();
  const childAccountId = params.get('child-account-id');
  useEffect(() => {
    if (childAccountId) {
      setExternalId(childAccountId || '');
      autofill(childAccountId || '');
    }
  }, [childAccountId]);

  const isValid = useMemo(() => isValidExternalId(externalId), [externalId]);
  return (
    <ChildAccountInputContainer>
      Enter Salesforce child account ID:
      <InputContainer>
        <AccountIdInputBox
          value={externalId}
          onChange={(e) => setExternalId(e.target.value)}
        ></AccountIdInputBox>
        <IconContainer
          onClick={() =>
            navigator.clipboard.readText().then((text) => setExternalId(text))
          }
        >
          <CopySVG />
        </IconContainer>
      </InputContainer>
      <Button
        buttonType="secondary"
        onClick={() => autofill(externalId)}
        disabled={!isValid || isLoading}
      >
        Fill
      </Button>
    </ChildAccountInputContainer>
  );
};
