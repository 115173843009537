import {
  Template as GeneratedTemplateType,
  TemplateType,
} from 'generated/core/graphql';
import { Tab } from 'components/Tabs';
import { getBrandFromDomain } from 'utils/brands';

import {
  CANDID_BRAND_NAME,
  GLIDEWELL_BRAND_NAME,
  OLIV_BRAND_NAME,
} from 'utils/brands';

const getDefaultAnswers = (defaultTemplate: GeneratedTemplateType) => {
  // base off the questions type return a default answer for all questions
  const defaultAnswers: Record<string, string | string[] | null> = {};
  defaultTemplate.renderedQuestions.forEach((group) => {
    group?.forEach((question) => {
      if (question.type === 'MULTIPLE_CHOICE') {
        defaultAnswers[question.id] = null;
      } else if (question.type === 'MULTI_SELECT') {
        defaultAnswers[question.id] = [];
      } else if (question.type === 'TEXT') {
        defaultAnswers[question.id] = '';
      }
    });
  });

  return defaultAnswers;
};

export const createUserTemplateFromDefaultPayload = (
  defaultTemplate: GeneratedTemplateType,
  name: string,
  userId: string,
  description?: string
) => {
  const mapQuestionsWithOrder = (orderInGroup: Record<string, string[]>) =>
    Object.entries(orderInGroup).map(([group, questionIds]) => ({
      group,
      questions: questionIds.map((questionId: string, index: number) => ({
        questionId,
        order: index + 1,
      })),
    }));

  return {
    input: {
      name,
      description: description ?? '',
      brandId: defaultTemplate.brandId,
      userId: parseInt(userId),
      questionsWithOrder: mapQuestionsWithOrder(
        defaultTemplate.groupStructure?.orderInGroup
      ),
      groupOrder: defaultTemplate.groupStructure?.groupOrder ?? [],
      caseTypeName: defaultTemplate.caseType?.name,
      defaultAnswers: defaultTemplate?.defaultAnswers?.root,
      userAnswers: JSON.stringify(getDefaultAnswers(defaultTemplate)),
      isDefault: false, // because it's a user template
      type: defaultTemplate.type as unknown as TemplateType,
    },
  };
};

enum TABS {
  CLINICAL = 'clinical',
  CASE_TEMPLATES = 'caseTemplates',
  DIAGNOSTIC_MATERIALS = 'diagnosticMaterials',
  MONTORING = 'monitoring',
}

export enum TAB_FILTERS {
  BRAND = 'brand',
}

type TabMap = {
  [key: string]: {
    brands: {
      [brandName: string]: boolean;
    };
  };
};

export const filterTabs = (tabs: Tab[], filter: string) => {
  /*
  Intentionally wrote this to be neutral so that other filters could
  be added, depending on the requirements.
  */
  const tabMap: TabMap = {
    [TABS.CLINICAL]: {
      brands: {
        [CANDID_BRAND_NAME]: true,
        [GLIDEWELL_BRAND_NAME]: true,
        [OLIV_BRAND_NAME]: true,
      },
    },
    [TABS.CASE_TEMPLATES]: {
      brands: {
        [CANDID_BRAND_NAME]: false,
        [GLIDEWELL_BRAND_NAME]: false,
        [OLIV_BRAND_NAME]: true,
      },
    },
    [TABS.DIAGNOSTIC_MATERIALS]: {
      brands: {
        [CANDID_BRAND_NAME]: true,
        [GLIDEWELL_BRAND_NAME]: true,
        [OLIV_BRAND_NAME]: true,
      },
    },
    [TABS.MONTORING]: {
      brands: {
        [CANDID_BRAND_NAME]: true,
        [GLIDEWELL_BRAND_NAME]: true,
        [OLIV_BRAND_NAME]: false,
      },
    },
  };
  const userBrand = getBrandFromDomain();
  return tabs.filter((tab) => {
    if (filter == 'brand' && tabMap[tab.id]) {
      return tabMap[tab.id].brands[userBrand];
    }
  });
};
