import React from 'react';
import { useFormikContext } from 'formik';
import { EditPracticeInfo } from 'components/EditPracticeModal/EditPracticeModal';
import {
  FormContainer,
  ActionButtons,
  StyledButton,
  ModalBody,
  Header,
  StyledCancelButton,
} from 'components/EditPracticeModal/EditPracticeModal.css';
import { type, Grid, Divider } from 'core/components';
import AddressForm from 'components/AddressForm/AddressForm';
import { AddressFormType } from 'components/AddressForm/types';
import { GetPracticeQuery } from 'generated/legacy/graphql';

type EditPracticeFormProps = {
  handleCloseModal: () => void;
  submitForm: (values: EditPracticeInfo) => void;
  submitButtonRef: React.RefObject<HTMLButtonElement>;
  isSubmitting: boolean;
  practice: GetPracticeQuery['practice'];
};

const EditPracticeForm = ({
  handleCloseModal,
  submitForm,
  submitButtonRef,
  isSubmitting,
}: EditPracticeFormProps) => {
  const { dirty, handleSubmit, resetForm } =
    useFormikContext<EditPracticeInfo>();

  const closeModal = () => {
    handleCloseModal();
    resetForm();
  };

  const handleCancel = () => {
    if (
      !dirty ||
      (dirty && window.confirm('You will lose any unsaved changes'))
    ) {
      closeModal();
    }
  };

  return (
    <FormContainer>
      <Header>
        <Grid container>
          <Grid item xs={12}>
            <type.H4>{'Edit practice info'}</type.H4>
          </Grid>
        </Grid>
      </Header>
      <Divider />
      <ModalBody>
        <AddressForm
          addressPath=""
          businessNameRequired
          getAddressData={(values: EditPracticeInfo) => values}
          submitForm={submitForm}
          updateAddress={(
            value: AddressFormType,
            originalValues: EditPracticeInfo
          ) => {
            return {
              ...originalValues,
              addressLine1: value.addressLine1 ?? '',
              addressLine2: value.addressLine2 ?? '',
              city: value.city ?? '',
              stateCode: value.stateCode ?? '',
              zip: value.zip ?? '',
              //Smarty street doesn't return country, so use the original.
              countryCode: originalValues.countryCode,
              businessName: originalValues.businessName,
            };
          }}
          submitButtonRef={submitButtonRef}
        />
      </ModalBody>
      <Divider />
      <ActionButtons>
        <StyledCancelButton
          onClick={handleCancel}
          type="button"
          buttonType="text"
          disabled={isSubmitting}
        >
          Cancel
        </StyledCancelButton>
        <StyledButton
          buttonType="secondary"
          isShort
          isLoading={isSubmitting}
          disabled={!dirty}
          onClick={() => handleSubmit()}
        >
          Save changes
        </StyledButton>
      </ActionButtons>
    </FormContainer>
  );
};

export default EditPracticeForm;
