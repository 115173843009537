import React, { useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import { MUIDataTableOptions, MUIDataTableMeta } from 'mui-datatables';
import { Table, Box, Loading, Button } from 'core/components';
import { Grid } from '@material-ui/core';
import styled from 'styled-components/macro';
import DocumentTitle from 'components/DocumentTitle';
import api, { ExtractReturnType } from 'state/api';
import { useAuthContext } from 'context/AuthContext';
import { ACCESS_GROUPS } from 'types';
import CreateQuestionModal from 'components/Modals/CreateQuestionModal';
import { AnswerOptionInput } from 'generated/core/graphql';

const StyledTable = styled(Table)<{ loading: boolean }>`
  tr {
    cursor: pointer;
  }
  opacity: ${({ loading }) => (loading ? 0.3 : 1)};
`;

const StyledLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;
export type CreateQuestionForm = {
  text: string;
  type: string;
  options?: AnswerOptionInput[] | undefined;
  id?: string;
};
type Question = ExtractReturnType<typeof api.useGetTemplateQuestionsQuery>[0];

const QuestionsListView = () => {
  const [
    getQuestions,
    { data: questionsData = [], isFetching: isFetchingQuestions },
  ] = api.useLazyGetTemplateQuestionsQuery({});
  const { checkHasAccess } = useAuthContext();
  const isAdmin = checkHasAccess(ACCESS_GROUPS.ADMIN);
  const [createQuestionModalOpen, setCreateQuestionModalOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<Question>();

  useEffect(() => {
    getQuestions({});
  }, []);

  const mappedQuestions = useMemo(() => {
    if (!questionsData) {
      return [];
    }
    return questionsData.map(
      ({ id, text, type, createdAt, updatedAt, options, templates }) =>
        ({
          id,
          text,
          type,
          createdAt: moment(createdAt).format('MM/DD/YYYY'),
          updatedAt: moment(updatedAt).format('MM/DD/YYYY'),
          options,
          templates,
        }) as Question
    );
  }, [questionsData]);

  const columns = [
    {
      name: 'text',
      label: 'Text',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated At',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'actions',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_value: string, tableMeta: MUIDataTableMeta) => {
          return (
            <div>
              <Button
                buttonType="secondary"
                onClick={() => handleViewQuestion(tableMeta.rowIndex)}
              >
                View
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    selectableRows: 'none',
    fixedHeader: true,
    filter: false,
    serverSide: true,
    count: mappedQuestions.length,
    tableBodyMaxHeight: 'calc(80vh)',
    filterType: 'textField',
    onRowClick: (_rowData, _rowMeta) => {},
  };

  const handleViewQuestion = (index: number) => {
    setSelectedQuestion(mappedQuestions[index]);
    setCreateQuestionModalOpen(true);
  };

  if (isFetchingQuestions) {
    return <Loading isCentered />;
  }

  return (
    <DocumentTitle title="Question Tool - List View">
      <Box py={['1rem']} m={['auto']}>
        <Box display="flex" flexDirection="column">
          {mappedQuestions && isFetchingQuestions && (
            <StyledLoader>
              <Loading />
            </StyledLoader>
          )}
          <Grid container spacing={2} direction="row" justifyContent="flex-end">
            <Grid
              item
              style={{
                marginRight: '1rem',
              }}
            ></Grid>
          </Grid>
          {isAdmin && (
            <Button
              buttonType="secondary"
              onClick={() => {
                setSelectedQuestion(undefined);
                setCreateQuestionModalOpen(true);
              }}
            >
              Create Question
            </Button>
          )}
          <CreateQuestionModal
            isOpen={createQuestionModalOpen}
            onClose={() => {
              setCreateQuestionModalOpen(false);
            }}
            question={selectedQuestion}
          />
          <StyledTable
            title="Questions"
            data={mappedQuestions}
            columns={columns}
            options={options}
            loading={isFetchingQuestions}
          />
        </Box>
      </Box>
    </DocumentTitle>
  );
};

export default QuestionsListView;
