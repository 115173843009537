import React from 'react';
import Modal from 'core/components/Modal/Modal';
import KeyValueDisplay from 'components/KeyValueDisplay';
import styled from 'styled-components';
import { ProductVariantType } from 'pages/ProductVariants';

const ModalBody = styled.div`
  padding: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 720px;
`;

const ProductModal = ({
  isOpen,
  onClose,
  productVariantData,
}: {
  isOpen: boolean;
  onClose: () => void;
  productVariantData?: ProductVariantType;
}) => {
  if (!productVariantData) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBody>
        <KeyValueDisplay label="Brand" value={productVariantData.brand} />
        <KeyValueDisplay label="Sku" value={productVariantData.sku} />
        <KeyValueDisplay
          label="Product Type"
          value={productVariantData.product.productType}
        />
        <KeyValueDisplay
          label="Fullfillment"
          value={productVariantData.fulfillment}
        />
        <KeyValueDisplay
          label="Default Price In Cents"
          value={productVariantData.defaultPriceInCents}
        />
        <KeyValueDisplay
          label="Description"
          value={productVariantData.description}
        />
        <KeyValueDisplay
          label="Fulfillment Identifier"
          value={productVariantData.fulfillmentIdentifier}
        />
        <KeyValueDisplay
          label="Regulatory Id"
          value={productVariantData.regulatoryId}
        />
        <KeyValueDisplay
          label="Image Name"
          value={productVariantData.imageName}
        />
        <KeyValueDisplay
          label="image"
          value={
            productVariantData.image && <img src={productVariantData.image} />
          }
        />
      </ModalBody>
    </Modal>
  );
};

export default ProductModal;
