import React from 'react';
import KeyValueDisplay from 'components/KeyValueDisplay';
import { Grid } from 'core/components';
import { Case } from 'types/fromApi';

type PatientCaseInfoProps = {
  patient?: {
    id: string;
    fullName: string;
    user: {
      email?: string | null;
    };
    referringDentist?: {
      fullName: string;
    } | null;
  } | null;
  caseData?: Case | null;
  tpStaging: {
    isApproved?: boolean | null;
    data?:
      | {
          detailedSteps?: {
            upper?: number | null;
            lower?: number | null;
          } | null;
        }
      | null
      | undefined;
  };
};

const PatientCaseInfo = ({
  patient,
  caseData,
  tpStaging,
}: PatientCaseInfoProps) => {
  return (
    <div>
      <h4>Patient Info</h4>
      <Grid container direction="row">
        <Grid item xs={6}>
          <KeyValueDisplay label="Patient ID" value={patient?.id} />
        </Grid>
        <Grid item xs={6}>
          <KeyValueDisplay label="Email" value={patient?.user?.email} />
        </Grid>
        <Grid item xs={6}>
          <KeyValueDisplay label="Full Name" value={patient?.fullName} />
        </Grid>
        <Grid item xs={6}>
          <KeyValueDisplay
            label="Referring Dentist"
            value={patient?.referringDentist?.fullName}
          />
        </Grid>
      </Grid>
      <h4>Active Case Info</h4>
      <Grid container direction="row">
        <Grid item xs={12}>
          <KeyValueDisplay label="Case Ref" value={caseData?.caseRef} />
        </Grid>
        <Grid item xs={6}>
          <KeyValueDisplay
            label="Case Type"
            value={caseData?.caseType?.label}
          />
        </Grid>
        <Grid item xs={6}>
          <KeyValueDisplay
            label="Internal State"
            value={caseData?.caseState?.internal}
          />
        </Grid>
        <Grid item xs={6}>
          <KeyValueDisplay
            label="Provider Facing State"
            value={caseData?.caseState?.providerFacing}
          />
        </Grid>
        <Grid item xs={6}>
          <KeyValueDisplay
            label="TP Approved?"
            value={tpStaging?.isApproved ? 'Yes' : 'No'}
          />
        </Grid>
        <Grid item xs={6}>
          <KeyValueDisplay
            label="Number of steps (upper)"
            value={tpStaging?.data?.detailedSteps?.upper}
          />
        </Grid>
        <Grid item xs={6}>
          <KeyValueDisplay
            label="Number of steps (lower)"
            value={tpStaging?.data?.detailedSteps?.lower}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PatientCaseInfo;
