import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import useInterval from 'hooks/useInterval';

const VERSION_CHECK_INTERVAL_MS = 15 * 60 * 1000;

type State = {
  current: string | null;
  latest: string | null;
  reload: () => void;
  shouldReload: boolean;
};

const fetchVersion = (): Promise<string> => {
  return fetch('/version.json', { cache: 'no-cache' })
    .then((res) => res.json())
    .then(({ version }) => version);
};

export default () => {
  const { pathname, search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(search);

    /**
     * `noCache` should only appear in the URL under our refresh scenario.
     * Once reload is done, it can be treated as "visual pollution" and
     * be removed.
     */
    if (params.has('noCache')) {
      params.delete('noCache');
      window.location.href = `${pathname}${params.size ? `?${params.toString()}` : ''}`;
    }
  }, [pathname, search, history]);

  const [state, setState] = useState<State>({
    current: import.meta.env.VITE_VERSION_TAG_NAME as string,
    latest: null,
    reload: () => {
      window.location.href = `${window.location.pathname}?noCache=${Date.now()}`;
      setState((prev) => ({
        ...prev,
        current: prev.latest,
        shouldReload: false,
      }));
    },
    shouldReload: false,
  });

  const updateState = useCallback(() => {
    fetchVersion().then((version) =>
      setState((prev) =>
        prev.latest === version
          ? prev
          : {
              ...prev,
              latest: version,
              shouldReload:
                !!prev.current && !!version && prev.current !== version,
            }
      )
    );
  }, []);

  useEffect(() => updateState(), []);
  useInterval(updateState, VERSION_CHECK_INTERVAL_MS);

  return state;
};
