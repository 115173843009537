import useVersionTracker from 'hooks/useVersionTracker';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const regexes = [
  /^\/$/,
  /^\/(action-items|invoice-summary|login|ortho-prism|patient|preferences)$/,
  /^\/(ortho-prism|patient)\/\d+$/,
  /^\/(ortho-prism|patient)\/\d+\/cases\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[4][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/,
  /^\/patient\/\d+\/case-creator$/,
];

const AppReloader = () => {
  const { pathname } = useLocation();
  const { reload, shouldReload } = useVersionTracker();

  useEffect(() => {
    if (!regexes.some((regex) => regex.test(pathname))) {
      return;
    }

    if (shouldReload) {
      reload();
    }
  }, [pathname]);

  return null;
};

export default AppReloader;
