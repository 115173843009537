import React, { useEffect, useState, useCallback } from 'react';
import { Loading, type, AlertCard } from 'core/components';

import FileUpload from 'components/FileUpload';

import { useDispatch, useSelector } from 'react-redux';

import {
  selectXrays,
  selectXrayMaterialState,
  selectAreXraysReadyToSubmit,
  selectXraySubmissions,
  fetchXrays,
  XrayFragment,
  selectLatestXrayMaterialEvaluations,
  selectLatestXrayMaterialEvaluationSet,
  selectUnsubmittedXrays,
  addXrays,
  removeXray,
  selectIsSubmitted,
} from 'pages/Patient/patientSlice';
import { Materials } from 'types/Material';
import { materialNeedsClinicianAction } from 'pages/Patient/utils';
import { MaterialStates, StateTransitions } from 'generated/core/graphql';
import RejectedMaterialsAlert from 'pages/Patient/CaseCreator/RejectedMaterialsAlert';

import {
  Container,
  DeleteButton,
  FormContainer,
  FormContent,
  Checkbox,
  Label,
  XrayTile,
  XrayTileContainer,
  XrayCaseLabel,
  SelectedXrays,
  UploadContainer,
  UploadTile,
  ListContainer,
  ListItem,
  ListItemText,
  AlertContainer,
} from 'pages/Patient/CaseCreator/DiagnosticMaterials/Xrays/Xrays.css';
import { useIsLoading } from 'state/system';
import RefinementXrays from 'pages/Patient/CaseCreator/DiagnosticMaterials/Xrays/RefinementXrays';
import MaterialsTable from 'components/MaterialsTable';
import {
  AddXrayDocument,
  AddXrayMutation,
  AddXrayMutationVariables,
} from 'generated/core/graphql';
import { useGQLMutation } from 'hooks/useGQL';
import {
  selectActiveCase,
  selectPatient,
  uploadXrays,
  removeMaterial,
} from 'pages/Patient/patientSlice';
import { AppDispatch } from 'state/store';
import { MaterialEditBanner } from 'pages/Patient/CaseCreator/DiagnosticMaterials/MaterialEditBanner';
import { AlertTypeEnum } from 'pages/Patient/types';
import { CaseTypeNames } from 'types/Case';
import { useNotificationContext } from 'core/context/NotificationContext';

type FileState = {
  file: File | null;
  fileSrc: string;
  precedingCaseId?: string;
  materialId: string;
};

const XrayUploadTextSection = ({ uploadPrompt }: { uploadPrompt: string }) => {
  return (
    <>
      <type.BodySmall>
        {uploadPrompt} Make sure X-rays meet the following requirements:
      </type.BodySmall>

      <ListContainer>
        <ListItem>
          <ListItemText>
            File type is a <strong>.jpeg</strong> or <strong>.png</strong>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Panoramic X-rays are strongly preferred, but if you can't send a
            panoramic, please send a full set of X-rays showing the entire mouth
          </ListItemText>
        </ListItem>
      </ListContainer>
    </>
  );
};

const XraysOverview = () => {
  const { showNotification } = useNotificationContext();
  const [isCapturedWithinYear, setIsCapturedWithinYear] = useState(true);
  const [isUploadingXrays, setIsUploadingXrays] = useState(false);
  const [isCopyingXrays, setIsCopyingXrays] = useState(false);
  const [isEditingXrays, setIsEditingXrays] = useState(false);
  const [isRemovingXray, setIsRemovingXray] = useState(false);
  const isLoading = useIsLoading(fetchXrays.typePrefix);
  const isSubmitted = useSelector(selectIsSubmitted);

  const patient = useSelector(selectPatient);
  const activeCase = useSelector(selectActiveCase);
  const xrays = useSelector(selectXrays);
  const unsubmittedXrays = useSelector(selectUnsubmittedXrays);
  const xraySubmissions = useSelector(selectXraySubmissions);
  const xrayMaterialState = useSelector(selectXrayMaterialState);
  const xraysReadyToSubmit = useSelector(selectAreXraysReadyToSubmit);
  const latestXrayEvaluationSet = useSelector(
    selectLatestXrayMaterialEvaluationSet
  );
  const latestXrayEvaluations = useSelector(
    selectLatestXrayMaterialEvaluations
  );
  const [xrayViewModels, setXrayViewModels] = useState<FileState[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  const [addXray] = useGQLMutation<AddXrayMutation, AddXrayMutationVariables>(
    AddXrayDocument,
    true
  );

  const caseType = activeCase?.caseType;
  const caseRef = activeCase?.caseRef!;
  const patientId = patient?.id!;

  const isRejected =
    xrayMaterialState?.transition === StateTransitions.Reject &&
    !xraysReadyToSubmit;
  const latestXrayEvaluation = latestXrayEvaluations?.[0];
  const latestXraySubmission = xraySubmissions?.[0];
  const latestXraySubmissionXrays =
    latestXraySubmission && !isRejected
      ? xrays.filter((xray) =>
          xray.submissions.some(
            (submission) => submission.id === latestXraySubmission.id
          )
        )
      : [];

  useEffect(() => {
    setXrayViewModels(
      unsubmittedXrays?.map((xray) => ({
        file: null,
        fileSrc: xray?.url!,
        materialId: xray?.id!,
      }))
    );
  }, [unsubmittedXrays]);

  const uploadPrompt = isRejected
    ? 'Please upload a new set of X-rays. '
    : 'Drop X-rays in the area below. ';

  const hasFiles = !!xrayViewModels.length;
  const hasXrays = !!latestXraySubmissionXrays.length;

  const handleSelectFiles = async (selectedFiles: FileList | File[] | null) => {
    if (!selectedFiles || !patientId) {
      return;
    }

    try {
      setIsUploadingXrays(true);
      await dispatch(
        uploadXrays({
          selectedFiles,
          patientId: Number(patientId),
          caseRef,
          capturedWithinYearOfSubmission: isCapturedWithinYear,
        })
      ).unwrap();
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      showNotification(err.message, 'error');
    } finally {
      setIsUploadingXrays(false);
    }
  };

  const handleDeleteFile = async (materialId: string) => {
    try {
      setIsRemovingXray(true);
      await dispatch(removeMaterial({ materialId })).unwrap();
      dispatch(removeXray({ materialId }));
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      showNotification(err.message, 'error');
    } finally {
      setIsRemovingXray(false);
    }
  };

  /**
   * With refinements, we give the option to the user to sync xrays from the previous case
   */
  const handleSyncingPrecedingCaseXrays = async (
    precedingXrays: XrayFragment[]
  ) => {
    const selected = await Promise.all(
      precedingXrays.map(async (xray) => {
        const response = await fetch(xray.url!);
        const data = await response.blob();
        const file = new File([data], xray.filename!);

        return file;
      })
    );
    handleSelectFiles(selected);
  };

  const handleEditXrays = useCallback(async () => {
    try {
      setIsCopyingXrays(true);
      setIsEditingXrays(true);

      const copiedXrays = await Promise.all(
        latestXraySubmissionXrays.map(async (xray) => {
          const addXrayResult = await addXray({
            awsFileLocation: xray.awsFileLocation!,
            data: {
              capturedWithinYearOfSubmission:
                !!xray.data!.capturedWithinYearOfSubmission,
            },
            patientId: xray.patientId,
            caseRef: xray.caseRef!,
            filename: xray.filename!,
          });
          return addXrayResult?.addXray?.xray!;
        })
      );

      dispatch(addXrays({ materials: copiedXrays as XrayFragment[] }));
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      showNotification(err.message, 'error');
    } finally {
      setIsCopyingXrays(false);
    }
  }, [latestXraySubmissionXrays]);

  const alertBody =
    'If X-rays aren’t recent, then we may not be able to achieve ideal results for the patient, and case delays are likely. Please submit recent X-rays, if possible.';

  if (isLoading || isCopyingXrays) {
    return <Loading isCentered />;
  }

  const hasNoClinicianAction = !materialNeedsClinicianAction(xrayMaterialState);
  const showMaterialBanner =
    hasNoClinicianAction ||
    xrayMaterialState?.state === MaterialStates.NeedsClarification;
  const bypassMaterialEditBanner = !!unsubmittedXrays.length || isEditingXrays;
  if (showMaterialBanner && !bypassMaterialEditBanner && latestXraySubmission) {
    return (
      <>
        <MaterialEditBanner
          handleEdit={handleEditXrays}
          materialTypeLabel="X-rays"
          submitedDateTime={latestXraySubmission.createdAt}
          submitedBy={latestXraySubmission.createdByEmail}
          allowEdit={!isSubmitted && !hasNoClinicianAction}
        />
        <MaterialsTable
          materials={latestXraySubmissionXrays! as XrayFragment[]}
        />
      </>
    );
  }

  return (
    <>
      {isRejected && (
        <AlertContainer>
          <RejectedMaterialsAlert
            materialClassification={Materials.Xrays}
            materials={xrays}
            rejectedEvaluationSet={latestXrayEvaluationSet}
            rejectedEvaluation={latestXrayEvaluation}
          />
        </AlertContainer>
      )}
      <XrayUploadTextSection uploadPrompt={uploadPrompt} />
      <Container>
        {!hasFiles ? (
          <UploadContainer isSmall={hasXrays}>
            {isUploadingXrays ? (
              <Loading isCentered />
            ) : (
              <FileUpload
                fileType=".jpg, .jpeg, .png"
                allowMultipleFiles
                isHorizontal={hasXrays}
                isDisabled={isUploadingXrays}
                onSelectFile={handleSelectFiles}
              />
            )}
          </UploadContainer>
        ) : (
          <>
            <SelectedXrays>
              {xrayViewModels.map(
                ({ fileSrc, precedingCaseId, materialId }) => (
                  <XrayTileContainer key={materialId}>
                    <XrayTile src={fileSrc}>
                      <DeleteButton
                        disabled={isUploadingXrays || isRemovingXray}
                        onClick={() => handleDeleteFile(materialId)}
                        type="button"
                      />
                    </XrayTile>
                    {!!precedingCaseId && (
                      <XrayCaseLabel>
                        From case #{precedingCaseId}
                      </XrayCaseLabel>
                    )}
                  </XrayTileContainer>
                )
              )}
              <UploadTile>
                {isUploadingXrays ? (
                  <Loading isCentered />
                ) : (
                  <FileUpload
                    fileType=".jpg, .jpeg, .png"
                    allowMultipleFiles
                    hideText
                    isDisabled={isUploadingXrays}
                    onSelectFile={handleSelectFiles}
                  />
                )}
              </UploadTile>
            </SelectedXrays>
            <FormContainer isDisabled={isUploadingXrays}>
              <FormContent>
                <Label>
                  <Checkbox
                    checked={isCapturedWithinYear}
                    onChange={() =>
                      setIsCapturedWithinYear(!isCapturedWithinYear)
                    }
                    type="checkbox"
                  />
                  These x-rays were taken within the last year and after any
                  relevant dental work
                  {!isCapturedWithinYear && (
                    <AlertContainer>
                      <AlertCard
                        header="Recent X-rays are strongly preferred"
                        displayIcon={true}
                        body={alertBody}
                        type={AlertTypeEnum.Warning}
                      />
                    </AlertContainer>
                  )}
                </Label>
              </FormContent>
            </FormContainer>
          </>
        )}
        {caseType?.name === CaseTypeNames.REFINEMENTS &&
          !latestXraySubmissionXrays.length && (
            <RefinementXrays
              alreadySynced={xrayViewModels.some((vm) => !!vm.precedingCaseId)}
              onSyncedClicked={handleSyncingPrecedingCaseXrays}
            />
          )}
      </Container>
    </>
  );
};

export default XraysOverview;
