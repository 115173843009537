import React from 'react';
import { TOOH_CHART_ACTIONS } from 'types/treatmentObjective';

import { Grid } from 'core/components';
import ToothChart from 'components/ToothChart/ConsolidatedToothChart/ConsolidatedToothChart';

import { DentalNotationOptions } from 'generated/legacy/graphql';

import {
  ToothActionButtons,
  ToothStateButton,
} from 'components/ToothChart/ConsolidatedToothChart/styles';
import useConsolidatedToothChart, {
  ToothStateMetadata,
} from 'components/ToothChart/ConsolidatedToothChart/useConsolidatedToothChart';

const ConsolidatedToothChart = ({
  dentalNotation,
  disabled,
}: {
  name: string;
  dentalNotation?: DentalNotationOptions;
  disabled?: boolean;
}) => {
  const {
    consolidatedToothChart,
    selectedToothNote,
    onToothClick,
    setSelectedToothNote,
  } = useConsolidatedToothChart({
    dentalNotation,
  });

  const OptionButton = ({
    state,
    copy,
  }: {
    state: TOOH_CHART_ACTIONS;
    copy: string;
  }) => {
    const { icon: ToothIcon, strokeColor } = ToothStateMetadata[state];
    const isSelected = selectedToothNote === state;
    return (
      <ToothStateButton
        isSelected={isSelected}
        buttonType={isSelected ? 'secondary' : 'secondary-outline'}
        onClick={() => setSelectedToothNote(state)}
        strokeColor={strokeColor}
        disabled={disabled}
      >
        {ToothIcon && <ToothIcon />}
        {copy}
      </ToothStateButton>
    );
  };

  return (
    <Grid container spacing={1}>
      <ToothActionButtons>
        <OptionButton
          state={TOOH_CHART_ACTIONS.CANNOT_MOVE}
          copy="Do not move"
        />
        <OptionButton
          state={TOOH_CHART_ACTIONS.WILL_EXTRACT}
          copy="Will be extracted"
        />
        <OptionButton
          state={TOOH_CHART_ACTIONS.ERUPTION_COMPENSATION}
          copy="Eruption compensation"
        />
      </ToothActionButtons>
      <ToothChart
        values={consolidatedToothChart}
        onToothClick={onToothClick}
        disabled={disabled}
        notation={dentalNotation}
        selectedToothKey={selectedToothNote}
      />
    </Grid>
  );
};

export default ConsolidatedToothChart;
