import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuthContext } from 'context/AuthContext';

const TokenLogin = () => {
  const { handleLogIn } = useAuthContext();

  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');
  const refreshToken = searchParams.get('refreshToken');
  const authGroups = JSON.parse(searchParams.get('authGroups') || '');
  const email = searchParams.get('email');
  const path = searchParams.get('path');

  if (token && refreshToken && path && authGroups && email) {
    handleLogIn(token, refreshToken, authGroups, email);
    return (
      <Redirect
        to={{
          pathname: path,
        }}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  }
};

export default TokenLogin;
