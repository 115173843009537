import { createContext, useContext } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';

import { JourneyQuery } from 'generated/legacy/graphql';

type JourneyState = {
  journeyData: JourneyQuery | undefined;
  journeyError: Maybe<string>;
  isLoadingJourney: boolean;
  getJourneyByCaseRef: (caseRef: string) => void;
};

export const JourneyContext = createContext<JourneyState>({
  journeyData: undefined,
  journeyError: null,
  isLoadingJourney: false,
  getJourneyByCaseRef: (_: string) => {},
});
export const useJourneyContext = () => useContext(JourneyContext);
