import React from 'react';

type DraggableListProps<T> = {
  items: T[];
  onDragEnd: (
    result: { source: { index: number }; destination: { index: number } },
    groupIndex: number
  ) => void;
  renderItem: (item: T, index: number) => React.ReactNode;
  groupIndex: number;
};

type QuestionWithOrder = {
  questionId: string;
  order: number;
  group?: string;
};

export const useDraggableList = <T,>() => {
  const onDragEnd = (
    result: { source: { index: number }; destination: { index: number } },
    groupIndex: number,
    formValues: { questionsWithOrder: { questions: QuestionWithOrder[] }[] },
    setFormValues: React.Dispatch<React.SetStateAction<any>>
  ) => {
    if (!result.destination) {
      return;
    }

    const newGroups = [...formValues.questionsWithOrder];
    const [removed] = newGroups[groupIndex].questions.splice(
      result.source.index,
      1
    );
    newGroups[groupIndex].questions.splice(
      result.destination.index,
      0,
      removed
    );

    // Update order based on new position
    newGroups[groupIndex].questions = newGroups[groupIndex].questions.map(
      (q: QuestionWithOrder, index: number) => ({
        ...q,
        order: index + 1,
      })
    );

    setFormValues({ ...formValues, questionsWithOrder: newGroups });
  };

  const DraggableList = ({
    items,
    onDragEnd,
    renderItem,
    groupIndex,
  }: DraggableListProps<T>) => {
    const handleDragStart = (
      e: React.DragEvent<HTMLDivElement>,
      index: number
    ) => {
      e.dataTransfer.setData('text/plain', index.toString());
    };

    const handleDrop = (
      e: React.DragEvent<HTMLDivElement>,
      dropIndex: number
    ) => {
      const dragIndex = parseInt(e.dataTransfer.getData('text'), 10);
      onDragEnd(
        { source: { index: dragIndex }, destination: { index: dropIndex } },
        groupIndex
      );
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
    };

    return (
      <div style={{ width: '-webkit-fill-available' }}>
        {items.map((item, index) => (
          <div
            key={index}
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDrop={(e) => handleDrop(e, index)}
            onDragOver={handleDragOver}
            style={{ marginBottom: '0.5rem' }}
          >
            {renderItem(item, index)}
          </div>
        ))}
      </div>
    );
  };

  return { DraggableList, onDragEnd };
};
