import React, { useEffect, useCallback } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';

import { useJourneyLazyQuery } from 'generated/legacy/graphql';
import { JourneyContext } from 'context/JourneyContext';

type Props = {
  caseRef?: Maybe<string>;
};

const JourneyProvider: React.FunctionComponent<Props> = ({
  caseRef,
  children,
}) => {
  const [
    getJourneyData,
    { loading: isLoadingJourney, data: journeyData, error: journeyError },
  ] = useJourneyLazyQuery();

  const getJourneyByCaseRef = useCallback(
    (caseRef: string) => {
      getJourneyData({ variables: { caseRef } });
    },
    [getJourneyData]
  );

  useEffect(() => {
    if (!caseRef) {
      return;
    }
    getJourneyByCaseRef(caseRef);
  }, [caseRef, getJourneyData, getJourneyByCaseRef]);

  return (
    <JourneyContext.Provider
      value={{
        journeyData,
        isLoadingJourney,
        getJourneyByCaseRef,
        journeyError: journeyError?.message,
      }}
    >
      {children}
    </JourneyContext.Provider>
  );
};

export default JourneyProvider;
