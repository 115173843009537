import styled from 'styled-components';
import Plus from 'assets/ic_plus.svg?react';
import Edit from 'assets/ic_edit-2.svg?react';
import Trash from 'assets/trash.svg?react';
import { TextareaAutosize } from '@material-ui/core';

export const NoteEditorContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 1rem;
  h3 {
    margin-bottom: 0.5rem;
  }
  border: 1px solid ${({ theme }) => theme.colors.black30};
  box-shadow: 0px 1px 4px 0px ${({ theme }) => theme.colors.text20};
  border-radius: 8px;
  padding: 1rem;
`;

export const StyledTextArea = styled(TextareaAutosize)`
  width: 100%;
  padding: 1.3rem 0 0.7rem;
  resize: none;
  line-height: 1rem;
  overflow-y: auto;
`;

export const IconButton = styled.button`
  margin-bottom: 4px;
  scale: 0.8;
  :disabled {
    opacity: 0.25;
  }
`;

export const EditorHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & h3 {
    margin: 0;
  }
`;

export const IconButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
`;

export const EditorFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: end;
  height: 3rem;
  gap: 0.5rem;
  & button {
    padding: 0.1rem 0.9rem;
  }
`;

export const LengthTracker = styled.h5<{ isOverMaxLength: boolean }>`
  ${(props) => {
    const { isOverMaxLength } = props;
    return `
      color: ${isOverMaxLength ? `${props.theme.colors.red50}` : `${props.theme.colors.text70}`};
      line-height: 21px;
      padding: 0.1rem;
      margin: 0;
    `;
  }}
`;

export const StyledEditIcon = styled(Edit)`
  stroke: ${({ theme }) => theme.colors.text};
`;

export const StyledTrashIcon = styled(Trash)`
  * {
    stroke: ${({ theme }) => theme.colors.text};
  }
`;

export const StyledPlusIcon = styled(Plus)`
  * {
    stroke: ${({ theme }) => theme.colors.text};
  }
`;

export const LastModifiedText = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    font-weight: 500;
    font-size: ${theme.fontSizes.small};
    color: ${theme.colors.black70};
    margin: 0;
    gap: 5px;
    padding: 0.5rem 0;
    & i {
      margin: 0;
      line-height: 1;
    }
`}
`;

export const StartText = styled.i`
  ${({ theme }) => `
    color: ${theme.colors.black70};
    font-size: ${theme.fontSizes.small};
    padding: 1.3rem 0;
    height: 6rem;
`}
`;
