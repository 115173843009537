import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Body,
  CloseCaseWrap,
  CaseLink,
} from 'pages/OrthoPrism/CaseActions/CaseActions.css';
import { ApolloError } from '@apollo/client';
import { type } from 'core/components';
import {
  cancelCoreCase,
  fetchCustomer,
  reopenCoreCase,
} from 'pages/OrthoPrism/orthoSlice';

import { useTransitionJourneyMutation } from 'generated/legacy/graphql';
import { Dropdown } from 'components/Dropdown/Dropdown';
import ConfirmDialog from 'components/ConfirmDialog';
import withNotifications from 'components/withNotifications';
import { JourneyTransition } from 'types/journey';
import { ALIGNER_JOURNEY_TYPE } from 'utils/journey';
import { fetchCases, selectCases } from 'pages/OrthoPrism/orthoSlice';
import {
  Label,
  StyledReactSelect,
  CustomerReactSelectStyles,
} from 'styles/inputs.css';
import { apolloErrorToString } from 'utils/apollo';
import { AUTH_GROUPS } from 'types';
import { useGQLMutation } from 'hooks/useGQL';
import {
  MaterialEvaluationTypes,
  RemoveMaterialEvaluationMutation,
  RemoveMaterialEvaluationDocument,
  RemoveMaterialEvaluationMutationVariables,
  TreatmentPlanStagingStates,
  CaseSource,
} from 'generated/core/graphql';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTreatmentPlanStagings,
  selectSortedTreatmentPlanStagings,
} from 'pages/OrthoPrism/orthoSlice';
import { Sort, sortByCreated } from 'utils/prism';
import * as Sentry from '@sentry/browser';
import {
  CloseCaseReason,
  CloseCaseReasonOption,
  CloseCaseReasonDict,
  customerDescriptions,
  CaseAction,
  closeCaseReasonOptions,
  caseActionList,
  CaseActionWrapProps,
  CaseActionProps,
} from 'pages/OrthoPrism/CaseActions/constants';
import api from 'state/api';
import useReinitiateTreatmentPlanning from 'hooks/useReinitiateTreatmentPlanning';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAuthContext } from 'context/AuthContext';

const CaseActionsWrap: FunctionComponent<CaseActionWrapProps> = ({
  customerId,
  selectedCase,
  showNotification,
}) => {
  const dispatch = useDispatch();
  const cases = useSelector(selectCases);
  const hiddenCaseActions = [];
  const hasActiveCase = cases?.some((caseItem) => caseItem.isActive);
  const casesLength = cases?.length || 0;

  if (casesLength > 1 && hasActiveCase) {
    hiddenCaseActions.push(CaseAction.reopenCase);
  }

  if (!selectedCase) {
    return (
      <div>
        <Body rightMargin>No case selected</Body>
      </div>
    );
  }

  const refetchCustomerAndCases = () => {
    dispatch(
      fetchCustomer({
        customerId,
      })
    );
    dispatch(
      fetchCases({
        patientIds: [Number(customerId)],
      })
    );
  };

  if (selectedCase) {
    return (
      <CaseActionsComponent
        {...{
          hiddenCaseActions,
          selectedCase,
          customerId,
          refetchCases: refetchCustomerAndCases,
          closeCaseReasonOptions,
          showNotification,
        }}
      />
    );
  } else {
    return <></>;
  }
};

const renderDialogBody = (
  action: CaseAction | null,
  setCloseCaseReason: (reason: CloseCaseReason) => void,
  closeCaseReason: string | null,
  closeCaseReasonOptions: CloseCaseReasonOption[]
) => {
  let copy: React.ReactElement | string;
  switch (action) {
    case CaseAction.closeCase: {
      copy = (
        <CloseCaseWrap>
          <Label htmlFor="close-case-reason">
            Reason for closing this case
          </Label>
          <StyledReactSelect
            id="close-case-reason"
            label="Reason"
            options={closeCaseReasonOptions}
            onChange={(option: CloseCaseReasonOption) =>
              setCloseCaseReason(option.value)
            }
            value={closeCaseReasonOptions.find(
              ({ value }) => value === closeCaseReason
            )}
            styles={CustomerReactSelectStyles}
          />
        </CloseCaseWrap>
      );
      break;
    }
    case CaseAction.reopenCase: {
      copy = (
        <>
          <type.H5>Are you sure you want to reopen this case?</type.H5>
          <div>This will take the case out of a cancelled state.</div>
        </>
      );
      break;
    }
    case CaseAction.revertQC: {
      copy = 'Revert treatment plan to QC Review?';
      break;
    }
    case CaseAction.clearTreatmentPlanningDraftFiles: {
      copy = (
        <type.H5>This will reset the in-progress TP, are you sure?</type.H5>
      );
      break;
    }
    case CaseAction.reinitiateTreatmentPlanning: {
      copy = (
        <type.H5>
          Switching TP software is a final action. All current progress on the
          treatment plan will be lost including all versions, reviews and notes.
          Please confirm to continue.
        </type.H5>
      );
      break;
    }
    default: {
      copy = '';
      break;
    }
  }

  return <div>{copy}</div>;
};

const CaseActionsComponent = ({
  hiddenCaseActions,
  selectedCase,
  showNotification,
  refetchCases,
  closeCaseReasonOptions,
}: CaseActionProps) => {
  const { 'enable-create-cases-in-core': proxyCaseCallsThroughCore } =
    useFlags();
  const { checkHasAccess, userInfo } = useAuthContext();
  const [shouldOpenDialog, setShouldOpenDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState<CaseAction | null>(null);
  const [closeCaseReason, setCloseCaseReason] =
    useState<CloseCaseReason | null>(null);
  const sortedTreatmentPlans = useSelector(selectSortedTreatmentPlanStagings);
  const dispatch = useDispatch();
  const latestTreatmentPlan = sortedTreatmentPlans[0];
  const tpState = latestTreatmentPlan?.state;

  const refetchCustomerAndCases = () => {
    dispatch(
      fetchCustomer({
        customerId: String(selectedCase.patientId),
      })
    );
    dispatch(
      fetchCases({
        patientIds: [selectedCase.patientId],
      })
    );
  };

  const onSuccessActions = (message: string) => {
    showNotification(message, 'success');
  };

  const showErrorNotification = (err: ApolloError) => {
    const apolloError = apolloErrorToString(err);
    const errorMessage = apolloError || 'There has been an error';
    showNotification(errorMessage, 'error');
  };

  const [transitionJourney] = useTransitionJourneyMutation({
    onError: showErrorNotification,
    onCompleted: () => {
      setCurrentAction(null);
      refetchCases();

      switch (currentAction) {
        case CaseAction.reopenCase:
          onSuccessActions(
            `Successfully reopened case ${selectedCase?.caseState?.id}`
          );
          break;
        case CaseAction.closeCase:
          if (closeCaseReason === CloseCaseReasonDict.IN_TREATMENT_ISSUE) {
            onSuccessActions(
              `Set status to "In treatment issue" for case ${selectedCase?.caseState?.id}`
            );
          } else {
            onSuccessActions(
              `Successfully closed case ${selectedCase?.caseState?.id}`
            );
          }
          setCloseCaseReason(null);
          break;
        default:
          break;
      }
    },
  });
  const [clearTreatmentPlanningDraftFile] =
    api.useClearTreatmentPlanningDraftFilesMutation();
  const { executor, message, status } = useReinitiateTreatmentPlanning({
    caseRef: selectedCase.caseRef,
    currentSoftware: selectedCase?.caseSoftware,
    notes: `Switching TP software from CaseAction. UserID: ${userInfo?.id}`,
  });
  const [removeEvaluationSet] = useGQLMutation<
    RemoveMaterialEvaluationMutation,
    RemoveMaterialEvaluationMutationVariables
  >(RemoveMaterialEvaluationDocument);
  useEffect(() => {
    if (message && status) {
      showNotification(message, status);
      refetchCustomerAndCases();
    }
  }, [message, status]);
  const triggerReinitiateTreatmentPlanning = async () => {
    await executor();
    setCurrentAction(null);
  };

  const triggerClearTreatmentPlanningDraftFiles = async (caseRef: string) => {
    try {
      const rsp = await clearTreatmentPlanningDraftFile({
        caseRef,
      }).unwrap();
      setCurrentAction(null);
      onSuccessActions(
        `Successfully moved the following files to a backup location on S3 ${rsp?.filesMoved?.join(
          ', '
        )}`
      );
    } catch (e) {
      if (e && typeof e === 'object' && 'message' in e) {
        showNotification(e?.message as string, 'error');
      }
    }
  };

  const handleClick = (action: CaseAction) => {
    setCurrentAction(action);
    switch (action) {
      default: {
        setShouldOpenDialog(true);
        break;
      }
    }
  };

  const handleConfirm = () => {
    const { caseRef } = selectedCase;
    const latestTPId = latestTreatmentPlan?.id || '';
    const journey = ALIGNER_JOURNEY_TYPE;

    switch (currentAction) {
      case CaseAction.reopenCase: {
        if (
          selectedCase.source === CaseSource.Core ||
          proxyCaseCallsThroughCore
        ) {
          dispatch(
            reopenCoreCase({
              caseRef: selectedCase?.caseRef,
            })
          );
          refetchCustomerAndCases();
        } else if (selectedCase.source === CaseSource.CaseService) {
          transitionJourney({
            variables: {
              caseRef,
              component: journey,
              transition: JourneyTransition.ForceCaseActive,
            },
          });
        } else {
          showNotification(
            'Unable to reopen case. Please reach out to support for assistance',
            'error'
          );
        }
        break;
      }
      case CaseAction.closeCase: {
        if (
          selectedCase.source === CaseSource.Core ||
          proxyCaseCallsThroughCore
        ) {
          dispatch(
            cancelCoreCase({
              caseRef: selectedCase?.caseRef,
              reason: closeCaseReason,
            })
          );
          refetchCustomerAndCases();
        } else if (selectedCase.source === CaseSource.CaseService) {
          transitionJourney({
            variables: {
              caseRef,
              component: journey,
              transition: JourneyTransition.ForceCaseCanceled,
              transitionReason: closeCaseReason,
            },
          });
        } else {
          showNotification(
            'Unable to cancel case. Please reach out to support for assistance',
            'error'
          );
        }
        break;
      }
      case CaseAction.revertQC: {
        if (latestTPId) {
          const minDate = '0001-01-01T00:00:00Z';
          const latestQCMaterialEvaluation =
            latestTreatmentPlan?.materialEvaluations
              ?.filter(
                (p) =>
                  p.evaluationType.name ===
                  MaterialEvaluationTypes.QualityControlEvaluation
              )
              ?.sort((a, b) =>
                sortByCreated(Sort.Desc)(
                  { created: a?.createdAt ?? minDate },
                  { created: b?.createdAt ?? minDate }
                )
              )[0];
          const evaluationSetId = latestQCMaterialEvaluation?.evaluationSet?.id;
          if (evaluationSetId) {
            removeEvaluationSet({
              evaluationSetId,
            })
              .then(() => {
                dispatch(
                  fetchTreatmentPlanStagings({ caseRef: selectedCase.caseRef })
                );
              })
              .catch((err) => {
                showErrorNotification(err);
                Sentry.captureException(err);
              });
          }
        }
        break;
      }
      case CaseAction.clearTreatmentPlanningDraftFiles: {
        if (caseRef) {
          triggerClearTreatmentPlanningDraftFiles(caseRef);
        }
        break;
      }
      case CaseAction.reinitiateTreatmentPlanning: {
        if (caseRef) {
          triggerReinitiateTreatmentPlanning();
        }
        break;
      }
      default: {
        break;
      }
    }

    setShouldOpenDialog(false);
  };

  const renderCaseActions = () => {
    return caseActionList
      .filter((caseAction) => {
        const isActive = selectedCase.isActive;

        if (hiddenCaseActions?.includes(caseAction)) {
          return false;
        }
        if (caseAction === CaseAction.revertQC) {
          if (
            tpState !== TreatmentPlanStagingStates.OrthoReview &&
            tpState !== TreatmentPlanStagingStates.QcRejected
          ) {
            return false;
          }
        }

        // Only show relevant actions depending on whether current case is active
        if (
          (isActive && caseAction === CaseAction.reopenCase) ||
          (!isActive && [CaseAction.closeCase].includes(caseAction))
        ) {
          return false;
        }
        if (caseAction === CaseAction.clearTreatmentPlanningDraftFiles) {
          if (!checkHasAccess([AUTH_GROUPS.TPMX])) {
            return false;
          }
        }
        if (caseAction === CaseAction.reinitiateTreatmentPlanning) {
          if (!checkHasAccess([AUTH_GROUPS.TPMX])) {
            return false;
          }
        }
        return true;
      })
      .map((caseAction) => {
        return (
          <CaseLink
            action={caseAction}
            key={caseAction}
            onClick={() => handleClick(caseAction)}
          >
            {customerDescriptions[caseAction]}
          </CaseLink>
        );
      });
  };

  const caseActions = renderCaseActions();

  if (!caseActions.length) {
    return null;
  }

  return (
    <div>
      <Body rightMargin>
        <Dropdown showCaret title={<CaseLink>Case actions</CaseLink>}>
          {caseActions}
        </Dropdown>
      </Body>
      <ConfirmDialog
        isOpen={shouldOpenDialog}
        onCancel={() => {
          setShouldOpenDialog(false);
          setTimeout(() => {
            setCurrentAction(null);
          }, 300);
        }}
        onConfirm={handleConfirm}
        isConfirmDisabled={
          currentAction === CaseAction.closeCase && !closeCaseReason
        }
        showCancelButton={!!currentAction}
        confirmButtonText="Confirm"
      >
        {renderDialogBody(
          currentAction,
          setCloseCaseReason,
          closeCaseReason,
          closeCaseReasonOptions
        )}
      </ConfirmDialog>
    </div>
  );
};

export default withNotifications(CaseActionsWrap);
