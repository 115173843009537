import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { AUTH_GROUPS } from 'types';
import * as TreatmentPlans from 'pages/OrthoPrism/treatmentPlans';
import Notes from 'pages/Patient/PatientDetail/DiagnosticMaterials/TreatmentPlanTab/ProReviewFormNotes';
import {
  FormContainer,
  FormRow,
  Label,
  QuestionHeading,
  Radio,
  RadioGroup,
  NotesContainer,
  NotesHeader,
  OrderButton,
  ButtonContainer,
  RightArrow,
  ContinueButton,
  ModalContainer,
} from 'pages/Patient/PatientDetail/DiagnosticMaterials/TreatmentPlanTab/ProReviewForm.css';
import {
  selectBypassOrthoReview,
  selectSelectedCase,
  selectProfileInfo,
} from 'pages/Patient/patientSlice';
import { usePatientLoadingStates } from 'pages/Patient/utils';
import * as TreatmentPlanStagings from 'pages/OrthoPrism/treatmentPlanStagings';
import Checkout from 'pages/Patient/Checkout/Checkout';
import FullPageModal from 'components/FullPageModal/FullPageModal';
import { CaseTypeNames } from 'types/Case';
import { ProductTypes } from 'types/checkout';
import { Button, Modal } from 'core/components';

type Props = {
  isSubmitting: boolean;
  onSubmit?: (
    args: TreatmentPlanStagings.TreatmentPlanStagingSubmitCallbackArgs
  ) => void;
  isDfaEnabled?: boolean;
  onOpenDfaSoftware?: () => void;
};

type ReviewOption =
  | typeof TreatmentPlans.ReviewOption.ProReject
  | typeof TreatmentPlans.ReviewOption.Approve;

const isReviewOption = (s: any): s is ReviewOption =>
  s === TreatmentPlans.ReviewOption.ProReject ||
  s === TreatmentPlans.ReviewOption.Approve;

const CandidProForm = ({
  isSubmitting,
  onSubmit = () => {},
  isDfaEnabled = false,
  onOpenDfaSoftware = () => {},
}: Props) => {
  const selectedCase = useSelector(selectSelectedCase);
  const { isFetchingPatient, isFetchingCases } = usePatientLoadingStates();
  const bypassOrthoReview = useSelector(selectBypassOrthoReview);
  const dentalWorkCTA = `Please ensure that the treatment plan you are approving is based on the patient's latest records. If any unplanned dental work is performed please reach out to our support team to determine if a new case should be started.`;

  const [transition, setTransition] = useState<ReviewOption | undefined>();
  const [showModal, setShowModal] = useState(false);
  const profileInfo = useSelector(selectProfileInfo);
  const { fullName } = profileInfo;

  const isApproved = transition === TreatmentPlans.ReviewOption.Approve;
  const [notes, setNotes] = useState<string | undefined>();
  const [isRefreshModalOpen, setIsRefreshModalOpen] = useState<boolean>(false);

  const onChangeTransition = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if (isReviewOption(value)) {
        setTransition(value);
        return;
      }
      console.error(`[panic] malformed transition: ${value}`);
    },
    []
  );

  let args = {
    transition,
    notes,
    reason: '',
  };

  /*
  TODO: Remove this once the UI for phase 2 is ready.
  Phase 1 of enabling clinicians to bypass ortho review focused on enabling this feature 
  from primarily the backend only. Phase 2 which will involve full UI support for this feature,
  but until then we need to hardcode the transition reason as "revise_setup" for Salesforce's workflow purposes.
  */
  if (bypassOrthoReview && !isApproved) {
    args = {
      transition,
      notes,
      reason: 'revise_setup',
    };
  }

  const isValid = TreatmentPlans.isProClinicianSubmissionArgs(args);
  const submitRef = React.createRef<HTMLButtonElement>();

  const handleSubmit = useCallback(async () => {
    // This is the submit action from this Pro form, this will call the inner Basic address form's submit action
    // which will validate the address and submit the form
    if (submitRef.current) {
      submitRef.current.click();
      return;
    }

    submitTreatmentPlan(args);
  }, [args]);

  const submitTreatmentPlan = async (args: any) => {
    if (TreatmentPlans.isProClinicianSubmissionArgs(args)) {
      onSubmit({
        transition: args.transition,
        notes: args.notes,
        problems: [
          {
            id: '',
            isEditing: false,
            topic: null,
            photos: [],
            notes: '',
          },
        ],
        proOrderArgs: {
          shippingAddress: args.shippingAddress!,
          shippingAddressType: args.shippingAddressType!,
          sendPatientUpdate: args.sendPatientUpdate!,
        },
      });
    } else {
      Sentry.captureException(
        `This is a quiet error. No network calls or logs get captured in this case
      TPs.isProClinicianSubmissionArgs: ${TreatmentPlans.isProClinicianSubmissionArgs(
        args
      )}, 
      args: ${JSON.stringify(args)}`
      );
    }
  };

  const productToCheckout = useMemo(() => {
    if (selectedCase?.caseType.name === CaseTypeNames.REFINEMENTS) {
      return ProductTypes.Refinement;
    }

    return ProductTypes.Aligner;
  }, [selectedCase?.caseRef]);

  return (
    <>
      <FullPageModal
        patientName={fullName}
        titleText="Place order"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onContinue={() => {}}
        continueDisabled={false}
        isLoading={false}
        hideFooter
      >
        <Checkout
          productType={productToCheckout}
          onClose={() => setShowModal(false)}
        />
      </FullPageModal>
      <Modal
        onClose={() => window.location.reload()}
        isOpen={isRefreshModalOpen}
      >
        <ModalContainer>
          <h1>Treatment plan modification using 3D controls is in progress.</h1>
          After submitting your modifications please refresh this page to see
          the updated version of this case.
          <ButtonContainer>
            <Button
              buttonType="secondary"
              onClick={() => window.location.reload()}
            >
              Close to refresh
            </Button>
          </ButtonContainer>
        </ModalContainer>
      </Modal>
      <FormContainer>
        <FormRow>
          <QuestionHeading>Is the treatment plan acceptable?</QuestionHeading>
          <p>
            By approving, you are agreeing that the treatment plan{' '}
            <strong>will be pushed to production</strong> and your office will
            be invoiced.
          </p>
          <RadioGroup role="radiogroup">
            <Label>
              <Radio
                type="radio"
                name="transition"
                errorText="You do not have the permissions to approve a treatment plan"
                value={TreatmentPlans.ReviewOption.Approve}
                onChange={onChangeTransition}
                forbiddenGroups={[
                  AUTH_GROUPS.TREATMENT_PLAN_QA,
                  AUTH_GROUPS.TREATMENT_PLAN_TECH,
                ]}
              />
              Approve this treatment plan version
            </Label>
            <Label>
              <Radio
                type="radio"
                name="transition"
                errorText="You do not have the permissions to reject a treatment plan"
                value={TreatmentPlans.ReviewOption.ProReject}
                onChange={onChangeTransition}
                forbiddenGroups={[AUTH_GROUPS.TREATMENT_PLAN_TECH]}
              />
              Request a revision or ask a question
            </Label>
          </RadioGroup>
        </FormRow>
        {transition && (
          <Notes isProClinician tpState={transition} onChange={setNotes} />
        )}
        <ButtonContainer>
          {isApproved ? (
            <ContinueButton
              isShort
              onClick={() => setShowModal(true)}
              buttonType="secondary"
              disabled={
                !isValid || isSubmitting || isFetchingPatient || isFetchingCases
              }
              isLoading={isSubmitting}
            >
              Continue to checkout <RightArrow role="img" />
            </ContinueButton>
          ) : (
            <OrderButton
              isShort
              onClick={handleSubmit}
              buttonType="secondary"
              disabled={
                !isValid || isSubmitting || isFetchingPatient || isFetchingCases
              }
              isLoading={isSubmitting}
            >
              Submit
            </OrderButton>
          )}
          {isDfaEnabled && (
            <OrderButton
              isShort
              onClick={() => {
                setIsRefreshModalOpen(true);
                onOpenDfaSoftware();
              }}
              buttonType="secondary"
            >
              Launch 3D Controls
            </OrderButton>
          )}
        </ButtonContainer>
        <NotesContainer>
          <NotesHeader>Note about dental work</NotesHeader>
          <div>{dentalWorkCTA}</div>
        </NotesContainer>
      </FormContainer>
    </>
  );
};

export default CandidProForm;
