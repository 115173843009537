import { ACCESS_GROUPS } from 'types';
import { useAuthContext } from 'context/AuthContext';

/*
 * function that checks if the user is a candid pro user but NOT either othro or support.
 * @returns whether the user is in candid pro, but not othro or support
 */
export function useIsOnlyCandidPro(): boolean {
  const { checkHasAccess } = useAuthContext();

  return (
    checkHasAccess &&
    checkHasAccess(ACCESS_GROUPS.CANDID_PRO) &&
    !checkHasAccess(ACCESS_GROUPS.ORTHO.concat(ACCESS_GROUPS.SUPPORT))
  );
}
