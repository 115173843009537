import moment from 'moment';
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import Dinero from 'dinero.js';

import {
  LoyaltyProgram,
  LoyaltyProgramTier,
  LoyaltyTierProduct,
} from 'generated/core/graphql';

import { Grid, Table, Button, Text, TextInput, colors } from 'core/components';
import EditIcon from 'assets/edit.svg?react';
const EditIconContainer = styled(Grid)`
  cursor: pointer;
`;

import { PageContainer, StyledBackLink } from 'pages/LoyaltyProgram/shared';
import api from 'state/api';
import { useNotificationContext } from 'core/context/NotificationContext';

const StyledTable = styled(Table)`
  td,
  th {
    padding-left: 0;
  }
`;

const discountFormatter = (discount: number) => {
  return Dinero({ amount: discount, currency: 'USD' }).toFormat('$0,0.00');
};

const columns = [
  'Tier name',
  {
    name: 'id',
    label: 'Discount',
    options: {
      customBodyRender: (loyaltyTierProducts: LoyaltyTierProduct[]) => {
        return (
          <ul>
            {loyaltyTierProducts.map((loyaltyTierProduct, index) => {
              return (
                <li key={index}>
                  <Text>{loyaltyTierProduct.sku}</Text>
                  <Text color={colors.red50}>
                    {discountFormatter(loyaltyTierProduct.discount)}
                  </Text>
                </li>
              );
            })}
          </ul>
        );
      },
    },
  },
  'TPAs required',
];

type RouteParams = {
  id: string;
};

const LoyaltyProgramViewer = ({
  loyaltyPrograms = [],
  onSubmitSuccess,
  onArchiveSuccess,
  onUnarchiveSuccess,
}: {
  loyaltyPrograms: LoyaltyProgram[];
  onSubmitSuccess: (name: string) => void;
  onArchiveSuccess: (programName: string) => void;
  onUnarchiveSuccess: (programName: string) => void;
}) => {
  const { showNotification } = useNotificationContext();
  const [isEdittingTitle, setIsEdittingTitle] = useState<boolean>(false);

  const history = useHistory();

  const { id: programId } = useParams<RouteParams>();
  const program = loyaltyPrograms.find(
    (program: LoyaltyProgram) => program?.id === programId
  );

  if (!program) {
    return null;
  }

  const [programName, setProgramName] = useState<string>(program?.name || '');

  const mappedData = program.loyaltyProgramTiers
    .sort(
      (a: LoyaltyProgramTier, b: LoyaltyProgramTier) =>
        a.sortOrder - b.sortOrder
    )
    .map((tier: LoyaltyProgramTier) => {
      return [
        tier.name,
        tier.loyaltyTierProducts,
        tier?.entryRule?.args?.minNumber,
      ];
    });

  const handleDuplicateClick = () => {
    history.push('/loyalty-program/create', { program });
  };

  const isValidLoyaltyProgramName = (name: string) => {
    if (name.includes('Grandfathered') || name.includes('DSO')) {
      return false;
    }
    if (name.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleNameInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.currentTarget;

    setProgramName(value);
  };

  const [updateLoyaltyProgram] = api.useUpdateLoyaltyProgramMutation({});
  const [archiveLoyaltyProgram] = api.useArchiveLoyaltyProgramMutation({});
  const [unarchiveLoyaltyProgram] = api.useUnarchiveLoyaltyProgramMutation({});

  const handleUnarchiveClick = async () => {
    const response = await unarchiveLoyaltyProgram({
      loyaltyProgramId: program?.id,
    });

    if ('error' in response) {
      showNotification(
        `Error unarchiving Loyalty Program: ${JSON.stringify(response.error)}`,
        'error'
      );
    } else {
      if (response?.data?.loyaltyProgram) {
        onUnarchiveSuccess(response.data.loyaltyProgram.name);
      }
    }
  };

  const handleArchiveClick = async () => {
    const response = await archiveLoyaltyProgram({
      loyaltyProgramId: program?.id,
    });

    if ('error' in response) {
      showNotification(
        `Error archiving Loyalty Program: ${JSON.stringify(response.error)}`,
        'error'
      );
    } else {
      if (response?.data?.loyaltyProgram) {
        onArchiveSuccess(response.data.loyaltyProgram.name);
      }
    }
  };

  const handleNameSave = async () => {
    try {
      if (!isValidLoyaltyProgramName(programName)) {
        showNotification('Invalid program name', 'error');
        return;
      }

      const response = await updateLoyaltyProgram({
        input: {
          id: program?.id,
          name: programName,
        },
      });

      if ('error' in response) {
        showNotification(
          `Error updating Loyalty Program: ${JSON.stringify(response.error)}`,
          'error'
        );
      } else {
        onSubmitSuccess(programName);
        setIsEdittingTitle(false);
      }
    } catch (error) {
      showNotification(JSON.stringify(error), 'error');
    }
  };

  const loyaltyProgramEditable = (programName: string) => {
    if (programName.includes('Grandfathered') || programName.includes('DSO')) {
      return false;
    } else {
      return true;
    }
  };

  const renderProgramStatus = (program: LoyaltyProgram) => {
    if (program.archivedAt) {
      const archivedMessage = `Inactive (archived on ${moment(program.archivedAt).format('MM/DD/YYYY')} by User#${program.archivedBy})`;
      return <Text color={colors.red90}>{archivedMessage}</Text>;
    } else {
      return <Text color={colors.green90}>Active</Text>;
    }
  };

  return (
    <PageContainer container justifyContent="center">
      <Grid container xs={8} direction="column">
        <Grid item style={{ marginBottom: '2rem' }}>
          <StyledBackLink />
        </Grid>
        <Grid container direction="row" alignItems="center" spacing={2}>
          {isEdittingTitle ? (
            <>
              <Grid item>
                <TextInput
                  value={programName}
                  onChange={handleNameInputChange}
                  style={{ width: '400px' }}
                  testId={`edit-program-name-input`}
                />
              </Grid>
              <Grid item>
                <Button onClick={handleNameSave}>Save</Button>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Text
                style={{ fontSize: '1.5rem', fontWeight: 700 }}
                data-testid={`edit-program-name`}
              >
                {programName}
              </Text>
            </Grid>
          )}
          {loyaltyProgramEditable(program?.name || '') && (
            <EditIconContainer item onClick={() => setIsEdittingTitle(true)}>
              {!isEdittingTitle && <EditIcon />}
            </EditIconContainer>
          )}
        </Grid>
        <Grid item>{renderProgramStatus(program)}</Grid>
        <Grid item style={{ marginBottom: '3.5rem' }}>
          <StyledTable
            data={mappedData}
            columns={columns}
            options={{
              selectableRows: 'none',
              fixedHeader: false,
              pagination: false,
            }}
          />
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item>
              {program.archivedAt && (
                <Button buttonType="secondary" onClick={handleUnarchiveClick}>
                  Unarchive
                </Button>
              )}
              {!program.archivedAt && (
                <Button buttonType="secondary" onClick={handleArchiveClick}>
                  Archive
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button buttonType="secondary" onClick={handleDuplicateClick}>
                Duplicate
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default LoyaltyProgramViewer;
