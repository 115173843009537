import React, { ReactNode } from 'react';
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import { client } from 'gql/clients';

const GraphQLProvider = ({ children }: { children: ReactNode }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export type ApolloClientType = ApolloClient<NormalizedCacheObject>;

export default GraphQLProvider;
