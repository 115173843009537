import { useCallback } from 'react';
import { OperationVariables, DocumentNode } from '@apollo/client';
import { client as legacyClient } from 'gql/clients';
import { ApolloClientType } from 'gql/GraphQLProvider';

// This is a solution pending useLazyQuery execution function should return a promise
// See https://github.com/apollographql/react-apollo/issues/3499
export const useLazyQuery = <Data, Variables extends OperationVariables>(
  query: DocumentNode,
  client: ApolloClientType = legacyClient
) => {
  return useCallback(
    (variables: Variables) =>
      client.query<Data, Variables>({
        query: query,
        variables: variables,
      }),
    [query, client]
  );
};

// This is a solution pending useLazyQuery execution function should return a promise
// See https://github.com/apollographql/react-apollo/issues/3499
export const useLazyMutation = <Data, Variables extends OperationVariables>(
  mutation: DocumentNode,
  client: ApolloClientType = legacyClient
) => {
  return useCallback(
    (variables: Variables) =>
      client.mutate<Data, Variables>({
        mutation: mutation,
        variables: variables,
        fetchPolicy: 'no-cache',
      }),
    [mutation, client]
  );
};
