import React from 'react';
import api from 'state/api';
import { Button } from 'core/components';
import { getEnvironment, Environment } from 'utils/environment';
import { useFormikContext } from 'formik';
import { CreatePracticeAccountMutationVariables } from 'generated/legacy/graphql';
import {
  ButtonTooltipContainer,
  GreyTooltip,
} from 'pages/PracticeAccountCreator/PracticeAccountCreator.css';
import { overwriteUniqueFields } from 'pages/PracticeAccountCreator/utils';
import { PracticeLeadData } from 'pages/PracticeAccountCreator/types';

const STAGING_BACKEND_SALESFORCE_ID = '001QL00000UHVpfYAH';

type DevelopmentActionsProps = {
  setSalesforceChildAccountId: (setChildAccountId: string) => void;
  setInProgress: (inProgress: boolean) => void;
  setEnableBrandSelection: (isBrandDisabled: boolean) => void;
  setLoyaltyOptions: (loyaltyOptions: PracticeLeadData) => void;
};

export const DevelopmentActions = ({
  setSalesforceChildAccountId,
  setInProgress,
  setEnableBrandSelection,
  setLoyaltyOptions,
}: DevelopmentActionsProps) => {
  const environment: Environment = getEnvironment();

  if (environment == Environment.QA || environment == Environment.PRODUCTION) {
    return null;
  }

  const { setFieldValue } =
    useFormikContext<CreatePracticeAccountMutationVariables>();
  const [getPracticeLeadData] = api.useLazyGetPracticeLeadDataQuery({});
  const [getAccountLeadData] = api.useLazyGetAccountLeadDataQuery({});

  const setDummyFieldValues = async () => {
    setEnableBrandSelection(true);
    setSalesforceChildAccountId(STAGING_BACKEND_SALESFORCE_ID);
    await overwriteUniqueFields(
      STAGING_BACKEND_SALESFORCE_ID,
      getPracticeLeadData,
      getAccountLeadData,
      setFieldValue,
      setInProgress,
      setLoyaltyOptions
    );
  };

  return (
    <div>
      <ButtonTooltipContainer>
        <Button buttonType="secondary" onClick={setDummyFieldValues}>
          Make haste
        </Button>
        <GreyTooltip
          title="This is for rapid creation of a doctor and practice for test and development purposes."
          placement="top"
        />
      </ButtonTooltipContainer>
    </div>
  );
};

export default DevelopmentActions;
